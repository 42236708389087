import React, { FC, useState, useEffect, useRef, useContext } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL, IMAGE_SERVER_URL_DEV } from "Constant/System";
import Company1 from "images/avatars/Image-1.png";
import ModalQuickView from "components/ModalSeeker";
import { addToast } from "shared/Toast";
import { getAds, getJobs, getShortList, GetUserId } from "Services/API/Get";
import { DeleteAd, DeleteJob, DeleteShortlist } from "Services/API/Delete";
import { useHistory } from "react-router-dom";

import ModalQuickView2 from "components/ModalSeeker";

import DatePicker from "react-multi-date-picker"
import { Autocomplete, Tooltip, Zoom } from "@mui/material";
import { UpdateAdStatus, UpdateJobStatus, UpdateQuotationStatus } from "Services/API/Put";
import { t } from "i18next";
import moment from "moment";
import UserShortList from "Constant/ShortList";
import WishContext from "Context/WishContext";


const MyJobs = (props) => {


    const { applied, setapplied, short, setshort } = useContext(WishContext);
    
    const router = useHistory();

    const [images, setImages] = useState([]);
    const [showModalQuickView, setShowModalQuickView] = useState(false);

    const [showModalQuickView2, setShowModalQuickView2] = useState(false);

    const [jobs, setjobs] = useState([]);

    const [search, setsearch] = useState("");
    const [category, setcategory] = useState("");

    const [selectedJob, setselectedJob] = useState("");

    const [currUser, setcurrUser] = useState(props.currUser);

    const CATEG = [{
        value: "Seasonal",
        name: "Seasonal"
    },
    {
        value: "Casual",
        name: "Casual"
    },
    {
        value: "Temporary",
        name: "Temporary"
    },
    {
        value: "Fixed",
        name: "Fixed"
    },
    {
        value: "Part Time",
        name: "Permanent – Part Time"
    },
    {
        value: "Full Time",
        name: "Permanent – Full time"
    },


    ]

    const getAllJobs = () => {
        console.log(">>>>>>sdsxcddf")
        getShortList({
            userId: GetUserId()
        })
            .then((res) => {
                console.log(">>>>>>>jobs", res.data);
                setjobs(res.data);
            })
            .catch((error) => {
                // setlopen(false);
                setjobs([]);
                console.log("Error", error.response);
                addToast(error.response.data.message, "warning");
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            })
    }

    useEffect(() => {
        getAllJobs();
    }, [showModalQuickView, search, category])




    return (
        <>
            <div className="flex justify-center w-full mt-12">

                <span className="block text-3xl font-bold flex justify-center w-full text-center">
                    Shortlist
                </span>


            </div>
            <div className="w-full flex justify-center px-3 sm:mb-28 xl:mb-60">

                <div className="w-full md:w-8/12 lg:w-7/12 xl:w-[45%]">

                    <div className="hidden flex w-full items-center justify-center">
                        <button
                            className={
                                `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-blueF
        w-full h-9 text-slate-50 font-medium mt-12 `}

                            onClick={() => {
                                // if (currUser?.packageId) {
                                //     setselectedJob("");
                                //     setShowModalQuickView(true)
                                // } else {
                                //     router.push("/subscription")
                                // }

                                setselectedJob("");
                                setShowModalQuickView(true)
                            }}
                        >

                            <span className="block  text-sm font-medium "
                            >
                                Create Job
                            </span>

                        </button>
                    </div>

                    {/* <form className="mt-6 w-full">

                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-jobs-none">
                                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                            </div>
                            <Input type="search" id="default-search" className="block w-full p-4 pl-10 text-base text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Title..." required
                                onChange={(e) => {
                                    setsearch(e.target.value);
                                }}
                            />

                        </div>
                    </form> */}

                    {/* <div className="w-full mt-4 space-y-4">

                        <div>
                            <Label className="text-sm">Category</Label>
                            <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                // defaultValue={{ value: category }}
                                value={{ value: category, name: CATEG.find((v) => v.value === category) ? CATEG.find((v) => v.value === category).name : "All" }}

                                // id="combo-box-demo"
                                options={[{ value: "", name: "All" }, ...CATEG]}
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => {
                                    setcategory(value.value);
                                    // seterrjobType(false);

                                    // setCountry(value);
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Select a category" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            />
                          
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>

                       

                    </div> */}
                    <div className="mt-10 space-y-2">
                        {
                            [1].map((item, index) => {
                                return (
                                    // <div className="flex">
                                    //     <div className="bg-gray-100 flex w-full px-4 py-3 rounded-lg space-x-4 cursor-pointer"

                                    //     >

                                    //         <div className="flex justify-start w-full items-center"
                                    //             onClick={() => {
                                    //                 console.log("item>>>>", item)
                                    //                 setselectedJob(item);
                                    //                 setShowModalQuickView2(true);

                                    //             }}
                                    //         >
                                    //             <div>
                                    //                 <div className="text-sm font-semibold flex justify-start items-center">Ad Id : {item.id}</div>

                                    //                 <div className="text-base font-medium flex justify-start items-center">{item.title}</div>




                                    //             </div>
                                    //         </div>

                                    //         <div className="w-6/12 flex justify-start items-center"
                                    //             onClick={() => {
                                    //                 console.log("item>>>>", item)
                                    //                 setselectedJob(item);
                                    //                 setShowModalQuickView2(true);

                                    //             }}
                                    //         >
                                    //             <div>
                                    //                 <div className="text-base font-medium flex justify-start items-center">Status</div>

                                    //                 <div className={`text-sm font-medium flex justify-start items-center ${item?.packageExpire === 1 ? "text-red-600" : "text-slate-900"}`}>{item?.packageExpire === 1 ? "Expired" : item?.isActive === 1 ? "Active" : "In Active"}</div>
                                    //             </div>
                                    //         </div>

                                    //         <div className="w-6/12 flex justify-start items-center"
                                    //             onClick={() => {
                                    //                 console.log("item>>>>", item)
                                    //                 setselectedJob(item);
                                    //                 setShowModalQuickView2(true);

                                    //             }}
                                    //         >
                                    //             <div>
                                    //                 <div className="text-base font-medium flex justify-start items-center">Applicants</div>

                                    //                 <div className="text-sm font-medium flex justify-start items-center">{item?.applicantsCount}</div>
                                    //             </div>
                                    //         </div>

                                    //         <div className="w-6/12 flex justify-start items-center">
                                    //             <label class="relative inline-flex items-center cursor-pointer mt-3">
                                    //                 <input type="checkbox" class="sr-only peer"
                                    //                     onChange={(e) => {
                                    //                         console.log(e.target.checked)
                                    //                         // setstatus(e.target.checked)


                                    //                         let obj = {
                                    //                             isActive: e.target.checked ? 1 : 0
                                    //                         }

                                    //                         UpdateAdStatus(obj, item?.id)
                                    //                             .then((res) => {
                                    //                                 getAllJobs();
                                    //                             })
                                    //                             .catch((error) => {


                                    //                                 addToast(error.response.data.message, "warning");
                                    //                                 // setOpenToast(true);
                                    //                                 // setColor(COLORS.Orange);
                                    //                                 // setAlertClass("error");
                                    //                                 // setMsg(error.response.data.message);
                                    //                             })


                                    //                     }}
                                    //                     checked={item?.isActive === 1}
                                    //                     id="product-status-active"

                                    //                 />
                                    //                 <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blueF"></div>
                                    //                 <label class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer" htmlFor="product-status-active">Active</label>
                                    //             </label>
                                    //         </div>

                                    //         <div className="flex items-center">
                                    //             <Tooltip title={"View Applicants"} placement="top" TransitionComponent={Zoom} arrow >

                                    //                 <i className="las la-eye text-xl text-left ml-2 cursor-pointer"
                                    //                     onClick={() => {
                                    //                         console.log("item>>>>", item)
                                    //                         setselectedJob(item);
                                    //                         setShowModalQuickView2(true);

                                    //                     }}
                                    //                 ></i>
                                    //             </Tooltip>
                                    //             {
                                    //                 // item.packageExpire === 0 &&

                                    //                 <Tooltip title={item.packageExpire === 0 ? "Edit" : "View"} placement="top" TransitionComponent={Zoom} arrow >

                                    //                     <i className={`${item.packageExpire === 0 ? "las la-pencil-alt" : "las la-binoculars"}  text-xl text-left ml-2 cursor-pointer`}

                                    //                         onClick={() => {
                                    //                             if (item.packageExpire === 0) {
                                    //                                 console.log("item>>>>", item)
                                    //                                 setselectedJob(item);
                                    //                                 setShowModalQuickView(true);
                                    //                             }

                                    //                         }}
                                    //                     ></i>
                                    //                 </Tooltip>
                                    //             }

                                    //             <Tooltip title={"Delete"} placement="top" TransitionComponent={Zoom} arrow >

                                    //                 <i className="las la-trash-alt text-xl text-left ml-2 text-red-500 cursor-pointer"
                                    //                     onClick={() => {
                                    //                         DeleteAd(item?.id)
                                    //                             .then((res) => {

                                    //                                 addToast("Ad successfully deleted", "success");
                                    //                                 getAllJobs();
                                    //                             })
                                    //                             .catch((error) => {
                                    //                                 // setlopen(false);
                                    //                                 console.log("Error", error.response);
                                    //                                 addToast(error.response.data.message, "warning");
                                    //                                 // setOpenToast(true);
                                    //                                 // setColor(COLORS.Orange);
                                    //                                 // setAlertClass("error");
                                    //                                 // setMsg(error.response.data.message);
                                    //                             })
                                    //                     }}
                                    //                 ></i>

                                    //             </Tooltip>
                                    //         </div>


                                    //     </div>


                                    // </div>

                                    <div className="w-full">
                                        <div className="mt-10 space-y-0 overflow-x-auto">

                                            {


                                                <>

                                                    {
                                                        false &&
                                                        <>
                                                            <div className="flex ">
                                                                <div className="bg-gray-100 flex w-full px-4 py-3 rounded-lg space-x-4 ">



                                                                    <div className="w-5/12 flex justify-start items-center">
                                                                        <div>
                                                                            <div className="text-base font-bold flex justify-start items-center">Ad Id</div>


                                                                            {/* <div className="text-sm font-medium flex justify-start items-center">{moment(item?.createdAt).format("YYYY-MM-DD HH:MM:SS")}</div> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-5/12 flex justify-start items-center">
                                                                        <div>
                                                                            <div className="text-base font-bold flex justify-start items-center">Customer</div>


                                                                            {/* <div className="text-sm font-medium flex justify-start items-center">{moment(item?.createdAt).format("YYYY-MM-DD HH:MM:SS")}</div> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-7/12 flex justify-start items-center">
                                                                        <div>
                                                                            <div className="text-base font-bold flex justify-start items-center">Email</div>


                                                                            {/* <div className="text-sm font-medium flex justify-start items-center">{moment(item?.createdAt).format("YYYY-MM-DD HH:MM:SS")}</div> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-5/12 flex justify-start items-center">
                                                                        <div>
                                                                            <div className="text-base font-bold flex justify-start items-center">Phone</div>


                                                                            {/* <div className="text-sm font-medium flex justify-start items-center">{moment(item?.createdAt).format("YYYY-MM-DD HH:MM:SS")}</div> */}
                                                                        </div>
                                                                    </div>

                                                                    {/* <div className="w-6/12 flex justify-start items-center">
                                                            <div>
                                                                <div className="text-base font-bold flex justify-start items-center">Cleaning Types</div>
            
                                                                <div className="text-sm font-medium flex justify-start items-center">{item?.orderNo}</div>
                                                            </div>
                                                        </div> */}

                                                                    <div className="w-6/12 flex justify-start items-center">
                                                                        <div>
                                                                            <div className="text-base font-bold flex justify-start items-center">Description</div>

                                                                            {/* <div className="text-sm font-medium flex justify-start items-center">{item?.orderNo}</div> */}
                                                                        </div>
                                                                    </div>

                                                                    <div className="w-5/12 flex justify-start items-center">
                                                                        <div>
                                                                            <div className="text-base font-bold flex justify-start items-center">Status</div>

                                                                            {/* <div className="text-sm font-medium flex justify-start items-center">{item?.orderNo}</div> */}
                                                                        </div>
                                                                    </div>

                                                                    {/* <div className="w-full flex justify-start items-center">
                                                            <div>
                                                                <div className="text-base font-bold flex justify-start items-center">Customer</div>
            
            
                                                            </div>
                                                        </div> */}

                                                                    {/* <div className="w-4/12 flex justify-start items-center">
                                                            <div>
                                                                <div className="text-base font-bold flex justify-start items-center">Amount</div>
            
                                                            </div>
                                                        </div>
                                                        <div className="w-11/12 flex justify-start items-center">
                                                            <div>
                                                                <div className="text-base font-bold flex justify-start items-center">Status</div>
            
                                                            </div>
            
            
                                                        </div> */}


                                                                </div>





                                                            </div>

                                                            <div className="flex items-center">

                                                                <i className="las la-trash-alt text-xl text-left ml-2 text-transparent"
                                                                    onClick={() => {
                                                                        // DeleteRecieved(item?.id)
                                                                        //     .then((res) => {

                                                                        //         addToast("Quotation successfully deleted", "success");
                                                                        //         getAlljobs();
                                                                        //     })
                                                                        //     .catch((error) => {
                                                                        //         // setlopen(false);
                                                                        //         console.log("Error", error.response);
                                                                        //         addToast(error.response.data.message, "warning");
                                                                        //         // setOpenToast(true);
                                                                        //         // setColor(COLORS.Orange);
                                                                        //         // setAlertClass("error");
                                                                        //         // setMsg(error.response.data.message);
                                                                        //     })
                                                                    }}
                                                                ></i>
                                                            </div>
                                                        </>

                                                    }



                                                    {
                                                        [1].map((item, index) => {
                                                            return (
                                                                <>
                                                                    <div className="flex ">
                                                                        <div className={`w-full  space-y-2 overflow-x-auto xl:overflow-hidden ${((jobs.length - 1) === index) ? "rounded-b-lg" : ""} ${(index === 0) ? "rounded-t-lg" : ""}`}>

                                                                            {
                                                                                index === 0 &&

                                                                                <div className=" flex px-4 py-3 space-x-4 bg-blueF text-white cursor-pointer">
                                                                                    <div className="w-2/12 flex justify-start items-center">
                                                                                        <div>
                                                                                            <div className="w-[70px] text-sm font-medium flex justify-start items-center">Seeker Id</div>


                                                                                            {/* <div className="text-sm font-medium flex justify-start items-center">{moment(item?.createdAt).format("YYYY-MM-DD HH:MM:SS")}</div> */}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-2/12 flex justify-start items-center">
                                                                                        <div>
                                                                                            <div className={`w-[80px] text-sm font-medium flex justify-start items-center ${false ? "text-red-600" : ""}`}>Name</div>


                                                                                            {/* <div className="text-sm font-medium flex justify-start items-center">{moment(item?.createdAt).format("YYYY-MM-DD HH:MM:SS")}</div> */}
                                                                                        </div>
                                                                                    </div>


                                                                                    <div className="w-2/12 flex justify-start items-center"
                                                                                        onClick={() => {
                                                                                            console.log("item>>>>", item)
                                                                                            // setselectedJob(item);
                                                                                            // setShowModalQuickView2(true);

                                                                                        }}
                                                                                    >
                                                                                        <div>



                                                                                            <div className="w-[60px] text-sm font-medium flex justify-start items-center">Contact</div>


                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-4/12 flex justify-start items-center">
                                                                                        <div>
                                                                                            <div className={`w-full text-sm font-medium flex justify-start items-center whitespace-nowrap`}>Email</div>


                                                                                            {/* <div className="text-sm font-medium flex justify-start items-center">{moment(item?.createdAt).format("YYYY-MM-DD HH:MM:SS")}</div> */}
                                                                                        </div>
                                                                                    </div>


                                                                                    <div className="flex items-center invisible">
                                                                                        {/* <Tooltip title={"View Applicants"} placement="top" TransitionComponent={Zoom} arrow >

                                                                                        <i className="las la-eye text-xl text-left ml-2 cursor-pointer"
                                                                                            onClick={() => {
                                                                                                console.log("item>>>>", item)
                                                                                                setselectedJob(item);
                                                                                                setShowModalQuickView2(true);

                                                                                            }}
                                                                                        ></i>
                                                                                    </Tooltip> */}
                                                                                        {
                                                                                            // item.packageExpire === 0 &&

                                                                                            <Tooltip title={item.packageExpire === 0 ? "Edit" : "View"} placement="top" TransitionComponent={Zoom} arrow >

                                                                                                <i className={`${item.packageExpire === 0 ? "las la-pencil-alt" : "las la-binoculars"}  text-xl text-left ml-2 cursor-pointer`}

                                                                                                    onClick={() => {
                                                                                                        if (item.packageExpire === 0) {
                                                                                                            console.log("item>>>>", item)
                                                                                                            setselectedJob(item);
                                                                                                            setShowModalQuickView(true);
                                                                                                        }

                                                                                                    }}
                                                                                                ></i>
                                                                                            </Tooltip>
                                                                                        }

                                                                                        <Tooltip title={"Delete"} placement="top" TransitionComponent={Zoom} arrow >

                                                                                            <i className="las la-trash-alt text-xl text-left ml-2 text-red-500 cursor-pointer"
                                                                                                onClick={() => {
                                                                                                    DeleteShortlist(item?.id)
                                                                                                        .then((res) => {

                                                                                                            addToast("Successfully removed from shortlist", "success");
                                                                                                            getAllJobs();

                                                                                                            UserShortList().then((res) => {
                                                                                                                console.log("short res-------->", res);
                                                                                                                setshort(res);

                                                                                                            });
                                                                                                        })
                                                                                                        .catch((error) => {
                                                                                                            // setlopen(false);
                                                                                                            console.log("Error", error.response);
                                                                                                            addToast(error.response.data.message, "warning");
                                                                                                            // setOpenToast(true);
                                                                                                            // setColor(COLORS.Orange);
                                                                                                            // setAlertClass("error");
                                                                                                            // setMsg(error.response.data.message);
                                                                                                        })
                                                                                                }}
                                                                                            ></i>

                                                                                        </Tooltip>
                                                                                    </div>
                                                                                    {/* <div className="w-6/12 flex justify-start items-center">
                                                            <div>

                                                                <div className="text-sm font-medium flex justify-start items-center">{item?.cleaningTypes}</div>

                                                            </div>
                                                        </div> */}

                                                                                    {/* <div className="w-6/12 flex justify-start items-center">
                                                                                <div>


                                                                                    <div className="min-w-[100px] sm:w-full text-sm font-normal flex justify-start items-center">{item?.description}</div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="w-5/12 flex justify-start items-center">
                                                                                <div className="flex">



                                                                                    <label class="relative inline-flex items-center cursor-pointer mt-3">
                                                                                        <input type="checkbox" class="sr-only peer"
                                                                                            onChange={(e) => {
                                                                                                console.log(e.target.checked)
                                                                                                // setstatus(e.target.checked)

                                                                                                let obj = {
                                                                                                    IsQuotatiionSent: e.target.checked ? 1 : 0
                                                                                                }

                                                                                                UpdateQuotationStatus(obj, item?.id)
                                                                                                    .then((res) => {
                                                                                                        getAlljobs();
                                                                                                    })
                                                                                                    .catch((error) => {


                                                                                                        addToast(error.response.data.message, "warning");
                                                                                                        // setOpenToast(true);
                                                                                                        // setColor(COLORS.Orange);
                                                                                                        // setAlertClass("error");
                                                                                                        // setMsg(error.response.data.message);
                                                                                                    })


                                                                                            }}
                                                                                            checked={item?.IsQuotatiionSent === 1}
                                                                                            id="product-status-active"

                                                                                        />
                                                                                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blueF"></div>
                                                                                        <label class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer" htmlFor="product-status-active">Complete</label>

                                                                                    </label>


                                                                                    <div className="flex items-center mt-2 ml-3">

                                                                                        <i className="las la-trash-alt text-xl text-left ml-2 text-red-500 cursor-pointer"
                                                                                            onClick={() => {
                                                                                                DeleteRecieved(item?.id)
                                                                                                    .then((res) => {

                                                                                                        addToast("Quotation successfully deleted", "success");
                                                                                                        getAlljobs();
                                                                                                    })
                                                                                                    .catch((error) => {
                                                                                                        // setlopen(false);
                                                                                                        console.log("Error", error.response);
                                                                                                        addToast(error.response.data.message, "warning");
                                                                                                        // setOpenToast(true);
                                                                                                        // setColor(COLORS.Orange);
                                                                                                        // setAlertClass("error");
                                                                                                        // setMsg(error.response.data.message);
                                                                                                    })
                                                                                            }}
                                                                                        ></i>
                                                                                    </div>


                                                                                </div>
                                                                            </div> */}



                                                                                </div >


                                                                            }
                                                                            {/* <div className="flex justify-start w-3/12 items-center">
            
                                                                        {
                                                                            [1,].map((data, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <div className="w-12 h-12 object-fill bg-contain rounded-full border-2 border-white"
                                                                                                style={{
                                                                                                    backgroundImage: `url(${IMAGE_SERVER_URL_DEV}${item?.avatar})`
                                                                                                }}
                                                                                            >
            
                                                                                            </div>
                                                                                    </>)
                                                                            })
                                                                        }
            
            
                                                                    </div> */}

                                                                            {
                                                                                jobs.length > 0 && jobs.map((item, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <div className=" flex px-4 py-3 space-x-4 bg-gray-100 text-blueF cursor-pointer">
                                                                                                <div className="w-2/12 flex justify-start items-center">
                                                                                                    <div>
                                                                                                        <div className="w-[70px] text-sm font-medium flex justify-start items-center">{item.empId}</div>


                                                                                                        {/* <div className="text-sm font-medium flex justify-start items-center">{moment(item?.createdAt).format("YYYY-MM-DD HH:MM:SS")}</div> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="w-2/12 flex justify-start items-center">
                                                                                                    <div>
                                                                                                        <div className={`w-[80px] text-sm font-medium flex justify-start items-center ${false ? "text-red-600" : ""}`}>{item?.firstMiddleName + " " + item?.surName}</div>


                                                                                                        {/* <div className="text-sm font-medium flex justify-start items-center">{moment(item?.createdAt).format("YYYY-MM-DD HH:MM:SS")}</div> */}
                                                                                                    </div>
                                                                                                </div>


                                                                                                <div className="w-2/12 flex justify-start items-center"
                                                                                                    onClick={() => {
                                                                                                        console.log("item>>>>", item)
                                                                                                        // setselectedJob(item);
                                                                                                        // setShowModalQuickView2(true);

                                                                                                    }}
                                                                                                >
                                                                                                    <div>



                                                                                                        <div className="w-[60px] text-sm font-medium flex justify-start items-center">{item?.mobileNumber}</div>


                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="w-4/12 flex justify-start items-center">
                                                                                                    <div>
                                                                                                        <div className={`w-full text-sm font-medium flex justify-start items-center`}>
                                                                                                            {/* {moment(item?.expireDate).format("YYYY-MM-DD")} */}

                                                                                                            {item?.email}

                                                                                                        </div>


                                                                                                        {/* <div className="text-sm font-medium flex justify-start items-center">{moment(item?.createdAt).format("YYYY-MM-DD HH:MM:SS")}</div> */}
                                                                                                    </div>
                                                                                                </div>

                                                                                                {/* <div className="w-6/12 flex justify-start items-center">
                                                                                                    <label class="relative inline-flex items-center cursor-pointer mt-3">
                                                                                                        <input type="checkbox" class="sr-only peer"
                                                                                                            onChange={(e) => {
                                                                                                                console.log(e.target.checked)
                                                                                                                // setstatus(e.target.checked)


                                                                                                                let obj = {
                                                                                                                    isActive: e.target.checked ? 1 : 0
                                                                                                                }

                                                                                                                UpdateAdStatus(obj, item?.id)
                                                                                                                    .then((res) => {
                                                                                                                        getAllJobs();
                                                                                                                    })
                                                                                                                    .catch((error) => {


                                                                                                                        addToast(error.response.data.message, "warning");
                                                                                                                        // setOpenToast(true);
                                                                                                                        // setColor(COLORS.Orange);
                                                                                                                        // setAlertClass("error");
                                                                                                                        // setMsg(error.response.data.message);
                                                                                                                    })


                                                                                                            }}
                                                                                                            checked={item?.isActive === 1}
                                                                                                            id="product-status-active"

                                                                                                        />
                                                                                                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blueF"></div>
                                                                                                        <label class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer" htmlFor="product-status-active">Active</label>
                                                                                                    </label>
                                                                                                </div> */}
                                                                                                <div className="flex items-center">
                                                                                                    {/* <Tooltip title={"View Applicants"} placement="top" TransitionComponent={Zoom} arrow >

                                                                                                        <i className="las la-eye text-xl text-left ml-2 cursor-pointer"
                                                                                                            onClick={() => {
                                                                                                                console.log("item>>>>", item)
                                                                                                                setselectedJob(item);
                                                                                                                setShowModalQuickView2(true);

                                                                                                            }}
                                                                                                        ></i>
                                                                                                    </Tooltip> */}
                                                                                                    {
                                                                                                        // item.packageExpire === 0 &&

                                                                                                        <Tooltip title={item.packageExpire === 0 ? "Edit" : "View"} placement="top" TransitionComponent={Zoom} arrow >

                                                                                                            <i className={`${item.packageExpire === 0 ? "las la-pencil-alt" : "las la-binoculars"}  text-xl text-left ml-2 cursor-pointer`}

                                                                                                                onClick={() => {
                                                                                                                    // if (item.packageExpire === 0) {
                                                                                                                    console.log("item>>>>", item)
                                                                                                                    setselectedJob(item);
                                                                                                                    setShowModalQuickView(true);
                                                                                                                    // }

                                                                                                                }}
                                                                                                            ></i>
                                                                                                        </Tooltip>
                                                                                                    }

                                                                                                    <Tooltip title={"Delete"} placement="top" TransitionComponent={Zoom} arrow >

                                                                                                        <i className="las la-trash-alt text-xl text-left ml-2 text-red-500 cursor-pointer"
                                                                                                            onClick={() => {
                                                                                                                DeleteShortlist(item?.id)
                                                                                                                    .then((res) => {

                                                                                                                        addToast("Successfully removed from shortlist", "success");
                                                                                                                        getAllJobs();

                                                                                                                        UserShortList().then((res) => {
                                                                                                                            console.log("short res-------->", res);
                                                                                                                            setshort(res);

                                                                                                                        });
                                                                                                                    })
                                                                                                                    .catch((error) => {
                                                                                                                        // setlopen(false);
                                                                                                                        console.log("Error", error.response);
                                                                                                                        addToast(error.response.data.message, "warning");
                                                                                                                        // setOpenToast(true);
                                                                                                                        // setColor(COLORS.Orange);
                                                                                                                        // setAlertClass("error");
                                                                                                                        // setMsg(error.response.data.message);
                                                                                                                    })
                                                                                                            }}
                                                                                                        ></i>

                                                                                                    </Tooltip>
                                                                                                </div>
                                                                                                {/* <div className="w-6/12 flex justify-start items-center">
                                                                            <div>
            
                                                                                <div className="text-sm font-medium flex justify-start items-center">{item?.cleaningTypes}</div>
            
                                                                            </div>
                                                                        </div> */}

                                                                                                {/* <div className="w-6/12 flex justify-start items-center">
                                                                                                <div>
            
            
                                                                                                    <div className="min-w-[100px] sm:w-full text-sm font-normal flex justify-start items-center">{item?.description}</div>
                                                                                                </div>
                                                                                            </div>
            
                                                                                            <div className="w-5/12 flex justify-start items-center">
                                                                                                <div className="flex">
            
            
            
                                                                                                    <label class="relative inline-flex items-center cursor-pointer mt-3">
                                                                                                        <input type="checkbox" class="sr-only peer"
                                                                                                            onChange={(e) => {
                                                                                                                console.log(e.target.checked)
                                                                                                                // setstatus(e.target.checked)
            
                                                                                                                let obj = {
                                                                                                                    IsQuotatiionSent: e.target.checked ? 1 : 0
                                                                                                                }
            
                                                                                                                UpdateQuotationStatus(obj, item?.id)
                                                                                                                    .then((res) => {
                                                                                                                        getAlljobs();
                                                                                                                    })
                                                                                                                    .catch((error) => {
            
            
                                                                                                                        addToast(error.response.data.message, "warning");
                                                                                                                        // setOpenToast(true);
                                                                                                                        // setColor(COLORS.Orange);
                                                                                                                        // setAlertClass("error");
                                                                                                                        // setMsg(error.response.data.message);
                                                                                                                    })
            
            
                                                                                                            }}
                                                                                                            checked={item?.IsQuotatiionSent === 1}
                                                                                                            id="product-status-active"
            
                                                                                                        />
                                                                                                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blueF"></div>
                                                                                                        <label class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer" htmlFor="product-status-active">Complete</label>
            
                                                                                                    </label>
            
            
                                                                                                    <div className="flex items-center mt-2 ml-3">
            
                                                                                                        <i className="las la-trash-alt text-xl text-left ml-2 text-red-500 cursor-pointer"
                                                                                                            onClick={() => {
                                                                                                                DeleteRecieved(item?.id)
                                                                                                                    .then((res) => {
            
                                                                                                                        addToast("Quotation successfully deleted", "success");
                                                                                                                        getAlljobs();
                                                                                                                    })
                                                                                                                    .catch((error) => {
                                                                                                                        // setlopen(false);
                                                                                                                        console.log("Error", error.response);
                                                                                                                        addToast(error.response.data.message, "warning");
                                                                                                                        // setOpenToast(true);
                                                                                                                        // setColor(COLORS.Orange);
                                                                                                                        // setAlertClass("error");
                                                                                                                        // setMsg(error.response.data.message);
                                                                                                                    })
                                                                                                            }}
                                                                                                        ></i>
                                                                                                    </div>
            
            
                                                                                                </div>
                                                                                            </div> */}



                                                                                            </div >
                                                                                        </>
                                                                                    );
                                                                                })
                                                                            }









                                                                        </div>
                                                                    </div >
                                                                </>
                                                            );
                                                        })
                                                    }
                                                </>


                                            }


                                            {jobs.length === 0 &&


                                                <>
                                                    <div className="flex !mt-20 justify-center items-center">
                                                        <p className="text-xl font-semibold">
                                                            {/* #WU3746HGG12 */}
                                                            Empty
                                                        </p>

                                                    </div>
                                                    {/* <div className="flex  justify-center items-center">
            
                                                    <NavLink to={"/service-providers"}>
                                                        <ButtonPrimary>Shop Now</ButtonPrimary>
                                                    </NavLink>
                                                </div> */}

                                                </>

                                            }

                                        </div>

                                    </div>
                                );
                            })
                        }

                    </div>

                </div>
            </div >

            <ModalQuickView
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
                selectedJob={selectedJob}
                shortlist={true}
            />

            <ModalQuickView2
                show={showModalQuickView2}
                onCloseModalQuickView={() => setShowModalQuickView2(false)}
                selectedJob={selectedJob}

            />

        </>
    )

}

export default MyJobs;