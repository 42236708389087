import React, { FC, useState, useEffect, useRef, useContext } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL, IMAGE_SERVER_URL_DEV } from "Constant/System";
import Company1 from "images/avatars/Image-1.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
// import ProductCard from "components/ProductCardNew";
import { getCleanT, getWishlistList } from "Services/API/Get";
import WishContext from "Context/WishContext";
import CleanCard from "components/CardCategories/CleanCard";


const ShopClean = (props) => {

    const userId = props?.userId

    const currUser = props?.currUser

    const Img = currUser?.serviceProviderImages ? JSON.parse(currUser?.serviceProviderImages) : []

    console.log(">>>>>>>>", currUser?.serviceProvierVideos.replace("watch?v=", 'embed/'))

    const [images, setImages] = useState([]);

    const [array, setArray] = useState([]);

    const [lopen, setlopen] = useState(false);


    const [limit, setlimit] = useState(6);

    const { wish, setWish } = useContext(WishContext);

    React.useEffect(() => {
        // getWish();

    }, [wish, limit])

    const getWish = () => {
        setlopen(true);
        getCleanT({ userId: userId })
            .then((res) => {
                setlopen(false);
                console.log("get Wish page>>>>>", res)

                setArray(res.data);
                // setCart(res.data.length);

                // this.setState({
                //   array: res.data,
                //   total: m,
                // });
            })
            .catch((error) => {
                console.error(error);
                setlopen(false);
                setArray([]);
                // setCart(0);
            });

    }



    return (
        <>
            <div className="flex justify-center w-full mt-12">

                {/* <span className="block text-xl font-bold flex justify-center w-full text-center">
                    List of saved products
                </span> */}


            </div>
            <div className="w-full flex justify-center px-3">

                <div className="w-full md:w-10/12 lg:w-10/12 xl:w-8/12">


                    {
                        currUser?.serviceProvierVideos &&

                        <iframe
                            src={currUser?.serviceProvierVideos?.replace("watch?v=", 'embed/')}
                            className="relative w-full h-[300px] border-none block"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            frameBorder="0"
                            title="Embedded youtube"
                            allowFullScreen

                        ></iframe>
                    }



                    {
                        Img.length > 0 ?

                            <>
                                <div className="flex justify-center w-full">
                                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4 mt-10 ">

                                        {Img.map((stay) => (
                                            // <CleanCard data={stay} />
                                            <div className="w-full flex overflow-hidden rounded-xl bg-slate-100">
                                                <img
                                                    src={

                                                        `${IMAGE_SERVER_URL_DEV}${stay.path}`


                                                    }
                                                    alt={"gallery"}
                                                    className="h-full w-full object-cover object-center"
                                                />
                                            </div>
                                        ))}

                                    </div>
                                </div>
                                {/* <div className="flex !mt-20 justify-center items-center">
                                    <ButtonSecondary
                                        onClick={() => setlimit(limit + 3)}
                                        loading={lopen}
                                    >Show me more</ButtonSecondary>
                                </div> */}

                            </>

                            :
                            <>

                            </>

                    }

                </div>
            </div>

        </>
    )

}

export default ShopClean;