import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import { StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { PRODUCTS } from "data/data";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import NotifyAddTocart from "./NotifyAddTocart";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import Label from "./Label/Label";
import Select from "shared/Select/Select";
import { IMAGE_SERVER_URL, IMAGE_SERVER_URL_DEV } from "Constant/System";
import { Autocomplete } from "@mui/material";
import { GetUserId, getCities, getDistricts } from "Services/API/Get";
import _, { upperCase } from "lodash";
import { addToast } from "shared/Toast";
import { postEvent, postJobs } from "Services/API/Post";
import { UpdateEvents } from "Services/API/Put";

const CoverImg = "https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"


export interface ProductQuickView2Props {
  className?: string;
  selectedEvent?: any;
  onCloseModalQuickView?: any;
}

const ProductQuickView2: FC<ProductQuickView2Props> = ({ className = "", selectedEvent, onCloseModalQuickView }) => {
  const { sizes, variants, status, allOfSizes } = PRODUCTS[0];
  const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];

  const [variantActive, setVariantActive] = React.useState(0);
  const [sizeSelected, setSizeSelected] = React.useState(sizes ? sizes[0] : "");
  const [qualitySelected, setQualitySelected] = React.useState(1);

  const [value, setValue] = React.useState<any>(0);
  const [firstName, setfirstName] = useState("");
  const [errfirstName, seterrfirstName] = useState(false);

  const [images, setImages] = useState(selectedEvent ? JSON.parse(selectedEvent.document) : [])

  const [eventType, seteventType] = useState(selectedEvent ? selectedEvent.eventType : "");
  const [houseType, sethouseType] = useState(selectedEvent ? selectedEvent.hostType : "");
  const [paidType, setpaidType] = useState(selectedEvent ? selectedEvent.charge : "");
  const [homeAddress, sethomeAddress] = useState(selectedEvent ? selectedEvent.homeAddress : "");
  const [homeDistrict, sethomeDistrict] = useState(selectedEvent ? selectedEvent.homeDistrict : "");
  const [homeCity, sethomeCity] = useState(selectedEvent ? selectedEvent.homeCity : "");
  const [homepostCode, sethomepostCode] = useState(selectedEvent ? selectedEvent.homepostCode : "");
  const [title, settitle] = useState(selectedEvent ? selectedEvent.title : "");
  const [description, setdescription] = useState(selectedEvent ? selectedEvent.description : "");
  const [startDate, setStartDate] = useState(selectedEvent ? selectedEvent.startDate : "");
  const [startTime, setstartTime] = useState(selectedEvent ? selectedEvent.startTime : "");
  const [expireDate, setexpireDate] = useState(selectedEvent ? selectedEvent.endDate : "");
  const [expireTime, setexpireTime] = useState(selectedEvent ? selectedEvent.endTime : "");
  const [currency, setcurrency] = useState(selectedEvent ? selectedEvent.ticketCurrency : "");
  const [rate, setrate] = useState(selectedEvent ? selectedEvent.ticketPrice : "");
  const [keywords, setkeywords] = useState(selectedEvent ? JSON.parse(selectedEvent?.keywords).join(" ") : "");

  const [coverImage, setcoverImage] = useState(selectedEvent ? selectedEvent?.banner : "");



  const [errhomeDistrict, seterrhomeDistrict] = useState(false);
  const [errhomeCity, seterrhomeCity] = useState(false);
  const [errhomepostCode, seterrhomepostCode] = useState(false);
  const [errhomeAddress, seterrhomeAddress] = useState(false);
  const [erreventType, seterreventType] = useState(false);
  const [errhouseType, seterrhouseType] = useState(false);
  const [errpaidType, seterrpaidType] = useState(false);
  const [errtitle, seterrtitle] = useState(false);
  const [errdescription, seterrdescription] = useState(false);
  const [errstartDate, seterrStartDate] = useState(false);
  const [errstartTime, seterrstartTime] = useState(false);
  const [errexpireDate, seterrexpireDate] = useState(false);
  const [errexpireTime, seterrexpireTime] = useState(false);
  const [errcurrency, seterrcurrency] = useState(false);
  const [errrate, seterrrate] = useState(false);
  const [errkeywords, seterrkeywords] = useState(false);

  const [errcoverImage, seterrcoverImage] = useState("");

  const [homeDistrictId, sethomeDistrictId] = useState(0);
  const [allHCities, setallHCities] = useState([]);
  const [allHDistricts, setallHDistricts] = useState([]);

  const notifyAddTocart = () => {
    toast.custom(
      (t) => (
        <NotifyAddTocart
          productImage={LIST_IMAGES_DEMO[0]}
          qualitySelected={qualitySelected}
          show={t.visible}
          sizeSelected={sizeSelected}
          variantActive={variantActive}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };


  React.useEffect(() => {
    listDistricts();

  }, []);

  React.useEffect(() => {
    if (homeDistrictId !== 0) {
      listHomecities();
    }
  }, [homeDistrictId]);

  const listDistricts = () => {
    getDistricts()
      .then((res) => {
        console.log("districts>>>", res);
        setallHDistricts(res.data);
        // setallODistricts(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };
  const listHomecities = () => {
    getCities(homeDistrict)
      .then((res) => {
        console.log("cities>>>", res);
        setallHCities(res.data.records);
      })
      .catch((error?: any) => {
        console.log("Error", error.response);
        setallHCities([])
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const handleChangeHdist = (e?: any, value?: any) => {
    if (value !== null) {
      sethomeDistrict(value.name_en);
      seterrhomeDistrict(false);
      sethomeDistrictId(value.id);
    } else {
      sethomeDistrict("");
      sethomeDistrictId(1);
    }

    console.log("state>>", value);
  };
  const handleChangeHcity = (e?: any, value?: any) => {
    if (value !== null) {
      sethomeCity(value.fields.scc_name);
      sethomepostCode(value.fields.scc_code);
      seterrhomeCity(false);
      seterrhomepostCode(false);
    } else {
      sethomeCity("");
      sethomepostCode("");

    }

    console.log("city>>", value);
  };

  const saveEvent = (e?: any) => {

    e.preventDefault();

    if (!title) {
      seterrtitle(true);
    }

    if (!eventType) {
      seterreventType(true);
    }
    if (!houseType) {
      seterrhouseType(true);
    }
    if (!paidType) {
      seterrpaidType(true);
    }
    if (!homeAddress) {
      seterrhomeAddress(true);
    }
    if (!homeDistrict) {
      seterrhomeDistrict(true);
    }
    if (!homeCity) {
      seterrhomeCity(true);
    }
    if (!homepostCode) {
      seterrhomepostCode(true);
    }
    if (!description) {
      seterrdescription(true);
    }
    if (!startDate) {
      seterrStartDate(true);
    }
    if (!startTime) {
      seterrstartTime(true);
    }
    if (!expireDate) {
      seterrexpireDate(true);
    }
    if (!expireTime) {
      seterrexpireTime(true);
    }
    if (!currency || !rate) {
      seterrrate(true);
    }

    // if (!Keywords) {
    //     seterrKeywords(true);
    // }
    // if (images.length === 0) {
    //     seterrImages(true);
    // }

    console.log(">>>>>>>>", _.filter(keywords.split(" ")))

    console.log(">>>>>>", images)

    let obj = {
      title: title,
      description: description,
      keywords: _.filter(keywords.split(" ")),
      userId: GetUserId(),
      document: images,
      eventType: eventType,
      hostType: houseType,
      charge: paidType,
      address: homeAddress,
      state: homeDistrict,
      suburb: homeCity,
      postcode: homepostCode,
      startDate: startDate,
      startTime: startTime,
      endDate: expireDate,
      endTime: expireTime,
      ticketCurrency: currency,
      ticketPrice: rate,
      banner: coverImage,
      country: "",


    }

    console.log("obj>>>>", obj)

    if (
      title &&
      description &&
      eventType &&
      houseType &&
      paidType &&
      homeAddress &&
      homeDistrict &&
      // homeCity &&
      homepostCode &&
      startDate &&
      startTime &&
      expireDate &&
      expireTime &&
      currency &&
      rate


    ) {
      if (!selectedEvent) {
        postEvent(obj)
          .then((res) => {
            // setlopen(false);
            addToast("Event successfully added", "success");
            settitle("");
            seteventType("");
            sethouseType("");
            setpaidType("");
            setdescription("");
            sethomeAddress("");
            sethomeDistrict("");
            sethomeCity("");
            sethomepostCode("");
            setStartDate("");
            setstartTime("");
            setImages([]);
            setkeywords("");
            setexpireTime("");
            setexpireDate("");
            setcurrency("");
            setrate("");
            // setOpenToast(true);
            // setColor(COLORS.Primary);
            // setAlertClass("success");
            // setMsg("Successfully updated");
            // getCurrentUser();
            // seteDisable(true);
            // getUser();

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            // setlopen(false);
            console.log("Error", error.response);
            addToast(error.response.data.message, "warning");
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          })
      } else {

        UpdateEvents(obj, selectedEvent?.id)
          .then((res) => {
            // setlopen(false);
            addToast("Event successfully updated ", "success");
            settitle("");
            seteventType("");
            sethouseType("");
            setpaidType("");
            setdescription("");
            sethomeAddress("");
            sethomeDistrict("");
            sethomeCity("");
            sethomepostCode("");
            setStartDate("");
            setstartTime("");
            setImages([]);
            setkeywords("");
            setexpireTime("");
            setexpireDate("");
            setcurrency("");
            setrate("");
            onCloseModalQuickView();
            // setOpenToast(true);
            // setColor(COLORS.Primary);
            // setAlertClass("success");
            // setMsg("Successfully updated");
            // getCurrentUser();
            // seteDisable(true);
            // getUser();

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            // setlopen(false);
            console.log("Error", error.response);
            addToast(error.response.data.message, "warning");
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          })

      }

    }


  }


  return (
    <div className={`nc-ProductQuickView2 ${className} mt-16 sm:mt-0 md:px-32 py-6`}>
      {/* MAIn */}
      <div className="lg:flex">
        {/* CONTENT */}
        <div className="w-full ">
          {/* HEADING */}

          <div className="w-full flex justify-center px-3">

            <div className="w-full">
              <div className="flex justify-center w-full mt-0">

                <span className="block text-xl font-bold flex justify-center w-full text-center">
                  {
                    selectedEvent ? "Update Event" : "Create Event"
                  }

                </span>


              </div>

              <div className="w-full mt-6 space-y-4">
                <div>
                  <Label className="text-sm">Event Type</Label>
                  <Select className="mt-1.5"
                    onChange={(e) => {
                      seteventType(e.target.value);
                      seterreventType(false);
                    }}
                    value={eventType}
                  >
                    <option value={""}>Select a event type</option>
                    <option value="Expo">Expo</option>
                    <option value="Show">Show</option>
                    <option value="Conference">Conference</option>
                    <option value="Training">Training</option>
                    <option value="Forum">Forum</option>
                    {/* <option value="United States">Canada</option>
    <option value="United States">Mexico</option>
    <option value="United States">Israel</option>
    <option value="United States">France</option>
    <option value="United States">England</option>
    <option value="United States">Laos</option>
    <option value="United States">China</option> */}
                  </Select>
                  {erreventType && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>
                <div>
                  <Label className="text-sm">Online or In-house</Label>
                  <Select className="mt-1.5"
                    onChange={(e) => {
                      sethouseType(e.target.value);
                      seterrhouseType(false);
                    }}
                    value={houseType}
                  >
                    <option value={""}>Select a event type</option>
                    <option value="Online">Online</option>
                    <option value="In-house">In-house</option>
                    {/* <option value="United States">Canada</option>
    <option value="United States">Mexico</option>
    <option value="United States">Israel</option>
    <option value="United States">France</option>
    <option value="United States">England</option>
    <option value="United States">Laos</option>
    <option value="United States">China</option> */}
                  </Select>
                  {errhouseType && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>
                <div>
                  <Label className="text-sm">Free or Paid</Label>
                  <Select className="mt-1.5"
                    onChange={(e) => {
                      setpaidType(e.target.value);
                      seterrpaidType(false);
                    }}
                    value={paidType}
                  >
                    <option value={""}>Select a event type</option>
                    <option value="Free">Free</option>
                    <option value="Paid">Paid</option>
                    {/* <option value="United States">Canada</option>
    <option value="United States">Mexico</option>
    <option value="United States">Israel</option>
    <option value="United States">France</option>
    <option value="United States">England</option>
    <option value="United States">Laos</option>
    <option value="United States">China</option> */}
                  </Select>
                  {errpaidType && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>
                <div>
                  <Label className="text-sm">Location Address</Label>
                  <Input className="mt-1.5"
                    placeholder="Enter address"

                    onChange={(e) => {
                      sethomeAddress(e.target.value);
                      seterrhomeAddress(false);
                    }}
                    value={homeAddress}
                  />
                  {errhomeAddress && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>
                <div>
                  <Label className="text-sm">State</Label>


                  <Autocomplete
                    className="mt-1.5"
                    // className="form-group"
                    // className="border-neutral-200"
                    disablePortal
                    defaultValue={{ name_en: homeDistrict }}
                    value={{ name_en: homeDistrict }}

                    // id="combo-box-demo"
                    options={allHDistricts}
                    getOptionLabel={(option?: any) => option.name_en}
                    onChange={(event?: any, value?: any) => {

                      handleChangeHdist(event, value);
                      // setCountry(value);
                    }}
                    // sx={{
                    //   // width: "80vw",
                    //   background:"transparent",
                    //   borderRadius: "20px",
                    //   // border: "none",
                    //   // borderColor: "transparent",

                    //   "& .MuiInputBase-input": {
                    //     height: 15,
                    //     // border: "none",
                    //     fontSize: "14px",
                    //     fontFamily: "'Jost', sans-serif",
                    //     // borderColor: "transparent",
                    //   },
                    //   //view
                    //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    //     // borderColor: "transparent",
                    //   },

                    //   "&:hover .MuiOutlinedInput-input": {
                    //     color: "black",
                    //   },
                    //   "&:hover .MuiInputLabel-root": {
                    //     color: COLORS.GrayBackground,
                    //   },
                    //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    //   {
                    //     // borderColor: "transparent",
                    //   },
                    //   ".MuiAutocomplete-root fieldset:hover": {
                    //     color: "transparent",
                    //     // borderColor: "transparent",
                    //   },
                    // }}
                    renderInput={(params) => (
                      <div ref={params.InputProps.ref}>
                        <Input {...params.inputProps} className="" placeholder="Select State" />
                      </div>
                    )}
                    ListboxProps={{
                      style: {
                        maxHeight: "150px",
                        fontSize: "14px",
                        // border: '1px solid white'
                      },
                    }}
                  />

                  {errhomeDistrict && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}

                </div>

                <div>
                  <Label className="text-sm">Suburb / City</Label>


                  <Autocomplete
                    className="mt-1.5"
                    // className="form-group"
                    // className="border-neutral-200"
                    disablePortal
                    defaultValue={{ fields: { scc_name: homeCity } }}
                    value={{ fields: { scc_name: homeCity } }}
                    // id="combo-box-demo"
                    options={allHCities}
                    getOptionLabel={(option) => option?.fields?.scc_name}
                    onChange={(event, value) => {

                      handleChangeHcity(event, value);
                      // setCountry(value);
                    }}
                    // sx={{
                    //   // width: "80vw",
                    //   background:"transparent",
                    //   borderRadius: "20px",
                    //   // border: "none",
                    //   // borderColor: "transparent",

                    //   "& .MuiInputBase-input": {
                    //     height: 15,
                    //     // border: "none",
                    //     fontSize: "14px",
                    //     fontFamily: "'Jost', sans-serif",
                    //     // borderColor: "transparent",
                    //   },
                    //   //view
                    //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    //     // borderColor: "transparent",
                    //   },

                    //   "&:hover .MuiOutlinedInput-input": {
                    //     color: "black",
                    //   },
                    //   "&:hover .MuiInputLabel-root": {
                    //     color: COLORS.GrayBackground,
                    //   },
                    //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    //   {
                    //     // borderColor: "transparent",
                    //   },
                    //   ".MuiAutocomplete-root fieldset:hover": {
                    //     color: "transparent",
                    //     // borderColor: "transparent",
                    //   },
                    // }}
                    renderInput={(params) => (
                      <div ref={params.InputProps.ref}>
                        <Input {...params.inputProps} className="" placeholder="Select State" />
                      </div>
                    )}
                    ListboxProps={{
                      style: {
                        maxHeight: "150px",
                        fontSize: "14px",
                        // border: '1px solid white'
                      },
                    }}
                  />

                  {errhomeCity && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}

                </div>
                <div>
                  <Label className="text-sm">Postcode</Label>
                  <Input className="mt-1.5"
                    placeholder="Enter post code"

                    onChange={(e) => {
                      sethomepostCode(e.target.value);
                      seterrhomepostCode(false);
                    }}
                    value={homepostCode}
                  />
                  {errhomepostCode && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>
                <div>
                  <Label className="text-sm">Event Title</Label>
                  <Input className="mt-1.5"
                    placeholder="Enter title"

                    onChange={(e) => {
                      settitle(e.target.value);
                      seterrtitle(false);
                    }}
                    value={title}
                  />
                  {errtitle && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>
                <div>
                  <Label className="text-sm">Description</Label>
                  <Textarea className="mt-1.5" rows={8}
                    placeholder="Enter description"
                    onChange={(e) => {
                      setdescription(e.target.value);
                      seterrdescription(false);
                    }}
                    value={description}
                  />
                  {errdescription && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>
                <div>
                  <Label className="text-sm">Start Date</Label>
                  <Input className="mt-1.5" type="date"
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      seterrStartDate(false);
                    }}
                    value={startDate}
                  />
                  {errstartDate && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>
                <div>
                  <Label className="text-sm">Start Time</Label>
                  <Input className="mt-1.5" type="time"
                    onChange={(e) => {
                      setstartTime(e.target.value);
                      seterrstartTime(false);
                    }}
                    value={startTime}
                  />
                  {errstartTime && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>
                <div>
                  <Label className="text-sm">End Date</Label>
                  <Input className="mt-1.5" type="date"
                    onChange={(e) => {
                      setexpireDate(e.target.value);
                      seterrexpireDate(false);
                    }}
                    value={expireDate}
                  />
                  {errexpireDate && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>
                <div>
                  <Label className="text-sm">End Time</Label>
                  <Input className="mt-1.5" type="time"
                    onChange={(e) => {
                      setexpireTime(e.target.value);
                      seterrexpireTime(false);
                    }}
                    value={expireTime}
                  />
                  {errexpireTime && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>


                <div>
                  <Label className="text-sm">Ticket Price ( Per person)</Label>
                  <div className="w-full flex">
                    <Select className="mt-1.5 w-4/12" rounded="rounded-l-lg"
                      onChange={(e) => {
                        setcurrency(e.target.value);
                        seterrrate(false);
                      }}
                      value={currency}
                    >
                      <option value={""}>Currency</option>
                      <option value="AUD">AUD</option>

                    </Select>
                    <Input className="mt-1.5" rounded="rounded-r-lg"
                      placeholder="Enter ticket price"
                      onChange={(e) => {
                        setrate(e.target.value);
                        seterrrate(false);
                      }}
                      value={rate}
                    />

                  </div>

                  {errrate && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>

                <div>
                  <Label className="text-sm">Cover</Label>
                  <div className="flex-shrink-0 flex justify-center w-full">
                    <div className="relative w-full overflow-hidden flex">
                      {
                        coverImage ?

                          <img
                            // src={CoverImg}
                            src={coverImage.includes('/uploads/') === true ? IMAGE_SERVER_URL_DEV + coverImage : coverImage}
                            alt=""
                            className="h-36 w-full object-cover z-0"
                          />

                          :
                          <div className="h-36 w-full object-cover z-0 bg-slate-200">
                          </div>
                      }

                      <div className={`absolute z-[1] inset-0   ${coverImage ? "hover:bg-black hover:bg-opacity-30" : "bg-black bg-opacity-30"}  flex flex-col items-center justify-center text-transparent ${coverImage ? "hover:text-neutral-50" : "text-neutral-50"} cursor-pointer`}>
                        <svg
                          className={`${coverImage ? "" : "text-neutral-50"}`}
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                            stroke="currentColor"
                            strokeWidth={1.5}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        <span className={`mt-1 text-[10.8px] ${coverImage ? "" : "text-neutral-50"}`}>{coverImage ? "Change Image" : "Upload Image"}</span>
                        <input
                          type="file"
                          accept="image/png, image/gif, image/jpeg, image/jpg"
                          className="absolute inset-0 opacity-0 cursor-pointer"
                          onChange={(e: any) => {

                            let file = e.target.files[0];

                            var extension = file.type;

                            extension = extension.split("/")[1];

                            console.log(">>>", extension.split("/")[1])

                            let reader = new FileReader();
                            reader.readAsDataURL(file);
                            let fileInp = e.target.files[0];
                            let baseURL;
                            // on reader load somthing...
                            reader.onload = () => {
                              // Make a fileInfo Object
                              baseURL = reader.result;
                              let obj: any =
                              {
                                path: baseURL,
                                thumbnail: false,
                                name: file.name,
                                ext: upperCase(extension)
                              }
                                ;

                              setcoverImage(baseURL);


                            };
                          }}
                        />
                      </div>

                    </div>
                  </div>

                </div>
                {/* <div>
                  <div className="flex-shrink-0 flex justify-center w-full -mt-10">
                    <div className="relative rounded-full overflow-hidden flex">
                      <img
                        src={Company1}
                        alt=""
                        className="w-24 h-24 rounded-full object-cover z-[2] border-2 border-slate-100"
                      />
                      <div className="absolute z-[3] inset-0 hover:bg-black hover:bg-opacity-30  flex flex-col items-center justify-center text-transparent hover:text-neutral-50 cursor-pointer">
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                            stroke="currentColor"
                            strokeWidth={1.5}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        <span className="mt-1 text-[10.8px]">Change Image</span>
                        <input
                          type="file"
                          className="absolute inset-0 opacity-0 cursor-pointer"
                        />
                      </div>

                    </div>
                  </div>
                </div> */}


                <div>
                  <Label className="text-sm">Type Keywords</Label>
                  <Input className="mt-1.5"
                    placeholder="#plumber #farmingjobs"

                    onChange={(e) => {
                      setkeywords(e.target.value);
                      seterrkeywords(false);
                    }}
                    value={keywords}
                  />
                  {errkeywords && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>
                <div className="w-full mt-3 xl:mt-6 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                  <div className="grid grid-cols-10 gap-5 pl-4 pr-5">
                    {images?.map((faker: any, fakerKey: any) => (
                      <>
                        <div
                          // key={fakerKey}
                          className="col-span-5 md:col-span-2 h-28 relative image-fit cursor-pointer zoom-in"
                        >
                          <div className="flex flex-col">
                            <img className="cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nO3Xv0skMRQH8GnGH6yoWCiChbJgZaGNtYWNnf+CFqJYWIpiJ8gVgjaiYiX+BRaCrY0iy3EWarGIhYiLhQiLyWzmzeR7JKO76KLHqclucB6EYbr3Sd6bl/G8NNIwG8z3YWj9chfQ12cPwQwAwj+/wbJZOwgTALov2EMwQwBrCGYQQDYQpgFkGmEDQCYRtgBkCmETQCYQtgH0BlGXgP9ZXgrw0xOAcyVUGhuDzOchi0WECwvuAeTtLcoRx+C9ve4AeFdXJfejI/0sjY+7A2CZDBBFCeDiwkGA7yM+OyuXj4pgeNgtQLi4WOkBIrC2NrcAvLsbCILkEHK5TyfPajmJo4ODBHB8DNbQ4BigqQmyUChXkZiddW+Q6RBCP9RA4/397gBod1cnHq6sINrfTxBXV+A9PfUP4KqBn570LODZLHhnJ+LLS42IT0/16Yi5OdDGBmhnB7S9DTExAdbcXB+AcHlZJ6uaWL0Hg4OgrS38K2hvr/YA3tEB+fCQ7PbJCeT19essn3viJWQ+j/j8PHnhvMaATAa0tla1s6p8wtVVlEZHwVpa9JBTTf02osPDGgEaGyFmZiBvbio7Wyzq+n7vCqEufGJyErS+DtrchJieBmtvtw8IBgZ0Y77a8VxOl9Jnvl7MJiAYGYF8fKyq8WBo6NuSZ8YAatI+l4y8uyvfOsOlpW9NnhkDtLZWfVHU8PrKnYfZLiExNaV3X/0+hvPz7w6iugUwS8tLAX56AkhL6EeXUBppeB/GX+axT5lwmtMeAAAAAElFTkSuQmCC" />
                            <span className="text-xs flex justify-center text-center">{faker?.name}</span>
                          </div>
                          {/* <img
                            className="rounded-md"
                            alt="Midone - HTML Admin Template"
                            src={faker.path.includes('/uploads/products/') === true ? IMAGE_SERVER_URL + faker?.path : faker?.path}
                          /> */}
                          <div
                            className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 -mr-2 -mt-2"
                          >
                            <div
                              onClick={(e) => {

                                var array = [...images]
                                array.splice(fakerKey, 1);
                                setImages(array)

                              }}
                            >
                              {/* <Lucide icon="X" className="w-4 h-4" /> */}
                              <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                            </div>
                          </div>


                        </div>
                        {/* <div className="form-check">
                            <input id="checkbox-switch-" className="form-check-input w-5 h-5 bg-gray-200 relative rounded-full text-white right-10 top-[80px]" type="checkbox"
                                checked={faker?.thumbnail}

                                onChange={(e) => {
                                    var array = [...images]
                                    const index = _.map(array, "thumbnail").indexOf(true);

                                    // console.log("index>>>", index)

                                    if (index < 0) {
                                        array[fakerKey].thumbnail = e.target.checked;
                                        setImages(array)
                                    } else {
                                        array[index].thumbnail = false;
                                        array[fakerKey].thumbnail = e.target.checked;
                                        setImages(array)
                                    }
                                    // array.filter(person => person.thumbnail === true)
                                    // array[fakerKey].thumbnail = e.target.checked;
                                    // setImages(array)
                                    // console.log("<<<<<",e.target.checked)
                                    // setlatest(e.target.checked)
                                    // onChangeUserArr(e, arr.privilege)
                                    // this.onChangeAllArr(e, arr.privilege);
                                }}
                            />
                           


                        </div> */}
                      </>

                    ))}
                  </div>
                  <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative text-sm">
                    {/* <Lucide icon="Image" className="w-4 h-4 mr-2" /> */}
                    <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                    <span className="text-primary mr-1 ">
                      Upload documents
                    </span>{" "}
                    or drag and drop
                    <input
                      id="horizontal-form-1"
                      accept={"application/pdf"}
                      type="file"
                      multiple
                      className="w-full h-full top-0 left-0 absolute opacity-0"
                      onChange={(e: any) => {
                        Array.from(e.target.files).map((item) => {

                          let file: any = item;
                          let reader = new FileReader();
                          var extension = file.type;

                          extension = extension.split("/")[1];

                          reader.readAsDataURL(file);
                          let fileInp = item;
                          let baseURL;
                          // on reader load somthing...
                          reader.onload = () => {
                            // Make a fileInfo Object
                            baseURL = reader.result;
                            let obj =
                            {
                              path: baseURL,
                              thumbnail: false,
                              name: file.name,
                              ext: upperCase(extension)
                            }
                              ;

                            console.log(">>>>>", obj);

                            setImages((existing: any) => [...existing, obj]);

                          };
                        })


                        // let file = e.target.files[0];

                        // var extension = file.type;

                        // extension = extension.split("/")[1];

                        // console.log(">>>", extension.split("/")[1])

                        // let reader = new FileReader();
                        // reader.readAsDataURL(file);
                        // let fileInp = e.target.files[0];
                        // let baseURL;
                        // // on reader load somthing...
                        // reader.onload = () => {
                        //   // Make a fileInfo Object
                        //   baseURL = reader.result;
                        //   let obj: any =
                        //   {
                        //     path: baseURL,
                        //     thumbnail: false,
                        //     name: file.name,
                        //     ext: upperCase(extension)
                        //   }
                        //     ;

                        //   setImages(images.concat(obj))


                        // };


                      }}
                    />
                  </div>
                </div>
                <div className="flex w-full items-center justify-center">
                  <button
                    className={
                      `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-gradient-to-r from-cyan-500 from-10% to-[#3c9140] to-90% hover:from-pink-500 hover:to-yellow-500 
w-full h-9 text-slate-50 font-medium mt-4 `}

                    onClick={saveEvent}
                  >
                    {/* <i className="las la-pencil-alt text-xl text-left mr-2"></i> */}
                    <span className="block  text-sm font-medium "
                    >
                      Save
                    </span>

                  </button>
                </div>

              </div>

            </div>
          </div>


        </div>


      </div>
    </div>
  );
};

export default ProductQuickView2;
