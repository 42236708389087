import rightImg from "images/about/1.jpg";
import React, { FC, useState, useEffect } from "react";
import SectionFounder, { People } from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";
import NcImage from "shared/NcImage/NcImage";
import SectionPromo1 from "components/SectionPromoHeader";
import cover from "images/cover/cover-8.png"
import Map from "images/about/map.png"
import Pic1 from "images/about/pic1.png"
import Pic2 from "images/about/pic2.png"
import Pic3 from "images/about/pic3.png"
import Pic4 from "images/about/pic4.png"
import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import Company1 from "images/avatars/Image-1.png";
import SectionSliderEvents from "components/SectionSliderCategories/SectionSliderEvents";
import { getEventById } from "Services/API/Get";
import { useParams } from "react-router-dom";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import moment from "moment";

const PageSingleEvent = ({ className = "" }) => {

    const [event, setevent] = useState("");

    let proId = null

    proId = useParams();

    proId = proId.id

    const SingleEvent = () => {
        getEventById(proId)
            .then((res) => {
                console.log("event>>>", res.data[0]);
                setevent(res.data[0]);
                // setallHCities(res.data);
            })
            .catch((error) => {
                console.log("Error", error.response);
                setevent("");
                // setallHCities([])
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });
    };

    useEffect(() => {
        SingleEvent();
    }, [proId])


    return (
        <>
            <div
                className={`nc-PageAbout overflow-hidden relative ${className}`}
                data-nc-id="PageAbout"
            >
                <Helmet>
                    <title>Event || Skillnet</title>
                </Helmet>

                {/* <SectionPromo1/> */}


                {/* ======== BG GLASS ======== */}
                {/* <BgGlassmorphism /> */}

                <div className="lg:container w-full pt-16 pb-24 lg:pt-22 lg:pb-24 space-y-16 lg:space-y-14 flex justify-center">

                    {/* <div className="w-10/12"> */}
                    <div className="w-11/12 xl:w-full">


                        {/* <div className="grid grid-cols-1 lg:grid-cols-2 flex justify-center"> */}
                        <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 flex justify-center gap-x-10">

                            {/* <div className="w-full"> */}
                            <div className="w-full lg:col-span-2 xl:col-span-3">
                                <span className="flex font-bold text-2xl mt-4 mb-3 text-center lg:text-left">
                                    {/* Backpackers are required for a grapes farm
                                    located in Tasmania */}
                                    {event?.title}
                                </span>
                                {
                                    event?.banner &&

                                    <div className="w-full md:mb-5">

                                        <img
                                            alt="gallery"
                                            className="block h-96 w-full  object-cover object-center"
                                            // src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
                                            src={`${IMAGE_SERVER_URL_DEV}${event?.banner}`}
                                        />

                                    </div>
                                }

                                <div className="w-full flex">
                                    <div className="flex flex-col lg:flex-row  justify-center  items-center w-full">
                                        <div className="flex justify-center lg:justify-start">
                                            <div className="w-14 h-14 object-fill bg-contain rounded-lg"
                                                style={{
                                                    // backgroundImage: `url(${Company1})`
                                                    backgroundImage: `url(${IMAGE_SERVER_URL_DEV}${event?.avatar})`

                                                }}
                                            >

                                            </div>
                                        </div>
                                        <div className="mt-3 lg:mt-0 lg:ml-4 lg:w-full" >
                                            <div className="flex flex-col space-y-2 lg:space-y-0">
                                                <div className="flex items-center w-full justify-center lg:justify-start lg:text-left">
                                                    <span className="text-xs text-neutral-500">Published by</span>

                                                </div>
                                                <div className="flex items-center w-full justify-center text-center lg:justify-start lg:text-left">
                                                    <span className="text-base font-bold">
                                                        {/* ABC private Company Name or publisher name */}
                                                        {event?.businessName}
                                                    </span>
                                                </div>
                                                <div className="flex items-center w-full justify-center text-center lg:justify-start lg:text-left">
                                                    <span className="text-xs text-neutral-500">5 minutes ago</span>
                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                    {/* <div className="flex w-full justify-center lg:hidden">
                                        <div className="w-14 h-14 object-fill bg-contain rounded-lg"
                                            style={{
                                                backgroundImage: `url(${Company1})`
                                            }}
                                        >

                                        </div>
                                    </div> */}



                                </div>

                                <div className="w-full flex justify-center">
                                    <div className="bg-gray-100 w-full sm:w-6/12 mt-6 py-6 lg:hidden">

                                        <div className="w-full h-full flex flex-col justify-center items-center space-y-8">
                                            <div className="flex flex-col justify-center items-center">
                                                <img className="w-8 h-8" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACZElEQVR4nO2ZPWtUQRSGHxNdiaZQxEpIndhpq6CNJloIJkgKg4VoERQtoiR+INFGC8mfkGisxcq/EPwKiPErWgQLCwUXRaO54cB7YVgkubOz7owyDwwsd89575mvO2dmIJP5Z6gBw8A94CVQV7Hfd/Wf2STNMeAdUKxR3gJDJEgnMOUE+hg4C/QBm1X69OyJY3dbvskwpcC+AyeBdavYdgCnZFtWJpnhVADfgD0efnudygwSmZozJ6wnfDkt3zexPwDDzpywIeOL+TyVhvVsNGYUxJkAjXPSmCYi8wqiN0BjpzRsnYnGVwXRHaDRLQ3Tika5HqSi0zS5Ig3kHmkVeWilNrS+KIAtARpbpfGZiDxXELsCNHZLw9L7aEwriAsBGhelcYeIHFIQ8wFJ4ytpDBCRTqXgFsh4E/4T8n2dwk5xP7AM/PBsVbP9CfwG9pEIN9SyS8BoBftR2ZrPdRJj3GM9KO1ukSiFZ0WSpcgVSYzif+iR7c4h3VqU51nbSIwBYEHBPahg/9BZCPuJTA0YAWadoWJnVDsq+PYALxy/WWnVaCM2HC4Bi04gH4ExYKOHzibgKvDJ0VmU9l8dctZa5509SJks2rOuAF2r/AlgztG1o6HJQN0/crDh3uORnq126u6LafVLu3zPB+Bwq15wRYldoVZrx+Q8ADzTO5fVO0FMSuwXcK3NqXYHcNlJLm82K3RErbGkU/dYDCrdt1iONjOxFwI2TK1mTLG89/1EH5ejTfANxGe9LlALrTeVuS8n2zCltnmb8XEqax89fWhIg8prusrUK9yVxyp1n4oUiZdMJoMfK+YhJaCnoLnkAAAAAElFTkSuQmCC" />
                                                <span className="text-sm font-bold">Event Type</span>
                                                <span className="text-sm text-neutral-500 mt-1">
                                                    {/* Casual */}
                                                    {event?.eventType}
                                                </span>
                                            </div>
                                            <div className="flex flex-col justify-center items-center">
                                                <img className="w-8 h-8" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACZElEQVR4nO2ZPWtUQRSGHxNdiaZQxEpIndhpq6CNJloIJkgKg4VoERQtoiR+INFGC8mfkGisxcq/EPwKiPErWgQLCwUXRaO54cB7YVgkubOz7owyDwwsd89575mvO2dmIJP5Z6gBw8A94CVQV7Hfd/Wf2STNMeAdUKxR3gJDJEgnMOUE+hg4C/QBm1X69OyJY3dbvskwpcC+AyeBdavYdgCnZFtWJpnhVADfgD0efnudygwSmZozJ6wnfDkt3zexPwDDzpywIeOL+TyVhvVsNGYUxJkAjXPSmCYi8wqiN0BjpzRsnYnGVwXRHaDRLQ3Tika5HqSi0zS5Ig3kHmkVeWilNrS+KIAtARpbpfGZiDxXELsCNHZLw9L7aEwriAsBGhelcYeIHFIQ8wFJ4ytpDBCRTqXgFsh4E/4T8n2dwk5xP7AM/PBsVbP9CfwG9pEIN9SyS8BoBftR2ZrPdRJj3GM9KO1ukSiFZ0WSpcgVSYzif+iR7c4h3VqU51nbSIwBYEHBPahg/9BZCPuJTA0YAWadoWJnVDsq+PYALxy/WWnVaCM2HC4Bi04gH4ExYKOHzibgKvDJ0VmU9l8dctZa5509SJks2rOuAF2r/AlgztG1o6HJQN0/crDh3uORnq126u6LafVLu3zPB+Bwq15wRYldoVZrx+Q8ADzTO5fVO0FMSuwXcK3NqXYHcNlJLm82K3RErbGkU/dYDCrdt1iONjOxFwI2TK1mTLG89/1EH5ejTfANxGe9LlALrTeVuS8n2zCltnmb8XEqax89fWhIg8prusrUK9yVxyp1n4oUiZdMJoMfK+YhJaCnoLnkAAAAAElFTkSuQmCC" />
                                                <span className="text-sm font-bold">Online or In-house</span>
                                                <span className="text-sm text-neutral-500 mt-1">
                                                    {/* Casual */}
                                                    {event?.hostType}
                                                </span>
                                            </div>

                                            <div className="flex flex-col justify-center items-center">
                                                <img className="w-8 h-8" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1UlEQVR4nO2YTUoDQRCFv0tEJOhCPI6ItzEnEDyHgt5AV2IwzEZ3uvAK6k5046pkoAOzSM9Uz09SM10PikB1pbvqzavqTMDhaEIBSMRWZJCfNNiuMXh+EtnIGgFav70DOsIJwBWAtwD61rDXYx3hLYC3AL0q9AxYAr8NPyjEmNXlV9byCJw2FX9Zc8BBJe7QQMFt87uoe/IC/AHnwDz4r4P/LhxSbn4ffFfsHpr8yloWobbSf7Jpo2VYLAOrOAY+NzD5ARyxe6TktwhrD5s2+gmL88ght8BXsJvgswJtfvNQ4zeG7/WhEa1TMjMnQC2NiaFRAVNHKwIKRU+tDMd3JkASB4u1+N4IiCGWoJV41Zo4AbgCpIWk+pCotwA+AxjFEJRcr8GV4rAnw/GdCZgSnABaKKBQSM7fBZjwEJSG+RBL0Eq8ak2cAFwB0kJSfUjUWwCfAYxiCEqu1+BKcZi/C4wErRRA7gQUhv/n3woBYniobZWAGGIFDRXfBU4AE1dA0badZCIEaIp/zoGAKmbAa/C/A/tkRMBMU7yWgKGutdT4OlRj1cUzsneBOqzjk4onkWXLWNfxVvncS/ni2FFVjerJp/bhWCypeG0fjsVetLJ3OBxkjX+C2jam+1aomgAAAABJRU5ErkJggg==" />
                                                <span className="text-sm font-bold">Start Date & Time</span>
                                                <span className="text-sm text-neutral-500 mt-1">
                                                    {/* 27 th May 2023, 9am */}
                                                    {`${moment(event?.startDate).format("Do MMM YYYY")}, ${event?.startTime}`}
                                                </span>
                                            </div>
                                            <div className="flex flex-col justify-center items-center">
                                                <img className="w-8 h-8" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1UlEQVR4nO2YTUoDQRCFv0tEJOhCPI6ItzEnEDyHgt5AV2IwzEZ3uvAK6k5046pkoAOzSM9Uz09SM10PikB1pbvqzavqTMDhaEIBSMRWZJCfNNiuMXh+EtnIGgFav70DOsIJwBWAtwD61rDXYx3hLYC3AL0q9AxYAr8NPyjEmNXlV9byCJw2FX9Zc8BBJe7QQMFt87uoe/IC/AHnwDz4r4P/LhxSbn4ffFfsHpr8yloWobbSf7Jpo2VYLAOrOAY+NzD5ARyxe6TktwhrD5s2+gmL88ght8BXsJvgswJtfvNQ4zeG7/WhEa1TMjMnQC2NiaFRAVNHKwIKRU+tDMd3JkASB4u1+N4IiCGWoJV41Zo4AbgCpIWk+pCotwA+AxjFEJRcr8GV4rAnw/GdCZgSnABaKKBQSM7fBZjwEJSG+RBL0Eq8ak2cAFwB0kJSfUjUWwCfAYxiCEqu1+BKcZi/C4wErRRA7gQUhv/n3woBYniobZWAGGIFDRXfBU4AE1dA0badZCIEaIp/zoGAKmbAa/C/A/tkRMBMU7yWgKGutdT4OlRj1cUzsneBOqzjk4onkWXLWNfxVvncS/ni2FFVjerJp/bhWCypeG0fjsVetLJ3OBxkjX+C2jam+1aomgAAAABJRU5ErkJggg==" />
                                                <span className="text-sm font-bold">Finish Date & Time</span>
                                                <span className="text-sm text-neutral-500 mt-1">
                                                    {/* 27 th May 2023, 9am */}
                                                    {`${moment(event?.endDate).format("Do MMM YYYY")}, ${event?.endTime}`}
                                                </span>
                                            </div>
                                            <div className="flex flex-col justify-center items-center">
                                                <img className="w-8 h-8" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADuElEQVR4nO2ZWYiOURjHfzRkmbHPZOJCWYZCiNwoW7JEGtkaV24VuZG9xnZBcodsxYWILJESLiQiDLIklEhqLFnGvoxPR/+3TtN8531f3znffCO/euvr/f7vc/bnOec58J9G6QBUAbuAq8BL4Luel3q3E5gLlFCAVAB7gM9AJuHzCdgN9KUAaAdsBn6ocr/U6yuB0Wpgez0VercKuCZtRqO1CWjTVI0wPXlHlakH9gO9U3zfBzhgNegyUE6eGao5byrwABiWg63hwEPZegYMJo8jETXiDNDZg80uwFmrMeX5WBN3rEYUebTdCjhnTbOga2azNZ18jERjI/NIZawhEBXyTvU5rok4RsgBfAS6hyhgj3rKeKfQHFRZ20JE7E/qqSQutgxYB9xUz5rnBrAWKE3oUH7pu2I8UqUeMsEujplAnSOivwdmJLBzXfo5eGSXjJqIHdeIKMAdUySPIvsY4LgVQCtjbK2WdofHdvwZiYwq5ppO0UgsceiWSvMO6ObQjZXuCh55JaP9HJp11kjEcSKBi+0nzQs88k1GXVvvWwlGrWFvGweQjRJpvuKRLzLqirYfpEniZaJKmqno2kVkdDTwRq2MugJUXYqGdLA8WDZ6SPMcj9yX0YEOzU1pjHeKY5y0NQ7NEGnu4pGTMjrboVkrzfEU9qodmnnSHMUjG2R0vUNTqqmSkYvNxnJp3gJdHbqNCRqbmtkyarbZLmYo2GXkYsdqzRRrOkUjYTTTY2xdlDZOl4oyFf5V3sRFpYJdti3K2wSV66Sdtnk64pkbqsiUBNpuCnY1cst12jtVx0yniFkqy4yKd6LIvZfwHEmw1v6a/pbvj5teudBRAdhsPnuFKqRGjTGuMRQLVcb5gGWwwEoOhKAIeKwyzBkoGMWWRzJna9/MtbYlJqsSlC0q7HDAqbuCPFBuLcZBHu1OsA5cJo7kha0BRsUs7qD5rMboaWVVRnmwV6lGvA6U+HNSbZ3yWuZgp7WVxF5AE9AWeKIKzM/BzhLZuOc5l/xX+a5anfrSUmq584k0IS2AS6qISXCnZZ++Ndv7Jmeors9+AiNTfDfJuktMc9MVlOiYez/hvYbJojzVN4soIFoDtxMchyO2W+eNXDxeEIZoitXHZFImKf6Y0+YACpQ16uknWbYZZUqBGs1iCpgi4IKVxjFeLcJModP671SD/wqSnlbSe2UjR+XaUFdqIZimdWDWy2Rgqn4bFz2eZka1RuCNUkDm9zKaIS2AQ1Y+q+GaaVaU6Mbpsu9LTf5VfgNttDSN+ivn8AAAAABJRU5ErkJggg==" />
                                                <span className="text-sm font-bold">Location</span>
                                                <span className="text-sm text-neutral-500 mt-1">
                                                    {event?.address + "," + event?.state + "," + event?.suburb}
                                                    {/* Hobart Tasmania */}
                                                </span>
                                            </div>




                                        </div>
                                    </div>
                                </div>


                                <div className="flex flex-col w-full mt-6">
                                    <span className="text-base font-bold flex items-center w-full justify-center text-center lg:justify-start lg:text-left">Event Description</span>

                                    {/* <span className="text-sm mt-4 flex items-center w-full justify-center text-center lg:justify-start lg:text-left">Company description - See completed projects and flexible talent strategies
                                        from companies like yours using Fiverr Business.</span> */}

                                    <span className="text-sm mt-4 flex items-center w-full justify-center text-center lg:justify-start lg:text-left">
                                        {/* paywall for content that users will be able to reach only via subscription. The
                                        website is a magazine about fashion. I need to update an yet to launch
                                        Wordpress website. I also want to add a paywall for content that users will be
                                        able to reach only via subscription. The website is a magazine about fashion. I
                                        need to update an yet to launch Wordpress website. I also want to add a
                                        paywall for content that users will be able to reach only via subscription. The
                                        website is a magazine about fashion. */}
                                        {
                                            event?.description
                                        }

                                    </span>


                                    <div className="flex flex-col w-full justify-center px-20 lg:hidden">




                                        <button
                                            className={
                                                `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#002930] 
w-full h-9 font-medium mt-6`}

                                        >
                                            <span className="block  text-xs font-medium text-white">
                                                {event?.charge === "Free" ? "Free" : `${event?.ticketCurrency} ${event?.ticketPrice}`}
                                                {/* $ 250/Hourly */}
                                            </span>

                                        </button>



                                        <button
                                            className={
                                                `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-gradient-to-r from-cyan-500 from-10% to-[#3c9140] to-90% hover:from-pink-500 hover:to-yellow-500 
w-full h-9 text-slate-50 font-medium mt-4 cursor-pointer`}

                                            onClick={() => {
                                                console.log(">>>>>", "hello")
                                            }}

                                        >
                                            {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
                                            <span className="block  text-xs font-medium">
                                                Apply Now
                                            </span>

                                        </button>


                                    </div>

                                </div>

                                <div className="mt-6">

                                    {
                                        event && JSON.parse(event?.document).length > 0 &&
                                        <>
                                            <span className="text-base font-bold flex items-center w-full justify-center text-center lg:justify-start lg:text-left">Downloads</span>

                                            <div className="mt-4 flex space-x-4 w-full justify-center lg:justify-start">
                                                {

                                                    event && JSON.parse(event?.document).map((item, index) => {

                                                        return (
                                                            <>
                                                                <a className="flex flex-col cursor-pointer" href={`${IMAGE_SERVER_URL_DEV}${item?.path}`} target="_blank" >

                                                                    <div className="flex w-full">
                                                                        <img className="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nO3Xv0skMRQH8GnGH6yoWCiChbJgZaGNtYWNnf+CFqJYWIpiJ8gVgjaiYiX+BRaCrY0iy3EWarGIhYiLhQiLyWzmzeR7JKO76KLHqclucB6EYbr3Sd6bl/G8NNIwG8z3YWj9chfQ12cPwQwAwj+/wbJZOwgTALov2EMwQwBrCGYQQDYQpgFkGmEDQCYRtgBkCmETQCYQtgH0BlGXgP9ZXgrw0xOAcyVUGhuDzOchi0WECwvuAeTtLcoRx+C9ve4AeFdXJfejI/0sjY+7A2CZDBBFCeDiwkGA7yM+OyuXj4pgeNgtQLi4WOkBIrC2NrcAvLsbCILkEHK5TyfPajmJo4ODBHB8DNbQ4BigqQmyUChXkZiddW+Q6RBCP9RA4/397gBod1cnHq6sINrfTxBXV+A9PfUP4KqBn570LODZLHhnJ+LLS42IT0/16Yi5OdDGBmhnB7S9DTExAdbcXB+AcHlZJ6uaWL0Hg4OgrS38K2hvr/YA3tEB+fCQ7PbJCeT19essn3viJWQ+j/j8PHnhvMaATAa0tla1s6p8wtVVlEZHwVpa9JBTTf02osPDGgEaGyFmZiBvbio7Wyzq+n7vCqEufGJyErS+DtrchJieBmtvtw8IBgZ0Y77a8VxOl9Jnvl7MJiAYGYF8fKyq8WBo6NuSZ8YAatI+l4y8uyvfOsOlpW9NnhkDtLZWfVHU8PrKnYfZLiExNaV3X/0+hvPz7w6iugUwS8tLAX56AkhL6EeXUBppeB/GX+axT5lwmtMeAAAAAElFTkSuQmCC" />
                                                                    </div>
                                                                    <span className="text-xs flex justify-center text-center mt-1">{item?.name}</span>

                                                                </a>
                                                                {/* <img className="cursor-pointer w-14 h-14" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABDCAYAAADHyrhzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAERklEQVR4nO3a/U8TdxwH8Ptt2f8gLb0rFiUOCXPDwAxLTJYlxmWbMYZFl21ZsizOuIxpdFu2aKZOAjJjsgcHVmSDVjRAH1cIlrZ3lD7QgITwPdyyPjEBh7VQ5vSHz3KH5Yftql33pXeXfN/JO21/adNXv9/PPaQURUJCQkJCUtTES5gTcQ3jwtnYBnoLpcbEShhrXKMHnI1tKNtBqTExgvHfMBaPfwYL+98iKyNZUQUPojGY2/0awUhWPQf3xyfg9su788bgjR2haZ4fkKrP5ztFqXabaMvgwS+/wvzehrwxZnquA+J5ydrtdvB4PBcotc6MTL8VFhuP4sFwOMBitYJ7ePg8pUaM1MlTcPeLk9gw+i0WEcTj8bRSqsM40wQrbg8kyrdgwxBBLBYB5DSlFozZ6u3iyhBmRvpyJ1aM7AoJNZ9jYxrmTK7GNfo9isC4d+EbmH2+bvV5UwukmlvFoYoLIwsy8dGRx75ntKTsBVkxZmt2wIprcPW1zgBzr7wOD+Nx+OvmJCz39kO63QhLXSbxMflMdcEYayAfNubEiGn1b8uKsdxzHdJtRvjjcCPc/fRzmN+3XzzvmG84AH9yI5A6dx5uv7RLPCFLbKrMCyM8NgahcHito8HgWoN+P6DKamVhJPSbYeGNN+FhIgmzz27P+WsJK2Xx42Mwt+vVvLfJk+rZaICIjlEGxsKBd8QKEHfeP5T34RQbhsEAgwwNozoaYnJiLB45DnfeOwgZq13cIoVA4MIQytIM/CYXRqq5FTI2Byx1m8WtIjeGUDdNwy2tHNtEWyZepRaKsB4YQodoGiZK9V8WF0ODp7gxHs2QywSDKSJGotRgiGv1NdnOfH+xa6avH/5P+fFxda6MfwYh1FLoF8FRgsETDCArg3/8Npnc10BmBnqEcW/ED16JIaqoAepyucDhdGLriHCFKvE5vx88DLc+OKRsDIfTCVabDVs5jpPEiNa9CBmWVTYGKlKjtfWw1H0VfOXlBCNaWw+plq9htKqaYEQFjLPNENi2TbkYnN8PPpbF1rFIJOc2SRs7gN1coVwMW44buYWWzTVAa+thhR2BISUP0EgkAuFwGFtvTk5KYiT3NkDybBM5miCeh5TNDoGtVQQDCdvk9FfKPx2fRgh7pT7Hu6lc2RjTCGEdnkIDwaA6r1qnEYKfBwawVhiiqsRARSzB4J+MEdAxl8jKYFYxgjrmO4LBrGKEaPoiwWBkxJiamtqIENqZ7UBf36DJbAapcrV1wFVWYu0Qw0jOjBDN/EDJHafJ9O0loxHa2tv/1Rs5ht16NFjKtMltQQV7ez+54XbDlc5OuTHaFYHBchx4vF7o6u6WDUOWQ2suDKHCTZmea9fkwjBScsdns72bxcjWYrMVHSNM0/L/Ad9utz8V6LjiC5ivLo+azJlsB3/86b63oiI9TNOZ9a6fpie4mpqn5bYgISEhISEhISGhpPM3TMsyW6Uua1UAAAAASUVORK5CYII="></img> */}

                                                            </>);
                                                    })
                                                }
                                            </div>

                                        </>
                                    }
                                </div>



                            </div>

                            <div className="hidden w-full lg:flex justify-center">

                                {/* <div className="bg-gray-100 lg:w-10/12 xl:w-9/12 mt-6 py-16 h-fit"> */}
                                {/* <div className="bg-gray-100 lg:w-10/12 xl:w-7/12 mt-6 py-16 h-fit"> */}
                                <div className="bg-gray-100 w-full mt-6 py-16 h-fit">

                                    <div className="w-full h-full flex flex-col justify-center items-center space-y-8">
                                        <div className="flex flex-col justify-center items-center">
                                            <img className="w-8 h-8" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACZElEQVR4nO2ZPWtUQRSGHxNdiaZQxEpIndhpq6CNJloIJkgKg4VoERQtoiR+INFGC8mfkGisxcq/EPwKiPErWgQLCwUXRaO54cB7YVgkubOz7owyDwwsd89575mvO2dmIJP5Z6gBw8A94CVQV7Hfd/Wf2STNMeAdUKxR3gJDJEgnMOUE+hg4C/QBm1X69OyJY3dbvskwpcC+AyeBdavYdgCnZFtWJpnhVADfgD0efnudygwSmZozJ6wnfDkt3zexPwDDzpywIeOL+TyVhvVsNGYUxJkAjXPSmCYi8wqiN0BjpzRsnYnGVwXRHaDRLQ3Tika5HqSi0zS5Ig3kHmkVeWilNrS+KIAtARpbpfGZiDxXELsCNHZLw9L7aEwriAsBGhelcYeIHFIQ8wFJ4ytpDBCRTqXgFsh4E/4T8n2dwk5xP7AM/PBsVbP9CfwG9pEIN9SyS8BoBftR2ZrPdRJj3GM9KO1ukSiFZ0WSpcgVSYzif+iR7c4h3VqU51nbSIwBYEHBPahg/9BZCPuJTA0YAWadoWJnVDsq+PYALxy/WWnVaCM2HC4Bi04gH4ExYKOHzibgKvDJ0VmU9l8dctZa5509SJks2rOuAF2r/AlgztG1o6HJQN0/crDh3uORnq126u6LafVLu3zPB+Bwq15wRYldoVZrx+Q8ADzTO5fVO0FMSuwXcK3NqXYHcNlJLm82K3RErbGkU/dYDCrdt1iONjOxFwI2TK1mTLG89/1EH5ejTfANxGe9LlALrTeVuS8n2zCltnmb8XEqax89fWhIg8prusrUK9yVxyp1n4oUiZdMJoMfK+YhJaCnoLnkAAAAAElFTkSuQmCC" />
                                            <span className="text-sm font-bold">Event Type</span>
                                            <span className="text-sm text-neutral-500 mt-1">{event?.eventType}</span>
                                        </div>
                                        <div className="flex flex-col justify-center items-center">
                                            <img className="w-8 h-8" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACZElEQVR4nO2ZPWtUQRSGHxNdiaZQxEpIndhpq6CNJloIJkgKg4VoERQtoiR+INFGC8mfkGisxcq/EPwKiPErWgQLCwUXRaO54cB7YVgkubOz7owyDwwsd89575mvO2dmIJP5Z6gBw8A94CVQV7Hfd/Wf2STNMeAdUKxR3gJDJEgnMOUE+hg4C/QBm1X69OyJY3dbvskwpcC+AyeBdavYdgCnZFtWJpnhVADfgD0efnudygwSmZozJ6wnfDkt3zexPwDDzpywIeOL+TyVhvVsNGYUxJkAjXPSmCYi8wqiN0BjpzRsnYnGVwXRHaDRLQ3Tika5HqSi0zS5Ig3kHmkVeWilNrS+KIAtARpbpfGZiDxXELsCNHZLw9L7aEwriAsBGhelcYeIHFIQ8wFJ4ytpDBCRTqXgFsh4E/4T8n2dwk5xP7AM/PBsVbP9CfwG9pEIN9SyS8BoBftR2ZrPdRJj3GM9KO1ukSiFZ0WSpcgVSYzif+iR7c4h3VqU51nbSIwBYEHBPahg/9BZCPuJTA0YAWadoWJnVDsq+PYALxy/WWnVaCM2HC4Bi04gH4ExYKOHzibgKvDJ0VmU9l8dctZa5509SJks2rOuAF2r/AlgztG1o6HJQN0/crDh3uORnq126u6LafVLu3zPB+Bwq15wRYldoVZrx+Q8ADzTO5fVO0FMSuwXcK3NqXYHcNlJLm82K3RErbGkU/dYDCrdt1iONjOxFwI2TK1mTLG89/1EH5ejTfANxGe9LlALrTeVuS8n2zCltnmb8XEqax89fWhIg8prusrUK9yVxyp1n4oUiZdMJoMfK+YhJaCnoLnkAAAAAElFTkSuQmCC" />
                                            <span className="text-sm font-bold">Online or In-house</span>
                                            <span className="text-sm text-neutral-500 mt-1"> {event?.hostType}</span>
                                        </div>

                                        <div className="flex flex-col justify-center items-center">
                                            <img className="w-8 h-8" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1UlEQVR4nO2YTUoDQRCFv0tEJOhCPI6ItzEnEDyHgt5AV2IwzEZ3uvAK6k5046pkoAOzSM9Uz09SM10PikB1pbvqzavqTMDhaEIBSMRWZJCfNNiuMXh+EtnIGgFav70DOsIJwBWAtwD61rDXYx3hLYC3AL0q9AxYAr8NPyjEmNXlV9byCJw2FX9Zc8BBJe7QQMFt87uoe/IC/AHnwDz4r4P/LhxSbn4ffFfsHpr8yloWobbSf7Jpo2VYLAOrOAY+NzD5ARyxe6TktwhrD5s2+gmL88ght8BXsJvgswJtfvNQ4zeG7/WhEa1TMjMnQC2NiaFRAVNHKwIKRU+tDMd3JkASB4u1+N4IiCGWoJV41Zo4AbgCpIWk+pCotwA+AxjFEJRcr8GV4rAnw/GdCZgSnABaKKBQSM7fBZjwEJSG+RBL0Eq8ak2cAFwB0kJSfUjUWwCfAYxiCEqu1+BKcZi/C4wErRRA7gQUhv/n3woBYniobZWAGGIFDRXfBU4AE1dA0badZCIEaIp/zoGAKmbAa/C/A/tkRMBMU7yWgKGutdT4OlRj1cUzsneBOqzjk4onkWXLWNfxVvncS/ni2FFVjerJp/bhWCypeG0fjsVetLJ3OBxkjX+C2jam+1aomgAAAABJRU5ErkJggg==" />
                                            <span className="text-sm font-bold">Start Date & Time</span>
                                            <span className="text-sm text-neutral-500 mt-1">{`${moment(event?.startDate).format("Do MMM YYYY")}, ${event?.startTime}`}</span>
                                        </div>
                                        <div className="flex flex-col justify-center items-center">
                                            <img className="w-8 h-8" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1UlEQVR4nO2YTUoDQRCFv0tEJOhCPI6ItzEnEDyHgt5AV2IwzEZ3uvAK6k5046pkoAOzSM9Uz09SM10PikB1pbvqzavqTMDhaEIBSMRWZJCfNNiuMXh+EtnIGgFav70DOsIJwBWAtwD61rDXYx3hLYC3AL0q9AxYAr8NPyjEmNXlV9byCJw2FX9Zc8BBJe7QQMFt87uoe/IC/AHnwDz4r4P/LhxSbn4ffFfsHpr8yloWobbSf7Jpo2VYLAOrOAY+NzD5ARyxe6TktwhrD5s2+gmL88ght8BXsJvgswJtfvNQ4zeG7/WhEa1TMjMnQC2NiaFRAVNHKwIKRU+tDMd3JkASB4u1+N4IiCGWoJV41Zo4AbgCpIWk+pCotwA+AxjFEJRcr8GV4rAnw/GdCZgSnABaKKBQSM7fBZjwEJSG+RBL0Eq8ak2cAFwB0kJSfUjUWwCfAYxiCEqu1+BKcZi/C4wErRRA7gQUhv/n3woBYniobZWAGGIFDRXfBU4AE1dA0badZCIEaIp/zoGAKmbAa/C/A/tkRMBMU7yWgKGutdT4OlRj1cUzsneBOqzjk4onkWXLWNfxVvncS/ni2FFVjerJp/bhWCypeG0fjsVetLJ3OBxkjX+C2jam+1aomgAAAABJRU5ErkJggg==" />
                                            <span className="text-sm font-bold">Finish Date & Time</span>
                                            <span className="text-sm text-neutral-500 mt-1">{`${moment(event?.endDate).format("Do MMM YYYY")}, ${event?.endTime}`}</span>
                                        </div>
                                        <div className="flex flex-col justify-center items-center">
                                            <img className="w-8 h-8" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADuElEQVR4nO2ZWYiOURjHfzRkmbHPZOJCWYZCiNwoW7JEGtkaV24VuZG9xnZBcodsxYWILJESLiQiDLIklEhqLFnGvoxPR/+3TtN8531f3znffCO/euvr/f7vc/bnOec58J9G6QBUAbuAq8BL4Luel3q3E5gLlFCAVAB7gM9AJuHzCdgN9KUAaAdsBn6ocr/U6yuB0Wpgez0VercKuCZtRqO1CWjTVI0wPXlHlakH9gO9U3zfBzhgNegyUE6eGao5byrwABiWg63hwEPZegYMJo8jETXiDNDZg80uwFmrMeX5WBN3rEYUebTdCjhnTbOga2azNZ18jERjI/NIZawhEBXyTvU5rok4RsgBfAS6hyhgj3rKeKfQHFRZ20JE7E/qqSQutgxYB9xUz5rnBrAWKE3oUH7pu2I8UqUeMsEujplAnSOivwdmJLBzXfo5eGSXjJqIHdeIKMAdUySPIvsY4LgVQCtjbK2WdofHdvwZiYwq5ppO0UgsceiWSvMO6ObQjZXuCh55JaP9HJp11kjEcSKBi+0nzQs88k1GXVvvWwlGrWFvGweQjRJpvuKRLzLqirYfpEniZaJKmqno2kVkdDTwRq2MugJUXYqGdLA8WDZ6SPMcj9yX0YEOzU1pjHeKY5y0NQ7NEGnu4pGTMjrboVkrzfEU9qodmnnSHMUjG2R0vUNTqqmSkYvNxnJp3gJdHbqNCRqbmtkyarbZLmYo2GXkYsdqzRRrOkUjYTTTY2xdlDZOl4oyFf5V3sRFpYJdti3K2wSV66Sdtnk64pkbqsiUBNpuCnY1cst12jtVx0yniFkqy4yKd6LIvZfwHEmw1v6a/pbvj5teudBRAdhsPnuFKqRGjTGuMRQLVcb5gGWwwEoOhKAIeKwyzBkoGMWWRzJna9/MtbYlJqsSlC0q7HDAqbuCPFBuLcZBHu1OsA5cJo7kha0BRsUs7qD5rMboaWVVRnmwV6lGvA6U+HNSbZ3yWuZgp7WVxF5AE9AWeKIKzM/BzhLZuOc5l/xX+a5anfrSUmq584k0IS2AS6qISXCnZZ++Ndv7Jmeors9+AiNTfDfJuktMc9MVlOiYez/hvYbJojzVN4soIFoDtxMchyO2W+eNXDxeEIZoitXHZFImKf6Y0+YACpQ16uknWbYZZUqBGs1iCpgi4IKVxjFeLcJModP671SD/wqSnlbSe2UjR+XaUFdqIZimdWDWy2Rgqn4bFz2eZka1RuCNUkDm9zKaIS2AQ1Y+q+GaaVaU6Mbpsu9LTf5VfgNttDSN+ivn8AAAAABJRU5ErkJggg==" />
                                            <span className="text-sm font-bold">Location</span>
                                            <span className="text-sm text-neutral-500 mt-1">{event?.address + "," + event?.state + "," + event?.suburb}</span>
                                        </div>


                                        {/* <div className="flex flex-col w-full justify-center px-20"> */}
                                        <div className="flex flex-col w-full justify-center px-20 lg:px-10 xl:px-14">




                                            <button
                                                className={
                                                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#002930] 
w-full h-9 font-medium mt-6`}

                                            >
                                                <span className="block  text-xs font-medium text-white">
                                                    {/* FREE Event */}
                                                    {event?.charge === "Free" ? "Free" : `${event?.ticketCurrency} ${event?.ticketPrice}`}
                                                </span>

                                            </button>



                                            <button
                                                className={
                                                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-gradient-to-r from-cyan-500 from-10% to-[#3c9140] to-90% hover:from-pink-500 hover:to-yellow-500 
w-full h-9 text-slate-50 font-medium mt-4 cursor-pointer`}

                                                onClick={() => {
                                                    console.log(">>>>>", "hello")
                                                }}

                                            >
                                                {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
                                                <span className="block  text-xs font-medium">
                                                    Resgister Now
                                                </span>

                                            </button>


                                        </div>


                                    </div>


                                </div>

                            </div>



                        </div>

                        <div className="w-full">
                            <span className="flex font-bold text-2xl my-6 text-center lg:text-left mt-14 lg:mt-24">Similar Events</span>
                            <SectionSliderEvents />
                        </div>

                    </div>

                </div>


            </div >

        </>
    );

}

export default PageSingleEvent;