import React, { FC, useContext, useEffect, useState, Fragment } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import AvatarDropdown from "./AvatarDropdown";
import Navigation from "shared/Navigation/Navigation";
import CartDropdown from "./CartDropdown";
import { XMarkIcon } from "@heroicons/react/24/outline";
import AuthContext from "Context/AuthContext";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import StepsContext from "Context/StepsContext";
import { useTranslation } from 'react-i18next';
import i18n from "i18n";
import { Dialog, Popover, Transition } from "@headlessui/react";
import Checkbox from "shared/Checkbox/Checkbox";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import WishContext from "Context/WishContext";
import ModalQuickView from "components/ModalJob";
import { addToast } from "shared/Toast";


export interface MainNav2LoggedProps { }

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {



  const { t } = useTranslation();
  const inputRef = React.createRef<HTMLInputElement>();
  const [showSearchForm, setShowSearchForm] = useState(false);

  const { user, setUser } = useContext(AuthContext);

  const [userD, setUserD] = React.useState<any | null>("");

  const history = useHistory();

  const location = useLocation()
  const path = location.pathname;

  const { selTab, setselTab } = useContext(StepsContext);

  const { lan, setLan } = useContext(WishContext);

  const [showModalQuickView, setShowModalQuickView] = useState(false);



  useEffect(() => {
    setselTab(path);
  }, [path])



  React.useEffect(() => {
    let userNew: any = localStorage.getItem(`${window.location.hostname}.userDetails`)

    userNew = JSON.parse(userNew);

    // console.log("--------->", user);
    // const response = window.localStorage.getItem(
    //   `${window.location.hostname}.userDetails`
    // );
    // setUserD(JSON.parse(response));
    let obj: any = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
    }

    if (userNew) {
      setUserD(obj);
    } else {
      setUserD("none");
    }


  }, [user]);

  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderSearchForm = () => {
    return (
      <form
        action=""
        method="POST"
        className="flex-1 py-2 text-slate-900 dark:text-slate-100"
      >
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1.5 px-5 h-full rounded">
          {renderMagnifyingGlassIcon()}
          <input
            ref={inputRef}
            type="text"
            placeholder="Type and press enter"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
            autoFocus
          />
          <button onClick={() => setShowSearchForm(false)}>
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
        <input type="submit" hidden value="" />
      </form>
    );
  };

  const renderContent = () => {
    return (
      <div className="h-20 flex justify-between">
        <div className="flex items-center hidden xl:hidden flex-1">
          <MenuBar />
        </div>

        <div className=" flex items-center xl:-ml-3 2xl:mr-6 justify-start w-full xl:justify-start xl:w-fit">
          <Logo className="flex-shrink-0" />
        </div>

        <div className="flex hidden xl:flex justify-center mx-2 z-[2]">
          {showSearchForm ? renderSearchForm() : <Navigation />}

          <div
            style={{ display: "none" }}
          >
            <NavLink to={"/profile"}><a>Profile</a></NavLink>
            <NavLink to={"/update-password"}><a>Profile</a></NavLink>
            <NavLink to={"/reset-password-otp"}><a>Profile</a></NavLink>


          </div>
        </div>

        <div className="flex items-center justify-end text-slate-700 dark:text-slate-100">
          {/* {!showSearchForm && (
            <button
              className="hidden lg:flex w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none items-center justify-center"
              onClick={() => setShowSearchForm(!showSearchForm)}
            >
              {renderMagnifyingGlassIcon()}
            </button>
          )} */}

          {

            // user?.userType !== "service provider" &&

            <>
              {/* <Popover className="relative mr-4">
                {({ open, close }) => (
                  <>
                    <Popover.Button
                      className={`flex items-center justify-between px-2 min-w-[150px] h-9 text-sm rounded-full border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${false ? "!border-primary-500 bg-primary-50 text-primary-900"
                          : "bg-[#b69d52] border-[#b69d52] dark:border-neutral-700 text-white dark:text-neutral-300 dark:hover:border-neutral-500"
                        }
                `}
                    >
                     

                      <span className={`ml-2 whitespace-nowrap text-white ${t("langKey") === "Si" ? "text-xs" : "text-xs sm:text-sm"}`} > {t('hLang')}
                      </span>

                      <ChevronDownIcon className="w-4 h-4 ml-3" />

                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                        <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                          <div className="relative flex flex-col px-5 py-6 space-y-5">


                            {["English", "Sinhala"].map((item: any) => (
                              <div key={item} className="">
                                <Checkbox
                                  name={item}
                                  label={item === "Sinhala" ? "සිංහල" : item}
                                  checked={lan === item}
                                  // defaultChecked={categoriesState.includes(item.mainId)}
                                  sizeClassName="w-5 h-5"
                                  labelClassName="text-sm font-normal"
                                  onChange={(checked) => {

                                    // localStorage.setItem("lan", item);
                                    setLan(item)

                                    const el = document.querySelector("body") as HTMLElement;
                                    

                                    if (item === "English") {
                                      i18n.changeLanguage('en');

                                      el.style.fontFamily = 'Inter';
                                    } else {
                                      i18n.changeLanguage('pl');
                                      el.style.fontFamily = 'NotoSans';
                                    }

                                    close();

                                  }}
                                // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                                />

                              </div>
                            ))}
                           
                          </div>
                         
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )
                }
              </Popover > */}

              {/* <NavLink to={"/signup"}> */}

              {/* </NavLink> */}

            </>

          }

          <button
            className={
              `ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70 
w-3xl px-12 h-9 font-medium cursor-pointer w-2xl bg-white border-2 border-redF mr-4 hidden md:flex`}

            onClick={() => {

              history.push("/signup");

              // if (!user) {
              //   addToast("Please Login", "warning");
              //   history.push("/login");

              //   return;
              // } else if (user?.userType === "service provider") {
              //   addToast("Please login as a proposal seeker", "warning");
              //   history.push("/login");
              //   return;

              // }

              // setShowModalQuickView(true)
            }}

          >

            <span className={`block  ${t("langKey") === "Si" ? "text-xs" : "text-sm"} font-medium whitespace-nowrap`}>
              {/* Publish Proposal Ad */}
              {"Create my profile"}
            </span>

          </button>





          {
            userD !== "" && userD === "none" ?

              <div className="h-full flex items-center justify-center hidden lg:flex space-x-3">
                {/* <NavLink to={"/signup"}>
                  <button
                    className={
                      `ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70 
        w-3xl px-7 h-9 font-medium cursor-pointer w-2xl bg-[#2d867b] border-2 border-[#2d867b]`}



                  >

                    <span className="block  text-sm font-medium whitespace-nowrap text-white">
                     
                      {t('hPublish')}
                    </span>

                  </button>
                </NavLink> */}
                <NavLink to={"/login"}>
                  <button
                    className={
                      `ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70 
         h-9 font-medium cursor-pointer min-w-[130px] px-4 bg-redF text-white`}



                  >

                    <span className={`block ${t("langKey") === "Si" ? "text-xs" : "text-sm"} font-medium whitespace-nowrap`}>
                      {"Sign In"}
                      {/* Login */}
                    </span>

                  </button>
                </NavLink>
                {/* <NavLink to={"/login"}>
                  <span className="ml-4 text-[14px]">Login</span>
                </NavLink> */}
              </div>

              :
              <>
                <div className="h-full flex items-center justify-center mr-7 hidden lg:flex">


                  <button
                    onClick={() => {
                      console.log("logout>>>>")
                      window.localStorage.removeItem(
                        `${window.location.hostname}.login`
                      );
                      window.localStorage.removeItem("exprTime");
                      window.localStorage.clear();
                      history.push("/");
                      window.location.reload();
                    }}

                    className={
                      `ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70 
      w-3xl px-7 h-9  font-medium cursor-pointer w-2xl bg-white border-2 border-[#2d867b]`}



                  >

                    <span className={`block  ${t("langKey") === "Si" ? "text-xs" : "text-sm"} font-medium whitespace-nowrap`}>
                      {t('hLogout')}
                    </span>

                  </button>


                </div>



              </>



          }

          {/* {
            userD != "" && userD != "none" &&

            <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300 lg:hidden mr-2"
              onClick={() => {
                console.log("logout>>>>")
                window.localStorage.removeItem(
                  `${window.location.hostname}.login`
                );
                window.localStorage.removeItem("exprTime");
                window.localStorage.clear();
                history.push("/");
                window.location.reload();
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.90002 7.55999C9.21002 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24002 20.08 8.91002 16.54"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 12H3.62"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.85 8.6499L2.5 11.9999L5.85 15.3499"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>

          } */}

          {
            user &&
            <div className="hidden lg:flex">
              <AvatarDropdown />
            </div>
          }

          <div className="lg:hidden">
            <AvatarDropdown />

          </div>

          {/* <CartDropdown /> */}
        </div>
      </div>
    );
  };

  return (
    <>
      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
        selectedJob={""}
      />

      <div className="nc-MainNav2Logged relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">
        <div className="container">{renderContent()}</div>
      </div>
    </>
  );
};

export default MainNav2Logged;
