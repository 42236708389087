import jwtDecode from "jwt-decode";
import Axios from "../../Axios";

const getToken = localStorage.getItem(`${window.location.hostname}.login`);
let myId = "";
if (getToken) {
  const { data } = JSON.parse(getToken);
  const { id } = jwtDecode(data);
  myId = id;
}


export const EditUserQuery = async (obj) => {
  return await Axios({
    pathname: `api/v1/user/${myId}`,
    obj: obj,
    method: "put",
  });
};
export const EditUserBilling = async (obj, id = null) => {
  return await Axios({
    pathname: `api/v1/billingInformation/${id ? id : myId}`,
    obj: obj,
    method: "put",
  });
};
export const ChangePasswordQuery = async (obj) => {
  return await Axios({
    pathname: `api/v1/user/${myId}`,
    obj: obj,
    method: "put",
  });
};

export const EditBillInfo = async (obj, id) =>
  await Axios({
    pathname: `api/v1/billinginformation/${id}`,
    obj: obj,
    method: "put",
  });
export const EditShipInfo = async (obj, id) =>
  await Axios({
    pathname: `api/v1/shippingInformation/${id}`,
    obj: obj,
    method: "put",
  });

export const UpdateCartItems = async (obj, id) =>
  await Axios({
    pathname: `api/v1/cart/${id}`,
    obj: obj,
    method: "put",
  });

export const UpdateUserwithemail = async (obj) =>
  await Axios({
    pathname: `api/v1/user/updateWithEmail/${myId}`,
    obj: obj,
    method: "put",
  });

export const setSiteVisits = async () => {
  return await Axios({
    pathname: "api/v1/settings/sitevisits",
    method: "put",
  });
};

export const UpdateFAQs = async (obj, id) =>
  await Axios({
    pathname: `api/v1/faq/${id}`,
    obj: obj,
    method: "put",
  });

export const UpdateJobs = async (obj, id) =>
  await Axios({
    pathname: `api/v1/job/${id}`,
    obj: obj,
    method: "put",
  });

export const UpdateEvents = async (obj, id) =>
  await Axios({
    pathname: `api/v1/event/${id}`,
    obj: obj,
    method: "put",
  });

export const UpdatePackage = async (obj, id) =>
  await Axios({
    pathname: `api/v1/user/package/${id}`,
    obj: obj,
    method: "put",
  });

export const UpdateProduct = async ({ obj, productId }) =>
  await Axios({
    pathname: `api/v1/product/${productId}`,
    obj: obj,
    method: "PUT",
  });

export const EditMCategory = async ({ obj, id }) =>
  await Axios({
    pathname: `api/v1/category/${id}`,
    obj: obj,
    method: "PUT",
  });

export const EditSubCategory = async ({ obj, id }) =>
  await Axios({
    pathname: `api/v1/subcategory/${id}`,
    obj: obj,
    method: "PUT",
  });

export const UpdateContract = async (obj, id) =>
  await Axios({
    pathname: `api/v1/serviceseeker/${id}`,
    obj: obj,
    method: "put",
  });

export const UpdateViews = async (id) =>
  await Axios({
    pathname: `api/v1/serviceseeker/updateviews/${id}`,
    obj: {},
    method: "put",
  });

export const UpdateCleanType = async (obj, id) =>
  await Axios({
    pathname: `api/v1/cleaningtype/${id}`,
    obj: obj,
    method: "put",
  });

export const UpdateOrderStatus = async (obj, id) =>
  await Axios({
    pathname: `api/v1/orderhistory/orderStatus/${id}`,
    obj: obj,
    method: "put",
  });


export const SubmitQuote = async (obj, id) =>
  await Axios({
    pathname: `api/v1/orderhistory/sumbitQuote/${id}`,
    obj: obj,
    method: "put",
  });

export const UpdateShippingCost = async (obj, id = myId) =>
  await Axios({
    pathname: `api/v1/user/shippingcost/${id}`,
    obj: obj,
    method: "put",
  });


export const UpdateQuotationStatus = async (obj, id) =>
  await Axios({
    pathname: `api/v1/applicant/changeQuotationStatus/${id}`,
    obj: obj,
    method: "put",
  });


export const UpdateJobStatus = async (obj, id) =>
  await Axios({
    pathname: `api/v1/job/updateStatus/${id}`,
    obj: obj,
    method: "put",
  });

export const UpdateContractStatus = async (obj, id) =>
  await Axios({
    pathname: `api/v1/serviceseeker/updateStatus/${id}`,
    obj: obj,
    method: "put",
  });

export const UpdateProductSub = async (obj, id) =>
  await Axios({
    pathname: `api/v1/product/subcategory/${id}`,
    obj: obj,
    method: "put",
  });



export const UpdateProfiles = async (obj, id) =>
  await Axios({
    pathname: `api/v1/member/${id}`,
    obj: obj,
    method: "put",
  });

export const UpdateAdStatus = async (obj, id) =>
  await Axios({
    pathname: `api/v1/ad/updateStatus/${id}`,
    obj: obj,
    method: "put",
  });


export const UpdateAds = async (obj, id) =>
  await Axios({
    pathname: `api/v1/ad/${id}`,
    obj: obj,
    method: "put",
  });

export const UpdatePackageService = async (obj, id) =>
  await Axios({
    pathname: `api/v1/servicePackage/${id}`,
    obj: obj,
    method: "put",
  });

export const UpdateJobSeeker = async (obj, id) =>
  await Axios({
    pathname: `api/v1/user/jobseeker/${id}`,
    obj: obj,
    method: "put",
  })

export const UpdateJobSeekerPersonalDetails = async (obj, id) =>
  await Axios({
    pathname: `api/v1/user/jobseeker/personal-details/${id}`,
    obj: obj,
    method: "put",
  })
export const UpdateJobSeekerExperiences = async (obj, id) =>
  await Axios({
    pathname: `api/v1/user/jobseeker/experinces/${id}`,
    obj: obj,
    method: "put",
  })

export const UpdateJobSeekerQualifications = async (obj, id) =>
  await Axios({
    pathname: `api/v1/user/jobseeker/qualification/${id}`,
    obj: obj,
    method: "put",
  })

export const UpdateJobSeekeAchievements = async (obj, id) =>
  await Axios({
    pathname: `api/v1/user/jobseeker/achievements/${id}`,
    obj: obj,
    method: "put",
  })
