import React, { FC, useState, useEffect, useRef } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL } from "Constant/System";
import _ from "lodash";
import { addToast } from "shared/Toast";
import { CreateMain, CreateSubCategory, postBlogs, postJobs } from "Services/API/Post";
import { GetUserId } from "Services/API/Get";


const Blogs = (props) => {

    const [images, setImages] = useState([]);

    const [title, settitle] = useState("");
    const [blogType, setblogType] = useState("");
    const [description, setdescription] = useState("");
    const [Keywords, setKeywords] = useState("");

    const [errimages, seterrImages] = useState([]);

    const [errtitle, seterrtitle] = useState(false);
    const [errblogType, seterrblogType] = useState(false);
    const [errdescription, seterrdescription] = useState(false);
    const [errKeywords, seterrKeywords] = useState(false);


    const saveBlog = (e) => {

        e.preventDefault();

        if (!title) {
            seterrtitle(true);
        }

        // if (!blogType) {
        //     seterrblogType(true);
        // }

        // if (!description) {
        //     seterrdescription(true);
        // }

        // if (!Keywords) {
        //     seterrKeywords(true);
        // }
        // if (images.length === 0) {
        //     seterrImages(true);
        // }

        console.log(">>>>>>>>", _.filter(Keywords.split(" ")))

        console.log(">>>>>>", props?.type)



        let obj = {
            name: title,
            categoryType: props?.type === "1" ? "subcategory1" : props?.type === "2" ? "subcategory2" : props?.type === "3" ? "subcategory3" : "",
            userId: GetUserId(),
        }

        console.log("obj>>>>", obj)

        if (
            title
            // description &&
            // Keywords &&
            // blogType
            // images.length > 0
        ) {
            if (props.type === "main") {

                CreateMain({ obj })
                    .then((res) => {
                        // setlopen(false);
                        addToast("Category successfully added", "success");
                        settitle("");
                        setdescription("");
                        setblogType("");
                        setImages([]);
                        setKeywords([]);
                        props?.onCloseModalQuickView();

                        // setOpenToast(true);
                        // setColor(COLORS.Primary);
                        // setAlertClass("success");
                        // setMsg("Successfully updated");
                        // getCurrentUser();
                        // seteDisable(true);
                        // getUser();

                        // history.push("/");
                        // window.location.reload();
                    })
                    .catch((error) => {
                        // setlopen(false);
                        console.log("Error", error.response);
                        addToast(error.response.data.message, "warning");
                        // setOpenToast(true);
                        // setColor(COLORS.Orange);
                        // setAlertClass("error");
                        // setMsg(error.response.data.message);
                    })

            } else {

                CreateSubCategory({ obj })
                    .then((res) => {
                        // setlopen(false);
                        addToast("Category successfully added", "success");
                        settitle("");
                        setdescription("");
                        setblogType("");
                        setImages([]);
                        setKeywords([]);
                        props?.onCloseModalQuickView();

                        // setOpenToast(true);
                        // setColor(COLORS.Primary);
                        // setAlertClass("success");
                        // setMsg("Successfully updated");
                        // getCurrentUser();
                        // seteDisable(true);
                        // getUser();

                        // history.push("/");
                        // window.location.reload();
                    })
                    .catch((error) => {
                        // setlopen(false);
                        console.log("Error", error.response);
                        addToast(error.response.data.message, "warning");
                        // setOpenToast(true);
                        // setColor(COLORS.Orange);
                        // setAlertClass("error");
                        // setMsg(error.response.data.message);
                    })

            }

        }


    }




    return (
        <>
            <div className="flex justify-center w-full mt-2">

                <span className="block text-xl font-bold flex justify-center w-full text-center">
                    {
                        props.type === "main" ? "Add Main Category" : "Add Sub Category"
                    }

                </span>


            </div>
            <div className="w-full flex justify-center px-3">

                <div className="w-full md:w-11/12 lg:w-8/12">

                    <div className="w-full mt-6 space-y-4">

                        <div>
                            <Label className="text-sm">Name</Label>
                            <Input className="mt-1.5"
                                onChange={(e) => {
                                    settitle(e.target.value);
                                    seterrtitle(false);
                                }}
                                value={title}
                            />
                            {errtitle && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>


                        <div className="flex w-full items-center justify-center">
                            <button
                                className={
                                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-4 `}

                                onClick={saveBlog}
                            >
                                {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                                <span className="block  text-sm font-medium "
                                >
                                    Save
                                </span>

                            </button>
                        </div>

                    </div>

                </div>
            </div>

        </>
    )

}

export default Blogs;