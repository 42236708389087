import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { PACKAGES } from "Constant/packages";
import AuthContext from "Context/AuthContext";
import { CurrentUser } from "Services/API/Get";
import { UpdatePackage } from "Services/API/Put";
import React, { FC, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import LoadingSpinner from "shared/LoadingSpinner";

export interface PageSubcriptionProps {
  className?: string;
}

export interface PricingItem {
  isPopular: boolean;
  name: string;
  pricing: string;
  desc: string;
  per: string;
  features: string[];
}

const pricings: PricingItem[] = [
  {
    isPopular: false,
    name: "Starter",
    pricing: "FREE",
    per: "/ Up to 7 days",
    features: ["Marketplace access", "Event posting", "Blog posting", "Farming forum access and consultancy", "Online shop", "Payment gateway", "Unlimited", "Inventory management", "Automated shipping cost", "Automated notifications", "3.99%"],
    desc: ` Literally you probably haven't heard of them jean shorts.`,
  },
  {
    isPopular: true,
    name: "Basic",
    pricing: "$15",
    per: "/ Up to 28 days",
    features: ["Marketplace access", "Event posting", "Blog posting", "Farming forum access and consultancy", "Online shop", "Payment gateway", "Unlimited", "Inventory management", "Automated shipping cost", "Automated notifications", "3.99%"],
    desc: ` Literally you probably haven't heard of them jean shorts.`,
  },
  {
    isPopular: false,
    name: "Plus",
    pricing: "$30",
    per: "/ Up to 84 days",
    features: ["Marketplace access", "Event posting", "Blog posting", "Farming forum access and consultancy", "Online shop", "Payment gateway", "Unlimited", "Inventory management", "Automated shipping cost", "Automated notifications", "3.99%"],
    desc: ` Literally you probably haven't heard of them jean shorts.`,
  },
];

const PageSubcription: FC<PageSubcriptionProps> = ({ className = "" }) => {
  const { user, setUser } = useContext(AuthContext);

  const [currUser, setcurrUser] = useState<any>("");

  const history = useHistory();

  const [lOpen, setlOpen] = useState(false);

  const renderPricingItem = (pricing: any, index: any) => {
    return (
      <div
        key={index}
        className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden ${pricing.isPopular
          ? "border-[#2d867b]"
          : "border-neutral-100 dark:border-neutral-700"
          }`}
      >
        {pricing.isPopular && (
          <span className="bg-[#2d867b] text-white px-3 py-1 tracking-widest text-[10.8px] absolute right-3 top-3 rounded-full z-10">
            POPULAR
          </span>
        )}
        <div className="mb-8">
          <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
            {pricing.name}
          </h3>
          <h2 className="text-5xl leading-none flex items-center text-slate-800 dark:text-slate-200">
            <span>{pricing.pricing}</span>
            {
              index != 0 &&

              <span className="text-base ml-1 font-normal text-neutral-500">
                {/* {pricing.per} */}
                &nbsp;
                per Ad
              </span>


            }

          </h2>
        </div>
        <nav className="space-y-4 mb-8">
          <li className="flex items-start" >
            <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
              <CheckIcon className="w-5 h-5 text-[#2d867b]" aria-hidden="true" />
            </span>
            <span className="text-neutral-700 dark:text-neutral-300">
              One ad posting (job ad or marketplace listing) up &nbsp;
              {
                index === 0 ?

                  <b>to 7 days.</b>

                  : index === 1 ?

                    <b>to 28 days.</b>

                    :

                    <b>to 84 days.</b>
              }


            </span>
          </li>
          <li className="flex items-start" >
            <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
              {/* <CheckIcon className="w-5 h-5" aria-hidden="true" /> */}
              {
                index === 0 ?
                  <>
                    <XMarkIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
                  </>




                  :

                  <CheckIcon className="w-5 h-5 text-[#2d867b]" aria-hidden="true" />
              }
            </span>
            <span className="text-neutral-700 dark:text-neutral-300">
              Showcase in the homepage results &nbsp;
              {/* {
                index === 0 ?
                  <>
                    {"- "}
                    <b className="text-red-500">Unavailable</b>
                  </>


                  : index === 1 ?

                    <>
                      {"- "}
                      <b className="text-red-500">Unavailable</b>
                    </>

                    :

                    <></>
              } */}


            </span>
          </li>
          <li className="flex items-start" >
            <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
              {/* <CheckIcon className="w-5 h-5" aria-hidden="true" /> */}
              {
                index === 0 || index === 1  ?
                  <>
                    <XMarkIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
                  </>




                  :

                  <CheckIcon className="w-5 h-5 text-[#2d867b]" aria-hidden="true" />
              }
            </span>
            <span className="text-neutral-700 dark:text-neutral-300">
              Priority listing in search results &nbsp;
              {/* {
                index === 0 ?
                  <>
                    {"- "}
                    <b className="text-red-500">Unavailable</b>
                  </>

                  :

                  <></>
              } */}


            </span>
          </li>
        </nav>
        {/* <hr className="border-slate-400 dark:border-slate-700" />
        <nav className="space-y-4 mb-8 mt-8">
          {pricing.features.map((item: any, index: any) => (
            <li className="flex items-start" key={index}>
              <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
                <CheckIcon className="w-5 h-5 text-[#2d867b]" aria-hidden="true" />
              </span>
              {
                item.includes("3.99") ?
                  <>
                    <span className="text-neutral-700 dark:text-neutral-300">
                      <b>
                        {item}&nbsp;
                      </b>

                      Transaction fee
                    </span>
                  </>
                  : item.includes("Unlimited") ?
                    <>
                      <span className="text-neutral-700 dark:text-neutral-300">
                        <b>
                          {item}&nbsp;
                        </b>

                        products & services listing in the online store
                      </span>

                    </>

                    :
                    <span className="text-neutral-700 dark:text-neutral-300">
                      {item}
                    </span>

              }

            </li>
          ))}
        </nav> */}
        <div className="flex flex-col mt-auto">

          {pricing.isPopular ? (
            <ButtonPrimary onClick={() => packageSelect(pricing)}>Submit</ButtonPrimary>
          ) : (
            <ButtonSecondary onClick={() => packageSelect(pricing)}>
              <span className="font-medium" >Submit</span>
            </ButtonSecondary>
          )}
          {/* <p className="text-[10.8px] text-neutral-500 dark:text-neutral-400 mt-3">
            {pricing.desc}
          </p> */}
        </div>
      </div>
    );
  };

  const packageSelect = (item?: any) => {

    console.log("pkg>>>>", item)

    let obj = {
      email: currUser?.email,
      amountPayable: item.pricing.split("$")[1],
      packageDetails: [
        {
          packagePrice: item.pricing.split("$")[1],
          qty: 1,
          packageName: item.name + " " + "Subscription",
        }
      ],
      packageId: item.id,
      userId: currUser?.id,
    }

    console.log("obj>>>", obj)

    setlOpen(true);

    UpdatePackage(obj, currUser?.id)
      .then((res) => {

        console.log("res>>>>", res.data)

        if (res.data !== undefined) {
          if (res.data === "success") {
            history.push("/profile");
          } else {
            window.location.href = res.data;
          }

        }

      })
      .catch((error) => {
        setlOpen(false);
        console.log("error", error);
        console.log("current user error", error.response.data.message);
        if (error.response.data.statusCode === 401) {
          history.push("/login");
        }
      });

  }

  const getCurrentUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          setUser(cData);
          const obj = {
            id: cData.id,
            email: cData?.email,
            businessName: cData?.businessName,
            contactName: cData?.contactName,
            phone: cData?.mobileNumber,
          };
          //   this.setState({
          //     businessName: cData?.businessName,
          //     contactName: cData?.contactName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });
          console.log("<><><><>", cData);
          setcurrUser(cData);
          // setName(cData?.businessName + " " + cData?.contactName);
          // setfirstName(cData?.firstName);
          // setlastName(cData?.lastName);
          // setcategory(cData?.supplierCategory !== null ? cData?.supplierCategory : "");
          // setbusinessName(cData?.businessName !== null ? cData?.businessName : "");
          // setbusinessReg(cData?.businessRegistrationNumber !== null ? cData?.businessRegistrationNumber : "");
          // setyear(cData?.establishedYear !== null ? cData?.establishedYear : "");
          // setwebsite(cData?.website !== null ? cData?.website : "");
          // setcountry(cData?.country !== null ? cData?.country : "");
          // setmobile(cData?.contactNumber !== null ? cData?.contactNumber : "");
          // setmobile2(cData?.contactNumber2 !== null ? cData?.contactNumber2 : "");
          // setcontactName(cData?.contactName !== null ? cData?.contactName : "");
          // setemail(cData?.email);
          // setpremail(cData?.email);
          // setabout(cData?.about !== null ? cData?.about : "");
          // sethomeDistrict(cData?.homeDistrict !== null ? cData?.homeDistrict : "");
          // sethomeAddress(cData?.homeAddress !== null ? cData?.homeAddress : "");
          // sethomeCity(cData?.homeCity !== null ? cData?.homeCity : "");
          // sethomeNo(cData?.homeNumber !== null ? cData?.homeNumber : "");
          // sethomepostCode(cData?.homepostCode !== null ? cData?.homepostCode : "");
          // setuserId(cData?.id);


          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };

  React.useEffect(() => {
    getCurrentUser();

  }, []);

  return (
    <>
      <LoadingSpinner open={lOpen} />
      <div className="w-full h-[220px] md:h-[330px] xl:h-[320px] 2xl:h-[350px] bg-cover bg-center bg-slate-800 relative z-[1]"
      // style={{
      //   backgroundImage: `url(${cover})`
      // }}
      >
        <div className="w-full h-full flex justify-center items-center">
          <div className="" >
            <h2 className="nc-SectionHero2Item__heading font-semibold text-xl sm:text-2xl md:text-2xl xl:text-4xl 2xl:text-5xl !leading-[114%] text-slate-50 flex justify-center">
              Pricing
            </h2>
            <span className="nc-SectionHero2Item__subheading block text-sm md:text-base text-slate-100 font-medium flex justify-center mt-6 max-w-3xl text-center px-3 lg:px-0">
              If you have any questions and need some advices feel free to write your in the members wall and seek members support and
              advices.
            </span>
            <div className="w-full flex items-center justify-center">
              <button
                className={
                  `ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70 
        w-3xl px-6 h-9 text-slate-50 font-medium mt-2 cursor-pointer w-2xl bg-[#2d867b] mt-12`}



              >

                <span className="block  text-sm font-medium">
                  FREE Sign up
                </span>

              </button>

            </div>


          </div>

        </div>



      </div>
      <div
        className={`nc-PageSubcription container pb-24 lg:pb-32 ${className}`}
        data-nc-id="PageSubcription"
      >
        {/* <header className="text-center max-w-2xl mx-auto my-20">
          <h2 className="flex items-center text-2xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          
            Subscription
          </h2>
          <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
            Pricing to fit the needs of any companie size.
          </span>
        </header> */}
        <section className="text-neutral-600 text-sm md:text-base overflow-hidden pt-12">
          <div className="grid lg:grid-cols-3 gap-5 xl:gap-8">
            {PACKAGES.map(renderPricingItem)}
          </div>
        </section>
      </div>
    </>

  );
};

export default PageSubcription;
