import React, { Fragment, useState, FC, useContext } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
import Radio from "shared/Radio/Radio";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import MySwitch from "components/MySwitch";
import { SectionSliderProductCardProps } from "./SidebarFilters";
import {
  getCities,
  getDistricts,
  getProviderCategory,
  getProviderSubCategoryById,
  getSriCities,
  getSriDistricts
} from "Services/API/Get";
import { Autocomplete } from "@mui/material";
import Input from "shared/Input/Input";
import SubData from "data/australian_postcodes.json";
import _, { lowerCase, upperFirst } from "lodash";
import { useTranslation } from "react-i18next";
import WishContext from "Context/WishContext";

// DEMO DATA
const DATA_categories = [
  {
    name: "New Arrivals"
  },
  {
    name: "Sale"
  },
  {
    name: "Backpacks"
  },
  {
    name: "Travel Bags"
  },
  {
    name: "Laptop Sleeves"
  },
  {
    name: "Organization"
  },
  {
    name: "Accessories"
  }
];

const DATA_colors = [
  { name: "White" },
  { name: "Beige" },
  { name: "Blue" },
  { name: "Black" },
  { name: "Brown" },
  { name: "Green" },
  { name: "Navy" }
];

const JOBTYPES = [
  {
    name: "Seasonal",
    value: "Seasonal",
    id: 5
  },
  {
    name: "Casual",
    value: "Casual",
    id: 1
  },
  {
    name: "Temporary",
    value: "Temporary",
    id: 2
  },
  {
    name: "Fixed term contract",
    value: "Fixed",
    id: 3
  },
  {
    name: "Permanent – Part Time",
    value: "Part Time",
    id: 4
  },
  {
    name: "Permanent – Full time",
    value: "Full Time",
    id: 4
  }
];

const SELLER_TYPES = [
  {
    name: "Wholesaler",
    value: "wholesaler",
    id: 2
  },
  {
    name: "Retailer",
    value: "retail supplier",
    id: 1
  }
  // {
  //   name: "Service Provider",
  //   value: "service provider",
  //   id: 3,
  // },
  // {
  //   name: "Service Seeker/Buyer",
  //   value: "service seeker",
  //   id: 4,
  // },
];

const DATA_sizes = [
  { name: "XXS" },
  { name: "XS" },
  { name: "S" },
  { name: "M" },
  { name: "L" },
  { name: "XL" },
  { name: "2XL" }
];

const DATA_sortOrderRadios = [
  // { name: "Most Popular", id: "featured" },
  // { name: "Best Rating", id: "Best-Rating" },
  // { name: "Newest", id: "latest" },
  { name: "Name A - Z", id: "LowToHigh" },
  { name: "Name Z - A", id: "HighToLow" }
];

const DATA_sortOrderRadios2 = [
  // { name: "Most Popular", id: "featured" },
  // { name: "Best Rating", id: "Best-Rating" },
  // { name: "Newest", id: "latest" },
  { name: "Hourly Rate Low - High", id: "LowToHigh" },
  { name: "Hourly Rate High - Low", id: "HighToLow" },
  { name: "Newest - Older", id: "latest" },
  { name: "Older - Newest", id: "older" }
];

const DATA_sortOrderRadios3 = [
  // { name: "Most Popular", id: "featured" },
  // { name: "Best Rating", id: "Best-Rating" },
  // { name: "Newest", id: "latest" },
  // { name: "Name A - Z", id: "LowToHigh" },
  // { name: "Name Z - A", id: "HighToLow" },
  { name: "Newest - Older", id: "latest" },
  { name: "Older - Newest", id: "older" }
];

const DATA_sortOrderRadios3Si = [
  // { name: "Most Popular", id: "featured" },
  // { name: "Best Rating", id: "Best-Rating" },
  // { name: "Newest", id: "latest" },
  // { name: "Name A - Z", id: "LowToHigh" },
  // { name: "Name Z - A", id: "HighToLow" },
  { name: "නවතම - පැරණි", id: "latest" },
  { name: "පැරණි - නවතම", id: "older" }
];

const AGEarr = [
  "18+",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "69",
  "70",
  "71",
  "72",
  "73",
  "74",
  "75",
  "75+"
];

const CASTarr = [
  // "Any",
  "Bathagama",
  "Choliya",
  "Dewa",
  "Colombo Chetti",
  "Durawa",
  "Govigama",
  "Hunu",
  "Karawa",
  "Kumbal",
  "Nekath Oli",
  "Panna",
  "Radala",
  "Rajaka",
  "Salagama",
  "Vishwa",
  "Other"
];

const RACEarr = ["Sinhalese", "Tamil", "Muslim", "Burgher"];

const RELIGIONarr = ["Buddhism", "Christian", "Islam", "Hindu", "Other"];

const EDUarr = [
  "Up to O/L",
  "Up to A/L",
  "Diploma",
  "Higher Diploma",
  "Bachelor Degree",
  "Master Degree",
  "PhD"
];

const OCUarr = [
  "Accountant (ගණකාධිකාරි)",
  "Admin Executive ",
  "Admin Manager ",
  "Agriculture Manager (කෘෂිකාර්මික කළමනාකරු)",
  "Agriculture Officer (කෘෂිකර්ම නිලධාරී)",
  "Air Craft Engineer (ගුවන් සේවා ඉන්ජිනේරු)",
  "Air Hostest (ගුවන් සේවිකා)",
  "Architecture (ගෘහනිර්මාණ ශිල්පී)",
  "Assistant (සහයක)",
  "Assistant Bank Manager (සහකාර බැංකු නිලධාරී )",
  "Assistant HR Manager (සහකාර මානව සම්පත් කළමනාකරු)",
  "Assistant Marketing Manager (සහකාර අලෙවි කළමනාකරු)",
  "Assistant Sales Manager (සහකාර විකුණුම් කළමනාකරු)",
  "Assistant T & D Manager (සහකාර  පුහුණු සහ සංවර්ධන කළමනාකරු)",
  "Assitant Manager - Procument ",
  "Auditor (විගණක)",
  "Bank Manager (බැංකු කළමනාකරු)",
  "Bank Officer (බැංකු නිලධාරී )",
  "Business Analysts ",
  "Businessman ( ව්‍යාපාරික)",
  "Civil Engineer (සිවිල් ඉන්ජිනේරු)",
  "Doctor (වෛද්‍ය)",
  "Draftman ",
  "Driver (රියදුරු )",
  "Electrical Engineer (විදුලි  ඉන්ජිනේරු)",
  "English Teacher (ඉංග්‍රීසි ගුරුවරයකු)",
  "Entrepreneur (ව්‍යවසායක)",
  "Executive - Marketing (අලෙවි නිලධාරී)",
  "Finance Manager (මුල්‍ය කළමනාකරු)",
  "Financial Controller (මුල්‍ය පාලක)",
  "Government Employee (රාජ්‍ය නිලධාරී)",
  "Government Sector Manager ",
  "Head of Marketing (අලෙවි ප්‍රධානි )",
  "Head of Sales (විකුණුම් ප්‍රධානි)",
  "HR Executive (මානව සම්පත් විධායක )",
  "HR Manager (මානව සම්පත් කළමනාකරු)",
  "IT Business Analysts ",
  "IT Manager (තොරතුරු තාක්ෂණික කළමනාකරු)",
  "IT Professional (තොරතුරු තාක්ෂණික වෘතියවේදී )",
  "Junior Manager (කණිෂ්ඨ කළමනාකරු)",
  "Lawyer (නීතිඥ)",
  "Lecturer (කථිකාචාර්ය)",
  "Manager - Audit (විගණන කළමනාකරු)",
  "Manager (කළමනාකරු)",
  "Marketing Manager (අලෙවි කළමනාකරු )",
  "Nurse (හෙදියක)",
  "Other (වෙනත්)",
  "Pilot (ගුවන් නියමු)",
  "Policeman (පොලිස් නිලධාරී)",
  "Policewoman (පොලිස් නිලධාරීනි)",
  "Pre-School Teacher (පෙර පාසල්  ගුරුවරි)",
  "Principle (විදුහල්පති )",
  "Private Sector Manager ",
  "Procument Executive ",
  "Procument Manager (",
  "Receptionist (පිළිගැනීමේ නිලධාරී)",
  "Sales Executive ( විකුණුම් නිලධාරී)",
  "Sales Manager (විකුණුම් කළමනාකරු)",
  "Scientist (විද්‍යාඥයා)",
  "Security Officer (ආරක්ෂක නිලධාරී)",
  "Senior Accountant (ජ්‍යෙෂ්ඨ ගණකාධිකාරි)",
  "Senior Auditor (ජ්‍යෙෂ්ඨ විගණක)",
  "Senior Executive - HR (ජ්‍යෙෂ්ඨ මානව සම්පත් කළමනාකරු)",
  "Senior Executive - Marketing (ජ්‍යෙෂ්ඨ අලෙවි නිලධාරී )",
  "Senior IT Manager (ජ්‍යෙෂ්ඨ තොරතුරු තාක්ෂණික කළමනාකරු)",
  "Senior Lecturer (ජ්‍යෙෂ්ඨ කථිකාචාර්ය)",
  "Senior Manager (ජ්‍යෙෂ්ඨ කළමනාකරු)",
  "Senior Sales Executive (ජ්‍යෙෂ්ඨ විකුණුම් නිලධාරී)",
  "Senior Software Engineer (ජ්‍යෙෂ්ඨ මෘදුකාංග ඉංජිනේරු)",
  "Software Engineer (මෘදුකාංග ඉංජිනේරු)",
  "Stock Broker ",
  "T & D Manager (පුහුණු සහ සංවර්ධන කළමනාකරු)",
  "Teacher (ගුරුවරයකු)",
  "Water Engineer (ජල   ඉන්ජිනේරු )"
];

const MARarr = ["Never Married", "Divorced", "Single Parent"];

const GENDarr = ["Male", "Female"];

const HORRarr = ["Kuja 7", "Shani 7", "Other"];

const HEIGHarr = [
  "4 - 4 6'",
  "4 6' - 5",
  "5 - 5 6'",
  "5 6' - 6",
  "6 - 6 6'",
  "6 6' - 7"
];

const HORRarrSi = ["කුජ 7", "ශනි 7", "වෙනත්"];

const GENDarrSi = ["සහකරු", "සහකාරිය"];

const AGEarrSi = [
  "18+",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "69",
  "70",
  "71",
  "72",
  "73",
  "74",
  "75",
  "75+"
];

const CASTarrSi = [
  // "ඕනෑම",
  "බත්ගම",
  "චෝලිය",
  "දේව",
  "කොළඹ චෙට්ටි",
  "දුරාව",
  "ගොවිගම",
  "හුණු",
  "කරාව",
  "කුඹල්",
  "නැකැත් ඔලි",
  "පැන්න",
  "රදල",
  "රාජක",
  "සලාගම",
  "විශ්ව",
  "වෙනත්"
];

const RACEarrSi = ["සිංහල", "දෙමළ", "මුස්ලිම්", "බර්ගර්"];

const RELIGIONarrSi = ["බෞද්ධ", "ක්‍රිස්තියානි", "ඉස්ලාම්", "හින්දු", "වෙනත්"];

const EDUarrSi = [
  "සාමාන්‍ය පෙළ දක්වා",
  "උසස් පෙළ දක්වා",
  "ඩිප්ලෝමා",
  "උසස් ඩිප්ලෝමා",
  "උපාධි",
  "පශ්චාත් උපාධි",
  "ආචාර්‍ය උපාධි"
];

const OCUarrSi = [
  "Accountant (ගණකාධිකාරි)",
  "Admin Executive ",
  "Admin Manager ",
  "Agriculture Manager (කෘෂිකාර්මික කළමනාකරු)",
  "Agriculture Officer (කෘෂිකර්ම නිලධාරී)",
  "Air Craft Engineer (ගුවන් සේවා ඉන්ජිනේරු)",
  "Air Hostest (ගුවන් සේවිකා)",
  "Architecture (ගෘහනිර්මාණ ශිල්පී)",
  "Assistant (සහයක)",
  "Assistant Bank Manager (සහකාර බැංකු නිලධාරී )",
  "Assistant HR Manager (සහකාර මානව සම්පත් කළමනාකරු)",
  "Assistant Marketing Manager (සහකාර අලෙවි කළමනාකරු)",
  "Assistant Sales Manager (සහකාර විකුණුම් කළමනාකරු)",
  "Assistant T & D Manager (සහකාර  පුහුණු සහ සංවර්ධන කළමනාකරු)",
  "Assitant Manager - Procument ",
  "Auditor (විගණක)",
  "Bank Manager (බැංකු කළමනාකරු)",
  "Bank Officer (බැංකු නිලධාරී )",
  "Business Analysts ",
  "Businessman ( ව්‍යාපාරික)",
  "Civil Engineer (සිවිල් ඉන්ජිනේරු)",
  "Doctor (වෛද්‍ය)",
  "Draftman ",
  "Driver (රියදුරු )",
  "Electrical Engineer (විදුලි  ඉන්ජිනේරු)",
  "English Teacher (ඉංග්‍රීසි ගුරුවරයකු)",
  "Entrepreneur (ව්‍යවසායක)",
  "Executive - Marketing (අලෙවි නිලධාරී)",
  "Finance Manager (මුල්‍ය කළමනාකරු)",
  "Financial Controller (මුල්‍ය පාලක)",
  "Government Employee (රාජ්‍ය නිලධාරී)",
  "Government Sector Manager ",
  "Head of Marketing (අලෙවි ප්‍රධානි )",
  "Head of Sales (විකුණුම් ප්‍රධානි)",
  "HR Executive (මානව සම්පත් විධායක )",
  "HR Manager (මානව සම්පත් කළමනාකරු)",
  "IT Business Analysts ",
  "IT Manager (තොරතුරු තාක්ෂණික කළමනාකරු)",
  "IT Professional (තොරතුරු තාක්ෂණික වෘතියවේදී )",
  "Junior Manager (කණිෂ්ඨ කළමනාකරු)",
  "Lawyer (නීතිඥ)",
  "Lecturer (කථිකාචාර්ය)",
  "Manager - Audit (විගණන කළමනාකරු)",
  "Manager (කළමනාකරු)",
  "Marketing Manager (අලෙවි කළමනාකරු )",
  "Nurse (හෙදියක)",
  "Other (වෙනත්)",
  "Pilot (ගුවන් නියමු)",
  "Policeman (පොලිස් නිලධාරී)",
  "Policewoman (පොලිස් නිලධාරීනි)",
  "Pre-School Teacher (පෙර පාසල්  ගුරුවරි)",
  "Principle (විදුහල්පති )",
  "Private Sector Manager ",
  "Procument Executive ",
  "Procument Manager (",
  "Receptionist (පිළිගැනීමේ නිලධාරී)",
  "Sales Executive ( විකුණුම් නිලධාරී)",
  "Sales Manager (විකුණුම් කළමනාකරු)",
  "Scientist (විද්‍යාඥයා)",
  "Security Officer (ආරක්ෂක නිලධාරී)",
  "Senior Accountant (ජ්‍යෙෂ්ඨ ගණකාධිකාරි)",
  "Senior Auditor (ජ්‍යෙෂ්ඨ විගණක)",
  "Senior Executive - HR (ජ්‍යෙෂ්ඨ මානව සම්පත් කළමනාකරු)",
  "Senior Executive - Marketing (ජ්‍යෙෂ්ඨ අලෙවි නිලධාරී )",
  "Senior IT Manager (ජ්‍යෙෂ්ඨ තොරතුරු තාක්ෂණික කළමනාකරු)",
  "Senior Lecturer (ජ්‍යෙෂ්ඨ කථිකාචාර්ය)",
  "Senior Manager (ජ්‍යෙෂ්ඨ කළමනාකරු)",
  "Senior Sales Executive (ජ්‍යෙෂ්ඨ විකුණුම් නිලධාරී)",
  "Senior Software Engineer (ජ්‍යෙෂ්ඨ මෘදුකාංග ඉංජිනේරු)",
  "Software Engineer (මෘදුකාංග ඉංජිනේරු)",
  "Stock Broker ",
  "T & D Manager (පුහුණු සහ සංවර්ධන කළමනාකරු)",
  "Teacher (ගුරුවරයකු)",
  "Water Engineer (ජල   ඉන්ජිනේරු )"
];

const MARarrSi = ["අවිවාහක", "දික්කසාද", "තනි දෙමාපිය (Single Parent)"];

const COUNTArr = [
  "Afghanistan",
  "Åland (Finland)",
  "Albania",
  "Algeria",
  "American Samoa (US)",
  "Andorra",
  "Angola",
  "Anguilla (BOT)",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Artsakh",
  "Aruba (Netherlands)",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda (BOT)",
  "Bhutan",
  "Bolivia",
  "Bonaire (Netherlands)",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands (BOT)",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands (BOT)",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island (Australia)",
  "Cocos (Keeling) Islands (Australia)",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao (Netherlands)",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "DR Congo",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (BOT)",
  "Faroe Islands (Denmark)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana (France)",
  "French Polynesia (France)",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar (BOT)",
  "Greece",
  "Greenland (Denmark)",
  "Grenada",
  "Guadeloupe (France)",
  "Guam (US)",
  "Guatemala",
  "Guernsey (Crown Dependency)",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man (Crown Dependency)",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jersey (Crown Dependency)",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique (France)",
  "Mauritania",
  "Mauritius",
  "Mayotte (France)",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat (BOT)",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia (France)",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island (Australia)",
  "North Korea",
  "North Macedonia",
  "Northern Cyprus",
  "Northern Mariana Islands (US)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn Islands (BOT)",
  "Poland",
  "Portugal",
  "Puerto Rico (US)",
  "Qatar",
  "Réunion (France)",
  "Romania",
  "Russia",
  "Rwanda",
  "Saba (Netherlands)",
  "Saint Barthélemy (France)",
  "Saint Helena, Ascension and Tristan da Cunha (BOT)",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (France)",
  "Saint Pierre and Miquelon (France)",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "São Tomé and Príncipe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Eustatius (Netherlands)",
  "Sint Maarten (Netherlands)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen (Norway)",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tokelau (NZ)",
  "Tonga",
  "Transnistria",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (BOT)",
  "Tuvalu",
  "U.S. Virgin Islands (US)",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Wallis and Futuna (France)",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];

const PRICE_RANGE = [0, 999];
//
const TabFilters: FC<any> = ({
  categories = [{ mainId: "", maincategory: "" }],
  setSelectedcat,
  selectedCat,
  allSubs = [],
  setselectedSub,
  selectedSub,
  allSizes = [],
  allColours = [],
  selectedSize,
  selectedColour,
  setselectedSize,
  setselectedColour,
  setminPrice,
  minPrice,
  setmaxPrice,
  maxPrice,
  setFilterSortValue,
  filterSortValue,
  setCurrentPage,
  setmainDesc,
  setsearch,
  search,
  selectedPro,
  setselectedPro,
  homeDistrict,
  homeCity,
  sethomeDistrict,
  sethomeCity,
  filterType = "company",
  houseType,
  sethouseType,
  payType,
  setpayType,
  fromDate,
  setfromDate,
  settoDate,
  toDate,
  country,
  setcountry,
  selectedAge,
  setselectedAge,
  selectedGender,
  setselectedGender,
  selectedCast,
  setselectedCast,
  selectedJob,
  setselectedJob,
  selectedHorr,
  setselectedHorr,
  selectedRace,
  setselectedRace,
  selectedRel,
  setselectedRel,
  selectedEdu,
  setselectedEdu,
  selectedMarr,
  setselectedMarr,
  country2 = "Sri Lanka",
  selectedSubCat,
  setselectedSubCat,
  tabNo,
  height,
  setheight
}) => {
  const { t } = useTranslation();

  const { lan, setLan } = useContext(WishContext);

  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  //
  const [isOnSale, setIsIsOnSale] = useState(true);
  const [rangePrices, setRangePrices] = useState([minPrice, maxPrice]);
  const [categoriesState, setCategoriesState] = useState<string[]>([]);
  const [colorsState, setColorsState] = useState<string[]>([]);
  const [sizesState, setSizesState] = useState<string[]>([]);
  const [sortOrderStates, setSortOrderStates] = useState<string>("");

  const [homeDistrictId, sethomeDistrictId] = useState(0);
  const [allHCities, setallHCities] = useState([]);
  const [allHDistricts, setallHDistricts] = useState([]);

  //
  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);

  //
  const handleChangeCategories = (checked: boolean, name: string) => {
    checked
      ? setCategoriesState([...categoriesState, name])
      : setCategoriesState(categoriesState.filter((i) => i !== name));
  };

  const handleChangeColors = (checked: boolean, name: string) => {
    checked
      ? setColorsState([...colorsState, name])
      : setColorsState(colorsState.filter((i) => i !== name));
  };

  const handleChangeSizes = (checked: boolean, name: string) => {
    checked
      ? setSizesState([...sizesState, name])
      : setSizesState(sizesState.filter((i) => i !== name));
  };

  const [newSubArray, setnewSubArray] = useState<any>([]);
  const [newSubs, setnewSubs] = useState<any>([]);

  const [newSubs2, setnewSubs2] = useState<any>([]);

  const [allProCate, setallProCate] = useState<any>([]);
  const [allProSubCate, setallProSubCate] = useState<any>([]);
  const [categoryId, setcategoryId] = useState<any>("");

  const [showFilter, setshowFilter] = useState(false);

  React.useEffect(() => {
    let newSubArray = JSON.parse(JSON.stringify(SubData));

    newSubArray = newSubArray?.map((d: any) => {
      if (d.type === "Delivery Area") {
        return {
          ...d,
          locality: upperFirst(lowerCase(d.locality))
        };
      }
    });

    newSubArray = _.compact(newSubArray);

    setnewSubArray(_.orderBy(newSubArray, ["locality"], ["asc"]));

    let arr: any = _.orderBy(newSubArray, ["locality"], ["asc"]);

    console.log("arr>>", arr);

    arr = _.uniq(arr);

    setnewSubs(arr);

    console.log(
      ">>>>>>>subs",
      newSubArray.filter((u: any) => u.state === 0)
    );
  }, []);

  React.useEffect(() => {
    listDistricts();
  }, [country, lan, tabNo]);

  React.useEffect(() => {
    if (homeDistrictId !== 0) {
      listHomecities();
    }
  }, [homeDistrictId, lan, tabNo]);

  // const listDistricts = () => {
  //   getDistricts()
  //     .then((res) => {
  //       console.log("districts>>>", res);
  //       setallHDistricts(res.data);
  //       // setallODistricts(res.data);
  //     })
  //     .catch((error) => {
  //       console.log("Error", error.response);
  //       // setOpenToast(true);
  //       // setColor(COLORS.Orange);
  //       // setAlertClass("error");
  //       // setMsg(error.response.data.message);
  //     });
  // };

  // const listHomecities = () => {
  //   getCities(homeDistrict)
  //     .then((res) => {
  //       console.log("cities>>>", res);
  //       setallHCities(res.data.records);
  //     })
  //     .catch((error) => {
  //       console.log("Error", error.response);
  //       setallHCities([])
  //       // setOpenToast(true);
  //       // setColor(COLORS.Orange);
  //       // setAlertClass("error");
  //       // setMsg(error.response.data.message);
  //     });
  // };

  const listCategories = () => {
    getProviderCategory()
      .then((res) => {
        console.log("cities>>>", res);
        setallProCate(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setallProCate([]);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const listSubCategories = () => {
    getProviderSubCategoryById(selectedCat)
      .then((res) => {
        console.log("cities>>>", res);
        setallProSubCate(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setallProSubCate([]);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  React.useEffect(() => {
    listCategories();
  }, []);

  React.useEffect(() => {
    if (selectedCat) {
      listSubCategories();
    }
  }, [selectedCat]);

  const listDistricts = () => {
    if (country === "Australia") {
      getDistricts()
        .then((res) => {
          console.log("districts>>>", res);
          setallHDistricts(res.data);
          // sethomeDistrict(res.data[0]?.name_en)
          // sethomeDistrictId(res.data[0]?.id);
          // seterrhomeDistrict(false);
          // setallODistricts(res.data);
        })
        .catch((error) => {
          console.log("Error", error.response);
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    } else if (country === "Sri Lanka" || country === "ශ්‍රී ලංකාව") {
      getSriDistricts({ language: tabNo === 1 ? t("langKey") : "En" })
        .then((res) => {
          console.log("districts>>>", res);
          setallHDistricts(res.data);
          // sethomeDistrict(res.data[0]?.name_en)
          // sethomeDistrictId(res.data[0]?.id);
          // seterrhomeDistrict(false);
          // setallODistricts(res.data);
        })
        .catch((error) => {
          console.log("Error", error.response);
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    }
  };

  const listHomecities = () => {
    if (country === "Australia") {
      getCities(homeDistrict)
        .then((res) => {
          console.log("cities>>>", res.data);
          setallHCities(res.data.records);
          // sethomeCity(res.data.records[0].fields.scc_name)
          // sethomepostCode(res.data.records[0].fields.scc_code)
          // seterrhomeCity(false);
          // seterrhomepostCode(false);
        })
        .catch((error) => {
          console.log("Error", error.response);
          setallHCities([]);
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    } else if (country === "Sri Lanka" || country === "ශ්‍රී ලංකාව") {
      getSriCities(homeDistrictId, tabNo === 1 ? t("langKey") : "En")
        .then((res) => {
          console.log("cities>>>", res.data);

          const subArr = res.data?.map((d: any) => {
            return {
              ...d,
              fields: {
                scc_name: d.name_en,
                scc_code: d.postcode
              }
            };
          });

          setallHCities(subArr);
          // sethomeCity(res.data.records[0].fields.scc_name)
          // sethomepostCode(res.data.records[0].fields.scc_code)
          // seterrhomeCity(false);
          // seterrhomepostCode(false);
        })
        .catch((error) => {
          console.log("Error", error.response);
          setallHCities([]);
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    }
  };

  const handleChangeHdist = (e?: any, value?: any) => {
    if (value !== null) {
      if (value.name_en === "All") {
        sethomeDistrict("");
      } else {
        sethomeDistrict(value.name_en);

        sethomeDistrictId(value.id);
      }
    } else {
      sethomeDistrict("");
      sethomeDistrictId(1);
    }

    setnewSubs(newSubArray.filter((u: any) => u.state === value.id));

    // setnewSubs([]);

    // const filtered = newSubArray.filter((u: { state: any; }) => u.state === value.id)

    // const perChunk = 700

    // const result = filtered.reduce((filtered: any, item: any, index: any) => {
    //   const chunkIndex = Math.floor(index / perChunk)

    //   if (!filtered[chunkIndex]) {
    //     filtered[chunkIndex] = [] // start a new chunk
    //   }

    //   filtered[chunkIndex].push(item)

    //   return filtered
    // }, [])

    // console.log("<<<<<<<", result)

    // for (let i = 0; i < result.length - 1; i++) {

    //   let item = result[i];

    //   setTimeout(() => {
    //     setnewSubs((existing: any) => [...existing, ...item]);
    //   }, i === 0 ? 0 : (2000));
    // }

    sethomeCity("");

    console.log("state>>", value);
  };
  const handleChangeHcity = (e?: any, value?: any) => {
    if (value !== null) {
      if (value.fields.scc_name === "All") {
        sethomeCity("");
      } else {
        sethomeCity(value.fields.scc_name);
      }

      // if (value.locality === "All") {
      //   sethomeCity("");
      // } else {
      //   sethomeCity(value)
      // }
    } else {
      sethomeCity("");
    }

    console.log("city>>", value);
  };

  //

  // OK
  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  // OK
  const renderTabsCategories = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none select-none
               ${
                 open
                   ? "!border-primary-500 "
                   : "border-neutral-300 dark:border-neutral-700"
               }
                ${
                  !!categoriesState.length
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "border-neutral-300 dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 2V5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16 2V5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 13H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 17H12"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16 3.5C19.33 3.68 21 4.95 21 9.65V15.83C21 19.95 20 22.01 15 22.01H9C4 22.01 3 19.95 3 15.83V9.65C3 4.95 4.67 3.69 8 3.5H16Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2">Categories</span>
              {!categoriesState.length ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() => setCategoriesState([])}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <Checkbox
                      name="All Categories"
                      label="All Categories"
                      defaultChecked={categoriesState.includes(
                        "All Categories"
                      )}
                      onChange={(checked) =>
                        handleChangeCategories(checked, "All Categories")
                      }
                    />
                    <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
                    {DATA_categories.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={categoriesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeCategories(checked, item.name)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setCategoriesState([]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  // OK
  const renderTabsSortOrder = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center w-full w-[150px] h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${
                  !!filterSortValue
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg className="w-4 h-4" viewBox="0 0 20 20" fill="none">
                <path
                  d="M11.5166 5.70834L14.0499 8.24168"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.5166 14.2917V5.70834"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.48327 14.2917L5.94995 11.7583"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.48315 5.70834V14.2917"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 10C18.3334 5.39763 14.6025 1.66667 10.0001 1.66667C5.39771 1.66667 1.66675 5.39763 1.66675 10C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2">
                {filterSortValue
                  ? DATA_sortOrderRadios.filter(
                      (i) => i.id === filterSortValue
                    )[0].name
                  : "Sort order"}
              </span>
              {!filterSortValue ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() => setFilterSortValue("")}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 right-0 sm:px-0 lg:max-w-sm">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-3">
                    <Radio
                      id={""}
                      key={""}
                      name="radioNameSort"
                      label={"None"}
                      className="!text-sm"
                      sizeClassName="w-5 h-5"
                      // defaultChecked={sortOrderStates === item.id}
                      checked={filterSortValue === ""}
                      // defaultChecked={sortOrderStates === item.id}

                      onChange={setFilterSortValue}
                      // onChange={setSortOrderStates}
                    />
                    {DATA_sortOrderRadios.map((item) => (
                      <Radio
                        id={item.id}
                        key={item.id}
                        name="radioNameSort"
                        className="!text-sm"
                        sizeClassName="w-5 h-5"
                        label={item.name}
                        // defaultChecked={sortOrderStates === item.id}
                        checked={filterSortValue === item.id}
                        // defaultChecked={sortOrderStates === item.id}

                        onChange={setFilterSortValue}
                        // onChange={setSortOrderStates}
                      />
                    ))}
                  </div>
                  {/* <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setSortOrderStates("");
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsSortOrderContract = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${
                  !!filterSortValue
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              {/* <svg className="w-4 h-4" viewBox="0 0 20 20" fill="none">
                <path
                  d="M11.5166 5.70834L14.0499 8.24168"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.5166 14.2917V5.70834"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.48327 14.2917L5.94995 11.7583"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.48315 5.70834V14.2917"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 10C18.3334 5.39763 14.6025 1.66667 10.0001 1.66667C5.39771 1.66667 1.66675 5.39763 1.66675 10C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg> */}

              <span
                className={`ml-2 text-slate-400 ${
                  t("langKey") === "Si" ? "text-xs" : "text-sm"
                }`}
              >
                {filterSortValue
                  ? (t("langKey") === "Si"
                      ? DATA_sortOrderRadios3
                      : DATA_sortOrderRadios3
                    ).filter((i) => i.id === filterSortValue)[0].name
                  : t("fSort")}
              </span>
              {!filterSortValue ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() => setFilterSortValue("")}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm ">
                <div className=" max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <Radio
                      id={""}
                      key={""}
                      name="radioNameSort"
                      label={t("langKey") === "Si" ? "ඕනෑම" : "All"}
                      className="!text-sm"
                      sizeClassName="w-5 h-5"
                      // defaultChecked={sortOrderStates === item.id}
                      checked={filterSortValue === ""}
                      // defaultChecked={sortOrderStates === item.id}

                      onChange={setFilterSortValue}
                      // onChange={setSortOrderStates}
                    />
                    {(t("langKey") === "Si"
                      ? DATA_sortOrderRadios3
                      : DATA_sortOrderRadios3
                    ).map((item) => (
                      <Radio
                        id={item.id}
                        key={item.id}
                        name="radioNameSort"
                        className="!text-sm"
                        sizeClassName="w-5 h-5"
                        label={item.name}
                        // defaultChecked={sortOrderStates === item.id}
                        checked={filterSortValue === item.id}
                        // defaultChecked={sortOrderStates === item.id}

                        onChange={setFilterSortValue}
                        // onChange={setSortOrderStates}
                      />
                    ))}
                  </div>
                  {/* <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setSortOrderStates("");
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsSortOrderPrice = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${
                  !!filterSortValue
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg className="w-4 h-4" viewBox="0 0 20 20" fill="none">
                <path
                  d="M11.5166 5.70834L14.0499 8.24168"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.5166 14.2917V5.70834"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.48327 14.2917L5.94995 11.7583"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.48315 5.70834V14.2917"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 10C18.3334 5.39763 14.6025 1.66667 10.0001 1.66667C5.39771 1.66667 1.66675 5.39763 1.66675 10C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2">
                {filterSortValue
                  ? DATA_sortOrderRadios2.filter(
                      (i) => i.id === filterSortValue
                    )[0].name
                  : "Sort order"}
              </span>
              {!filterSortValue ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() => setFilterSortValue("")}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 right-0 sm:px-0 lg:max-w-sm">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-3">
                    <Radio
                      id={""}
                      key={""}
                      name="radioNameSort"
                      label={"None"}
                      className="!text-sm"
                      sizeClassName="w-5 h-5"
                      // defaultChecked={sortOrderStates === item.id}
                      checked={filterSortValue === ""}
                      // defaultChecked={sortOrderStates === item.id}

                      onChange={setFilterSortValue}
                      // onChange={setSortOrderStates}
                    />
                    {DATA_sortOrderRadios2.map((item) => (
                      <Radio
                        id={item.id}
                        key={item.id}
                        name="radioNameSort"
                        className="!text-sm"
                        sizeClassName="w-5 h-5"
                        label={item.name}
                        // defaultChecked={sortOrderStates === item.id}
                        checked={filterSortValue === item.id}
                        // defaultChecked={sortOrderStates === item.id}

                        onChange={setFilterSortValue}
                        // onChange={setSortOrderStates}
                      />
                    ))}
                  </div>
                  {/* <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setSortOrderStates("");
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  // OK
  const renderTabsState = () => {
    return (
      <>
        <Autocomplete
          className=""
          // className="form-group"
          // className="border-neutral-200"
          disablePortal
          defaultValue={{ name_en: homeDistrict }}
          value={{ name_en: homeDistrict }}
          // id="combo-box-demo"
          // options={[{ name_en: "All", value: "" }, ...allHDistricts]}
          options={[
            {
              name_en: tabNo === 1 && t("langKey") === "Si" ? "ඕනෑම" : "All",
              value: ""
            },
            ...allHDistricts
          ]}
          getOptionLabel={(option) => option.name_en}
          onChange={(event, value) => {
            handleChangeHdist(event, value);
            // setCountry(value);
          }}
          // sx={{
          //   // width: "80vw",
          //   background:"transparent",
          //   borderRadius: "20px",
          //   // border: "none",
          //   // borderColor: "transparent",

          //   "& .MuiInputBase-input": {
          //     height: 15,
          //     // border: "none",
          //     fontSize: "14px",
          //     fontFamily: "'Jost', sans-serif",
          //     // borderColor: "transparent",
          //   },
          //   //view
          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          //     // borderColor: "transparent",
          //   },

          //   "&:hover .MuiOutlinedInput-input": {
          //     color: "black",
          //   },
          //   "&:hover .MuiInputLabel-root": {
          //     color: COLORS.GrayBackground,
          //   },
          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
          //   {
          //     // borderColor: "transparent",
          //   },
          //   ".MuiAutocomplete-root fieldset:hover": {
          //     color: "transparent",
          //     // borderColor: "transparent",
          //   },
          // }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input
                {...params.inputProps}
                widthClass="w-full"
                fontClass={`${
                  t("langKey") === "Si" ? "text-xs" : "text-sm"
                } font-normal`}
                className={`bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500 ${
                  tabNo === 2 ? "font-[Inter]" : ""
                }`}
                placeholder={tabNo === 2 ? "District" : t("fDis")}
              />
            </div>
          )}
          ListboxProps={{
            style: {
              maxHeight: "150px",
              fontSize: "14px",
              fontFamily:
                tabNo === 1 && t("langKey") === "Si" ? "NotoSans" : "Inter"
              // border: '1px solid white'
            }
          }}
        />
      </>
    );
  };

  const renderTabsSuburb = () => {
    return (
      <>
        {/* <Autocomplete
          className=""
          // className="form-group"
          // className="border-neutral-200"
          disablePortal
          // defaultValue={{ fields: { scc_name: homeCity } }}
          defaultValue={{ locality: homeCity }}
          // value={{ fields: { scc_name: homeCity } }}
          value={{ locality: homeCity }}
          // id="combo-box-demo"
          // options={[{ fields: { scc_name: "All", value: "" } }, ...allHCities]}
          options={[{ locality: "All" }, ...newSubs]}
          // getOptionLabel={(option) => option?.fields?.scc_name}
          getOptionLabel={(option) => option?.locality}
          onChange={(event, value) => {

            handleChangeHcity(event, value);
            // setCountry(value);
          }}
          // sx={{
          //   // width: "80vw",
          //   background:"transparent",
          //   borderRadius: "20px",
          //   // border: "none",
          //   // borderColor: "transparent",

          //   "& .MuiInputBase-input": {
          //     height: 15,
          //     // border: "none",
          //     fontSize: "14px",
          //     fontFamily: "'Jost', sans-serif",
          //     // borderColor: "transparent",
          //   },
          //   //view
          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          //     // borderColor: "transparent",
          //   },

          //   "&:hover .MuiOutlinedInput-input": {
          //     color: "black",
          //   },
          //   "&:hover .MuiInputLabel-root": {
          //     color: COLORS.GrayBackground,
          //   },
          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
          //   {
          //     // borderColor: "transparent",
          //   },
          //   ".MuiAutocomplete-root fieldset:hover": {
          //     color: "transparent",
          //     // borderColor: "transparent",
          //   },
          // }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input {...params.inputProps} widthClass="w-full" fontClass = "text-sm font-normal" className="bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500" placeholder="Select Suburb" />
            </div>
          )}
          ListboxProps={{
            style: {
              maxHeight: "150px",
              fontSize: "14px",
              // border: '1px solid white'
            },
          }}
        /> */}

        <Autocomplete
          className=""
          // className="form-group"
          // className="border-neutral-200"
          // disablePortal
          // defaultValue={{ fields: { scc_name: homeCity } }}
          value={{ fields: { scc_name: homeCity } }}
          // defaultValue={{ locality: homeCity }}
          // value={{ locality: homeCity }}
          // value={homeCity}

          // id="combo-box-demo"
          // options={allHCities}
          options={[
            {
              fields: {
                scc_name: tabNo === 1 && t("langKey") === "Si" ? "ඕනෑම" : "All"
              }
            },
            ...allHCities
          ]}
          // options={["All", ...newSubs2]}

          getOptionLabel={(option) => option?.fields?.scc_name}
          // getOptionLabel={(option) => option}
          // onInputChange={(event, value) => {
          //   console.log(value)

          //   if (value) {

          //     let char = lowerCase(value)

          //     let arr = [...newSubs]

          //     console.log("<<<<", newSubs)

          //     // arr = arr.filter((data) => lowerCase(data.locality).includes(char));

          //     const det = arr.map((item, index) => {

          //       if (lowerCase(item.locality).startsWith(char)) {

          //         return { ...item }
          //       }
          //     })

          //     console.log(">>>>>", _.compact(det))

          //     arr = _.compact(det)

          //     arr = _.map(arr, "locality")

          //     console.log("arr>>", arr)

          //     arr = _.uniq(arr);

          //     setnewSubs2(arr);

          //   } else {
          //     setnewSubs2([])
          //   }
          // }}
          onChange={(event, value) => {
            handleChangeHcity(event, value);
            // setCountry(value);
          }}
          // sx={{
          //   // width: "80vw",
          //   background:"transparent",
          //   borderRadius: "20px",
          //   // border: "none",
          //   // borderColor: "transparent",

          //   "& .MuiInputBase-input": {
          //     height: 15,
          //     // border: "none",
          //     fontSize: "14px",
          //     fontFamily: "'Jost', sans-serif",
          //     // borderColor: "transparent",
          //   },
          //   //view
          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          //     // borderColor: "transparent",
          //   },

          //   "&:hover .MuiOutlinedInput-input": {
          //     color: "black",
          //   },
          //   "&:hover .MuiInputLabel-root": {
          //     color: COLORS.GrayBackground,
          //   },
          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
          //   {
          //     // borderColor: "transparent",
          //   },
          //   ".MuiAutocomplete-root fieldset:hover": {
          //     color: "transparent",
          //     // borderColor: "transparent",
          //   },
          // }}

          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input
                {...params.inputProps}
                widthClass="w-full"
                fontClass={`${
                  t("langKey") === "Si" ? "text-xs" : "text-sm"
                } font-normal`}
                className={`bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500 ${
                  tabNo === 2 ? "font-[Inter]" : ""
                }`}
                placeholder={tabNo === 2 ? "City" : t("fCity")}
              />
            </div>
          )}
          ListboxProps={{
            style: {
              maxHeight: "150px",
              fontSize: "14px",
              fontFamily:
                tabNo === 1 && t("langKey") === "Si" ? "NotoSans" : "Inter"
              // border: '1px solid white'
            }
          }}
        />
      </>
    );
  };

  const renderTabsCountry = () => {
    return (
      <>
        {/* <Autocomplete
          className=""
          // className="form-group"
          // className="border-neutral-200"
          disablePortal
          // defaultValue={{ fields: { scc_name: homeCity } }}
          defaultValue={{ locality: homeCity }}
          // value={{ fields: { scc_name: homeCity } }}
          value={{ locality: homeCity }}
          // id="combo-box-demo"
          // options={[{ fields: { scc_name: "All", value: "" } }, ...allHCities]}
          options={[{ locality: "All" }, ...newSubs]}
          // getOptionLabel={(option) => option?.fields?.scc_name}
          getOptionLabel={(option) => option?.locality}
          onChange={(event, value) => {

            handleChangeHcity(event, value);
            // setCountry(value);
          }}
          // sx={{
          //   // width: "80vw",
          //   background:"transparent",
          //   borderRadius: "20px",
          //   // border: "none",
          //   // borderColor: "transparent",

          //   "& .MuiInputBase-input": {
          //     height: 15,
          //     // border: "none",
          //     fontSize: "14px",
          //     fontFamily: "'Jost', sans-serif",
          //     // borderColor: "transparent",
          //   },
          //   //view
          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          //     // borderColor: "transparent",
          //   },

          //   "&:hover .MuiOutlinedInput-input": {
          //     color: "black",
          //   },
          //   "&:hover .MuiInputLabel-root": {
          //     color: COLORS.GrayBackground,
          //   },
          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
          //   {
          //     // borderColor: "transparent",
          //   },
          //   ".MuiAutocomplete-root fieldset:hover": {
          //     color: "transparent",
          //     // borderColor: "transparent",
          //   },
          // }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input {...params.inputProps} widthClass="w-full" fontClass = "text-sm font-normal" className="bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500" placeholder="Select Suburb" />
            </div>
          )}
          ListboxProps={{
            style: {
              maxHeight: "150px",
              fontSize: "14px",
              // border: '1px solid white'
            },
          }}
        /> */}

        <Autocomplete
          className=""
          // className="form-group"
          // className="border-neutral-200"
          // disablePortal
          // defaultValue={{ fields: { scc_name: homeCity } }}
          // value={{ fields: { scc_name: homeCity } }}
          // defaultValue={{ locality: homeCity }}
          // value={{ locality: homeCity }}
          value={country}
          // id="combo-box-demo"
          // options={allHCities}
          // options={[t("langKey") === "En" ? "Sri Lanka" : "ශ්‍රී ලංකාව"]}
          options={[t("langKey") === "En" ? "All" : "ඕනෑම", ...COUNTArr]}
          // options={["All", ...newSubs2]}

          // getOptionLabel={(option) => option?.fields?.scc_name}
          getOptionLabel={(option) => option}
          onInputChange={(event, value) => {
            console.log(value);

            // if (value) {

            //   let char = lowerCase(value)

            //   let arr = [...newSubs]

            //   console.log("<<<<", newSubs)

            //   // arr = arr.filter((data) => lowerCase(data.locality).includes(char));

            //   const det = arr.map((item, index) => {

            //     if (lowerCase(item.locality).startsWith(char)) {

            //       return { ...item }
            //     }
            //   })

            //   console.log(">>>>>", _.compact(det))

            //   arr = _.compact(det)

            //   arr = _.map(arr, "locality")

            //   console.log("arr>>", arr)

            //   arr = _.uniq(arr);

            //   setnewSubs2(arr);

            // } else {
            //   setnewSubs2([])
            // }
          }}
          onChange={(event, value) => {
            if (value) {
              if (value === "All" || value === "ඕනෑම") {
                setcountry("");
              } else {
                setcountry(value);
              }

              sethomeDistrict("");
              sethomeCity("");
            }

            // handleChangeHcity(event, value);
            // setCountry(value);
          }}
          // sx={{
          //   // width: "80vw",
          //   background:"transparent",
          //   borderRadius: "20px",
          //   // border: "none",
          //   // borderColor: "transparent",

          //   "& .MuiInputBase-input": {
          //     height: 15,
          //     // border: "none",
          //     fontSize: "14px",
          //     fontFamily: "'Jost', sans-serif",
          //     // borderColor: "transparent",
          //   },
          //   //view
          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          //     // borderColor: "transparent",
          //   },

          //   "&:hover .MuiOutlinedInput-input": {
          //     color: "black",
          //   },
          //   "&:hover .MuiInputLabel-root": {
          //     color: COLORS.GrayBackground,
          //   },
          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
          //   {
          //     // borderColor: "transparent",
          //   },
          //   ".MuiAutocomplete-root fieldset:hover": {
          //     color: "transparent",
          //     // borderColor: "transparent",
          //   },
          // }}

          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input
                {...params.inputProps}
                widthClass="w-full"
                fontClass={`${
                  t("langKey") === "Si" ? "text-xs" : "text-sm"
                } font-normal`}
                className="bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                placeholder={t("fCountry")}
              />
            </div>
          )}
          ListboxProps={{
            style: {
              maxHeight: "150px",
              fontSize: "14px",
              fontFamily: t("langKey") === "Si" ? "NotoSans" : "Inter"
              // border: '1px solid white'
            }
          }}
        />
      </>
    );
  };

  // OK
  const renderTabsCleanTypes = () => {
    return (
      // <Popover className="relative">
      //   {({ open, close }) => (
      //     <>
      //       <Popover.Button
      //         className={`flex items-center justify-center px-4 py-3 h-11 text-sm rounded-lg border focus:outline-none select-none
      //         ${open ? "!border-primary-500 " : ""}
      //           ${!!selectedCat
      //             ? "!border-primary-500 bg-primary-50 text-primary-900"
      //             : "bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
      //           }
      //           `}
      //       >
      //         <svg
      //           className="w-4 h-4"
      //           viewBox="0 0 24 24"
      //           fill="none"
      //           xmlns="http://www.w3.org/2000/svg"
      //         >
      //           <path
      //             d="M21 9V3H15"
      //             stroke="currentColor"
      //             strokeWidth="1.5"
      //             strokeLinecap="round"
      //             strokeLinejoin="round"
      //           />
      //           <path
      //             d="M3 15V21H9"
      //             stroke="currentColor"
      //             strokeWidth="1.5"
      //             strokeLinecap="round"
      //             strokeLinejoin="round"
      //           />
      //           <path
      //             d="M21 3L13.5 10.5"
      //             stroke="currentColor"
      //             strokeWidth="1.5"
      //             strokeLinecap="round"
      //             strokeLinejoin="round"
      //           />
      //           <path
      //             d="M10.5 13.5L3 21"
      //             stroke="currentColor"
      //             strokeWidth="1.5"
      //             strokeLinecap="round"
      //             strokeLinejoin="round"
      //           />
      //         </svg>

      //         <span className={`ml-2 whitespace-nowrap text-slate-400 ${t("langKey") === "Si" ? "text-xs" : "text-sm"}`}>Cleaning Type</span>
      //         {!selectedCat ? (
      //           <ChevronDownIcon className="w-4 h-4 ml-3" />
      //         ) : (
      //           <span onClick={() =>
      //             // setSizesState([])
      //             setSelectedcat("")
      //           }>{renderXClear()}</span>
      //         )}
      //       </Popover.Button>
      //       <Transition
      //         as={Fragment}
      //         enter="transition ease-out duration-200"
      //         enterFrom="opacity-0 translate-y-1"
      //         enterTo="opacity-100 translate-y-0"
      //         leave="transition ease-in duration-150"
      //         leaveFrom="opacity-100 translate-y-0"
      //         leaveTo="opacity-0 translate-y-1"
      //       >
      //         <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
      //           <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
      //             <div className="relative flex flex-col px-5 py-6 space-y-5">

      //               <Checkbox
      //                 sizeClassName="w-5 h-5"
      //                 labelClassName="text-sm font-normal"
      //                 name={""}
      //                 label={t("langKey")==="Si"?"ඕනෑම":"All"}
      //                 checked={selectedCat === ""}
      //                 onChange={(checked) => {
      //                   setSelectedcat("");

      //                   // window.scrollTo({
      //                   //   top: 0,
      //                   //   behavior: "smooth"
      //                   // });
      //                 }}
      //               // defaultChecked={colorsState.includes(item.name)}
      //               // onChange={(checked) => handleChangeColors(checked, item.name)}
      //               />

      //               {categories.map((item: any) => (
      //                 <div key={item} className="">
      //                   <Checkbox
      //                     name={item.name}
      //                     label={item.name}
      //                     checked={selectedCat === item.id}
      //                     // defaultChecked={sizesState.includes(item.name)}
      //                     onChange={(checked) => {
      //                       setSelectedcat(item.id);

      //                       // window.scrollTo({
      //                       //   top: 0,
      //                       //   behavior: "smooth"
      //                       // });
      //                     }}
      //                     // onChange={(checked) => handleChangeSizes(checked, item.name)}
      //                     sizeClassName="w-5 h-5"
      //                     labelClassName="text-sm font-normal"
      //                   />
      //                 </div>
      //               ))}
      //               {/* {DATA_sizes.map((item) => (
      //                 <div key={item.name} className="">
      //                   <Checkbox
      //                     name={item.name}
      //                     label={item.name}
      //                     defaultChecked={sizesState.includes(item.name)}
      //                     onChange={(checked) =>
      //                       handleChangeSizes(checked, item.name)
      //                     }
      //                   />
      //                 </div>
      //               ))} */}
      //             </div>
      //             {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
      //           <ButtonThird
      //             onClick={() => {
      //               close();
      //               setSizesState([]);
      //             }}
      //             sizeClass="px-4 py-2 sm:px-5"
      //           >
      //             Clear
      //           </ButtonThird>
      //           <ButtonPrimary
      //             onClick={close}
      //             sizeClass="px-4 py-2 sm:px-5"
      //           >
      //             Apply
      //           </ButtonPrimary>
      //         </div> */}
      //           </div>
      //         </Popover.Panel>
      //       </Transition>
      //     </>
      //   )
      //   }
      // </Popover >
      <Autocomplete
        className=""
        // className="form-group"
        // className="border-neutral-200"
        disablePortal
        // defaultValue={{ name: homeDistrict }}
        value={{
          name: allProCate.find((v: any) => v.id === selectedCat)
            ? allProCate.find((v: any) => v.id === selectedCat).name
            : ""
        }}
        // id="combo-box-demo"
        options={[{ name: "All", id: "" }, ...allProCate]}
        getOptionLabel={(option) => option.name}
        onChange={(event, value) => {
          if (value !== null) {
            setSelectedcat(value.id);
          } else {
            setSelectedcat("");
          }

          // handleChangeHdist(event, value);
          // setCountry(value);
        }}
        // sx={{
        //   // width: "80vw",
        //   background:"transparent",
        //   borderRadius: "20px",
        //   // border: "none",
        //   // borderColor: "transparent",

        //   "& .MuiInputBase-input": {
        //     height: 15,
        //     // border: "none",
        //     fontSize: "14px",
        //     fontFamily: "'Jost', sans-serif",
        //     // borderColor: "transparent",
        //   },
        //   //view
        //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        //     // borderColor: "transparent",
        //   },

        //   "&:hover .MuiOutlinedInput-input": {
        //     color: "black",
        //   },
        //   "&:hover .MuiInputLabel-root": {
        //     color: COLORS.GrayBackground,
        //   },
        //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
        //   {
        //     // borderColor: "transparent",
        //   },
        //   ".MuiAutocomplete-root fieldset:hover": {
        //     color: "transparent",
        //     // borderColor: "transparent",
        //   },
        // }}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <Input
              {...params.inputProps}
              widthClass="w-full"
              fontClass={`${
                t("langKey") === "Si" ? "text-xs" : "text-sm"
              } font-normal`}
              className="bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500 font-[Inter]"
              placeholder="Category"
            />
          </div>
        )}
        ListboxProps={{
          style: {
            maxHeight: "150px",
            fontSize: "14px"
            // border: '1px solid white'
          }
        }}
      />
    );
  };

  const renderTabsSubProvider = () => {
    return (
      // <Popover className="relative">
      //   {({ open, close }) => (
      //     <>
      //       <Popover.Button
      //         className={`flex items-center justify-center px-4 py-3 h-11 text-sm rounded-lg border focus:outline-none select-none
      //         ${open ? "!border-primary-500 " : ""}
      //           ${!!selectedCat
      //             ? "!border-primary-500 bg-primary-50 text-primary-900"
      //             : "bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
      //           }
      //           `}
      //       >
      //         <svg
      //           className="w-4 h-4"
      //           viewBox="0 0 24 24"
      //           fill="none"
      //           xmlns="http://www.w3.org/2000/svg"
      //         >
      //           <path
      //             d="M21 9V3H15"
      //             stroke="currentColor"
      //             strokeWidth="1.5"
      //             strokeLinecap="round"
      //             strokeLinejoin="round"
      //           />
      //           <path
      //             d="M3 15V21H9"
      //             stroke="currentColor"
      //             strokeWidth="1.5"
      //             strokeLinecap="round"
      //             strokeLinejoin="round"
      //           />
      //           <path
      //             d="M21 3L13.5 10.5"
      //             stroke="currentColor"
      //             strokeWidth="1.5"
      //             strokeLinecap="round"
      //             strokeLinejoin="round"
      //           />
      //           <path
      //             d="M10.5 13.5L3 21"
      //             stroke="currentColor"
      //             strokeWidth="1.5"
      //             strokeLinecap="round"
      //             strokeLinejoin="round"
      //           />
      //         </svg>

      //         <span className={`ml-2 whitespace-nowrap text-slate-400 ${t("langKey") === "Si" ? "text-xs" : "text-sm"}`}>Cleaning Type</span>
      //         {!selectedCat ? (
      //           <ChevronDownIcon className="w-4 h-4 ml-3" />
      //         ) : (
      //           <span onClick={() =>
      //             // setSizesState([])
      //             setSelectedcat("")
      //           }>{renderXClear()}</span>
      //         )}
      //       </Popover.Button>
      //       <Transition
      //         as={Fragment}
      //         enter="transition ease-out duration-200"
      //         enterFrom="opacity-0 translate-y-1"
      //         enterTo="opacity-100 translate-y-0"
      //         leave="transition ease-in duration-150"
      //         leaveFrom="opacity-100 translate-y-0"
      //         leaveTo="opacity-0 translate-y-1"
      //       >
      //         <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
      //           <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
      //             <div className="relative flex flex-col px-5 py-6 space-y-5">

      //               <Checkbox
      //                 sizeClassName="w-5 h-5"
      //                 labelClassName="text-sm font-normal"
      //                 name={""}
      //                 label={t("langKey")==="Si"?"ඕනෑම":"All"}
      //                 checked={selectedCat === ""}
      //                 onChange={(checked) => {
      //                   setSelectedcat("");

      //                   // window.scrollTo({
      //                   //   top: 0,
      //                   //   behavior: "smooth"
      //                   // });
      //                 }}
      //               // defaultChecked={colorsState.includes(item.name)}
      //               // onChange={(checked) => handleChangeColors(checked, item.name)}
      //               />

      //               {categories.map((item: any) => (
      //                 <div key={item} className="">
      //                   <Checkbox
      //                     name={item.name}
      //                     label={item.name}
      //                     checked={selectedCat === item.id}
      //                     // defaultChecked={sizesState.includes(item.name)}
      //                     onChange={(checked) => {
      //                       setSelectedcat(item.id);

      //                       // window.scrollTo({
      //                       //   top: 0,
      //                       //   behavior: "smooth"
      //                       // });
      //                     }}
      //                     // onChange={(checked) => handleChangeSizes(checked, item.name)}
      //                     sizeClassName="w-5 h-5"
      //                     labelClassName="text-sm font-normal"
      //                   />
      //                 </div>
      //               ))}
      //               {/* {DATA_sizes.map((item) => (
      //                 <div key={item.name} className="">
      //                   <Checkbox
      //                     name={item.name}
      //                     label={item.name}
      //                     defaultChecked={sizesState.includes(item.name)}
      //                     onChange={(checked) =>
      //                       handleChangeSizes(checked, item.name)
      //                     }
      //                   />
      //                 </div>
      //               ))} */}
      //             </div>
      //             {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
      //           <ButtonThird
      //             onClick={() => {
      //               close();
      //               setSizesState([]);
      //             }}
      //             sizeClass="px-4 py-2 sm:px-5"
      //           >
      //             Clear
      //           </ButtonThird>
      //           <ButtonPrimary
      //             onClick={close}
      //             sizeClass="px-4 py-2 sm:px-5"
      //           >
      //             Apply
      //           </ButtonPrimary>
      //         </div> */}
      //           </div>
      //         </Popover.Panel>
      //       </Transition>
      //     </>
      //   )
      //   }
      // </Popover >
      <Autocomplete
        className=""
        // className="form-group"
        // className="border-neutral-200"
        disablePortal
        // defaultValue={{ name: homeDistrict }}
        value={{
          name: allProSubCate.find((v: any) => v.id === selectedSubCat)
            ? allProSubCate.find((v: any) => v.id === selectedSubCat).name
            : ""
        }}
        // id="combo-box-demo"
        options={[{ name: "All", id: "" }, ...allProSubCate]}
        getOptionLabel={(option) => option.name}
        onChange={(event, value) => {
          if (value !== null) {
            setselectedSubCat(value.id);
          } else {
            setselectedSubCat("");
          }

          // handleChangeHdist(event, value);
          // setCountry(value);
        }}
        // sx={{
        //   // width: "80vw",
        //   background:"transparent",
        //   borderRadius: "20px",
        //   // border: "none",
        //   // borderColor: "transparent",

        //   "& .MuiInputBase-input": {
        //     height: 15,
        //     // border: "none",
        //     fontSize: "14px",
        //     fontFamily: "'Jost', sans-serif",
        //     // borderColor: "transparent",
        //   },
        //   //view
        //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        //     // borderColor: "transparent",
        //   },

        //   "&:hover .MuiOutlinedInput-input": {
        //     color: "black",
        //   },
        //   "&:hover .MuiInputLabel-root": {
        //     color: COLORS.GrayBackground,
        //   },
        //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
        //   {
        //     // borderColor: "transparent",
        //   },
        //   ".MuiAutocomplete-root fieldset:hover": {
        //     color: "transparent",
        //     // borderColor: "transparent",
        //   },
        // }}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <Input
              {...params.inputProps}
              widthClass="w-full"
              fontClass={`${
                t("langKey") === "Si" ? "text-xs" : "text-sm"
              } font-normal`}
              className="bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500 font-[Inter]"
              placeholder="Sub Category"
            />
          </div>
        )}
        ListboxProps={{
          style: {
            maxHeight: "150px",
            fontSize: "14px"
            // border: '1px solid white'
          }
        }}
      />
    );
  };

  const renderTabsJobTypes = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${
                  selectedPro?.length != 0
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span
                className={`ml-2 whitespace-nowrap text-slate-400 ${
                  t("langKey") === "Si" ? "text-xs" : "text-sm"
                }`}
              >
                Job Type
              </span>
              {selectedPro?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span
                  onClick={() =>
                    // setSizesState([])
                    setselectedPro([])
                  }
                >
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <Checkbox
                      name={""}
                      label={t("langKey") === "Si" ? "ඕනෑම" : "All"}
                      checked={selectedPro?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedPro([]);
                        // setselectedColour("");
                        // setselectedSize("");
                        // setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("");
                      }}
                    />
                    {JOBTYPES.map((item: any) => (
                      <div key={item.id} className="">
                        <Checkbox
                          name={item.value}
                          label={item.name}
                          checked={selectedPro?.includes(item.value)}
                          // defaultChecked={categoriesState.includes(item.mainId)}
                          sizeClassName="w-5 h-5"
                          labelClassName="text-sm font-normal"
                          onChange={(checked) => {
                            // if (selectedPro?.includes(item.value)) {
                            //   const index = selectedPro.indexOf(item.value);

                            //   console.log(">>>>>>index", index)

                            //   var arr = [...selectedPro]
                            //   if (index > -1) {
                            //     arr.splice(index, 1);
                            //     console.log("arr>>>>", arr)
                            //     setselectedPro(arr)
                            //   }

                            // } else {
                            //   setselectedPro(((data: any) => [...data, item.value]))
                            // }

                            setselectedPro([item.value]);
                            // setSelectedcat(item.value);

                            // setselectedColour("");
                            // setselectedSize("");
                            // setCurrentPage(1);
                            // removeLocal();
                            setmainDesc(item);
                            setsearch("");
                          }}
                          // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                        />
                      </div>
                    ))}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsGender = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-md border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${
                  selectedGender?.length != 0
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              {/* <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg> */}

              <span
                className={`ml-2 whitespace-nowrap text-slate-400 ${
                  t("langKey") === "Si" ? "text-xs" : "text-sm"
                }`}
              >
                {selectedGender?.length > 0
                  ? selectedGender[0] === "Male"
                    ? "Groom"
                    : selectedGender[0] === "Female"
                    ? "Bride"
                    : selectedGender[0] === "සහකරු"
                    ? "සහකරු"
                    : "සහකාරිය"
                  : "Seeker Type"}
              </span>
              {selectedGender?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span
                  onClick={() =>
                    // setSizesState([])
                    setselectedGender([])
                  }
                >
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <Checkbox
                      name={""}
                      label={t("langKey") === "Si" ? "ඕනෑම" : "All"}
                      checked={selectedGender?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedGender([]);
                        // setselectedColour("");
                        // setselectedSize("");
                        // setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("");
                      }}
                    />
                    {(lan === "English" ? GENDarr : GENDarrSi).map(
                      (item: any) => (
                        <div key={item} className="">
                          <Checkbox
                            name={item}
                            label={
                              item === "Male"
                                ? "Groom"
                                : item === "Female"
                                ? "Bride"
                                : item
                            }
                            // checked={selectedGender?.includes(item === "Male" ? "Female" : (item === "Female" ? "Male" : (item === "සහකරු" ? "සහකරු" : "සහකාරිය")))}
                            checked={selectedGender?.includes(
                              item === "Male"
                                ? "Male"
                                : item === "Female"
                                ? "Female"
                                : item === "සහකරු"
                                ? "සහකරු"
                                : "සහකාරිය"
                            )}
                            // defaultChecked={categoriesState.includes(item.mainId)}
                            sizeClassName="w-5 h-5"
                            labelClassName="text-sm font-normal"
                            onChange={(checked) => {
                              // var data = ""

                              if (item === "Male") {
                                item = "Male";
                              } else if (item === "Female") {
                                item = "Female";
                              } else if (item === "සහකරු") {
                                item = "සහකරු";
                              } else if (item === "සහකාරිය") {
                                item = "සහකාරිය";
                              }

                              if (selectedGender?.includes(item)) {
                                const index = selectedGender.indexOf(item);

                                console.log(">>>>>>index", index);

                                var arr = [...selectedGender];
                                if (index > -1) {
                                  arr.splice(index, 1);
                                  console.log("arr>>>>", arr);
                                  setselectedGender(arr);
                                }
                              } else {
                                setselectedGender((datas: any) => [
                                  ...datas,
                                  item
                                ]);
                              }

                              // setselectedGender([item]);

                              // setSelectedcat(item.value);

                              // setselectedColour("");
                              // setselectedSize("");
                              // setCurrentPage(1);
                              // removeLocal();
                              setmainDesc(item);
                              setsearch("");
                            }}
                            // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                          />
                        </div>
                      )
                    )}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsAge = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${
                  selectedAge?.length != 0
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              {/* <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg> */}

              <span
                className={`ml-2 whitespace-nowrap text-slate-400 ${
                  t("langKey") === "Si" ? "text-xs" : "text-sm"
                }`}
              >
                {selectedAge?.length > 0 ? selectedAge[0] : t("fAge")}
              </span>
              {selectedAge?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span
                  onClick={() =>
                    // setSizesState([])
                    setselectedAge([])
                  }
                >
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm ">
                <div className=" max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <Checkbox
                      name={""}
                      label={t("langKey") === "Si" ? "ඕනෑම" : "All"}
                      checked={selectedAge?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedAge([]);
                        // setselectedColour("");
                        // setselectedSize("");
                        // setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("");
                      }}
                    />
                    {(lan === "English" ? AGEarr : AGEarrSi).map(
                      (item: any) => (
                        <div key={item} className="">
                          <Checkbox
                            name={item}
                            label={item}
                            checked={selectedAge?.includes(item)}
                            // defaultChecked={categoriesState.includes(item.mainId)}
                            sizeClassName="w-5 h-5"
                            labelClassName="text-sm font-normal font-[Inter]"
                            onChange={(checked) => {
                              if (selectedAge?.includes(item)) {
                                const index = selectedAge.indexOf(item);

                                console.log(">>>>>>index", index);

                                var arr = [...selectedAge];
                                if (index > -1) {
                                  arr.splice(index, 1);
                                  console.log("arr>>>>", arr);
                                  setselectedAge(arr);
                                }
                              } else {
                                setselectedAge((data: any) => [...data, item]);
                              }

                              // setselectedAge([item]);
                              // setSelectedcat(item.value);

                              // setselectedColour("");
                              // setselectedSize("");
                              // setCurrentPage(1);
                              // removeLocal();
                              setmainDesc(item);
                              setsearch("");
                            }}
                            // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                          />
                        </div>
                      )
                    )}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsRating = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${
                  selectedAge?.length != 0
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              {/* <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg> */}

              <span
                className={`ml-2 whitespace-nowrap text-slate-400 ${
                  t("langKey") === "Si" ? "text-xs" : "text-sm"
                }`}
              >
                {selectedAge?.length > 0 ? selectedAge[0] : "Rating"}
              </span>
              {selectedAge?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span
                  onClick={() =>
                    // setSizesState([])
                    setselectedAge([])
                  }
                >
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm ">
                <div className=" max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <Checkbox
                      name={""}
                      label={t("langKey") === "Si" ? "ඕනෑම" : "All"}
                      checked={selectedAge?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedAge([]);
                        // setselectedColour("");
                        // setselectedSize("");
                        // setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("");
                      }}
                    />
                    {(lan === "English" ? AGEarr : AGEarrSi).map(
                      (item: any) => (
                        <div key={item} className="">
                          <Checkbox
                            name={item}
                            label={item}
                            checked={selectedAge?.includes(item)}
                            // defaultChecked={categoriesState.includes(item.mainId)}
                            sizeClassName="w-5 h-5"
                            labelClassName="text-sm font-normal font-[Inter]"
                            onChange={(checked) => {
                              if (selectedAge?.includes(item)) {
                                const index = selectedAge.indexOf(item);

                                console.log(">>>>>>index", index);

                                var arr = [...selectedAge];
                                if (index > -1) {
                                  arr.splice(index, 1);
                                  console.log("arr>>>>", arr);
                                  setselectedAge(arr);
                                }
                              } else {
                                setselectedAge((data: any) => [...data, item]);
                              }

                              // setselectedAge([item]);
                              // setSelectedcat(item.value);

                              // setselectedColour("");
                              // setselectedSize("");
                              // setCurrentPage(1);
                              // removeLocal();
                              setmainDesc(item);
                              setsearch("");
                            }}
                            // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                          />
                        </div>
                      )
                    )}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsCast = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${
                  selectedCast?.length != 0
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              {/* <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg> */}

              <span
                className={`ml-2 whitespace-nowrap text-slate-400 ${
                  t("langKey") === "Si" ? "text-xs" : "text-sm"
                }`}
              >
                {selectedCast?.length > 0 ? selectedCast[0] : "Experience"}
              </span>
              {selectedCast?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span
                  onClick={() =>
                    // setSizesState([])
                    setselectedCast([])
                  }
                >
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <Checkbox
                      name={""}
                      label={t("langKey") === "Si" ? "ඕනෑම" : "All"}
                      checked={selectedCast?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedCast([]);
                        // setselectedColour("");
                        // setselectedSize("");
                        // setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("");
                      }}
                    />
                    {(lan === "English" ? CASTarr : CASTarrSi).map(
                      (item: any) => (
                        <div key={item} className="">
                          <Checkbox
                            name={item}
                            label={item}
                            checked={selectedCast?.includes(item)}
                            // defaultChecked={categoriesState.includes(item.mainId)}
                            sizeClassName="w-5 h-5"
                            labelClassName="text-sm font-normal"
                            onChange={(checked) => {
                              if (selectedCast?.includes(item)) {
                                const index = selectedCast.indexOf(item);

                                console.log(">>>>>>index", index);

                                var arr = [...selectedCast];
                                if (index > -1) {
                                  arr.splice(index, 1);
                                  console.log("arr>>>>", arr);
                                  setselectedCast(arr);
                                }
                              } else {
                                setselectedCast((data: any) => [...data, item]);
                              }

                              // setselectedCast([item]);
                              // setSelectedcat(item.value);

                              // setselectedColour("");
                              // setselectedSize("");
                              // setCurrentPage(1);
                              // removeLocal();
                              setmainDesc(item);
                              setsearch("");
                            }}
                            // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                          />
                        </div>
                      )
                    )}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsRace = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${
                  selectedRace?.length != 0
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              {/* <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg> */}

              <span
                className={`ml-2 whitespace-nowrap text-slate-400 ${
                  t("langKey") === "Si" ? "text-xs" : "text-sm"
                }`}
              >
                {selectedRace?.length > 0 ? selectedRace[0] : t("fRace")}
              </span>
              {selectedRace?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span
                  onClick={() =>
                    // setSizesState([])
                    setselectedRace([])
                  }
                >
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <Checkbox
                      name={""}
                      label={t("langKey") === "Si" ? "ඕනෑම" : "All"}
                      checked={selectedRace?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedRace([]);
                        // setselectedColour("");
                        // setselectedSize("");
                        // setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("");
                      }}
                    />
                    {(lan === "English" ? RACEarr : RACEarrSi).map(
                      (item: any) => (
                        <div key={item} className="">
                          <Checkbox
                            name={item}
                            label={item}
                            checked={selectedRace?.includes(item)}
                            // defaultChecked={categoriesState.includes(item.mainId)}
                            sizeClassName="w-5 h-5"
                            labelClassName="text-sm font-normal"
                            onChange={(checked) => {
                              if (selectedRace?.includes(item)) {
                                const index = selectedRace.indexOf(item);

                                console.log(">>>>>>index", index);

                                var arr = [...selectedRace];
                                if (index > -1) {
                                  arr.splice(index, 1);
                                  console.log("arr>>>>", arr);
                                  setselectedRace(arr);
                                }
                              } else {
                                setselectedRace((data: any) => [...data, item]);
                              }

                              // setselectedRace([item]);
                              // setSelectedcat(item.value);

                              // setselectedColour("");
                              // setselectedSize("");
                              // setCurrentPage(1);
                              // removeLocal();
                              setmainDesc(item);
                              setsearch("");
                            }}
                            // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                          />
                        </div>
                      )
                    )}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsReligion = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${
                  selectedRel?.length != 0
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              {/* <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg> */}

              <span
                className={`ml-2 whitespace-nowrap text-slate-400 ${
                  t("langKey") === "Si" ? "text-xs" : "text-sm"
                }`}
              >
                {selectedRel?.length > 0 ? selectedRel[0] : t("fRel")}
              </span>
              {selectedRel?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span
                  onClick={() =>
                    // setSizesState([])
                    setselectedRel([])
                  }
                >
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <Checkbox
                      name={""}
                      label={t("langKey") === "Si" ? "ඕනෑම" : "All"}
                      checked={selectedRel?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedRel([]);
                        // setselectedColour("");
                        // setselectedSize("");
                        // setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("");
                      }}
                    />
                    {(lan === "English" ? RELIGIONarr : RELIGIONarrSi).map(
                      (item: any) => (
                        <div key={item} className="">
                          <Checkbox
                            name={item}
                            label={item}
                            checked={selectedRel?.includes(item)}
                            // defaultChecked={categoriesState.includes(item.mainId)}
                            sizeClassName="w-5 h-5"
                            labelClassName="text-sm font-normal"
                            onChange={(checked) => {
                              if (selectedRel?.includes(item)) {
                                const index = selectedRel.indexOf(item);

                                console.log(">>>>>>index", index);

                                var arr = [...selectedRel];
                                if (index > -1) {
                                  arr.splice(index, 1);
                                  console.log("arr>>>>", arr);
                                  setselectedRel(arr);
                                }
                              } else {
                                setselectedRel((data: any) => [...data, item]);
                              }

                              // setselectedRel([item]);
                              // setSelectedcat(item.value);

                              // setselectedColour("");
                              // setselectedSize("");
                              // setCurrentPage(1);
                              // removeLocal();
                              setmainDesc(item);
                              setsearch("");
                            }}
                            // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                          />
                        </div>
                      )
                    )}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsEducation = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${
                  selectedEdu?.length != 0
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              {/* <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg> */}

              <span
                className={`ml-2 whitespace-nowrap text-slate-400 ${
                  t("langKey") === "Si" ? "text-xs" : "text-sm"
                }`}
              >
                {selectedEdu?.length > 0 ? selectedEdu[0] : t("fEdu")}
              </span>
              {selectedEdu?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span
                  onClick={() =>
                    // setSizesState([])
                    setselectedEdu([])
                  }
                >
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <Checkbox
                      name={""}
                      label={t("langKey") === "Si" ? "ඕනෑම" : "All"}
                      checked={selectedEdu?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedEdu([]);
                        // setselectedColour("");
                        // setselectedSize("");
                        // setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("");
                      }}
                    />
                    {(lan === "English" ? EDUarr : EDUarrSi).map(
                      (item: any) => (
                        <div key={item} className="">
                          <Checkbox
                            name={item}
                            label={item}
                            checked={selectedEdu?.includes(item)}
                            // defaultChecked={categoriesState.includes(item.mainId)}
                            sizeClassName="w-5 h-5"
                            labelClassName="text-sm font-normal"
                            onChange={(checked) => {
                              if (selectedEdu?.includes(item)) {
                                const index = selectedEdu.indexOf(item);

                                console.log(">>>>>>index", index);

                                var arr = [...selectedEdu];
                                if (index > -1) {
                                  arr.splice(index, 1);
                                  console.log("arr>>>>", arr);
                                  setselectedEdu(arr);
                                }
                              } else {
                                setselectedEdu((data: any) => [...data, item]);
                              }

                              // setselectedEdu([item]);
                              // setSelectedcat(item.value);

                              // setselectedColour("");
                              // setselectedSize("");
                              // setCurrentPage(1);
                              // removeLocal();
                              setmainDesc(item);
                              setsearch("");
                            }}
                            // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                          />
                        </div>
                      )
                    )}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsHorroscope = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${
                  selectedHorr?.length != 0
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              {/* <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg> */}

              <span
                className={`ml-2 whitespace-nowrap text-slate-400 ${
                  t("langKey") === "Si" ? "text-xs" : "text-sm"
                }`}
              >
                {selectedHorr?.length > 0 ? selectedHorr[0] : "Field"}
              </span>
              {selectedHorr?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span
                  onClick={() =>
                    // setSizesState([])
                    setselectedHorr([])
                  }
                >
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <Checkbox
                      name={""}
                      label={t("langKey") === "Si" ? "ඕනෑම" : "All"}
                      checked={selectedHorr?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedHorr([]);
                        // setselectedColour("");
                        // setselectedSize("");
                        // setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("");
                      }}
                    />
                    {(lan === "English" ? HORRarr : HORRarrSi).map(
                      (item: any) => (
                        <div key={item} className="">
                          <Checkbox
                            name={item}
                            label={item}
                            checked={selectedHorr?.includes(item)}
                            // defaultChecked={categoriesState.includes(item.mainId)}
                            sizeClassName="w-5 h-5"
                            labelClassName="text-sm font-normal"
                            onChange={(checked) => {
                              if (selectedHorr?.includes(item)) {
                                const index = selectedHorr.indexOf(item);

                                console.log(">>>>>>index", index);

                                var arr = [...selectedHorr];
                                if (index > -1) {
                                  arr.splice(index, 1);
                                  console.log("arr>>>>", arr);
                                  setselectedHorr(arr);
                                }
                              } else {
                                setselectedHorr((data: any) => [...data, item]);
                              }

                              // setselectedHorr([item]);
                              // setSelectedcat(item.value);

                              // setselectedColour("");
                              // setselectedSize("");
                              // setCurrentPage(1);
                              // removeLocal();
                              setmainDesc(item);
                              setsearch("");
                            }}
                            // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                          />
                        </div>
                      )
                    )}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsMarriage = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${
                  selectedMarr?.length != 0
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              {/* <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg> */}

              <span
                className={`ml-2 whitespace-nowrap text-slate-400 ${
                  t("langKey") === "Si" ? "text-xs" : "text-sm"
                }`}
              >
                {selectedMarr?.length > 0 ? selectedMarr[0] : t("fMarr")}
              </span>
              {selectedMarr?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span
                  onClick={() =>
                    // setSizesState([])
                    setselectedMarr([])
                  }
                >
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <Checkbox
                      name={""}
                      label={t("langKey") === "Si" ? "ඕනෑම" : "All"}
                      checked={selectedMarr?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedMarr([]);
                        // setselectedColour("");
                        // setselectedSize("");
                        // setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("");
                      }}
                    />
                    {(lan === "English" ? MARarr : MARarrSi).map(
                      (item: any) => (
                        <div key={item} className="">
                          <Checkbox
                            name={item}
                            label={item}
                            checked={selectedMarr?.includes(item)}
                            // defaultChecked={categoriesState.includes(item.mainId)}
                            sizeClassName="w-5 h-5"
                            labelClassName="text-sm font-normal"
                            onChange={(checked) => {
                              if (selectedMarr?.includes(item)) {
                                const index = selectedMarr.indexOf(item);

                                console.log(">>>>>>index", index);

                                var arr = [...selectedMarr];
                                if (index > -1) {
                                  arr.splice(index, 1);
                                  console.log("arr>>>>", arr);
                                  setselectedMarr(arr);
                                }
                              } else {
                                setselectedMarr((data: any) => [...data, item]);
                              }

                              // setselectedMarr([item]);
                              // setSelectedcat(item.value);

                              // setselectedColour("");
                              // setselectedSize("");
                              // setCurrentPage(1);
                              // removeLocal();
                              setmainDesc(item);
                              setsearch("");
                            }}
                            // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                          />
                        </div>
                      )
                    )}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsHeight = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${
                  selectedMarr?.length != 0
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              {/* <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg> */}

              <span
                className={`ml-2 whitespace-nowrap text-slate-400 ${
                  t("langKey") === "Si" ? "text-xs" : "text-sm"
                }`}
              >
                {height ? height : t("fHei")}
              </span>
              {!height ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span
                  onClick={() =>
                    // setSizesState([])
                    // setselectedMarr([])
                    setheight("")
                  }
                >
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <Checkbox
                      name={""}
                      label={t("langKey") === "Si" ? "ඕනෑම" : "All"}
                      checked={height === ""}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        // setselectedMarr([]);
                        // setselectedColour("");
                        // setselectedSize("");
                        // setCurrentPage(1);
                        // removeLocal();
                        setheight("");
                        setmainDesc("");
                        setsearch("");
                      }}
                    />
                    {(lan === "English" ? HEIGHarr : HEIGHarr).map(
                      (item: any) => (
                        <div key={item} className="">
                          <Checkbox
                            name={item}
                            label={item}
                            checked={height === item}
                            // defaultChecked={categoriesState.includes(item.mainId)}
                            sizeClassName="w-5 h-5"
                            labelClassName="text-sm font-normal"
                            onChange={(checked) => {
                              setheight(item);
                              // if (selectedMarr?.includes(item)) {
                              //   const index = selectedMarr.indexOf(item);

                              //   console.log(">>>>>>index", index)

                              //   var arr = [...selectedMarr]
                              //   if (index > -1) {
                              //     arr.splice(index, 1);
                              //     console.log("arr>>>>", arr)
                              //     setselectedMarr(arr)
                              //   }

                              // } else {
                              //   setselectedMarr(((data: any) => [...data, item]))
                              // }

                              // setselectedMarr([item]);
                              // setSelectedcat(item.value);

                              // setselectedColour("");
                              // setselectedSize("");
                              // setCurrentPage(1);
                              // removeLocal();
                              setmainDesc(item);
                              setsearch("");
                            }}
                            // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                          />
                        </div>
                      )
                    )}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsOccupation = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${
                  selectedJob?.length != 0
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              {/* <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg> */}

              <span
                className={`ml-2 whitespace-nowrap text-slate-400 ${
                  t("langKey") === "Si" ? "text-xs" : "text-sm"
                }`}
              >
                {selectedJob?.length > 0
                  ? selectedJob[0].split("(")[0]
                  : "Qualification"}
              </span>
              {selectedJob?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span
                  onClick={() =>
                    // setSizesState([])
                    setselectedJob([])
                  }
                >
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <Checkbox
                      name={""}
                      label={t("langKey") === "Si" ? "ඕනෑම" : "All"}
                      checked={selectedJob?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedJob([]);
                        // setselectedColour("");
                        // setselectedSize("");
                        // setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("");
                      }}
                    />
                    {(lan === "English" ? OCUarr : OCUarrSi).map(
                      (item: any) => (
                        <div key={item} className="">
                          <Checkbox
                            name={item}
                            label={item}
                            checked={selectedJob?.includes(item)}
                            // defaultChecked={categoriesState.includes(item.mainId)}
                            sizeClassName="w-5 h-5"
                            labelClassName="text-sm font-normal"
                            onChange={(checked) => {
                              if (selectedJob?.includes(item)) {
                                const index = selectedJob.indexOf(item);

                                console.log(">>>>>>index", index);

                                var arr = [...selectedJob];
                                if (index > -1) {
                                  arr.splice(index, 1);
                                  console.log("arr>>>>", arr);
                                  setselectedJob(arr);
                                }
                              } else {
                                setselectedJob((data: any) => [...data, item]);
                              }

                              // setselectedJob([item]);
                              // setSelectedcat(item.value);

                              // setselectedColour("");
                              // setselectedSize("");
                              // setCurrentPage(1);
                              // removeLocal();
                              setmainDesc(item);
                              setsearch("");
                            }}
                            // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                          />
                        </div>
                      )
                    )}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsSellerTypes = () => {
    return (
      <Popover className="relative mt-4">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${
                  selectedPro?.length != 0
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "bg-white border-white dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span
                className={`ml-2 whitespace-nowrap text-slate-400 ${
                  t("langKey") === "Si" ? "text-xs" : "text-sm"
                }`}
              >
                Seller Type
              </span>
              {selectedPro?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span
                  onClick={() =>
                    // setSizesState([])
                    setselectedPro([1, 2])
                  }
                >
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <Checkbox
                      name={""}
                      label={t("langKey") === "Si" ? "ඕනෑම" : "All"}
                      checked={
                        selectedPro?.includes(1) && selectedPro?.includes(2)
                      }
                      // checked={selectedPro?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedPro([1, 2]);
                        // setselectedColour("");
                        // setselectedSize("");
                        // setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("");
                      }}
                    />
                    {SELLER_TYPES.map((item: any) => (
                      <div key={item.id} className="">
                        <Checkbox
                          name={item.value}
                          label={item.name}
                          checked={
                            selectedPro?.includes(item.id) &&
                            selectedPro.length === 1
                          }
                          // defaultChecked={categoriesState.includes(item.mainId)}
                          sizeClassName="w-5 h-5"
                          labelClassName="text-sm font-normal"
                          onChange={(checked) => {
                            // if (selectedPro?.includes(item.value)) {
                            //   const index = selectedPro.indexOf(item.value);

                            //   console.log(">>>>>>index", index)

                            //   var arr = [...selectedPro]
                            //   if (index > -1) {
                            //     arr.splice(index, 1);
                            //     console.log("arr>>>>", arr)
                            //     setselectedPro(arr)
                            //   }

                            // } else {
                            //   setselectedPro(((data: any) => [...data, item.value]))
                            // }

                            setselectedPro([item.id]);
                            // setSelectedcat(item.value);

                            // setselectedColour("");
                            // setselectedSize("");
                            // setCurrentPage(1);
                            // removeLocal();
                            setmainDesc(item);
                            setsearch("");
                          }}
                          // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                        />
                      </div>
                    ))}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  // OK
  const renderTabsPriceRage = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-start px-2 w-full h-11 text-sm rounded-lg border bg-[#F4F4F4] text-primary-900 focus:outline-none `}
            >
              {/* <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.67188 14.3298C8.67188 15.6198 9.66188 16.6598 10.8919 16.6598H13.4019C14.4719 16.6598 15.3419 15.7498 15.3419 14.6298C15.3419 13.4098 14.8119 12.9798 14.0219 12.6998L9.99187 11.2998C9.20187 11.0198 8.67188 10.5898 8.67188 9.36984C8.67188 8.24984 9.54187 7.33984 10.6119 7.33984H13.1219C14.3519 7.33984 15.3419 8.37984 15.3419 9.66984"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 6V18"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg> */}

              {/* <span className="ml-2 min-w-[90px]">{`${rangePrices[0]}$ - ${rangePrices[1]}$`}</span> */}
              <span className="ml-2 min-w-[90px]">{`${rangePrices[0]}$ - ${rangePrices[1]}$`}</span>
              {rangePrices[0] === PRICE_RANGE[0] &&
              rangePrices[1] === PRICE_RANGE[1] ? null : (
                <span onClick={() => setRangePrices(PRICE_RANGE)}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">Hourly Rate</span>
                      <Slider
                        range
                        // min={PRICE_RANGE[0]}
                        // max={PRICE_RANGE[1]}
                        min={0}
                        max={999}
                        step={1}
                        defaultValue={[0, 999]}
                        // defaultValue={[rangePrices[0], rangePrices[1]]}
                        allowCross={false}
                        onAfterChange={(_input: any | number | number[]) => {
                          // setRangePrices(_input as number[]);
                          setminPrice(_input[0]);
                          setmaxPrice(_input[1]);
                        }}
                        onChange={(_input: any | number | number[]) => {
                          setRangePrices(_input as number[]);
                          // setminPrice(_input[0]);
                          // setmaxPrice(_input[1]);
                        }}
                        // onChange={(_input: number | number[]) =>
                        //   setRangePrices(_input as number[])
                        // }
                      />
                    </div>

                    <div className="flex justify-between space-x-5">
                      <div>
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-slate-400 dark:text-neutral-300"
                        >
                          Min price
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <span className="absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-sm">
                            $
                          </span>
                          <input
                            type="text"
                            name="minPrice"
                            disabled
                            id="minPrice"
                            className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                            value={rangePrices[0]}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-slate-400 dark:text-neutral-300"
                        >
                          Max price
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <span className="absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-sm">
                            $
                          </span>
                          <input
                            type="text"
                            disabled
                            name="maxPrice"
                            id="maxPrice"
                            className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                            value={rangePrices[1]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        setRangePrices(PRICE_RANGE);
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  // OK
  const renderTabIsOnsale = () => {
    return (
      <div
        className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer select-none ${
          isOnSale
            ? "border-primary-500 bg-primary-50 text-primary-900"
            : "border-neutral-300 dark:border-neutral-700 text-slate-400 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
        }`}
        onClick={() => setIsIsOnSale(!isOnSale)}
      >
        <svg
          className="w-4 h-4"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.9889 14.6604L2.46891 13.1404C1.84891 12.5204 1.84891 11.5004 2.46891 10.8804L3.9889 9.36039C4.2489 9.10039 4.4589 8.59038 4.4589 8.23038V6.08036C4.4589 5.20036 5.1789 4.48038 6.0589 4.48038H8.2089C8.5689 4.48038 9.0789 4.27041 9.3389 4.01041L10.8589 2.49039C11.4789 1.87039 12.4989 1.87039 13.1189 2.49039L14.6389 4.01041C14.8989 4.27041 15.4089 4.48038 15.7689 4.48038H17.9189C18.7989 4.48038 19.5189 5.20036 19.5189 6.08036V8.23038C19.5189 8.59038 19.7289 9.10039 19.9889 9.36039L21.5089 10.8804C22.1289 11.5004 22.1289 12.5204 21.5089 13.1404L19.9889 14.6604C19.7289 14.9204 19.5189 15.4304 19.5189 15.7904V17.9403C19.5189 18.8203 18.7989 19.5404 17.9189 19.5404H15.7689C15.4089 19.5404 14.8989 19.7504 14.6389 20.0104L13.1189 21.5304C12.4989 22.1504 11.4789 22.1504 10.8589 21.5304L9.3389 20.0104C9.0789 19.7504 8.5689 19.5404 8.2089 19.5404H6.0589C5.1789 19.5404 4.4589 18.8203 4.4589 17.9403V15.7904C4.4589 15.4204 4.2489 14.9104 3.9889 14.6604Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 15L15 9"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.4945 14.5H14.5035"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.49451 9.5H9.50349"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <span className="line-clamp-1 ml-2">On sale</span>
        {isOnSale && renderXClear()}
      </div>
    );
  };

  // OK
  const renderMoreFilterItem = (
    data: {
      name: string;
      description?: string;
      defaultChecked?: boolean;
    }[]
  ) => {
    const list1 = data.filter((_, i) => i < data.length / 2);
    const list2 = data.filter((_, i) => i >= data.length / 2);
    return (
      <div className="grid grid-cols-2 gap-x-4 sm:gap-x-8 gap-8">
        <div className="flex flex-col space-y-5">
          {list1.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.description}
              label={item.name}
              defaultChecked={!!item.defaultChecked}
              sizeClassName="w-5 h-5 sm:w-6 sm:h-6"
            />
          ))}
        </div>
        <div className="flex flex-col space-y-5">
          {list2.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.description}
              label={item.name}
              defaultChecked={!!item.defaultChecked}
              sizeClassName="w-5 h-5 sm:w-6 sm:h-6"
            />
          ))}
        </div>
      </div>
    );
  };

  // FOR RESPONSIVE MOBILE
  const renderTabMobileFilter = () => {
    return (
      <div className="flex-shrink-0">
        <div
          className={`flex flex-shrink-0 items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-900 focus:outline-none cursor-pointer select-none`}
          onClick={openModalMoreFilter}
        >
          <svg
            className="w-4 h-4"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22 6.5H16"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 6.5H2"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 10C11.933 10 13.5 8.433 13.5 6.5C13.5 4.567 11.933 3 10 3C8.067 3 6.5 4.567 6.5 6.5C6.5 8.433 8.067 10 10 10Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22 17.5H18"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 17.5H2"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 21C15.933 21 17.5 19.433 17.5 17.5C17.5 15.567 15.933 14 14 14C12.067 14 10.5 15.567 10.5 17.5C10.5 19.433 12.067 21 14 21Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span className="ml-2">Products filters (3)</span>
          {renderXClear()}
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full text-left align-middle transition-all transform bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-medium leading-6 text-gray-900"
                    >
                      Products filters
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-6 sm:px-8 md:px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Categories</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(DATA_categories)}
                        </div>
                      </div>
                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Colors</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(DATA_colors)}
                        </div>
                      </div>
                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Size</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(DATA_sizes)}
                        </div>
                      </div>

                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Range Prices</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-8">
                            <div className="space-y-5">
                              <Slider
                                range
                                className="text-red-400"
                                min={PRICE_RANGE[0]}
                                max={PRICE_RANGE[1]}
                                defaultValue={rangePrices}
                                allowCross={false}
                                onChange={(_input: number | number[]) =>
                                  setRangePrices(_input as number[])
                                }
                              />
                            </div>

                            <div className="flex justify-between space-x-5">
                              <div>
                                <label
                                  htmlFor="minPrice"
                                  className="block text-sm font-medium text-slate-400 dark:text-neutral-300"
                                >
                                  Min price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name="minPrice"
                                    disabled
                                    id="minPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={rangePrices[0]}
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="maxPrice"
                                  className="block text-sm font-medium text-slate-400 dark:text-neutral-300"
                                >
                                  Max price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    disabled
                                    name="maxPrice"
                                    id="maxPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={rangePrices[1]}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Sort Order</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-5">
                            {DATA_sortOrderRadios.map((item) => (
                              <Radio
                                id={item.id}
                                key={item.id}
                                name="radioNameSort"
                                label={item.name}
                                defaultChecked={sortOrderStates === item.id}
                                onChange={setSortOrderStates}
                              />
                            ))}
                          </div>
                        </div>
                      </div>

                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">On sale!</h3>
                        <div className="mt-6 relative ">
                          <MySwitch
                            label="On sale!"
                            desc="Products currently on sale"
                            enabled={isOnSale}
                            onChange={setIsIsOnSale}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        setRangePrices(PRICE_RANGE);
                        setCategoriesState([]);
                        setColorsState([]);
                        setSortOrderStates("");
                        closeModalMoreFilter();
                      }}
                      sizeClass="py-2.5 px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={closeModalMoreFilter}
                      sizeClass="py-2.5 px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <>
      {filterType === "job" ? (
        <>
          <div className="grid grid-cols-2 lg:grid-cols-6 w-full gap-x-7 gap-y-6">
            {filterType === "job" && renderTabsSortOrderContract()}

            {filterType === "job" && renderTabsGender()}

            {filterType === "job" && renderTabsAge()}

            {filterType === "job" && renderTabsCast()}

            {/* {filterType === "job" &&

renderTabsOccupation()

} */}

            {showFilter && (
              <>
                <div className="lg:hidden">
                  {filterType === "job" && renderTabsOccupation()}
                </div>
                <div className="lg:hidden">
                  {filterType === "job" && renderTabsHorroscope()}
                </div>

                {/* <div className="lg:hidden">

                    {filterType === "job" &&

                      renderTabsRace()

                    }

                  </div>

                  <div className="lg:hidden">


                    {filterType === "job" &&

                      renderTabsReligion()

                    }

                  </div>

                  <div className="lg:hidden">


                    {filterType === "job" &&

                      renderTabsEducation()

                    }

                  </div>
                  <div className="lg:hidden">

                    {filterType === "job" &&

                      renderTabsMarriage()

                    }
                  </div>

                  <div className="lg:hidden">

                    {filterType === "job" &&

                      renderTabsCountry()

                    }
                  </div>


                  {
                    country === "Sri Lanka" &&

                    <>
                      <div className="lg:hidden">

                        {filterType === "job" &&

                          renderTabsState()

                        }
                      </div>
                      <div className="lg:hidden">


                        {filterType === "job" &&

                          renderTabsSuburb()

                        }
                      </div>


                    </>
                  }



                  <div className="lg:hidden">

                    {filterType === "job" &&

                      renderTabsHeight()

                    }
                  </div>
 */}
              </>
            )}

            <div className="hidden lg:block">
              {filterType === "job" && renderTabsOccupation()}
            </div>

            <div className="hidden lg:block">
              {filterType === "job" && renderTabsHorroscope()}
            </div>

            {/* <div className="hidden lg:block">

                {filterType === "job" &&

                  renderTabsRace()

                }

              </div>

              <div className="hidden lg:block">


                {filterType === "job" &&

                  renderTabsReligion()

                }

              </div>

              <div className="hidden lg:block">


                {filterType === "job" &&

                  renderTabsEducation()

                }

              </div>
              <div className="hidden lg:block">

                {filterType === "job" &&

                  renderTabsMarriage()

                }
              </div>

              <div className="hidden lg:block">

                {filterType === "job" &&

                  renderTabsCountry()

                }
              </div> */}

            {/* {
                country === "Sri Lanka" &&
                <>
                  <div className="hidden lg:block">

                    {filterType === "job" &&

                      renderTabsState()

                    }
                  </div>
                  <div className="hidden lg:block">


                    {filterType === "job" &&

                      renderTabsSuburb()

                    }
                  </div>
                </>
              } */}

            {/* 
              <div className="hidden lg:block">
                {filterType === "job" &&

                  renderTabsHeight()

                }

              </div> */}

            {/* <div className="hidden lg:block">
                {filterType === "job" &&

                  renderTabsSortOrderContract()

                }

              </div> */}
          </div>

          <div className="flex lg:hidden mt-8 justify-center items-center">
            <ButtonPrimary
              onClick={() => {
                setshowFilter(true);
              }}
            >
              See more Filters
            </ButtonPrimary>
          </div>
        </>
      ) : filterType === "writer" ? (
        <>
          <div className="grid grid-cols-2 lg:grid-cols-5 w-full gap-x-7 gap-y-6">
            {
              // filterType === "job" &&

              renderTabsSortOrderContract()
            }

            {
              // filterType === "job" &&

              renderTabsHorroscope()
            }

            {/* {
                  // filterType === "job" &&

                  renderTabsGender()

                } */}

            {
              // filterType === "job" &&

              renderTabsRating()
            }

            {/* {
                  // filterType === "job" &&

                  renderTabsCast()

                } */}

            {showFilter && (
              <>
                <div className="lg:hidden">
                  {
                    // filterType === "job" &&
                    // renderTabsOccupation()
                  }
                </div>
                <div className="lg:hidden">
                  {
                    // filterType === "job" &&
                    // renderTabsHorroscope()
                  }
                </div>
              </>
            )}

            <div

            // className="hidden lg:block"
            >
              {
                // filterType === "job" &&

                renderTabsOccupation()
              }
            </div>

            {<div className="">{renderTabsState()}</div>}
          </div>

          {/* <div className="flex lg:hidden mt-8 justify-center items-center">
                <ButtonPrimary
                  onClick={() => {
                    setshowFilter(true)
                  }}

                >See more Filters</ButtonPrimary>
              </div> */}
        </>
      ) : (
        <div className="grid grid-cols-2 lg:grid-cols-4 w-full gap-x-7 gap-y-6">
          {filterType === "provider" && renderTabsCleanTypes()}

          {filterType === "provider" && renderTabsSubProvider()}

          {filterType === "provider" && renderTabsState()}

          {filterType === "provider" && renderTabsSuburb()}
        </div>
      )}
    </>
  );
};

export default TabFilters;
