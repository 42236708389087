import { lowerCase, upperFirst } from "lodash";
import React, { useEffect, useMemo, useState } from "react";

export default function BotToast(props: any) {

  let open = props?.open
  const msg = props?.msg
  const status = props?.status


  const [tOpen, settOpen] = useState<any>(open)

  useEffect(() => {

    if (tOpen === true) {
      setTimeout(() => {

        settOpen(false)

      }, 10000);
    }
  }, [tOpen])



  useEffect(() => {
    settOpen(open);
  }, [open])


  function sentenceCase(input?: any, lowercaseBefore?: any) {
    input = (input === undefined || input === null) ? '' : input;
    if (lowercaseBefore) { input = input.toLowerCase(); }
    return input.toString().replace(/(^|\. *)([a-z])/g, function (match: any, separator: any, char: any) {
      return separator + char.toUpperCase();
    });
  }

  return (
    <>
      {tOpen === true ?

        // <div className={`w-full px-4 py-3 my-4 ${status === "success" ? "bg-green-100" : "bg-red-100"}  border-2 ${status === "success" ? "border-green-500" : "border-red-500"}`}>
        //   <span className={`${status === "success" ? "text-green-500" : "text-red-500"} text-sm text-center flex justify-center`}>{msg}</span>
        // </div>

        <div className={`w-full px-4 py-3 my-4  `}>
          <span className={`text-sm text-center flex justify-center font-medium text-[#2d867b]`}>{msg?.includes(".") ? sentenceCase(msg) : msg?.charAt(0).toUpperCase() + msg?.slice(1).toLowerCase()}</span>
        </div>
        :
        <></>
      }

    </>

  );
}