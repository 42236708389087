import React, { useState, FC } from "react";

import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
import Radio from "shared/Radio/Radio";
import MySwitch from "components/MySwitch";
import Input from "shared/Input/Input";
import { Autocomplete } from "@mui/material";
import _ from "lodash";

export interface CategorProps {
  mainId?: any;
  maincategory?: string;
}

export interface SubCategorProps {
  id?: any;
  name?: any;
  description?: any;
  subId?: any;
  subcategoryname?: any;
}

export interface SectionSliderProductCardProps {
  categories?: CategorProps[],
  setSelectedcat?: any,
  selectedCat?: any,
  allSubs?: SubCategorProps[],
  setselectedSub?: any,
  selectedSub?: any,
  allSizes?: any,
  allColours?: any,
  selectedSize?: any,
  selectedColour?: any,
  setselectedSize?: any,
  setselectedColour?: any,
  setminPrice?: any,
  minPrice?: any,
  setmaxPrice?: any,
  maxPrice?: any,
  setFilterSortValue?: any,
  filterSortValue?: any,
  setCurrentPage?: any,
  setmainDesc?: any,
  setsearch?: any,
  search?: any,
  allSubs2?: any,
  setselectedSub2?: any,
  selectedSub2?: any,
  allSubs3?: any,
  setselectedSub3?: any,
  selectedSub3?: any,
  filterType?: any,
  allMaterial?: any,
  selectedMaterial?: any;
  allBrand?: any;
  selectedBrand?: any;
  setselectedMaterial?: any;
  setselectedBrand?: any;
  homeDistrict?: any,
  homeCity?: any,
  sethomeDistrict?: any,
  sethomeCity?: any,
}

// DEMO DATA
const DATA_categories = [
  {
    name: "Backpacks",
  },
  {
    name: "Travel Bags",
  },
  {
    name: "Laptop Sleeves",
  },
  {
    name: "Organization",
  },
  {
    name: "Accessories",
  },
];

const DATA_colors = [
  { name: "White" },
  { name: "Beige" },
  { name: "Blue" },
  { name: "Black" },
  { name: "Brown" },
  { name: "Green" },
  { name: "Navy" },
];

const DATA_sizes = [
  { name: "XS" },
  { name: "S" },
  { name: "M" },
  { name: "L" },
  { name: "XL" },
  { name: "2XL" },
];

const DATA_sortOrderRadios = [
  { name: "Most Popular", id: "featured" },
  // { name: "Best Rating", id: "Best-Rating" },
  { name: "Newest", id: "latest" },
  { name: "Price Low - Hight", id: "LowToHigh" },
  { name: "Price Hight - Low", id: "HighToLow" },
];

const PRICE_RANGE = [1, 500];
//
const SidebarFilters: FC<SectionSliderProductCardProps> = ({
  categories = [{ mainId: "", maincategory: "" }],
  setSelectedcat,
  selectedCat,
  allSubs = [],
  setselectedSub,
  selectedSub,
  allSizes = [],
  allColours = [],
  selectedSize,
  selectedColour,
  setselectedSize,
  setselectedColour,
  setminPrice,
  minPrice,
  setmaxPrice,
  maxPrice,
  setFilterSortValue,
  filterSortValue,
  setCurrentPage,
  setmainDesc,
  setsearch,
  search,
  allSubs2 = [],
  setselectedSub2,
  selectedSub2,
  allSubs3,
  setselectedSub3,
  selectedSub3,

}) => {
  //
  const [isOnSale, setIsIsOnSale] = useState(true);
  const [rangePrices, setRangePrices] = useState([minPrice, maxPrice]);
  const [categoriesState, setCategoriesState] = useState<string[]>([]);
  const [colorsState, setColorsState] = useState<string[]>([]);
  const [sizesState, setSizesState] = useState<string[]>([]);
  const [sortOrderStates, setSortOrderStates] = useState<string>("");

  const [catUp, setcatUp] = useState(true);
  const [subcatUp, setsubcatUp] = useState(false);
  const [subcatUp2, setsubcatUp2] = useState(false);
  const [subcatUp3, setsubcatUp3] = useState(false);
  const [clrUp, setclrUp] = useState(false);
  const [sizeUp, setsizeUp] = useState(false);

  //
  const handleChangeCategories = (checked: boolean, name: string) => {
    checked
      ? setCategoriesState([...categoriesState, name])
      : setCategoriesState(categoriesState.filter((i) => i !== name));
  };

  const handleChangeColors = (checked: boolean, name: string) => {
    checked
      ? setColorsState([...colorsState, name])
      : setColorsState(colorsState.filter((i) => i !== name));
  };

  const handleChangeSizes = (checked: boolean, name: string) => {
    checked
      ? setSizesState([...sizesState, name])
      : setSizesState(sizesState.filter((i) => i !== name));
  };

  const removeLocal = () => {
    localStorage.removeItem("sMain");
    localStorage.removeItem("sSub");
  }

  //

  // OK
  const RenderTabsCategories = (props?: any) => {

    const [arrUp, setarrUp] = useState(props?.catUp)

    return (
      <>
        <div>
          <div>
            {/* <span className="flex mt-4 text-sm">Select Size</span> */}
            <Autocomplete
              // multiple
              size="small"
              className="mt-1.5"
              // className="form-group"
              // className="border-neutral-200"
              // value={selectedSizes}
              value={{ maincategory: "", mainId: "" }}
              options={[
                // { maincategory: "All", mainId: "" },
                ...categories]}
              getOptionLabel={(option: any) => option.maincategory}
              onChange={(event, newValue: any) => {

                console.log("select>>>>", newValue)

                // setselectedSizes(newValue)

                // if (newValue !== null) {
                //   setselectedSize(newValue)
                //   // seterrcat(false);
                // } else {
                //   setselectedSize({ measurement: "None", unit: "" });
                //   // seterrcat(false);
                // }

                if (newValue !== null) {
                  // setselectedSizes(newValue)

                  if (newValue?.maincategory === "All") {
                    setselectedSub([])
                  } else {

                    if (selectedCat?.includes(newValue.mainId)) {
                      const index = selectedCat.indexOf(newValue.mainId);

                      console.log(">>>>>>index", index)

                      var arr = [...selectedCat]
                      if (index > -1) {
                        arr.splice(index, 1);
                        console.log("arr>>>>", arr)
                        setSelectedcat(arr)
                      }

                    } else {
                      setSelectedcat(((data: any) => [...data, newValue.mainId]))
                    }


                  }

                  // seterrcat(false);
                } else {
                  // setselectedSub([]);
                  // seterrcat(false);
                }



                setselectedColour("");
                setselectedSize("");
                setCurrentPage(1);
                removeLocal();
                // setmainDesc(item);
                setsearch("");
                props.setcatUp(true);
                setselectedSub([]);
                setselectedSub2([]);
                setselectedSub3([]);

              }}

              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <Input {...params.inputProps} className="bg-[#F4F4F4]" placeholder="Select Category" />
                </div>
              )}
              // renderInput={(params) => (
              //     <TextField
              //         {...params}

              //     />

              // )}
              ListboxProps={{
                style: {
                  maxHeight: "150px",
                  fontSize: "14px",
                  // border: '1px solid white'
                },
              }}
            />
            {false && (
              <div className="text-red-600 text-sm text-right mt-2">
                This field is required
              </div>
            )}
          </div>
          <div className="flex flex-wrap mt-0 mb-0">

            {
              selectedCat.length === 0 &&

              <>
                {/* <div className="bg-[#e2e8f0] w-fit px-4 py-2 flex mr-1 mt-2">

                  <span className="text-xs">{"All"}</span>

                 

                </div> */}
              </>

            }

            {
              selectedCat.map((item: any, index: any) => {

                let data = categories.find(({ mainId }) => mainId === parseInt(item))
                return (
                  <>

                    <div className="bg-[#e2e8f0] w-fit p-2 flex mr-1 mt-2">

                      <span className="text-xs">{data?.maincategory}</span>

                      <div className="flex items-center ml-3 cursor-pointer"
                        onClick={(e) => {
                          var array = [...selectedCat]
                          array.splice(index, 1);
                          setSelectedcat([...array])
                        }}
                      >
                        <i className="las la-times text-base text-center  flex items-center justify-center"></i>

                      </div>

                    </div>
                  </>
                )
              })
            }


          </div>
        </div>

        {/* <div className="relative flex flex-col space-y-4 bg-[#F4F4F4] rounded-lg py-3 px-4">

          <div className="flex items-center justify-between cursor-pointer"
            onClick={() => {

              if (arrUp) {
                setarrUp(false);
                props.setcatUp(false);
              }
              else {
                setarrUp(true);
                props.setcatUp(true);
              }
            }}
          >


            <span className="font-semibold flex items-center">Categories</span>

            {
              arrUp ?

                <i className="flex items-center las la-angle-up"></i>

                :

                <i className="flex items-center las la-angle-down"></i>

            }


          </div>
          {
            arrUp &&

            <>

              <Checkbox
                name={""}
                label={"All"}

                // checked={selectedCat === ""}
                checked={selectedCat?.length === 0}
                sizeClassName="w-5 h-5"
                labelClassName="text-xs font-normal"
                onChange={(checked) => {
                  setSelectedcat([]);
                  setselectedColour("");
                  setselectedSize("");
                  setCurrentPage(1);
                  removeLocal();
                  setmainDesc("");
                  setsearch("");
                  props.setcatUp(true);
                  setselectedSub([]);
                  setselectedSub2([]);
                  setselectedSub3([]);
                  // window.scrollTo({
                  //   top: 0,
                  //   behavior: "smooth"
                  // });
                }}

              // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
              />

              {categories.map((item) => (
                <div key={item.mainId} className="">
                  <Checkbox
                    name={item.mainId}
                    label={item.maincategory}
                    // checked={selectedCat === item.mainId}
                    checked={selectedCat?.includes(item.mainId)}
                    // defaultChecked={categoriesState.includes(item.mainId)}
                    sizeClassName="w-5 h-5"
                    labelClassName="text-xs font-normal"
                    onChange={(checked) => {
                      // setSelectedcat(item.mainId);
                      if (selectedCat?.includes(item.mainId)) {
                        const index = selectedCat.indexOf(item.mainId);

                        console.log(">>>>>>index", index)

                        var arr = [...selectedCat]
                        if (index > -1) {
                          arr.splice(index, 1);
                          console.log("arr>>>>", arr)
                          setSelectedcat(arr)
                        }

                      } else {
                        setSelectedcat(((data: any) => [...data, item.mainId]))
                      }

                      setselectedColour("");
                      setselectedSize("");
                      setCurrentPage(1);
                      removeLocal();
                      setmainDesc(item);
                      setsearch("");
                      props.setcatUp(true);
                      setselectedSub([]);
                      setselectedSub2([]);
                      setselectedSub3([]);
                      // window.scrollTo({
                      //   top: 0,
                      //   behavior: "smooth"
                      // });
                    }}
                  // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                  />

                </div>
              ))}
            </>

          }

        </div> */}
      </>
    );
  };

  // OK
  const RenderTabsSubCategories = (props?: any) => {

    const [arrUp, setarrUp] = useState(props?.subcatUp)

    return (

      <>
        <div>
          <div>
            {/* <span className="flex mt-4 text-sm">Select Size</span> */}
            <Autocomplete
              // multiple
              size="small"
              className="mt-1.5"
              // className="form-group"
              // className="border-neutral-200"
              // value={selectedSizes}
              value={{ subcategoryname: "", subId: "" }}
              options={[...allSubs]}
              getOptionLabel={(option: any) => option.subcategoryname}
              onChange={(event, newValue: any) => {

                console.log("select>>>>", newValue)

                // setselectedSizes(newValue)

                // if (newValue !== null) {
                //   setselectedSize(newValue)
                //   // seterrcat(false);
                // } else {
                //   setselectedSize({ measurement: "None", unit: "" });
                //   // seterrcat(false);
                // }

                if (newValue !== null) {
                  // setselectedSizes(newValue)

                  if (newValue?.subcategoryname === "All") {
                    setselectedSub([])
                  } else {

                    if (selectedSub?.includes(newValue.subId)) {
                      const index = selectedSub.indexOf(newValue.subId);

                      console.log(">>>>>>index", index)

                      var arr = [...selectedSub]
                      if (index > -1) {
                        arr.splice(index, 1);
                        console.log("arr>>>>", arr)
                        setselectedSub(arr)
                      }

                    } else {
                      setselectedSub(((data: any) => [...data, newValue.subId]))
                    }


                  }

                  // seterrcat(false);
                } else {
                  // setselectedSub([]);
                  // seterrcat(false);
                }

                removeLocal();
                props.setsubcatUp(true);
                console.log(">>>>>>asasas")
                setselectedSub2([]);
                setselectedSub3([]);

              }}

              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <Input {...params.inputProps} className="bg-[#F4F4F4]" placeholder="Select Sub Category" />
                </div>
              )}
              // renderInput={(params) => (
              //     <TextField
              //         {...params}

              //     />

              // )}
              ListboxProps={{
                style: {
                  maxHeight: "150px",
                  fontSize: "14px",
                  // border: '1px solid white'
                },
              }}
            />
            {false && (
              <div className="text-red-600 text-sm text-right mt-2">
                This field is required
              </div>
            )}
          </div>
          <div className="flex flex-wrap mt-0 mb-0">

            {/* {
              selectedSub?.length === 0 &&

              <>
                <div className="bg-[#e2e8f0] w-fit px-4 py-2 flex mr-1 mt-2">

                  <span className="text-sm">{"All"}</span>

                 

                </div>
              </>

            } */}

            {
              selectedSub && selectedSub?.length > 0 && selectedSub?.map((item: any, index: any) => {

                let data = allSubs.find(({ subId }) => subId === parseInt(item))
                return (
                  <>

                    <div className="bg-[#e2e8f0] w-fit p-2 flex mr-1 mt-2">

                      <span className="text-xs">{data?.subcategoryname}</span>

                      <div className="flex items-center ml-3 cursor-pointer"
                        onClick={(e) => {
                          var array = [...selectedSub]
                          array.splice(index, 1);
                          setselectedSub([...array])
                        }}
                      >
                        <i className="las la-times text-base text-center  flex items-center justify-center"></i>

                      </div>

                    </div>
                  </>
                )
              })
            }


          </div>
        </div>

        {/* <div className="relative flex flex-col space-y-4 bg-[#F4F4F4] rounded-lg py-3 px-4">
          <div className="flex items-center justify-between cursor-pointer"
            onClick={() => {

              if (arrUp) {
                setarrUp(false);
                props.setsubcatUp(false);
              }
              else {
                setarrUp(true);
                props.setsubcatUp(true);
              }
            }}
          >


            <span className="font-semibold flex items-center">Sub Categories</span>

            {
              arrUp ?

                <i className="flex items-center las la-angle-up"></i>

                :

                <i className="flex items-center las la-angle-down"></i>

            }


          </div>

          {
            arrUp &&

            <>
              {allSubs.map((item: any) => (
                <div key={item.subId} className="">
                  <Checkbox
                    name={item.subId}
                    label={item.subcategoryname}
                    // checked={selectedSub === item.subId}
                    checked={selectedSub?.includes(item.subId)}
                    // defaultChecked={categoriesState.includes(item.mainId)}
                    sizeClassName="w-5 h-5"
                    labelClassName="text-xs font-normal"
                    onChange={(checked) => {
                      // setSelectedcat(item.mainId);
                      if (selectedSub?.includes(item.subId)) {
                        const index = selectedSub.indexOf(item.subId);

                        console.log(">>>>>>index", index)

                        var arr = [...selectedSub]
                        if (index > -1) {
                          arr.splice(index, 1);
                          console.log("arr>>>>", arr)
                          setselectedSub(arr)
                        }

                      } else {
                        setselectedSub(((data: any) => [...data, item.subId]))
                      }


                      removeLocal();
                      props.setsubcatUp(true);
                      console.log(">>>>>>asasas")
                      setselectedSub2([]);
                      setselectedSub3([]);

                      // window.scrollTo({
                      //   top: 0,
                      //   behavior: "smooth"
                      // });
                    }}
                  // onChange={(checked) => {
                  //   setselectedSub(item.subId); removeLocal();
                  //   // window.scrollTo({
                  //   //   top: 0,
                  //   //   behavior: "smooth"
                  //   // });
                  //   props.setsubcatUp(true);
                  // }}
                  // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                  />

                </div>
              ))}
            </>

          }


        </div> */}
      </>

    );
  };


  const RenderTabsSubCategories2 = (props?: any) => {

    const [arrUp, setarrUp] = useState(props?.subcatUp)

    return (
      <>
        <div>
          <div>
            {/* <span className="flex mt-4 text-sm">Select Size</span> */}
            <Autocomplete
              // multiple
              size="small"
              className="mt-1.5"
              // className="form-group"
              // className="border-neutral-200"
              // value={selectedSizes}
              value={{ subcategoryname: "", subId: "" }}
              options={[...allSubs2]}
              getOptionLabel={(option: any) => option.subcategoryname}
              onChange={(event, newValue: any) => {

                console.log("select>>>>", newValue)

                // setselectedSizes(newValue)

                // if (newValue !== null) {
                //   setselectedSize(newValue)
                //   // seterrcat(false);
                // } else {
                //   setselectedSize({ measurement: "None", unit: "" });
                //   // seterrcat(false);
                // }

                if (newValue !== null) {
                  // setselectedSizes(newValue)

                  if (newValue?.subcategoryname === "All") {
                    setselectedSub2([])
                  } else {

                    if (selectedSub2?.includes(newValue.subId)) {
                      const index = selectedSub2.indexOf(newValue.subId);

                      console.log(">>>>>>index", index)

                      var arr = [...selectedSub2]
                      if (index > -1) {
                        arr.splice(index, 1);
                        console.log("arr>>>>", arr)
                        setselectedSub2(arr)
                      }

                    } else {
                      setselectedSub2(((data: any) => [...data, newValue.subId]))
                    }


                  }

                  // seterrcat(false);
                } else {
                  // setselectedSub([]);
                  // seterrcat(false);
                }

                removeLocal();
                props.setsubcatUp(true);

                setselectedSub3([]);

              }}

              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <Input {...params.inputProps} className="bg-[#F4F4F4]" placeholder="Select Sub Category 2" />
                </div>
              )}
              // renderInput={(params) => (
              //     <TextField
              //         {...params}

              //     />

              // )}
              ListboxProps={{
                style: {
                  maxHeight: "150px",
                  fontSize: "14px",
                  // border: '1px solid white'
                },
              }}
            />
            {false && (
              <div className="text-red-600 text-sm text-right mt-2">
                This field is required
              </div>
            )}
          </div>
          <div className="flex flex-wrap mt-0 mb-0">

            {/* {
              selectedSub?.length === 0 &&

              <>
                <div className="bg-[#e2e8f0] w-fit px-4 py-2 flex mr-1 mt-2">

                  <span className="text-sm">{"All"}</span>

                 

                </div>
              </>

            } */}

            {
              selectedSub2 && selectedSub2?.length > 0 && selectedSub2?.map((item: any, index: any) => {

                let data = allSubs2.find((data: any) => data.subId === parseInt(item))
                return (
                  <>

                    <div className="bg-[#e2e8f0] w-fit p-2 flex mr-1 mt-2">

                      <span className="text-xs">{data?.subcategoryname}</span>

                      <div className="flex items-center ml-3 cursor-pointer"
                        onClick={(e) => {
                          var array = [...selectedSub]
                          array.splice(index, 1);
                          setselectedSub2([...array])
                        }}
                      >
                        <i className="las la-times text-base text-center  flex items-center justify-center"></i>

                      </div>

                    </div>
                  </>
                )
              })
            }


          </div>
        </div>

        {/* <div className="relative flex flex-col space-y-4 bg-[#F4F4F4] rounded-lg py-3 px-4">
          <div className="flex items-center justify-between cursor-pointer"
            onClick={() => {

              if (arrUp) {
                setarrUp(false);
                props.setsubcatUp(false);
              }
              else {
                setarrUp(true);
                props.setsubcatUp(true);
              }
            }}
          >


            <span className="font-semibold flex items-center">Sub Categories 2</span>

            {
              arrUp ?

                <i className="flex items-center las la-angle-up"></i>

                :

                <i className="flex items-center las la-angle-down"></i>

            }


          </div>

          {
            arrUp &&

            <>
              {allSubs2.map((item: any) => (
                <div key={item.subId} className="">
                  <Checkbox
                    name={item.subId}
                    label={item.subcategoryname}
                    // checked={selectedSub === item.subId}
                    checked={selectedSub2?.includes(item.subId)}
                    // defaultChecked={categoriesState.includes(item.mainId)}
                    sizeClassName="w-5 h-5"
                    labelClassName="text-xs font-normal"
                    onChange={(checked) => {
                      // setSelectedcat(item.mainId);
                      if (selectedSub2?.includes(item.subId)) {
                        const index = selectedSub2.indexOf(item.subId);

                        console.log(">>>>>>index", index)

                        var arr = [...selectedSub2]
                        if (index > -1) {
                          arr.splice(index, 1);
                          console.log("arr>>>>", arr)
                          setselectedSub2(arr)
                        }

                      } else {
                        setselectedSub2(((data: any) => [...data, item.subId]))
                      }


                      removeLocal();
                      props.setsubcatUp(true);

                      setselectedSub3([]);


                      // window.scrollTo({
                      //   top: 0,
                      //   behavior: "smooth"
                      // });
                    }}
                  // onChange={(checked) => {
                  //   setselectedSub(item.subId); removeLocal();
                  //   // window.scrollTo({
                  //   //   top: 0,
                  //   //   behavior: "smooth"
                  //   // });
                  //   props.setsubcatUp(true);
                  // }}
                  // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                  />

                </div>
              ))}
            </>

          }


        </div> */}
      </>

    );
  };

  const RenderTabsSubCategories3 = (props?: any) => {

    const [arrUp, setarrUp] = useState(props?.subcatUp)

    return (
      <>
        <div>
          <div>
            {/* <span className="flex mt-4 text-sm">Select Size</span> */}
            <Autocomplete
              // multiple
              size="small"
              className="mt-1.5"
              // className="form-group"
              // className="border-neutral-200"
              // value={selectedSizes}
              value={{ subcategoryname: "", subId: "" }}
              options={[...allSubs3]}
              getOptionLabel={(option: any) => option.subcategoryname}
              onChange={(event, newValue: any) => {

                console.log("select>>>>", newValue)

                // setselectedSizes(newValue)

                // if (newValue !== null) {
                //   setselectedSize(newValue)
                //   // seterrcat(false);
                // } else {
                //   setselectedSize({ measurement: "None", unit: "" });
                //   // seterrcat(false);
                // }

                if (newValue !== null) {
                  // setselectedSizes(newValue)

                  if (newValue?.subcategoryname === "All") {
                    setselectedSub3([])
                  } else {

                    if (selectedSub3?.includes(newValue.subId)) {
                      const index = selectedSub3.indexOf(newValue.subId);

                      console.log(">>>>>>index", index)

                      var arr = [...selectedSub3]
                      if (index > -1) {
                        arr.splice(index, 1);
                        console.log("arr>>>>", arr)
                        setselectedSub3(arr)
                      }

                    } else {
                      setselectedSub3(((data: any) => [...data, newValue.subId]))
                    }


                  }

                  // seterrcat(false);
                } else {
                  // setselectedSub([]);
                  // seterrcat(false);
                }

                removeLocal();
                props.setsubcatUp(true);


              }}

              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <Input {...params.inputProps} className="bg-[#F4F4F4]" placeholder="Select Sub Category 3" />
                </div>
              )}
              // renderInput={(params) => (
              //     <TextField
              //         {...params}

              //     />

              // )}
              ListboxProps={{
                style: {
                  maxHeight: "150px",
                  fontSize: "14px",
                  // border: '1px solid white'
                },
              }}
            />
            {false && (
              <div className="text-red-600 text-sm text-right mt-2">
                This field is required
              </div>
            )}
          </div>
          <div className="flex flex-wrap mt-0 mb-0">

            {/* {
              selectedSub?.length === 0 &&

              <>
                <div className="bg-[#e2e8f0] w-fit px-4 py-2 flex mr-1 mt-2">

                  <span className="text-sm">{"All"}</span>

                 

                </div>
              </>

            } */}

            {
              selectedSub3 && selectedSub3?.length > 0 && selectedSub3?.map((item: any, index: any) => {

                let data = allSubs3.find((data: any) => data.subId === parseInt(item))
                return (
                  <>

                    <div className="bg-[#e2e8f0] w-fit p-2 flex mr-1 mt-2">

                      <span className="text-xs">{data?.subcategoryname}</span>

                      <div className="flex items-center ml-3 cursor-pointer"
                        onClick={(e) => {
                          var array = [...selectedSub]
                          array.splice(index, 1);
                          setselectedSub3([...array])
                        }}
                      >
                        <i className="las la-times text-base text-center  flex items-center justify-center"></i>

                      </div>

                    </div>
                  </>
                )
              })
            }


          </div>
        </div>

        {/* <div className="relative flex flex-col space-y-4 bg-[#F4F4F4] rounded-lg py-3 px-4">
          <div className="flex items-center justify-between cursor-pointer"
            onClick={() => {

              if (arrUp) {
                setarrUp(false);
                props.setsubcatUp(false);
              }
              else {
                setarrUp(true);
                props.setsubcatUp(true);
              }
            }}
          >


            <span className="font-semibold flex items-center">Sub Categories 3</span>

            {
              arrUp ?

                <i className="flex items-center las la-angle-up"></i>

                :

                <i className="flex items-center las la-angle-down"></i>

            }


          </div>

          {
            arrUp &&

            <>
              {allSubs3.map((item: any) => (
                <div key={item.subId} className="">
                  <Checkbox
                    name={item.subId}
                    label={item.subcategoryname}
                    // checked={selectedSub === item.subId}
                    checked={selectedSub3?.includes(item.subId)}
                    // defaultChecked={categoriesState.includes(item.mainId)}
                    sizeClassName="w-5 h-5"
                    labelClassName="text-xs font-normal"
                    onChange={(checked) => {
                      // setSelectedcat(item.mainId);
                      if (selectedSub3?.includes(item.subId)) {
                        const index = selectedSub3.indexOf(item.subId);

                        console.log(">>>>>>index", index)

                        var arr = [...selectedSub3]
                        if (index > -1) {
                          arr.splice(index, 1);
                          console.log("arr>>>>", arr)
                          setselectedSub3(arr)
                        }

                      } else {
                        setselectedSub3(((data: any) => [...data, item.subId]))
                      }


                      removeLocal();
                      props.setsubcatUp(true);




                      // window.scrollTo({
                      //   top: 0,
                      //   behavior: "smooth"
                      // });
                    }}
                  // onChange={(checked) => {
                  //   setselectedSub(item.subId); removeLocal();
                  //   // window.scrollTo({
                  //   //   top: 0,
                  //   //   behavior: "smooth"
                  //   // });
                  //   props.setsubcatUp(true);
                  // }}
                  // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                  />

                </div>
              ))}
            </>

          }


        </div> */}
      </>

    );
  };

  // OK
  const RenderTabsColor = (props?: any) => {

    const [arrUp, setarrUp] = useState(props?.clrUp)


    return (
      <div className="relative flex flex-col space-y-4 bg-[#F4F4F4] rounded-lg py-3 px-4">

        <div className="flex items-center justify-between cursor-pointer"
          onClick={() => {

            if (arrUp) {
              setarrUp(false);
              props.setclrUp(false);
            }
            else {
              setarrUp(true);
              props.setclrUp(true);
            }
          }}
        >


          <span className="font-semibold flex items-center">Colors</span>

          {
            arrUp ?

              <i className="flex items-center las la-angle-up"></i>

              :

              <i className="flex items-center las la-angle-down"></i>

          }


        </div>


        {
          arrUp &&

          <>
            <Checkbox
              sizeClassName="w-5 h-5"
              labelClassName="text-xs font-normal"
              name={""}
              label={"All"}
              checked={selectedColour === ""}
              onChange={(checked) => {
                setselectedColour("");
                props.setclrUp(true);
                // window.scrollTo({
                //   top: 0,
                //   behavior: "smooth"
                // });
              }}
            // defaultChecked={colorsState.includes(item.name)}
            // onChange={(checked) => handleChangeColors(checked, item.name)}
            />
            <Checkbox
              sizeClassName="w-5 h-5"
              labelClassName="text-xs font-normal"
              name={"none"}
              label={"None"}
              checked={selectedColour === "none"}
              onChange={(checked) => { setselectedColour("none"); props.setclrUp(true); }}
            // defaultChecked={colorsState.includes(item.name)}
            // onChange={(checked) => handleChangeColors(checked, item.name)}
            />
            {allColours.map((item: any) => (
              <div key={item} className="">
                <Checkbox
                  sizeClassName="w-5 h-5"
                  labelClassName="text-xs font-normal capitalize"
                  name={item}
                  label={item}
                  checked={selectedColour === item}
                  onChange={(checked) => {
                    setselectedColour(item);
                    props.setclrUp(true);
                    // window.scrollTo({
                    //   top: 0,
                    //   behavior: "smooth"
                    // });
                  }}

                // defaultChecked={colorsState.includes(item.name)}
                // onChange={(checked) => handleChangeColors(checked, item.name)}
                />
              </div>
            ))}
          </>

        }

      </div>
    );
  };

  // OK
  const RenderTabsSize = (props?: any) => {
    const [arrUp, setarrUp] = useState(props?.sizeUp)


    return (
      <div className="relative flex flex-col  space-y-4 bg-[#F4F4F4] rounded-lg py-3 px-4">

        <div className="flex items-center justify-between cursor-pointer"
          onClick={() => {

            if (arrUp) {
              setarrUp(false);
              props.setsizeUp(false);
            }
            else {
              setarrUp(true);
              props.setsizeUp(true);
            }
          }}
        >


          <span className="font-semibold flex items-center">Sizes</span>

          {
            arrUp ?

              <i className="flex items-center las la-angle-up"></i>

              :

              <i className="flex items-center las la-angle-down"></i>

          }


        </div>

        {
          arrUp &&

          <>
            <Checkbox
              sizeClassName="w-5 h-5"
              labelClassName="text-xs font-normal"
              name={""}
              label={"All"}
              checked={selectedSize === ""}
              onChange={(checked) => {
                setselectedSize("");
                props.setsizeUp(true);
                // window.scrollTo({
                //   top: 0,
                //   behavior: "smooth"
                // });
              }}
            // defaultChecked={colorsState.includes(item.name)}
            // onChange={(checked) => handleChangeColors(checked, item.name)}
            />
            <Checkbox
              sizeClassName="w-5 h-5"
              labelClassName="text-xs font-normal"
              name={"none"}
              label={"None"}
              checked={selectedSize === "none"}
              onChange={(checked) => { setselectedSize("none"); props.setsizeUp(true); }}
            // defaultChecked={colorsState.includes(item.name)}
            // onChange={(checked) => handleChangeColors(checked, item.name)}
            />
            {allSizes.map((item: any) => (
              <div key={item} className="">
                <Checkbox
                  name={item}
                  label={item}
                  checked={selectedSize === item}
                  // defaultChecked={sizesState.includes(item.name)}
                  onChange={(checked) => {
                    setselectedSize(item);
                    props.setsizeUp(true);
                    // window.scrollTo({
                    //   top: 0,
                    //   behavior: "smooth"
                    // });
                  }}
                  // onChange={(checked) => handleChangeSizes(checked, item.name)}
                  sizeClassName="w-5 h-5"
                  labelClassName="text-xs font-normal"
                />
              </div>
            ))}
          </>

        }



      </div>
    );
  };

  // OK
  const renderTabsPriceRage = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-5 pr-3">
        <div className="space-y-5">
          <span className="font-semibold">Price range</span>
          <Slider
            range
            min={0}
            max={999}
            step={1}
            defaultValue={[0, 999]}
            allowCross={false}
            onAfterChange={(_input: any | number | number[]) => {

              // setRangePrices(_input as number[]);
              setminPrice(_input[0]);
              setmaxPrice(_input[1]);

            }

            }
            onChange={(_input: any | number | number[]) => {
              setRangePrices(_input as number[]);
              // setminPrice(_input[0]);
              // setmaxPrice(_input[1]);
            }
            }
          // onChange={(e : any)=>{
          //   console.log(">>>>>>>",e)
          //   setminPrice(e[0]);
          //   setmaxPrice(e[1]);
          // }}
          />
        </div>

        <div className="flex justify-between space-x-5">
          <div>
            <label
              htmlFor="minPrice"
              className="block text-xs font-medium text-neutral-700 dark:text-neutral-300"
            >
              Min price
            </label>
            <div className="mt-1 relative rounded-md">
              <span className="absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-xs">
                $
              </span>
              <input
                type="text"
                name="minPrice"
                disabled
                id="minPrice"
                className="block w-32 pr-10 pl-4 sm:text-xs border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                value={rangePrices[0]}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="maxPrice"
              className="block text-xs font-medium text-neutral-700 dark:text-neutral-300"
            >
              Max price
            </label>
            <div className="mt-1 relative rounded-md">
              <span className="absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-xs">
                $
              </span>
              <input
                type="text"
                disabled
                name="maxPrice"
                id="maxPrice"
                className="block w-32 pr-10 pl-4 sm:text-xs border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                value={rangePrices[1]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // OK
  const renderTabsSortOrder = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-4">
        <span className="font-semibold mb-2.5">Sort order</span>
        <Radio
          id={""}
          key={""}
          name="radioNameSort"
          label={"None"}
          checked={filterSortValue === ""}
          // defaultChecked={sortOrderStates === item.id}
          sizeClassName="w-5 h-5"
          onChange={setFilterSortValue}
          className="!text-xs"
        />
        {DATA_sortOrderRadios.map((item) => (
          <Radio
            id={item.id}
            key={item.id}
            name="radioNameSort"
            label={item.name}
            checked={filterSortValue === item.id}
            // defaultChecked={sortOrderStates === item.id}
            sizeClassName="w-5 h-5"
            onChange={setFilterSortValue}
            className="!text-xs"
          />
        ))}
      </div>
    );
  };

  return (
    <div className="text-sm">
      <RenderTabsCategories catUp={catUp} setcatUp={setcatUp} />

      {allSubs.length > 0 && selectedCat.length > 0 &&
        <RenderTabsSubCategories subcatUp={subcatUp} setsubcatUp={setsubcatUp} />
      }

      {allSubs2.length > 0 && selectedCat.length > 0 && selectedSub.length > 0 &&
        <RenderTabsSubCategories2 subcatUp={subcatUp2} setsubcatUp={setsubcatUp2} />
      }

      {allSubs3.length > 0 && selectedCat.length > 0 && selectedSub.length > 0 && selectedSub2.length > 0 &&
        <RenderTabsSubCategories3 subcatUp={subcatUp3} setsubcatUp={setsubcatUp3} />
      }
      {/* {<RenderTabsColor clrUp={clrUp} setclrUp={setclrUp} />}
      {<RenderTabsSize sizeUp={sizeUp} setsizeUp={setsizeUp} />} */}
      {/* {renderTabsPriceRage()} */}
      {/* <div className="py-8 pr-2">
        <MySwitch
          label="On sale!"
          desc="Products currently on sale"
          enabled={isOnSale}
          onChange={setIsIsOnSale}
        />
      </div> */}
      {/* {renderTabsSortOrder()} */}
    </div>
  );
};

export default SidebarFilters;
