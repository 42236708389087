import React, { useEffect } from "react";
import { CurrentUser, getCart, getCartList, getMemberCart, getShortList, getWishlistList } from "../Services/API/Get";
import _ from "lodash";

const UserShortList = async () => {
  const myCountOfCart = async () => {
    let Count = [];

    await getShortList({ limit: Number.MAX_SAFE_INTEGER })
      .then((res) => {
        console.log("get shortlist >>>>>", res)
        let m = 0;
        // const abc = res.data?.map((e) => {
        //   m = m + e.qty * e.discountedprice;
        // });
        Count = res.data;

        // this.setState({
        //   array: res.data,
        //   total: m,
        // });
      })
      .catch((err) => {
        Count = [];


      });
    console.log("Cart count >>>", Count);
    return Count;


  };

  return await myCountOfCart();
};

export default UserShortList;
