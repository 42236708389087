import React, { useState, useEffect, useRef, Suspense } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import PageCollection from "containers/PageCollection";
import PageSearch from "containers/PageSearch";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import ProductDetailPage2 from "containers/ProductDetailPage/ProductDetailPage2";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountBilling from "containers/AccountPage/AccountBilling";
import AccountOrder from "containers/AccountPage/AccountOrder";
import CartPage from "containers/ProductDetailPage/CartPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import PageCollection2 from "containers/PageCollection2";
import CartCount from "Constant/CartCount";
import AuthContext from "Context/AuthContext";
import CartContext from "Context/CartContext";
import StepsContext from "Context/StepsContext";
import { AppliedJobs, CurrentUser } from "Services/API/Get";
import LoadingSpinner from "shared/LoadingSpinner";
import ResetPass from "containers/Forgot Password";
import UpdatePass from "containers/Update Password";
import SwitchDarkMode2 from "shared/SwitchDarkMode/SwitchDarkMode2";
import TotalContext from "Context/TotalContext";
import UserWishList from "Constant/WishList";
import WishContext from "Context/WishContext";
import PageServicing from "containers/PageAbout/PageServicing";
import PageFinance from "containers/PageAbout/PageFinance";
import PageShipping from "containers/PageAbout/PageShipping";
import PageReturns from "containers/PageAbout/PageReturns";
import PageConditions from "containers/PageAbout/PageConditions";
import BlogSingle2 from "containers/BlogPage/BlogSingle2";
import BlogSingle3 from "containers/BlogPage/BlogSingle3";
import PageScrubber from "containers/PageAbout/PageScrubber";
import PageSweeper from "containers/PageAbout/PageSweeper";
import PageVaccum from "containers/PageAbout/PageVaccum";
import PageCarpet from "containers/PageAbout/PageCarpet";
import PageProfile from "containers/PageProfile/PageProfile";
import PageCollectionEvent from "containers/PageCollectionEvent";
import PageCollectionForum from "containers/PageCollectionForum";
import PageSingleJob from "containers/PageAbout/PageSingleJob";
import PageCollectionJobs from "containers/PageCollectionJobs";
import PageCollectionWholesalers from "containers/PageCollectionWholesalers";
import PageCollectionFarmers from "containers/PageCollectionFarmers";
import PageCollectionProviders from "containers/PageCollectionProviders";
import PageCollectionMarketPlace from "containers/PageCollectionMarketPlace";
import PageCollectionBlogs from "containers/PageCollectionBlogs";
import PageSingleEvent from "containers/PageAbout/PageSingleEvent";
import PageSingleBlog from "containers/PageAbout/PageSingleBlog";
import PageFAQS from "containers/PageAbout/PageFAQS";
import Navbar from "components/NewHome/Navbar/Main";
import Home from "components/NewHome/Home/Main";
import NewFooter from "components/NewHome/Footer/Main";

import PageCollectionShop from "containers/PageCollectionShop";
import PageCollectionContracts from "containers/PageCollectionContracts";
import PageCollectionSellers from "containers/PageCollectionSellers";
import PageCollectionRent from "containers/PageCollectionRent";
import PageSingleContract from "containers/PageAbout/PageSingleContract";

import PageUserDelivery from "containers/PageAbout/PageUserDelivery";
import PageUserRefund from "containers/PageAbout/PageUserRefund";

import PageAboutNew from "containers/PageAbout/PageAboutNew";

import PageLoading from "containers/PageLoading/Page404";
import i18n from "i18n";

import ResetPassOTP from "containers/Forgot PasswordOTP";
import UserShortList from "Constant/ShortList";
import UserShortListJob from "Constant/ShortListJob";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  // { path: "/", exact: true, component: Home },
  // { path: "/#", exact: true, component: PageHome },
  { path: "/home2", exact: true, component: PageHome2 },
  { path: "/home3", exact: true, component: PageHome3 },
  //
  { path: "/home-header-2", exact: true, component: PageHome },
  { path: "/product-detail/:id", component: ProductDetailPage },
  { path: "/product-detail-2", component: ProductDetailPage2 },
  //
  { path: "/page-collection-2", component: PageCollection2 },
  { path: "/page-collection", component: PageCollection },
  { path: "/page-search", component: PageSearch },
  //
  { path: "/account", component: AccountPage },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-change-password", component: AccountPass },
  { path: "/account-billing", component: AccountBilling },
  { path: "/account-my-order", component: AccountOrder },
  //
  { path: "/cart", component: CartPage },
  { path: "/checkout", component: CheckoutPage },
  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAboutNew },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },
  { path: "/reset-password", component: ResetPass },
  { path: "/update-password", component: UpdatePass },
  { path: "/servicing", component: PageServicing },
  { path: "/finance", component: PageFinance },
  { path: "/shipping", component: PageShipping },
  { path: "/cancellation", component: PageReturns },
  { path: "/conditions", component: PageConditions },
  { path: "/blog-single-2", component: BlogSingle2 },
  { path: "/blog-single-3", component: BlogSingle3 },
  { path: "/service-scrubber", component: PageScrubber },
  { path: "/service-sweeper", component: PageSweeper },
  { path: "/service-vaccum", component: PageVaccum },
  { path: "/service-carpet", component: PageCarpet },
  { path: "/profile", component: PageProfile },
  { path: "/profile-view/:id", component: PageProfile },
  { path: "/events", component: PageCollectionEvent },
  { path: "/community", component: PageCollectionForum },
  { path: "/job-single/:id", component: PageSingleJob },
  { path: "/jobs", component: PageCollectionJobs },
  { path: "/blogs", component: PageCollectionBlogs },
  { path: "/service-providers", component: PageCollectionWholesalers },
  { path: "/farmers", component: PageCollectionFarmers },
  { path: "/service-providers", component: PageCollectionProviders },
  { path: "/marketplace", component: PageCollectionMarketPlace },
  { path: "/event-single/:id", component: PageSingleEvent },
  { path: "/single-blog", component: PageSingleBlog },
  { path: "/faqs", component: PageFAQS },
  { path: "/shop", component: PageCollectionShop },
  { path: "/contracts", component: PageCollectionContracts },
  { path: "/sellers", component: PageCollectionSellers },
  { path: "/rent", component: PageCollectionRent },
  { path: "/contract-single/:id", component: PageSingleContract },
  { path: "/user-refund/:id", component: PageUserRefund },
  { path: "/user-delivery/:id", component: PageUserDelivery },
  { path: "/loading", component: PageLoading },

  { path: "/reset-password-otp", component: ResetPassOTP }
];

const Routes = () => {
  const [user, setUser] = useState(null);
  const [lopen, setlopen] = useState(true);

  const [cart, setCart] = useState(0);
  const [wish, setWish] = useState<any>([]);
  const [cartotal, setcartotal] = useState(0);
  const [steps, setSteps] = useState(1);
  const [selTab, setselTab] = useState<any>(null);
  const [menuCategory, setMenuCategory] = useState(null);
  const testRef = useRef(null);
  const testRef2 = useRef(null);
  const testRef3 = useRef(null);

  const [lan, setLan] = useState("English");

  const [applied, setapplied] = useState<any>([]);

  const [short, setshort] = useState<any>([]);

  const [shortJob, setshortJob] = useState<any>([]);

  useEffect(() => {
    const response = window.localStorage.getItem(
      `${window.location.hostname}.login`
    );

    // if (!window.localStorage.getItem(`loading`)) {
    //   setlopen(true);

    //   window.localStorage.setItem("loading", "true")
    // }

    if (response) {
      CartCount().then((res) => {
        console.log("count res-------->", res);
        setCart(res);
        if (res !== 0) {
          setSteps(4);
        }
        if (window.localStorage.getItem(`order`) === "true") {
          setSteps(6);
        }
      });

      UserWishList().then((res) => {
        console.log("count res-------->", res);
        setWish(res);
      });

      UserShortList().then((res) => {
        console.log("short res-------->", res);
        setshort(res);
      });

      UserShortListJob().then((res) => {
        console.log("short job res-------->", res);
        setshortJob(res);
      });

      console.log("-------->", response);

      setSteps(2);
      setlopen(true);
      CurrentUser()
        .then((res) => {
          if (res.statusCode === 200) {
            const cData = res.data[0];
            console.log("+++++++++++", cData);
            const response = window.localStorage.setItem(
              `${window.location.hostname}.userDetails`,
              JSON.stringify(cData)
            );
            setUser(cData);
            // setTimeout(() => {
            //   setlopen(false);
            // }, 3000);

            if (cData.packageId !== null) {
              setSteps(3);
            }
          } else {
            setlopen(false);
          }
          if (window.localStorage.getItem(`order`) === "true") {
            setSteps(6);
          }
        })
        .catch((err) => {
          // setTimeout(() => {
          //   setlopen(false);
          // }, 3000);
        });
    } else {
      if (!window.localStorage.getItem("tempUser")) {
        console.log(
          ">>>>>>>QQQCCC",
          Math.floor(100000 + Math.random() * 900000)
        );

        window.localStorage.setItem(
          "tempUser",
          Math.floor(100000 + Math.random() * 900000).toString()
        );

        window.location.reload();
      }
    }

    CartCount().then((res) => {
      console.log("count res-------->", res);
      setCart(res);
      if (res !== 0) {
        setSteps(4);
      }
      if (window.localStorage.getItem(`order`) === "true") {
        setSteps(6);
      }
    });

    UserWishList().then((res) => {
      console.log("count res-------->", res);
      setWish(res);
    });

    UserShortList().then((res) => {
      console.log("short res-------->", res);
      setshort(res);
    });

    UserShortListJob().then((res) => {
      console.log("short job res-------->", res);
      setshortJob(res);
    });

    AppliedJobs({
      jobType: JSON.stringify([])
    })
      .then((res) => {
        console.log(">>>>>>>jobs", res.data);
        setapplied(res.data.result);
      })
      .catch((error) => {
        // setlopen(false);
        setapplied([]);
        console.log("Error", error.response);

        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });

    const el = document.querySelector("body") as HTMLElement;

    // if (window.localStorage.getItem(`lan`) === "Sinhala") {
    //   setLan("Sinhala")
    //   i18n.changeLanguage('pl');
    //   el.style.fontFamily = 'Abhaya';

    //   setTimeout(() => {
    //     setlopen(false);
    //   }, 3000);

    // } else if (window.localStorage.getItem(`lan`) === "English") {
    //   setLan("English")
    //   i18n.changeLanguage('en');
    //   el.style.fontFamily = 'Inter';

    //   setTimeout(() => {
    //     setlopen(false);
    //   }, 3000);
    // }
    // else {
    //   setLan("Sinhala")
    //   i18n.changeLanguage('pl');
    //   el.style.fontFamily = 'Abhaya';

    //   setTimeout(() => {
    //     setlopen(false);
    //   }, 3000);
    // }

    // setLan("Sinhala")
    // i18n.changeLanguage('pl');
    // el.style.fontFamily = 'NotoSans';

    setTimeout(() => {
      setlopen(false);
    }, 3000);
  }, []);

  return (
    // <Suspense fallback={<LoadingSpinner open={true} />}>
    <AuthContext.Provider value={{ user: user, setUser: setUser }}>
      <StepsContext.Provider
        value={{
          steps: steps,
          setSteps: setSteps,
          selTab: selTab,
          setselTab: setselTab
        }}
      >
        <WishContext.Provider
          value={{
            wish: wish,
            setWish: setWish,
            applied: applied,
            setapplied: setapplied,
            lan: lan,
            setLan: setLan,
            short: short,
            setshort: setshort,
            shortJob: shortJob,
            setshortJob: setshortJob
          }}
        >
          <TotalContext.Provider
            value={{ cartotal: cartotal, setcartotal: setcartotal }}
          >
            <CartContext.Provider
              value={{
                cart: cart,
                setCart: setCart
              }}
            >
              {/* <LoadingSpinner open={lopen} /> */}
              <div style={{ display: "none" }}>
                <SwitchDarkMode2 />
              </div>
              <Router basename="/">
                <ScrollToTop />
                <SiteHeader />
                {/* <Navbar /> */}
                <Switch>
                  {pages.map(({ component, path, exact }) => {
                    return (
                      <Route
                        key={path}
                        component={component}
                        exact={!!exact}
                        path={path}
                      />
                    );
                  })}
                  <Route component={Page404} />
                </Switch>
                {/* <NewFooter /> */}
                <Footer />
              </Router>
            </CartContext.Provider>
          </TotalContext.Provider>
        </WishContext.Provider>
      </StepsContext.Provider>
    </AuthContext.Provider>
    // </Suspense>
  );
};

export default Routes;
