import React, { FC, useState, useEffect, useRef } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL, IMAGE_SERVER_URL_DEV } from "Constant/System";
import Company1 from "images/avatars/Image-1.png";
import ModalQuickView from "components/ModalEvent";
import { getEvents } from "Services/API/Get";
import { addToast } from "shared/Toast";
import moment from "moment";
import { DeleteEvent, DeleteJob } from "Services/API/Delete";
import { useHistory } from "react-router-dom";


const Events = (props) => {
    const router = useHistory();

    const [images, setImages] = useState([]);
    const [showModalQuickView, setShowModalQuickView] = useState(false)

    const [events, setevents] = useState([]);

    const [search, setsearch] = useState("");
    const [category, setcategory] = useState("");

    const [selectedEvent, setselectedEvent] = useState("");

    const [fromDate, setfromDate] = useState("");
    const [toDate, settoDate] = useState("");

    const [currUser, setcurrUser] = useState(props.currUser);

    const getAllEvents = () => {
        console.log(">>>>>>sdsxcddf")
        getEvents({
            eventType: JSON.stringify([]),
            title: search,
            fromDate: fromDate ? fromDate : "1999-01-04",
            toDate: toDate ? toDate : "9999-01-04"
        })
            .then((res) => {
                console.log(">>>>>>>events", res.data);
                setevents(res.data.result);
            })
            .catch((error) => {
                // setlopen(false);
                setevents([]);
                console.log("Error", error.response);
                addToast(error.response.data.message, "warning");
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            })
    }

    useEffect(() => {
        getAllEvents();
    }, [showModalQuickView, search, fromDate, toDate])

    return (
        <>
            <ModalQuickView
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
                selectedEvent={selectedEvent}
            />
            <div className="flex justify-center w-full mt-12">

                <span className="block text-xl font-bold flex justify-center w-full text-center">
                    Events List
                </span>


            </div>
            <div className="w-full flex justify-center px-3">

                <div className="w-full md:w-8/12 lg:w-7/12 xl:w-[45%]">
                    <div className="flex w-full items-center justify-center">
                        <button
                            className={
                                `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-12 `}

                            onClick={() => {
                                if (currUser?.packageId) {
                                    setselectedEvent("");
                                    setShowModalQuickView(true)
                                } else {
                                    router.push("/subscription")
                                }
                            }}
                        >
                            {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                            <span className="block  text-sm font-medium "
                            >
                                Create an event
                            </span>

                        </button>
                    </div>
                    <form className="mt-6 w-full">

                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                            </div>
                            <Input type="search" id="default-search" className="block w-full p-4 pl-10 text-base text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search ..." required
                                onChange={(e) => {
                                    setsearch(e.target.value);
                                }}
                            />

                        </div>
                    </form>

                    <div className="w-full mt-4 space-y-4">

                        <div>
                            <Label className="text-sm">From</Label>
                            <Input className="mt-1.5" type="date"
                                onChange={(e) => {
                                    setfromDate(e.target.value);
                                    // seterrlasttName(false);
                                }}
                                value={fromDate}
                            />
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">To</Label>
                            <Input className="mt-1.5" type="date"
                                onChange={(e) => {
                                    settoDate(e.target.value);
                                    // seterrlasttName(false);
                                }}
                                value={toDate}
                            />
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>


                    </div>
                    <div className="mt-10 space-y-2">
                        {
                            events.map((item, index) => {
                                return (
                                    <>
                                        <div className="flex">
                                            <div className="bg-gray-100 flex w-full px-4 py-3 rounded-lg space-x-4">
                                                <div className="flex justify-start w-4/12 items-center">
                                                    <div className="w-12 h-12 object-fill bg-contain rounded-lg border-2 border-white"
                                                        style={{
                                                            // backgroundImage: `url(${Company1})`
                                                            backgroundImage: item ? `url(${IMAGE_SERVER_URL_DEV}${item?.avatar})` : `url(${Company1})`
                                                        }}
                                                    >

                                                    </div>

                                                </div>
                                                <div className="w-full flex justify-start items-center">
                                                    <div>
                                                        <div className="text-base font-medium flex justify-start items-center">{item?.title}</div>

                                                        <div className="text-sm font-medium flex justify-start items-center">{item?.country}</div>
                                                    </div>
                                                </div>

                                                <div className="w-6/12 flex justify-start items-center">
                                                    <div>
                                                        <div className="text-base font-medium flex justify-start items-center">{moment(item?.createdAt).format("YYYY-MM-DD")}</div>

                                                        <div className="text-sm font-medium flex justify-start items-center">Country</div>
                                                    </div>
                                                </div>

                                                <div className="w-6/12 flex justify-start items-center">
                                                    <div>
                                                        <div className="text-base font-medium flex justify-start items-center">Ref. Number</div>

                                                        <div className="text-sm font-medium flex justify-start items-center">New</div>
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="flex items-center">
                                                <i className="las la-pencil-alt text-xl text-left ml-2 cursor-pointer"
                                                    onClick={() => {
                                                        console.log("item>>>>", item)
                                                        setselectedEvent(item);
                                                        setShowModalQuickView(true);

                                                    }}
                                                ></i>
                                                <i className="las la-trash-alt text-xl text-left ml-2 text-red-500 cursor-pointer"
                                                    onClick={() => {
                                                        DeleteEvent(item?.id)
                                                            .then((res) => {

                                                                addToast("Event successfully deleted", "success");
                                                                getAllEvents();
                                                            })
                                                            .catch((error) => {
                                                                // setlopen(false);
                                                                console.log("Error", error.response);
                                                                addToast(error.response.data.message, "warning");
                                                                // setOpenToast(true);
                                                                // setColor(COLORS.Orange);
                                                                // setAlertClass("error");
                                                                // setMsg(error.response.data.message);
                                                            })
                                                    }}
                                                ></i>
                                            </div>
                                        </div>



                                    </>
                                );
                            })
                        }

                    </div>

                </div>
            </div>

        </>
    )

}

export default Events;