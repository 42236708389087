import React, {
  FC,
  useState,
  useEffect,
  useRef,
  Fragment,
  useContext
} from "react";
import Stripe from "images/sLogo.png";
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL, IMAGE_SERVER_URL_DEV } from "Constant/System";
import _, { lowerCase, map, upperCase, upperFirst } from "lodash";
import { postAds, postJobs, postJobSeeker } from "Services/API/Post";
import { addToast } from "shared/Toast";
import {
  CurrentUser,
  GetUserId,
  JobSeekerAchievements,
  JobSeekerExperience,
  JobSeekerQualification,
  getCities,
  getDistricts,
  getJobDetails,
  getOrderDetails,
  getProfiles,
  getUserIp,
  getUserLocation
} from "Services/API/Get";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Box
} from "@mui/material";
import {
  UpdateAds,
  UpdateJobs,
  UpdateJobSeekeAchievements,
  UpdateJobSeeker,
  UpdateJobSeekerExperiences,
  UpdateJobSeekerPersonalDetails,
  UpdateJobSeekerQualifications,
  UpdatePackage
} from "Services/API/Put";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import moment from "moment";
import SubData from "data/australian_postcodes.json";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoadingSpinner from "shared/LoadingSpinner";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import BotToast from "shared/BotToast";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import JobsCard from "components/CardCategories/JobsCard";
import { t } from "i18next";

import { Dialog, Popover, Transition } from "@headlessui/react";
import Checkbox from "shared/Checkbox/Checkbox";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import WishContext from "Context/WishContext";

import Avatar from "images/avatars/Image-2.png";
import classnames from "classnames";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d867b"
    },
    secondary: {
      main: "#2d867b"
    }
  }
});

const CATEG = [
  {
    value: "Seasonal",
    name: "Seasonal"
  },
  {
    value: "Casual",
    name: "Casual"
  },
  {
    value: "Temporary",
    name: "Temporary"
  },
  // {
  //     value: "Fixed",
  //     name: "Fixed"
  // },
  {
    value: "Part Time",
    name: "Permanent – Part Time"
  },
  {
    value: "Full Time",
    name: "Permanent – Full time"
  }
];

const Jobs = ({
  selectedJob = null,
  onCloseModalQuickView,
  getAllJobs = null
}) => {
  const { lan, setLan } = useContext(WishContext);

  const [images, setImages] = useState(
    selectedJob?.cv ? JSON.parse(selectedJob?.cv) : []
  );

  const [title, settitle] = useState(
    selectedJob
      ? selectedJob?.language === "En"
        ? "English"
        : selectedJob?.language === "Si"
        ? "Sinhala"
        : ""
      : ""
  );
  const [jobType, setjobType] = useState(selectedJob ? selectedJob?.type : "");
  const [country, setcountry] = useState(
    selectedJob ? selectedJob?.country : "Australia"
  );
  const [homeDistrict, sethomeDistrict] = useState(
    selectedJob ? selectedJob?.district : ""
  );
  const [homeCity, sethomeCity] = useState(
    selectedJob ? selectedJob?.suburb : ""
  );
  const [duration, setduration] = useState(
    selectedJob ? selectedJob?.duration : ""
  );
  const [description, setdescription] = useState(
    selectedJob ? selectedJob?.description : ""
  );
  const [currency, setcurrency] = useState(
    selectedJob ? selectedJob?.currency : "AUD"
  );
  const [rate, setrate] = useState(selectedJob ? selectedJob?.hourlyRate : "");

  let day = moment().add(365, "days");
  day = moment(day).format("YYYY-MM-DD");

  // console.log(">>>>>>>day", moment(day).toDate());
  // setexpire(moment(day).toDate());

  const [expire, setexpire] = useState(
    selectedJob
      ? moment(selectedJob?.expiryDate).toDate()
      : moment(day).toDate()
  );

  const [expireVisibility, setexpireVisibility] = useState(
    selectedJob?.expireVisibility
      ? moment(selectedJob?.expireVisibility).toDate()
      : ""
  );

  const [Keywords, setKeywords] = useState("");

  const [oldPkg, setoldPkg] = useState(
    selectedJob ? selectedJob?.packageId?.toString() : ""
  );

  const [errimages, seterrImages] = useState([]);

  const [errtitle, seterrtitle] = useState(false);
  const [errjobType, seterrjobType] = useState(false);
  const [errcountry, seterrcountry] = useState(false);
  const [errsuburb, seterrsuburb] = useState(false);
  const [errduration, seterrduration] = useState(false);
  const [errdescription, seterrdescription] = useState(false);
  const [errcurrency, seterrcurrency] = useState(false);
  const [errrate, seterrrate] = useState(false);
  const [errexpire, seterrexpire] = useState(false);
  const [errKeywords, seterrKeywords] = useState(false);
  const [errhomeDistrict, seterrhomeDistrict] = useState(false);
  const [errhomeCity, seterrhomeCity] = useState(false);

  const [homeDistrictId, sethomeDistrictId] = useState(0);
  const [allHCities, setallHCities] = useState([]);
  const [allHDistricts, setallHDistricts] = useState([]);

  const [currUser, setcurrUser] = useState("");

  const [packageId, setpackageId] = useState(
    selectedJob ? selectedJob?.packageId?.toString() : "1"
  );

  const [lOpen, setlOpen] = useState(false);

  const [errpackages, seterrpackages] = useState(false);

  const history = useHistory();

  const [newSubArray, setnewSubArray] = useState([]);
  const [newSubs, setnewSubs] = useState([]);

  const [bOpen, setbOpen] = useState(false);
  const [bMsg, setbMsg] = useState("");
  const [bStatus, setbStatus] = useState("");

  const ref = useRef(null);

  const [editorData, setEditorData] = useState(
    selectedJob?.duties ? selectedJob?.duties : "<br/><br/><br/><br/><br/>"
  );

  const [newSubs2, setnewSubs2] = useState([]);

  const [profiles, setprofiles] = useState([]);
  const [selectedProfile, setselectedProfile] = useState(
    selectedJob ? { id: selectedJob?.memberId, name: selectedJob?.title } : ""
  );

  const handleScroll = () => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const [adDetails, setadDetails] = useState("");

  const [ipCountry, setipCountry] = useState("Other");

  const [selectedTab, setselectedTab] = useState(1);

  const [modal1, setmodal1] = useState(false);

  const [avatar, setavatar] = useState(
    selectedJob?.avatar ? JSON.parse(selectedJob?.avatar)[0] : ""
  );

  const [proTab, setproTab] = useState(1);

  const [contactPerson, setcontactPerson] = useState("");
  const [errCountry, seterrCountry] = useState(false);
  const [errhomepostCode, seterrhomepostCode] = useState(false);
  const [errcontactPerson, seterrcontactPerson] = useState(false);
  const [homepostCode, sethomepostCode] = useState("");

  const [surName, setsurName] = useState(
    selectedJob ? selectedJob?.surName : ""
  );
  const [errsurName, seterrsurName] = useState(false);
  const [otherName, setotherName] = useState(
    selectedJob ? selectedJob?.firstMiddleName : ""
  );
  const [errotherName, seterrotherName] = useState(false);
  const [gender, setgender] = useState(selectedJob ? selectedJob?.gender : "");
  const [errgender, seterrgender] = useState(false);
  const [dob, setdob] = useState(
    selectedJob ? moment(selectedJob?.dob).toDate() : ""
  );
  const [errdob, seterrdob] = useState(false);
  const [email, setemail] = useState(selectedJob ? selectedJob?.email : "");
  const [erremail, seterremail] = useState(false);
  const [contact, setcontact] = useState(
    selectedJob ? selectedJob?.mobileNumber : ""
  );
  const [errcontact, seterrcontact] = useState(false);
  const [landNo, setlandNo] = useState(
    selectedJob ? selectedJob?.landNmuber : ""
  );
  const [errlandNo, seterrlandNo] = useState(false);

  const [role, setrole] = useState(selectedJob ? selectedJob?.designation : "");
  const [errrole, seterrrole] = useState(false);
  const [category, setcategory] = useState(
    selectedJob ? selectedJob?.category : ""
  );
  const [errcategory, seterrcategory] = useState(false);
  const [company, setcompany] = useState(
    selectedJob ? selectedJob?.companyName : ""
  );
  const [errcompany, seterrcompany] = useState(false);
  const [empSt, setempSt] = useState(
    selectedJob ? selectedJob?.employmentStatus : ""
  );
  const [errempSt, seterrempSt] = useState(false);
  const [startD, setstartD] = useState(
    selectedJob ? moment(selectedJob?.employmentStartDate).toDate() : ""
  );
  const [errstartD, seterrstartD] = useState(false);
  const [endD, setendD] = useState(
    selectedJob ? moment(selectedJob?.employmentEndDate).toDate() : ""
  );
  const [errendD, seterrendD] = useState(false);
  const [duties, setduties] = useState(
    selectedJob ? selectedJob?.responsibilites : ""
  );
  const [errduties, seterrduties] = useState(false);

  const [field, setfield] = useState(
    selectedJob ? selectedJob?.fieldOfStudy : ""
  );
  const [errfield, seterrfield] = useState(false);
  const [qualif, setqualif] = useState(
    selectedJob ? selectedJob?.qualificationType : ""
  );
  const [errqualif, seterrqualif] = useState(false);
  const [qName, setqName] = useState(
    selectedJob ? selectedJob?.qualificationName : ""
  );
  const [errqName, seterrqName] = useState(false);
  const [qStatus, setqStatus] = useState(
    selectedJob ? selectedJob?.qualificationStatus : ""
  );
  const [errqStatus, seterrqStatus] = useState(false);
  const [qSdate, setqSdate] = useState(
    selectedJob ? moment(selectedJob?.courseStartDate).toDate() : ""
  );
  const [errqSdate, seterrqSdate] = useState(false);
  const [qEdate, setqEdate] = useState(
    selectedJob ? moment(selectedJob?.courseEndDate).toDate() : ""
  );
  const [errqEdate, seterrqEdate] = useState(false);
  const [uni, setuni] = useState(selectedJob ? selectedJob?.university : "");
  const [erruni, seterruni] = useState(false);

  const [achievement, setachievement] = useState(
    selectedJob ? selectedJob?.achievement : ""
  );
  const [achYear, setachYear] = useState(
    selectedJob ? moment(selectedJob?.achievedYear).toDate() : ""
  );
  const [aDesc, setaDesc] = useState(
    selectedJob ? selectedJob?.description : ""
  );

  const [top5, settop5] = useState(
    selectedJob?.fields ? JSON.parse(selectedJob?.fields) : ""
  );

  // const [images, setImages] = useState([]);

  // useEffect(() => {
  //     if (!selectedJob)
  //         settitle(lan)
  // }, [lan, selectedJob])

  useEffect(() => {
    // console.log("<<<<hello>>>>", selectedJob);
  }, [selectedJob]);

  React.useEffect(() => {
    let newSubArray = JSON.parse(JSON.stringify(SubData));

    newSubArray = newSubArray?.map((d) => {
      if (d.type === "Delivery Area") {
        return {
          ...d,
          locality: upperFirst(lowerCase(d.locality))
        };
      }
    });

    newSubArray = _.compact(newSubArray);

    setnewSubArray(_.orderBy(newSubArray, ["locality"], ["asc"]));

    // console.log(
    //   ">>>>>>>subs",
    //   newSubArray.filter((u) => u.state === 0)
    // );
  }, []);

  const packageSelect = (item) => {
    // console.log("pkg>>>>", item);

    let obj = {};

    if (item === "1") {
      obj = {
        email: currUser?.email,
        amountPayable: "FREE",
        packageDetails: [
          {
            packagePrice: "FREE",
            qty: 1,
            packageName: "FREE" + " " + "Subscription",
            days: 7
          }
        ],
        packageId: 1,
        userId: currUser?.id
      };
    } else if (item === "2") {
      obj = {
        email: currUser?.email,
        amountPayable: "15",
        packageDetails: [
          {
            packagePrice: "15",
            qty: 1,
            packageName: "Basic" + " " + "Subscription",
            days: 28
          }
        ],
        packageId: 2,
        userId: currUser?.id
      };
    } else if (item === "3") {
      obj = {
        email: currUser?.email,
        amountPayable: "30",
        packageDetails: [
          {
            packagePrice: "30",
            qty: 1,
            packageName: "Plus" + " " + "Subscription",
            days: 84
          }
        ],
        packageId: 2,
        userId: currUser?.id
      };
    }

    // let obj = {
    //     email: currUser?.email,
    //     amountPayable: item.pricing.split("$")[1],
    //     packageDetails: [
    //         {
    //             packagePrice: item.pricing.split("$")[1],
    //             qty: 1,
    //             packageName: item.name + " " + "Subscription",
    //         }
    //     ],
    //     packageId: item.id,
    //     userId: currUser?.id,
    // }

    // console.log("obj>>>", obj);

    setlOpen(true);

    UpdatePackage(obj, currUser?.id)
      .then((res) => {
        // console.log("res>>>>", res.data);

        if (res.data !== undefined) {
          if (res.data === "success") {
            history.push("/profile");
          } else {
            window.location.href = res.data;
          }
        }
      })
      .catch((error) => {
        setlOpen(false);
        console.log("error", error);
        console.log("current user error", error.response.data.message);
        if (error.response.data.statusCode === 401) {
          history.push("/login");
        }
      });
  };

  // React.useEffect(() => {

  //     if (packageId) {
  //         packageSelect(packageId);
  //     }

  // }, [packageId]);

  React.useEffect(() => {
    listDistricts();
    getCurrentUser();
    // listProfiles();
  }, []);

  React.useEffect(() => {
    listProfiles();
  }, [selectedJob]);

  React.useEffect(() => {
    if (homeDistrictId !== 0) {
      listHomecities();
    }
  }, [homeDistrictId]);

  useEffect(() => {
    getUserIp()
      .then((res) => {
        // console.log(">>>>>>ip", res);

        getUserLocation(res?.ip)
          .then((res) => {
            // console.log(">>>>>>ip location", res);

            if (res?.data.country_name === "Sri Lanka") {
              setipCountry("Sri Lanka");
            } else {
              setipCountry(res?.data.country_name);
            }
          })
          .catch((error) => {
            // setprofiles([]);
            console.log("Error", error.response);
          });
      })
      .catch((error) => {
        // setprofiles([]);
        console.log("Error", error.response);
      });
  }, []);

  const listProfiles = () => {
    getProfiles({
      jobType: JSON.stringify([])
      // title: search,
      // jobType:JSON.stringify([])
    })
      .then((res) => {
        // console.log(">>>>>>>jobs", res.data);

        const subArr = res.data.result?.map((d, key) => {
          return {
            ...d,
            key: key
            // id: d.state_code
          };
        });

        setprofiles(subArr);

        if (selectedJob) {
          setselectedProfile(
            res.data.result.find((v) => v.id === selectedProfile?.id)
          );
        }
      })
      .catch((error) => {
        // setlopen(false);
        setprofiles([]);
        console.log("Error", error.response);
        // addToast(error.response.data.message, "warning");
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const listDistricts = () => {
    getDistricts()
      .then((res) => {
        // console.log("districts>>>", res);
        setallHDistricts(res.data);
        // setallODistricts(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };
  const listHomecities = () => {
    getCities(homeDistrict)
      .then((res) => {
        // console.log("cities>>>", res.data.records);
        setallHCities(res.data.records);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setallHCities([]);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const handleChangeHdist = (e, value) => {
    if (value !== null) {
      sethomeDistrict(value.name_en);
      seterrhomeDistrict(false);
      sethomeDistrictId(value.id);
    } else {
      sethomeDistrict("");
      sethomeDistrictId(1);
    }

    // console.log("state>>", value);
    // setnewSubs(newSubArray.filter((u) => u.state === value.id))

    setnewSubs([]);

    const filtered = newSubArray.filter((u) => u.state === value.id);

    const perChunk = 700;

    const result = filtered.reduce((filtered, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!filtered[chunkIndex]) {
        filtered[chunkIndex] = []; // start a new chunk
      }

      filtered[chunkIndex].push(item);

      return filtered;
    }, []);

    // console.log("<<<<<<<", result);

    for (let i = 0; i < result.length - 1; i++) {
      let item = result[i];

      setTimeout(
        () => {
          setnewSubs((existing) => [...existing, ...item]);
        },
        i === 0 ? 0 : 2000
      );
    }
  };
  const handleChangeHcity = (e, value) => {
    if (value !== null) {
      // console.log(">><<", value.fields.scc_name)
      sethomeCity(value);
      // sethomepostCode(value.postcode);
      // sethomeCity(value.fields.scc_name);
      // sethomepostCode(value.postcode);
      seterrhomeCity(false);
      // seterrhomepostCode(false);
    } else {
      sethomeCity("");
      // sethomepostCode("");
    }

    // console.log("city>>", value);
  };

  const saveJob = async (e) => {
    e.preventDefault();

    // if (!description) {
    //     seterrdescription(true);
    // }

    if (!selectedProfile) {
      seterrjobType(true);
    }
    if (!packageId) {
      seterrpackages(true);
    }

    let obj2 = {};

    let item = packageId;

    if (item === "1") {
      obj2 = {
        email: currUser?.email,
        amountPayable: "FREE",
        packageDetails: [
          {
            packagePrice: "FREE",
            qty: 1,
            packageName: "FREE" + " " + "Subscription",
            days: 365
          }
        ],
        packageId: 1,
        userId: currUser?.id
      };
    } else if (item === "2") {
      obj2 = {
        email: currUser?.email,
        amountPayable: "50",
        packageDetails: [
          {
            packagePrice: ipCountry === "Sri Lanka" ? "1.63" : "50",
            qty: 1,
            packageName: "Basic" + " " + "Subscription",
            days: 90
          }
        ],
        packageId: 2,
        userId: currUser?.id
      };
    } else if (item === "3") {
      obj2 = {
        email: currUser?.email,
        amountPayable: "85",
        packageDetails: [
          {
            packagePrice: ipCountry === "Sri Lanka" ? "2.93" : "85",
            qty: 1,
            packageName: "Plus" + " " + "Subscription",
            days: 180
          }
        ],
        packageId: 3,
        userId: currUser?.id
      };
    }

    let orderMyNo = 100000;

    await getJobDetails()
      .then((res) => {
        orderMyNo = parseInt(res.data[res.data.length - 1]?.adId) + 1;
      })
      .catch((error) => {
        console.error("order history error", error.response);
      });

    let obj = {
      adId: !selectedJob ? orderMyNo : selectedJob?.id,
      title: selectedProfile?.name,
      userId: GetUserId(),
      memberId: selectedProfile?.id,
      description: description,
      duties: editorData,
      createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
      email: currUser?.email,
      packageUpdatedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      packageDetails: obj2,
      oldPkg: oldPkg,
      expiryDate: moment(expire).format("YYYY-MM-DD"),
      language: title === "English" ? "En" : title === "Sinhala" ? "Si" : "",
      expireVisibility: expireVisibility
        ? moment(expireVisibility).format("YYYY-MM-DD")
        : ""
    };

    obj = { ...selectedProfile, ...obj };

    // console.log("obj>>>>", obj);

    if (packageId && selectedProfile) {
      if (!selectedJob) {
        setlOpen(true);
        postAds(obj)
          .then((res) => {
            setlOpen(false);
            if (res.data.includes("stripe")) {
              window.location.href = res.data;
              return;
            }
            // setlOpen(false);

            setbOpen(true);
            setbMsg(t("adSucc"));
            setbStatus("success");

            // addToast("Job successfully added", "success");
            settitle("");
            setjobType("");
            setcountry("");
            setdescription("");
            sethomeDistrict("");
            sethomeCity("");
            setduration("");
            setrate("");
            setImages([]);
            setKeywords("");
            setexpire("");
            setcurrency("");
            setEditorData("<br/><br/><br/><br/><br/>");
            setselectedProfile("");
            // setOpenToast(true);
            // setColor(COLORS.Primary);
            // setAlertClass("success");
            // setMsg("Successfully updated");
            // getCurrentUser();
            // seteDisable(true);
            // getUser();

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            setlOpen(false);
            console.log("Error", error.response);
            setbOpen(true);
            setbMsg(error.response.data.message);
            setbStatus("warning");

            // addToast(error.response.data.message, "warning");
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          });
      }
      // else {
      //   setlOpen(true);
      //   UpdateAds(obj, selectedJob?.id)
      //     .then((res) => {
      //       setlOpen(false);
      //       if (res.data.includes("stripe")) {
      //         window.location.href = res.data;
      //         return;
      //       } else {
      //         setbOpen(true);
      //         setbMsg("Ad successfully updated ");
      //         setbStatus("success");
      //       }

      //       // addToast("Job successfully updated ", "success");
      //       settitle("");
      //       setjobType("");
      //       setcountry("");
      //       setdescription("");
      //       sethomeDistrict("");
      //       sethomeCity("");
      //       setduration("");
      //       setrate("");
      //       setImages([]);
      //       setKeywords("");
      //       setexpire("");
      //       setcurrency("");
      //       onCloseModalQuickView();
      //       // setOpenToast(true);
      //       // setColor(COLORS.Primary);
      //       // setAlertClass("success");
      //       // setMsg("Successfully updated");
      //       // getCurrentUser();
      //       // seteDisable(true);
      //       // getUser();

      //       // history.push("/");
      //       // window.location.reload();
      //     })
      //     .catch((error) => {
      //       setlOpen(false);
      //       console.log("Error", error.response);
      //       setbOpen(true);
      //       setbMsg(error.response.data.message);
      //       setbStatus("warning");

      //       // addToast(error.response.data.message, "warning");
      //       // setOpenToast(true);
      //       // setColor(COLORS.Orange);
      //       // setAlertClass("error");
      //       // setMsg(error.response.data.message);
      //     });
      // }
    } else {
      // handleScroll();
      setbOpen(true);
      setbMsg("Required fields are missing and add missing fields.");
      setbStatus("warning");
    }
  };

  const getCurrentUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          // setUser(cData);
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber
          };
          //   this.setState({
          //     firstName: cData?.firstName,
          //     lastName: cData?.lastName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });
          // console.log("<><><><>", cData);
          setcurrUser(cData);
setemail(cData?.email)
          // setpackageId(cData.packageId ? cData.packageId : "");

          // if (!selectedJob) {

          //     if (cData.packageId === "1") {

          //         let day = moment().add(7, "days")
          //         day = moment(day).format("YYYY-MM-DD")

          //         console.log(">>>>>>>", moment(day).toDate())
          //         setexpire(moment(day).toDate());

          //     } if (cData.packageId === "2") {

          //         let day = moment().add(28, "days")
          //         day = moment(day).format("YYYY-MM-DD")

          //         console.log(">>>>>>>", moment(day).toDate())
          //         setexpire(moment(day).toDate());

          //     } else if (cData.packageId === "3") {

          //         let day = moment().add(84, "days")
          //         day = moment(day).format("YYYY-MM-DD")

          //         console.log(">>>>>>>", moment(day).toDate())
          //         setexpire(moment(day).toDate());

          //     }
          // }

          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };

  useEffect(() => {
    if (bOpen === true) {
      setTimeout(() => {
        setbOpen(false);
      }, 10000);
    }
  }, [bOpen]);

  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const saveJobSeeker = async (e) => {
    e.preventDefault();

    if (!jobType) {
      seterrjobType(true);
    }

    if (!surName) {
      seterrsurName(true);
    }

    if (!otherName) {
      seterrotherName(true);
    }

    if (!gender) {
      seterrgender(true);
    }

    if (!dob) {
      seterrdob(true);
    }

    if (!homeDistrict) {
      seterrhomeDistrict(true);
    }

    if (!email) {
      seterremail(true);
    }

    if (!contact) {
      seterrcontact(true);
    }

    if (!endD) {
      seterrendD(true);
    }

    let obj = {
      userId: GetUserId(),
      jobseekeravatar: avatar ? [avatar] : [],
      type: jobType,
      surName: surName,
      firstMiddleName: otherName,
      gender: gender,
      dsd: "sdsd",
      dob: moment(dob).format("YYYY-MM-DD"),
      district: homeDistrict,
      email: email,
      mobileNumber: contact,
      landNmuber: landNo,
      cv: images,

      experiences: workExperienceItems,
      achievements: achievementItems,
      qualifications: qualificationItems
    };

    // console.log(">>>>>>", obj);

    // return;

    if (
      jobType &&
      surName &&
      otherName &&
      gender &&
      dob &&
      homeDistrict &&
      email &&
      contact
    ) {
      if (!selectedJob) {
        postJobSeeker(obj)
          .then((res) => {
            setlOpen(false);

            // setbOpen(true);
            // setbMsg(t("adSucc"));
            // setbStatus("success");

            addToast("Job Seeker successfully added", "success");
            getAllJobs();
            onCloseModalQuickView();

            // setOpenToast(true);
            // setColor(COLORS.Primary);
            // setAlertClass("success");
            // setMsg("Successfully updated");
            // getCurrentUser();
            // seteDisable(true);
            // getUser();

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            setlOpen(false);
            console.log("Error", error.response);
            setbOpen(true);
            setbMsg(error.response.data.message);
            setbStatus("warning");

            // addToast(error.response.data.message, "warning");
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          });
      }
      //  else {
      //   UpdateJobSeeker(obj, selectedJob?.id)
      //     .then((res) => {
      //       setlOpen(false);

      //       // setbOpen(true);
      //       // setbMsg(t("adSucc"));
      //       // setbStatus("success");

      //       addToast("Job Seeker successfully updated", "success");
      //       getAllJobs();
      //       onCloseModalQuickView();
      //     })
      //     .catch((error) => {
      //       setlOpen(false);
      //       console.log("Error", error.response);
      //       setbOpen(true);
      //       setbMsg(error.response.data.message);
      //       setbStatus("warning");
      //     });
      // }
    } else {
      setbOpen(true);
      setbMsg("Required fields are missing and add missing fields.");
      setbStatus("warning");
    }
  };

  const updateAchievements = async (e) => {
    e.preventDefault();

    // Validate all achievement items
    let hasErrors = false;
    const updatedAchievementItems = achievementItems.map((achievement) => {
      let errors = {};

      if (!achievement.achievement) {
        errors.achievement = "Achievement is required";
        hasErrors = true;
      }

      if (!achievement.achYear) {
        errors.achYear = "Achieved Year is required";
        hasErrors = true;
      } else if (!moment(achievement.achYear, "YYYY", true).isValid()) {
        errors.achYear = "Achieved Year must be a valid year";
        hasErrors = true;
      }

      return {
        ...achievement,
        errors
      };
    });

    // Update state with the new errors
    setAchievementItems(updatedAchievementItems);

    if (!hasErrors) {
      // Prepare the data object to send to the server
      const achievementsArray = achievementItems.map((achievement) => ({
        achievement: achievement.achievement,
        achYear: moment(achievement.achYear).format("YYYY"),
        aDesc: achievement.aDesc
      }));

      const obj = {
        achievements: achievementsArray
      };

      try {
        const response = await UpdateJobSeekeAchievements(obj, selectedJob?.id);
        setlOpen(false);
        addToast("Job Seeker's Achievements successfully updated", "success");
        getAllJobs();
        onCloseModalQuickView();
      } catch (error) {
        setlOpen(false);
        console.log("Error", error.response);
        // setbOpen(true);
        // setbMsg(error.response?.data?.message || "An error occurred while updating achievements.");
        // setbStatus("warning");
      }
    } else {
      setbOpen(true);
      setbMsg(
        "Required fields are missing. Please fill out all required fields."
      );
      setbStatus("warning");
    }
  };

  const updatePersonalDetails = async (e) => {
    e.preventDefault();
    if (!jobType) {
      seterrjobType(true);
    }

    if (!surName) {
      seterrsurName(true);
    }

    if (!otherName) {
      seterrotherName(true);
    }

    if (!gender) {
      seterrgender(true);
    }

    if (!dob) {
      seterrdob(true);
    }

    if (!homeDistrict) {
      seterrhomeDistrict(true);
    }

    if (!email) {
      seterremail(true);
    }

    if (!contact) {
      seterrcontact(true);
    }

    let obj = {
      userId: GetUserId(),
      jobseekeravatar: avatar ? [avatar] : [],
      type: jobType,
      surName: surName,
      firstMiddleName: otherName,
      gender: gender,
      dsd: "sdsd",
      dob: moment(dob).format("YYYY-MM-DD"),
      district: homeDistrict,
      email: email,
      mobileNumber: contact,
      landNmuber: landNo,
      cv: images
    };

    if (
      jobType &&
      surName &&
      otherName &&
      gender &&
      dob &&
      homeDistrict &&
      email &&
      contact
    ) {
      UpdateJobSeekerPersonalDetails(obj, selectedJob?.id)
        .then((res) => {
          setlOpen(false);

          // setbOpen(true);
          // setbMsg(t("adSucc"));
          // setbStatus("success");

          addToast("Job Seeker successfully updated", "success");
          getAllJobs();
          onCloseModalQuickView();
        })
        .catch((error) => {
          setlOpen(false);
          console.log("Error", error.response);
          setbOpen(true);
          setbMsg(error.response.data.message);
          setbStatus("warning");
        });
    } else {
      setbOpen(true);
      setbMsg("Required fields are missing and add missing fields.");
      setbStatus("warning");
    }
  };

  const updateExperiences = async (e) => {
    e.preventDefault();

    // Validate all experience items
    let hasErrors = false;
    const updatedExperienceItems = workExperienceItems.map((experience) => {
      let errors = {};

      if (!experience.role) {
        errors.role = "Role is required";
        hasErrors = true;
      }

      if (!experience.category) {
        errors.category = "Category is required";
        hasErrors = true;
      }

      if (!experience.companyName) {
        errors.companyName = "Company Name is required";
        hasErrors = true;
      }

      if (!experience.employmentStatus) {
        errors.employmentStatus = "Employment Status is required";
        hasErrors = true;
      }

      if (!experience.startDate) {
        errors.startDate = "Start Date is required";
        hasErrors = true;
      }

      if (experience.endDate && experience.startDate > experience.endDate) {
        errors.endDate = "End Date cannot be before Start Date";
        hasErrors = true;
      }

      return {
        ...experience,
        errors
      };
    });

    // Update state with new errors
    setWorkExperienceItems(updatedExperienceItems);

    if (!hasErrors) {
      // Prepare the data object to send to the server
      const experiencesArray = workExperienceItems.map((experience) => ({
        role: experience.role,
        category: experience.category,
        companyName: experience.companyName,
        employmentStatus: experience.employmentStatus,
        startDate: moment(experience.startDate).format("YYYY-MM-DD"),
        endDate: experience.endDate
          ? moment(experience.endDate).format("YYYY-MM-DD")
          : null,
        duties: experience.duties
      }));

      // Send the data to the server
      const obj = {
        experiences: experiencesArray
      };

      try {
        const response = await UpdateJobSeekerExperiences(obj, selectedJob?.id);
        setlOpen(false);
        addToast("Work Experiences successfully updated", "success");
        getAllJobs();
        onCloseModalQuickView();
      } catch (error) {
        setlOpen(false);
        console.log("Error", error.response);
        setbOpen(true);
        setbMsg(
          error.response?.data?.message ||
            "An error occurred while updating experiences."
        );
        setbStatus("warning");
      }
    } else {
      setbOpen(true);
      setbMsg(
        "Required fields are missing. Please fill out all required fields."
      );
      setbStatus("warning");
    }
  };

  const updateQualifications = async (e) => {
    e.preventDefault();

    // Validate all qualification items
    let hasErrors = false;
    const updatedQualificationItems = qualificationItems.map(
      (qualification) => {
        let errors = {};

        if (!qualification.fieldOfStudy) {
          errors.fieldOfStudy = "Field of Study is required";
          hasErrors = true;
        }

        if (!qualification.qualificationType) {
          errors.qualificationType = "Qualification Type is required";
          hasErrors = true;
        }

        if (!qualification.qualificationName) {
          errors.qualificationName = "Qualification Name is required";
          hasErrors = true;
        }

        if (!qualification.qualificationStatus) {
          errors.qualificationStatus = "Qualification Status is required";
          hasErrors = true;
        }

        if (!qualification.startDate) {
          errors.startDate = "Start Date is required";
          hasErrors = true;
        }

        if (
          qualification.endDate &&
          qualification.startDate > qualification.endDate
        ) {
          errors.endDate = "End Date cannot be before Start Date";
          hasErrors = true;
        }

        if (!qualification.institution) {
          errors.institution = "Institution/University is required";
          hasErrors = true;
        }

        return {
          ...qualification,
          errors
        };
      }
    );

    // Update state with new errors
    setQualificationItems(updatedQualificationItems);

    if (!hasErrors) {
      // Prepare the data object to send to the server
      const qualificationsArray = qualificationItems.map((qualification) => ({
        fieldOfStudy: qualification.fieldOfStudy,
        qualificationType: qualification.qualificationType,
        qualificationName: qualification.qualificationName,
        qualificationStatus: qualification.qualificationStatus,
        startDate: moment(qualification.startDate).format("YYYY-MM-DD"),
        endDate: qualification.endDate
          ? moment(qualification.endDate).format("YYYY-MM-DD")
          : null,
        institution: qualification.institution
      }));

      // Send the data to the server
      const obj = {
        userId: GetUserId(),
        qualifications: qualificationsArray
      };

      try {
        const response = await UpdateJobSeekerQualifications(
          obj,
          selectedJob?.id
        );
        setlOpen(false);
        addToast("Qualifications successfully updated", "success");
        getAllJobs();
        onCloseModalQuickView();
      } catch (error) {
        setlOpen(false);
        console.log("Error", error.response);
        setbOpen(true);
        setbMsg(
          error.response?.data?.message ||
            "An error occurred while updating qualifications."
        );
        setbStatus("warning");
      }
    } else {
      setbOpen(true);
      setbMsg(
        "Required fields are missing. Please fill out all required fields."
      );
      setbStatus("warning");
    }
  };

  const [workExperienceItems, setWorkExperienceItems] = useState([
    {
      id: 0, // Default ID for the first card
      role: "",
      category: "",
      companyName: "",
      employmentStatus: "",
      startDate: "",
      endDate: "",
      duties: "",
      errors: {}
    }
  ]);

  React.useEffect(() => {
    const getExperiences = () => {
      JobSeekerExperience(selectedJob?.id)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            // If there is data from the API, use it to set the state
            setWorkExperienceItems(
              res.data.map((item, index) => ({
                ...item,
                id: index, // Ensure each item has an ID
                errors: {} // Initialize the errors object
              }))
            );
          } else {
            // If no data is returned, keep the default initial card
            setWorkExperienceItems([
              {
                id: 0,
                role: "",
                category: "",
                companyName: "",
                employmentStatus: "",
                startDate: "",
                endDate: "",
                duties: "",
                errors: {}
              }
            ]);
          }
        })
        .catch((err) => {
          console.log("Error fetching experience", err);
        });
    };

    getExperiences();
  }, [selectedJob]);

  // Function to validate an individual experience item
  const validateExperience = (experience) => {
    let newErrors = {};
    if (!experience.role) newErrors.role = "Role is required";
    if (!experience.companyName)
      newErrors.companyName = "Company name is required";
    if (!experience.employmentStatus)
      newErrors.employmentStatus = "Employee Status is required";
    if (!experience.startDate) newErrors.startDate = "Start date is required";
    if (experience.endDate && experience.startDate > experience.endDate) {
      newErrors.endDate = "End date cannot be before start date";
    }
    return newErrors;
  };

  // Function to validate all items
  const validateAllWorkExperienceItems = () => {
    const updatedItems = workExperienceItems.map((item) => {
      const errors = validateExperience(item);
      return { ...item, errors };
    });

    setWorkExperienceItems(updatedItems);

    // Check if there are any errors in any of the items
    return updatedItems.every((item) => Object.keys(item.errors).length === 0);
  };

  // Function to add new work experience after validating existing ones
  const addNewWorkExperience = () => {
    if (validateAllWorkExperienceItems()) {
      // If all items are valid, add a new empty card
      setWorkExperienceItems([
        ...workExperienceItems,
        {
          id: workExperienceItems.length, // Assign a unique ID
          role: "",
          category: "",
          companyName: "",
          employmentStatus: "",
          startDate: "",
          endDate: "",
          duties: "",
          errors: {}
        }
      ]);
    } else {
      console.log(
        "Validation failed. Please fix the errors before adding a new experience."
      );
    }
  };

  const updateWorkExperienceItem = (id, updatedExperience) => {
    const updatedItems = workExperienceItems.map((item) =>
      item.id === id ? updatedExperience : item
    );
    setWorkExperienceItems(updatedItems);
  };

  const removeWorkExperienceItem = (id) => {
    // Prevent removing the item if only one card is left
    if (workExperienceItems.length === 1) {
      console.log("At least one work experience item must remain.");
      return;
    }

    const updatedItems = workExperienceItems.filter((item) => item.id !== id);
    setWorkExperienceItems(updatedItems);
  };

  const [qualificationItems, setQualificationItems] = useState([
    {
      id: 0,
      fieldOfStudy: "",
      qualificationType: "",
      qualificationName: "",
      qualificationStatus: "",
      startDate: "",
      endDate: "",
      institution: "",
      errors: {}
    }
  ]);

  React.useEffect(() => {
    const getQualifications = () => {
      JobSeekerQualification(selectedJob?.id)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            // If there is data from the API, use it to set the state
            setQualificationItems(
              res.data.map((item, index) => ({
                ...item,
                id: index, // Ensure each item has an ID
                errors: {} // Initialize the errors object
              }))
            );
          } else {
            // If no data is returned, keep the default initial card
            setQualificationItems([
              {
                id: 0,
                fieldOfStudy: "",
                qualificationType: "",
                qualificationName: "",
                qualificationStatus: "",
                startDate: "",
                endDate: "",
                institution: "",
                errors: {}
              }
            ]);
          }
        })
        .catch((err) => {
          console.log("Error fetching qualifications", err);
        });
    };

    getQualifications();
  }, [selectedJob]);

  const validateQualification = (qualification) => {
    let newErrors = {};
    if (!qualification.fieldOfStudy)
      newErrors.fieldOfStudy = "Field of Study is required";
    if (!qualification.qualificationType)
      newErrors.qualificationType = "Qualification Type is required";
    if (!qualification.qualificationName)
      newErrors.qualificationName = "Qualification Name is required";
    if (!qualification.qualificationStatus)
      newErrors.qualificationStatus = "Qualification Status is required";
    if (!qualification.startDate)
      newErrors.startDate = "Start date is required";
    if (
      qualification.endDate &&
      qualification.startDate > qualification.endDate
    ) {
      newErrors.endDate = "End date cannot be before start date";
    }
    return newErrors;
  };

  const validateAllQualificationItems = () => {
    const updatedItems = qualificationItems.map((item) => {
      const errors = validateQualification(item);
      return { ...item, errors };
    });

    setQualificationItems(updatedItems);

    // Check if there are any errors
    return updatedItems.every((item) => Object.keys(item.errors).length === 0);
  };

  const addNewQualification = () => {
    if (validateAllQualificationItems()) {
      setQualificationItems([
        ...qualificationItems,
        {
          id: qualificationItems.length,
          fieldOfStudy: "",
          qualificationType: "",
          qualificationName: "",
          qualificationStatus: "",
          startDate: "",
          endDate: "",
          institution: "",
          errors: {}
        }
      ]);
    } else {
      console.log(
        "Validation failed. Please fix the errors before adding a new qualification."
      );
    }
  };

  const updateQualificationItem = (id, updatedQualification) => {
    const updatedItems = qualificationItems.map((item) =>
      item.id === id ? updatedQualification : item
    );
    setQualificationItems(updatedItems);
  };

  const removeQualificationItem = (id) => {
    if (qualificationItems.length === 1) {
      console.log("At least one qualification item must remain.");
      return;
    }

    const updatedItems = qualificationItems.filter((item) => item.id !== id);
    setQualificationItems(updatedItems);
  };

  const [achievementItems, setAchievementItems] = useState([
    {
      id: 0,
      achievement: "",
      achYear: "",
      aDesc: "",
      errors: {}
    }
  ]);

  React.useEffect(() => {
    const getAchievements = () => {
      JobSeekerAchievements(selectedJob?.id)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            setAchievementItems(
              res.data.map((item, index) => ({
                ...item,
                id: index,
                errors: {}
              }))
            );
          } else {
            setAchievementItems([
              {
                id: 0,
                achievement: "",
                achYear: "",
                aDesc: "",
                errors: {}
              }
            ]);
          }
        })
        .catch((err) => {
          console.log("Error fetching achievements", err);
        });
    };

    getAchievements();
  }, [selectedJob]);

  const validateAchievement = (achievement) => {
    let newErrors = {};
    if (!achievement.achievement)
      newErrors.achievement = "Achievement is required";
    if (!achievement.achYear) newErrors.achYear = "Achieved Year is required";
    return newErrors;
  };

  const validateAllAchievementItems = () => {
    const updatedItems = achievementItems.map((item) => {
      const errors = validateAchievement(item);
      return { ...item, errors };
    });

    setAchievementItems(updatedItems);
    return updatedItems.every((item) => Object.keys(item.errors).length === 0);
  };

  const addNewAchievement = () => {
    if (validateAllAchievementItems()) {
      setAchievementItems([
        ...achievementItems,
        {
          id: achievementItems.length,
          achievement: "",
          achYear: "",
          aDesc: "",
          errors: {}
        }
      ]);
    } else {
      console.log(
        "Validation failed. Please fix the errors before adding a new achievement."
      );
    }
  };

  const updateAchievementItem = (id, updatedAchievement) => {
    const updatedItems = achievementItems.map((item) =>
      item.id === id ? updatedAchievement : item
    );
    setAchievementItems(updatedItems);
  };

  const removeAchievementItem = (id) => {
    if (achievementItems.length === 1) {
      console.log("At least one achievement item must remain.");
      return;
    }
    const updatedItems = achievementItems.filter((item) => item.id !== id);
    setAchievementItems(updatedItems);
  };

  return (
    <>
      <LoadingSpinner open={lOpen} />
      <div className="justify-center w-full mt-2">
        {!selectedJob && (
          <div className="flex justify-center">
            {/* <span className="text-xs font-normal flex justify-center w-full md:w-9/12 lg:w-[45%] text-center mt-1 mb-6">

                            Are you looking to hire talent for your company? You can post your
                            job vacancies through this page.

                        </span> */}
          </div>
        )}
      </div>
      <div className="w-full flex justify-center mb-20">
        <div className="w-full md:w-11/12 lg:w-12/12">
          {/* <span className="block text-xl font-bold flex justify-start w-full text-left text-[#003D35]">

                        {selectedJob ? "Edit Ad" : t("adButton")}
                    </span> */}

          <div className="col-span-12">
            {/* BEGIN: Display Information */}
            <div className="py-6 px-9">
              <div className="flex justify-between">
                <div>
                  <h2 className="text-lg text-blueF font-bold">
                    Complete Profile
                  </h2>

                  <h5 className="text-xs text-primaryF font-normal mt-2">
                    The following details will appear on the profile.{" "}
                  </h5>
                </div>

                <div>
                  <div className="relative rounded-full overflow-hidden flex">
                    <div className="w-20 h-20 rounded-full object-cover z-[2] border-2 border-white">
                      <img
                        // src={cover}
                        src={
                          avatar?.path?.includes("/uploads/") === true
                            ? IMAGE_SERVER_URL_DEV + avatar?.path
                            : avatar?.path
                        }
                        alt=""
                      />

                      <div
                        className={`absolute z-[3]  inset-0   ${
                          avatar && true
                            ? "hover:bg-black hover:bg-opacity-30"
                            : avatar
                            ? ""
                            : "bg-gray-400"
                        }  flex flex-col items-center justify-center text-transparent ${
                          avatar && true
                            ? "hover:text-neutral-50"
                            : avatar
                            ? "text-transparent"
                            : "text-neutral-50"
                        } cursor-pointer`}
                      >
                        <svg
                          className={`${avatar ? "" : "text-neutral-50"}`}
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                            stroke="currentColor"
                            strokeWidth={1.5}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        <span
                          className={`mt-1 text-[10px] ${
                            avatar ? "" : "text-neutral-50"
                          }`}
                        >
                          {avatar ? "Change Image" : "Upload Image"}
                        </span>
                        {/* <span className={`text-[8px] ${avatar ? "" : "text-neutral-50"}`}>{avatar ? "(500 x 500)px" : "(500 x 500)px"}</span> */}

                        {true && (
                          <Input
                            type="file"
                            className="absolute inset-0 opacity-0 cursor-pointer"
                            // onChange={onSelectFile2}
                            onChange={async (e) => {
                              let file = e.target.files[0];

                              var extension = file.type;

                              extension = extension.split("/")[1];

                              console.log(">>>", extension.split("/")[1]);

                              let reader = new FileReader();
                              reader.readAsDataURL(file);
                              let fileInp = e.target.files[0];
                              let baseURL;
                              // on reader load somthing...
                              reader.onload = () => {
                                // Make a fileInfo Object
                                baseURL = reader.result;
                                let obj = {
                                  // image: baseURL,
                                  path: baseURL,
                                  thumbnail: false,
                                  name: file.name,
                                  ext: upperCase(extension)
                                };
                                setavatar(obj);
                              };
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-2 gap-y-2 mt-8 cursor-pointer">
                <div
                  className={`text-white ${
                    proTab === 1 ? "bg-[#D6073E]" : "bg-blueF"
                  } text-xs text-center px-2 py-3 rounded-md`}
                  onClick={() => {
                    setproTab(1);
                  }}
                >
                  Personal Details
                </div>

                <div
                  className={`text-white ${
                    proTab === 2 ? "bg-[#D6073E]" : "bg-blueF"
                  } text-xs text-center px-2 py-3 rounded-md`}
                  onClick={() => {
                    setproTab(2);
                  }}
                >
                  Work Experience
                </div>

                <div
                  className={`text-white ${
                    proTab === 3 ? "bg-[#D6073E]" : "bg-blueF"
                  } text-xs text-center px-2 py-3 rounded-md`}
                  onClick={() => {
                    setproTab(3);
                  }}
                >
                  Qualifications
                </div>

                <div
                  className={`text-white ${
                    proTab === 4 ? "bg-[#D6073E]" : "bg-blueF"
                  } text-xs text-center px-2 py-3 rounded-md`}
                  onClick={() => {
                    setproTab(4);
                  }}
                >
                  Career Achievements
                </div>
              </div>

              {proTab === 1 && (
                <>
                  <div className="grid grid-cols-3 md:grid-cols-3 gap-x-4 gap-y-7 mt-8 cursor-pointer text-[#86949F] text-sm">
                    <div className="col-span-3 md:col-auto">
                      <label
                        htmlFor="update-profile-form-3"
                        className="form-label"
                      >
                        Top Skills
                      </label>
                      <div>
                        {/* <span className="flex mt-4 text-sm">Select Size</span> */}
                        <Autocomplete
                          // multiple
                          size="small"
                          className="mt-1.5"
                          // className="form-group"
                          // className="border-neutral-200"
                          // value={selectedSizes}
                          value={""}
                          options={[
                            "field1",
                            "field2",
                            "field3",
                            "field4",
                            "field5"
                          ]}
                          getOptionLabel={(option) => option}
                          onChange={(event, newValue) => {
                            console.log("select>>>>", newValue);

                            // setselectedSizes(newValue)

                            // if (newValue !== null) {
                            //   setselectedSize(newValue)
                            //   // seterrcat(false);
                            // } else {
                            //   setselectedSize({ measurement: "None", unit: "" });
                            //   // seterrcat(false);
                            // }

                            if (newValue !== null) {
                              // setselectedSizes(newValue)

                              if (top5?.includes(newValue)) {
                                const index = top5.indexOf(newValue);

                                console.log(">>>>>>index", index);

                                var arr = [...top5];
                                if (index > -1) {
                                  arr.splice(index, 1);
                                  console.log("arr>>>>", arr);
                                  settop5(arr);
                                }
                              } else {
                                settop5((data) => [...data, newValue]);
                              }

                              // seterrcat(false);
                            } else {
                              // setselectedSub([]);
                              // seterrcat(false);
                            }
                          }}
                          renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                              <Input
                                {...params.inputProps}
                                className={classnames({
                                  "form-control": true,
                                  // "border-red-600": errjobType,
                                  "bg-[#F4F4F4]": true,
                                  "border-none": true,
                                  "mt-1.5": true
                                })}
                                placeholder="Select Skills"
                              />
                            </div>
                          )}
                          // renderInput={(params) => (
                          //     <TextField
                          //         {...params}

                          //     />

                          // )}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                              fontSize: "14px"
                              // border: '1px solid white'
                            }
                          }}
                        />
                        {false && (
                          <div className="text-red-600 text-sm text-right mt-2">
                            This field is required
                          </div>
                        )}
                      </div>
                      <div className="flex flex-wrap mt-0 mb-0">
                        {top5 &&
                          top5?.length > 0 &&
                          top5?.map((item, index) => {
                            // let data = allSubs.find(({ subId }) => subId === parseInt(item))
                            return (
                              <>
                                <div className="bg-[#e2e8f0] w-fit p-2 flex mr-1 mt-2">
                                  <span className="text-xs">{item}</span>

                                  <div
                                    className="flex items-center ml-3 cursor-pointer"
                                    onClick={(e) => {
                                      var array = [...top5];
                                      array.splice(index, 1);
                                      settop5([...array]);
                                    }}
                                  >
                                    <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>

                    <div className="col-span-3 md:col-auto">
                      <div className="">
                        <label
                          htmlFor="update-profile-form-3"
                          className="form-label"
                        >
                          Job Seeker Type *
                        </label>

                        <div>
                          <Autocomplete
                            className="mt-0"
                            // className="form-group"
                            // className="border-neutral-200"
                            disablePortal
                            // defaultValue={{ value: category }}
                            // value={{ value: category, name: CATEG.find((v) => v.value === category) ? CATEG.find((v) => v.value === category).name : "All" }}
                            value={jobType}
                            // id="combo-box-demo"
                            options={["Part Time", "Permenent"]}
                            getOptionLabel={(option) => option}
                            onChange={(e, value) => {
                              setjobType(value);
                              seterrjobType(false);

                              // setCountry(value);
                            }}
                            // sx={{
                            //   // width: "80vw",
                            //   background:"transparent",
                            //   borderRadius: "20px",
                            //   // border: "none",
                            //   // borderColor: "transparent",

                            //   "& .MuiInputBase-input": {
                            //     height: 15,
                            //     // border: "none",
                            //     fontSize: "14px",
                            //     fontFamily: "'Jost', sans-serif",
                            //     // borderColor: "transparent",
                            //   },
                            //   //view
                            //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            //     // borderColor: "transparent",
                            //   },

                            //   "&:hover .MuiOutlinedInput-input": {
                            //     color: "black",
                            //   },
                            //   "&:hover .MuiInputLabel-root": {
                            //     color: COLORS.GrayBackground,
                            //   },
                            //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            //   {
                            //     // borderColor: "transparent",
                            //   },
                            //   ".MuiAutocomplete-root fieldset:hover": {
                            //     color: "transparent",
                            //     // borderColor: "transparent",
                            //   },
                            // }}
                            renderInput={(params) => (
                              <div ref={params.InputProps.ref}>
                                <Input
                                  {...params.inputProps}
                                  className={classnames({
                                    "form-control": true,
                                    "border-red-600": errjobType,
                                    "bg-[#F4F4F4]": true,
                                    "border-none": true,
                                    "mt-1.5": true
                                  })}
                                  placeholder="Select Job Seeker Type"
                                />
                              </div>
                            )}
                            ListboxProps={{
                              style: {
                                maxHeight: "150px",
                                fontSize: "14px"
                                // border: '1px solid white'
                              }
                            }}
                          />

                          {errjobType && (
                            <div className="text-red-600 mt-2 text-right text-xs">
                              This Field is Required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-span-3 md:col-auto">
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Surname *
                      </label>
                      <Input
                        id="update-profile-form-1"
                        type="text"
                        className={classnames({
                          "form-control": true,
                          "border-red-600": errsurName,
                          "bg-[#F4F4F4]": true,
                          "border-none": true,
                          "mt-1.5": true
                        })}
                        placeholder="Surname"
                        value={surName}
                        onChange={(e) => {
                          setsurName(e.target.value);
                          seterrsurName(false);
                        }}
                        // disabled
                      />
                      {errsurName && (
                        <div className="text-red-600 mt-2 text-right text-xs">
                          This Field is Required
                        </div>
                      )}
                    </div>

                    <div className="col-span-3 md:col-auto">
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        First Middle Name *
                      </label>
                      <Input
                        id="update-profile-form-1"
                        type="text"
                        className={classnames({
                          "form-control": true,
                          "border-red-600": errotherName,
                          "bg-[#F4F4F4]": true,
                          "border-none": true,
                          "mt-1.5": true
                        })}
                        placeholder="First Middle Name"
                        value={otherName}
                        onChange={(e) => {
                          setotherName(e.target.value);
                          seterrotherName(false);
                        }}
                        // disabled
                      />
                      {errotherName && (
                        <div className="text-red-600 mt-2 text-right text-xs">
                          This Field is Required
                        </div>
                      )}
                    </div>

                    <div className="col-span-3 md:col-auto">
                      <div className="">
                        <label
                          htmlFor="update-profile-form-3"
                          className="form-label"
                        >
                          Gender *
                        </label>

                        <div>
                          <Autocomplete
                            className="mt-0"
                            // className="form-group"
                            // className="border-neutral-200"
                            disablePortal
                            // defaultValue={{ value: category }}
                            // value={{ value: category, name: CATEG.find((v) => v.value === category) ? CATEG.find((v) => v.value === category).name : "All" }}
                            value={gender}
                            // id="combo-box-demo"
                            options={["Male", "Female", "Other"]}
                            getOptionLabel={(option) => option}
                            onChange={(e, value) => {
                              setgender(value);
                              seterrgender(false);

                              // setCountry(value);
                            }}
                            // sx={{
                            //   // width: "80vw",
                            //   background:"transparent",
                            //   borderRadius: "20px",
                            //   // border: "none",
                            //   // borderColor: "transparent",

                            //   "& .MuiInputBase-input": {
                            //     height: 15,
                            //     // border: "none",
                            //     fontSize: "14px",
                            //     fontFamily: "'Jost', sans-serif",
                            //     // borderColor: "transparent",
                            //   },
                            //   //view
                            //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            //     // borderColor: "transparent",
                            //   },

                            //   "&:hover .MuiOutlinedInput-input": {
                            //     color: "black",
                            //   },
                            //   "&:hover .MuiInputLabel-root": {
                            //     color: COLORS.GrayBackground,
                            //   },
                            //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            //   {
                            //     // borderColor: "transparent",
                            //   },
                            //   ".MuiAutocomplete-root fieldset:hover": {
                            //     color: "transparent",
                            //     // borderColor: "transparent",
                            //   },
                            // }}
                            renderInput={(params) => (
                              <div ref={params.InputProps.ref}>
                                <Input
                                  {...params.inputProps}
                                  className={classnames({
                                    "form-control": true,
                                    "border-red-600": errgender,
                                    "bg-[#F4F4F4]": true,
                                    "border-none": true,
                                    "mt-1.5": true
                                  })}
                                  placeholder="Select Gender"
                                />
                              </div>
                            )}
                            ListboxProps={{
                              style: {
                                maxHeight: "150px",
                                fontSize: "14px"
                                // border: '1px solid white'
                              }
                            }}
                          />

                          {errgender && (
                            <div className="text-red-600 mt-2 text-right text-xs">
                              This Field is Required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-span-3 md:col-auto">
                      <label
                        htmlFor="update-profile-form-3"
                        className="form-label"
                      >
                        Date of Birth *
                      </label>
                      <div>
                        <DatePicker
                          // range
                          // plugins={[
                          //     <DatePanel />
                          // ]}
                          format="DD/MM/YYYY"
                          containerStyle={{
                            width: "100%"
                          }}
                          render={<CustomInput />}
                          value={dob}
                          onChange={(e, val) => {
                            console.log(">>>>>>", e);
                            console.log(">>>>>>", e.toDate());
                            setdob(e.toDate());
                            seterrdob(false);
                            // setdateRange([e[0]?.toDate(), e[1]?.toDate()])
                            // seterrdateRange(false);
                            // setexpire(e.toDate());
                            // seterrexpire(false);
                          }}
                          // value={dateRange}
                          // value={year}
                          // onChange={setyear}
                        />
                        {/* {errdateRange && (
                                                    <div className="text-red-600 text-sm text-right mt-2">
                                                        This field is required
                                                    </div>
                                                )} */}
                      </div>
                      {/* <div className="relative">
                                                <div className="absolute rounded-l w-10 h-full flex items-center justify-center bg-[#F4F4F4] text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400">
                                                    <Lucide icon="Calendar" className="w-4 h-4" />
                                                </div>
                                                <Litepicker
                                                    // value={datefrom === 0 ? "" : datefrom}
                                                    // onChange={setdatefrom}
                                                    placeholder="Select from date"
                                                    options={{
                                                        autoApply: true,
                                                        showWeekNumbers: true,
                                                        dropdowns: {
                                                            minYear: 1990,
                                                            maxYear: null,
                                                            months: true,
                                                            years: true,
                                                        },
                                                    }} className="form-control pl-12 bg-[#F4F4F4] border-none" />
                                            </div> */}
                      {errdob && (
                        <div className="text-red-600 mt-2 text-right text-xs">
                          This Field is Required
                        </div>
                      )}
                    </div>

                    <div className="col-span-3 md:col-auto">
                      <div className="">
                        <label
                          htmlFor="update-profile-form-3"
                          className="form-label"
                        >
                          District *
                        </label>

                        <div>
                          <Autocomplete
                            className="mt-0"
                            // className="form-group"
                            // className="border-neutral-200"
                            disablePortal
                            defaultValue={{ name_en: homeDistrict }}
                            value={{ name_en: homeDistrict }}
                            // id="combo-box-demo"
                            options={allHDistricts}
                            getOptionLabel={(option) => option.name_en}
                            onChange={(event, value) => {
                              handleChangeHdist(event, value);
                              // setCountry(value);
                            }}
                            // sx={{
                            //   // width: "80vw",
                            //   background:"transparent",
                            //   borderRadius: "20px",
                            //   // border: "none",
                            //   // borderColor: "transparent",

                            //   "& .MuiInputBase-input": {
                            //     height: 15,
                            //     // border: "none",
                            //     fontSize: "14px",
                            //     fontFamily: "'Jost', sans-serif",
                            //     // borderColor: "transparent",
                            //   },
                            //   //view
                            //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            //     // borderColor: "transparent",
                            //   },

                            //   "&:hover .MuiOutlinedInput-input": {
                            //     color: "black",
                            //   },
                            //   "&:hover .MuiInputLabel-root": {
                            //     color: COLORS.GrayBackground,
                            //   },
                            //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            //   {
                            //     // borderColor: "transparent",
                            //   },
                            //   ".MuiAutocomplete-root fieldset:hover": {
                            //     color: "transparent",
                            //     // borderColor: "transparent",
                            //   },
                            // }}
                            renderInput={(params) => (
                              <div ref={params.InputProps.ref}>
                                <Input
                                  {...params.inputProps}
                                  className={classnames({
                                    "form-control": true,
                                    "border-red-600": errhomeDistrict,
                                    "bg-[#F4F4F4]": true,
                                    "border-none": true,
                                    "mt-1.5": true
                                  })}
                                  placeholder="Select District"
                                />
                              </div>
                            )}
                            ListboxProps={{
                              style: {
                                maxHeight: "150px",
                                fontSize: "14px"
                                // border: '1px solid white'
                              }
                            }}
                          />

                          {errhomeDistrict && (
                            <div className="text-red-600 text-sm text-right mt-2">
                              This field is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-span-3 md:col-auto">
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Email Address *
                      </label>
                      <Input
                        id="update-profile-form-1"
                        type="text"
                        className={classnames({
                          "form-control": true,
                          "border-red-600": erremail,
                          "bg-[#F4F4F4]": true,
                          "border-none": true,
                          "mt-1.5": true
                        })}
                        placeholder="Email"
                        value={email}
                        onChange={(e) => {
                          setemail(e.target.value);
                          seterremail(false);
                        }}
                        // disabled
                      />
                      {erremail && (
                        <div className="text-red-600 mt-2 text-right text-xs">
                          This Field is Required
                        </div>
                      )}
                    </div>

                    <div className="col-span-3 md:col-auto">
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Mobile Number *
                      </label>
                      <Input
                        id="update-profile-form-1"
                        type="text"
                        className={classnames({
                          "form-control": true,
                          "border-red-600": errcontact,
                          "bg-[#F4F4F4]": true,
                          "border-none": true,
                          "mt-1.5": true
                        })}
                        placeholder="Mobile"
                        value={contact}
                        onChange={(e) => {
                          setcontact(e.target.value);
                          seterrcontact(false);
                        }}
                        // disabled
                      />
                      {errcontact && (
                        <div className="text-red-600 mt-2 text-right text-xs">
                          This Field is Required
                        </div>
                      )}
                    </div>

                    <div className="col-span-3 md:col-auto">
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Land Number
                      </label>
                      <Input
                        id="update-profile-form-1"
                        type="text"
                        className={classnames({
                          "form-control": true,
                          "border-red-600": errlandNo,
                          "bg-[#F4F4F4]": true,
                          "border-none": true,
                          "mt-1.5": true
                        })}
                        placeholder="Land Number"
                        value={landNo}
                        onChange={(e) => {
                          setlandNo(e.target.value);
                          seterrlandNo(false);
                        }}
                        // disabled
                      />
                      {errlandNo && (
                        <div className="text-red-600 mt-2 text-right text-xs">
                          This Field is Required
                        </div>
                      )}
                    </div>

                    <div className="col-span-3">
                      <div className="w-full mt-3 xl:mt-6 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                        <div className="grid grid-cols-10 gap-5 pl-4 pr-5">
                          {images?.map((faker, fakerKey) => (
                            <>
                              <div
                                // key={fakerKey}
                                className="col-span-6 md:col-span-2 h-28 relative image-fit cursor-pointer zoom-in"
                              >
                                <div className="flex flex-col ">
                                  <img
                                    className="cursor-pointer w-[60px]"
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nO3Xv0skMRQH8GnGH6yoWCiChbJgZaGNtYWNnf+CFqJYWIpiJ8gVgjaiYiX+BRaCrY0iy3EWarGIhYiLhQiLyWzmzeR7JKO76KLHqclucB6EYbr3Sd6bl/G8NNIwG8z3YWj9chfQ12cPwQwAwj+/wbJZOwgTALov2EMwQwBrCGYQQDYQpgFkGmEDQCYRtgBkCmETQCYQtgH0BlGXgP9ZXgrw0xOAcyVUGhuDzOchi0WECwvuAeTtLcoRx+C9ve4AeFdXJfejI/0sjY+7A2CZDBBFCeDiwkGA7yM+OyuXj4pgeNgtQLi4WOkBIrC2NrcAvLsbCILkEHK5TyfPajmJo4ODBHB8DNbQ4BigqQmyUChXkZiddW+Q6RBCP9RA4/397gBod1cnHq6sINrfTxBXV+A9PfUP4KqBn570LODZLHhnJ+LLS42IT0/16Yi5OdDGBmhnB7S9DTExAdbcXB+AcHlZJ6uaWL0Hg4OgrS38K2hvr/YA3tEB+fCQ7PbJCeT19essn3viJWQ+j/j8PHnhvMaATAa0tla1s6p8wtVVlEZHwVpa9JBTTf02osPDGgEaGyFmZiBvbio7Wyzq+n7vCqEufGJyErS+DtrchJieBmtvtw8IBgZ0Y77a8VxOl9Jnvl7MJiAYGYF8fKyq8WBo6NuSZ8YAatI+l4y8uyvfOsOlpW9NnhkDtLZWfVHU8PrKnYfZLiExNaV3X/0+hvPz7w6iugUwS8tLAX56AkhL6EeXUBppeB/GX+axT5lwmtMeAAAAAElFTkSuQmCC"
                                  />
                                  <span className="text-xs flex justify-center text-center">
                                    {faker?.name}
                                  </span>
                                </div>

                                {/* {
                                                                    faker.path.includes('/uploads/') === true &&

                                                                    <img
                                                                        className="rounded-md"
                                                                        alt=""
                                                                        src={faker.path.includes('/uploads/') === true ? IMAGE_SERVER_URL + faker?.path : faker?.path}
                                                                    />
                                                                }

                                                                <div className="flex flex-col">
                                                                    <img className="cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nO3Xv0skMRQH8GnGH6yoWCiChbJgZaGNtYWNnf+CFqJYWIpiJ8gVgjaiYiX+BRaCrY0iy3EWarGIhYiLhQiLyWzmzeR7JKO76KLHqclucB6EYbr3Sd6bl/G8NNIwG8z3YWj9chfQ12cPwQwAwj+/wbJZOwgTALov2EMwQwBrCGYQQDYQpgFkGmEDQCYRtgBkCmETQCYQtgH0BlGXgP9ZXgrw0xOAcyVUGhuDzOchi0WECwvuAeTtLcoRx+C9ve4AeFdXJfejI/0sjY+7A2CZDBBFCeDiwkGA7yM+OyuXj4pgeNgtQLi4WOkBIrC2NrcAvLsbCILkEHK5TyfPajmJo4ODBHB8DNbQ4BigqQmyUChXkZiddW+Q6RBCP9RA4/397gBod1cnHq6sINrfTxBXV+A9PfUP4KqBn570LODZLHhnJ+LLS42IT0/16Yi5OdDGBmhnB7S9DTExAdbcXB+AcHlZJ6uaWL0Hg4OgrS38K2hvr/YA3tEB+fCQ7PbJCeT19essn3viJWQ+j/j8PHnhvMaATAa0tla1s6p8wtVVlEZHwVpa9JBTTf02osPDGgEaGyFmZiBvbio7Wyzq+n7vCqEufGJyErS+DtrchJieBmtvtw8IBgZ0Y77a8VxOl9Jnvl7MJiAYGYF8fKyq8WBo6NuSZ8YAatI+l4y8uyvfOsOlpW9NnhkDtLZWfVHU8PrKnYfZLiExNaV3X/0+hvPz7w6iugUwS8tLAX56AkhL6EeXUBppeB/GX+axT5lwmtMeAAAAAElFTkSuQmCC" />
                                                                    <span className="text-xs flex justify-center text-center">{faker?.name}</span>
                                                                </div> */}

                                <div className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 -mr-2 -mt-2">
                                  <div
                                    onClick={(e) => {
                                      var array = [...images];
                                      array.splice(fakerKey, 1);
                                      setImages(array);
                                    }}
                                  >
                                    {/* <Lucide icon="X" className="w-4 h-4" /> */}
                                    <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                        <div className="px-4 pb-4 flex flex-col items-center justify-center cursor-pointer relative text-sm mt-4">
                          <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                          {/* <Lucide icon="File" className="w-5 h-5" /> */}
                          Upload documents or drag and drop
                          <Input
                            id="horizontal-form-1"
                            type="file"
                            multiple
                            accept={"application/pdf"}
                            className="w-full h-full top-0 left-0 absolute opacity-0"
                            onChange={(e) => {
                              Array.from(e.target.files).map((item) => {
                                let file = item;
                                let reader = new FileReader();
                                reader.readAsDataURL(file);
                                let fileInp = item;
                                let baseURL;
                                // on reader load somthing...
                                reader.onload = () => {
                                  // Make a fileInfo Object
                                  baseURL = reader.result;
                                  let obj = {
                                    path: baseURL,
                                    thumbnail: false,
                                    name: file.name
                                  };
                                  console.log(">>>>>", obj);

                                  setImages((existing) => [...existing, obj]);
                                };
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {selectedJob ? (
                    <div className="flex w-full justify-end mt-12">
                      <div
                        className="flex items-center text-white font-medium bg-blueF w-fit px-16 py-2 rounded-full text-sm space-x-4 cursor-pointer"
                        onClick={updatePersonalDetails}
                      >
                        <span>Update Personal Details</span>
                      </div>
                    </div>
                  ) : (
                    <div className="flex justify-end mt-12">
                      <div
                        className="flex items-center text-white font-medium bg-blueF w-fit px-16 py-2 rounded-full text-sm space-x-4 cursor-pointer"
                        onClick={() => {
                          setproTab(2);
                          window.scrollTo(0, 0);
                        }}
                      >
                        <span>Next</span>
                      </div>
                    </div>
                  )}
                </>
              )}

              {proTab === 2 && (
                <>
                  {workExperienceItems.map((item, index) => (
                    <WorkExperienceCard
                      key={index}
                      experience={item}
                      onUpdate={(updatedExperience) =>
                        updateWorkExperienceItem(item.id, updatedExperience)
                      }
                      onRemove={
                        workExperienceItems.length > 1
                          ? () => removeWorkExperienceItem(item.id)
                          : null
                      }
                    />
                  ))}
                  <div className="w-full flex justify-end ">
                    <button
                      className="flex justify-center mt-5 items-center text-blueF font-medium border-blueF border-[1px] w-full px-16 py-3 rounded-lg text-sm space-x-4 cursor-pointer"
                      onClick={addNewWorkExperience}
                    >
                      Add Work Experience
                    </button>
                  </div>

                  {selectedJob ? (
                    <div className="flex w-full justify-end mt-12">
                      <div
                        className="flex items-center text-white font-medium bg-blueF w-fit px-16 py-2 rounded-full text-sm space-x-4 cursor-pointer"
                        onClick={updateExperiences}
                      >
                        <span>Update Experiences</span>
                      </div>
                    </div>
                  ) : (
                    <div className="flex justify-end mt-12">
                      <div
                        className="flex items-center text-white font-medium bg-blueF w-fit px-16 py-2 rounded-full text-sm space-x-4 cursor-pointer"
                        onClick={() => {
                          setproTab(3);
                          window.scrollTo(0, 0);
                        }}
                      >
                        <span>Next</span>
                      </div>
                    </div>
                  )}
                </>
              )}

              {proTab === 3 && (
                <>
                  {qualificationItems.map((item) => (
                    <QualificationCard
                      key={item.id}
                      qualification={item}
                      onUpdate={(updatedQualification) =>
                        updateQualificationItem(item.id, updatedQualification)
                      }
                      onRemove={() => removeQualificationItem(item.id)}
                    />
                  ))}

                  <div className="w-full flex justify-end">
                    <button
                    className="flex justify-center mt-5 items-center text-blueF font-medium border-blueF border-[1px] w-full px-16 py-3 rounded-lg text-sm space-x-4 cursor-pointer"
                    onClick={addNewQualification}
                    >
                      Add Qualification
                    </button>
                  </div>

                  {selectedJob ? (
                    <div className="flex w-full justify-end mt-12">
                      <div
                        className="flex items-center text-white font-medium bg-blueF w-fit px-16 py-2 rounded-full text-sm space-x-4 cursor-pointer"
                        onClick={updateQualifications}
                      >
                        <span>Update Qualifications</span>
                      </div>
                    </div>
                  ) : (
                    <div className="flex justify-end mt-12">
                      <div
                        className="flex items-center text-white font-medium bg-blueF w-fit px-16 py-2 rounded-full text-sm space-x-4 cursor-pointer"
                        onClick={() => {
                          setproTab(4);
                          window.scrollTo(0, 0);
                        }}
                      >
                        <span>Next</span>
                      </div>
                    </div>
                  )}
                </>
              )}

              {proTab === 4 && (
                <>
                  {achievementItems.map((item) => (
                    <AchievementCard
                      key={item.id}
                      achievement={item}
                      onUpdate={(updatedAchievement) =>
                        updateAchievementItem(item.id, updatedAchievement)
                      }
                      onRemove={() => removeAchievementItem(item.id)}
                    />
                  ))}

                  <div className="w-full flex justify-end">
                    <button
                      className="flex justify-center mt-5 items-center text-blueF font-medium border-blueF border-[1px] w-full px-16 py-3 rounded-lg text-sm space-x-4 cursor-pointer"
                      onClick={addNewAchievement}
                    >
                      Add Achievement
                    </button>
                  </div>
                </>
              )}

              {/* {
                                proTab === 1 &&

                                <Profile
                                    proTab={proTab}
                                    setproTab={setproTab}
                                />

                            }

                            {
                                proTab === 2 &&

                                <Work
                                    proTab={proTab}
                                    setproTab={setproTab}
                                />

                            }

                            {
                                proTab === 3 &&

                                <Qualifications
                                    proTab={proTab}
                                    setproTab={setproTab}
                                />

                            }

                            {
                                proTab === 4 &&

                                <Achievements

                                />

                            } */}

              {proTab === 4 && (
                <>
                  <div className="w-full items-center justify-center">
                    <BotToast open={bOpen} msg={bMsg} status={bStatus} />
                  </div>
                  {selectedJob ? (
                    <div className="flex w-full justify-end mt-12">
                      <div
                        className="flex items-center text-white font-medium bg-blueF w-fit px-16 py-2 rounded-full text-sm space-x-4 cursor-pointer"
                        onClick={updateAchievements}
                      >
                        <span>Update Achievements</span>
                      </div>
                    </div>
                  ) : (
                    <div className="flex justify-end mt-12">
                      <div
                        className="flex items-center text-white font-medium bg-blueF w-fit px-16 py-2 rounded-full text-sm space-x-4 cursor-pointer"
                        onClick={saveJobSeeker}
                      >
                        <span>Save Profile</span>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            {/* END: Display Information */}

            {/* 
                        <div className="w-full flex justify-end">

                            <button className="btn btn-primary w-20 mt-3"
                            // onClick={onSubmit}
                            >
                                Save
                            </button>

                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

function CustomInput({ onFocus, value, onChange }) {
  return (
    <div className="mt-1.5 flex">
      <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-[#F4F4F4] dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
        <i className="text-xl las la-calendar"></i>
      </span>
      <Input
        // readOnly
        className={classnames({
          "form-control": true,
          // "border-red-600": errcountry,
          "bg-[#F4F4F4]": true,
          "border-none": true,

          "!rounded-l-none": true
        })}
        // className="!rounded-l-none"
        placeholder="DD-MM-YYYY"
        onFocus={onFocus}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

const AchievementCard = ({ achievement, onUpdate, onRemove }) => {
  const [achievementSingle, setAchievementSingle] = useState(achievement);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedAchievement = { ...achievementSingle, [name]: value };
    setAchievementSingle(updatedAchievement);
    onUpdate(updatedAchievement); // Automatically updates the parent state
  };
  const years = Array.from(new Array(20), (val, index) =>
    (new Date().getFullYear() - index).toString()
  );
  return (
    <div className="grid grid-cols-3 gap-x-4 gap-y-7 mt-8 text-sm border-black border-[1px] px-5 py-8 rounded-lg">
      {/* Achievement */}
      <div className="col-span-2">
        <label className="form-label">Achievement *</label>
        <Input
          type="text"
          className={`form-control ${
            achievement.errors?.achievement ? "border-red-600" : ""
          }`}
          placeholder="Achievement. Eg - Best Sales Person in Southern Region"
          value={achievementSingle.achievement}
          name="achievement"
          onChange={handleInputChange}
        />
        {achievement.errors?.achievement && (
          <div className="text-red-600 text-xs">
            {achievement.errors.achievement}
          </div>
        )}
      </div>

      {/* Achieved Year */}
      <div>
        <label className="form-label">Achieved Year *</label>
        <Autocomplete
          className="mt-0"
          disablePortal
          value={achievementSingle.achYear} // This is where the selected year is stored
          options={years} // Array of years for the dropdown
          getOptionLabel={(option) => option} // Ensure the label is the year itself
          onChange={(e, value) =>
            handleInputChange({ target: { name: "achYear", value } })
          } // Set the selected year
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input
                {...params.inputProps}
                className={classnames({
                  "form-control": true,
                  "border-red-600": achievement.errors.achYear, // Error handling
                  "bg-[#F4F4F4]": true,
                  "border-none": true,
                  "mt-1.5": true
                })}
                placeholder="Select Achieved Year"
              />
            </div>
          )}
          ListboxProps={{
            style: { maxHeight: "150px", fontSize: "14px" } // Limit the dropdown height
          }}
        />
        {achievement.errors?.achYear && (
          <div className="text-red-600 text-xs">
            {achievement.errors.achYear}
          </div>
        )}
      </div>

      {/* Description of Achievement */}
      <div className="col-span-3">
        <label className="form-label">Description of the Achievement</label>
        <Textarea
          rows={7}
          className="form-control bg-[#F4F4F4] text-[#86949F] mt-1.5"
          placeholder="Description of the achievement"
          value={achievementSingle.aDesc}
          name="aDesc"
          onChange={handleInputChange}
        />
      </div>

      {/* Remove Button */}
      {onRemove && (
        <div className="col-span-3 flex justify-end">
          <button
            className="bg-red-500 text-white py-2 px-4 rounded"
            onClick={onRemove}
          >
            Remove
          </button>
        </div>
      )}
    </div>
  );
};

const QualificationCard = ({ qualification, onUpdate, onRemove }) => {
  const [qualificationSingle, setQualificationSingle] = useState(qualification);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedQualification = { ...qualificationSingle, [name]: value };
    setQualificationSingle(updatedQualification);
    onUpdate(updatedQualification); // Automatically updates the parent state
  };

  const qualificationTypes = [
    "Doctoral Degrees", 
    "Master’s Degrees", 
    "Postgraduate Diplomas and Certificates", 
    "Bachelor’s Degrees", 
    "Associate Degrees", 
    "Advanced Diploma", 
    "Diploma", 
    "Certificate", 
    "Trade Qualifications", 
    "G.C.E. A/L", 
    "G.C.E. O/L", 
    "London A/L", 
    "London O/L", 
    "Other"
];

const status = ["In Progress", "Completed"]

const universities = [
  "University of Colombo",
  "University of Peradeniya",
  "University of Kelaniya",
  "University of Sri Jayewardenepura",
  "University of Moratuwa",
  "University of Jaffna",
  "Eastern University, Sri Lanka",
  "University of Ruhuna",
  "South Eastern University of Sri Lanka",
  "Sabaragamuwa University of Sri Lanka",
  "Wayamba University of Sri Lanka",
  "Uva Wellassa University",
  "Rajarata University of Sri Lanka",
  "Open University of Sri Lanka",
  "Gampaha Wickramarachchi University of Indigenous Medicine",
  "Buddhist and Pali University of Sri Lanka",
  "University of the Visual and Performing Arts",
  "University of Vavuniya",
  "Sri Lanka Institute of Information Technology (SLIIT)",
  "General Sir John Kotelawala Defence University (KDU)",
  "NSBM Green University",
  "Horizon Campus",
  "CINEC Campus",
  "South Asian Institute of Technology and Medicine (SAITM)",
  "ICBT Campus",
  "American National College (ANC)",
  "Asian University for Women (AUW)",
  "Institute of Indigenous Medicine (IIM)",
  "Institute of Technology, University of Moratuwa (ITUM)",
  "Postgraduate Institute of Management (PIM)",
  "Other"
];

  return (
    <div className="grid grid-cols-3 gap-x-4 gap-y-7 mt-8 text-sm border-black border-[1px] px-5 py-8 rounded-lg">
      {/* Field of Study */}
      <div className="col-span-3 md:col-auto">
        <label className="form-label">Field of Study *</label>
        <div>
          <Autocomplete
            className="mt-0"
            disablePortal
            value={qualificationSingle.fieldOfStudy}
            options={["Computer Science", "Engineering", "Mathematics"]} // Example options
            getOptionLabel={(option) => option}
            onChange={(e, value) =>
              handleInputChange({ target: { name: "fieldOfStudy", value } })
            }
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <Input
                  {...params.inputProps}
                  className={classnames({
                    "form-control": true,
                    "border-red-600": qualification.errors.fieldOfStudy,
                    "bg-[#F4F4F4]": true,
                    "border-none": true,
                    "mt-1.5": true
                  })}
                  placeholder="Select Field of Study"
                />
              </div>
            )}
            ListboxProps={{
              style: { maxHeight: "150px", fontSize: "14px" }
            }}
          />
          {qualification.errors?.fieldOfStudy && (
            <div className="text-red-600 text-xs">
              {qualification.errors.fieldOfStudy}
            </div>
          )}
        </div>
      </div>

      {/* Qualification Type */}
      <div className="col-span-3 md:col-auto">
        <label className="form-label">Qualification Type *</label>
        <Autocomplete
          className="mt-0"
          disablePortal
          value={qualificationSingle.qualificationType}
          options={qualificationTypes} // Example qualification types
          getOptionLabel={(option) => option}
          onChange={(e, value) =>
            handleInputChange({ target: { name: "qualificationType", value } })
          }
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input
                {...params.inputProps}
                className={classnames({
                  "form-control": true,
                  "border-red-600": qualification.errors.qualificationType,
                  "bg-[#F4F4F4]": true,
                  "border-none": true,
                  "mt-1.5": true
                })}
                placeholder="Select Qualification Type"
              />
            </div>
          )}
          ListboxProps={{
            style: { maxHeight: "250px", fontSize: "14px" }
          }}
        />
        {qualification.errors?.qualificationType && (
          <div className="text-red-600 text-xs">
            {qualification.errors.qualificationType}
          </div>
        )}
      </div>

      {/* Qualification Name */}
      <div className="col-span-3 md:col-auto">
        <label className="form-label">Qualification Name *</label>
        <Input
          type="text"
          className={`form-control ${
            qualification.errors?.qualificationName ? "border-red-600" : ""
          }`}
          placeholder="Qualification Name"
          value={qualificationSingle.qualificationName}
          name="qualificationName"
          onChange={handleInputChange}
        />
        {qualification.errors?.qualificationName && (
          <div className="text-red-600 text-xs">
            {qualification.errors.qualificationName}
          </div>
        )}
      </div>

      {/* Qualification Status */}
      <div className="col-span-3 md:col-auto">
        <label className="form-label">Qualification Status *</label>
        <Autocomplete
          className="mt-0"
          disablePortal
          value={qualificationSingle.qualificationStatus}
          options={status} // Example qualification statuses
          getOptionLabel={(option) => option}
          onChange={(e, value) =>
            handleInputChange({
              target: { name: "qualificationStatus", value }
            })
          }
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input
                {...params.inputProps}
                className={classnames({
                  "form-control": true,
                  "border-red-600": qualification.errors.qualificationStatus,
                  "bg-[#F4F4F4]": true,
                  "border-none": true,
                  "mt-1.5": true
                })}
                placeholder="Select Qualification Status"
              />
            </div>
          )}
          ListboxProps={{
            style: { maxHeight: "150px", fontSize: "14px" }
          }}
        />
        {qualification.errors?.qualificationStatus && (
          <div className="text-red-600 text-xs">
            {qualification.errors.qualificationStatus}
          </div>
        )}
      </div>

      {/* Start Date */}
      <div className="col-span-3 md:col-auto">
        <label className="form-label">Start Date *</label>
        <DatePicker
          format="DD/MM/YYYY"
          containerStyle={{ width: "100%" }}
          value={qualificationSingle.startDate}
          onChange={(date) =>
            handleInputChange({
              target: { name: "startDate", value: date.toDate() }
            })
          }
          render={<CustomInput />}
        />
        {qualification.errors?.startDate && (
          <div className="text-red-600 text-xs">
            {qualification.errors.startDate}
          </div>
        )}
      </div>

      {/* End Date */}
      <div className="col-span-3 md:col-auto">
        <label className="form-label">End Date *</label>
        <DatePicker
          format="DD/MM/YYYY"
          containerStyle={{ width: "100%" }}
          value={qualificationSingle.endDate}
          onChange={(date) =>
            handleInputChange({
              target: { name: "endDate", value: date.toDate() }
            })
          }
          render={<CustomInput />}
        />
        {qualification.errors?.endDate && (
          <div className="text-red-600 text-xs">
            {qualification.errors.endDate}
          </div>
        )}
      </div>

      {/* Institution Name */}
      <div className="col-span-3">
        <label className="form-label">Institution/University Name *</label>
        <Autocomplete
          className="mt-0"
          disablePortal
          value={qualificationSingle.institution}
          options={universities} // Example universities
          getOptionLabel={(option) => option}
          onChange={(e, value) =>
            handleInputChange({ target: { name: "institution", value } })
          }
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input
                {...params.inputProps}
                className={classnames({
                  "form-control": true,
                  "border-red-600": qualification.errors.institution,
                  "bg-[#F4F4F4]": true,
                  "border-none": true,
                  "mt-1.5": true
                })}
                placeholder="Select Institution Name"
              />
            </div>
          )}
          ListboxProps={{
            style: { maxHeight: "150px", fontSize: "14px" }
          }}
        />
        {qualification.errors?.institution && (
          <div className="text-red-600 text-xs">
            {qualification.errors.institution}
          </div>
        )}
      </div>

      {/* Remove Button */}
      {onRemove && (
        <div className="col-span-3 flex justify-end">
          <button
            className="bg-red-500 text-white py-2 px-4 rounded"
            onClick={onRemove}
          >
            Remove
          </button>
        </div>
      )}

      {/* Display errors, if any */}
      {Object.keys(qualification.errors).length > 0 && (
        <div className="col-span-3 text-red-600">
          Please fix the errors before saving.
        </div>
      )}
    </div>
  );
};

const WorkExperienceCard = ({ experience, onUpdate, onRemove }) => {
  const [experienceSingle, setExperienceSingle] = useState(experience);

  console.log(experience);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedExperience = { ...experienceSingle, [name]: value };
    setExperienceSingle(updatedExperience);
    onUpdate(updatedExperience); // Automatically updates the parent state
  };

  const categories = [
    "Permanent Full Time", 
    "Permanent Part Time", 
    "Casual", 
    "Fixed Term Contract", 
    "Freelance"
];

  return (
    <div className="grid grid-cols-3 md:grid-cols-3 gap-x-4 gap-y-7 mt-8 cursor-pointer text-[#86949F] text-sm border-black border-[1px] px-5 py-8 rounded-lg">
      <div className="col-span-3 md:col-auto">
        <label htmlFor="update-profile-form-role" className="form-label">
          Designation/Role *
        </label>
        <Input
          id="update-profile-form-company"
          type="text"
          className={`form-control ${
            experience.errors?.role ? "border-red-600" : ""
          }`}
          placeholder="Select Designation/Role"
          value={experienceSingle.role || ""}
          onChange={handleInputChange}
          name="role"
        />
      
        {experience.errors?.role && (
          <div className="text-red-600 mt-2 text-right text-xs">
            {experience.errors?.role}
          </div>
        )}
      </div>
      <div className="col-span-3 md:col-auto">
        <label htmlFor="update-profile-form-role" className="form-label">
          Employee Type*
        </label>
        <Autocomplete
          className="mt-0"
          disablePortal
          value={experienceSingle.category || ""}
          options={categories}
          getOptionLabel={(option) => option}
          onChange={(e, value) =>
            handleInputChange({ target: { name: "category", value } })
          }
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input
                {...params.inputProps}
                className={`form-control ${
                  experience.errors?.category ? "border-red-600" : ""
                }`}
                placeholder="Select Category"
              />
            </div>
          )}
        />
        {experience.errors?.category && (
          <div className="text-red-600 mt-2 text-right text-xs">
            {experience.errors?.category}
          </div>
        )}
      </div>

      {/* Repeat similar structure for other fields */}
      <div className="col-span-3 md:col-auto">
        <label htmlFor="update-profile-form-company" className="form-label">
          Company Name *
        </label>
        <Input
          id="update-profile-form-company"
          type="text"
          className={`form-control ${
            experience.errors?.companyName ? "border-red-600" : ""
          }`}
          placeholder="Company Name"
          value={experienceSingle.companyName || ""}
          onChange={handleInputChange}
          name="companyName"
        />
        {experience.errors?.companyName && (
          <div className="text-red-600 mt-2 text-right text-xs">
            {experience.errors?.companyName}
          </div>
        )}
      </div>

      <div className="col-span-3 md:col-auto">
        <label htmlFor="update-profile-form-role" className="form-label">
          Employment Status
        </label>
        <Autocomplete
          className="mt-0"
          disablePortal
          value={experienceSingle.employmentStatus || ""}
          options={["Sri Lanka", "Australia"]}
          getOptionLabel={(option) => option}
          onChange={(e, value) =>
            handleInputChange({ target: { name: "employmentStatus", value } })
          }
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input
                {...params.inputProps}
                className={`form-control ${
                  experience.errors?.employmentStatus ? "border-red-600" : ""
                }`}
                placeholder="Select Employment Status"
              />
            </div>
          )}
        />
        {experience.errors?.employmentStatus && (
          <div className="text-red-600 mt-2 text-right text-xs">
            {experience.errors?.employmentStatus}
          </div>
        )}
      </div>

      {/* Start Date */}
      <div className="col-span-3 md:col-auto">
        <label htmlFor="update-profile-form-startDate" className="form-label">
          Start Date *
        </label>
        <DatePicker
          format="DD/MM/YYYY"
          containerStyle={{ width: "100%" }}
          value={experienceSingle.startDate || ""}
          onChange={(date) =>
            handleInputChange({
              target: { name: "startDate", value: date.toDate() }
            })
          }
          render={<CustomInput />}
        />
        {experience.errors?.startDate && (
          <div className="text-red-600 mt-2 text-right text-xs">
            {experience.errors?.startDate}
          </div>
        )}
      </div>

      {/* End Date */}
      <div className="col-span-3 md:col-auto">
        <label htmlFor="update-profile-form-endDate" className="form-label">
          End Date
        </label>
        <DatePicker
          format="DD/MM/YYYY"
          containerStyle={{ width: "100%" }}
          value={experienceSingle.endDate || ""}
          onChange={(date) =>
            handleInputChange({
              target: { name: "endDate", value: date.toDate() }
            })
          }
          render={<CustomInput />}
        />
        {experience.errors?.endDate && (
          <div className="text-red-600 mt-2 text-right text-xs">
            {experience.errors?.endDate}
          </div>
        )}
      </div>

      {/* Duties */}
      <div className="col-span-3">
        <label htmlFor="update-profile-form-duties" className="form-label">
          Add Duties & Responsibilities
        </label>
        <Textarea
          rows={7}
          id="update-profile-form-duties"
          className="form-control bg-[#F4F4F4] text-[#86949F] mt-1.5"
          placeholder="Add Duties & Responsibilities"
          value={experienceSingle.duties || ""}
          onChange={(e) => handleInputChange(e)}
          name="duties"
        />
      </div>

      {onRemove && (
        <div className="col-span-3 flex justify-end">
          <button
            className="bg-red-500 text-white py-2 px-4 rounded mr-4"
            onClick={onRemove}
          >
            Remove
          </button>
        </div>
      )}
      {Object.keys(experience.errors).length > 0 && (
        <div className="col-span-3 text-red-600">
          Please fix the errors before saving.
        </div>
      )}
    </div>
  );
};

export default Jobs;
