import React, { FC, useState, useEffect, useRef } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL, IMAGE_SERVER_URL_DEV } from "Constant/System";
import _ from "lodash";
import { addToast } from "shared/Toast";
import { postBlogs, postCleanTypes, postJobs } from "Services/API/Post";
import { GetCleaningType, GetUserId } from "Services/API/Get";
import { Autocomplete } from "@mui/material";
import { UpdateCleanType } from "Services/API/Put";
import BotToast from "shared/BotToast";
import LoadingSpinner from "shared/LoadingSpinner";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const Blogs = (props) => {

    const sItem = props?.selectedItem

    const onCloseModalQuickView = props?.onCloseModalQuickView

    const [images, setImages] = useState(sItem ? sItem?.image : "");

    const [title, settitle] = useState(sItem ? sItem?.title : "");
    const [blogType, setblogType] = useState("");
    const [description, setdescription] = useState(sItem ? sItem?.description : "");
    const [Keywords, setKeywords] = useState("");

    const [errimages, seterrImages] = useState([]);

    const [errtitle, seterrtitle] = useState(false);
    const [errblogType, seterrblogType] = useState(false);
    const [errdescription, seterrdescription] = useState(false);
    const [errKeywords, seterrKeywords] = useState(false);

    const [cleanTypes, setcleanTypes] = useState([]);
    const [cleanId, setcleanId] = useState(sItem ? sItem?.cleaningtypeId : "");
    const [errclean, seterrclean] = useState(false);

    const [bOpen, setbOpen] = useState(false);
    const [bMsg, setbMsg] = useState("");
    const [bStatus, setbStatus] = useState("");

    const [lopen, setlopen] = useState(false);

    const [editorData, setEditorData] = useState(sItem ? sItem?.description : "<br /><br /><br /><br />");


    const listClean = () => {

        GetCleaningType()
            .then((res) => {
                console.log("cleanTypes>>>", res);
                setcleanTypes(res.data);
                // setallODistricts(res.data);
            })
            .catch((error) => {
                console.log("Error", error.response);
                setcleanTypes([])
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });
    };

    useEffect(() => {
        listClean();
    }, [])



    const saveBlog = (e) => {

        e.preventDefault();

        if (!cleanId) {
            seterrclean(true);
        }

        if (!title) {
            seterrtitle(true);
        }


        // if (!blogType) {
        //     seterrblogType(true);
        // }

        if (!editorData) {
            seterrdescription(true);
        }

        // if (!Keywords) {
        //     seterrKeywords(true);
        // }
        // if (images.length === 0) {
        //     seterrImages(true);
        // }

        // console.log(">>>>>>>>", _.filter(Keywords.split(" ")))

        // console.log(">>>>>>", images)

        let obj = {
            cleaningtypeId: cleanId,
            description: editorData,
            // keywords: _.filter(Keywords.split(" ")),
            userId: GetUserId(),
            image: images,
            title: title,
            // category: blogType


        }

        console.log("obj>>>>", obj)

        if (
            title &&
            editorData
            // Keywords &&
            // blogType
            // images.length > 0
        ) {
            if (sItem) {

                setlopen(true);

                UpdateCleanType(obj, sItem?.id)
                    .then((res) => {
                        setlopen(false);

                        setbOpen(true);
                        setbMsg("Service successfully updated ");
                        setbStatus("success");


                        // addToast("Clean Type successfully updated ", "success");
                        setcleanId("");
                        settitle("");
                        setdescription("");
                        setblogType("");
                        setImages("");
                        setKeywords([]);
                        onCloseModalQuickView();
                        setEditorData("<br /><br /><br /><br />")

                        // setOpenToast(true);
                        // setColor(COLORS.Primary);
                        // setAlertClass("success");
                        // setMsg("Successfully updated");
                        // getCurrentUser();
                        // seteDisable(true);
                        // getUser();

                        // history.push("/");
                        // window.location.reload();
                    })
                    .catch((error) => {
                        setlopen(false);
                        console.log("Error", error.response);

                        setbOpen(true);
                        setbMsg(error.response.data.message);
                        setbStatus("warning");

                        // addToast(error.response.data.message, "warning");
                        // setOpenToast(true);
                        // setColor(COLORS.Orange);
                        // setAlertClass("error");
                        // setMsg(error.response.data.message);
                    })

            } else {

                setlopen(true);

                postCleanTypes(obj)
                    .then((res) => {
                        setlopen(false);

                        setbOpen(true);
                        setbMsg("Service successfully added");
                        setbStatus("success");

                        // addToast("Clean Type successfully added", "success");
                        setcleanId("");
                        settitle("");
                        setdescription("");
                        setblogType("");
                        setImages("");
                        setKeywords([]);
                        onCloseModalQuickView();
                        setEditorData("<br /><br /><br /><br />")
                        // onCloseModalQuickView();
                        // setOpenToast(true);
                        // setColor(COLORS.Primary);
                        // setAlertClass("success");
                        // setMsg("Successfully updated");
                        // getCurrentUser();
                        // seteDisable(true);
                        // getUser();

                        // history.push("/");
                        // window.location.reload();
                    })
                    .catch((error) => {
                        setlopen(false);
                        console.log("Error", error.response);

                        setbOpen(true);
                        setbMsg(error.response.data.message);
                        setbStatus("warning");

                        // addToast(error.response.data.message, "warning");
                        // setOpenToast(true);
                        // setColor(COLORS.Orange);
                        // setAlertClass("error");
                        // setMsg(error.response.data.message);
                    })

            }


        }


    }


    useEffect(() => {
        if (bOpen === true) {
            setTimeout(() => {

                setbOpen(false)

            }, 10000);
        }
    }, [bOpen])

    return (
        <>
            <LoadingSpinner open={lopen} />
            <div className="flex justify-center w-full mt-2">

                <span className="block text-xl font-bold flex justify-center w-full text-center">
                    {
                        sItem ? "Edit Service" : "Add Service"
                    }
                </span>


            </div>
            <div className="w-full flex justify-center px-3">

                <div className="w-full md:w-11/12 lg:w-8/12">

                    <div className="w-full mt-6 space-y-4">
                        <div>
                            <Label className="text-sm">Service Name</Label>

                            <Input className="mt-1.5"
                                placeholder="Enter Name"
                                onChange={(e) => {
                                    settitle(e.target.value);
                                    seterrtitle(false);
                                }}
                                value={title}
                            />

                            {/* <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                // defaultValue={{ name: cleanTypes.find((v) => v.id === cleanId) ? cleanTypes.find((v) => v.id === cleanId).name : "" }}
                                value={{ name: cleanTypes.find((v) => v.id === cleanId) ? cleanTypes.find((v) => v.id === cleanId).name : "" }}

                                // value={{ name: "" }}
                                // options={[{ name: "" }, ...cleanTypes]}
                                // id="combo-box-demo"
                                options={cleanTypes}
                                getOptionLabel={(option) => option.name}
                                onChange={(e, newValue) => {
                                    // setcleanId(value.id);
                                    // seterrclean(false);

                                    if (newValue !== null) {
                                        setcleanId(newValue.id);
                                        seterrclean(false);
                                    } else {
                                        setcleanId("");
                                        seterrclean(false);
                                    }

                                    // if (newValue.length !== 0) {

                                    //     if (selectedCtypes?.length > 0) {

                                    //         const index = _.map(selectedCtypes, "id").indexOf(newValue?.id);

                                    //         if (index < 0) {

                                    //             selectedCtypes(selectedCtypes.concat(newValue));

                                    //         }

                                    //     } else {
                                    //         setselectedCtypes(selectedCtypes.concat(newValue));
                                    //     }

                                    // } else {
                                    //     // setselectedSizes([]);
                                    //     // seterrcat(false);
                                    // }

                                    // setCountry(value);
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Select a Cleaning Type" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            /> */}



                            {/* <Select className="mt-1.5"
                                    onChange={(e) => {
                                        setcleanId(e.target.value);
                                        seterrclean(false);
                                    }}
                                    value={cleanId}

                                >
                                    <option value={""}>Select a Cleaning Type</option>
                                    {

                                        cleanTypes.map((item, index) => {
                                            return (
                                                <option value={item.id}>{item?.name}</option>

                                            )
                                        })
                                    }
                                   
                                </Select> */}
                            {errtitle && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        {/* <div>
                            <Label className="text-sm">Blog title</Label>
                            <Input className="mt-1.5"
                                onChange={(e) => {
                                    settitle(e.target.value);
                                    seterrtitle(false);
                                }}
                                value={title}
                            />
                            {errtitle && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}

                        <div className="prose prose-sm  w-full sm:prose lg:prose dark:prose-invert">
                            <Label className="text-sm">Description</Label>
                            {/* <Textarea className="mt-1.5" rows={8} placeholder="Description"
                                onChange={(e) => {
                                    setdescription(e.target.value);
                                    seterrdescription(false);
                                }}
                                value={description}
                            /> */}
                            <CKEditor

                                editor={ClassicEditor}
                                data={editorData}
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setEditorData(data);
                                    console.log({ event, editor, data });
                                    seterrdescription(false);
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                            />
                            {errdescription && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>

                        {/* <div>
                            <Label className="text-sm">Type Keywords</Label>
                            <Input className="mt-1.5"
                                placeholder="#plumber #farmingjobs"
                                onChange={(e) => {
                                    setKeywords(e.target.value);
                                    seterrKeywords(false);
                                }}
                                value={Keywords}
                            />
                            {errKeywords && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}

                        <div className="w-full mt-3 xl:mt-0 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                            <div className="grid grid-cols-10 gap-5 pl-4 pr-5">
                                {[1]?.map((faker, fakerKey) => (
                                    <>
                                        {
                                            images &&

                                            <div
                                                // key={fakerKey}
                                                className="col-span-5 md:col-span-2 h-28 relative image-fit cursor-pointer zoom-in"
                                            >
                                                <img
                                                    className="rounded-md"
                                                    alt="Midone - HTML Admin Template"
                                                    src={images?.includes('/uploads/') === true ? IMAGE_SERVER_URL_DEV + images : images}
                                                />



                                            </div>
                                        }


                                    </>

                                ))}
                            </div>
                            <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative text-sm">

                                <i className="las la-image text-xl text-center  mr-2 flex items-center justify-center"></i>
                                <span className="text-primary mr-1">
                                    Upload image
                                </span>{" "}
                                or drag and drop
                                <input
                                    id="horizontal-form-1"
                                    type="file"
                                    accept={"image/png, image/jpeg"}
                                    className="w-full h-full top-0 left-0 absolute opacity-0"
                                    onChange={(e) => {

                                        let file = e.target.files[0];

                                        let reader = new FileReader();
                                        reader.readAsDataURL(file);
                                        let fileInp = e.target.files[0];
                                        let baseURL;
                                        // on reader load somthing...
                                        reader.onload = () => {
                                            // Make a fileInfo Object
                                            baseURL = reader.result;
                                            let obj =
                                            {
                                                path: baseURL,
                                                thumbnail: false,
                                            }
                                                ;

                                            // setImages(images.concat(obj))
                                            setImages(obj.path)


                                        };
                                    }}
                                />
                            </div>
                        </div>


                        <div className="w-full items-center justify-center">

                            <BotToast open={bOpen} msg={bMsg} status={bStatus} />

                            <button
                                className={
                                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-4 `}

                                onClick={saveBlog}
                            >
                                {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                                <span className="block  text-sm font-medium "
                                >

                                    {!sItem ? " Save" : "Save"}
                                </span>

                            </button>



                        </div>

                    </div>

                </div>
            </div>

        </>
    )

}

export default Blogs;