import React, { FC, useState, useEffect, useRef } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL, IMAGE_SERVER_URL_DEV } from "Constant/System";
import Company1 from "images/avatars/Image-1.png";
import ModalQuickView from "components/ModalJob";
import { addToast } from "shared/Toast";
import { GetApplicantBYContract, GetApplicantBYJob, getJobs } from "Services/API/Get";
import { DeleteJob } from "Services/API/Delete";
import { useHistory } from "react-router-dom";
import parse from 'html-react-parser';


const MyJobs = (props) => {

    const sJob = props?.selectedJob

    const Type = props?.type

    const router = useHistory();

    const [images, setImages] = useState([]);
    const [showModalQuickView, setShowModalQuickView] = useState(false);

    const [jobs, setjobs] = useState(null);

    const [search, setsearch] = useState("");
    const [category, setcategory] = useState("");

    const [selectedJob, setselectedJob] = useState("");

    const [currUser, setcurrUser] = useState(props.currUser);

    const getAllJobs = () => {
        console.log(">>>>>>sdsxcddf")

        if (Type === "contract") {
            GetApplicantBYContract({
                serviceId: sJob?.id
            })
                .then((res) => {
                    console.log(">>>>>>>jobs", res.data);
                    setjobs(res.data.result);
                })
                .catch((error) => {
                    // setlopen(false);
                    setjobs([]);
                    console.log("Error", error.response);
                    // addToast(error.response.data.message, "warning");
                    // setOpenToast(true);
                    // setColor(COLORS.Orange);
                    // setAlertClass("error");
                    // setMsg(error.response.data.message);
                })

        } else {
            GetApplicantBYJob({
                jobId: sJob?.id,
                title: search
            })
                .then((res) => {
                    console.log(">>>>>>>jobs", res.data);
                    setjobs(res.data.result);
                })
                .catch((error) => {
                    // setlopen(false);
                    setjobs([]);
                    console.log("Error", error.response);
                    // addToast(error.response.data.message, "warning");
                    // setOpenToast(true);
                    // setColor(COLORS.Orange);
                    // setAlertClass("error");
                    // setMsg(error.response.data.message);
                })
        }

    }

    useEffect(() => {
        getAllJobs();
    }, [showModalQuickView, search, category, Type])


    return (
        <>
            <div className="flex justify-center w-full mt-2">

                <span className="block text-xl font-bold flex justify-center w-full text-center">

                    {
                        Type === "contract" ? "Quotations" : "Applicants"
                    }

                </span>


            </div>
            <div className="w-full flex justify-center px-3">

                <div className="w-full md:w-11/12 lg:w-full">

                    <div className="flex w-full items-center justify-center">
                        {/* <button
                            className={
                                `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-12 `}

                            onClick={() => {
                                // if (currUser?.packageId) {
                                //     setselectedJob("");
                                //     setShowModalQuickView(true)
                                // } else {
                                //     router.push("/subscription")
                                // }

                                setselectedJob("");
                                setShowModalQuickView(true)
                            }}
                        >
                        
                            <span className="block  text-sm font-medium "
                            >
                                Post a Job
                            </span>

                        </button> */}
                    </div>
                    <form className="mt-6 w-full">

                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                            </div>
                            <Input type="search" id="default-search" className="block w-full p-4 pl-10 text-base text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Name..." required
                                onChange={(e) => {
                                    setsearch(e.target.value);
                                }}
                            />

                        </div>
                    </form>

                    {/* <div className="w-full mt-4 space-y-4">

                        <div>
                            <Label className="text-sm">Category</Label>
                            <Select className="mt-1.5"
                                onChange={(e) => {
                                    setcategory(e.target.value);
                                    // seterrcountry(false);
                                }}
                                value={category}
                            >
                                <option value={""}>All</option>
                                <option value="Seasonal">Seasonal</option>
                                <option value="Casual">Casual</option>
                                <option value="Temporary">Temporary</option>
                                <option value="Fixed">Fixed term contract</option>
                                <option value="Part Time">Permanent – Part Time</option>
                                <option value="Full Time">Permanent – Full time</option>
                                
                            </Select>
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                       


                    </div> */}
                    <div className="mt-10 space-y-2">
                        {
                            jobs?.map((item, index) => {
                                const doc = item?.document ? JSON.parse(item?.document)[0] : ""
                                return (
                                    <>
                                        <div className="">
                                            <div className="bg-gray-100 flex w-full px-4 py-3 rounded-lg space-x-4">
                                                {/* <div className="flex justify-start w-4/12 items-center">
                                                    <div className="w-12 h-12 object-fill bg-contain rounded-full border-2 border-white"
                                                        style={{
                                                            // backgroundImage: `url(${Company1})`
                                                            backgroundImage: item ? `url(${IMAGE_SERVER_URL_DEV}${item?.avatar})` : `url(${Company1})`
                                                        }}
                                                    >

                                                    </div>

                                                </div> */}
                                                <div className="flex justify-start w-full items-center ml-3">
                                                    <div>
                                                        <div className="text-base font-medium flex justify-start items-center">Name : {item.name}</div>

                                                        <div className="text-sm font-medium flex justify-start items-center">Email : {item.mainEmail}</div>
                                                        <div className="text-sm font-medium flex justify-start items-center">Contact : {item.contactNo}</div>
                                                        <div className="text-sm font-medium flex justify-start items-center">Whatsapp : {item.whatsAppNo ? item.whatsAppNo : "None"}</div>
                                                        <div className="text-sm font-medium flex justify-start items-center">Gender : {item.gender}</div>
                                                        <div className="text-sm font-medium flex justify-start items-center">Age : {item.age}</div>
                                                        <div className="text-sm font-medium flex justify-start items-center">City : {item.city}</div>
                                                        <div className="text-sm font-medium flex justify-start items-center">District : {item.district}</div>
                                                        <div className="text-sm font-medium flex justify-start items-center">Current Resides Country : {item.currentResidesCountry}</div>
                                                        <div className="text-sm font-medium flex justify-start items-center">Cast : {item.cast}</div>
                                                        <div className="text-sm font-medium flex justify-start items-center">Race : {item.race}</div>
                                                        <div className="text-sm font-medium flex justify-start items-center">Religion : {item.religion}</div>
                                                        <div className="text-sm font-medium flex justify-start items-center">Height : {item.height}</div>
                                                        <div className="text-sm font-medium flex justify-start items-center">Horroscope Status : {item.horoscopStatus}</div>
                                                        <div className="text-sm font-medium flex justify-start items-center">Marriage Status : {item.marriageStatus}</div>
                                                        <div className="text-sm font-medium flex justify-start items-center">Ocuupation : {item.occupation}</div>
                                                        <div className="text-sm font-medium flex justify-start items-center">Family Background : {item.familyBackground ? item.familyBackground : "None"}</div>



                                                        <div className="text-sm font-medium flex justify-start items-center mt-2">{parse(item.description)}</div>
                                                    </div>
                                                </div>

                                                <div className="w-6/12 flex justify-start items-center">
                                                    <div>
                                                        {/* <div className="text-base font-medium flex justify-start items-center">Status</div>

                                                    <div className="text-sm font-medium flex justify-start items-center">Active</div> */}
                                                    </div>
                                                </div>

                                                <div className="w-6/12 flex justify-start items-center">
                                                    <div>
                                                        {/* <div className="text-base font-medium flex justify-start items-center">Applicants</div>

                                                    <div className="text-sm font-medium flex justify-start items-center">{item?.noOfApplicants}</div> */}
                                                        <a className="flex flex-col cursor-pointer" href={`${IMAGE_SERVER_URL_DEV}${doc?.path}`} target="_blank" >

                                                            <div className="flex w-full">
                                                                <img className="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nO3Xv0skMRQH8GnGH6yoWCiChbJgZaGNtYWNnf+CFqJYWIpiJ8gVgjaiYiX+BRaCrY0iy3EWarGIhYiLhQiLyWzmzeR7JKO76KLHqclucB6EYbr3Sd6bl/G8NNIwG8z3YWj9chfQ12cPwQwAwj+/wbJZOwgTALov2EMwQwBrCGYQQDYQpgFkGmEDQCYRtgBkCmETQCYQtgH0BlGXgP9ZXgrw0xOAcyVUGhuDzOchi0WECwvuAeTtLcoRx+C9ve4AeFdXJfejI/0sjY+7A2CZDBBFCeDiwkGA7yM+OyuXj4pgeNgtQLi4WOkBIrC2NrcAvLsbCILkEHK5TyfPajmJo4ODBHB8DNbQ4BigqQmyUChXkZiddW+Q6RBCP9RA4/397gBod1cnHq6sINrfTxBXV+A9PfUP4KqBn570LODZLHhnJ+LLS42IT0/16Yi5OdDGBmhnB7S9DTExAdbcXB+AcHlZJ6uaWL0Hg4OgrS38K2hvr/YA3tEB+fCQ7PbJCeT19essn3viJWQ+j/j8PHnhvMaATAa0tla1s6p8wtVVlEZHwVpa9JBTTf02osPDGgEaGyFmZiBvbio7Wyzq+n7vCqEufGJyErS+DtrchJieBmtvtw8IBgZ0Y77a8VxOl9Jnvl7MJiAYGYF8fKyq8WBo6NuSZ8YAatI+l4y8uyvfOsOlpW9NnhkDtLZWfVHU8PrKnYfZLiExNaV3X/0+hvPz7w6iugUwS8tLAX56AkhL6EeXUBppeB/GX+axT5lwmtMeAAAAAElFTkSuQmCC" />
                                                            </div>
                                                            {/* <span className="text-xs flex justify-center text-center mt-1">{item?.name}</span> */}

                                                        </a>
                                                    </div>
                                                </div>


                                            </div>


                                            {/* <div className="bg-gray-100 w-full px-4 py-3 rounded-b-lg space-y-0">
                                                {parse(item?.description)}
                                            </div> */}


                                            {/* <div className="flex items-center">
                                            <i className="las la-pencil-alt text-xl text-left ml-2 cursor-pointer"
                                                onClick={() => {
                                                    console.log("item>>>>", item)
                                                    setselectedJob(item);
                                                    setShowModalQuickView(true);

                                                }}
                                            ></i>
                                            <i className="las la-trash-alt text-xl text-left ml-2 text-red-500 cursor-pointer"
                                                onClick={() => {
                                                    DeleteJob(item?.id)
                                                        .then((res) => {

                                                            addToast("Job successfully deleted", "success");
                                                            getAllJobs();
                                                        })
                                                        .catch((error) => {
                                                            // setlopen(false);
                                                            console.log("Error", error.response);
                                                            addToast(error.response.data.message, "warning");
                                                            // setOpenToast(true);
                                                            // setColor(COLORS.Orange);
                                                            // setAlertClass("error");
                                                            // setMsg(error.response.data.message);
                                                        })
                                                }}
                                            ></i>
                                        </div> */}

                                        </div>

                                    </>
                                );
                            })
                        }
                        {
                            jobs?.length === 0 &&

                            <div className="text-sm font-medium flex justify-center items-center">
                                {
                                    Type === "contract" ? "No Quotations Found" : "No Applicants Found"
                                }

                            </div>
                        }

                    </div>

                </div>
            </div>

            <ModalQuickView
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
                selectedJob={selectedJob}
            />

        </>
    )

}

export default MyJobs;