import React, { FC, useState, useEffect, useRef } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL } from "Constant/System";
import _ from "lodash";
import { addToast } from "shared/Toast";
import { AddSubsForMain, AddSubsForSub2Id, AddSubsForSubId, CreateSubCategory, postBlogs, postJobs } from "Services/API/Post";
import { GetAllSubcategory1, GetAllSubcategory2, GetAllSubcategory3, GetAllSubs2forMain, GetAllSubs3forMain, GetAllSubsforMain, GetMaincategory, GetUserId } from "Services/API/Get";
import { Button, Grid, TextField, Typography, Autocomplete } from "@mui/material";
import { COLORS } from "Constant/Colors";
import { EditMCategory, EditSubCategory } from "Services/API/Put";


const Blogs = (props) => {

    const MainId = props?.mainId;

    const SubId = props?.subId;

    const Sub2Id = props?.sub2Id;

    const Sub3Id = props?.sub3Id;

    const Show = props?.show;

    const Type = props?.type;

    const [images, setImages] = useState([]);

    const [title, settitle] = useState("");
    const [blogType, setblogType] = useState("");
    const [description, setdescription] = useState("");
    const [Keywords, setKeywords] = useState("");

    const [errimages, seterrImages] = useState([]);

    const [errtitle, seterrtitle] = useState(false);
    const [errblogType, seterrblogType] = useState(false);
    const [errdescription, seterrdescription] = useState(false);
    const [errKeywords, seterrKeywords] = useState(false);


    const [subcategories, setsubcategories] = useState([]);
    const [subcat, setsubcat] = useState("");
    const [selectedSubs, setselectedSubs] = useState([]);
    const [categories, setcategories] = useState([]);
    const [category, setcategory] = useState(MainId ? MainId : "");
    const [errcat, seterrcat] = useState(false);

    const [subId, setsubId] = useState(SubId ? SubId : "");
    const [sub2Id, setsub2Id] = useState(Sub2Id ? Sub2Id : "");
    const [sub3Id, setsub3Id] = useState(Sub3Id ? Sub3Id : "");

    const CategoryGet = () => {



        if (Type === "main") {

            GetMaincategory({
                privateStore: ""
            })
                .then((res) => {
                    console.log("cat>>", res.data);

                    setcategories(res.data);
                    settitle(res.data.find(({ mainId }) => mainId === category)?.maincategory)
                    // if (!product)
                    //     setcategory(res.data[0]?.mainId)
                    // setLogo(res.data[res.data.length - 1].image)
                    // this.setState({
                    //   logo: res.data[res.data.length - 1].image,
                    // });
                })
                .catch((error) => {
                    console.error(error);
                });


        } else if (Type === "sub1") {

            GetAllSubcategory1({
                marketPlace: ""
            })
                .then((res) => {
                    console.log("sub>>", res.data);

                    // setsubcategories(res.data);
                    setcategories(res.data);
                    settitle(res.data.find(({ id }) => id === subId)?.name)
                    // setsubcat(res.data[0]?.id)
                    // setLogo(res.data[res.data.length - 1].image)
                    // this.setState({
                    //   logo: res.data[res.data.length - 1].image,
                    // });
                })
                .catch((error) => {
                    console.error(error);
                });


        } else if (Type === "sub2") {

            GetAllSubcategory2({
                marketPlace: ""
            })
                .then((res) => {
                    console.log("sub>>", res.data);

                    // setsubcategories(res.data);
                    setcategories(res.data);
                    settitle(res.data.find(({ id }) => id === sub2Id)?.name)
                    // setsubcat(res.data[0]?.id)
                    // setLogo(res.data[res.data.length - 1].image)
                    // this.setState({
                    //   logo: res.data[res.data.length - 1].image,
                    // });
                })
                .catch((error) => {
                    console.error(error);
                });





        } else if (Type === "sub3") {

            GetAllSubcategory3({
                marketPlace: ""
            })
                .then((res) => {
                    console.log("sub>>", res.data);

                    // setsubcategories(res.data);
                    setcategories(res.data);
                    settitle(res.data.find(({ id }) => id === sub3Id)?.name)
                    // setsubcat(res.data[0]?.id)
                    // setLogo(res.data[res.data.length - 1].image)
                    // this.setState({
                    //   logo: res.data[res.data.length - 1].image,
                    // });
                })
                .catch((error) => {
                    console.error(error);
                });

        }

    };

    const SubCategoryGet = () => {

        if (Type === "main") {

            GetAllSubcategory1({
                marketPlace: ""
            })
                .then((res) => {
                    console.log("sub>>", res.data);

                    setsubcategories(res.data);
                    // setsubcat(res.data[0]?.id)
                    // setLogo(res.data[res.data.length - 1].image)
                    // this.setState({
                    //   logo: res.data[res.data.length - 1].image,
                    // });
                })
                .catch((error) => {
                    console.error(error);
                });

        } else if (Type === "sub1") {

            GetAllSubcategory2({
                marketPlace: ""
            })
                .then((res) => {
                    console.log("sub>>", res.data);

                    setsubcategories(res.data);
                    // setsubcat(res.data[0]?.id)
                    // setLogo(res.data[res.data.length - 1].image)
                    // this.setState({
                    //   logo: res.data[res.data.length - 1].image,
                    // });
                })
                .catch((error) => {
                    console.error(error);
                });

        } else if (Type === "sub2") {
            GetAllSubcategory3({
                marketPlace: ""
            })
                .then((res) => {
                    console.log("sub33>>", res.data);

                    setsubcategories(res.data);
                    // setsubcat(res.data[0]?.id)
                    // setLogo(res.data[res.data.length - 1].image)
                    // this.setState({
                    //   logo: res.data[res.data.length - 1].image,
                    // });
                })
                .catch((error) => {
                    console.error(error);
                });


        }

    };

    useEffect(() => {
        SubCategoryGet();
        CategoryGet();
    }, [category, subId])


    const getSubsforMain = () => {
        if (Type === "main" && category) {

            GetAllSubsforMain(category)
                .then((res) => {
                    console.log("sub cats>>", res.data);

                    const newArr = res.data?.map((m, index) => {

                        if (m.subId) {
                            return m.subId;
                        }

                    })

                    console.log("sub cats22222>>", _.compact(newArr));

                    setselectedSubs(_.compact(newArr));

                    // setusers(res.data);

                    // setLogo(res.data[res.data.length - 1].image)
                    // this.setState({
                    //   logo: res.data[res.data.length - 1].image,
                    // });
                })
                .catch((error) => {
                    console.error(error);
                    setselectedSubs([]);
                    setsubcat("");
                    // setusers([])
                });


        } else if (Type === "sub1" && category && subId) {

            GetAllSubs2forMain({ id: category, subId: subId })
                .then((res) => {
                    console.log("sub cats>>", res.data);

                    const newArr = res.data?.map((m, index) => {

                        if (m.sub2Id) {
                            return m.sub2Id;
                        }

                    })

                    console.log("sub cats22222>>", _.compact(newArr));

                    setselectedSubs(_.compact(newArr));

                    // setusers(res.data);

                    // setLogo(res.data[res.data.length - 1].image)
                    // this.setState({
                    //   logo: res.data[res.data.length - 1].image,
                    // });
                })
                .catch((error) => {
                    console.error(error);
                    setselectedSubs([]);
                    setsubcat("");
                    // setusers([])
                });


        } else if (Type === "sub2" && category && subId && sub2Id) {

            GetAllSubs3forMain({ id: category, subId: subId, sub2Id: sub2Id })
                .then((res) => {
                    console.log("sub cats3333>>", res.data);

                    const newArr = res.data?.map((m, index) => {

                        if (m.sub3Id) {
                            return m.sub3Id;
                        }

                    })

                    console.log("sub cats333>>", _.compact(newArr));

                    setselectedSubs(_.compact(newArr));

                    // setusers(res.data);

                    // setLogo(res.data[res.data.length - 1].image)
                    // this.setState({
                    //   logo: res.data[res.data.length - 1].image,
                    // });
                })
                .catch((error) => {
                    console.error(error);
                    setselectedSubs([]);
                    setsubcat("");
                    // setusers([])
                });




        }


    }

    useEffect(() => {
        getSubsforMain();

    }, [category, subId])



    const saveBlog = (e) => {

        e.preventDefault();

        if (!title) {
            seterrtitle(true);
        }



        // if (!blogType) {
        //     seterrblogType(true);
        // }

        // if (!description) {
        //     seterrdescription(true);
        // }

        // if (!Keywords) {
        //     seterrKeywords(true);
        // }
        // if (images.length === 0) {
        //     seterrImages(true);
        // }

        console.log(">>>>>>>>", _.filter(Keywords.split(" ")))

        console.log(">>>>>>", props?.type)



        let obj = {
            name: title,
            categoryType: props?.type === "1" ? "subcategory1" : props?.type === "2" ? "subcategory2" : props?.type === "3" ? "subcategory3" : "",
        }

        console.log("obj>>>>", obj)

        if (
            title
            // description &&
            // Keywords &&
            // blogType
            // images.length > 0
        ) {
            if (Type === "main") {

                EditMCategory({
                    obj: {
                        name: title,
                        // description: desc,
                    },
                    id: category,
                })
                    .then((res) => {
                        // setlopen(false);
                        // addToast("Category successfully updated ", "success");
                        settitle("");
                        setdescription("");
                        setblogType("");
                        setImages([]);
                        setKeywords([]);
                        // props?.onCloseModalQuickView();

                        // setOpenToast(true);
                        // setColor(COLORS.Primary);
                        // setAlertClass("success");
                        // setMsg("Successfully updated");
                        // getCurrentUser();
                        // seteDisable(true);
                        // getUser();

                        // history.push("/");
                        // window.location.reload();
                    })
                    .catch((error) => {
                        // setlopen(false);
                        console.log("Error", error.response);
                        // addToast(error.response.data.message, "warning");
                        // setOpenToast(true);
                        // setColor(COLORS.Orange);
                        // setAlertClass("error");
                        // setMsg(error.response.data.message);
                    })



                AddSubsForMain({
                    obj: {
                        subcategory: _.uniq(selectedSubs)
                    },
                    id: category,
                })
                    .then((res) => {
                        // getSubsforMain();
                        addToast("Category successfully updated ", "success");
                        props?.onCloseModalQuickView();

                    })
                    .catch((error) => {
                        console.log("Error", error)

                        addToast(error.response.data.message, "warning");
                    }
                    );



            } else if (Type === "sub1") {


                EditSubCategory({
                    obj: {
                        name: title,
                        categoryType: "subcategory1",
                        // description: desc,
                    },
                    id: subId,
                })
                    .then((res) => {
                        // setlopen(false);

                        settitle("");
                        setdescription("");
                        setblogType("");
                        setImages([]);
                        setKeywords([]);
                        // props?.onCloseModalQuickView();

                        // setOpenToast(true);
                        // setColor(COLORS.Primary);
                        // setAlertClass("success");
                        // setMsg("Successfully updated");
                        // getCurrentUser();
                        // seteDisable(true);
                        // getUser();

                        // history.push("/");
                        // window.location.reload();
                    })
                    .catch((error) => {
                        // setlopen(false);
                        console.log("Error", error.response);
                        // addToast(error.response.data.message, "warning");
                        // setOpenToast(true);
                        // setColor(COLORS.Orange);
                        // setAlertClass("error");
                        // setMsg(error.response.data.message);
                    })



                AddSubsForSubId({
                    obj: {
                        subcategory2: _.uniq(selectedSubs),
                    },
                    id: category,
                    subId: subId
                })
                    .then((res) => {
                        // getSubsforMain();
                        addToast("Category successfully updated ", "success");
                        props?.onCloseModalQuickView();

                    })
                    .catch((error) => {
                        console.log("Error", error)

                        addToast(error.response.data.message, "warning");
                    }
                    );



            } else if (Type === "sub2") {


                EditSubCategory({
                    obj: {
                        name: title,
                        categoryType: "subcategory2",
                        // description: desc,
                    },
                    id: sub2Id,
                })
                    .then((res) => {
                        // setlopen(false);

                        settitle("");
                        setdescription("");
                        setblogType("");
                        setImages([]);
                        setKeywords([]);


                        // setOpenToast(true);
                        // setColor(COLORS.Primary);
                        // setAlertClass("success");
                        // setMsg("Successfully updated");
                        // getCurrentUser();
                        // seteDisable(true);
                        // getUser();

                        // history.push("/");
                        // window.location.reload();
                    })
                    .catch((error) => {
                        // setlopen(false);
                        console.log("Error", error.response);
                        // addToast(error.response.data.message, "warning");
                        // setOpenToast(true);
                        // setColor(COLORS.Orange);
                        // setAlertClass("error");
                        // setMsg(error.response.data.message);
                    })



                AddSubsForSub2Id({
                    obj: {
                        subcategory3: _.uniq(selectedSubs),
                    },
                    id: category,
                    subId: subId,
                    sub2Id: sub2Id,
                })
                    .then((res) => {
                        // getSubsforMain();
                        addToast("Category successfully updated ", "success");
                        props?.onCloseModalQuickView();

                    })
                    .catch((error) => {
                        console.log("Error", error)

                        addToast(error.response.data.message, "warning");
                    }
                    );



            } else if (Type === "sub3") {


                EditSubCategory({
                    obj: {
                        name: title,
                        categoryType: "subcategory3",
                        // description: desc,
                    },
                    id: sub3Id,
                })
                    .then((res) => {
                        // setlopen(false);

                        settitle("");
                        setdescription("");
                        setblogType("");
                        setImages([]);
                        setKeywords([]);
                        addToast("Category successfully updated ", "success");
                        props?.onCloseModalQuickView();



                        // setOpenToast(true);
                        // setColor(COLORS.Primary);
                        // setAlertClass("success");
                        // setMsg("Successfully updated");
                        // getCurrentUser();
                        // seteDisable(true);
                        // getUser();

                        // history.push("/");
                        // window.location.reload();
                    })
                    .catch((error) => {
                        // setlopen(false);
                        console.log("Error", error.response);
                        addToast(error.response.data.message, "warning");
                        // setOpenToast(true);
                        // setColor(COLORS.Orange);
                        // setAlertClass("error");
                        // setMsg(error.response.data.message);
                    })



            }


        }


    }




    return (
        <>
            <div className="flex justify-center w-full mt-2">

                <span className="block text-xl font-bold flex justify-center w-full text-center">
                    Edit Category
                </span>


            </div>
            <div className="w-full flex justify-center px-3">

                <div className="w-full md:w-11/12 lg:w-8/12">

                    <div className="w-full mt-6 space-y-4">

                        {
                            Type === "main" &&

                            <div>
                                <Label className="text-sm">Category</Label>
                                <div className="flex w-full items-center mt-1.5">
                                    <div className="w-full">
                                        <Autocomplete
                                            className=""
                                            // className="form-group"
                                            // className="border-neutral-200"
                                            defaultValue={

                                                Type === "main" ?
                                                    { maincategory: categories.find(({ mainId }) => mainId === category)?.maincategory ? categories.find(({ mainId }) => mainId === category)?.maincategory : "" }

                                                    : Type === "sub1" ?

                                                        { name: categories.find(({ id }) => id === subId)?.name ? categories.find(({ id }) => id === subId)?.name : "" }
                                                        :

                                                        ""

                                            }
                                            value={Type === "main" ?
                                                { maincategory: categories.find(({ mainId }) => mainId === category)?.maincategory ? categories.find(({ mainId }) => mainId === category)?.maincategory : "" }

                                                : Type === "sub1" ?

                                                    { name: categories.find(({ id }) => id === subId)?.name ? categories.find(({ id }) => id === subId)?.name : "" }
                                                    :

                                                    ""}
                                            options={categories}
                                            getOptionLabel={(option) => Type === "main" ? option.maincategory : Type === "sub1" ? option.name : ""}
                                            onChange={(event, newValue) => {

                                                if (newValue !== null) {
                                                    if (Type === "main")
                                                        setcategory(newValue?.mainId)

                                                    else if (Type === "sub1")
                                                        setsubId(newValue?.id)
                                                    seterrcat(false);
                                                } else {
                                                    setcategory("");
                                                    setsubId("");
                                                    seterrcat(false);
                                                }

                                                setselectedSubs([])

                                            }}
                                            // sx={{
                                            //   // width: "80vw",
                                            //   background:"transparent",
                                            //   borderRadius: "20px",
                                            //   // border: "none",
                                            //   // borderColor: "transparent",

                                            //   "& .MuiInputBase-input": {
                                            //     height: 15,
                                            //     // border: "none",
                                            //     fontSize: "14px",
                                            //     fontFamily: "'Avenir', sans-serif",
                                            //     // borderColor: "transparent",
                                            //   },
                                            //   //view
                                            //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                            //     // borderColor: "transparent",
                                            //   },

                                            //   "&:hover .MuiOutlinedInput-input": {
                                            //     color: "black",
                                            //   },
                                            //   "&:hover .MuiInputLabel-root": {
                                            //     color: COLORS.GrayBackground,
                                            //   },
                                            //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                            //   {
                                            //     // borderColor: "transparent",
                                            //   },
                                            //   ".MuiAutocomplete-root fieldset:hover": {
                                            //     color: "transparent",
                                            //     // borderColor: "transparent",
                                            //   },
                                            // }}
                                            renderInput={(params) => (
                                                <div ref={params.InputProps.ref}>
                                                    <Input {...params.inputProps} className="" placeholder="Select Category" />
                                                </div>
                                            )}
                                            ListboxProps={{
                                                style: {
                                                    maxHeight: "150px",
                                                    fontSize: "14px",
                                                    // border: '1px solid white'
                                                },
                                            }}
                                        />
                                    </div>


                                </div>

                                {false && (
                                    <div className="text-red-600 text-sm text-right mt-2">
                                        This field is required
                                    </div>
                                )}
                            </div>


                        }



                        <div>
                            <Label className="text-sm">Name</Label>
                            <Input className="mt-1.5"
                                onChange={(e) => {
                                    settitle(e.target.value);
                                    seterrtitle(false);
                                }}
                                value={title}
                            />
                            {errtitle && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>

                        {
                            Type != "sub3" &&


                            <div>
                                <div>
                                    <span className="flex mt-4 text-sm">Select Sub Categories</span>
                                    <Autocomplete
                                        // multiple
                                        size="small"
                                        className="mt-1.5"
                                        // className="form-group"
                                        // className="border-neutral-200"
                                        // value={selectedSizes}
                                        defaultValue={{ name: subcategories.find(({ id }) => id === subcat)?.name ? subcategories.find(({ id }) => id === subcat)?.name : "" }}
                                        value={{ name: subcategories.find(({ id }) => id === subcat)?.name ? subcategories.find(({ id }) => id === subcat)?.name : "" }}
                                        options={subcategories}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, newValue) => {

                                            if (newValue !== null) {
                                                const index = selectedSubs.indexOf(newValue?.id);

                                                if (index < 0) {
                                                    setselectedSubs(selectedSubs.concat(newValue?.id));
                                                }


                                                // setsubcat(newValue?.id)
                                                // seterrcat(false);
                                            } else {
                                                // setselectedSubs([]);
                                                // setsubcat("");
                                                // seterrcat(false);
                                            }

                                        }}
                                        sx={{
                                            // width: "80vw",
                                            background: "transparent",
                                            borderRadius: "20px",
                                            // border: "none",
                                            // borderColor: "transparent",


                                            "& .MuiInputBase-input": {
                                                height: 15,
                                                // border: "1px #873e23",
                                                fontSize: "14px",
                                                fontFamily: "'Avenir', sans-serif",
                                                // borderColor: "#873e23",
                                                borderColor: "#d4d4d4",


                                            },



                                            //view
                                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                                // borderColor: "transparent",
                                                borderColor: "#d4d4d4",
                                            },

                                            "&:hover .MuiOutlinedInput-input": {
                                                color: "black",
                                                borderColor: "#d4d4d4",
                                            },
                                            "&:hover .MuiInputLabel-root": {
                                                color: COLORS.GrayBackground,
                                                borderColor: "#d4d4d4",
                                            },
                                            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                            {
                                                borderColor: "#d4d4d4",
                                            },
                                            ".MuiAutocomplete-root fieldset:hover": {
                                                color: "transparent",
                                                borderColor: "transparent",
                                            },
                                        }}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                                <Input {...params.inputProps} className="" placeholder="Select Sub Categories" />
                                            </div>
                                        )}
                                        // renderInput={(params) => (
                                        //     <TextField
                                        //         {...params}

                                        //     />

                                        // )}
                                        ListboxProps={{
                                            style: {
                                                maxHeight: "150px",
                                                fontSize: "14px",
                                                // border: '1px solid white'
                                            },
                                        }}
                                    />
                                    {false && (
                                        <div className="text-red-600 text-sm text-right mt-2">
                                            This field is required
                                        </div>
                                    )}
                                </div>
                                <div className="flex flex-wrap mt-4">

                                    {
                                        selectedSubs.map((item, index) => {
                                            return (
                                                <>

                                                    <div className="bg-[#e2e8f0] w-fit p-2 flex mr-1 mt-2">

                                                        <span>{subcategories.find(({ id }) => id === item)?.name}</span>

                                                        <div className="flex items-center ml-3 cursor-pointer"
                                                            onClick={(e) => {
                                                                var array = [...selectedSubs]
                                                                array.splice(index, 1);
                                                                setselectedSubs([...array])
                                                            }}
                                                        >
                                                            <i className="las la-times text-base text-center  flex items-center justify-center"></i>

                                                        </div>

                                                    </div>
                                                </>
                                            )
                                        })
                                    }


                                </div>
                            </div>




                        }



                        <div className="flex w-full items-center justify-center">
                            <button
                                className={
                                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-4 `}

                                onClick={saveBlog}
                            >
                                {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                                <span className="block  text-sm font-medium "
                                >
                                    Save
                                </span>

                            </button>
                        </div>

                    </div>

                </div>
            </div>

        </>
    )

}

export default Blogs;