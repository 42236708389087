import React, { useContext } from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo.svg";
import logoLightImg from "images/logo-light.svg";
import { getLogo } from "Services/API/Get";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import logoNew from "images/2.png";
import logoBlack from "images/logoBlack.png";
import logoFooter from "images/1.png"
import StepsContext from "Context/StepsContext";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
  footer?: any;
  sideMenu?: any;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "flex-shrink-0",
  footer = "",
  sideMenu = false
}) => {
  const [logo, setLogo] = React.useState("");

  const { selTab, setselTab } = useContext(StepsContext);

  const logoGet = () => {
    getLogo()
      .then((res) => {
        console.log(res.data);
        setLogo(res.data[res.data.length - 1].image)
        // this.setState({
        //   logo: res.data[res.data.length - 1].image,
        // });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  React.useEffect(() => {
    logoGet();


  }, []);
  return (
    <>
      {
        sideMenu ?

          <>
            {/* <Link
              to="/"
              className={`ttnc-logo inline-block text-slate-600 ${className}`}
            > */}
            {/* THIS USE FOR MY CLIENT */}
            {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
            {img ? (
              <img
                className={`block max-h-14  ${footer ? "sm:max-h-32 -ml-6" : "sm:max-h-16"}  ${imgLight ? "dark:hidden" : ""
                  }`}
                // src= {`${IMAGE_SERVER_URL_DEV}${logo}`}
                src={footer ? logoFooter : logoNew}
                alt="Logo"

                onClick={() => {
                  setselTab("/")
                }}

              />
            ) : (
              "Logo Here"
            )}
            {imgLight && (
              <img
                className="hidden max-h-8 sm:max-h-10 dark:block"
                // src={imgLight}
                // src= {`${IMAGE_SERVER_URL_DEV}${logo}`}
                src={footer ? logoFooter : logoNew}

                alt="Logo-Light"

                onClick={() => {
                  setselTab("/")
                }}
              />
            )}
            {/* </Link> */}
          </>

          :

          <>
            <Link
              to="/"
              className={`ttnc-logo inline-block text-slate-600 ${className}`}
            >
              {/* THIS USE FOR MY CLIENT */}
              {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
              {img ? (
                <img
                  className={`block max-h-14  ${footer ? "max-h-[75px] lg:max-h-32 -ml-0" : "sm:max-h-[65px]"}  ${imgLight ? "dark:hidden" : ""
                    }`}
                  // src= {`${IMAGE_SERVER_URL_DEV}${logo}`}
                  src={footer ? logoFooter : logoNew}
                  alt="Logo"
                  onClick={() => {
                    setselTab("/")
                  }}
                />
              ) : (
                "Logo Here"
              )}
              {imgLight && (
                <img
                  className="hidden max-h-8 sm:max-h-10 dark:block"
                  // src={imgLight}
                  // src= {`${IMAGE_SERVER_URL_DEV}${logo}`}
                  src={footer ? logoFooter : logoNew}

                  alt="Logo-Light"
                  onClick={() => {
                    setselTab("/")
                  }}
                />
              )}
            </Link>

          </>
      }

    </>

  );
};

export default Logo;
