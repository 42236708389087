import React, { FC, useState, useEffect, useRef } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL } from "Constant/System";


const Listing = ({ modal = false }) => {

    const [images, setImages] = useState([]);

    const [category, setcategory] = useState("Livestock")

    return (
        <>
            <div className={`flex justify-center w-full ${modal ? "mt-2" : "mt-12"}`}>

                <span className="block text-xl font-bold flex justify-center w-full text-center">
                    Create Listing
                </span>


            </div>
            <div className="w-full flex justify-center px-3">

                <div className={`w-full ${modal ? "md:w-11/12 lg:w-8/12" : "md:w-9/12 lg:w-7/12 xl:w-[45%]"} `}>

                    <div className="w-full mt-6 space-y-4">
                        <div>
                            <Label className="text-sm">Listing Type</Label>
                            <Select className="mt-1.5"
                            // onChange={(e) => {
                            //     setcountry(e.target.value);
                            //     seterrcountry(false);
                            // }}
                            // value={country}
                            >
                                <option value={""}>Select a listing type</option>
                                <option value="Sell">Sell</option>
                                <option value="Rent">Rent</option>
                                <option value="Wanted">Wanted</option>
                                <option value="Auction">Auction</option>
                                {/* <option value="United States">Canada</option>
                    <option value="United States">Mexico</option>
                    <option value="United States">Israel</option>
                    <option value="United States">France</option>
                    <option value="United States">England</option>
                    <option value="United States">Laos</option>
                    <option value="United States">China</option> */}
                            </Select>
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">Category</Label>
                            <Select className="mt-1.5"
                                onChange={(e) => {
                                    setcategory(e.target.value);
                                    // seterrcountry(false);
                                }}
                                value={category}
                            >
                                <option value={""}>Select a category</option>
                                <option value="Livestock">Livestock</option>
                                <option value="Machinery">Machinery & Equipment</option>
                                <option value="Other">Other</option>
                                {/* <option value="United States">Canada</option>
                    <option value="United States">Mexico</option>
                    <option value="United States">Israel</option>
                    <option value="United States">France</option>
                    <option value="United States">England</option>
                    <option value="United States">Laos</option>
                    <option value="United States">China</option> */}
                            </Select>
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">Sub Category</Label>
                            <Select className="mt-1.5"
                            // onChange={(e) => {
                            //     setcountry(e.target.value);
                            //     seterrcountry(false);
                            // }}
                            // value={country}
                            >
                                <option value={""}>Select a sub category</option>
                                <option value="Livestock">Livestock</option>
                                <option value="Machinery">Machinery & Equipment</option>
                                <option value="Other">Other</option>
                                {/* <option value="United States">Canada</option>
                    <option value="United States">Mexico</option>
                    <option value="United States">Israel</option>
                    <option value="United States">France</option>
                    <option value="United States">England</option>
                    <option value="United States">Laos</option>
                    <option value="United States">China</option> */}
                            </Select>
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">Sub Category 2</Label>
                            <Select className="mt-1.5"
                            // onChange={(e) => {
                            //     setcountry(e.target.value);
                            //     seterrcountry(false);
                            // }}
                            // value={country}
                            >
                                <option value={""}>Select a sub category 2</option>
                                <option value="Livestock">Livestock</option>
                                <option value="Machinery">Machinery & Equipment</option>
                                <option value="Other">Other</option>
                                {/* <option value="United States">Canada</option>
                    <option value="United States">Mexico</option>
                    <option value="United States">Israel</option>
                    <option value="United States">France</option>
                    <option value="United States">England</option>
                    <option value="United States">Laos</option>
                    <option value="United States">China</option> */}
                            </Select>
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        {
                            category === "Livestock" ?
                                <>
                                    <div>
                                        <Label className="text-sm">Breeds</Label>
                                        <Input className="mt-1.5"
                                        // onChange={(e) => {
                                        //     setlasttName(e.target.value);
                                        //     seterrlasttName(false);
                                        // }}
                                        // value={lastName}
                                        />
                                        {false && (
                                            <div className="text-red-600 text-sm text-right mt-2">
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <Label className="text-sm">Age</Label>
                                        <Input className="mt-1.5"
                                        // onChange={(e) => {
                                        //     setlasttName(e.target.value);
                                        //     seterrlasttName(false);
                                        // }}
                                        // value={lastName}
                                        />
                                        {false && (
                                            <div className="text-red-600 text-sm text-right mt-2">
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <Label className="text-sm">Weight in Kg</Label>
                                        <Input className="mt-1.5"
                                        // onChange={(e) => {
                                        //     setlasttName(e.target.value);
                                        //     seterrlasttName(false);
                                        // }}
                                        // value={lastName}
                                        />
                                        {false && (
                                            <div className="text-red-600 text-sm text-right mt-2">
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <Label className="text-sm">Head in cm</Label>
                                        <Input className="mt-1.5"
                                        // onChange={(e) => {
                                        //     setlasttName(e.target.value);
                                        //     seterrlasttName(false);
                                        // }}
                                        // value={lastName}
                                        />
                                        {false && (
                                            <div className="text-red-600 text-sm text-right mt-2">
                                                This field is required
                                            </div>
                                        )}
                                    </div>

                                </>
                                : category === "Machinery" ?

                                    <>
                                        <div>
                                            <Label className="text-sm">Make</Label>
                                            <Input className="mt-1.5"
                                            // onChange={(e) => {
                                            //     setlasttName(e.target.value);
                                            //     seterrlasttName(false);
                                            // }}
                                            // value={lastName}
                                            />
                                            {false && (
                                                <div className="text-red-600 text-sm text-right mt-2">
                                                    This field is required
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <Label className="text-sm">Model</Label>
                                            <Input className="mt-1.5"
                                            // onChange={(e) => {
                                            //     setlasttName(e.target.value);
                                            //     seterrlasttName(false);
                                            // }}
                                            // value={lastName}
                                            />
                                            {false && (
                                                <div className="text-red-600 text-sm text-right mt-2">
                                                    This field is required
                                                </div>
                                            )}
                                        </div>

                                        <div>
                                            <Label className="text-sm">New or Used</Label>
                                            <Select className="mt-1.5"
                                            // onChange={(e) => {
                                            //     setcountry(e.target.value);
                                            //     seterrcountry(false);
                                            // }}
                                            // value={country}
                                            >
                                                <option value={"new"}>New</option>
                                                <option value="used">Used</option>
                                                {/* <option value="United States">Canada</option>
                    <option value="United States">Mexico</option>
                    <option value="United States">Israel</option>
                    <option value="United States">France</option>
                    <option value="United States">England</option>
                    <option value="United States">Laos</option>
                    <option value="United States">China</option> */}
                                            </Select>
                                            {false && (
                                                <div className="text-red-600 text-sm text-right mt-2">
                                                    This field is required
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <Label className="text-sm">Country</Label>
                                            <Select className="mt-1.5"
                                            // onChange={(e) => {
                                            //     setcountry(e.target.value);
                                            //     seterrcountry(false);
                                            // }}
                                            // value={country}
                                            >
                                                <option value={""}>Select a country</option>
                                                <option value="Australia">Australia</option>
                                                {/* <option value="United States">Canada</option>
                    <option value="United States">Mexico</option>
                    <option value="United States">Israel</option>
                    <option value="United States">France</option>
                    <option value="United States">England</option>
                    <option value="United States">Laos</option>
                    <option value="United States">China</option> */}
                                            </Select>
                                            {false && (
                                                <div className="text-red-600 text-sm text-right mt-2">
                                                    This field is required
                                                </div>
                                            )}
                                        </div>
                                    </>
                                    :
                                    <>
                                    </>
                        }


                        <div>
                            <Label className="text-sm">State</Label>
                            <Select className="mt-1.5"
                            // onChange={(e) => {
                            //     setcountry(e.target.value);
                            //     seterrcountry(false);
                            // }}
                            // value={country}
                            >
                                <option value={""}>Select a state</option>
                                <option value="Australia">Australia</option>
                                {/* <option value="United States">Canada</option>
                    <option value="United States">Mexico</option>
                    <option value="United States">Israel</option>
                    <option value="United States">France</option>
                    <option value="United States">England</option>
                    <option value="United States">Laos</option>
                    <option value="United States">China</option> */}
                            </Select>
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">Suburb</Label>
                            <Select className="mt-1.5"
                            // onChange={(e) => {
                            //     setcountry(e.target.value);
                            //     seterrcountry(false);
                            // }}
                            // value={country}
                            >
                                <option value={""}>Select a Suburb</option>
                                <option value="Australia">Australia</option>
                                {/* <option value="United States">Canada</option>
                    <option value="United States">Mexico</option>
                    <option value="United States">Israel</option>
                    <option value="United States">France</option>
                    <option value="United States">England</option>
                    <option value="United States">Laos</option>
                    <option value="United States">China</option> */}
                            </Select>
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">Postcode</Label>
                            <Input className="mt-1.5"
                            // onChange={(e) => {
                            //     setlasttName(e.target.value);
                            //     seterrlasttName(false);
                            // }}
                            // value={lastName}
                            />
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">Ad Title</Label>
                            <Input className="mt-1.5"
                            // onChange={(e) => {
                            //     setlasttName(e.target.value);
                            //     seterrlasttName(false);
                            // }}
                            // value={lastName}
                            />
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">Description</Label>
                            <Textarea className="mt-1.5" rows={8}
                            // onChange={(e) => {
                            //     setlasttName(e.target.value);
                            //     seterrlasttName(false);
                            // }}
                            // value={lastName}
                            />
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">Seller Type</Label>
                            <Select className="mt-1.5"
                            // onChange={(e) => {
                            //     setcountry(e.target.value);
                            //     seterrcountry(false);
                            // }}
                            // value={country}
                            >
                                <option value={""}>Select a Suburb</option>
                                <option value="Australia">Australia</option>
                                {/* <option value="United States">Canada</option>
                    <option value="United States">Mexico</option>
                    <option value="United States">Israel</option>
                    <option value="United States">France</option>
                    <option value="United States">England</option>
                    <option value="United States">Laos</option>
                    <option value="United States">China</option> */}
                            </Select>
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        {
                            category !== "Livestock" && category !== "Machinery" &&

                            <div>
                                <Label className="text-sm">Minimum Order Quantity</Label>
                                <Input className="mt-1.5"
                                // onChange={(e) => {
                                //     setlasttName(e.target.value);
                                //     seterrlasttName(false);
                                // }}
                                // value={lastName}
                                />
                                {false && (
                                    <div className="text-red-600 text-sm text-right mt-2">
                                        This field is required
                                    </div>
                                )}
                            </div>
                        }
                        <div>
                            <Label className="text-sm">Price (Inc. all taxes)</Label>
                            <div className="w-full flex">
                                <Select className="mt-1.5 w-4/12" rounded="rounded-l-lg"
                                // onChange={(e) => {
                                //     setcountry(e.target.value);
                                //     seterrcountry(false);
                                // }}
                                // value={country}
                                >
                                    <option value={""}>Currency</option>
                                    <option value="AUD">AUD</option>

                                </Select>
                                <Input className="mt-1.5" rounded="rounded-r-lg"
                                // onChange={(e) => {
                                //     setlasttName(e.target.value);
                                //     seterrlasttName(false);
                                // }}
                                // value={lastName}
                                />

                            </div>

                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>


                        <div>
                            <Label className="text-sm">Type Keywords</Label>
                            <Input className="mt-1.5"
                                placeholder="#plumber #farmingjobs"

                            // onChange={(e) => {
                            //     setlasttName(e.target.value);
                            //     seterrlasttName(false);
                            // }}
                            // value={lastName}
                            />
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div className="w-full mt-3 xl:mt-6 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                            <div className="grid grid-cols-10 gap-5 pl-4 pr-5">
                                {images?.map((faker, fakerKey) => (
                                    <>
                                        <div
                                            // key={fakerKey}
                                            className="col-span-5 md:col-span-2 h-28 relative image-fit cursor-pointer zoom-in"
                                        >
                                            <img
                                                className="rounded-md"
                                                alt="Midone - HTML Admin Template"
                                                src={faker.path.includes('/uploads/products/') === true ? IMAGE_SERVER_URL + faker?.path : faker?.path}
                                            />
                                            <div
                                                className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 -mr-2 -mt-2"
                                            >
                                                <div
                                                    onClick={(e) => {

                                                        var array = [...images]
                                                        array.splice(fakerKey, 1);
                                                        setImages(array)

                                                    }}
                                                >
                                                    {/* <Lucide icon="X" className="w-4 h-4" /> */}
                                                    <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                                </div>
                                            </div>


                                        </div>
                                        {/* <div className="form-check">
                                            <input id="checkbox-switch-" className="form-check-input w-5 h-5 bg-gray-200 relative rounded-full text-white right-10 top-[80px]" type="checkbox"
                                                checked={faker?.thumbnail}

                                                onChange={(e) => {
                                                    var array = [...images]
                                                    const index = _.map(array, "thumbnail").indexOf(true);

                                                    // console.log("index>>>", index)

                                                    if (index < 0) {
                                                        array[fakerKey].thumbnail = e.target.checked;
                                                        setImages(array)
                                                    } else {
                                                        array[index].thumbnail = false;
                                                        array[fakerKey].thumbnail = e.target.checked;
                                                        setImages(array)
                                                    }
                                                    // array.filter(person => person.thumbnail === true)
                                                    // array[fakerKey].thumbnail = e.target.checked;
                                                    // setImages(array)
                                                    // console.log("<<<<<",e.target.checked)
                                                    // setlatest(e.target.checked)
                                                    // onChangeUserArr(e, arr.privilege)
                                                    // this.onChangeAllArr(e, arr.privilege);
                                                }}
                                            />
                                           


                                        </div> */}
                                    </>

                                ))}
                            </div>
                            <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative">
                                {/* <Lucide icon="Image" className="w-4 h-4 mr-2" /> */}
                                <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                                <span className="text-primary mr-1">
                                    Upload images
                                </span>{" "}
                                or drag and drop
                                <input
                                    id="horizontal-form-1"
                                    type="file"
                                    className="w-full h-full top-0 left-0 absolute opacity-0"
                                    onChange={(e) => {

                                        let file = e.target.files[0];

                                        let reader = new FileReader();
                                        reader.readAsDataURL(file);
                                        let fileInp = e.target.files[0];
                                        let baseURL;
                                        // on reader load somthing...
                                        reader.onload = () => {
                                            // Make a fileInfo Object
                                            baseURL = reader.result;
                                            let obj =
                                            {
                                                path: baseURL,
                                                thumbnail: false,
                                            }
                                                ;

                                            setImages(images.concat(obj))


                                        };
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex w-full items-center justify-center">
                            <button
                                className={
                                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-gradient-to-r from-cyan-500 from-10% to-[#3c9140] to-90% hover:from-pink-500 hover:to-yellow-500 
        w-full h-9 text-slate-50 font-medium mt-4 `}


                            >
                                {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                                <span className="block  text-sm font-medium "
                                >
                                    Save
                                </span>

                            </button>
                        </div>

                    </div>

                </div>
            </div>

        </>
    )

}

export default Listing;