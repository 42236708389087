import React, { FC, useState, useEffect, useRef, useContext } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { makeStyles } from "@mui/styles";
import { Button, Grid, TextField, Typography, Autocomplete } from "@mui/material";
import { FONTS_INTER } from "Constant";
import { COLORS } from "Constant/Colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ModalQuickView from "components/ModalFormular";
import { CurrentUser, getCities, getDistricts } from "Services/API/Get";
import AuthContext from "Context/AuthContext";
import { EditUserBilling, EditUserQuery } from "Services/API/Put";
import { upperFirst, lowerCase } from 'lodash';
import { addToast } from "shared/Toast";
import ShippingFormular from "./ShippingFormular";

const useStyles = makeStyles((theme) => ({
    "@global": {
        '.MuiAutocomplete-option': {
            width: "100%"
        },
    },
    root: {
        "& .MuiOutlinedInput-root": {
            padding: 7,
        },
    },
    inputRoot: {
        '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            padding: "0px",
            fontFamily: FONTS_INTER,
            fontSize: "10.5pt",

            color: COLORS.TextColor2,
            fontWeight: 400,
            outline: "none",
            border: "none",

            height: "1.6rem",
        },
        '&[class*="MuiOutlinedInput-root"] .Mui-disabled': {
            opacity: 1,
            WebkitTextFillColor: "rgba(0, 0, 0, 0.68)",
            fontWeight: "400",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",

            background: "none",
        },
    },
}));

const Billing = ({ getUser }) => {

    const theme = createTheme({
        palette: {
            primary: {
                main: COLORS.PrimaryBackground,
            },
            secondary: {
                main: COLORS.PrimaryBackground,
            },
        },
    });

    const classes = useStyles();


    const { user, setUser } = useContext(AuthContext);

    const [firstName, setfirstName] = useState("");
    const [lastName, setlasttName] = useState("");
    const [mobile, setmobile] = useState("");
    const [email, setemail] = useState("");
    const [premail, setpremail] = useState("");
    const [lopen, setlopen] = React.useState(false);

    const [homeNo, sethomeNo] = useState("");
    const [homeAddress, sethomeAddress] = useState("");
    const [homeDistrict, sethomeDistrict] = useState("");
    const [homeCity, sethomeCity] = useState("");
    const [homepostCode, sethomepostCode] = useState("");

    const [errhomeDistrict, seterrhomeDistrict] = useState(false);
    const [errhomeCity, seterrhomeCity] = useState(false);
    const [errhomepostCode, seterrhomepostCode] = useState(false);
    const [errhomeAddress, seterrhomeAddress] = useState(false);

    const [homeDistrictId, sethomeDistrictId] = useState(0);
    const [allHCities, setallHCities] = useState([]);
    const [allHDistricts, setallHDistricts] = useState([]);

    const [password, setPassword] = useState("");
    const [repassword, setrePassword] = useState("");
    const [idisable, setidisable] = useState(true);
    const [errfirstName, seterrfirstName] = useState(false);
    const [errlastName, seterrlasttName] = useState(false);
    const [errmobile, seterrmobile] = useState("");
    const [erremail, seterremail] = useState(false);

    const [errPass, seterrPass] = useState(false);
    const [errRePass, seterrRePass] = useState("");

    const [userId, setuserId] = useState("");
    const [currUser, setcurrUser] = useState("");
    const [eDisable, seteDisable] = useState(true);
    const [eDisable2, seteDisable2] = useState(true);

    const [selectedTab, setselectedTab] = useState(1);

    const [co, setco] = useState("");



    const [subject, setsubject] = useState("");

    const [message, setmessage] = useState("");


    const [errsubject, seterrsubject] = useState(false);
    const [errmessage, seterrmessage] = useState(false);

    const [showModalQuickView, setShowModalQuickView] = useState(false)

    const getCurrentUser = () => {
        CurrentUser()
            .then((res) => {
                if (res.statusCode === 200) {
                    const cData = res.data[0];
                    setUser(cData);
                    const obj = {
                        id: cData.id,
                        email: cData?.email,
                        firstName: cData?.firstName,
                        lastName: cData?.lastName,
                        phone: cData?.mobileNumber,
                    };
                    //   this.setState({
                    //     firstName: cData?.firstName,
                    //     lastName: cData?.lastName,
                    //     email: cData?.email,
                    //     tele: cData?.phone,
                    //   });
                    console.log("<><><><>", cData);
                    setcurrUser(cData);
                    // setName(cData?.firstName + " " + cData?.lastName);
                    setfirstName(cData?.firstName);
                    setlasttName(cData?.lastName);
                    setemail(cData?.email);
                    setpremail(cData?.email);
                    setmobile(cData?.contactNumber);
                    sethomeDistrict(cData?.homeDistrict !== null ? cData?.homeDistrict : "");
                    sethomeAddress(cData?.homeAddress !== null ? cData?.homeAddress : "");
                    sethomeCity(cData?.homeCity !== null ? cData?.homeCity : "");
                    sethomeNo(cData?.homeNumber !== null ? cData?.homeNumber : "");
                    sethomepostCode(cData?.homepostCode !== null ? cData?.homepostCode : "");
                    setuserId(cData?.id);

                    getDistricts()
                        .then((res) => {

                            var mobile22 = res.data?.filter(function (el) {
                                return el.name_en === cData?.homeDistrict;
                            });

                            sethomeDistrictId(mobile22.length > 0 ? mobile22[0]?.id : 0)

                            // setallODistricts(res.data);
                        })
                        .catch((error) => {
                            console.log("Error", error.response);
                            // setOpenToast(true);
                            // setColor(COLORS.Orange);
                            // setAlertClass("error");
                            // setMsg(error.response.data.message);
                        });



                    localStorage.setItem(
                        `${window.location.hostname}.profile`,
                        JSON.stringify(obj)
                    );
                }
            })
            .catch((error) => {
                console.log("error", error);
                console.log("current user error", error.response.data.message);
            });
    };

    const onSubmit2 = async (e) => {

        const phoneRegex =
            /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

        e.preventDefault();

        if (homeAddress === "") {
            seterrhomeAddress(true);
        }
        if (homeCity === "") {
            seterrhomeCity(true);
        }
        if (homeDistrict === "") {
            seterrhomeDistrict(true);
        }
        if (homepostCode === "") {
            seterrhomepostCode(true);
        }

        // if (password === "") {
        //   seterrPass(true)

        // }

        // if (repassword === "") {
        //   seterrRePass("This field is required");

        // }

        // if (password !== "") {
        //   //   seterrPass(true)
        //   if (repassword === "") {
        //     seterrRePass("This field is required");
        //   } else {
        //     seterrRePass("");
        //   }
        // }





        // if(password != "" && repassword != "" && !(password === repassword)){
        //   seterrRePass("Password Mismatch")
        // }
        // if(password != "" && repassword != "" && (password === repassword)){
        //   seterrRePass("")
        // }

        let obj = {
            firstName: upperFirst(lowerCase(currUser.firstName)),
            lastName: upperFirst(lowerCase(currUser.lastName)),
            contactNumber: currUser.mobileNumber,
            email: currUser.email,
            homeNumber: currUser.homeNumber === null ? "" : currUser.homeNumber,
            homeAddress: homeAddress,
            homeCity: homeCity,
            password: "",

            homeDistrict: homeDistrict,
            homepostCode: homepostCode,
            note: currUser.note === null ? "" : currUser.note,

        };
        console.log("obj>>>>", obj);

        if (

            homeAddress !== "" &&
            homeCity !== "" &&
            homeDistrict !== "" &&
            homepostCode !== ""
            // (password !== "" ? repassword !== "" : false)


        ) {
            setlopen(true);

            EditUserBilling(obj)
                .then((res) => {
                    setlopen(false);
                    addToast("Successfully updated", "success");
                    setPassword("");
                    setrePassword("");
                    // setOpenToast(true);
                    // setColor(COLORS.Primary);
                    // setAlertClass("success");
                    // setMsg("Successfully updated");
                    getCurrentUser();
                    getUser();
                    seteDisable2(true);
                    // seteDisable(true);

                    // history.push("/");
                    // window.location.reload();
                })
                .catch((error) => {
                    setlopen(false);
                    console.log("Error", error.response);
                    addToast(error.response.data.message, "warning");
                    // setOpenToast(true);
                    // setColor(COLORS.Orange);
                    // setAlertClass("error");
                    // setMsg(error.response.data.message);
                })


        } else {
            // scrollElement();
        }
    };
    React.useEffect(() => {
        getCurrentUser();
        listDistricts();

    }, []);

    React.useEffect(() => {
        if (homeDistrictId !== 0) {
            listHomecities();
        }
    }, [homeDistrictId]);

    const listDistricts = () => {
        getDistricts()
            .then((res) => {
                console.log("districts>>>", res);
                setallHDistricts(res.data);
                // setallODistricts(res.data);
            })
            .catch((error) => {
                console.log("Error", error.response);
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });
    };
    const listHomecities = () => {
        getCities(homeDistrict)
            .then((res) => {
                console.log("cities>>>", res);
                setallHCities(res.data.records);
            })
            .catch((error) => {
                console.log("Error", error.response);
                setallHCities([])
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });
    };

    const handleChangeHdist = (e, value) => {
        if (value !== null) {
            sethomeDistrict(value.name_en);
            seterrhomeDistrict(false);
            sethomeDistrictId(value.id);
        } else {
            sethomeDistrict("");
            sethomeDistrictId(1);
        }

        console.log("state>>", value);
    };
    const handleChangeHcity = (e, value) => {
        if (value !== null) {
            sethomeCity(value.fields.scc_name);
            sethomepostCode(value.fields.scc_code);
            seterrhomeCity(false);
            seterrhomepostCode(false);
        } else {
            sethomeCity("");
            sethomepostCode("");

        }

        console.log("city>>", value);
    };


    return (
        <>
            <ModalQuickView
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
            />
            <div className="w-full flex justify-center px-3">
                <div className="w-full md:w-9/12 lg:w-[45%]">
                    <div className="flex justify-center w-full mt-12">

                        <span className="block text-xl font-bold flex justify-center w-full text-center">
                            Add Shipping Formular
                        </span>


                    </div>
                    <div className="flex w-full items-center justify-center mb-6">
                        {/* <button
                            className={
                                `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-gradient-to-r from-cyan-500 from-10% to-[#3c9140] to-90% hover:from-pink-500 hover:to-yellow-500 
        w-full h-9 text-slate-50 font-medium mt-12 `}

                            onClick={() => { setShowModalQuickView(true) }}
                        >

                            <span className="block  text-sm font-medium "
                            >
                                Add Shipping Formular
                            </span>

                        </button> */}
                    </div>
                    <div className="space-y-4">

                        <ShippingFormular />
                        {/* <div>
                            <Label className="text-sm">Addess</Label>
                            <div className="mt-1.5 flex">
                                <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                    <i className="text-xl las la-map-signs"></i>
                                </span>
                                <Input
                                    className="!rounded-l-none"
                                    placeholder="New york, USA"
                                    onChange={(e) => {
                                        sethomeAddress(e.target.value);
                                        seterrhomeAddress(false);
                                    }}
                                    value={homeAddress}
                                />
                            </div>
                            {errhomeAddress && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>

                        <div>
                            <Label className="text-sm">State</Label>


                            <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                defaultValue={{ name_en: homeDistrict }}
                                value={{ name_en: homeDistrict }}

                                // id="combo-box-demo"
                                options={allHDistricts}
                                getOptionLabel={(option) => option.name_en}
                                onChange={(event, value) => {

                                    handleChangeHdist(event, value);
                                    // setCountry(value);
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Select State" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            />

                            {errhomeDistrict && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}

                        </div>

                        <div>
                            <Label className="text-sm">Suburb / City</Label>


                            <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                defaultValue={{ fields: { scc_name: homeCity } }}
                                value={{ fields: { scc_name: homeCity } }}
                                // id="combo-box-demo"
                                options={allHCities}
                                getOptionLabel={(option) => option?.fields?.scc_name}
                                onChange={(event, value) => {

                                    handleChangeHcity(event, value);
                                    // setCountry(value);
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Select State" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            />

                            {errhomeCity && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}

                        </div>


                        <div>
                            <Label className="text-sm">Post Code</Label>
                            <div className="mt-1.5 flex">
                                <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                    <i className="text-xl las la-envelope"></i>
                                </span>
                                <Input
                                    className="!rounded-l-none"
                                    placeholder="1152"
                                    onChange={(e) => {
                                        sethomepostCode(e.target.value);
                                        seterrhomepostCode(false);
                                        // seterrfirstName(false);
                                    }}
                                    value={homepostCode}
                                />
                            </div>
                            {errhomepostCode && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}

                        {/* ---- */}
                        {/* <div>
                <Label>Gender</Label>
                <Select className="mt-1.5">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Select>
              </div> */}

                        {/* ---- */}
                        {/* <div>
                <Label>Phone number</Label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-xl las la-phone-volume"></i>
                  </span>
                  <Input
                    className="!rounded-l-none"
                    // defaultValue="003 888 232"
                    placeholder="Enter Mobile"
                    onChange={(e) => {
                      setmobile(e.target.value);
                      seterrmobile("");
                    }}
                    value={mobile}
                  />
                </div>
                {errmobile && (
                  <div className="text-red-600 text-sm text-right mt-2">
                   {errmobile}
                  </div>
                )}
              </div> */}
                        {/* ---- */}
                        {/* <div>
                <Label>About you</Label>
                <Textarea className="mt-1.5" defaultValue="..." />
              </div> */}
                    </div>
                    {/* <div className="pt-2 mt-4">
                        <ButtonPrimary
                        // onClick={onSubmit2}
                        >Update account</ButtonPrimary>
                    </div> */}

                    {/* <div className="flex w-full items-center justify-center">
                        <button
                            className={
                                `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-gradient-to-r from-cyan-500 from-10% to-[#3c9140] to-90% hover:from-pink-500 hover:to-yellow-500 
        w-full h-9 text-slate-50 font-medium mt-12 `}
                            onClick={onSubmit2}

                        >
                           
                            <span className="block  text-sm font-medium "

                            >
                                Update account
                            </span>

                        </button>
                    </div> */}




                </div>
            </div >

        </>
    )

}

export default Billing;