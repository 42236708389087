import React, { FC, useState, useEffect, useRef, useContext } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL } from "Constant/System";
import Company1 from "images/avatars/Image-1.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
// import ProductCard from "components/ProductCardNew";
import { GetUserId, getCleanT, getServicePackage, getWishlistList } from "Services/API/Get";
import WishContext from "Context/WishContext";
import CleanCard from "components/CardCategories/PackageCard";
import { Autocomplete } from "@mui/material";
import ModalQuickView from "components/ModalHire";
import _, { lowerCase } from "lodash";

import img1 from "images/cleaning/After Party.png"
import img2 from "images/cleaning/Blinds.png"
import img3 from "images/cleaning/Building.png"
import img4 from "images/cleaning/Car Park.png"
import img5 from "images/cleaning/Carpet.png"
import img6 from "images/cleaning/Child Care Cleaning.png"
import img7 from "images/cleaning/Emegency Cleaning.png"
import img8 from "images/cleaning/End of lease.png"
import img9 from "images/cleaning/Factory.png"
import img10 from "images/cleaning/Healthcare.png"
import img11 from "images/cleaning/Hospital.png"
import img12 from "images/cleaning/House.png"
import img13 from "images/cleaning/office.png"
import img14 from "images/cleaning/Other.png"
import img15 from "images/cleaning/Oven.png"
import img16 from "images/cleaning/Periodic House Cleaning.png"
import img17 from "images/cleaning/Pressure Washing.png"
import img18 from "images/cleaning/Sanitiznf.png"
import img19 from "images/cleaning/School.png"
import img20 from "images/cleaning/Strip and seal.png"
import img21 from "images/cleaning/Supermarket.png"
import img22 from "images/cleaning/Tile.png"
import img23 from "images/cleaning/Window.png"
import img24 from "images/cleaning/Commercial Cleaning.png"
import img25 from "images/cleaning/Industrial.png"
import img26 from "images/cleaning/Residential.png"

import moment from "moment";
import parse from 'html-react-parser';

import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";




const ShopClean = (props) => {

    const userId = props?.userId

    const currUser = props?.currUser

    const [images, setImages] = useState([]);

    const [array, setArray] = useState([]);

    const [lopen, setlopen] = useState(false);


    const [limit, setlimit] = useState(6);

    const { wish, setWish } = useContext(WishContext);

    const [selectedTypes, setselectedTypes] = useState([]);

    const [showModalQuickView, setShowModalQuickView] = useState(false);

    const [quoteDetails, setquoteDetails] = React.useState("");

    const [errclean, seterrclean] = useState(false);


    React.useEffect(() => {
        getWish();

    }, [wish, limit])

    const getWish = () => {
        setlopen(true);
        getServicePackage({ userId: userId })
            .then((res) => {
                setlopen(false);
                console.log("get Wish page>>>>>", res)

                const dat = res.data.map((item, index) => {

                    let img = ""

                    switch (item.name) {
                        case 'After Party Cleaning':
                            item.image = img1;
                            console.log("helloo")
                            break;
                        case 'Blind Cleaning':
                            item.image = img2;
                            break;
                        case 'Builders Cleaning':
                            item.image = img3;
                            break;
                        case 'Car Park Cleaning':
                            item.image = img4;
                            break;
                        case 'Carpet Cleaning':
                            item.image = img5;
                            break;
                        case 'Emergency Cleaning':
                            item.image = img7;
                            break;
                        case 'End of Lease Cleaning':
                            item.image = img8;
                            break;
                        case 'Healthcare Cleaning':
                            item.image = img10;
                            break;
                        case 'Home Cleaning':
                            item.image = img12;
                            break;
                        case 'Office Cleaning':
                            item.image = img13;
                            break;
                        case 'Pressure Washing':
                            item.image = img17;
                            break;
                        case 'Sanitization and Deep Cleaning':
                            item.image = img18;
                            break;
                        case 'School Cleaning':
                            item.image = img19;
                            break;
                        case 'Strip and Seal Cleaning':
                            item.image = img20;
                            break;
                        case 'Supermarket Cleaning':
                            item.image = img21;
                            break;
                        case 'Tile Cleaning':
                            item.image = img22;
                            break;
                        case 'Window Cleaning':
                            item.image = img23;
                            break;
                        case 'Other':
                            item.image = img14;
                            break;
                        case 'Oven Cleaning':
                            item.image = img15;
                            break;
                        case 'Child Care Cleaning':
                            item.image = img6;
                            break;
                        case 'Commercial Cleaning':
                            item.image = img24;
                            break;
                        case 'Industrial Cleaning':
                            item.image = img25;
                            break;
                        case 'Residential Cleaning':
                            item.image = img26;
                            break;


                    }


                    return item;
                })

                console.log("get Wish page>>>>>", dat)

                setArray(dat);
                // setCart(res.data.length);

                // this.setState({
                //   array: res.data,
                //   total: m,
                // });
            })
            .catch((error) => {
                console.error(error);
                setlopen(false);
                setArray([]);
                // setCart(0);
            });

    }


    const renderPricingItem = (pricing, index) => {
        return (
            <div
                key={index}
                className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden ${pricing.isPopular
                    ? "border-[#2d867b]"
                    : "border-neutral-100 dark:border-neutral-700"
                    }`}
            >
                {pricing.isPopular && (
                    <span className="bg-[#2d867b] text-white px-3 py-1 tracking-widest text-[10.8px] absolute right-3 top-3 rounded-full z-10">
                        POPULAR
                    </span>
                )}
                <div className="mb-8">
                    <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
                        {pricing.packageName}
                    </h3>
                    <h2 className="text-5xl font-bold leading-none flex items-center text-slate-800 dark:text-slate-200">
                        <span>{pricing.amount}</span>
                        {/* {
                            index != 0 &&

                            <span className="text-base ml-1 font-normal text-neutral-500">
                              
                                &nbsp;
                                per Ad
                            </span>


                        } */}

                    </h2>
                </div>
                <nav className="space-y-4 mb-8">
                    {/* <li className="flex items-start" >
                        <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
                            <CheckIcon className="w-5 h-5 text-[#2d867b]" aria-hidden="true" />
                        </span>
                        <span className="text-neutral-700 dark:text-neutral-300">
                            One ad posting (job ad or marketplace listing) up &nbsp;
                            {
                                index === 0 ?

                                    <b>to 7 days.</b>

                                    : index === 1 ?

                                        <b>to 28 days.</b>

                                        :

                                        <b>to 84 days.</b>
                            }


                        </span>
                    </li> */}

                    <div className="flex items-start">

                        {parse(pricing.description)}

                    </div>

                </nav>
                {/* <hr className="border-slate-400 dark:border-slate-700" />
            <nav className="space-y-4 mb-8 mt-8">
              {pricing.features.map((item: any, index: any) => (
                <li className="flex items-start" key={index}>
                  <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
                    <CheckIcon className="w-5 h-5 text-[#2d867b]" aria-hidden="true" />
                  </span>
                  {
                    item.includes("3.99") ?
                      <>
                        <span className="text-neutral-700 dark:text-neutral-300">
                          <b>
                            {item}&nbsp;
                          </b>
    
                          Transaction fee
                        </span>
                      </>
                      : item.includes("Unlimited") ?
                        <>
                          <span className="text-neutral-700 dark:text-neutral-300">
                            <b>
                              {item}&nbsp;
                            </b>
    
                            products & services listing in the online store
                          </span>
    
                        </>
    
                        :
                        <span className="text-neutral-700 dark:text-neutral-300">
                          {item}
                        </span>
    
                  }
    
                </li>
              ))}
            </nav> */}
                <div className="flex flex-col mt-auto">

                    {/* {pricing.isPopular ? (
                        <ButtonPrimary onClick={() => packageSelect(pricing)}>Submit</ButtonPrimary>
                    ) : (
                        <ButtonSecondary onClick={() => packageSelect(pricing)}>
                            <span className="font-medium" >Submit</span>
                        </ButtonSecondary>
                    )} */}
                    {/* <p className="text-[10.8px] text-neutral-500 dark:text-neutral-400 mt-3">
                {pricing.desc}
              </p> */}
                </div>
            </div>
        );
    };



    return (
        <>
            <ModalQuickView
                show={showModalQuickView}
                onCloseModalQuickView={() => { setShowModalQuickView(false); setselectedTypes([]) }}
                quoteDetails={quoteDetails}
                type={"cleaning"}
            />
            <div className="flex justify-center w-full mt-12">

                {/* <span className="block text-xl font-bold flex justify-center w-full text-center">
                    List of saved products
                </span> */}

                <div className="w-full items-center justify-center">


                    <>
                        <div className="w-full mt-1 mb-4">

                            <div className="flex justify-center">

                                <div className="flex items-center space-x-4">

                                    {/* {
                                        currUser?.publicLiabilityInsurance === 1 &&

                                        <span className="flex justify-start items-center text-xs text-slate-900 dark:text-neutral-400 text-left">



                                            <i className="las la-users text-lg text-left mr-2 text-[#002930]"></i>

                                            Public Liability Insurance
                                        </span>
                                    }


                                    {
                                        currUser?.IndemnityInsurance === 1 &&

                                        <span className="flex items-center justify-start text-xs text-slate-900 dark:text-neutral-400 text-left">



                                            <i className="las la-shield-alt text-lg text-left mr-2 text-[#002930]"></i>




                                            Indemnity Insurance
                                        </span>
                                    }


                                    {
                                        currUser?.workCoverInsurance === 1 &&

                                        <span className="flex items-center justify-start text-xs text-slate-900 dark:text-neutral-400 text-left">


                                            <i className="las la-briefcase text-lg text-left mr-2 text-[#002930]"></i>




                                            Work Cover
                                        </span>

                                    }



                                    {
                                        currUser?.workersCompensationInsurance === 1 &&

                                        <span className="flex items-center justify-start text-xs text-slate-900 dark:text-neutral-400 text-left">



                                            <i className="las la-hard-hat text-lg text-left mr-2 text-[#002930]"></i>



                                            Workers' Compensation Insurance
                                        </span>
                                    } */}


                                    {
                                        currUser?.policeClearanceCertificate === 1 &&

                                        <span className="flex items-center justify-start text-xs text-slate-900 dark:text-neutral-400 text-left">


                                            <i className="las la-certificate text-lg text-left mr-2 text-[#002930]"></i>




                                            National Police Check
                                        </span>


                                    }


                                    {
                                        currUser?.workingWithChildren === 1 &&

                                        <span className="flex items-center justify-start text-xs text-slate-900 dark:text-neutral-400 text-left">



                                            <i className="las la-baby text-lg text-left mr-2 text-[#002930]"></i>



                                            Children Check
                                        </span>
                                    }





                                </div>

                            </div>





                        </div>

                    </>

                </div>


            </div>
            <div className="w-full flex justify-center px-3">

                <div className="w-9/12 md:w-10/12 lg:w-8/12 xl:w-7/12">


                    {/* <div className="w-full items-center justify-center">


                      
                        <div className="w-full mt-4 space-y-4">

                            <div>
                                <Label className="text-sm">Select Cleaning Types</Label>
                                <Autocomplete
                                    className="mt-1.5"
                                    // className="form-group"
                                    // className="border-neutral-200"
                                    disablePortal
                                    // defaultValue={{ value: category }}
                                    // value={{ value: category, name: CATEG.find((v) => v.value === category) ? CATEG.find((v) => v.value === category).name : "All" }}

                                    // id="combo-box-demo"
                                    value={{ name: "" }}
                                    options={array}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, newValue) => {
                                        if (newValue !== null) {
                                            // setselectedSizes(newValue)

                                            if (newValue?.subcategoryname === "All") {
                                                setselectedTypes([])
                                            } else {

                                                if (_.map(selectedTypes, "cleaningtypeId")?.includes(newValue.cleaningtypeId)) {
                                                    const index = _.map(selectedTypes, "cleaningtypeId").indexOf(newValue.cleaningtypeId);

                                                    console.log(">>>>>>index", index)

                                                    var arr = [...selectedTypes]
                                                    if (index > -1) {
                                                        arr.splice(index, 1);
                                                        console.log("arr>>>>", arr)
                                                        setselectedTypes(arr)
                                                    }

                                                } else {
                                                    setselectedTypes(((data) => [...data, newValue]))
                                                }

                                                seterrclean(false);


                                            }

                                            // seterrcat(false);
                                        } else {
                                            // setselectedTypes([]);
                                            // seterrcat(false);
                                        }
                                    }}
                                    // sx={{
                                    //   // width: "80vw",
                                    //   background:"transparent",
                                    //   borderRadius: "20px",
                                    //   // border: "none",
                                    //   // borderColor: "transparent",

                                    //   "& .MuiInputBase-input": {
                                    //     height: 15,
                                    //     // border: "none",
                                    //     fontSize: "14px",
                                    //     fontFamily: "'Jost', sans-serif",
                                    //     // borderColor: "transparent",
                                    //   },
                                    //   //view
                                    //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                    //     // borderColor: "transparent",
                                    //   },

                                    //   "&:hover .MuiOutlinedInput-input": {
                                    //     color: "black",
                                    //   },
                                    //   "&:hover .MuiInputLabel-root": {
                                    //     color: COLORS.GrayBackground,
                                    //   },
                                    //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                    //   {
                                    //     // borderColor: "transparent",
                                    //   },
                                    //   ".MuiAutocomplete-root fieldset:hover": {
                                    //     color: "transparent",
                                    //     // borderColor: "transparent",
                                    //   },
                                    // }}
                                    renderInput={(params) => (
                                        <div ref={params.InputProps.ref}>
                                            <Input {...params.inputProps} className="" placeholder="Select types" />
                                        </div>
                                    )}
                                    ListboxProps={{
                                        style: {
                                            maxHeight: "150px",
                                            fontSize: "14px",
                                            // border: '1px solid white'
                                        },
                                    }}
                                />
                              
                                {errclean && (
                                    <div className="text-red-600 text-sm text-right mt-2">
                                        This field is required
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-wrap mt-0 mb-6">

                                {
                                    selectedTypes && selectedTypes?.length > 0 && selectedTypes?.map((item, index) => {

                                        let data = array.find(({ cleaningtypeId }) => cleaningtypeId === parseInt(item.cleaningtypeId))
                                        return (
                                            <>

                                                <div className="bg-[#e2e8f0] w-fit p-2 flex mr-1 mt-2">

                                                    <span className="text-xs">{data?.name}</span>

                                                    <div className="flex items-center ml-3 cursor-pointer"
                                                        onClick={(e) => {
                                                            var array = [...selectedTypes]
                                                            array.splice(index, 1);
                                                            setselectedTypes([...array])
                                                        }}
                                                    >
                                                        <i className="las la-times text-base text-center  flex items-center justify-center"></i>

                                                    </div>

                                                </div>
                                            </>
                                        )
                                    })
                                }


                            </div>



                        </div>
                        <div className="flex w-full items-center justify-center">
                            <button
                                className={
                                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-6 `}

                                onClick={() => {
                                    // if (currUser?.packageId) {
                                    //     setselectedJob("");
                                    //     setShowModalQuickView(true)
                                    // } else {
                                    //     router.push("/subscription")
                                    // }

                                    // setselectedJob("");
                                    if (selectedTypes.length === 0) {
                                        seterrclean(true);
                                        return;
                                    }

                                    let obj = {
                                        "userId": GetUserId(),
                                        "cleaningTypes": _.map(selectedTypes, "name").join(','),
                                        "serviceProviderId": userId,
                                        createdAt: moment().format("YYYY-MM-DD HH:mm:ss")

                                    }

                                    setquoteDetails(obj);

                                    setShowModalQuickView(true)
                                }}
                            >
                              
                                <span className="block  text-sm font-medium "
                                >
                                    Request quotation
                                </span>

                            </button>
                        </div>

                        
                    </div> */}
                    {
                        array.length > 0 ?

                            <>
                                <div className="">
                                    {/* {array.map((stay) => (
                                        <CleanCard data={stay} />
                                    ))} */}

                                    <div className="text-neutral-600 text-sm md:text-base overflow-hidden">
                                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 xl:gap-8">
                                            {/* {array.map(renderPricingItem)} */}
                                            {array.map((stay) => (
                                                <CleanCard data={stay} />
                                            ))}
                                        </div>
                                    </div>




                                </div>
                                {/* <div className="flex !mt-20 justify-center items-center">
                                    <ButtonSecondary
                                        onClick={() => setlimit(limit + 3)}
                                        loading={lopen}
                                    >Show me more</ButtonSecondary>
                                </div> */}

                            </>

                            :
                            <>
                                <div className="flex !mt-20 justify-center items-center">
                                    <p className="text-xl font-semibold">
                                        {/* #WU3746HGG12 */}
                                        No Packages
                                    </p>

                                </div>
                                {/* <div className="flex  justify-center items-center mt-4">

                                    <a href="/#/page-collection-2" >
                                        <ButtonPrimary>Shop Now</ButtonPrimary>
                                    </a>
                                </div> */}

                            </>

                    }

                </div>
            </div>

        </>
    )

}

export default ShopClean;