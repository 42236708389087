import React from "react";
import ReactDOM from "react-dom/client";
//

import './styles/__theme_font.scss'

import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "rc-slider/assets/index.css";

//
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistor, store } from "app/store";
import { PersistGate } from "redux-persist/integration/react";
import { hydrate, render } from "react-dom";

import MetaPixel from "utils/metaPixel"
import './i18n';



const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const rootElement: any = document.getElementById("root");


root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>      
      <App />
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <App />
//       </PersistGate>
//     </Provider>
//     ,
//     rootElement);
// } else {
//   root.render(
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <App />
//       </PersistGate>
//     </Provider>
//     );
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
