import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { getCompany } from "Services/API/Get";
import Img from "images/Footer.png"
import COMPLOGO from "images/INFINITYCS2.png"
import { t } from "i18next";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: any = [
  {
    id: "5",
    title: t("fContact"),
    menus: [
      // { href: "/servicing", label: "Repairs and Servicing" },
      // { href: "/shop", label: "Shop" },
      { href: "/sellers", label: "0766 224 324" },
      // { href: "/rent", label: "Rent Machinery" },
      // { href: "/service-providers", label: "Service Provider" },
      // { href: "/contracts", label: "Cleaning Contracts" },
      // { href: "/jobs", label: "Jobs" },

    ],
  },
  {
    id: "1",
    title: t("fEmail"),
    menus: [
      // { href: "/shipping", label: "Shipping" },
      { href: "/about", label: "info@xxxx.lk" },
      // { href: "/conditions", label: "Privacy Policy" },
      // { href: "/cancellation", label: "Cancellation Policy" },
      // { href: "/faqs", label: "FAQs" },

    ],
  },
  {
    id: "1",
    title: t("fAdd"),
    menus: [
      { href: "/shipping", label: "234 Devala Road Colombo 07" },
      // { href: "", label: "Support Team" },
      // { href: "", label: "Compliance Team" },
      // { href: "/faqs", label: "FAQs" },

    ],
  },
  {
    id: "2",
    title: t("fOther"),
    menus: [
      { href: "/conditions", label: "Privacy Policy" },
      // { href: "#", label: "Support" },
      // { href: "#", label: "Developers" },
      // { href: "#", label: "Learn design" },
    ],
  },
  // {
  //   id: "4",
  //   title: "Community",
  //   menus: [
  //     { href: "#", label: "Discussion Forums" },
  //     { href: "#", label: "Code of Conduct" },
  //     { href: "#", label: "Contributing" },
  //     { href: "#", label: "API Reference" },
  //   ],
  // },
];



const Footer: React.FC = () => {
  const [company, setcompany] = useState<any>("");
  const [lopen, setlopen] = useState<any>(false);

  const CompanyGet = () => {
    setlopen(true);
    getCompany()
      .then((res) => {
        setlopen(false);
        console.log(res.data);
        console.log("company res>>>", res.data[res.data.length - 1])
        setcompany(res.data[res.data.length - 1]);
        // setLogo(res.data[res.data.length - 1].image)
        // this.setState({
        //   logo: res.data[res.data.length - 1].image,
        // });
      })
      .catch((error) => {
        setlopen(false);
        console.error(error);
      });
  };

  React.useEffect(() => {
    CompanyGet();

  }, []);


  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div className="flex justify-start lg:justify-center">


        <div key={index} className="text-sm lg:ml-[0px]">

          <h2 className={`${index === -1 ? "text-transparent" : "text-white"}  dark:text-[#002930] text-sm whitespace-nowrap`}>
            {menu.title}
          </h2>
          <ul className="mt-5 space-y-4">
            {menu.menus.map((item, index) => (
              <>
                <li key={index}>
                  {/* <NavLink
                    to={{ pathname: item.href }}
                    key={index}
                    className="text-white dark:text-[#002930] dark:hover:text-slate-900 font-bold "
                  // href={item.href}
                  // target="_blank"
                  // rel="noopener noreferrer"
                  >
                    
                  </NavLink> */}
                  <div className="text-white dark:text-[#002930] dark:hover:text-slate-900 font-bold ">
                    {item.label}
                  </div>
                </li>

              </>
            ))}
            {
              menu?.title === "Contact Support" &&

              //     <button
              //       className={
              //         `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
              // w-full h-9 text-slate-50 font-medium mt-4 `}
              //     >
              //       {/* <i className="las la-pencil-alt text-xl text-left mr-2"></i> */}
              //       <span className="block  text-sm font-medium "

              //       >
              //         Submit a ticket
              //       </span>

              //     </button>
              <li >
                <a href="https://businessdirectory.infinitycs.com.au" target="_blank" className="text-[#002930] dark:text-[#002930] hover:text-slate-900 dark:hover:text-slate-900">
                  {/* <NavLink
                to={{ pathname: item.href }}
                key={index}
                className="text-[#002930] dark:text-[#002930] hover:text-slate-900 dark:hover:text-slate-900"
              // href={item.href}
              // target="_blank"
              // rel="noopener noreferrer"
              > */}
                  Submit a ticket
                </a>
                {/* </NavLink> */}
              </li>
            }
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="nc-Footer relative py-20 lg:pt-20 lg:pb-24 dark:border-neutral-700 bg-[#0A3247]">
        <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-6 lg:gap-x-10 ">
          <div className="grid grid-cols-1 gap-1 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
            <div className="col-span-1 md:col-span-1">
              <Logo footer={true} />
            </div>

          </div>

          {/* {widgetMenus.map(renderWidgetMenuItem)} */}
          <div className="flex justify-start xl:justify-center">


            <div className={`${t("langKey") === "Si" ? "text-sm" : "text-sm"}   lg:ml-[0px]`}>

              <h2 className={`${"text-white"}  dark:text-[#002930] whitespace-nowrap`}>
                {t("fContact")}
              </h2>
              <ul className="mt-5 space-y-4">
                {[1].map((item, index) => (
                  <>
                    <li key={index}>
                      {/* <NavLink
            to={{ pathname: item.href }}
            key={index}
            className="text-white dark:text-[#002930] dark:hover:text-slate-900 font-bold "
          // href={item.href}
          // target="_blank"
          // rel="noopener noreferrer"
          >
            
          </NavLink> */}
                      <div className="text-white dark:text-[#002930] dark:hover:text-slate-900 font-bold font-['Inter']">
                        {"0766 224 324"}
                      </div>
                    </li>

                  </>
                ))}

              </ul>
            </div>
          </div>
          <div className="flex justify-start xl:justify-center">


            <div className={`${t("langKey") === "Si" ? "text-sm" : "text-sm"}   lg:ml-[0px]`}>

              <h2 className={`${"text-white"}  dark:text-[#002930] whitespace-nowrap`}>
                {t("fEmail")}
              </h2>
              <ul className="mt-5 space-y-4">
                {[1].map((item, index) => (
                  <>
                    <li key={index}>
                      {/* <NavLink
to={{ pathname: item.href }}
key={index}
className="text-white dark:text-[#002930] dark:hover:text-slate-900 font-bold "
// href={item.href}
// target="_blank"
// rel="noopener noreferrer"
>

</NavLink> */}
                      <div className="text-white dark:text-[#002930] dark:hover:text-slate-900 font-bold font-['Inter']">
                        {"info@mangalya.lk"}
                      </div>
                    </li>

                  </>
                ))}

              </ul>
            </div>
          </div>
          <div className="flex justify-start xl:justify-center">


            <div className={`${t("langKey") === "Si" ? "text-sm" : "text-sm"}   lg:ml-[0px]`}>

              <h2 className={`${"text-white"}  dark:text-[#002930]  whitespace-nowrap`}>
                {t("fAdd")}
              </h2>
              <ul className="mt-5 space-y-4">
                {[1].map((item, index) => (
                  <>
                    <li key={index}>
                      {/* <NavLink
to={{ pathname: item.href }}
key={index}
className="text-white dark:text-[#002930] dark:hover:text-slate-900 font-bold "
// href={item.href}
// target="_blank"
// rel="noopener noreferrer"
>

</NavLink> */}
                      <div className="text-white dark:text-[#002930] dark:hover:text-slate-900 font-bold font-['Inter']">
                        {t("fAddVal")}
                      </div>
                    </li>

                  </>
                ))}

              </ul>
            </div>
          </div>
          <div className="flex justify-start xl:justify-center">


            <div className={`${t("langKey") === "Si" ? "text-sm" : "text-sm"}   lg:ml-[0px]`}>

              <h2 className={`${"text-white"}  dark:text-[#002930] whitespace-nowrap`}>
                {t("fOther")}
              </h2>
              <ul className="mt-5 space-y-4">
                {[1].map((item, index) => (
                  <>
                    <li key={index}>
                      {/* <NavLink
to={{ pathname: item.href }}
key={index}
className="text-white dark:text-[#002930] dark:hover:text-slate-900 font-bold "
// href={item.href}
// target="_blank"
// rel="noopener noreferrer"
>

</NavLink> */}
                      <div className="text-white dark:text-[#002930] dark:hover:text-slate-900 font-bold font-[Inter]">
                        {/* {t("fPrivacy")} */}
                        Privacy Policy
                      </div>
                    </li>

                  </>
                ))}

              </ul>
            </div>
          </div>
          <div className="flex justify-start xl:justify-center">
            <div className={`${t("langKey") === "Si" ? "text-sm" : "text-sm"}   lg:ml-[0px] `}>
              <h2 className="text-white dark:text-[#002930] ">
                {t("fSocial")}
              </h2>
              <ul className="mt-5 space-y-4">

                {/* <img src={Img} className="h-[100px] -ml-2 cursor-pointer" /> */}
                <div className="flex space-x-5">

                  <a href={"https://www.facebook.com/profile.php?id=61551660879692&mibextid=ZbWKwL"} target="_blank" >
                    <div className="w-10 h-10 rounded-full border border-slate-200 flex items-center justify-center text-slate-100 hover:bg-white hover:text-slate-900 cursor-pointer">
                      <i className="lab la-facebook-f text-3xl "></i>
                    </div>


                  </a>


                  <a href={"https://instagram.com/tidytraderr.au?igshid=NTc4MTIwNjQ2YQ=="} target="_blank" >

                    <div className="w-10 h-10 rounded-full border border-slate-200 flex items-center justify-center text-slate-100 hover:bg-white hover:text-slate-900 cursor-pointer">
                      <i className="lab la-instagram text-3xl "></i>
                    </div>

                  </a>

                  <div className="w-10 h-10 rounded-full border border-slate-200 flex items-center justify-center text-slate-100 hover:bg-white hover:text-slate-900 cursor-pointer">
                    <i className="lab la-linkedin text-3xl "></i>
                  </div>

                </div>

              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-[#0A3247] ">

        {/* <div className="flex w-full justify-center items-center">

          <div className="border border-[#2d867b] border-opacity-50 w-[250px] sm:w-[500px]"></div>

        </div> */}

        <div className="flex flex-col w-full justify-center items-center pb-10">

          <span className="text-white text-xs mt-8">
            Powered by
          </span>

          <a href={"https://infinitixx.com"} target="_blank" >

            <img src={COMPLOGO} className="h-[25px] cursor-pointer mt-3" />
          </a>
        </div>

        {/* <div className="w-full h-full flex flex-col md:flex-row justify-between items-center md:px-16 py-6 md:py-5">

          <div className="flex space-x-5">

            <a href={"https://www.facebook.com/profile.php?id=61551660879692&mibextid=ZbWKwL"} target="_blank" >
              <div className="w-10 h-10 rounded-full border border-slate-200 flex items-center justify-center text-slate-100 hover:bg-white hover:text-slate-900 cursor-pointer">
                <i className="lab la-facebook-f text-4xl "></i>
              </div>


            </a>


            <a href={"https://instagram.com/tidytraderr.au?igshid=NTc4MTIwNjQ2YQ=="} target="_blank" >

              <div className="w-10 h-10 rounded-full border border-slate-200 flex items-center justify-center text-slate-100 hover:bg-white hover:text-slate-900 cursor-pointer">
                <i className="lab la-instagram text-4xl "></i>
              </div>

            </a>

            <div className="w-10 h-10 rounded-full border border-slate-200 flex items-center justify-center text-slate-100 hover:bg-white hover:text-slate-900 cursor-pointer">
              <i className="lab la-linkedin text-4xl "></i>
            </div>

          </div>

          <div>
            <span className="flex text-xs text-slate-100 mt-2 md:mt-0">2023 Tidytraderr. All Rights Reserved</span>
          </div>

        </div> */}

      </div>
    </div>
  );
};

export default Footer;
