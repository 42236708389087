import React, { FC, useState, useEffect, useRef } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL, IMAGE_SERVER_URL_DEV } from "Constant/System";
import _, { lowerCase, map, upperFirst } from "lodash";
import { postContracts, postJobs } from "Services/API/Post";
import { addToast } from "shared/Toast";
import { CurrentUser, GetCleaningType, GetCleaningTypeContract, GetUserId, getCities, getDistricts } from "Services/API/Get";
import { Button, Grid, TextField, Typography, Autocomplete } from "@mui/material";
import { UpdateContract, UpdateJobs, UpdatePackage } from "Services/API/Put";
import { useHistory } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import moment from "moment";
import SubData from "data/australian_postcodes.json";
import LoadingSpinner from "shared/LoadingSpinner";
import BotToast from "shared/BotToast";
import FileResizer from "react-image-file-resizer";


const Jobs = ({ selectedJob = null, onCloseModalQuickView }) => {

    const [images, setImages] = useState(selectedJob.contractImages ? JSON.parse(selectedJob.contractImages) : []);

    const [title, settitle] = useState(selectedJob ? selectedJob.title : "");
    const [jobType, setjobType] = useState(selectedJob ? selectedJob.jobType : "");
    const [country, setcountry] = useState(selectedJob ? selectedJob.country : "Australia");
    const [homeDistrict, sethomeDistrict] = useState(selectedJob ? selectedJob.homeDistrict : "");
    const [homeCity, sethomeCity] = useState(selectedJob ? selectedJob.homeCity : "");
    const [duration, setduration] = useState(selectedJob ? selectedJob.duration : "");
    const [description, setdescription] = useState(selectedJob ? selectedJob.description : "");
    const [currency, setcurrency] = useState(selectedJob ? selectedJob.currency : "AUD");
    const [rate, setrate] = useState(selectedJob ? selectedJob.hourlyRate : "");
    const [expire, setexpire] = useState(selectedJob ? selectedJob.expiryDate : "");
    const [Keywords, setKeywords] = useState(selectedJob.keywords ? JSON.parse(selectedJob.keywords).join(" ") : "");

    const [errimages, seterrImages] = useState([]);

    const [errtitle, seterrtitle] = useState(false);
    const [errjobType, seterrjobType] = useState(false);
    const [errcountry, seterrcountry] = useState(false);
    const [errsuburb, seterrsuburb] = useState(false);
    const [errduration, seterrduration] = useState(false);
    const [errdescription, seterrdescription] = useState(false);
    const [errcurrency, seterrcurrency] = useState(false);
    const [errrate, seterrrate] = useState(false);
    const [errexpire, seterrexpire] = useState(false);
    const [errKeywords, seterrKeywords] = useState(false);
    const [errhomeDistrict, seterrhomeDistrict] = useState(false);
    const [errhomeCity, seterrhomeCity] = useState(false);

    const [homeDistrictId, sethomeDistrictId] = useState(0);
    const [allHCities, setallHCities] = useState([]);
    const [allHDistricts, setallHDistricts] = useState([]);

    const [currUser, setcurrUser] = useState("");

    const [packageId, setpackageId] = useState("");

    const [lOpen, setlOpen] = useState(false);

    const [errpackages, seterrpackages] = useState(false);

    const history = useHistory();

    const [cleanTypes, setcleanTypes] = useState([]);
    const [cleanId, setcleanId] = useState(selectedJob ? selectedJob.cleaningTypeId : 1);
    const [errclean, seterrclean] = useState(false);

    const [newSubArray, setnewSubArray] = useState([]);
    const [newSubs, setnewSubs] = useState([]);

    const [bOpen, setbOpen] = useState(false);
    const [bMsg, setbMsg] = useState("");
    const [bStatus, setbStatus] = useState("");

    const [newSubs2, setnewSubs2] = useState([]);

    React.useEffect(() => {

        let newSubArray = JSON.parse(JSON.stringify(SubData));

        newSubArray = newSubArray?.map((d) => {
            if (d.type === "Delivery Area") {

                return {
                    ...d,
                    locality: upperFirst(lowerCase(d.locality)),
                };

            }

        });

        newSubArray = _.compact(newSubArray)

        setnewSubArray(_.orderBy(newSubArray, ['locality'], ['asc']));

        console.log(">>>>>>>subs", newSubArray.filter((u) => u.state === 0))


    }, []);


    const packageSelect = (item) => {

        console.log("pkg>>>>", item)

        let obj = {}

        if (item === "1") {

            obj = {
                email: currUser?.email,
                amountPayable: "FREE",
                packageDetails: [
                    {
                        packagePrice: "FREE",
                        qty: 1,
                        packageName: "FREE" + " " + "Subscription",
                        days: 7
                    }
                ],
                packageId: 1,
                userId: currUser?.id,
            }


        } else if (item === "2") {

            obj = {
                email: currUser?.email,
                amountPayable: "15",
                packageDetails: [
                    {
                        packagePrice: "15",
                        qty: 1,
                        packageName: "Basic" + " " + "Subscription",
                        days: 28

                    }
                ],
                packageId: 2,
                userId: currUser?.id,
            }



        } else if (item === "3") {

            obj = {
                email: currUser?.email,
                amountPayable: "30",
                packageDetails: [
                    {
                        packagePrice: "30",
                        qty: 1,
                        packageName: "Plus" + " " + "Subscription",
                        days: 84
                    }
                ],
                packageId: 2,
                userId: currUser?.id,
            }


        }

        // let obj = {
        //     email: currUser?.email,
        //     amountPayable: item.pricing.split("$")[1],
        //     packageDetails: [
        //         {
        //             packagePrice: item.pricing.split("$")[1],
        //             qty: 1,
        //             packageName: item.name + " " + "Subscription",
        //         }
        //     ],
        //     packageId: item.id,
        //     userId: currUser?.id,
        // }

        console.log("obj>>>", obj)

        setlOpen(true);

        UpdatePackage(obj, currUser?.id)
            .then((res) => {

                console.log("res>>>>", res.data)

                if (res.data !== undefined) {
                    if (res.data === "success") {
                        history.push("/profile");
                    } else {
                        window.location.href = res.data;
                    }

                }

            })
            .catch((error) => {
                setlOpen(false);
                console.log("error", error);
                console.log("current user error", error.response.data.message);
                if (error.response.data.statusCode === 401) {
                    history.push("/login");
                }
            });

    }

    // React.useEffect(() => {

    //     if (packageId) {
    //         packageSelect(packageId);
    //     }

    // }, [packageId]);

    React.useEffect(() => {
        listDistricts();
        getCurrentUser();

    }, []);

    React.useEffect(() => {
        if (homeDistrictId !== 0) {
            listHomecities();
        }
    }, [homeDistrictId]);

    const listDistricts = () => {
        getDistricts()
            .then((res) => {
                console.log("districts>>>", res);
                setallHDistricts(res.data);
                // setallODistricts(res.data);
            })
            .catch((error) => {
                console.log("Error", error.response);
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });

        GetCleaningTypeContract()
            .then((res) => {
                console.log("cleanTypes>>>", res);
                setcleanTypes(res.data);
                // setallODistricts(res.data);
            })
            .catch((error) => {
                console.log("Error", error.response);
                setcleanTypes([])
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });
    };
    const listHomecities = () => {
        getCities(homeDistrict)
            .then((res) => {
                console.log("cities>>>", res.data.records);
                setallHCities(res.data.records);
            })
            .catch((error) => {
                console.log("Error", error.response);
                setallHCities([])
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });
    };

    const handleChangeHdist = (e, value) => {
        if (value !== null) {
            sethomeDistrict(value.name_en);
            seterrhomeDistrict(false);
            sethomeDistrictId(value.id);
        } else {
            sethomeDistrict("");
            sethomeDistrictId(1);
        }

        console.log("state>>", value);
        // setnewSubs(newSubArray.filter((u) => u.state === value.id))

        setnewSubs([]);

        const filtered = newSubArray.filter((u) => u.state === value.id)

        const perChunk = 700

        const result = filtered.reduce((filtered, item, index) => {
            const chunkIndex = Math.floor(index / perChunk)

            if (!filtered[chunkIndex]) {
                filtered[chunkIndex] = [] // start a new chunk
            }

            filtered[chunkIndex].push(item)

            return filtered
        }, [])

        console.log("<<<<<<<", result)

        for (let i = 0; i < result.length - 1; i++) {

            let item = result[i];

            setTimeout(() => {
                setnewSubs(existing => [...existing, ...item]);
            }, i === 0 ? 0 : (2000));
        }

    };
    const handleChangeHcity = (e, value) => {
        if (value !== null) {
            // console.log(">><<", value.fields.scc_name)
            // sethomeCity(value.fields.scc_name);
            sethomeCity(value);
            // sethomepostCode(value.postcode);
            seterrhomeCity(false);
            // seterrhomepostCode(false);
        } else {
            sethomeCity("");
            // sethomepostCode("");

        }

        console.log("city>>", value);
    };


    const saveJob = (e) => {

        e.preventDefault();

        if (!title) {
            seterrtitle(true);
        }

        // if (!jobType) {
        //     seterrjobType(true);
        // }
        if (!country) {
            seterrcountry(true);
        }
        if (!homeDistrict) {
            seterrhomeDistrict(true);
        }
        if (!homeCity) {
            seterrhomeCity(true);
        }
        // if (!duration) {
        //     seterrduration(true);
        // }
        if (!description) {
            seterrdescription(true);
        }

        // if (!currency || !rate) {
        //     seterrrate(true);
        // }

        if (!cleanId) {
            seterrclean(true);
        }
        // if (!expire) {
        //     seterrexpire(true);
        // }
        // if (!packageId) {
        //     seterrpackages(true);
        // }
        // if (!Keywords) {
        //     seterrKeywords(true);
        // }
        // if (images.length === 0) {
        //     seterrImages(true);
        // }

        console.log(">>>>>>>>", _.filter(Keywords.split(" ")))

        console.log(">>>>>>", images)

        let obj2 = {}

        let item = packageId

        if (item === "1") {

            obj2 = {
                email: currUser?.email,
                amountPayable: "FREE",
                packageDetails: [
                    {
                        packagePrice: "FREE",
                        qty: 1,
                        packageName: "FREE" + " " + "Subscription",
                        days: 7
                    }
                ],
                packageId: 1,
                userId: currUser?.id,
            }


        } else if (item === "2") {

            obj2 = {
                email: currUser?.email,
                amountPayable: "15",
                packageDetails: [
                    {
                        packagePrice: "15",
                        qty: 1,
                        packageName: "Basic" + " " + "Subscription",
                        days: 28

                    }
                ],
                packageId: 2,
                userId: currUser?.id,
            }



        } else if (item === "3") {

            obj2 = {
                email: currUser?.email,
                amountPayable: "30",
                packageDetails: [
                    {
                        packagePrice: "30",
                        qty: 1,
                        packageName: "Plus" + " " + "Subscription",
                        days: 84
                    }
                ],
                packageId: 2,
                userId: currUser?.id,
            }


        }

        let obj = {
            title: title,
            description: description,
            keywords: _.filter(Keywords.split(" ")),
            userId: GetUserId(),
            contractImages: images,
            country: country,
            suburb: homeCity,
            duration: duration,
            hourlyRate: rate.toString(),
            currency: currency,
            expiryDate: expire,
            state: homeDistrict,
            jobType: jobType,
            createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            packageDetails: obj2,
            cleaningTypeId: cleanId

        }

        console.log("obj>>>>", obj)

        if (
            title &&
            description &&
            // Keywords &&
            country &&
            homeDistrict &&
            // homeCity &&
            // duration &&
            // rate &&
            // currency &&
            cleanId
            // expire &&
            // packageId &&
            // jobType
        ) {

            if (!selectedJob) {
                setlOpen(true);
                postContracts(obj)
                    .then((res) => {
                        if (res.data.includes('stripe')) {
                            window.location.href = res.data;
                            return;
                        }
                        setlOpen(false);

                        setbOpen(true);
                        setbMsg("Contract successfully added");
                        setbStatus("success");

                        // addToast("Contract successfully added", "success");
                        settitle("");
                        setjobType("");
                        setcountry("");
                        setdescription("");
                        sethomeDistrict("");
                        sethomeCity("");
                        setduration("");
                        setrate("");
                        setImages([]);
                        setKeywords("");
                        setexpire("");
                        setcurrency("");
                        setcleanId("");
                        // setOpenToast(true);
                        // setColor(COLORS.Primary);
                        // setAlertClass("success");
                        // setMsg("Successfully updated");
                        // getCurrentUser();
                        // seteDisable(true);
                        // getUser();

                        // history.push("/");
                        // window.location.reload();
                    })
                    .catch((error) => {
                        setlOpen(false);
                        console.log("Error", error.response);

                        setbOpen(true);
                        setbMsg(error.response.data.message);
                        setbStatus("warning");

                        // addToast(error.response.data.message, "warning");
                        // setOpenToast(true);
                        // setColor(COLORS.Orange);
                        // setAlertClass("error");
                        // setMsg(error.response.data.message);
                    })
            } else {
                setlOpen(true);
                UpdateContract(obj, selectedJob?.id)
                    .then((res) => {
                        setlOpen(false);

                        setbOpen(true);
                        setbMsg("Contract successfully updated ");
                        setbStatus("success");

                        // addToast("Contract successfully updated ", "success");
                        settitle("");
                        setjobType("");
                        setcountry("");
                        setdescription("");
                        sethomeDistrict("");
                        sethomeCity("");
                        setduration("");
                        setrate("");
                        setImages([]);
                        setKeywords("");
                        setexpire("");
                        setcurrency("");
                        setcleanId("");
                        onCloseModalQuickView();
                        // setOpenToast(true);
                        // setColor(COLORS.Primary);
                        // setAlertClass("success");
                        // setMsg("Successfully updated");
                        // getCurrentUser();
                        // seteDisable(true);
                        // getUser();

                        // history.push("/");
                        // window.location.reload();
                    })
                    .catch((error) => {
                        setlOpen(false);
                        console.log("Error", error.response);


                        setbOpen(true);
                        setbMsg(error.response.data.message);
                        setbStatus("warning");

                        // addToast(error.response.data.message, "warning");
                        // setOpenToast(true);
                        // setColor(COLORS.Orange);
                        // setAlertClass("error");
                        // setMsg(error.response.data.message);
                    })

            }

        } else {
            setbOpen(true);
            setbMsg("Required fields are missing and add missing fields.");
            setbStatus("warning");
        }


    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            FileResizer.imageFileResizer(
                file,
                file.height,
                file.width,
                "PNG",
                50,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });

    const getCurrentUser = () => {
        CurrentUser()
            .then((res) => {
                if (res.statusCode === 200) {
                    const cData = res.data[0];
                    // setUser(cData);
                    const obj = {
                        id: cData.id,
                        email: cData?.email,
                        firstName: cData?.firstName,
                        lastName: cData?.lastName,
                        phone: cData?.mobileNumber,
                    };
                    //   this.setState({
                    //     firstName: cData?.firstName,
                    //     lastName: cData?.lastName,
                    //     email: cData?.email,
                    //     tele: cData?.phone,
                    //   });
                    console.log("<><><><>", cData);
                    setcurrUser(cData);
                    setpackageId(cData.packageId ? cData.packageId : "");

                    localStorage.setItem(
                        `${window.location.hostname}.profile`,
                        JSON.stringify(obj)
                    );
                }
            })
            .catch((error) => {
                console.log("error", error);
                console.log("current user error", error.response.data.message);
            });
    };

    useEffect(() => {
        if (bOpen === true) {
            setTimeout(() => {

                setbOpen(false)

            }, 10000);
        }
    }, [bOpen])

    return (
        <>
            <LoadingSpinner open={lOpen} />
            <div className="flex justify-center w-full mt-2">

                <span className="block text-xl font-bold flex justify-center w-full text-center">

                    {selectedJob ? "Edit Contract" : "Post Contracts"}
                </span>


            </div>
            <div className="w-full flex justify-center px-3">

                <div className="w-full md:w-11/12 lg:w-10/12">

                    <div className="w-full mt-6 space-y-4">
                        {/* <div>
                            <Label className="text-sm">Pricing</Label>
                            <div class="flex w-full justify-between mt-4 ">
                                <div class="flex">
                                    <div class="flex items-center h-5">
                                        <input id="helper-radio"
                                            aria-describedby="helper-radio-text"
                                            checked={packageId === "1"}
                                            onChange={(e) => {
                                                setpackageId(e.target.value);
                                            }}
                                            type="radio"
                                            value="1"
                                            class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    </div>
                                    <div className="flex">
                                        <div class="ml-2 text-sm">
                                            <label for="helper-radio" class="cursor-pointer font-medium text-gray-900 dark:text-gray-300">Starter

                                            </label>
                                            <p id="helper-radio-text" class="cursor-pointer text-xs font-normal text-gray-500 dark:text-gray-300">Free</p>
                                        </div>
                                        <div className="flex ml-2">
                                            <Tooltip title={"One ad posting (job ad or marketplace listing) up  to 7 days."} placement="right-start" TransitionComponent={Zoom} arrow >
                                                <i className="las la-info-circle cursor-pointer"></i>
                                            </Tooltip>

                                        </div>
                                    </div>
                                </div>



                                <div class="flex">
                                    <div class="flex items-center h-5">
                                        <input id="helper-radio2"
                                            aria-describedby="helper-radio-text"
                                            checked={packageId === "2"}
                                            onChange={(e) => {
                                                setpackageId(e.target.value);
                                            }}
                                            type="radio"
                                            value="2"
                                            class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    </div>

                                    <div className="flex">
                                        <div class="ml-2 text-sm">
                                            <label for="helper-radio2" class="cursor-pointer font-medium text-gray-900 dark:text-gray-300">Basic</label>
                                            <p id="helper-radio-text" class="cursor-pointer text-xs font-normal text-gray-500 dark:text-gray-300">$ 15 per Ad</p>

                                        </div>
                                        <div className="flex ml-2">
                                            <Tooltip title={"One ad posting (job ad or marketplace listing) up  to 28 days.  Showcase in the homepage results"} placement="right-start" TransitionComponent={Zoom} arrow >
                                                <i className="las la-info-circle cursor-pointer"></i>
                                            </Tooltip>

                                        </div>
                                    </div>

                                </div>

                                <div class="flex">
                                    <div class="flex items-center h-5">
                                        <input id="helper-radio3"
                                            aria-describedby="helper-radio-text"
                                            type="radio"
                                            onChange={(e) => {
                                                setpackageId(e.target.value);
                                            }}
                                            checked={packageId === "3"}
                                            value="3"
                                            class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    </div>
                                    <div className="flex">
                                        <div class="ml-2 text-sm">

                                            <label for="helper-radio3" class="cursor-pointer font-medium text-gray-900 dark:text-gray-300">Plus</label>
                                            <p id="helper-radio-text" class="cursor-pointer text-xs font-normal text-gray-500 dark:text-gray-300">$ 30 per Ad</p>
                                        </div>
                                        <div className="flex ml-2">
                                            <Tooltip title={"One ad posting (job ad or marketplace listing) up  to 84 days.  Showcase in the homepage results.   Priority listing in search results."} placement="right-start" TransitionComponent={Zoom} arrow >
                                                <i className="las la-info-circle cursor-pointer"></i>
                                            </Tooltip>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {errpackages && (
                            <div className="text-red-600 text-sm text-right mt-2">
                                This field is required
                            </div>
                        )} */}
                        <div>
                            <Label className="text-sm">Title</Label>
                            <Input className="mt-1.5"
                                placeholder="Enter contract title"
                                onChange={(e) => {
                                    settitle(e.target.value);
                                    seterrtitle(false);
                                }}
                                value={title}
                            />
                            {errtitle && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">Cleaning Type</Label>

                            <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                // defaultValue={{ name: cleanTypes.find((v) => v.id === cleanId) ? cleanTypes.find((v) => v.id === cleanId).name : "" }}
                                value={{ name: cleanTypes.find((v) => v.id === cleanId) ? cleanTypes.find((v) => v.id === cleanId).name : "" }}

                                // value={{ name: "" }}
                                // options={[{ name: "" }, ...cleanTypes]}
                                // id="combo-box-demo"
                                options={cleanTypes}
                                getOptionLabel={(option) => option.name}
                                onChange={(e, newValue) => {
                                    setcleanId(newValue.id);
                                    seterrclean(false);

                                    // if (newValue.length !== 0) {
                                    //     // setselectedSizes(newValue)
                                    //     if (selectedCtypes?.length > 0) {

                                    //         const index = _.map(selectedCtypes, "id").indexOf(newValue?.id);

                                    //         if (index < 0) {

                                    //             selectedCtypes(selectedCtypes.concat(newValue));

                                    //         }

                                    //     } else {
                                    //         setselectedCtypes(selectedCtypes.concat(newValue));
                                    //     }
                                    //     // seterrcat(false);
                                    // } else {
                                    //     // setselectedSizes([]);
                                    //     // seterrcat(false);
                                    // }

                                    // setCountry(value);
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Select a Cleaning Type" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            />

                            {/* <Select className="mt-1.5"
                                onChange={(e) => {
                                    setcleanId(e.target.value);
                                    // seterrcategory(false);
                                }}
                                value={cleanId}

                            >
                                <option value={""}>Select a Cleaning Type</option>
                                {

                                    cleanTypes.map((item, index) => {
                                        return (
                                            <option value={item.id}>{item?.name}</option>

                                        )
                                    })
                                }
                                


                            </Select> */}
                            {errclean && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        {/* <div>
                            <Label className="text-sm">Job Type</Label>
                            <Select className="mt-1.5"
                                onChange={(e) => {
                                    setjobType(e.target.value);
                                    seterrjobType(false);
                                }}
                                value={jobType}
                            >
                                <option value={""} className="" >Select a job type</option>
                                <option value="Seasonal">Seasonal</option>
                                <option value="Casual">Casual</option>
                                <option value="Temporary">Temporary</option>
                                <option value="Fixed">Fixed term contract</option>
                                <option value="Part Time">Permanent – Part Time</option>
                                <option value="Full Time">Permanent – Full time</option>
                                
                            </Select>
                            {errjobType && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}
                        <div>
                            <Label className="text-sm">Country</Label>
                            <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                defaultValue={country}
                                value={country}

                                // id="combo-box-demo"
                                options={["Australia"]}
                                getOptionLabel={(option) => option}
                                onChange={(e, value) => {
                                    setcountry(value);
                                    seterrcountry(false);

                                    // setCountry(value);
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Select a country" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            />

                            {/* <Select className="mt-1.5"
                                onChange={(e) => {
                                    setcountry(e.target.value);
                                    seterrcountry(false);
                                }}
                                value={country}
                            >
                                <option value={""}>Select a country</option>
                                <option value="Australia">Australia</option>

                            </Select> */}
                            {errcountry && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        {/* <div>
                            <Label className="text-sm">Suburb</Label>
                            <Select className="mt-1.5"
                                onChange={(e) => {
                                    sethomeDistrict(e.target.value);
                                    seterrhomeDistrict(false);
                                }}
                                value={homeDistrict}
                            >
                                <option value={""}>Select a Suburb</option>
                                <option value="Australia">Australia</option>
                               
                            </Select>
                            {errhomeDistrict && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}
                        <div>
                            <Label className="text-sm">State</Label>


                            <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                defaultValue={{ name_en: homeDistrict }}
                                value={{ name_en: homeDistrict }}

                                // id="combo-box-demo"
                                options={allHDistricts}
                                getOptionLabel={(option) => option.name_en}
                                onChange={(event, value) => {

                                    handleChangeHdist(event, value);
                                    // setCountry(value);
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Select State" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            />

                            {errhomeDistrict && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}

                        </div>

                        <div>
                            <Label className="text-sm">Suburb / City</Label>


                            {/* <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                // defaultValue={{ fields: { scc_name: homeCity } }}
                                // value={{ fields: { scc_name: homeCity } }}
                                // // id="combo-box-demo"
                                // options={allHCities}
                                // getOptionLabel={(option) => option?.fields?.scc_name}
                                defaultValue={{ locality: homeCity }}
                                value={{ locality: homeCity }}

                                options={newSubs}

                                getOptionLabel={(option) => option?.locality}
                                onChange={(event, value) => {

                                    handleChangeHcity(event, value);
                                    // setCountry(value);
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Select State" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            /> */}

                            <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                // disablePortal
                                // defaultValue={{ fields: { scc_name: homeCity } }}
                                // value={{ fields: { scc_name: homeCity } }}
                                // defaultValue={{ locality: homeCity }}
                                // value={{ locality: homeCity }}
                                value={homeCity}

                                // id="combo-box-demo"
                                // options={allHCities}
                                // options={newSubs2}
                                options={newSubs2}

                                // getOptionLabel={(option) => option?.fields?.scc_name}
                                getOptionLabel={(option) => option}
                                onInputChange={(event, value) => {
                                    console.log(value)

                                    if (value) {

                                        let char = lowerCase(value)

                                        let arr = [...newSubs]

                                        // arr = arr.filter((data) => lowerCase(data.locality).includes(char));

                                        const det = arr.map((item, index) => {

                                            if (lowerCase(item.locality).startsWith(char)) {

                                                return { ...item }
                                            }
                                        })


                                        console.log(">>>>>", _.compact(det))

                                        arr = _.compact(det)

                                        arr = _.map(arr, "locality")

                                        console.log("arr>>", arr)

                                        arr = _.uniq(arr);

                                        setnewSubs2(arr);

                                    } else {
                                        setnewSubs2([])
                                    }
                                }}
                                onChange={(event, value) => {

                                    handleChangeHcity(event, value);
                                    // setCountry(value);
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}

                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Search Suburb" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            />

                            {errhomeCity && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}

                        </div>
                        {/* <div>
                            <Label className="text-sm">Duration</Label>
                            <Input className="mt-1.5"
                                placeholder="Enter duration"
                                onChange={(e) => {
                                    setduration(e.target.value);
                                    seterrduration(false);
                                }}
                                value={duration}
                            />
                            {errduration && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}
                        <div>
                            <Label className="text-sm">Description</Label>
                            <Textarea className="mt-1.5" rows={8}
                                placeholder="Enter description"
                                onChange={(e) => {
                                    setdescription(e.target.value);
                                    seterrdescription(false);
                                }}
                                value={description}
                            />
                            {errdescription && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">Hourly Rate</Label>
                            <div className="w-full flex">
                                <Select className="mt-1.5 w-4/12" rounded="rounded-l-lg"

                                    onChange={(e) => {
                                        setcurrency(e.target.value);
                                        seterrrate(false);
                                    }}
                                    value={currency}
                                >
                                    <option value={""}>Currency</option>
                                    <option value="AUD">AUD</option>

                                </Select>
                                <Input className="mt-1.5" rounded="rounded-r-lg"
                                    placeholder="Enter hourly rate"
                                    type="number"
                                    onChange={(e) => {
                                        setrate(e.target.value);
                                        seterrrate(false);
                                    }}
                                    value={rate}
                                />

                            </div>

                            {errrate && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        {/* <div>
                            <Label className="text-sm">Expiry date</Label>
                            <Input className="mt-1.5" type="date"
                                placeholder="Enter expiry date"
                                onChange={(e) => {
                                    setexpire(e.target.value);
                                    seterrexpire(false);
                                }}
                                value={expire}
                            />
                            {errexpire && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}

                        {/* <div>
                            <Label className="text-sm">Type Keywords</Label>
                            <Input className="mt-1.5"
                                placeholder="#plumber #farmingjobs"

                                onChange={(e) => {
                                    setKeywords(e.target.value);
                                    seterrKeywords(false);
                                }}
                                value={Keywords}
                            />
                            {errKeywords && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}
                        <div className="w-full mt-3 xl:mt-0 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                            <div className="grid grid-cols-10 gap-5 pl-4 pr-5">
                                {images?.map((faker, fakerKey) => (
                                    <>
                                        <div
                                            // key={fakerKey}
                                            className="col-span-5 md:col-span-2 h-28 relative image-fit cursor-pointer zoom-in"
                                        >
                                            <img
                                                className="rounded-md"
                                                alt="Midone - HTML Admin Template"
                                                src={faker.path.includes('/uploads/') === true ? IMAGE_SERVER_URL_DEV + faker?.path : faker?.path}
                                            />
                                            <div
                                                className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 -mr-2 -mt-2"
                                            >
                                                <div
                                                    onClick={(e) => {

                                                        var array = [...images]
                                                        array.splice(fakerKey, 1);
                                                        setImages(array)

                                                    }}
                                                >
                                                    {/* <Lucide icon="X" className="w-4 h-4" /> */}
                                                    <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                                </div>
                                            </div>


                                        </div>
                                        {/* <div className="form-check">
                                            <input id="checkbox-switch-" className="form-check-input w-5 h-5 bg-gray-200 relative rounded-full text-white right-10 top-[80px]" type="checkbox"
                                                checked={faker?.thumbnail}

                                                onChange={(e) => {
                                                    var array = [...images]
                                                    const index = _.map(array, "thumbnail").indexOf(true);

                                                    // console.log("index>>>", index)

                                                    if (index < 0) {
                                                        array[fakerKey].thumbnail = e.target.checked;
                                                        setImages(array)
                                                    } else {
                                                        array[index].thumbnail = false;
                                                        array[fakerKey].thumbnail = e.target.checked;
                                                        setImages(array)
                                                    }
                                                    // array.filter(person => person.thumbnail === true)
                                                    // array[fakerKey].thumbnail = e.target.checked;
                                                    // setImages(array)
                                                    // console.log("<<<<<",e.target.checked)
                                                    // setlatest(e.target.checked)
                                                    // onChangeUserArr(e, arr.privilege)
                                                    // this.onChangeAllArr(e, arr.privilege);
                                                }}
                                            />
                                           


                                        </div> */}
                                    </>

                                ))}
                            </div>
                            <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative text-sm">
                                {/* <Lucide icon="Image" className="w-4 h-4 mr-2" /> */}
                                <i className="las la-image text-xl text-center  mr-2 flex items-center justify-center"></i>
                                <span className="text-primary mr-1">
                                    Upload images
                                </span>{" "}
                                or drag and drop
                                <input
                                    id="horizontal-form-1"
                                    type="file"
                                    multiple
                                    accept={"image/png, image/jpeg"}
                                    className="w-full h-full top-0 left-0 absolute opacity-0"
                                    onChange={(e) => {

                                        Array.from(e.target.files).map(async (item) => {

                                            console.log(">>>>>", item)

                                            let file = item;
                                            let reader = new FileReader();
                                            reader.readAsDataURL(file);
                                            let fileInp = item;
                                            let baseURL;
                                            // on reader load somthing...
                                            const image = await resizeFile(file);

                                            let obj =
                                            {
                                                path: image,
                                                thumbnail: true,
                                                // id: id,
                                            }
                                                ;

                                            console.log(">>>>>", obj);

                                            setImages(existing => [...existing, obj]);


                                            // reader.onload = () => {
                                            //     // Make a fileInfo Object
                                            //     baseURL = reader.result;
                                            //     let obj =
                                            //     {
                                            //         path: baseURL,
                                            //         thumbnail: false,
                                            //         // name: file.name,
                                            //     }
                                            //         ;

                                            //     console.log(">>>>>", obj);

                                            //     setImages(existing => [...existing, obj]);

                                            // };
                                        })



                                    }}
                                />
                                {/* <input
                                    id="horizontal-form-1"
                                    type="file"
                                    className="w-full h-full top-0 left-0 absolute opacity-0"
                                    onChange={(e) => {

                                        let file = e.target.files[0];

                                        let reader = new FileReader();
                                        reader.readAsDataURL(file);
                                        let fileInp = e.target.files[0];
                                        let baseURL;
                                        // on reader load somthing...
                                        reader.onload = () => {
                                            // Make a fileInfo Object
                                            baseURL = reader.result;
                                            let obj =
                                            {
                                                path: baseURL,
                                                thumbnail: false,
                                            }
                                                ;

                                            setImages(images.concat(obj))


                                        };
                                    }}
                                /> */}
                            </div>
                        </div>
                        <div className="items-center justify-center">
                            <BotToast open={bOpen} msg={bMsg} status={bStatus} />
                            <button
                                className={
                                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-4 `}
                                onClick={saveJob}

                            >
                                {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                                <span className="block  text-sm font-medium "
                                >
                                    Save
                                </span>

                            </button>


                        </div>

                    </div>

                </div>
            </div>

        </>
    )

}

export default Jobs;