import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link, NavLink } from "react-router-dom";
import ButtonCircle from "shared/Button/ButtonCircle";
import Company1 from "images/avatars/Image-1.png";
import { StarIcon } from "@heroicons/react/24/solid";
import Rating from '@mui/material/Rating';
import Cover from "images/videoImg.jpeg";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";

import demCover from "images/cover/demo-1.jpg";
import demAvatar from "images/cover/demo-2.jpg";

export interface CardCategory2Props {
  className?: string;
  ratioClass?: string;
  bgClass?: string;
  featuredImage?: string;
  name?: string;
  desc?: string;
  providers?: any;
  data?: any;
}

const CardCategory2: FC<CardCategory2Props> = ({
  className = "",
  ratioClass = "aspect-w-1 aspect-h-1",
  bgClass = "bg-orange-50",
  featuredImage = ".",
  name,
  desc,
  providers = false,
  data
}) => {

  const getState = (item?: any) => {

    if (item === "Victoria") {
      return "VIC"
    } else if (item === "New South Wales") {
      return "NSW"
    } else if (item === "Western Australia") {
      return "WA"
    } else if (item === "Queensland") {
      return "QLD"
    } else if (item === "Tasmania") {
      return "TAS"
    } else if (item === "South Australia") {
      return "SA"
    } else if (item === "Northern Territory") {
      return "NT"
    } else if (item === "Australian Capital Territory") {
      return "ACT"
    }

  }


  return (
    <NavLink to={data ? `/profile-view/${data?.id}` : "/profile"}>
      <div

        className={`nc-CardCategory2 ${className} font-[Inter] `}
        data-nc-id="CardCategory2"
      >
        <div
        // className={`flex-1 relative w-full h-fit rounded-2xl group`}
        >
          <div className="bg-[#F4F4F4] rounded-md">
            <div className="w-full h-20 object-fill bg-cover bg-center rounded-t-md"
              style={{
                // backgroundImage: `url(${Cover})`
                backgroundImage: data?.cover ? `url(${IMAGE_SERVER_URL_DEV}${data?.cover})` : `url(${demCover})`
              }}
            >

            </div>
            <div className="flex justify-start relative z-[1] -mt-12 ml-3 lg:ml-6">
              <div className="w-20 h-20 object-fill bg-contain rounded-full"
                style={{
                  // backgroundImage: `url(${Company1})`
                  backgroundImage: data?.avatar ? `url(${IMAGE_SERVER_URL_DEV}${data?.avatar})` : `url(${demAvatar})`

                }}
              >

              </div>

            </div>
            <div className="container px-2 sm:px-3 lg:px-5 pb-7 pt-3 w-full">


              <div className="flex w-full" >

                {/* <ButtonCircle
              // className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
              size=" w-11 h-11"
              type="submit"
            >
              <i className="las la-apple-alt text-4xl text-left"></i>


            </ButtonCircle> */}



                <div className="h-10 justify-center w-full">
                  <NavLink to={`/profile-view/${data?.id}`}>
                    <h2 className="text-sm sm:text-sm text-neutral-900 dark:text-neutral-100 font-semibold flex justify-start lg:justify-start">

                      {
                        data ? data?.businessName?.slice(0, 18) : "Company description"
                      }

                      {/* {
                        data?.businessName?.length >= 18 ? "...." : ""
                      } */}

                      {/* {data ? data?.businessName : "Company Name"} */}
                    </h2>
                  </NavLink>
                  <span className="block  text-xs text-neutral-500 dark:text-neutral-400">


                    {/* Main category name */}
                  </span>
                  <span className="flex mt-1 sm:mt-3.5 text-xs text-neutral-500 dark:text-neutral-400 justify-start lg:justify-start items-center">
                    <i className="las la-map-marker text-xs text-left mr-2"></i>

                    {data?.homeCity} {getState(data?.homeDistrict)}
                  </span>

                  {/* <div className="flex items-center space-x-0.5 mt-1.5 text-yellow-500">
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
              </div> */}

                </div>

              </div>
              <div className={`flex mt-2 lg:mt-4  ${data?.about === "" ? "h-12" : "h-12 lg:h-9"} w-full`}>
                <div className="flex w-full">
                  <span className="text-xs font-medium w-full">
                    {
                      data ? data?.about?.slice(0, 80) : "Company description - See completed projects and flexible talent strategies from ......."
                    }
                    {
                      data?.about?.length >= 80 ? "......." : ""
                    }
                    {/* {
                      data?.publicLiabilityInsurance === 1 &&

                      <span className="block text-xs text-neutral-500 dark:text-neutral-400">
                        <i className="las la-users text-xs text-left mr-2"></i>

                        Public Liability Insurance
                      </span>

                    } */}

                    {/* {
                      data?.IndemnityInsurance === 1 &&

                      <span className="block text-xs text-neutral-500 dark:text-neutral-400">
                        <i className="las la-shield-alt text-xs text-left mr-2"></i>

                        Indemnity Insurance
                      </span>

                    }

                    {
                      data?.workCoverInsurance === 1 &&


                      < span className="block text-xs text-neutral-500 dark:text-neutral-400">
                        <i className="las la-briefcase text-xs text-left mr-2"></i>

                        Work Cover
                      </span>

                    }

                    {
                      data.workersCompensationInsurance === 1 &&

                      <span className="block text-xs text-neutral-500 dark:text-neutral-400">
                        <i className="las la-hard-hat text-xs text-left mr-2"></i>

                        Workers' Compensation Insurance
                      </span>
                    } */}

                    {
                      data.policeClearanceCertificate === 1 &&


                      <span className="flex justify-start lg:justify-start text-[10px] lg:text-xs text-neutral-500 dark:text-neutral-400 items-center">
                        {/* <i className="las la-certificate text-xs text-left mr-1 lg:mr-2"></i> */}

                        {
                          data.policeClearanceCertificate === 1 ?

                            <i className="fa fa-check-circle text-base lg:text-lg text-left mr-2 text-[#002930]"></i>

                            :

                            <i className="fa fa-times text-base lg:text-lg text-left mr-2 text-red-600"></i>

                        }

                        National Police Check
                      </span>

                    }

                    {
                      data.workingWithChildren === 1 &&

                      <span className="flex justify-start lg:justify-start text-[10px] lg:text-xs text-neutral-500 dark:text-neutral-400 items-center">
                        {/* <i className="las la-baby text-xs text-left mr-1 lg:mr-2"></i> */}

                        {
                          data.workingWithChildren === 1 ?

                            <i className="fa fa-check-circle text-base lg:text-lg text-left mr-2 text-[#002930]"></i>

                            :

                            <i className="fa fa-times text-base lg:text-lg text-left mr-2 text-red-600"></i>

                        }


                        Children Check
                      </span>

                    }


                  </span>
                </div>


              </div>



              <div className="mt-6 flex items-center justify-center w-full" >

                {
                  !providers ?

                    <>
                      <button
                        className={
                          `ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#002930]
        w-3xl px-6 h-9 text-slate-50 font-medium mt-2 cursor-pointer hover:bg-slate-800`}

                        onClick={() => {
                          console.log(">>>>>", "hello")
                        }}

                      >

                        <span className="block  text-xs font-medium">
                          See stocks
                        </span>

                      </button>
                    </>
                    :
                    <>
                      <div className="flex items-center w-full cursor-pointer">

                        {/* <span className="flex justify-end w-full items-center">
                          <span className="text-xs text-[#2d867b]">Visit Page</span>
                          <i className="las la-arrow-alt-circle-right text-xl text-[#2d867b] dark:text-neutral-100 font-medium mt-0 flex justify-end ml-3"></i>
                        </span> */}

                        <button
                          className={
                            `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b] 
        w-full h-9 text-slate-50 font-medium mt-2 cursor-pointer`}

                        >
                          {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
                          <span className="block  text-xs font-medium">
                            Visit Page
                          </span>

                        </button>



                      </div>

                    </>
                }




              </div>



              {/* <img
            className="object-cover "
            src={featuredImage}

          /> */}
              {/* <NcImage
            containerClassName="w-full h-full flex justify-center"
            src={featuredImage}
            className="object-cover rounded-2xl"
          /> */}
            </div>
          </div>
          <div className="pt-5"></div>
          {/* <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span> */}
        </div>
        {/* <div className="mt-5 flex-1 text-center">
        <h2 className="text-base sm:text-base text-neutral-900 dark:text-neutral-100 font-semibold">
          {name}
        </h2>
        <span className="block mt-0.5 sm:mt-1.5 text-sm text-neutral-500 dark:text-neutral-400">
          {desc}
        </span>
      </div> */}
      </div>
    </NavLink >
  );
};

export default CardCategory2;
