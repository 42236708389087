import React, { FC, useState, useEffect, useRef } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL, IMAGE_SERVER_URL_DEV } from "Constant/System";
import Company1 from "images/avatars/Image-1.png";
import ModalQuickView from "components/ModalContract";
import { addToast } from "shared/Toast";
import { GetCleaningTypeContract, getContracts, getJobs } from "Services/API/Get";
import { DeleteContract, DeleteJob } from "Services/API/Delete";
import { useHistory } from "react-router-dom";

import ModalQuickView2 from "components/ModalApplicant";
import { Autocomplete, Tooltip, Zoom } from "@mui/material";
import { UpdateContractStatus, UpdateJobStatus } from "Services/API/Put";


const MyJobs = (props) => {

    const router = useHistory();

    const [images, setImages] = useState([]);
    const [showModalQuickView, setShowModalQuickView] = useState(false);

    const [showModalQuickView2, setShowModalQuickView2] = useState(false);

    const [jobs, setjobs] = useState([]);

    const [search, setsearch] = useState("");
    const [category, setcategory] = useState("");

    const [selectedJob, setselectedJob] = useState("");

    const [currUser, setcurrUser] = useState(props.currUser);

    const [cleanTypes, setcleanTypes] = useState([]);
    const [cleanId, setcleanId] = useState("");

    const getAllJobs = () => {
        console.log(">>>>>>sdsxcddf")
        getContracts({
            // jobType: JSON.stringify([]),
            title: search,
            cleaningType: cleanId ? JSON.stringify([cleanId]) : JSON.stringify([])
        })
            .then((res) => {
                console.log(">>>>>>>jobs", res.data);
                setjobs(res.data.result);
            })
            .catch((error) => {
                // setlopen(false);
                setjobs([]);
                console.log("Error", error.response);
                addToast(error.response.data.message, "warning");
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            })



    }

    useEffect(() => {
        GetCleaningTypeContract()
            .then((res) => {
                console.log("cleanTypes>>>", res);
                setcleanTypes(res.data);
                // setallODistricts(res.data);
            })
            .catch((error) => {
                console.log("Error", error.response);
                setcleanTypes([])
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });
    }, [])


    useEffect(() => {
        getAllJobs();
    }, [showModalQuickView, search, category,cleanId])


    return (
        <>
            <div className="flex justify-center w-full mt-12">

                <span className="block text-xl font-bold flex justify-center w-full text-center">
                    Contracts
                </span>


            </div>
            <div className="w-full flex justify-center px-3">

                <div className="w-full md:w-8/12 lg:w-9/12 xl:w-[55%]">

                    <div className="flex w-full items-center justify-center">
                        <button
                            className={
                                `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-12 `}

                            onClick={() => {
                                // if (currUser?.packageId) {
                                //     setselectedJob("");
                                //     setShowModalQuickView(true)
                                // } else {
                                //     router.push("/subscription")
                                // }

                                setselectedJob("");
                                setShowModalQuickView(true)
                            }}
                        >
                            {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                            <span className="block  text-sm font-medium "
                            >
                                Post a Contract
                            </span>

                        </button>
                    </div>
                    <form className="mt-6 w-full">

                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                            </div>
                            <Input type="search" id="default-search" className="block w-full p-4 pl-10 text-base text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Title..." required
                                onChange={(e) => {
                                    setsearch(e.target.value);
                                }}
                            />

                        </div>
                    </form>

                    <div className="mt-4">
                        <Label className="text-sm">Cleaning Type</Label>

                        <Autocomplete
                            className="mt-1.5"
                            // className="form-group"
                            // className="border-neutral-200"
                            disablePortal
                            // defaultValue={{ name: cleanTypes.find((v) => v.id === cleanId) ? cleanTypes.find((v) => v.id === cleanId).name : "" }}
                            value={{ name: cleanTypes.find((v) => v.id === cleanId) ? cleanTypes.find((v) => v.id === cleanId).name : "" }}

                            // value={{ name: "" }}
                            // options={[{ name: "" }, ...cleanTypes]}
                            // id="combo-box-demo"
                            options={[{ name: "All", id: "" }, ...cleanTypes]}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, newValue) => {
                                setcleanId(newValue.id);
                                // seterrclean(false);

                                // if (newValue.length !== 0) {
                                //     // setselectedSizes(newValue)
                                //     if (selectedCtypes?.length > 0) {

                                //         const index = _.map(selectedCtypes, "id").indexOf(newValue?.id);

                                //         if (index < 0) {

                                //             selectedCtypes(selectedCtypes.concat(newValue));

                                //         }

                                //     } else {
                                //         setselectedCtypes(selectedCtypes.concat(newValue));
                                //     }
                                //     // seterrcat(false);
                                // } else {
                                //     // setselectedSizes([]);
                                //     // seterrcat(false);
                                // }

                                // setCountry(value);
                            }}
                            // sx={{
                            //   // width: "80vw",
                            //   background:"transparent",
                            //   borderRadius: "20px",
                            //   // border: "none",
                            //   // borderColor: "transparent",

                            //   "& .MuiInputBase-input": {
                            //     height: 15,
                            //     // border: "none",
                            //     fontSize: "14px",
                            //     fontFamily: "'Jost', sans-serif",
                            //     // borderColor: "transparent",
                            //   },
                            //   //view
                            //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            //     // borderColor: "transparent",
                            //   },

                            //   "&:hover .MuiOutlinedInput-input": {
                            //     color: "black",
                            //   },
                            //   "&:hover .MuiInputLabel-root": {
                            //     color: COLORS.GrayBackground,
                            //   },
                            //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            //   {
                            //     // borderColor: "transparent",
                            //   },
                            //   ".MuiAutocomplete-root fieldset:hover": {
                            //     color: "transparent",
                            //     // borderColor: "transparent",
                            //   },
                            // }}
                            renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                    <Input {...params.inputProps} className="" placeholder="Select a Cleaning Type" />
                                </div>
                            )}
                            ListboxProps={{
                                style: {
                                    maxHeight: "150px",
                                    fontSize: "14px",
                                    // border: '1px solid white'
                                },
                            }}
                        />

                        {/* <Select className="mt-1.5"
                                onChange={(e) => {
                                    setcleanId(e.target.value);
                                    // seterrcategory(false);
                                }}
                                value={cleanId}

                            >
                                <option value={""}>Select a Cleaning Type</option>
                                {

                                    cleanTypes.map((item, index) => {
                                        return (
                                            <option value={item.id}>{item?.name}</option>

                                        )
                                    })
                                }
                                


                            </Select> */}

                    </div>

                    {/* <div className="w-full mt-4 space-y-4">

                        <div>
                            <Label className="text-sm">Category</Label>
                            <Select className="mt-1.5"
                                onChange={(e) => {
                                    setcategory(e.target.value);
                                    // seterrcountry(false);
                                }}
                                value={category}
                            >
                                <option value={""}>All</option>
                                <option value="Seasonal">Seasonal</option>
                                <option value="Casual">Casual</option>
                                <option value="Temporary">Temporary</option>
                                <option value="Fixed">Fixed term contract</option>
                                <option value="Part Time">Permanent – Part Time</option>
                                <option value="Full Time">Permanent – Full time</option>
                                
                            </Select>
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        

                    </div> */}
                    <div className="mt-10 space-y-2">

                        <div className="flex">
                            <div className="bg-gray-100 flex w-full px-4 py-3 rounded-lg space-x-4 cursor-pointer"
                            // onClick={() => {
                            //     console.log("item>>>>", item)
                            //     setselectedJob(item);
                            //     setShowModalQuickView2(true);

                            // }}
                            >
                                {/* <div className="flex justify-start w-4/12 items-center">
                                    <div className="w-12 h-12 object-fill bg-contain rounded-full border-2 border-white "
                                        // style={{
                                        //     // backgroundImage: `url(${Company1})`
                                        //     backgroundImage: item ? `url(${IMAGE_SERVER_URL_DEV}${item?.avatar})` : `url(${Company1})`
                                        // }}
                                    >

                                    </div>

                                </div> */}
                                <div className="w-6/12 flex justify-start items-center">
                                    <div>
                                        <div className="text-base font-medium flex justify-start items-center">Name</div>

                                        {/* <div className="text-sm font-medium flex justify-start items-center">{item.country}</div> */}

                                    </div>
                                </div>

                                <div className="flex w-full justify-start items-center">
                                    <div>
                                        <div className="text-base font-medium flex justify-start items-center">Cleaning Type</div>

                                        {/* <div className="text-sm font-medium flex justify-start items-center">Active</div> */}
                                    </div>
                                </div>


                                {/* <div className="w-6/12 flex justify-start items-center">
                                                <div>
                                                    <div className="text-base font-medium flex justify-start items-center">Status</div>

                                                    <div className="text-sm font-medium flex justify-start items-center">Active</div>
                                                </div>
                                            </div> */}

                                <div className="w-6/12 flex justify-start items-center">
                                    {/* <div>
                                                    <div className="text-base font-medium flex justify-start items-center">Applicants</div>

                                                    <div className="text-sm font-medium flex justify-start items-center">{item?.noOfApplicants}</div>
                                                </div> */}
                                    <div>
                                        <div className="text-base font-medium flex justify-start items-center">State</div>

                                        {/* <div className="text-sm font-medium flex justify-start items-center">Active</div> */}
                                    </div>
                                </div>

                                <div className="w-6/12 flex justify-start items-center">
                                    {/* <div>
                                                    <div className="text-base font-medium flex justify-start items-center">Applicants</div>

                                                    <div className="text-sm font-medium flex justify-start items-center">{item?.noOfApplicants}</div>
                                                </div> */}
                                    <div>
                                        <div className="text-base font-medium flex justify-start items-center">Suburb</div>

                                        {/* <div className="text-sm font-medium flex justify-start items-center">Active</div> */}
                                    </div>
                                </div>

                                <div className="w-6/12 flex justify-start items-center">

                                    <div>
                                        <div className="text-base font-medium flex justify-start items-center">Status</div>

                                        {/* <div className="text-sm font-medium flex justify-start items-center">Active</div> */}
                                    </div>
                                </div>


                            </div>
                            <div className="flex items-center">
                                <i className="las la-eye text-xl text-left text-transparent ml-2 cursor-pointer"
                                // onClick={() => {
                                //     console.log("item>>>>", item)
                                //     setselectedJob(item);
                                //     setShowModalQuickView(true);

                                // }}
                                ></i>
                                <i className="las la-pencil-alt text-xl text-left text-transparent ml-2 cursor-pointer"
                                // onClick={() => {
                                //     console.log("item>>>>", item)
                                //     setselectedJob(item);
                                //     setShowModalQuickView(true);

                                // }}
                                ></i>
                                <i className="las la-trash-alt text-xl text-left ml-2 text-transparent cursor-pointer"
                                // onClick={() => {
                                //     DeleteContract(item?.id)
                                //         .then((res) => {

                                //             addToast("Contract successfully deleted", "success");
                                //             getAllJobs();
                                //         })
                                //         .catch((error) => {
                                //             // setlopen(false);
                                //             console.log("Error", error.response);
                                //             addToast(error.response.data.message, "warning");
                                //             // setOpenToast(true);
                                //             // setColor(COLORS.Orange);
                                //             // setAlertClass("error");
                                //             // setMsg(error.response.data.message);
                                //         })
                                // }}
                                ></i>
                            </div>

                        </div>
                        {
                            jobs.map((item, index) => {
                                return (
                                    <div className="flex">
                                        <div className="bg-gray-100 flex w-full px-4 py-3 rounded-lg space-x-4 cursor-pointer"
                                        // onClick={() => {
                                        //     console.log("item>>>>", item)
                                        //     setselectedJob(item);
                                        //     setShowModalQuickView2(true);

                                        // }}
                                        >
                                            {/* <div className="flex justify-start w-4/12 items-center">
                                                <div className="w-12 h-12 object-fill bg-contain rounded-full border-2 border-white "
                                                    style={{
                                                        // backgroundImage: `url(${Company1})`
                                                        backgroundImage: item ? `url(${IMAGE_SERVER_URL_DEV}${item?.avatar})` : `url(${Company1})`
                                                    }}
                                                >

                                                </div>

                                            </div> */}
                                            <div className="w-6/12 flex justify-start items-center"
                                                onClick={() => {
                                                    console.log("item>>>>", item)
                                                    setselectedJob(item);
                                                    setShowModalQuickView2(true);

                                                }}
                                            >
                                                <div>
                                                    <div className="text-base font-medium flex justify-start items-center">{item.title}</div>

                                                    {/* <div className="text-sm font-medium flex justify-start items-center">{item.country}</div> */}

                                                </div>
                                            </div>

                                            <div className="w-full flex justify-start items-center"

                                                onClick={() => {
                                                    console.log("item>>>>", item)
                                                    setselectedJob(item);
                                                    setShowModalQuickView2(true);

                                                }}>
                                                <div>
                                                    <div className="text-base font-medium flex justify-start items-center">{item.name}</div>

                                                    {/* <div className="text-sm font-medium flex justify-start items-center">Active</div> */}
                                                </div>
                                            </div>


                                            {/* <div className="w-6/12 flex justify-start items-center">
                                                <div>
                                                    <div className="text-base font-medium flex justify-start items-center">Status</div>

                                                    <div className="text-sm font-medium flex justify-start items-center">Active</div>
                                                </div>
                                            </div> */}

                                            <div className="w-6/12 flex justify-start items-center"
                                                onClick={() => {
                                                    console.log("item>>>>", item)
                                                    setselectedJob(item);
                                                    setShowModalQuickView2(true);

                                                }}
                                            >
                                                {/* <div>
                                                    <div className="text-base font-medium flex justify-start items-center">Applicants</div>

                                                    <div className="text-sm font-medium flex justify-start items-center">{item?.noOfApplicants}</div>
                                                </div> */}
                                                <div>
                                                    <div className="text-base font-medium flex justify-start items-center">{item.state}</div>

                                                    {/* <div className="text-sm font-medium flex justify-start items-center">Active</div> */}
                                                </div>
                                            </div>

                                            <div className="w-6/12 flex justify-start items-center"
                                                onClick={() => {
                                                    console.log("item>>>>", item)
                                                    setselectedJob(item);
                                                    setShowModalQuickView2(true);

                                                }}
                                            >
                                                {/* <div>
                                                    <div className="text-base font-medium flex justify-start items-center">Applicants</div>

                                                    <div className="text-sm font-medium flex justify-start items-center">{item?.noOfApplicants}</div>
                                                </div> */}
                                                <div>
                                                    <div className="text-base font-medium flex justify-start items-center">{item.suburb}</div>

                                                    {/* <div className="text-sm font-medium flex justify-start items-center">Active</div> */}
                                                </div>
                                            </div>

                                            <div className="w-6/12 flex justify-start items-center">
                                                <label class="relative inline-flex items-center cursor-pointer mt-3">
                                                    <input type="checkbox" class="sr-only peer"
                                                        onChange={(e) => {
                                                            console.log(e.target.checked)
                                                            // setstatus(e.target.checked)


                                                            let obj = {
                                                                isActive: e.target.checked ? 1 : 0
                                                            }

                                                            UpdateContractStatus(obj, item?.id)
                                                                .then((res) => {
                                                                    getAllJobs();
                                                                })
                                                                .catch((error) => {


                                                                    addToast(error.response.data.message, "warning");
                                                                    // setOpenToast(true);
                                                                    // setColor(COLORS.Orange);
                                                                    // setAlertClass("error");
                                                                    // setMsg(error.response.data.message);
                                                                })


                                                        }}
                                                        checked={item?.isActive === 1}
                                                        id="product-status-active"

                                                    />
                                                    <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#2d867b]"></div>
                                                    <label class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer" htmlFor="product-status-active">Active</label>
                                                </label>
                                            </div>


                                        </div>
                                        <div className="flex items-center">
                                            <Tooltip title={"View contracts"} placement="top" TransitionComponent={Zoom} arrow >
                                                <i className="las la-eye text-xl text-left ml-2 cursor-pointer"
                                                    onClick={() => {
                                                        console.log("item>>>>", item)
                                                        setselectedJob(item);
                                                        setShowModalQuickView2(true);

                                                    }}
                                                ></i>
                                            </Tooltip>
                                            <Tooltip title={"Edit"} placement="top" TransitionComponent={Zoom} arrow >
                                                <i className="las la-pencil-alt text-xl text-left ml-2 cursor-pointer"
                                                    onClick={() => {
                                                        console.log("item>>>>", item)
                                                        setselectedJob(item);
                                                        setShowModalQuickView(true);

                                                    }}
                                                ></i>
                                            </Tooltip>
                                            <Tooltip title={"Delete"} placement="top" TransitionComponent={Zoom} arrow >

                                                <i className="las la-trash-alt text-xl text-left ml-2 text-red-500 cursor-pointer"
                                                    onClick={() => {
                                                        DeleteContract(item?.id)
                                                            .then((res) => {

                                                                addToast("Contract successfully deleted", "success");
                                                                getAllJobs();
                                                            })
                                                            .catch((error) => {
                                                                // setlopen(false);
                                                                console.log("Error", error.response);
                                                                addToast(error.response.data.message, "warning");
                                                                // setOpenToast(true);
                                                                // setColor(COLORS.Orange);
                                                                // setAlertClass("error");
                                                                // setMsg(error.response.data.message);
                                                            })
                                                    }}
                                                ></i>
                                            </Tooltip>
                                        </div>

                                    </div>
                                );
                            })
                        }

                    </div>

                </div>
            </div >

            <ModalQuickView
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
                selectedJob={selectedJob}
            />

            <ModalQuickView2
                show={showModalQuickView2}
                onCloseModalQuickView={() => setShowModalQuickView2(false)}
                selectedJob={selectedJob}
                type={"contract"}

            />

        </>
    )

}

export default MyJobs;