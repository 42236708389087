import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
// import ProductCard from "components/ProductCard";
import ProductCardNew from "components/ProductCardNew";
import { PRODUCTS } from "data/data";
import SidebarFilters from "./SidebarFiltersNew";
import Pagination from "shared/Pagination/PaginationNew";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {
  BrandsForShop,
  Categories, CategoriesForShop,
  GetSubcategory1ForShop, GetSubcategory2ForShop, GetSubcategory3ForShop, MaterialsForShop, getAllProducts
} from "Services/API/Get";
import _ from 'lodash';
import LoadingSpinner from "shared/LoadingSpinner";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import TabFilters from "./TabFilters";
import ProductCard from "components/CardCategories/ProductCard";
import { NavLink } from "react-router-dom";


export interface PageCollection2Props {
  className?: string;
  proId?: any;
}


const PageCollection2: FC<PageCollection2Props> = ({ className = "", proId = "" }) => {

  const [nProducts, setnProducts] = useState([]);
  const [allSizes, setallSizes] = useState<any | null>([]);
  const [allColours, setallColours] = useState<any | null>([]);
  const [allSubs, setallSubs] = useState<any | null>([]);
  const [categories, setcategories] = useState([]);
  const [tags, settags] = useState<any | null>([]);
  const [selectedSize, setselectedSize] = useState("");
  const [selectedColour, setselectedColour] = useState("");
  const [selectedCat, setselectedCat] = useState<any | null>([]);
  const [minPrice, setminPrice] = useState<any | null>(0);
  const [maxPrice, setmaxPrice] = useState<any | null>(999);
  const [selectedSub, setselectedSub] = useState<any>([]);

  const [selectedSubCat, setselectedSubCat] = useState([]);
  const [dataCount, setcount] = useState(0);
  const [search, setsearch] = useState("");
  const [lopen, setlopen] = useState(true);

  const [mainDesc, setmainDesc] = useState<any>("");
  const [subDesc, setsubDesc] = useState("");
  const [filterSortValue, setFilterSortValue] = useState("");
  const [filterSortIndex, setfilterSortIndex] = useState(0);

  const [pageLimit, setpageLimit] = useState(9);
  const [currentPage, setCurrentPage] = useState(1);

  const [Scat, setScat] = useState(false);

  const [allSubs2, setallSubs2] = useState<any | null>([]);
  const [allSubs3, setallSubs3] = useState<any | null>([]);

  const [selectedSub2, setselectedSub2] = useState<any>([]);
  const [selectedSub3, setselectedSub3] = useState<any>([]);

  const [allMaterial, setallMaterial] = useState<any | null>([]);
  const [selectedMaterial, setselectedMaterial] = useState("");

  const [allBrand, setallBrand] = useState<any | null>([]);
  const [selectedBrand, setselectedBrand] = useState("");

  const getallCategory = () => {

    CategoriesForShop({
      sellerId: "",
      hire: "0"
    })
      .then((response) => {
        console.log("category>>>>", response?.data);
        setcategories(response.data);
        // this.setState(
        //   {
        //     array: res.data.result,
        //     filter: res.data.result,
        //     showPages: m,
        //     showOff: true,
        //   },
        //   async () => {
        //     await this.getPaginationGroup(m);
        //   }
        // );
      })
      .catch(() => {
        setcategories([]);
        // this.setState({
        //   array: [],
        //   filter: [],
        //   showPages: 1,
        //   showOff: false,
        // });
      });


    MaterialsForShop({
      sellerId: "",
      hire: "0"
    })
      .then((response) => {
        console.log("material>>>>", response?.data);
        setallMaterial(response.data);
        // this.setState(
        //   {
        //     array: res.data.result,
        //     filter: res.data.result,
        //     showPages: m,
        //     showOff: true,
        //   },
        //   async () => {
        //     await this.getPaginationGroup(m);
        //   }
        // );
      })
      .catch(() => {
        setallMaterial([]);
        // this.setState({
        //   array: [],
        //   filter: [],
        //   showPages: 1,
        //   showOff: false,
        // });
      });

    BrandsForShop({
      sellerId: "",
      hire: "0"
    })
      .then((response) => {
        console.log("brand>>>>", response?.data);
        setallBrand(response.data);
        // this.setState(
        //   {
        //     array: res.data.result,
        //     filter: res.data.result,
        //     showPages: m,
        //     showOff: true,
        //   },
        //   async () => {
        //     await this.getPaginationGroup(m);
        //   }
        // );
      })
      .catch(() => {
        setallBrand([]);
        // this.setState({
        //   array: [],
        //   filter: [],
        //   showPages: 1,
        //   showOff: false,
        // });
      });

  }

  useEffect(() => {
    getallCategory();

  }, [])

  const SubForSHop = () => {

    GetSubcategory1ForShop({
      mainId: JSON.stringify(selectedCat),
      sellerId: "",
      hire: "0"
    })
      .then((response) => {
        console.log("ssubcategory>>>>", response?.data);
        setallSubs(response.data);
        // this.setState(
        //   {
        //     array: res.data.result,
        //     filter: res.data.result,
        //     showPages: m,
        //     showOff: true,
        //   },
        //   async () => {
        //     await this.getPaginationGroup(m);
        //   }
        // );
      })
      .catch(() => {
        setallSubs([])
        // this.setState({
        //   array: [],
        //   filter: [],
        //   showPages: 1,
        //   showOff: false,
        // });
      });

    GetSubcategory2ForShop({
      mainId: JSON.stringify(selectedCat),
      subId: JSON.stringify(selectedSub),
      sellerId: "",
      hire: "0"
    })
      .then((response) => {
        console.log("ssubcategory>>>>", response?.data);
        setallSubs2(response.data);
        // this.setState(
        //   {
        //     array: res.data.result,
        //     filter: res.data.result,
        //     showPages: m,
        //     showOff: true,
        //   },
        //   async () => {
        //     await this.getPaginationGroup(m);
        //   }
        // );
      })
      .catch(() => {
        setallSubs2([])
        // this.setState({
        //   array: [],
        //   filter: [],
        //   showPages: 1,
        //   showOff: false,
        // });
      });

    GetSubcategory3ForShop({
      mainId: JSON.stringify(selectedCat),
      subId: JSON.stringify(selectedSub),
      sub2Id: JSON.stringify(selectedSub2),
      sellerId: "",
      hire: "0"
    })
      .then((response) => {
        console.log("ssubcategory3>>>>", response?.data);
        setallSubs3(response.data);
        // this.setState(
        //   {
        //     array: res.data.result,
        //     filter: res.data.result,
        //     showPages: m,
        //     showOff: true,
        //   },
        //   async () => {
        //     await this.getPaginationGroup(m);
        //   }
        // );
      })
      .catch(() => {
        setallSubs3([])
        // this.setState({
        //   array: [],
        //   filter: [],
        //   showPages: 1,
        //   showOff: false,
        // });
      });

  }


  useEffect(() => {
    SubForSHop();

  }, [selectedCat, selectedSub, selectedSub2])

  useEffect(() => {

    const fetchData = async () => {
      const data = await getFilters();
      const data2 = await getItemProducts2();
    }


    fetchData();


  }, [selectedCat])

  useEffect(() => {
    // if (allSubs.length !== 0) {
      const fetchData = async () => {
        const data2 = await getItemProducts();
      }

      fetchData();
    // }

  }, [selectedSub, allSubs, filterSortValue, pageLimit, currentPage, selectedColour, selectedSize, minPrice, maxPrice, search, selectedSub2, selectedSub3, selectedBrand, selectedMaterial])

  useEffect(() => {
    console.log("sub cats>>>>>", selectedSubCat)

  }, [selectedSubCat])

  const getFilters = async () => {

    const ob = {
      sort: {
        sortField: "productName",
        sort: "asc",
      },
      categories: JSON.stringify(selectedCat),
      distinct: "0",
      userId: proId,
    };
    // setlopen(true);

    await getAllProducts(ob).then((res) => {

      console.log("FFF>>", res)
      const allColors = _.uniqBy(res?.data?.result, function (e: { colour: any; }) {
        return e.colour;
      });
      var allColors2 = allColors?.filter(function (el: any) {
        return el.colour !== "";
      });
      const allSizes = _.uniqBy(res?.data?.result, function (e: { size: any; }) {
        return e.size;
      });
      var sizeArr = allSizes?.filter(function (el: { size: string; }) {
        return el.size !== "";
      });
      const allPrice = _.uniqBy(res?.data?.result, function (e: { discountedprice: any; }) {
        return e.discountedprice;
      });
      const allTags = _.uniqBy(res?.data?.result, function (e: { tags: any; }) {
        return e.tags;
      });
      const allSubs = _.uniqBy(res?.data?.result, function (e: {
        subcatId: any;
        subCategory: any;
        subcatDesc: any;
      }) {
        return e.subCategory;
      });

      console.log("WWEDSD", allSubs)

      var subsArr = allSubs?.filter(function (el: { subCategory: null; }) {
        return el.subCategory !== null;
      });
      const myPrices = _.map(allPrice, "discountedprice");

      const Pricelist = _.sortBy(myPrices, [
        function (o: any) {
          return o;
        },
      ]);

      const mySizes = _.map(sizeArr, "size");
      const myColors = _.map(allColors2, "colour");
      const myTags = _.map(allTags, "tags");
      const mySubs = _.map(subsArr, "subCategory");

      const updateMyColors = myColors?.map((item: any, index: any) => {
        return {
          id: index,
          name: item,
          status: false,
        };
      });

      const updateMySubs = subsArr.map((item, index) => {

        return {
          id: item.subcatId,
          name: item.subCategory,
          // description: item.subcatDesc,
        };



      });
      console.log("subs>>>>>", myColors)
      setallSizes(mySizes);
      setallColours(myColors);
      settags(myTags);
      // setallSubs(updateMySubs);

      let Id2: any = localStorage.getItem("sSub");
      setselectedSub(Id2 ? parseInt(Id2) : "");
      // this.setState({
      //   color: updateMyColors,
      //   size: mySizes,
      //   min: Pricelist[0],
      //   max: Pricelist[Pricelist.length - 1],
      //   range: {
      //     min: Pricelist[0],
      //     max: Pricelist[Pricelist.length - 1],
      //   },
      // });
    })
      .catch(() => {
        setlopen(false);
      });



  }

  const getItemProducts = async () => {
    let sort = {


      sortField: "productName",
      sort: "asc",

    }
    let latest = ""
    let featured = ""

    if (filterSortValue === "HighToLow") {

      sort = {


        sortField: "unitPrice",
        sort: "desc",

      }
    } else if (filterSortValue === "LowToHigh") {

      sort = {


        sortField: "unitPrice",
        sort: "asc",

      }
    } else if (filterSortValue === "latest") {
      latest = "1"
    } else if (filterSortValue === "featured") {
      featured = "1"
    } else {
      sort = {


        sortField: "productName",
        sort: "asc",

      }
      latest = ""
      featured = ""
    }





    // console.log(sortType)

    const obj = {

      sort: sort,
      categories: JSON.stringify(selectedCat),
      subId: JSON.stringify(selectedSub),
      sub2Id: JSON.stringify(selectedSub2),
      sub3Id: JSON.stringify(selectedSub3),
      search: search,
      latest: latest,
      featured: featured,
      pNo: currentPage.toString(),
      limit: pageLimit.toString(),
      color: selectedColour,
      size: selectedSize,
      pto: maxPrice,
      pfrom: minPrice,
      userId: proId,
      hire: "0",
      brand: selectedBrand,
      material: selectedMaterial,

    };
    console.log("><<<<<<<<<<<<", obj);
    try {
      await getAllProducts(obj)
        .then((response) => {
          // setlopen(false);
          console.log(">>>>>>>>>>>>>>>>>>", response?.data);

          let m =
            Math.ceil(parseFloat(response.data.count[0].pages)) === 0
              ? 1
              : Math.ceil(parseFloat(response.data.count[0].pages));

          setnProducts(response.data.result);
          setcount(response.data.count[0].count);
          // this.setState(
          //   {
          //     array: res.data.result,
          //     filter: res.data.result,
          //     showPages: m,
          //     showOff: true,
          //   },
          //   async () => {
          //     await this.getPaginationGroup(m);
          //   }
          // );
        })
        .catch(() => {
          setnProducts([]);
          setcount(0);
          // setlopen(false);
          // this.setState({
          //   array: [],
          //   filter: [],
          //   showPages: 1,
          //   showOff: false,
          // });
        });
    } catch (error) {
      console.log("T&C ", error);
    }
  }

  const getItemProducts2 = async () => {
    let sort = {


      sortField: "productName",
      sort: "asc",

    }
    let latest = ""
    let featured = ""

    if (filterSortValue === "HighToLow") {

      sort = {


        sortField: "unitPrice",
        sort: "desc",

      }
    } else if (filterSortValue === "LowToHigh") {

      sort = {


        sortField: "unitPrice",
        sort: "asc",

      }
    } else if (filterSortValue === "latest") {
      latest = "1"
    } else if (filterSortValue === "featured") {
      featured = "1"
    } else {
      sort = {


        sortField: "productName",
        sort: "asc",

      }
      latest = ""
      featured = ""
    }





    // console.log(sortType)

    const obj = {

      sort: sort,
      categories: JSON.stringify(selectedCat),
      subId: JSON.stringify(selectedSub),
      sub2Id: JSON.stringify(selectedSub2),
      sub3Id: JSON.stringify(selectedSub3),
      search: search,
      latest: latest,
      featured: featured,
      pNo: currentPage.toString(),
      limit: pageLimit.toString(),
      color: selectedColour,
      size: selectedSize,
      pto: maxPrice,
      pfrom: minPrice,
      userId: proId,
      hire: "0",
      brand: selectedBrand,
      material: selectedMaterial,

    };
    console.log("><<<<<<<<<<<<", obj);
    try {
      // setlopen(true);
      await getAllProducts(obj)
        .then((response) => {
          setlopen(false);
          console.log(">>>>>>>>>>>>>>>>>>", response?.data);

          let m =
            Math.ceil(parseFloat(response.data.count[0].pages)) === 0
              ? 1
              : Math.ceil(parseFloat(response.data.count[0].pages));

          setnProducts(response.data.result);
          setcount(response.data.count[0].count);
          // this.setState(
          //   {
          //     array: res.data.result,
          //     filter: res.data.result,
          //     showPages: m,
          //     showOff: true,
          //   },
          //   async () => {
          //     await this.getPaginationGroup(m);
          //   }
          // );
        })
        .catch(() => {
          setnProducts([]);
          setcount(0);
          setlopen(false);
          // this.setState({
          //   array: [],
          //   filter: [],
          //   showPages: 1,
          //   showOff: false,
          // });
        });
    } catch (error) {
      console.log("T&C ", error);
    }
  }


  useEffect(() => {
    console.log("selected cat>>>>", selectedCat)
  }, [selectedCat])

  useEffect(() => {
    console.log("param>>>", localStorage.getItem("sMain"));

    let Id: any = localStorage.getItem("sMain");
    let Id2: any = localStorage.getItem("sSub");




    if (((Id && Id !== undefined)
      // || (Id2 && Id2 !== undefined)
    ) && (categories.length !== 0 && nProducts.length !== 0)) {
      setselectedCat([parseInt(Id)]);
      // setselectedSub(Id2 ? parseInt(Id2) : "");
      setScat(true);


      Categories()
        .then((response) => {

          var subsArr = response?.data?.filter(function (el: { mainId: number; }) {

            return el.mainId === parseInt(Id!);
          });

          console.log("MAINNNNNNNNN", subsArr)
          setmainDesc(subsArr[0])

        })
        .catch(() => {

        });





    }

    // return () => {
    //   localStorage.removeItem("sMain");
    // };

  }, [categories, nProducts])

  useEffect(() => {
    if (Scat === true) {
      localStorage.removeItem("sMain");
      localStorage.removeItem("sSub");
      setScat(false);
    }

    // return () => {
    //   localStorage.removeItem("sMain");
    //   localStorage.removeItem("sSub");
    // }
  }, [Scat])



  return (
    <div
      className={`nc-PageCollection2 ${className}`}
      data-nc-id="PageCollection2"
    >
      <LoadingSpinner open={lopen} />
      <Helmet>
        <title>Shop || Skillnet</title>
      </Helmet>

      <div className="w-full h-[40px] md:h-[45px] xl:h-[35px] 2xl:h-[55px] bg-cover bg-center bg-[#002930] relative z-[1]"
      // style={{
      //   backgroundImage: `url(${cover})`
      // }}
      >
        <div className="w-full h-full flex justify-center items-center">
          <div className="" >
            {/* <h2 className="nc-SectionHero2Item__heading font-semibold text-xl xl:text-xl 2xl:text-3xl !leading-[114%] text-slate-50 flex justify-center">
              Service Providers
            </h2> */}

<span className="nc-SectionHero2Item__subheading block text-xs sm:text-sm text-slate-100 font-medium flex justify-center max-w-3xl text-center px-3 lg:px-0">
              New to the platform ? &nbsp; <NavLink to={"/signup"} ><span className="text-[#F1A700]">Open your account for FREE</span></NavLink>
            </span>
            {/* <div className="w-full flex items-center justify-center">
              <button
                className={
                  `ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70 
        w-3xl px-6 h-9 font-medium mt-2 cursor-pointer hover:bg-slate-800 w-2xl bg-white text-slate-900 hover:text-slate-50 mt-4`}



              >

                <span className="block  text-xs font-medium">
                  Create Page
                </span>

              </button>

            </div> */}


          </div>

        </div>



      </div>

      <div className="container py-16 lg:pb-0 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          {/* <div className="w-full">
            <h2 className="block text-2xl sm:text-2xl lg:text-4xl font-semibold">
              
              {mainDesc?.maincategory}
            </h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base text-justify">
             
              {mainDesc?.description}
            </span>
          </div> */}

          {/* <hr className="border-slate-200 dark:border-slate-700" /> */}
          <main>
            {/* LOOP ITEMS */}
            <div className="flex flex-col lg:flex-row">
              <div className="lg:w-1/3 xl:w-1/4 pr-4 space-y-6">
                <div className="w-full flex justify-between">
                  <Input
                    type="text"
                    bgClass="bg-[#F4F4F4]"
                    placeholder="Search Product Name"
                    className="mt-1 max-w-sm"
                    onChange={(e: any) => {
                      setsearch(e.target.value);

                      // seterrfirstName(false);
                    }}
                    value={search}
                  />

                </div>
                <SidebarFilters
                  categories={categories}
                  setSelectedcat={setselectedCat}
                  selectedCat={selectedCat}
                  allSubs={allSubs}
                  setselectedSub={setselectedSub}
                  selectedSub={selectedSub}
                  allSizes={allSizes}
                  selectedSize={selectedSize}
                  setselectedSize={setselectedSize}
                  allColours={allColours}
                  selectedColour={selectedColour}
                  setselectedColour={setselectedColour}
                  setminPrice={setminPrice}
                  minPrice={minPrice}
                  setmaxPrice={setmaxPrice}
                  maxPrice={maxPrice}
                  filterSortValue={filterSortValue}
                  setFilterSortValue={setFilterSortValue}
                  setCurrentPage={setCurrentPage}
                  setmainDesc={setmainDesc}
                  setsearch={setsearch}
                  search={search}
                  allSubs2={allSubs2}
                  allSubs3={allSubs3}
                  selectedSub2={selectedSub2}
                  selectedSub3={selectedSub3}
                  setselectedSub2={setselectedSub2}
                  setselectedSub3={setselectedSub3}
                />
              </div>
              {/* <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mx-4 border-t lg:border-t-0"></div> */}
              <div className="flex-1 ">
                <div className="">
                  <div className="relative flex flex-col pb-8">
                    <div className="w-full flex justify-between">

                      <TabFilters
                        categories={categories}
                        setSelectedcat={setselectedCat}
                        selectedCat={selectedCat}
                        allSubs={allSubs}
                        setselectedSub={setselectedSub}
                        selectedSub={selectedSub}
                        allSizes={allSizes}
                        selectedSize={selectedSize}
                        setselectedSize={setselectedSize}
                        allColours={allColours}
                        selectedColour={selectedColour}
                        setselectedColour={setselectedColour}
                        setminPrice={setminPrice}
                        minPrice={minPrice}
                        setmaxPrice={setmaxPrice}
                        maxPrice={maxPrice}
                        filterSortValue={filterSortValue}
                        setFilterSortValue={setFilterSortValue}
                        setCurrentPage={setCurrentPage}
                        setmainDesc={setmainDesc}
                        setsearch={setsearch}
                        search={search}
                        allMaterial={allMaterial}
                        selectedMaterial={selectedMaterial}
                        allBrand={allBrand}
                        selectedBrand={selectedBrand}
                        setselectedMaterial={setselectedMaterial}
                        setselectedBrand={setselectedBrand}
                      />

                      {/* <Select className="mt-1.5 w-5/12 sm:w-3/12 mb-6"
                        onChange={(e) => {
                          setFilterSortValue(e.target.value);

                        }}
                        value={filterSortValue}
                      >
                        <option value={""}>Sort Order</option>
                        <option value="">None</option>
                        <option value="featured">Most Popular</option>
                        <option value="latest">Newest</option>
                        <option value="LowToHigh">Price Low - High</option>
                        <option value="HighToLow">Price High - Low</option>


                  
                      </Select> */}
                    </div>

                    <div className="flex-1 grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-x-3 sm:gap-x-8 sm:gap-y-10 ">
                      {nProducts?.length > 0 && nProducts?.map((item, index) => (
                        <ProductCard data={item} key={index} />
                      ))}
                      {nProducts.length === 0 && <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
                        No Products found.
                      </span>}
                    </div>

                  </div>

                </div>

                {/* PAGINATION */}
                <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                  <Pagination
                    pageLimit={pageLimit}
                    dataCount={dataCount}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                  <ButtonPrimary
                    // loading
                    onClick={() => {

                      setpageLimit(pageLimit + 3);
                    }}
                  >Show me more</ButtonPrimary>
                </div>
              </div>
            </div>

          </main>
        </div>

        {/* === SECTION 5 === */}
        {/* <hr className="border-slate-200 dark:border-slate-700" />

        <SectionSliderCollections /> */}
        <hr className="border-slate-200 dark:border-slate-700" />

        {/* SUBCRIBES */}

      </div>
      <SectionPromo1 />
    </div>
  );
};

export default PageCollection2;
