import rightImg from "images/hero-right1.png";
import rightImg1 from "images/service/4.jpg";
import rightImg2 from "images/service/2.jpg";
import rightImg3 from "images/service/3.jpg";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Vaccum Cleaners || Skillnet</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      {/* <BgGlassmorphism /> */}

      <div className="container py-16 lg:py-28 space-y-8 lg:space-y-14 ">
        {/* <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        /> */}


        <div className="flex items-center justify-items-center justify-center grid grid-cols-1 lg:grid-cols-4 gap-10 ">

          <div className="col-span-3 ...">

            <h2 className="text-xl font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100">
              VACUUM CLEANER SERVICE AND REPAIRS
            </h2>
            <div className="text-justify">
              <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-6">
                With a dedicated team of service technicians who will go always go the extra mile for our customers. We are Melbourne’s leading vacuum repair specialists.
              </span>
              <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                Wherever you are located in Melbourne, we offer affordable service repair options that ensures that your commercial vacuum continues to operate optimally.
              </span>
              <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                With quick repair turnaround times, our service technicians will bring your vacuum working like new in no time! We 100 percent guarantee you’ll be delighted with us. With a strong reputation for outstanding customer service, we take great pride in our service work.
              </span>
            </div>
          </div>
          <div className="flex-grow w-full">
            <img className="w-full ml-9" src={rightImg1} alt="" />
          </div>
        </div>

        <div className="flex items-center justify-items-center justify-center grid grid-cols-1 lg:grid-cols-4 gap-10 ">

          <div className="col-span-3 ...">

            <h2 className="text-base font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100">
              The Vacuum Repair Experts
            </h2>
            <div className="text-justify">
              <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-6">
                Vacuum cleaners for repair are only handled by our senior team of qualified service technicians who have extensive hands-on experience in repairing all brands of industrial vacuums. We carry many common spare parts in stock to repair most makes and models of commercial vacuum cleaners. We support brands including Tennant, Nilfisk, Pullman and Pacvac.
              </span>
              <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                Our process starts by comprehensively assessing the condition of your vacuum and the repair required, providing a FREE QUOTE. Once our quote is approved we will proceed, as we carry motors, filters and most spare parts that we need in our warehouse. You’ll then have your vacuum cleaner back in no time!
              </span>
              <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                Our qualified team never compromise on machine performance. We only use new and original parts, aiming to match each manufacturer’s specifications.
              </span>
              <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                We offer a 90 day back to base warranty on (motor) repairs to ensure that you have complete peace of mind; inclusive of all parts and workmanship.
              </span>
            </div>
          </div>
          <div className="flex-grow">
            <img className="w-full ml-9" src={rightImg3} alt="" />
          </div>
        </div>







        {/* <SectionFounder /> */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div> */}

        {/* <SectionStatistic /> */}

        {/* <SectionPromo3 /> */}
      </div>
    </div>
  );
};

export default PageAbout;
