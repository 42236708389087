import React, {
  FC,
  useState,
  useEffect,
  useRef,
  Fragment,
  useContext
} from "react";
import Stripe from "images/sLogo.png";
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import {
  IMAGE_SERVER_URL,
  IMAGE_SERVER_URL_DEV,
  IMAGE_SERVER_URL_DEV2
} from "Constant/System";
import _, { lowerCase, map, upperFirst } from "lodash";
import { postAds, postJobs } from "Services/API/Post";
import { addToast } from "shared/Toast";
import {
  CurrentUser,
  GetUserId,
  getCities,
  getDistricts,
  getJobDetails,
  getOrderDetails,
  getProfiles,
  getUserIp,
  getUserLocation,
  getWriterReviewsById
} from "Services/API/Get";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Box,
  Rating
} from "@mui/material";
import { UpdateAds, UpdateJobs, UpdatePackage } from "Services/API/Put";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import moment from "moment";
import SubData from "data/australian_postcodes.json";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoadingSpinner from "shared/LoadingSpinner";
import DatePicker from "react-multi-date-picker";
import BotToast from "shared/BotToast";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import JobsCard from "components/CardCategories/JobsCard";
import { t } from "i18next";

import { Dialog, Popover, Transition } from "@headlessui/react";
import Checkbox from "shared/Checkbox/Checkbox";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import WishContext from "Context/WishContext";

import Avatar from "images/avatars/Image-2.png";
import ModalQuickView from "components/ModalReview";
import ModalQuote from "components/ModalQuote";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d867b"
    },
    secondary: {
      main: "#2d867b"
    }
  }
});

const CATEG = [
  {
    value: "Seasonal",
    name: "Seasonal"
  },
  {
    value: "Casual",
    name: "Casual"
  },
  {
    value: "Temporary",
    name: "Temporary"
  },
  // {
  //     value: "Fixed",
  //     name: "Fixed"
  // },
  {
    value: "Part Time",
    name: "Permanent – Part Time"
  },
  {
    value: "Full Time",
    name: "Permanent – Full time"
  }
];

const Jobs = ({ selectedJob = null, onCloseModalQuickView }) => {
  const { lan, setLan } = useContext(WishContext);

  const [showModalQuickView, setShowModalQuickView] = useState(false);

  const [images, setImages] = useState([]);

  const [title, settitle] = useState(
    selectedJob
      ? selectedJob?.language === "En"
        ? "English"
        : selectedJob?.language === "Si"
        ? "Sinhala"
        : ""
      : ""
  );
  const [jobType, setjobType] = useState(
    selectedJob ? selectedJob?.jobType : ""
  );
  const [country, setcountry] = useState(
    selectedJob ? selectedJob?.country : "Australia"
  );
  const [homeDistrict, sethomeDistrict] = useState(
    selectedJob ? selectedJob?.state : ""
  );
  const [homeCity, sethomeCity] = useState(
    selectedJob ? selectedJob?.suburb : ""
  );
  const [duration, setduration] = useState(
    selectedJob ? selectedJob?.duration : ""
  );
  const [description, setdescription] = useState(
    selectedJob ? selectedJob?.description : ""
  );
  const [currency, setcurrency] = useState(
    selectedJob ? selectedJob?.currency : "AUD"
  );
  const [rate, setrate] = useState(selectedJob ? selectedJob?.hourlyRate : "");

  let day = moment().add(365, "days");
  day = moment(day).format("YYYY-MM-DD");

  console.log(">>>>>>>day", moment(day).toDate());
  // setexpire(moment(day).toDate());

  const [expire, setexpire] = useState(
    selectedJob
      ? moment(selectedJob?.expiryDate).toDate()
      : moment(day).toDate()
  );

  const [expireVisibility, setexpireVisibility] = useState(
    selectedJob?.expireVisibility
      ? moment(selectedJob?.expireVisibility).toDate()
      : ""
  );

  const [Keywords, setKeywords] = useState("");

  const [oldPkg, setoldPkg] = useState(
    selectedJob ? selectedJob?.packageId : ""
  );

  const [errimages, seterrImages] = useState([]);

  const [errtitle, seterrtitle] = useState(false);
  const [errjobType, seterrjobType] = useState(false);
  const [errcountry, seterrcountry] = useState(false);
  const [errsuburb, seterrsuburb] = useState(false);
  const [errduration, seterrduration] = useState(false);
  const [errdescription, seterrdescription] = useState(false);
  const [errcurrency, seterrcurrency] = useState(false);
  const [errrate, seterrrate] = useState(false);
  const [errexpire, seterrexpire] = useState(false);
  const [errKeywords, seterrKeywords] = useState(false);
  const [errhomeDistrict, seterrhomeDistrict] = useState(false);
  const [errhomeCity, seterrhomeCity] = useState(false);

  const [homeDistrictId, sethomeDistrictId] = useState(0);
  const [allHCities, setallHCities] = useState([]);
  const [allHDistricts, setallHDistricts] = useState([]);

  const [currUser, setcurrUser] = useState("");

  const [packageId, setpackageId] = useState(
    selectedJob ? selectedJob?.packageId : "1"
  );

  const [lOpen, setlOpen] = useState(false);

  const [errpackages, seterrpackages] = useState(false);

  const history = useHistory();

  const [newSubArray, setnewSubArray] = useState([]);
  const [newSubs, setnewSubs] = useState([]);

  const [bOpen, setbOpen] = useState(false);
  const [bMsg, setbMsg] = useState("");
  const [bStatus, setbStatus] = useState("");

  const ref = useRef(null);

  const [editorData, setEditorData] = useState(
    selectedJob?.duties ? selectedJob?.duties : "<br/><br/><br/><br/><br/>"
  );

  const [newSubs2, setnewSubs2] = useState([]);

  const [profiles, setprofiles] = useState([]);
  const [selectedProfile, setselectedProfile] = useState(
    selectedJob ? { id: selectedJob?.memberId, name: selectedJob?.title } : ""
  );

  const handleScroll = () => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const [adDetails, setadDetails] = useState("");

  const [ipCountry, setipCountry] = useState("Other");

  const [selectedTab, setselectedTab] = useState(1);

  const [modal1, setmodal1] = useState(false);

  const [avatar, setavatar] = useState("");

  const [proTab, setproTab] = useState(1);

  const [reviews, setreviews] = useState([]);

  const [showModalQuickView2, setShowModalQuickView2] = useState(false);

  // const [images, setImages] = useState([]);

  useEffect(() => {
    if (!selectedJob) settitle(lan);
  }, [lan, selectedJob]);

  React.useEffect(() => {
    let newSubArray = JSON.parse(JSON.stringify(SubData));

    newSubArray = newSubArray?.map((d) => {
      if (d.type === "Delivery Area") {
        return {
          ...d,
          locality: upperFirst(lowerCase(d.locality))
        };
      }
    });

    newSubArray = _.compact(newSubArray);

    setnewSubArray(_.orderBy(newSubArray, ["locality"], ["asc"]));

    console.log(
      ">>>>>>>subs",
      newSubArray.filter((u) => u.state === 0)
    );
  }, []);

  const packageSelect = (item) => {
    console.log("pkg>>>>", item);

    let obj = {};

    if (item === "1") {
      obj = {
        email: currUser?.email,
        amountPayable: "FREE",
        packageDetails: [
          {
            packagePrice: "FREE",
            qty: 1,
            packageName: "FREE" + " " + "Subscription",
            days: 7
          }
        ],
        packageId: 1,
        userId: currUser?.id
      };
    } else if (item === "2") {
      obj = {
        email: currUser?.email,
        amountPayable: "15",
        packageDetails: [
          {
            packagePrice: "15",
            qty: 1,
            packageName: "Basic" + " " + "Subscription",
            days: 28
          }
        ],
        packageId: 2,
        userId: currUser?.id
      };
    } else if (item === "3") {
      obj = {
        email: currUser?.email,
        amountPayable: "30",
        packageDetails: [
          {
            packagePrice: "30",
            qty: 1,
            packageName: "Plus" + " " + "Subscription",
            days: 84
          }
        ],
        packageId: 2,
        userId: currUser?.id
      };
    }

    // let obj = {
    //     email: currUser?.email,
    //     amountPayable: item.pricing.split("$")[1],
    //     packageDetails: [
    //         {
    //             packagePrice: item.pricing.split("$")[1],
    //             qty: 1,
    //             packageName: item.name + " " + "Subscription",
    //         }
    //     ],
    //     packageId: item.id,
    //     userId: currUser?.id,
    // }

    console.log("obj>>>", obj);

    setlOpen(true);

    UpdatePackage(obj, currUser?.id)
      .then((res) => {
        console.log("res>>>>", res.data);

        if (res.data !== undefined) {
          if (res.data === "success") {
            history.push("/profile");
          } else {
            window.location.href = res.data;
          }
        }
      })
      .catch((error) => {
        setlOpen(false);
        console.log("error", error);
        console.log("current user error", error.response.data.message);
        if (error.response.data.statusCode === 401) {
          history.push("/login");
        }
      });
  };

  // React.useEffect(() => {

  //     if (packageId) {
  //         packageSelect(packageId);
  //     }

  // }, [packageId]);

  React.useEffect(() => {
    listDistricts();
    getCurrentUser();
    // listProfiles();
  }, []);

  React.useEffect(() => {
    listProfiles();
  }, [selectedJob]);

  React.useEffect(() => {
    if (homeDistrictId !== 0) {
      listHomecities();
    }
  }, [homeDistrictId]);

  useEffect(() => {
    getUserIp()
      .then((res) => {
        console.log(">>>>>>ip", res);

        getUserLocation(res?.ip)
          .then((res) => {
            console.log(">>>>>>ip location", res);

            if (res?.data.country_name === "Sri Lanka") {
              setipCountry("Sri Lanka");
            } else {
              setipCountry(res?.data.country_name);
            }
          })
          .catch((error) => {
            // setprofiles([]);
            console.log("Error", error.response);
          });
      })
      .catch((error) => {
        // setprofiles([]);
        console.log("Error", error.response);
      });
  }, []);

  const listProfiles = () => {
    getProfiles({
      jobType: JSON.stringify([])
      // title: search,
      // jobType:JSON.stringify([])
    })
      .then((res) => {
        console.log(">>>>>>>jobs", res.data);

        const subArr = res.data.result?.map((d, key) => {
          return {
            ...d,
            key: key
            // id: d.state_code
          };
        });

        setprofiles(subArr);

        if (selectedJob) {
          setselectedProfile(
            res.data.result.find((v) => v.id === selectedProfile?.id)
          );
        }
      })
      .catch((error) => {
        // setlopen(false);
        setprofiles([]);
        console.log("Error", error.response);
        // addToast(error.response.data.message, "warning");
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const listReviews = () => {
    getWriterReviewsById(selectedJob?.id)
      .then((res) => {
        console.log(">>>>>>>reviews", res.data);
        setreviews(res.data);
      })
      .catch((error) => {
        // setlopen(false);
        setreviews([]);
        console.log("reviews Error", error.response);
        // addToast(error.response.data.message, "warning");
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  useEffect(() => {
    listReviews();
  }, [selectedJob, showModalQuickView]);

  const listDistricts = () => {
    getDistricts()
      .then((res) => {
        console.log("districts>>>", res);
        setallHDistricts(res.data);
        // setallODistricts(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };
  const listHomecities = () => {
    getCities(homeDistrict)
      .then((res) => {
        console.log("cities>>>", res.data.records);
        setallHCities(res.data.records);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setallHCities([]);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const handleChangeHdist = (e, value) => {
    if (value !== null) {
      sethomeDistrict(value.name_en);
      seterrhomeDistrict(false);
      sethomeDistrictId(value.id);
    } else {
      sethomeDistrict("");
      sethomeDistrictId(1);
    }

    console.log("state>>", value);
    // setnewSubs(newSubArray.filter((u) => u.state === value.id))

    setnewSubs([]);

    const filtered = newSubArray.filter((u) => u.state === value.id);

    const perChunk = 700;

    const result = filtered.reduce((filtered, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!filtered[chunkIndex]) {
        filtered[chunkIndex] = []; // start a new chunk
      }

      filtered[chunkIndex].push(item);

      return filtered;
    }, []);

    console.log("<<<<<<<", result);

    for (let i = 0; i < result.length - 1; i++) {
      let item = result[i];

      setTimeout(
        () => {
          setnewSubs((existing) => [...existing, ...item]);
        },
        i === 0 ? 0 : 2000
      );
    }
  };
  const handleChangeHcity = (e, value) => {
    if (value !== null) {
      // console.log(">><<", value.fields.scc_name)
      sethomeCity(value);
      // sethomepostCode(value.postcode);
      // sethomeCity(value.fields.scc_name);
      // sethomepostCode(value.postcode);
      seterrhomeCity(false);
      // seterrhomepostCode(false);
    } else {
      sethomeCity("");
      // sethomepostCode("");
    }

    console.log("city>>", value);
  };

  const saveJob = async (e) => {
    e.preventDefault();

    // if (!description) {
    //     seterrdescription(true);
    // }

    if (!selectedProfile) {
      seterrjobType(true);
    }
    if (!packageId) {
      seterrpackages(true);
    }

    let obj2 = {};

    let item = packageId;

    if (item === "1") {
      obj2 = {
        email: currUser?.email,
        amountPayable: "FREE",
        packageDetails: [
          {
            packagePrice: "FREE",
            qty: 1,
            packageName: "FREE" + " " + "Subscription",
            days: 365
          }
        ],
        packageId: 1,
        userId: currUser?.id
      };
    } else if (item === "2") {
      obj2 = {
        email: currUser?.email,
        amountPayable: "50",
        packageDetails: [
          {
            packagePrice: ipCountry === "Sri Lanka" ? "1.63" : "50",
            qty: 1,
            packageName: "Basic" + " " + "Subscription",
            days: 90
          }
        ],
        packageId: 2,
        userId: currUser?.id
      };
    } else if (item === "3") {
      obj2 = {
        email: currUser?.email,
        amountPayable: "85",
        packageDetails: [
          {
            packagePrice: ipCountry === "Sri Lanka" ? "2.93" : "85",
            qty: 1,
            packageName: "Plus" + " " + "Subscription",
            days: 180
          }
        ],
        packageId: 3,
        userId: currUser?.id
      };
    }

    let orderMyNo = 100000;

    await getJobDetails()
      .then((res) => {
        orderMyNo = parseInt(res.data[res.data.length - 1]?.adId) + 1;
      })
      .catch((error) => {
        console.error("order history error", error.response);
      });

    let obj = {
      adId: !selectedJob ? orderMyNo : selectedJob?.id,
      title: selectedProfile?.name,
      userId: GetUserId(),
      memberId: selectedProfile?.id,
      description: description,
      duties: editorData,
      createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
      email: currUser?.email,
      packageUpdatedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      packageDetails: obj2,
      oldPkg: oldPkg,
      expiryDate: moment(expire).format("YYYY-MM-DD"),
      language: title === "English" ? "En" : title === "Sinhala" ? "Si" : "",
      expireVisibility: expireVisibility
        ? moment(expireVisibility).format("YYYY-MM-DD")
        : ""
    };

    obj = { ...selectedProfile, ...obj };

    console.log("obj>>>>", obj);

    if (packageId && selectedProfile) {
      if (!selectedJob) {
        setlOpen(true);
        postAds(obj)
          .then((res) => {
            setlOpen(false);
            if (res.data.includes("stripe")) {
              window.location.href = res.data;
              return;
            }
            // setlOpen(false);

            setbOpen(true);
            setbMsg(t("adSucc"));
            setbStatus("success");

            // addToast("Job successfully added", "success");
            settitle("");
            setjobType("");
            setcountry("");
            setdescription("");
            sethomeDistrict("");
            sethomeCity("");
            setduration("");
            setrate("");
            setImages([]);
            setKeywords("");
            setexpire("");
            setcurrency("");
            setEditorData("<br/><br/><br/><br/><br/>");
            setselectedProfile("");
            // setOpenToast(true);
            // setColor(COLORS.Primary);
            // setAlertClass("success");
            // setMsg("Successfully updated");
            // getCurrentUser();
            // seteDisable(true);
            // getUser();

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            setlOpen(false);
            console.log("Error", error.response);
            setbOpen(true);
            setbMsg(error.response.data.message);
            setbStatus("warning");

            // addToast(error.response.data.message, "warning");
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          });
      } else {
        setlOpen(true);
        UpdateAds(obj, selectedJob?.id)
          .then((res) => {
            setlOpen(false);
            if (res.data.includes("stripe")) {
              window.location.href = res.data;
              return;
            } else {
              setbOpen(true);
              setbMsg("Ad successfully updated ");
              setbStatus("success");
            }

            // addToast("Job successfully updated ", "success");
            settitle("");
            setjobType("");
            setcountry("");
            setdescription("");
            sethomeDistrict("");
            sethomeCity("");
            setduration("");
            setrate("");
            setImages([]);
            setKeywords("");
            setexpire("");
            setcurrency("");
            onCloseModalQuickView();
            // setOpenToast(true);
            // setColor(COLORS.Primary);
            // setAlertClass("success");
            // setMsg("Successfully updated");
            // getCurrentUser();
            // seteDisable(true);
            // getUser();

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            setlOpen(false);
            console.log("Error", error.response);
            setbOpen(true);
            setbMsg(error.response.data.message);
            setbStatus("warning");

            // addToast(error.response.data.message, "warning");
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          });
      }
    } else {
      // handleScroll();
      setbOpen(true);
      setbMsg("Required fields are missing and add missing fields.");
      setbStatus("warning");
    }
  };

  const getCurrentUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          // setUser(cData);
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber
          };
          //   this.setState({
          //     firstName: cData?.firstName,
          //     lastName: cData?.lastName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });
          console.log("<><><><>", cData);
          setcurrUser(cData);

          // setpackageId(cData.packageId ? cData.packageId : "");

          // if (!selectedJob) {

          //     if (cData.packageId === "1") {

          //         let day = moment().add(7, "days")
          //         day = moment(day).format("YYYY-MM-DD")

          //         console.log(">>>>>>>", moment(day).toDate())
          //         setexpire(moment(day).toDate());

          //     } if (cData.packageId === "2") {

          //         let day = moment().add(28, "days")
          //         day = moment(day).format("YYYY-MM-DD")

          //         console.log(">>>>>>>", moment(day).toDate())
          //         setexpire(moment(day).toDate());

          //     } else if (cData.packageId === "3") {

          //         let day = moment().add(84, "days")
          //         day = moment(day).format("YYYY-MM-DD")

          //         console.log(">>>>>>>", moment(day).toDate())
          //         setexpire(moment(day).toDate());

          //     }
          // }

          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };

  function CustomInput({ onFocus, value, onChange }) {
    return (
      <div className="mt-1.5 flex">
        <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-xs sm:text-sm">
          <i className="text-xl las la-calendar"></i>
        </span>
        <Input
          readOnly
          className="!rounded-l-none"
          placeholder="DD-MM-YYYY"
          onFocus={onFocus}
          value={value}
          onChange={onChange}
        />
      </div>
    );
  }

  useEffect(() => {
    if (bOpen === true) {
      setTimeout(() => {
        setbOpen(false);
      }, 10000);
    }
  }, [bOpen]);

  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  return (
    <>
      <ModalQuote
        show={showModalQuickView2}
        onCloseModalQuickView={() => setShowModalQuickView2(false)}
        jobId={selectedJob}
        type={"writer"}
        // setselectedAdds={setselectedAdds}
      />

      <LoadingSpinner open={lOpen} />

      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
        type={"writer"}
        proId={selectedJob?.id}
      />
      <div className="justify-center w-full mt-2">
        {!selectedJob && (
          <div className="flex justify-center">
            {/* <span className="text-xs font-normal flex justify-center w-full md:w-9/12 lg:w-[45%] text-center mt-1 mb-6">

                            Are you looking to hire talent for your company? You can post your
                            job vacancies through this page.

                        </span> */}
          </div>
        )}
      </div>
      <div className="w-full flex justify-center mb-0">
        <div className="w-full md:w-11/12 lg:w-12/12">
          {/* <span className="block text-xl font-bold flex justify-start w-full text-left text-[#003D35]">

                        {selectedJob ? "Edit Ad" : t("adButton")}
                    </span> */}

          <div className="col-span-12">
            {/* BEGIN: Display Information */}
            <div className="intro-y box lg:mt-5 py-8 w-full">
              <div className="flex border-b border-slate-200/60 dark:border-darkmode-400 w-full pb-10">
                {/* <h2 className="font-medium text-xs sm:text-sm mr-auto">
                Personal Information
              </h2> */}
                <div className="items-center ">
                  <div className="w-20 h-20 sm:w-32 sm:h-32 image-fit">
                    <img
                      alt="Avatar"
                      className="rounded-full"
                      // src={Avatar}
                      src={
                        JSON.parse(selectedJob?.avatar).length > 0
                          ? `${IMAGE_SERVER_URL_DEV2}${
                              JSON.parse(selectedJob?.avatar)[0].path
                            }`
                          : Avatar
                      }
                    />
                  </div>
                </div>
                <div className="ml-6 items-center">
                  <div className="flex items-center">
                    <h2 className="font-bold text-base mr-3 text-primaryF">
                      {selectedJob?.otherNames + " " + selectedJob?.surName}
                    </h2>

                    <Rating
                      name="size-small"
                      value={selectedJob?.rank}
                      size="small"
                      className=""
                      readOnly
                    />
                  </div>
                  <h3 className="font-normal text-xs sm:text-sm mr-auto text-secondaryF">
                    Technical CV Writer
                  </h3>
                  <h4 className="font-medium text-xs sm:text-sm mr-auto text-redF mt-2 whitespace-nowrap">
                    <span> {selectedJob?.experience}+ Years Experience</span>{" "}
                    <span className="mx-2">
                      |
                      {/* </span> <span className="">23 Age</span>  <span className="mx-2">| */}
                    </span>{" "}
                    <span className="">{selectedJob?.district}</span>
                  </h4>

                  {/* <div className="text-primaryF font-medium border border-primaryF w-fit px-4 py-2 rounded-full mt-3 text-xs sm:text-sm">
                                        10000 Views
                                    </div> */}
                  <span
                    className={`flex justify-start ${
                      true ? "text-[12px]" : "text-xs"
                    } text-primaryF font-medium dark:text-neutral-400 mt-2 `}
                  >
                    <span className="flex items-center justify-start">
                      <i className="las la-phone-volume text-lg text-left mr-2"></i>
                      {/* {data?.city} {getState(data?.district)} */}
                      {/* 0777 661 485 */}
                      {selectedJob?.mobileNumber}
                    </span>

                    {/* <span className="text-slate-900 flex items-center">


<i className="las la-clock text-lg text-left mr-1"></i>

{getTime(data?.dif)}
</span> */}

                    <span className="flex items-center ml-6">
                      <i className="las la-envelope text-lg text-left mr-2"></i>
                      {/* {data?.dif}  */}
                      {selectedJob?.email}
                      {/* {getTime(data?.dif)} */}
                    </span>
                  </span>
                </div>
              </div>
              <div className="">
                <div className="grid grid-cols-12">
                  <div className="col-span-4 sm:col-span-2 border-r border-slate-200/60 pt-5 cursor-pointer">
                    <div className="space-y-5 pl-0">
                      {selectedTab === 1 ? (
                        <h6 className="text-xs sm:text-sm text-redF font-medium">
                          I About Me
                        </h6>
                      ) : (
                        <h6
                          className="text-xs sm:text-sm text-primaryF font-medium"
                          onClick={() => {
                            setselectedTab(1);
                          }}
                        >
                          About Me
                        </h6>
                      )}

                      {selectedTab === 2 ? (
                        <h6 className="text-xs sm:text-sm text-redF font-medium">
                          I Work Samples
                        </h6>
                      ) : (
                        <h6
                          className="text-xs sm:text-sm text-primaryF font-medium"
                          onClick={() => {
                            setselectedTab(2);
                          }}
                        >
                          Work Samples
                        </h6>
                      )}

                      {selectedTab === 3 ? (
                        <h6 className="text-xs sm:text-sm text-redF font-medium">
                          I Testimonials
                        </h6>
                      ) : (
                        <h6
                          className="text-xs sm:text-sm text-primaryF font-medium"
                          onClick={() => {
                            setselectedTab(3);
                          }}
                        >
                          Testimonials
                        </h6>
                      )}

                      {/* {
                                                selectedTab === 4 ?
                                                    <h6 className="text-xs sm:text-sm text-redF font-medium">I  Personal Details</h6>
                                                    :
                                                    <h6 className="text-xs sm:text-sm text-primaryF font-medium"
                                                        onClick={() => {
                                                            setselectedTab(4);
                                                            setmodal1(true);
                                                        }}
                                                    >Personal Details</h6>

                                            } */}
                    </div>

                    <div
                      className="bg-redF border border-redF text-white flex justify-center text-xs w-[110px] rounded-full py-1.5 cursor-pointer mt-10"
                      onClick={() => {
                        setShowModalQuickView2(true);
                      }}
                    >
                      Inquire
                    </div>

                    <div className="border-t border-slate-200/60 mt-16 min-h-[250px]">
                      <div className="pl-0">
                        <h6 className="text-base text-primaryF font-bold pt-5">
                          Top Fields
                        </h6>

                        <div className="space-y-2 mt-3 text-xs text-primaryF font-normal">
                          {/* <h6 className="">Information Technology

                                                    </h6> */}
                          {JSON.parse(selectedJob?.fields).map(
                            (item, index) => {
                              return <h6 className="">{item}</h6>;
                            }
                          )}
                          {/* <h6 className="">Business</h6>
                                                    <h6 className="">Communication
                                                    </h6>
                                                    <h6 className="">Health Sector
                                                    </h6>
                                                    <h6 className="">Immigration
                                                    </h6> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-8 sm:col-span-10">
                    <div className="py-5 pl-4 sm:pl-10 sm:pr-5">
                      {selectedTab === 1 && (
                        <>
                          <div className="w-full text-[#45535E] mt-0 leading-7 text-xs sm:text-sm">
                            {/* As a seasoned and results-driven Professional Resume Writer, I bring a wealth of experience in crafting compelling and targeted resumes that elevate my clients' professional profiles. With a passion for helping individuals achieve their career goals, I specialize in creating customized and impactful resumes that resonate with hiring managers and recruiters across diverse industries. */}

                            {selectedJob?.description}
                          </div>
                          {/* <div className="flex justify-between items-start mt-5">
                                                        <div>

                                                            <h5 className="text-primaryF font-bold text-xs sm:text-sm">Key Highlights</h5>
                                                           

                                                        </div>


                                                    </div>

                                                    <div className="mt-6 text-primaryF ">

                                                        <ul className="list-disc ml-5 space-y-1 text-[13px] leading-7">
                                                            <li><b>Expertise in Tailored Resumes:</b> With [X] years of experience in the resume writing industry, I have honed my skills in tailoring resumes to showcase the unique strengths and accomplishments of each client. Whether you are a recent graduate, a mid-career professional, or an executive, I have the expertise to highlight your achievements effectively.

                                                            </li>

                                                            <li><b>Expertise in Tailored Resumes:</b> With [X] years of experience in the resume writing industry, I have honed my skills in tailoring resumes to showcase the unique strengths and accomplishments of each client. Whether you are a recent graduate, a mid-career professional, or an executive, I have the expertise to highlight your achievements effectively.

                                                            </li>

                                                            <li><b>Expertise in Tailored Resumes:</b> With [X] years of experience in the resume writing industry, I have honed my skills in tailoring resumes to showcase the unique strengths and accomplishments of each client. Whether you are a recent graduate, a mid-career professional, or an executive, I have the expertise to highlight your achievements effectively.

                                                            </li>


                                                        </ul>

                                                    </div> */}
                        </>
                      )}

                      {selectedTab === 2 && (
                        <>
                          <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
                            {JSON.parse(selectedJob?.samples).map(
                              (item, index) => {
                                return (
                                  <div>
                                    <img
                                      class="h-auto max-w-full rounded-lg"
                                      src={`${IMAGE_SERVER_URL_DEV2}${item?.path}`}
                                      alt=""
                                    />
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </>
                      )}

                      {selectedTab === 3 && (
                        <>
                          <div className="flex justify-end w-full">
                            <div
                              className="bg-white border border-primaryF text-xs text-primaryF rounded-full w-[150px] text-center py-1.5 cursor-pointer"
                              onClick={() => {
                                setShowModalQuickView(true);
                              }}
                            >
                              Write a Review
                            </div>
                          </div>
                          <div className="space-y-8 mt-5">
                            {reviews.map((item, index) => {
                              return (
                                <>
                                  <div className="bg-[#F4F4F4] px-4 sm:px-9 py-5">
                                    <div className="flex justify-between items-start">
                                      <div className="flex items-center">
                                        <div className="items-center ">
                                          <div className="w-12 h-12 image-fit">
                                            <img
                                              alt="Avatar"
                                              className="rounded-full"
                                              // src={Avatar}
                                              src={
                                                JSON.parse(item?.avatar)
                                                  .length > 0
                                                  ? `${IMAGE_SERVER_URL_DEV}${
                                                      JSON.parse(
                                                        item?.avatar
                                                      )[0].path
                                                    }`
                                                  : Avatar
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="ml-3 items-center">
                                          <div className="flex items-center">
                                            <h2 className="font-bold text-xs sm:text-sm mr-3 text-primaryF">
                                              {/* Kanchana Sirimanna */}
                                              {item?.firstMiddleName +
                                                " " +
                                                item?.surName}
                                            </h2>
                                          </div>

                                          <h4 className="font-medium text-xs mr-auto text-redF mt-2 whitespace-nowrap">
                                            <span className="text-primaryF">
                                              {item?.designation}
                                            </span>{" "}
                                            <span className="mx-2 text-primaryF">
                                              |
                                            </span>{" "}
                                            <span className="">
                                              {item?.companyName}
                                            </span>
                                          </h4>

                                          <div className="mt-2 sm:hidden">
                                            <Rating
                                              name="size-small"
                                              value={item?.rating}
                                              size="small"
                                              className=""
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="hidden sm:flex">
                                        <Rating
                                          name="size-small"
                                          value={item?.rating}
                                          size="small"
                                          className=""
                                          readOnly
                                        />
                                      </div>
                                    </div>

                                    <div className="w-full rounded-xl text-[#45535E] mt-4 leading-6 text-xs">
                                      {/* I had the pleasure of working with [CV Writer's Full Name] on revamping my resume, and the experience surpassed all my expectations. [He/She] is a true professional with an exceptional talent for crafting resumes that stand out. From our initial consultation to the final delivery, [CV Writer's Full Name] demonstrated a deep understanding of my industry and career goals. [He/She] asked insightful questions that helped uncover my unique strengths and accomplishments, which were then artfully highlighted in my new resume. */}

                                      {item?.feedback}
                                    </div>

                                    <div className="flex justify-end text-right text-xs text-[#45535E]">
                                      {/* 3 hours ago */}
                                      {moment(item?.createdAt).fromNow()}
                                    </div>
                                  </div>
                                </>
                              );
                            })}

                            {/* <div className="bg-[#F4F4F4] px-9 py-5">


                                                            <div className="flex justify-between items-start">
                                                                <div className="flex items-center">


                                                                   
                                                                    <div className="items-center " >
                                                                        <div className="w-12 h-12 image-fit">
                                                                            <img
                                                                                alt="Avatar"
                                                                                className="rounded-full"
                                                                                src={Avatar}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="ml-3 items-center" >
                                                                        <div className="flex items-center">


                                                                            <h2 className="font-bold text-xs sm:text-sm mr-3 text-primaryF">
                                                                                Kanchana Sirimanna
                                                                            </h2>


                                                                        </div>

                                                                        <h4 className="font-medium text-xs mr-auto text-redF mt-2 whitespace-nowrap">
                                                                            <span className="text-primaryF"> Senior Software Engineer</span>  <span className="mx-2 text-primaryF">|</span> <span className="">Xpress IT Private Limited</span>
                                                                        </h4>


                                                                    </div>


                                                                </div>

                                                                <div>
                                                                    <Rating name="size-small" value={5} size="small" className="" readOnly />
                                                                </div>


                                                            </div>

                                                            <div className="w-full rounded-xl text-[#45535E] mt-4 leading-6 text-xs">

                                                                I had the pleasure of working with [CV Writer's Full Name] on revamping my resume, and the experience surpassed all my expectations. [He/She] is a true professional with an exceptional talent for crafting resumes that stand out. From our initial consultation to the final delivery, [CV Writer's Full Name] demonstrated a deep understanding of my industry and career goals. [He/She] asked insightful questions that helped uncover my unique strengths and accomplishments, which were then artfully highlighted in my new resume.

                                                            </div>

                                                            <div className="flex justify-end text-right text-xs text-[#45535E]">
                                                                3 hours ago
                                                            </div>

                                                        </div> */}
                          </div>
                        </>
                      )}

                      {selectedTab === 4 && (
                        <>
                          <div className="space-y-8">
                            <div>
                              <div className="w-full text-[#45535E] mt-4 leading-8 text-xs sm:text-sm">
                                If you are interested on this profile please buy
                                it to view the personal and contact details of
                                this candidate. Also you will be able to
                                download the detailed resume. Please click on
                                the “Buy Now” section, choose a suitable package
                                and checkout.
                              </div>

                              <div className="flex justify-end mt-6">
                                <div className="bg-redF text-white w-[200px] rounded-full text-xs sm:text-sm text-center py-2">
                                  Buy Now
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* END: Display Information */}

            {/* 
                        <div className="w-full flex justify-end">

                            <button className="btn btn-primary w-20 mt-3"
                            // onClick={onSubmit}
                            >
                                Save
                            </button>

                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Jobs;
