import React, {
  FC,
  useState,
  useEffect,
  useRef,
  Fragment,
  useContext
} from "react";
import Stripe from "images/sLogo.png";
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL, IMAGE_SERVER_URL_DEV } from "Constant/System";
import _, { lowerCase, map, upperCase, upperFirst } from "lodash";
import {
  postAds,
  postCVWriter,
  postJobs,
  postJobSeeker
} from "Services/API/Post";
import { addToast } from "shared/Toast";
import {
  CurrentUser,
  GetUserId,
  getCities,
  getDistricts,
  getJobDetails,
  getOrderDetails,
  getProfiles,
  getUserIp,
  getUserLocation
} from "Services/API/Get";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Box
} from "@mui/material";
import { UpdateAds, UpdateJobs, UpdatePackage } from "Services/API/Put";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import moment from "moment";
import SubData from "data/australian_postcodes.json";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoadingSpinner from "shared/LoadingSpinner";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import BotToast from "shared/BotToast";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import JobsCard from "components/CardCategories/JobsCard";
import { t } from "i18next";

import { Dialog, Popover, Transition } from "@headlessui/react";
import Checkbox from "shared/Checkbox/Checkbox";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import WishContext from "Context/WishContext";

import Avatar from "images/avatars/Image-2.png";
import classnames from "classnames";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d867b"
    },
    secondary: {
      main: "#2d867b"
    }
  }
});

const CATEG = [
  {
    value: "Seasonal",
    name: "Seasonal"
  },
  {
    value: "Casual",
    name: "Casual"
  },
  {
    value: "Temporary",
    name: "Temporary"
  },
  // {
  //     value: "Fixed",
  //     name: "Fixed"
  // },
  {
    value: "Part Time",
    name: "Permanent – Part Time"
  },
  {
    value: "Full Time",
    name: "Permanent – Full time"
  }
];

const Jobs = ({ selectedJob = null, onCloseModalQuickView }) => {
  const { lan, setLan } = useContext(WishContext);

  const [images, setImages] = useState([]);

  const [title, settitle] = useState(
    selectedJob
      ? selectedJob?.language === "En"
        ? "English"
        : selectedJob?.language === "Si"
        ? "Sinhala"
        : ""
      : ""
  );
  const [jobType, setjobType] = useState(
    selectedJob ? selectedJob?.jobType : ""
  );
  const [country, setcountry] = useState(
    selectedJob ? selectedJob?.country : "Australia"
  );
  const [homeDistrict, sethomeDistrict] = useState(
    selectedJob ? selectedJob?.state : ""
  );
  const [homeCity, sethomeCity] = useState(
    selectedJob ? selectedJob?.suburb : ""
  );
  const [duration, setduration] = useState(
    selectedJob ? selectedJob?.duration : ""
  );
  const [description, setdescription] = useState(
    selectedJob ? selectedJob?.description : ""
  );
  const [currency, setcurrency] = useState(
    selectedJob ? selectedJob?.currency : "AUD"
  );
  const [rate, setrate] = useState(selectedJob ? selectedJob?.hourlyRate : "");

  let day = moment().add(365, "days");
  day = moment(day).format("YYYY-MM-DD");

  console.log(">>>>>>>day", moment(day).toDate());
  // setexpire(moment(day).toDate());

  const [expire, setexpire] = useState(
    selectedJob
      ? moment(selectedJob?.expiryDate).toDate()
      : moment(day).toDate()
  );

  const [expireVisibility, setexpireVisibility] = useState(
    selectedJob?.expireVisibility
      ? moment(selectedJob?.expireVisibility).toDate()
      : ""
  );

  const [Keywords, setKeywords] = useState("");

  const [oldPkg, setoldPkg] = useState(
    selectedJob ? selectedJob?.packageId?.toString() : ""
  );

  const [errimages, seterrImages] = useState([]);

  const [errtitle, seterrtitle] = useState(false);
  const [errjobType, seterrjobType] = useState(false);
  const [errcountry, seterrcountry] = useState(false);
  const [errsuburb, seterrsuburb] = useState(false);
  const [errduration, seterrduration] = useState(false);
  const [errdescription, seterrdescription] = useState(false);
  const [errcurrency, seterrcurrency] = useState(false);
  const [errrate, seterrrate] = useState(false);
  const [errexpire, seterrexpire] = useState(false);
  const [errKeywords, seterrKeywords] = useState(false);
  const [errhomeDistrict, seterrhomeDistrict] = useState(false);
  const [errhomeCity, seterrhomeCity] = useState(false);

  const [homeDistrictId, sethomeDistrictId] = useState(0);
  const [allHCities, setallHCities] = useState([]);
  const [allHDistricts, setallHDistricts] = useState([]);

  const [currUser, setcurrUser] = useState("");

  const [packageId, setpackageId] = useState(
    selectedJob ? selectedJob?.packageId.toString() : "1"
  );

  const [lOpen, setlOpen] = useState(false);

  const [errpackages, seterrpackages] = useState(false);

  const history = useHistory();

  const [newSubArray, setnewSubArray] = useState([]);
  const [newSubs, setnewSubs] = useState([]);

  const [bOpen, setbOpen] = useState(false);
  const [bMsg, setbMsg] = useState("");
  const [bStatus, setbStatus] = useState("");

  const ref = useRef(null);

  const [editorData, setEditorData] = useState(
    selectedJob?.duties ? selectedJob?.duties : "<br/><br/><br/><br/><br/>"
  );

  const [newSubs2, setnewSubs2] = useState([]);

  const [profiles, setprofiles] = useState([]);
  const [selectedProfile, setselectedProfile] = useState(
    selectedJob ? { id: selectedJob?.memberId, name: selectedJob?.title } : ""
  );

  const handleScroll = () => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const [adDetails, setadDetails] = useState("");

  const [ipCountry, setipCountry] = useState("Other");

  const [selectedTab, setselectedTab] = useState(1);

  const [modal1, setmodal1] = useState(false);

  const [avatar, setavatar] = useState("");

  const [proTab, setproTab] = useState(1);

  const [contactPerson, setcontactPerson] = useState("");
  const [errCountry, seterrCountry] = useState(false);
  const [errhomepostCode, seterrhomepostCode] = useState(false);
  const [errcontactPerson, seterrcontactPerson] = useState(false);
  const [homepostCode, sethomepostCode] = useState("");

  const [surName, setsurName] = useState("");
  const [errsurName, seterrsurName] = useState(false);
  const [otherName, setotherName] = useState("");
  const [errotherName, seterrotherName] = useState(false);
  const [gender, setgender] = useState("");
  const [errgender, seterrgender] = useState(false);
  const [dob, setdob] = useState("");
  const [errdob, seterrdob] = useState(false);
  const [email, setemail] = useState("");
  const [erremail, seterremail] = useState(false);
  const [contact, setcontact] = useState("");
  const [errcontact, seterrcontact] = useState(false);
  const [landNo, setlandNo] = useState("");
  const [errlandNo, seterrlandNo] = useState(false);

  const [role, setrole] = useState("");
  const [errrole, seterrrole] = useState(false);
  const [category, setcategory] = useState("");
  const [errcategory, seterrcategory] = useState(false);
  const [company, setcompany] = useState("");
  const [errcompany, seterrcompany] = useState(false);
  const [empSt, setempSt] = useState("");
  const [errempSt, seterrempSt] = useState(false);
  const [startD, setstartD] = useState("");
  const [errstartD, seterrstartD] = useState(false);
  const [endD, setendD] = useState("");
  const [errendD, seterrendD] = useState(false);
  const [duties, setduties] = useState("");
  const [errduties, seterrduties] = useState(false);

  const [field, setfield] = useState("");
  const [errfield, seterrfield] = useState(false);
  const [qualif, setqualif] = useState("");
  const [errqualif, seterrqualif] = useState(false);
  const [qName, setqName] = useState("");
  const [errqName, seterrqName] = useState(false);
  const [qStatus, setqStatus] = useState("");
  const [errqStatus, seterrqStatus] = useState(false);
  const [qSdate, setqSdate] = useState("");
  const [errqSdate, seterrqSdate] = useState(false);
  const [qEdate, setqEdate] = useState("");
  const [errqEdate, seterrqEdate] = useState(false);
  const [uni, setuni] = useState("");
  const [erruni, seterruni] = useState(false);

  const [achievement, setachievement] = useState("");
  const [achYear, setachYear] = useState("");
  const [aDesc, setaDesc] = useState("");

  const [top5, settop5] = useState([]);
  const [errtop5, seterrtop5] = useState(false);
  const [experience, setexperience] = useState("");
  const [errexperience, seterrexperience] = useState(false);

  // const [images, setImages] = useState([]);

  useEffect(() => {
    if (!selectedJob) settitle(lan);
  }, [lan, selectedJob]);

  React.useEffect(() => {
    let newSubArray = JSON.parse(JSON.stringify(SubData));

    newSubArray = newSubArray?.map((d) => {
      if (d.type === "Delivery Area") {
        return {
          ...d,
          locality: upperFirst(lowerCase(d.locality))
        };
      }
    });

    newSubArray = _.compact(newSubArray);

    setnewSubArray(_.orderBy(newSubArray, ["locality"], ["asc"]));

    console.log(
      ">>>>>>>subs",
      newSubArray.filter((u) => u.state === 0)
    );
  }, []);

  const packageSelect = (item) => {
    console.log("pkg>>>>", item);

    let obj = {};

    if (item === "1") {
      obj = {
        email: currUser?.email,
        amountPayable: "FREE",
        packageDetails: [
          {
            packagePrice: "FREE",
            qty: 1,
            packageName: "FREE" + " " + "Subscription",
            days: 7
          }
        ],
        packageId: 1,
        userId: currUser?.id
      };
    } else if (item === "2") {
      obj = {
        email: currUser?.email,
        amountPayable: "15",
        packageDetails: [
          {
            packagePrice: "15",
            qty: 1,
            packageName: "Basic" + " " + "Subscription",
            days: 28
          }
        ],
        packageId: 2,
        userId: currUser?.id
      };
    } else if (item === "3") {
      obj = {
        email: currUser?.email,
        amountPayable: "30",
        packageDetails: [
          {
            packagePrice: "30",
            qty: 1,
            packageName: "Plus" + " " + "Subscription",
            days: 84
          }
        ],
        packageId: 2,
        userId: currUser?.id
      };
    }

    // let obj = {
    //     email: currUser?.email,
    //     amountPayable: item.pricing.split("$")[1],
    //     packageDetails: [
    //         {
    //             packagePrice: item.pricing.split("$")[1],
    //             qty: 1,
    //             packageName: item.name + " " + "Subscription",
    //         }
    //     ],
    //     packageId: item.id,
    //     userId: currUser?.id,
    // }

    console.log("obj>>>", obj);

    setlOpen(true);

    UpdatePackage(obj, currUser?.id)
      .then((res) => {
        console.log("res>>>>", res.data);

        if (res.data !== undefined) {
          if (res.data === "success") {
            history.push("/profile");
          } else {
            window.location.href = res.data;
          }
        }
      })
      .catch((error) => {
        setlOpen(false);
        console.log("error", error);
        console.log("current user error", error.response.data.message);
        if (error.response.data.statusCode === 401) {
          history.push("/login");
        }
      });
  };

  // React.useEffect(() => {

  //     if (packageId) {
  //         packageSelect(packageId);
  //     }

  // }, [packageId]);

  React.useEffect(() => {
    listDistricts();
    getCurrentUser();
    // listProfiles();
  }, []);

  React.useEffect(() => {
    listProfiles();
  }, [selectedJob]);

  React.useEffect(() => {
    if (homeDistrictId !== 0) {
      listHomecities();
    }
  }, [homeDistrictId]);

  useEffect(() => {
    getUserIp()
      .then((res) => {
        console.log(">>>>>>ip", res);

        getUserLocation(res?.ip)
          .then((res) => {
            console.log(">>>>>>ip location", res);

            if (res?.data.country_name === "Sri Lanka") {
              setipCountry("Sri Lanka");
            } else {
              setipCountry(res?.data.country_name);
            }
          })
          .catch((error) => {
            // setprofiles([]);
            console.log("Error", error.response);
          });
      })
      .catch((error) => {
        // setprofiles([]);
        console.log("Error", error.response);
      });
  }, []);

  const listProfiles = () => {
    getProfiles({
      jobType: JSON.stringify([])
      // title: search,
      // jobType:JSON.stringify([])
    })
      .then((res) => {
        console.log(">>>>>>>jobs", res.data);

        const subArr = res.data.result?.map((d, key) => {
          return {
            ...d,
            key: key
            // id: d.state_code
          };
        });

        setprofiles(subArr);

        if (selectedJob) {
          setselectedProfile(
            res.data.result.find((v) => v.id === selectedProfile?.id)
          );
        }
      })
      .catch((error) => {
        // setlopen(false);
        setprofiles([]);
        console.log("Error", error.response);
        // addToast(error.response.data.message, "warning");
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const listDistricts = () => {
    getDistricts()
      .then((res) => {
        console.log("districts>>>", res);
        setallHDistricts(res.data);
        // setallODistricts(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };
  const listHomecities = () => {
    getCities(homeDistrict)
      .then((res) => {
        console.log("cities>>>", res.data.records);
        setallHCities(res.data.records);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setallHCities([]);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const handleChangeHdist = (e, value) => {
    if (value !== null) {
      sethomeDistrict(value.name_en);
      seterrhomeDistrict(false);
      sethomeDistrictId(value.id);
    } else {
      sethomeDistrict("");
      sethomeDistrictId(1);
    }

    console.log("state>>", value);
    // setnewSubs(newSubArray.filter((u) => u.state === value.id))

    setnewSubs([]);

    const filtered = newSubArray.filter((u) => u.state === value.id);

    const perChunk = 700;

    const result = filtered.reduce((filtered, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!filtered[chunkIndex]) {
        filtered[chunkIndex] = []; // start a new chunk
      }

      filtered[chunkIndex].push(item);

      return filtered;
    }, []);

    console.log("<<<<<<<", result);

    for (let i = 0; i < result.length - 1; i++) {
      let item = result[i];

      setTimeout(
        () => {
          setnewSubs((existing) => [...existing, ...item]);
        },
        i === 0 ? 0 : 2000
      );
    }
  };
  const handleChangeHcity = (e, value) => {
    if (value !== null) {
      // console.log(">><<", value.fields.scc_name)
      sethomeCity(value);
      // sethomepostCode(value.postcode);
      // sethomeCity(value.fields.scc_name);
      // sethomepostCode(value.postcode);
      seterrhomeCity(false);
      // seterrhomepostCode(false);
    } else {
      sethomeCity("");
      // sethomepostCode("");
    }

    console.log("city>>", value);
  };

  const saveJob = async (e) => {
    e.preventDefault();

    // if (!description) {
    //     seterrdescription(true);
    // }

    if (!selectedProfile) {
      seterrjobType(true);
    }
    if (!packageId) {
      seterrpackages(true);
    }

    let obj2 = {};

    let item = packageId;

    if (item === "1") {
      obj2 = {
        email: currUser?.email,
        amountPayable: "FREE",
        packageDetails: [
          {
            packagePrice: "FREE",
            qty: 1,
            packageName: "FREE" + " " + "Subscription",
            days: 365
          }
        ],
        packageId: 1,
        userId: currUser?.id
      };
    } else if (item === "2") {
      obj2 = {
        email: currUser?.email,
        amountPayable: "50",
        packageDetails: [
          {
            packagePrice: ipCountry === "Sri Lanka" ? "1.63" : "50",
            qty: 1,
            packageName: "Basic" + " " + "Subscription",
            days: 90
          }
        ],
        packageId: 2,
        userId: currUser?.id
      };
    } else if (item === "3") {
      obj2 = {
        email: currUser?.email,
        amountPayable: "85",
        packageDetails: [
          {
            packagePrice: ipCountry === "Sri Lanka" ? "2.93" : "85",
            qty: 1,
            packageName: "Plus" + " " + "Subscription",
            days: 180
          }
        ],
        packageId: 3,
        userId: currUser?.id
      };
    }

    let orderMyNo = 100000;

    await getJobDetails()
      .then((res) => {
        orderMyNo = parseInt(res.data[res.data.length - 1]?.adId) + 1;
      })
      .catch((error) => {
        console.error("order history error", error.response);
      });

    let obj = {
      adId: !selectedJob ? orderMyNo : selectedJob?.id,
      title: selectedProfile?.name,
      userId: GetUserId(),
      memberId: selectedProfile?.id,
      description: description,
      duties: editorData,
      createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
      email: currUser?.email,
      packageUpdatedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      packageDetails: obj2,
      oldPkg: oldPkg,
      expiryDate: moment(expire).format("YYYY-MM-DD"),
      language: title === "English" ? "En" : title === "Sinhala" ? "Si" : "",
      expireVisibility: expireVisibility
        ? moment(expireVisibility).format("YYYY-MM-DD")
        : ""
    };

    obj = { ...selectedProfile, ...obj };

    console.log("obj>>>>", obj);

    if (packageId && selectedProfile) {
      if (!selectedJob) {
        setlOpen(true);
        postAds(obj)
          .then((res) => {
            setlOpen(false);
            if (res.data.includes("stripe")) {
              window.location.href = res.data;
              return;
            }
            // setlOpen(false);

            setbOpen(true);
            setbMsg(t("adSucc"));
            setbStatus("success");

            // addToast("Job successfully added", "success");
            settitle("");
            setjobType("");
            setcountry("");
            setdescription("");
            sethomeDistrict("");
            sethomeCity("");
            setduration("");
            setrate("");
            setImages([]);
            setKeywords("");
            setexpire("");
            setcurrency("");
            setEditorData("<br/><br/><br/><br/><br/>");
            setselectedProfile("");
            // setOpenToast(true);
            // setColor(COLORS.Primary);
            // setAlertClass("success");
            // setMsg("Successfully updated");
            // getCurrentUser();
            // seteDisable(true);
            // getUser();

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            setlOpen(false);
            console.log("Error", error.response);
            setbOpen(true);
            setbMsg(error.response.data.message);
            setbStatus("warning");

            // addToast(error.response.data.message, "warning");
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          });
      } else {
        setlOpen(true);
        UpdateAds(obj, selectedJob?.id)
          .then((res) => {
            setlOpen(false);
            if (res.data.includes("stripe")) {
              window.location.href = res.data;
              return;
            } else {
              setbOpen(true);
              setbMsg("Ad successfully updated ");
              setbStatus("success");
            }

            // addToast("Job successfully updated ", "success");
            settitle("");
            setjobType("");
            setcountry("");
            setdescription("");
            sethomeDistrict("");
            sethomeCity("");
            setduration("");
            setrate("");
            setImages([]);
            setKeywords("");
            setexpire("");
            setcurrency("");
            onCloseModalQuickView();
            // setOpenToast(true);
            // setColor(COLORS.Primary);
            // setAlertClass("success");
            // setMsg("Successfully updated");
            // getCurrentUser();
            // seteDisable(true);
            // getUser();

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            setlOpen(false);
            console.log("Error", error.response);
            setbOpen(true);
            setbMsg(error.response.data.message);
            setbStatus("warning");

            // addToast(error.response.data.message, "warning");
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          });
      }
    } else {
      // handleScroll();
      setbOpen(true);
      setbMsg("Required fields are missing and add missing fields.");
      setbStatus("warning");
    }
  };

  const getCurrentUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          // setUser(cData);
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber
          };
          //   this.setState({
          //     firstName: cData?.firstName,
          //     lastName: cData?.lastName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });
          console.log("<><><><>", cData);
          setcurrUser(cData);

          // setpackageId(cData.packageId ? cData.packageId : "");

          // if (!selectedJob) {

          //     if (cData.packageId === "1") {

          //         let day = moment().add(7, "days")
          //         day = moment(day).format("YYYY-MM-DD")

          //         console.log(">>>>>>>", moment(day).toDate())
          //         setexpire(moment(day).toDate());

          //     } if (cData.packageId === "2") {

          //         let day = moment().add(28, "days")
          //         day = moment(day).format("YYYY-MM-DD")

          //         console.log(">>>>>>>", moment(day).toDate())
          //         setexpire(moment(day).toDate());

          //     } else if (cData.packageId === "3") {

          //         let day = moment().add(84, "days")
          //         day = moment(day).format("YYYY-MM-DD")

          //         console.log(">>>>>>>", moment(day).toDate())
          //         setexpire(moment(day).toDate());

          //     }
          // }

          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };

  function CustomInput({ onFocus, value, onChange }) {
    return (
      <div className="mt-1.5 flex">
        <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-[#F4F4F4] dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
          <i className="text-xl las la-calendar"></i>
        </span>
        <Input
          // readOnly
          className={classnames({
            "form-control": true,
            // "border-red-600": errcountry,
            "bg-[#F4F4F4]": true,
            "border-none": true,

            "!rounded-l-none": true
          })}
          // className="!rounded-l-none"
          placeholder="DD-MM-YYYY"
          onFocus={onFocus}
          value={value}
          onChange={onChange}
        />
      </div>
    );
  }

  useEffect(() => {
    if (bOpen === true) {
      setTimeout(() => {
        setbOpen(false);
      }, 10000);
    }
  }, [bOpen]);

  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const saveJobSeeker = async (e) => {
    e.preventDefault();

    if (!top5.length > 0) {
      seterrtop5(true);
    }

    if (!surName) {
      seterrsurName(true);
    }

    if (!otherName) {
      seterrotherName(true);
    }

    if (!gender) {
      seterrgender(true);
    }

    if (!experience) {
      seterrexperience(true);
    }

    if (!homeDistrict) {
      seterrhomeDistrict(true);
    }

    if (!email) {
      seterremail(true);
    }

    if (!contact) {
      seterrcontact(true);
    }

    let obj = {
      userId: GetUserId(),
      cvwriteravatar: [avatar],
      fields: top5,
      surName: surName,
      otherNames: otherName,
      gender: gender,
      experience: experience,
      district: homeDistrict,
      email: email,
      mobileNumber: contact,
      landNmuber: landNo,
      cvwritersample: images,
      description: duties
    };

    console.log(">>>>>>", obj);

    // return;

    if (
      top5.length > 0 &&
      surName &&
      otherName &&
      gender &&
      experience &&
      homeDistrict &&
      email &&
      contact
    ) {
      postCVWriter(obj)
        .then((res) => {
          setlOpen(false);

          // setbOpen(true);
          // setbMsg(t("adSucc"));
          // setbStatus("success");

          addToast("CV Writer successfully added", "success");
          onCloseModalQuickView();

          // setOpenToast(true);
          // setColor(COLORS.Primary);
          // setAlertClass("success");
          // setMsg("Successfully updated");
          // getCurrentUser();
          // seteDisable(true);
          // getUser();

          // history.push("/");
          // window.location.reload();
        })
        .catch((error) => {
          setlOpen(false);
          console.log("Error", error.response);
          setbOpen(true);
          setbMsg(error.response.data.message);
          setbStatus("warning");

          // addToast(error.response.data.message, "warning");
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    } else {
      setbOpen(true);
      setbMsg("Required fields are missing and add missing fields.");
      setbStatus("warning");
    }
  };

  return (
    <>
      <LoadingSpinner open={lOpen} />
      <div className="justify-center w-full mt-2">
        {!selectedJob && (
          <div className="flex justify-center">
            {/* <span className="text-xs font-normal flex justify-center w-full md:w-9/12 lg:w-[45%] text-center mt-1 mb-6">

                            Are you looking to hire talent for your company? You can post your
                            job vacancies through this page.

                        </span> */}
          </div>
        )}
      </div>
      <div className="w-full flex justify-center mb-20">
        <div className="w-full md:w-11/12 lg:w-12/12">
          {/* <span className="block text-xl font-bold flex justify-start w-full text-left text-[#003D35]">

                        {selectedJob ? "Edit Ad" : t("adButton")}
                    </span> */}

          <div className="col-span-12">
            {/* BEGIN: Display Information */}
            <div className="py-6 px-9">
              <div className="flex justify-between">
                <div>
                  <h2 className="text-lg text-blueF font-bold">
                    Complete Profile
                  </h2>

                  <h5 className="text-xs text-primaryF font-normal mt-2">
                    The following details will appear on the profile.{" "}
                  </h5>
                </div>

                <div>
                  <div className="relative rounded-full overflow-hidden flex">
                    <div className="w-20 h-20 rounded-full object-cover z-[2] border-2 border-white">
                      <img
                        // src={cover}
                        src={
                          avatar?.path?.includes("/uploads/") === true
                            ? IMAGE_SERVER_URL_DEV + avatar?.path
                            : avatar?.path
                        }
                        alt=""
                      />

                      <div
                        className={`absolute z-[3]  inset-0   ${
                          avatar && true
                            ? "hover:bg-black hover:bg-opacity-30"
                            : avatar
                            ? ""
                            : "bg-gray-400"
                        }  flex flex-col items-center justify-center text-transparent ${
                          avatar && true
                            ? "hover:text-neutral-50"
                            : avatar
                            ? "text-transparent"
                            : "text-neutral-50"
                        } cursor-pointer`}
                      >
                        <svg
                          className={`${avatar ? "" : "text-neutral-50"}`}
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                            stroke="currentColor"
                            strokeWidth={1.5}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        <span
                          className={`mt-1 text-[10px] ${
                            avatar ? "" : "text-neutral-50"
                          }`}
                        >
                          {avatar ? "Change Image" : "Upload Image"}
                        </span>
                        {/* <span className={`text-[8px] ${avatar ? "" : "text-neutral-50"}`}>{avatar ? "(500 x 500)px" : "(500 x 500)px"}</span> */}

                        {true && (
                          <Input
                            type="file"
                            className="absolute inset-0 opacity-0 cursor-pointer"
                            // onChange={onSelectFile2}
                            onChange={async (e) => {
                              let file = e.target.files[0];

                              var extension = file.type;

                              extension = extension.split("/")[1];

                              console.log(">>>", extension.split("/")[1]);

                              let reader = new FileReader();
                              reader.readAsDataURL(file);
                              let fileInp = e.target.files[0];
                              let baseURL;
                              // on reader load somthing...
                              reader.onload = () => {
                                // Make a fileInfo Object
                                baseURL = reader.result;
                                let obj = {
                                  // image: baseURL,
                                  path: baseURL,
                                  thumbnail: false,
                                  name: file.name,
                                  ext: upperCase(extension)
                                };
                                setavatar(obj);
                              };
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-2 mt-8 cursor-pointer">
                <div
                  className={`text-white ${
                    proTab === 1 ? "bg-[#D6073E]" : "bg-blueF"
                  } text-xs text-center px-2 py-3 rounded-md`}
                  onClick={() => {
                    setproTab(1);
                  }}
                >
                  Personal Details
                </div>

                <div
                  className={`text-white ${
                    proTab === 2 ? "bg-[#D6073E]" : "bg-blueF"
                  } text-xs text-center px-2 py-3 rounded-md`}
                  onClick={() => {
                    setproTab(2);
                  }}
                >
                  Work Samples
                </div>
                {/* 
                                <div className={`text-white ${proTab === 3 ? "bg-[#D6073E]" : "bg-blueF"} text-xs text-center px-2 py-3 rounded-md`}
                                    onClick={() => {
                                        setproTab(3);
                                    }}>
                                    Qualifications
                                </div>

                                <div className={`text-white ${proTab === 4 ? "bg-[#D6073E]" : "bg-blueF"} text-xs text-center px-2 py-3 rounded-md`}
                                    onClick={() => {
                                        setproTab(4);
                                    }}
                                >
                                    Career Achievements
                                </div> */}
              </div>

              {proTab === 1 && (
                <>
                  <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-7 mt-8 cursor-pointer text-[#86949F] text-sm">
                    <div className="">
                      <div className="">
                        <label
                          htmlFor="update-profile-form-3"
                          className="form-label"
                        >
                          Select top 5 fields *
                        </label>

                        <div>
                          <Popover className="relative mt-1.5">
                            {({ open, close }) => (
                              <>
                                <Popover.Button
                                  className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${
                  top5?.length != 0
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "bg-[#F4F4F4] border-white dark:border-neutral-700  dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
                                >
                                  <span
                                    className={`ml-2 whitespace-nowrap  ${"text-sm"}`}
                                  >
                                    {top5?.length > 0
                                      ? top5[0]
                                      : "Select top 5 fields"}
                                  </span>
                                  {top5?.length === 0 ? (
                                    <ChevronDownIcon className="w-4 h-4 ml-3" />
                                  ) : (
                                    <span
                                      onClick={() =>
                                        // setSizesState([])
                                        settop5([])
                                      }
                                    >
                                      {renderXClear()}
                                    </span>
                                  )}
                                </Popover.Button>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-200"
                                  enterFrom="opacity-0 translate-y-1"
                                  enterTo="opacity-100 translate-y-0"
                                  leave="transition ease-in duration-150"
                                  leaveFrom="opacity-100 translate-y-0"
                                  leaveTo="opacity-0 translate-y-1"
                                >
                                  <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm ">
                                    <div className=" max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                                      <div className="relative flex flex-col px-5 py-6 space-y-5">
                                        {[
                                          "field1",
                                          "field2",
                                          "field3",
                                          "field4",
                                          "field5"
                                        ].map((item) => (
                                          <div key={item} className="">
                                            <Checkbox
                                              name={item}
                                              label={item}
                                              checked={top5?.includes(item)}
                                              // defaultChecked={categoriesState.includes(item.mainId)}
                                              sizeClassName="w-5 h-5"
                                              labelClassName="text-sm font-normal font-[Inter]"
                                              onChange={(checked) => {
                                                seterrtop5(false);
                                                if (top5?.includes(item)) {
                                                  const index =
                                                    top5.indexOf(item);

                                                  console.log(
                                                    ">>>>>>index",
                                                    index
                                                  );

                                                  var arr = [...top5];
                                                  if (index > -1) {
                                                    arr.splice(index, 1);
                                                    console.log("arr>>>>", arr);
                                                    settop5(arr);
                                                  }
                                                } else {
                                                  settop5((data) => [
                                                    ...data,
                                                    item
                                                  ]);
                                                }

                                                // settop5([item]);
                                                // setSelectedcat(item.value);

                                                // setselectedColour("");
                                                // setselectedSize("");
                                                // setCurrentPage(1);
                                                // removeLocal();
                                              }}
                                              // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                                            />
                                          </div>
                                        ))}
                                        {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                                      </div>
                                      {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </>
                            )}
                          </Popover>
                        </div>
                        {errtop5 && (
                          <div className="text-red-600 mt-2 text-right text-xs">
                            This Field is Required
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="">
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Surname *
                      </label>
                      <Input
                        id="update-profile-form-1"
                        type="text"
                        className={classnames({
                          "form-control": true,
                          "border-red-600": errsurName,
                          "bg-[#F4F4F4]": true,
                          "border-none": true,
                          "mt-1.5": true
                        })}
                        placeholder="Surname"
                        value={surName}
                        onChange={(e) => {
                          setsurName(e.target.value);
                          seterrsurName(false);
                        }}
                        // disabled
                      />
                      {errsurName && (
                        <div className="text-red-600 mt-2 text-right text-xs">
                          This Field is Required
                        </div>
                      )}
                    </div>

                    <div className="">
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Other Names *
                      </label>
                      <Input
                        id="update-profile-form-1"
                        type="text"
                        className={classnames({
                          "form-control": true,
                          "border-red-600": errotherName,
                          "bg-[#F4F4F4]": true,
                          "border-none": true,
                          "mt-1.5": true
                        })}
                        placeholder="Other Names"
                        value={otherName}
                        onChange={(e) => {
                          setotherName(e.target.value);
                          seterrotherName(false);
                        }}
                        // disabled
                      />
                      {errotherName && (
                        <div className="text-red-600 mt-2 text-right text-xs">
                          This Field is Required
                        </div>
                      )}
                    </div>

                    <div className="">
                      <div className="">
                        <label
                          htmlFor="update-profile-form-3"
                          className="form-label"
                        >
                          Gender *
                        </label>

                        <div>
                          <Autocomplete
                            className="mt-0"
                            // className="form-group"
                            // className="border-neutral-200"
                            disablePortal
                            // defaultValue={{ value: category }}
                            // value={{ value: category, name: CATEG.find((v) => v.value === category) ? CATEG.find((v) => v.value === category).name : "All" }}
                            value={gender}
                            // id="combo-box-demo"
                            options={["Male", "Female"]}
                            getOptionLabel={(option) => option}
                            onChange={(e, value) => {
                              setgender(value);
                              seterrgender(false);

                              // setCountry(value);
                            }}
                            // sx={{
                            //   // width: "80vw",
                            //   background:"transparent",
                            //   borderRadius: "20px",
                            //   // border: "none",
                            //   // borderColor: "transparent",

                            //   "& .MuiInputBase-input": {
                            //     height: 15,
                            //     // border: "none",
                            //     fontSize: "14px",
                            //     fontFamily: "'Jost', sans-serif",
                            //     // borderColor: "transparent",
                            //   },
                            //   //view
                            //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            //     // borderColor: "transparent",
                            //   },

                            //   "&:hover .MuiOutlinedInput-input": {
                            //     color: "black",
                            //   },
                            //   "&:hover .MuiInputLabel-root": {
                            //     color: COLORS.GrayBackground,
                            //   },
                            //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            //   {
                            //     // borderColor: "transparent",
                            //   },
                            //   ".MuiAutocomplete-root fieldset:hover": {
                            //     color: "transparent",
                            //     // borderColor: "transparent",
                            //   },
                            // }}
                            renderInput={(params) => (
                              <div ref={params.InputProps.ref}>
                                <Input
                                  {...params.inputProps}
                                  className={classnames({
                                    "form-control": true,
                                    "border-red-600": errgender,
                                    "bg-[#F4F4F4]": true,
                                    "border-none": true,
                                    "mt-1.5": true
                                  })}
                                  placeholder="Select Gender"
                                />
                              </div>
                            )}
                            ListboxProps={{
                              style: {
                                maxHeight: "150px",
                                fontSize: "14px"
                                // border: '1px solid white'
                              }
                            }}
                          />

                          {errgender && (
                            <div className="text-red-600 mt-2 text-right text-xs">
                              This Field is Required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="update-profile-form-3"
                        className="form-label"
                      >
                        Years of Experience *
                      </label>
                      <div className="">
                        <Input
                          id="update-profile-form-1"
                          min={0}
                          type="number"
                          className={classnames({
                            "form-control": true,
                            "border-red-600": errexperience,
                            "bg-[#F4F4F4]": true,
                            "border-none": true,
                            "mt-1.5": true
                          })}
                          placeholder="Years of Experience"
                          value={experience}
                          onChange={(e) => {
                            setexperience(e.target.value);
                            seterrexperience(false);
                          }}
                          // disabled
                        />
                        {errexperience && (
                          <div className="text-red-600 mt-2 text-right text-xs">
                            This Field is Required
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="">
                      <div className="">
                        <label
                          htmlFor="update-profile-form-3"
                          className="form-label"
                        >
                          District *
                        </label>

                        <div>
                          <Autocomplete
                            className="mt-0"
                            // className="form-group"
                            // className="border-neutral-200"
                            disablePortal
                            defaultValue={{ name_en: homeDistrict }}
                            value={{ name_en: homeDistrict }}
                            // id="combo-box-demo"
                            options={allHDistricts}
                            getOptionLabel={(option) => option.name_en}
                            onChange={(event, value) => {
                              handleChangeHdist(event, value);
                              // setCountry(value);
                            }}
                            // sx={{
                            //   // width: "80vw",
                            //   background:"transparent",
                            //   borderRadius: "20px",
                            //   // border: "none",
                            //   // borderColor: "transparent",

                            //   "& .MuiInputBase-input": {
                            //     height: 15,
                            //     // border: "none",
                            //     fontSize: "14px",
                            //     fontFamily: "'Jost', sans-serif",
                            //     // borderColor: "transparent",
                            //   },
                            //   //view
                            //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            //     // borderColor: "transparent",
                            //   },

                            //   "&:hover .MuiOutlinedInput-input": {
                            //     color: "black",
                            //   },
                            //   "&:hover .MuiInputLabel-root": {
                            //     color: COLORS.GrayBackground,
                            //   },
                            //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            //   {
                            //     // borderColor: "transparent",
                            //   },
                            //   ".MuiAutocomplete-root fieldset:hover": {
                            //     color: "transparent",
                            //     // borderColor: "transparent",
                            //   },
                            // }}
                            renderInput={(params) => (
                              <div ref={params.InputProps.ref}>
                                <Input
                                  {...params.inputProps}
                                  className={classnames({
                                    "form-control": true,
                                    "border-red-600": errhomeDistrict,
                                    "bg-[#F4F4F4]": true,
                                    "border-none": true,
                                    "mt-1.5": true
                                  })}
                                  placeholder="Select District"
                                />
                              </div>
                            )}
                            ListboxProps={{
                              style: {
                                maxHeight: "150px",
                                fontSize: "14px"
                                // border: '1px solid white'
                              }
                            }}
                          />

                          {errhomeDistrict && (
                            <div className="text-red-600 text-sm text-right mt-2">
                              This field is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Email Address *
                      </label>
                      <Input
                        id="update-profile-form-1"
                        type="text"
                        className={classnames({
                          "form-control": true,
                          "border-red-600": erremail,
                          "bg-[#F4F4F4]": true,
                          "border-none": true,
                          "mt-1.5": true
                        })}
                        placeholder="Email"
                        value={email}
                        onChange={(e) => {
                          setemail(e.target.value);
                          seterremail(false);
                        }}
                        // disabled
                      />
                      {erremail && (
                        <div className="text-red-600 mt-2 text-right text-xs">
                          This Field is Required
                        </div>
                      )}
                    </div>

                    <div className="">
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Mobile Number *
                      </label>
                      <Input
                        id="update-profile-form-1"
                        type="text"
                        className={classnames({
                          "form-control": true,
                          "border-red-600": errcontact,
                          "bg-[#F4F4F4]": true,
                          "border-none": true,
                          "mt-1.5": true
                        })}
                        placeholder="Mobile"
                        value={contact}
                        onChange={(e) => {
                          setcontact(e.target.value);
                          seterrcontact(false);
                        }}
                        // disabled
                      />
                      {errcontact && (
                        <div className="text-red-600 mt-2 text-right text-xs">
                          This Field is Required
                        </div>
                      )}
                    </div>

                    <div className="">
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Land Number
                      </label>
                      <Input
                        id="update-profile-form-1"
                        type="text"
                        className={classnames({
                          "form-control": true,
                          "border-red-600": errlandNo,
                          "bg-[#F4F4F4]": true,
                          "border-none": true,
                          "mt-1.5": true
                        })}
                        placeholder="Land Number"
                        value={landNo}
                        onChange={(e) => {
                          setlandNo(e.target.value);
                          seterrlandNo(false);
                        }}
                        // disabled
                      />
                      {errlandNo && (
                        <div className="text-red-600 mt-2 text-right text-xs">
                          This Field is Required
                        </div>
                      )}
                    </div>

                    <div className="col-span-3">
                      <div className="">
                        <Textarea
                          rows={7}
                          id="update-profile-form-5"
                          className="form-control bg-[#F4F4F4] text-[#86949F] mt-1.5"
                          placeholder="Describe about you and the experience"
                          value={duties}
                          onChange={(e) => {
                            setduties(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end mt-12">
                    <div
                      className="flex items-center text-white font-medium bg-blueF w-fit px-16 py-2 rounded-full text-sm space-x-4 cursor-pointer"
                      onClick={() => {
                        setproTab(2);
                      }}
                    >
                      <span>Next</span>
                    </div>
                  </div>
                </>
              )}

              {proTab === 2 && (
                <>
                  <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-7 mt-8 cursor-pointer text-[#86949F] text-sm">
                    <div className="col-span-3">
                      <div className="w-full mt-3 xl:mt-6 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                        <div className="grid grid-cols-10 gap-5 pl-4 pr-5">
                          {images?.map((faker, fakerKey) => (
                            <>
                              <div
                                // key={fakerKey}
                                className="col-span-6 md:col-span-2 h-28 relative image-fit cursor-pointer zoom-in"
                              >
                                {/* <div className="flex flex-col ">
                                                                    <img className="cursor-pointer w-[60px]" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nO3Xv0skMRQH8GnGH6yoWCiChbJgZaGNtYWNnf+CFqJYWIpiJ8gVgjaiYiX+BRaCrY0iy3EWarGIhYiLhQiLyWzmzeR7JKO76KLHqclucB6EYbr3Sd6bl/G8NNIwG8z3YWj9chfQ12cPwQwAwj+/wbJZOwgTALov2EMwQwBrCGYQQDYQpgFkGmEDQCYRtgBkCmETQCYQtgH0BlGXgP9ZXgrw0xOAcyVUGhuDzOchi0WECwvuAeTtLcoRx+C9ve4AeFdXJfejI/0sjY+7A2CZDBBFCeDiwkGA7yM+OyuXj4pgeNgtQLi4WOkBIrC2NrcAvLsbCILkEHK5TyfPajmJo4ODBHB8DNbQ4BigqQmyUChXkZiddW+Q6RBCP9RA4/397gBod1cnHq6sINrfTxBXV+A9PfUP4KqBn570LODZLHhnJ+LLS42IT0/16Yi5OdDGBmhnB7S9DTExAdbcXB+AcHlZJ6uaWL0Hg4OgrS38K2hvr/YA3tEB+fCQ7PbJCeT19essn3viJWQ+j/j8PHnhvMaATAa0tla1s6p8wtVVlEZHwVpa9JBTTf02osPDGgEaGyFmZiBvbio7Wyzq+n7vCqEufGJyErS+DtrchJieBmtvtw8IBgZ0Y77a8VxOl9Jnvl7MJiAYGYF8fKyq8WBo6NuSZ8YAatI+l4y8uyvfOsOlpW9NnhkDtLZWfVHU8PrKnYfZLiExNaV3X/0+hvPz7w6iugUwS8tLAX56AkhL6EeXUBppeB/GX+axT5lwmtMeAAAAAElFTkSuQmCC" />
                                                                    <span className="text-xs flex justify-center text-center">{faker?.name}</span>
                                                                </div> */}

                                {
                                  // faker.path.includes('/uploads/') === true &&

                                  <img
                                    className="rounded-md"
                                    alt=""
                                    src={
                                      faker.path.includes("/uploads/") === true
                                        ? IMAGE_SERVER_URL_DEV + faker?.path
                                        : faker?.path
                                    }
                                  />
                                }

                                <div className="flex flex-col">
                                  {/* <img className="cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nO3Xv0skMRQH8GnGH6yoWCiChbJgZaGNtYWNnf+CFqJYWIpiJ8gVgjaiYiX+BRaCrY0iy3EWarGIhYiLhQiLyWzmzeR7JKO76KLHqclucB6EYbr3Sd6bl/G8NNIwG8z3YWj9chfQ12cPwQwAwj+/wbJZOwgTALov2EMwQwBrCGYQQDYQpgFkGmEDQCYRtgBkCmETQCYQtgH0BlGXgP9ZXgrw0xOAcyVUGhuDzOchi0WECwvuAeTtLcoRx+C9ve4AeFdXJfejI/0sjY+7A2CZDBBFCeDiwkGA7yM+OyuXj4pgeNgtQLi4WOkBIrC2NrcAvLsbCILkEHK5TyfPajmJo4ODBHB8DNbQ4BigqQmyUChXkZiddW+Q6RBCP9RA4/397gBod1cnHq6sINrfTxBXV+A9PfUP4KqBn570LODZLHhnJ+LLS42IT0/16Yi5OdDGBmhnB7S9DTExAdbcXB+AcHlZJ6uaWL0Hg4OgrS38K2hvr/YA3tEB+fCQ7PbJCeT19essn3viJWQ+j/j8PHnhvMaATAa0tla1s6p8wtVVlEZHwVpa9JBTTf02osPDGgEaGyFmZiBvbio7Wyzq+n7vCqEufGJyErS+DtrchJieBmtvtw8IBgZ0Y77a8VxOl9Jnvl7MJiAYGYF8fKyq8WBo6NuSZ8YAatI+l4y8uyvfOsOlpW9NnhkDtLZWfVHU8PrKnYfZLiExNaV3X/0+hvPz7w6iugUwS8tLAX56AkhL6EeXUBppeB/GX+axT5lwmtMeAAAAAElFTkSuQmCC" /> */}
                                  {/* <span className="text-xs flex justify-center text-center">{faker?.name}</span> */}
                                </div>

                                <div className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 -mr-2 -mt-2">
                                  <div
                                    onClick={(e) => {
                                      var array = [...images];
                                      array.splice(fakerKey, 1);
                                      setImages(array);
                                    }}
                                  >
                                    {/* <Lucide icon="X" className="w-4 h-4" /> */}
                                    <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                        <div className="px-4 pb-4 flex flex-col items-center justify-center cursor-pointer relative text-sm mt-4">
                          <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                          {/* <Lucide icon="File" className="w-5 h-5" /> */}
                          Upload images or drag and drop
                          <Input
                            id="horizontal-form-1"
                            type="file"
                            multiple
                            accept={"image/png, image/jpeg"}
                            className="w-full h-full top-0 left-0 absolute opacity-0"
                            onChange={(e) => {
                              Array.from(e.target.files).map((item) => {
                                let file = item;
                                let reader = new FileReader();
                                reader.readAsDataURL(file);
                                let fileInp = item;
                                let baseURL;
                                // on reader load somthing...
                                reader.onload = () => {
                                  // Make a fileInfo Object
                                  baseURL = reader.result;
                                  let obj = {
                                    path: baseURL,
                                    thumbnail: false,
                                    name: file.name
                                  };
                                  console.log(">>>>>", obj);

                                  setImages((existing) => [...existing, obj]);
                                };
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="flex justify-end mt-12">
                                        <div className="flex items-center text-white font-medium bg-blueF w-fit px-16 py-2 rounded-full text-sm space-x-4 cursor-pointer"
                                            onClick={() => {
                                                setproTab(3)
                                            }}
                                        >

                                            <span>Next</span>
                                        </div>
                                    </div> */}
                </>
              )}

              {/* {
                                proTab === 1 &&

                                <Profile
                                    proTab={proTab}
                                    setproTab={setproTab}
                                />

                            }

                            {
                                proTab === 2 &&

                                <Work
                                    proTab={proTab}
                                    setproTab={setproTab}
                                />

                            }

                            {
                                proTab === 3 &&

                                <Qualifications
                                    proTab={proTab}
                                    setproTab={setproTab}
                                />

                            }

                            {
                                proTab === 4 &&

                                <Achievements

                                />

                            } */}

              {proTab === 2 && (
                <>
                  <div className="w-full items-center justify-center">
                    <BotToast open={bOpen} msg={bMsg} status={bStatus} />
                  </div>

                  <div className="flex justify-end mt-12">
                    <div
                      className="flex items-center text-white font-medium bg-blueF w-fit px-16 py-2 rounded-full text-sm space-x-4 cursor-pointer"
                      onClick={saveJobSeeker}
                    >
                      <span>Save Profile</span>
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* END: Display Information */}

            {/* 
                        <div className="w-full flex justify-end">

                            <button className="btn btn-primary w-20 mt-3"
                            // onClick={onSubmit}
                            >
                                Save
                            </button>

                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Jobs;
