import React, { FC, useState, useEffect, useRef, useContext } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL } from "Constant/System";
import Company1 from "images/avatars/Image-1.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
// import ProductCard from "components/ProductCardNew";
import { getWishlistList } from "Services/API/Get";
import WishContext from "Context/WishContext";
import ProductCard from "components/CardCategories/ProductCard";
import { NavLink } from "react-router-dom";


const SaveList = () => {

    const [images, setImages] = useState([]);

    const [array, setArray] = useState([]);

    const [lopen, setlopen] = useState(false);


    const [limit, setlimit] = useState(6);

    const { wish, setWish } = useContext(WishContext);

    React.useEffect(() => {
        getWish();

    }, [wish, limit])

    const getWish = () => {
        setlopen(true);
        getWishlistList({ limit: limit })
            .then((res) => {
                setlopen(false);
                console.log("get Wish page>>>>>", res)

                setArray(res.data);
                // setCart(res.data.length);

                // this.setState({
                //   array: res.data,
                //   total: m,
                // });
            })
            .catch((error) => {
                console.error(error);
                setlopen(false);
                setArray([]);
                // setCart(0);
            });

    }



    return (
        <>
            <div className="flex justify-center w-full mt-12">

                <span className="block text-xl font-bold flex justify-center w-full text-center">
                    List of saved products
                </span>


            </div>
            <div className="w-full flex justify-center px-3">

                <div className="w-full md:w-8/12 lg:w-10/12 xl:w-8/12">
                    {
                        array.length > 0 ?

                            <>
                                <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-3 lg:grid-cols-4 mt-10">
                                    {array.map((stay) => (
                                        <ProductCard data={stay} />
                                    ))}
                                </div>
                                <div className="flex !mt-20 justify-center items-center">
                                    <ButtonSecondary
                                        onClick={() => setlimit(limit + 3)}
                                        loading={lopen}
                                    >Show me more</ButtonSecondary>
                                </div>

                            </>

                            :
                            <>
                                <div className="flex !mt-20 justify-center items-center">
                                    <p className="text-xl font-semibold">
                                        {/* #WU3746HGG12 */}
                                        No items found in wishlist
                                    </p>

                                </div>
                                <div className="flex  justify-center items-center mt-4">

                                    <NavLink to={"/sellers"}>
                                        <ButtonPrimary>Shop Now</ButtonPrimary>
                                    </NavLink>
                                </div>

                            </>

                    }

                </div>
            </div>

        </>
    )

}

export default SaveList;