export const PACKAGES = [
    {
        isPopular: false,
        name: "Starter",
        pricing: "FREE",
        per: "/ Up to 7 days",
        features: ["Marketplace access", "Event posting", "Blog posting", "Farming forum access and consultancy", "Online shop", "Payment gateway", "Unlimited", "Inventory management", "Automated shipping cost", "Automated notifications", "3.99%"],
        desc: ` Literally you probably haven't heard of them jean shorts.`,
        id: 1,
    },
    {
        isPopular: true,
        name: "Basic",
        pricing: "$15",
        per: "/ Up to 28 days",
        features: ["Marketplace access", "Event posting", "Blog posting", "Farming forum access and consultancy", "Online shop", "Payment gateway", "Unlimited", "Inventory management", "Automated shipping cost", "Automated notifications", "3.99%"],
        desc: ` Literally you probably haven't heard of them jean shorts.`,
        id: 2,
    },
    {
        isPopular: false,
        name: "Plus",
        pricing: "$30",
        per: "/ Up to 84 days",
        features: ["Marketplace access", "Event posting", "Blog posting", "Farming forum access and consultancy", "Online shop", "Payment gateway", "Unlimited", "Inventory management", "Automated shipping cost", "Automated notifications", "3.99%"],
        desc: ` Literally you probably haven't heard of them jean shorts.`,
        id: 3
    },
];