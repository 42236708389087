import React, { FC, useContext } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link, NavLink, useHistory } from "react-router-dom";
import ButtonCircle from "shared/Button/ButtonCircle";
import Company1 from "images/avatars/Image-1.png";
import { StarIcon } from "@heroicons/react/24/solid";
import Rating from '@mui/material/Rating';
import ModalQuickView from "components/ModalQuote";
import AuthContext from "Context/AuthContext";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import LikeButton from "components/LikeButton";
import { addToast } from "shared/Toast";

export interface CardCategory2Props {
  className?: string;
  ratioClass?: string;
  bgClass?: string;
  featuredImage?: string;
  name?: string;
  desc?: string;
  collection?: any;
  data?: any;
}

const CardCategory2: FC<CardCategory2Props> = ({
  className = "",
  ratioClass = "aspect-w-1 aspect-h-1",
  bgClass = "bg-orange-50",
  featuredImage = ".",
  name,
  desc,
  collection,
  data
}) => {
  const [showModalQuickView, setShowModalQuickView] = React.useState(false)

  const router = useHistory();
  const { user, setUser } = useContext(AuthContext);

  const getState = (item?: any) => {

    if (item === "Victoria") {
      return "VIC"
    } else if (item === "New South Wales") {
      return "NSW"
    } else if (item === "Western Australia") {
      return "WA"
    } else if (item === "Queensland") {
      return "QLD"
    } else if (item === "Tasmania") {
      return "TAS"
    } else if (item === "South Australia") {
      return "SA"
    } else if (item === "Northern Territory") {
      return "NT"
    } else if (item === "Australian Capital Territory") {
      return "ACT"
    }

  }


  return (
    <>
      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
        jobId={data}
        type={"contract"}
      />

      <div

        className={`nc-CardCategory2 ${className}`}
        data-nc-id="CardCategory2"
      >
        <div
        // className={`flex-1 relative w-full h-fit rounded-2xl group`}
        >

          <div className="bg-[#F4F4F4] rounded-2xl">



            <div className="flex justify-end w-full">
              {/* <i className="las la-bookmark text-4xl text-left mr-1 mt-2 "></i> */}
              {/* <img className="text-4xl text-left mr-1 mt-2 cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA00lEQVR4nO2VMU4CQRiFNzQmlhQewIYj2HgBQmPnMSw5Btew4w5WHoFslnnfm2ULCkoTG1lDgYUJgR0WtJgv+bv38iWTmX+KIvMfACZIK0PbZZBqSeN0sVR3lf7IISaLnSjdTxafTD5q58tFfk7kBZKG867mj38npK3tqe0XpK9riT8dwvM+E6UnpI+LipE2th9/5+oQHgzry4ilZQhhdDBr3xsWvYqB96qq7o7lY4xDpLdexMC8aZrbUztlWd4Ar2eJgVnbtoOuvV1n100WZ4oe+QZXC8i3JwqA3AAAAABJRU5ErkJggg==" /> */}
              {/* <div className="px-4 bg-[#FF4848] rounded-xl mr-2 mt-4 flex items-center py-1">
                <span className="text-xs text-slate-100">Featured</span>
              </div> */}
            </div>
            <div className={`${collection ? "px-4" : "px-7"} pb-6 pt-6`}>

              <NavLink to={`/contract-single/${data?.id}`} >


                <div className="">

                  <div className="flex justify-between w-full">

                    <div className="flex">

                      <h2 className="text-xs sm:text-xs text-slate-900 dark:text-neutral-100 font-semibold h-6">

                        {
                          data ? data?.title?.slice(0, 21) : "Company description"
                        }

                        {
                          data?.title?.length >= 21 ? "..." : ""
                        }

                        {/* {
                          data ? data?.title : "Ad Title should be here appear here "
                        } */}


                      </h2>

                    </div>

                    <div className="flex">

                      {/* <LikeButton
                        // liked={_.map(wish, "id").includes(id)} 
                        className=""
                      //  addWish={notifyAddToWish} 
                      /> */}
                    </div>
                  </div>



                  <span className={`flex flex-col lg:flex-row lg:items-center ${collection ? "text-[11px]" : "text-xs"} text-neutral-500 dark:text-neutral-400 mt-3 justify-start`}>
                    {/* <Rating name="size-small" defaultValue={4} size="small" className="" readOnly /> */}
                    {/* <div className="flex items-center space-x-0.5  text-yellow-500 mr-2">
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
              </div> */}

                    <span className="text-slate-900 flex items-center justify-start order-2 lg:order-1">
                      {/* <i className="las la-eye text-lg text-left mr-1"></i>
                     
                      {data?.viewsCount} */}
                      <i className="las la-map-marker-alt text-lg text-left mr-1"></i>
                      {data?.suburb} {getState(data?.state)}
                    </span>

                    <span className="lg:ml-4 text-slate-900 flex items-center justify-start order-1 lg:order-2">
                      {/* <i className="las la-map-marker-alt text-lg text-left mr-1"></i>
                      {data?.suburb},{getState(data?.state)} */}
                    </span>

                    {/* <span className="text-slate-900 flex items-center">
                      <i className="las la-calendar text-lg text-left mr-2"></i>
                      {
                        data ? data?.duration : "1 month"
                      }

                    </span>

                    <span className="text-slate-900 flex items-center">
                      <i className="las la-clock text-lg text-left mr-2"></i>
                      5 minutes ago
                    </span> */}


                  </span>

                  <div className="">
                    <span className="block mt-4 text-[11.5px] leading-normal font-medium text-slate-900 font-normal">

                      <span>Type</span>
                      <span className="font-bold ml-7">:  Residential</span>

                    </span>

                  </div>

                  <div className={`${data?.description === "" ? "h-12" : "h-16 lg:h-12"}`}>
                    <span className="block mt-3 text-[11.5px] leading-normal font-medium text-slate-900 font-normal">
                      {
                        data ? data?.description?.slice(0, 80) : " Company description - See completed projects and flexible talent strategies from ......"
                      }
                      {
                        data?.description?.length >= 80 ? "......." : ""
                      }
                    </span>
                  </div>


                </div>

              </NavLink>

              <div className="mt-6">

                <button
                  className={
                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b] 
        w-full h-9 text-slate-50 font-medium mt-2 cursor-pointer`}

                  onClick={() => {
                    // if (!user) {
                    //   router.push("/login");
                    //   return;
                    // }
                    if (!(user?.userType === "service seeker" || user?.userType === "service provider")) {
                      addToast("You should login as a service seeker or service provider.", "warning");
                      router.push("/login");

                      return;
                    }
                    setShowModalQuickView(true);
                  }}

                >
                  {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
                  <span className="block  text-xs font-medium">
                    Submit a quote
                  </span>

                </button>


              </div>

              {/* <img
            className="object-cover "
            src={featuredImage}

          /> */}
              {/* <NcImage
            containerClassName="w-full h-full flex justify-center"
            src={featuredImage}
            className="object-cover rounded-2xl"
          /> */}
            </div>
          </div>
          <div className="pt-5"></div>
          {/* <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span> */}
        </div>
        {/* <div className="mt-5 flex-1 text-center">
        <h2 className="text-base sm:text-base text-neutral-900 dark:text-neutral-100 font-semibold">
          {name}
        </h2>
        <span className="block mt-0.5 sm:mt-1.5 text-sm text-neutral-500 dark:text-neutral-400">
          {desc}
        </span>
      </div> */}
      </div >
    </>
  );
};

export default CardCategory2;
