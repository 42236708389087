import ProductCard from "components/ProductCardNew";
import WishContext from "Context/WishContext";
import { PRODUCTS } from "data/data";
import React, { useContext, useState } from "react";
import { getWishlistList } from "Services/API/Get";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { NavLink } from "react-router-dom";

const AccountSavelists = () => {
  const [array, setArray] = useState([]);

  const [lopen, setlopen] = useState(false);


  const [limit, setlimit] = useState(6);

  const { wish, setWish } = useContext(WishContext);

  React.useEffect(() => {
    getWish();

  }, [wish, limit])

  const getWish = () => {
    setlopen(true);
    getWishlistList({ limit: limit })
      .then((res) => {
        setlopen(false);
        console.log("get Wish page>>>>>", res)

        setArray(res.data);
        // setCart(res.data.length);

        // this.setState({
        //   array: res.data,
        //   total: m,
        // });
      })
      .catch((error) => {
        console.error(error);
        setlopen(false);
        setArray([]);
        // setCart(0);
      });

  }



  const renderSection1 = () => {

    return (
      <div className="space-y-10 sm:space-y-12">
        <div>
          <h2 className="text-2xl sm:text-2xl font-semibold">
            List of saved products
          </h2>
        </div>

        {
          array.length > 0 ?

            <>
              <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 ">
                {array.map((stay: any) => (
                  <ProductCard key={stay.id} data2={stay} />
                ))}
              </div>
              <div className="flex !mt-20 justify-center items-center">
                <ButtonSecondary
                  onClick={() => setlimit(limit + 3)}
                  loading={lopen}
                >Show me more</ButtonSecondary>
              </div>

            </>

            :
            <>
              <div className="flex !mt-20 justify-center items-center">
                <p className="text-xl font-semibold">
                  {/* #WU3746HGG12 */}
                  No items found in wishlist
                </p>

              </div>
              <div className="flex  justify-center items-center">

                <NavLink to={"/sellers"}>
                  <ButtonPrimary>Shop Now</ButtonPrimary>
                </NavLink>
              </div>

            </>

        }


      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default AccountSavelists;
