import React, { FC, useState, useEffect, useRef } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL, IMAGE_SERVER_URL_DEV } from "Constant/System";
import Company1 from "images/avatars/Image-1.png";
import ModalQuickView from "components/ModalProfile";
import { addToast } from "shared/Toast";
import { JobSeekerById, getJobs, getProfiles } from "Services/API/Get";
import { DeleteJob, DeleteProfile } from "Services/API/Delete";
import { useHistory } from "react-router-dom";

import ModalQuickView2 from "components/ModalApplicant";
import ModalQuickView3 from "components/ModalJob";

import DatePicker from "react-multi-date-picker"
import { Autocomplete, Tooltip, Zoom } from "@mui/material";
import { UpdateJobStatus, UpdateQuotationStatus } from "Services/API/Put";
import Profiles from "./Profiles"
import { t } from "i18next";
import SeekerSAdd from "./SeekerAdd"


const MyJobs = (props) => {

    const router = useHistory();

    const ref = useRef(null);

    const [images, setImages] = useState([]);
    const [showModalQuickView, setShowModalQuickView] = useState(false);

    const [showModalQuickView2, setShowModalQuickView2] = useState(false);
    const [showModalQuickView3, setShowModalQuickView3] = useState(false);

    const [jobs, setjobs] = useState([]);

    const [search, setsearch] = useState("");
    const [category, setcategory] = useState("");

    const [selectedJob, setselectedJob] = useState([]);

    const [currUser, setcurrUser] = useState(props.currUser);

    const [hideForm, sethideForm] = useState(false);


    const CATEG = [{
        value: "Seasonal",
        name: "Seasonal"
    },
    {
        value: "Casual",
        name: "Casual"
    },
    {
        value: "Temporary",
        name: "Temporary"
    },
    {
        value: "Fixed",
        name: "Fixed"
    },
    {
        value: "Part Time",
        name: "Permanent – Part Time"
    },
    {
        value: "Full Time",
        name: "Permanent – Full time"
    },


    ]

    const handleScroll = () => {
        setTimeout(() => {

            ref.current?.scrollIntoView({ behavior: 'smooth', block: "start", inline: "start" });


        }, 2500);
    };

    const handleScroll2 = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        // handleScroll();
    }, [])

    const getAllJobs = () => {
        console.log(">>>>>>sdsxcddf")
        // getProfiles({
        //     jobType: JSON.stringify([]),
        //     title: search,
        //     jobType: category ? JSON.stringify([category]) : JSON.stringify([])
        // })
        //     .then((res) => {
        //         console.log(">>>>>>>jobs", res.data);
        //         setjobs(res.data.result);
        //     })
        //     .catch((error) => {
        //         // setlopen(false);
        //         setjobs([]);
        //         console.log("Error", error.response);
        //         addToast(error.response.data.message, "warning");
        //         // setOpenToast(true);
        //         // setColor(COLORS.Orange);
        //         // setAlertClass("error");
        //         // setMsg(error.response.data.message);
        //     })

        JobSeekerById()
            .then((res) => {
                console.log(">>>>>>>profile", res.data);
                setselectedJob(res.data)
                // setjobs(res.data.result);
            })
            .catch((error) => {
                // setlopen(false);
                // setjobs([]);
                setselectedJob([])
                console.log("Error", error.response);
                // addToast(error.response.data.message, "warning");
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            })
    }

    useEffect(() => {
        getAllJobs();
    }, [showModalQuickView, search, category])




    return (
        <>
            {
                // jobs.length > 0 &&

                <div className="flex justify-center w-full mt-0">

                    {/* <span className="block text-3xl font-bold flex justify-center w-full text-center">
                        {t('hProfiles')}
                    </span> */}


                </div>

                // <></>


            }

            <div className="w-full flex justify-center">

                {/* <div className="w-full md:w-8/12 lg:w-7/12 xl:w-[45%]"> */}
                <div className="w-full">

                    <div className="flex w-full items-center justify-center">
                        {/* <button
                            className={
                                `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-12 `}

                            onClick={() => {
                                // if (currUser?.packageId) {
                                //     setselectedJob("");
                                //     setShowModalQuickView(true)
                                // } else {
                                //     router.push("/subscription")
                                // }

                                // setselectedJob("");
                                // setShowModalQuickView(true)

                                router.push("/profile?selectPage=ads")
                            }}
                        >
                          
                            <span className="block  text-sm font-medium "
                            >
                                Create an Ad
                            </span>

                        </button> */}
                    </div>
                    <form className="mt-0 w-full">

                        {/* <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                            </div>
                            <Input type="search" id="default-search" className="block w-full p-4 pl-10 text-base text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Name..." required
                                onChange={(e) => {
                                    setsearch(e.target.value);
                                }}
                            />

                        </div> */}
                    </form>

                    {/* <div className="w-full mt-4 space-y-4">

                        <div>
                            <Label className="text-sm">Category</Label>
                            <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                // defaultValue={{ value: category }}
                                value={{ value: category, name: CATEG.find((v) => v.value === category) ? CATEG.find((v) => v.value === category).name : "All" }}

                                // id="combo-box-demo"
                                options={[{ value: "", name: "All" }, ...CATEG]}
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => {
                                    setcategory(value.value);
                                    // seterrjobType(false);

                                    // setCountry(value);
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Select a category" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            />
                           
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>

                       


                    </div> */}

                    {/* <Profiles onCloseModalQuickView={getAllJobs} selectedJob={null} owner /> */}



                    <div className="flex w-full items-center justify-center">
                        <div className={`w-full md:w-9/12 lg:w-8/12 xl:w-[60%] py-4  mt-0`}>
                            {
                                selectedJob.length > 0 &&
                                <SeekerSAdd selectedJob={selectedJob[0]} onCloseModalQuickView={null} getAllJobs={getAllJobs} />

                            }

                            {
                                !selectedJob.length > 0 &&
                                <SeekerSAdd selectedJob={null} onCloseModalQuickView={null} getAllJobs={getAllJobs} />

                            }
                        </div>
                    </div>




                    {/* <div className="mt-0 space-y-2">
                        {
                            jobs.length > 0 && jobs.map((item, index) => {
                                return (
                                    <>




                                        <Profiles onCloseModalQuickView={getAllJobs} selectedJob={item} index={index} />

                                    </>

                                );
                            })
                        }

                    </div> */}

                </div>
            </div >

            {
                !hideForm &&

                <div ref={ref} >

                    {/* <Profiles onCloseModalQuickView={getAllJobs} selectedJob={null} sethideForm={sethideForm} /> */}



                </div>


            }

            <div className="w-full flex justify-center px-3 lg:px-0">

                <div className="w-full md:w-9/12 lg:w-8/12 xl:w-[50%]" >

                    {
                        hideForm &&

                        <button
                            className={
                                `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-white text-[#2d867b] border border-[#2d867b]
w-full h-14 sm:h-11 font-medium mt-4 mb-4 lg:mb-4`}
                            onClick={(e) => {
                                sethideForm(false)

                            }}

                        >
                            {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                            <span className="block  text-sm font-medium "
                            >
                                {
                                    t('pButtonSeeker')
                                }

                            </span>

                        </button>
                    }

                    {
                        hideForm &&


                        <button
                            className={
                                `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
w-full h-14 sm:h-11 text-slate-50 font-medium mt-4 mb-4 lg:mb-4`}
                            onClick={(e) => {
                                setShowModalQuickView3(true)

                            }}

                        >
                            {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                            <span className="block  text-sm font-medium "
                            >
                                {
                                    t('pButtonSeeker2')
                                }

                            </span>

                        </button>

                    }


                </div>

            </div>





            <ModalQuickView
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
                selectedJob={selectedJob}

            />

            <ModalQuickView2
                show={showModalQuickView2}
                onCloseModalQuickView={() => setShowModalQuickView2(false)}
                selectedJob={selectedJob}

            />

            <ModalQuickView3
                show={showModalQuickView3}
                onCloseModalQuickView={() => setShowModalQuickView3(false)}
                selectedJob={""}
            />

        </>
    )

}

export default MyJobs;