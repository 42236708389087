import React, { FC, useState, useEffect, useRef, useContext } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Textarea from "shared/Textarea/Textarea";
import Company1 from "images/avatars/Image-1.png";
import cover from "images/cover/cover-9.png";
import ModalQuickView from "components/ModalFAQ";
import AuthContext from "Context/AuthContext";
import { CurrentUser, GetCleaningType, VerifyABN, getCities, getDistricts, getProviderCategory, getProviderSubCategoryById, getSriCities, getSriDistricts } from "Services/API/Get";
import { EditUserQuery } from "Services/API/Put";
import _, { upperFirst, lowerCase } from 'lodash';
import { addToast } from "shared/Toast";
import { signUpVerification } from "Services/API/Post";
import { Button, Grid, TextField, Typography, Autocomplete, Tooltip } from "@mui/material";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import moment from "moment";
import SubData from "data/australian_postcodes.json";
import LoadingSpinner from "shared/LoadingSpinner";
import DatePicker from "react-multi-date-picker"
import BotToast from "shared/BotToast";
import Zoom from '@mui/material/Zoom';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { t } from "i18next";


const Account = ({ getAllFaqs, getUser, logUser, setselectedTab }) => {

    const CATEG = [{
        value: "Agri Technology"
    },
    {
        value: "Chemicals Supplies"
    },
    {
        value: "Consultancy"
    },
    {
        value: "Equipment Rentals"
    },
    {
        value: "Equipment Repairs"
    },
    {
        value: "Exporters"
    },
    {
        value: "Farm Supplies"
    },
    {
        value: "Financial Services"
    },
    {
        value: "Insurance"
    },
    {
        value: "Labour Supplies"
    },
    {
        value: "Logistic"
    },
    {
        value: "Machinery"
    },
    {
        value: "Other"
    },
    {
        value: "Pest Controlling"
    },
    {
        value: "Cold room Suppliers"
    },


    ]

    const COUNTArr = [
        "Afghanistan",
        "Åland (Finland)",
        "Albania",
        "Algeria",
        "American Samoa (US)",
        "Andorra",
        "Angola",
        "Anguilla (BOT)",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Artsakh",
        "Aruba (Netherlands)",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda (BOT)",
        "Bhutan",
        "Bolivia",
        "Bonaire (Netherlands)",
        "Bosnia and Herzegovina",
        "Botswana",
        "Brazil",
        "British Virgin Islands (BOT)",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cape Verde",
        "Cayman Islands (BOT)",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island (Australia)",
        "Cocos (Keeling) Islands (Australia)",
        "Colombia",
        "Comoros",
        "Congo",
        "Cook Islands",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Curaçao (Netherlands)",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "DR Congo",
        "East Timor",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands (BOT)",
        "Faroe Islands (Denmark)",
        "Fiji",
        "Finland",
        "France",
        "French Guiana (France)",
        "French Polynesia (France)",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar (BOT)",
        "Greece",
        "Greenland (Denmark)",
        "Grenada",
        "Guadeloupe (France)",
        "Guam (US)",
        "Guatemala",
        "Guernsey (Crown Dependency)",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man (Crown Dependency)",
        "Israel",
        "Italy",
        "Ivory Coast",
        "Jamaica",
        "Japan",
        "Jersey (Crown Dependency)",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Kosovo",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macau",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique (France)",
        "Mauritania",
        "Mauritius",
        "Mayotte (France)",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat (BOT)",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Caledonia (France)",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island (Australia)",
        "North Korea",
        "North Macedonia",
        "Northern Cyprus",
        "Northern Mariana Islands (US)",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn Islands (BOT)",
        "Poland",
        "Portugal",
        "Puerto Rico (US)",
        "Qatar",
        "Réunion (France)",
        "Romania",
        "Russia",
        "Rwanda",
        "Saba (Netherlands)",
        "Saint Barthélemy (France)",
        "Saint Helena, Ascension and Tristan da Cunha (BOT)",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin (France)",
        "Saint Pierre and Miquelon (France)",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "São Tomé and Príncipe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Eustatius (Netherlands)",
        "Sint Maarten (Netherlands)",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Korea",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Svalbard and Jan Mayen (Norway)",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Togo",
        "Tokelau (NZ)",
        "Tonga",
        "Transnistria",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands (BOT)",
        "Tuvalu",
        "U.S. Virgin Islands (US)",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Vatican City",
        "Venezuela",
        "Vietnam",
        "Wallis and Futuna (France)",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe"
    ]

    const { user, setUser } = useContext(AuthContext);

    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [businessName, setbusinessName] = useState("");
    const [businessReg, setbusinessReg] = useState("");
    const [year, setyear] = useState("");
    const [website, setwebsite] = useState("");
    const [country, setcountry] = useState("Sri Lanka");
    const [about, setabout] = useState("");
    const [category, setcategory] = useState("");
    const [contactName, setcontactName] = useState("");
    const [mobile, setmobile] = useState("");
    const [mobile2, setmobile2] = useState("");
    const [email, setemail] = useState("");
    const [premail, setpremail] = useState("");
    const [lopen, setlopen] = React.useState(false);

    const [homeNo, sethomeNo] = useState("");
    const [homeAddress, sethomeAddress] = useState("");
    const [homeDistrict, sethomeDistrict] = useState("");
    const [homeCity, sethomeCity] = useState("");
    const [homepostCode, sethomepostCode] = useState("");

    const [errhomeDistrict, seterrhomeDistrict] = useState(false);
    const [errhomeCity, seterrhomeCity] = useState(false);
    const [errhomepostCode, seterrhomepostCode] = useState(false);
    const [errhomeAddress, seterrhomeAddress] = useState(false);

    const [homeDistrictId, sethomeDistrictId] = useState(0);
    const [allHCities, setallHCities] = useState([]);
    const [allHDistricts, setallHDistricts] = useState([]);

    const [password, setPassword] = useState("");
    const [repassword, setrePassword] = useState("");
    const [idisable, setidisable] = useState(true);
    const [errbusinessName, seterrbusinessName] = useState(false);
    const [errbusinessReg, seterrbusinessReg] = useState(false);
    const [errcontactName, seterrcontactName] = useState(false);
    const [errmobile, seterrmobile] = useState("");
    const [errmobile2, seterrmobile2] = useState("");
    const [erremail, seterremail] = useState(false);
    const [erryear, seterryear] = useState("");
    const [errwebsite, seterrwebsite] = useState("");
    const [errcountry, seterrcountry] = useState("");
    const [errabout, seterrabout] = useState("");
    const [errcategory, seterrcategory] = useState("");
    const [errfirstName, seterrfirstName] = useState("");
    const [errlastName, seterrlastName] = useState("");

    const [errshippit, seterrshippit] = useState("");

    const [errPass, seterrPass] = useState(false);
    const [errRePass, seterrRePass] = useState("");

    const [userId, setuserId] = useState("");
    const [currUser, setcurrUser] = useState(logUser);
    const [eDisable, seteDisable] = useState(true);
    const [eDisable2, seteDisable2] = useState(true);

    // const [selectedTab, setselectedTab] = useState(1);

    const [co, setco] = useState("");



    const [subject, setsubject] = useState("");

    const [message, setmessage] = useState("");


    const [errsubject, seterrsubject] = useState(false);
    const [errmessage, seterrmessage] = useState(false);

    const [showModalQuickView, setShowModalQuickView] = useState(false)

    const [images, setImages] = useState([]);

    const [vedioUrl, setvedioUrl] = useState("");

    const [cleanTypes, setcleanTypes] = useState([]);
    const [cleanId, setcleanId] = useState("");
    const [errclean, seterrclean] = useState(false);

    const [newSubArray, setnewSubArray] = useState([]);
    const [newSubs, setnewSubs] = useState([]);

    const [newSubs2, setnewSubs2] = useState([]);

    const [shippitKey, setshippitKey] = useState("");

    const [selectedCtypes, setselectedCtypes] = useState([]);

    const ref = useRef(null);

    const ref2 = useRef(null);

    const [bOpen, setbOpen] = useState(false);
    const [bMsg, setbMsg] = useState("");
    const [bStatus, setbStatus] = useState("");

    const [websiteRequest, setwebsiteRequest] = useState(false);

    const [editorData, setEditorData] = useState("<br/><br/><br/><br/><br/>");

    const [editorData2, setEditorData2] = useState("<br/><br/><br/><br/><br/>");


    const [refundDoc, setrefundDoc] = useState("");

    const [deliveryDoc, setdeliveryDoc] = useState("");

    const [verifyABN, setverifyABN] = useState(false);

    const [options, setOptions] = React.useState(['']);
    const [value, setValue] = React.useState(options[0]);
    const [inputValue, setInputValue] = React.useState('');

    const [policy1, setpolicy1] = useState(false);
    const [policy2, setpolicy2] = useState(false);
    const [policy3, setpolicy3] = useState(false);
    const [policy4, setpolicy4] = useState(false);

    const [policy5, setpolicy5] = useState(false);

    const [policy6, setpolicy6] = useState(false);

    const [policeDoc, setpoliceDoc] = useState("");

    const [childDoc, setchildDoc] = useState("");

    const [errPolice, seterrPolice] = useState(false);

    const handleScroll = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: "center", });
    };

    const handleScroll2 = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const [policeDate, setpoliceDate] = useState("");
    const [childDate, setchildDate] = useState("");

    const [allProCate, setallProCate] = useState([]);
    const [allProSubCate, setallProSubCate] = useState([]);

    const [selectedCat, setselectedCat] = useState("");
    const [selectedSubCat, setselectedSubCat] = useState("");


    const listCategories = () => {
        getProviderCategory()
            .then((res) => {
                console.log("cities>>>", res);
                setallProCate(res.data);
            })
            .catch((error) => {
                console.log("Error", error.response);
                setallProCate([])
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });
    };


    const listSubCategories = () => {
        getProviderSubCategoryById(selectedCat)
            .then((res) => {
                console.log("cities>>>", res);
                setallProSubCate(res.data);
            })
            .catch((error) => {
                console.log("Error", error.response);
                setallProSubCate([])
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });
    };


    React.useEffect(() => {
        listCategories();

    }, []);

    React.useEffect(() => {
        if (selectedCat) {
            listSubCategories();
        }
    }, [selectedCat]);


    React.useEffect(() => {

        let newSubArray = JSON.parse(JSON.stringify(SubData));

        newSubArray = newSubArray?.map((d) => {
            if (d.type === "Delivery Area") {

                return {
                    ...d,
                    locality: upperFirst(lowerCase(d.locality)),
                };

            }

        });

        newSubArray = _.compact(newSubArray)

        setnewSubArray(_.orderBy(newSubArray, ['locality'], ['asc']));

        console.log(">>>>>>>subs", newSubArray.filter((u) => u.state === 0))


    }, []);



    useEffect(() => {
        console.log("log>>>>>>", logUser)
        setcurrUser(logUser);
    }, [logUser])


    const onSubmit = async (e) => {

        const phoneRegex =
            /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

        e.preventDefault();

        if (firstName === "") {
            seterrfirstName(true);
        }
        if (lastName === "") {
            seterrlastName(true);
        }

        if (currUser?.userType != "service seeker") {
            if (homeAddress === "") {
                seterrhomeAddress(true);
            }

            if (country === "Sri Lanka") {
                if (homeCity === "") {
                    seterrhomeCity(true);
                }
                if (homeDistrict === "") {
                    seterrhomeDistrict(true);
                }
                if (homepostCode === "") {
                    seterrhomepostCode(true);
                }
            }

        }





        // if (currUser?.userType === "service seeker") {

        //     if (!category) {
        //         seterrcategory(true);
        //     }


        // }

        // if (currUser?.userType === "wholesaler" || currUser?.userType === "retail supplier") {
        //     if (!shippitKey) {
        //         seterrshippit(true);
        //     }
        // }

        if (currUser?.userType === "service provider" || currUser?.userType === "wholesaler" || currUser?.userType === "retail supplier") {

            if (!businessName) {
                seterrbusinessName(true);
            }
            // if (businessReg === "") {
            //     seterrbusinessReg(true);
            // }
            if (year === "") {
                seterryear(true);
            }
            // if (!cleanId) {
            //     seterrclean(true);
            // }

            // if (!verifyABN) {
            //     addToast("Please verify your ABN !", "warning");
            // }

        }


        // if (category === "") {
        //     seterrcategory(true);
        // }
        // if (businessName === "") {
        //     seterrbusinessName(true);
        // }
        // if (businessReg === "") {
        //     seterrbusinessReg(true);
        // }
        // if (year === "") {
        //     seterryear(true);
        // }
        // if (website === "") {
        //     seterrwebsite(true);
        // }
        if (country === "") {
            seterrcountry(true);
        }
        if (email === "") {
            seterremail(true);
        }
        // if (contactName === "") {
        //     seterrcontactName(true);
        // }
        if (currUser?.userType != "service seeker") {

            if (about === "") {
                seterrabout(true);
            }

        }


        // if (homeNo === "") {
        //   seterrhomeNo(true);
        // }
        // if (mobile === "") {
        //   seterrmobile(true);
        // }
        // if (password !== "") {
        //   //   seterrPass(true)
        //   if (repassword === "") {
        //     seterrRePass("This field is required");
        //   } else {
        //     seterrRePass("");
        //   }
        // }

        if (!phoneRegex.test(mobile)) {
            if (mobile === "") {
                seterrmobile("This field is required");
            } else {
                seterrmobile("Please enter valid number");
            }
        }

        if (currUser?.userType === "service provider") {
            if (!phoneRegex.test(mobile)) {
                if (mobile === "") {
                    seterrmobile("This field is required");
                } else {
                    seterrmobile("Please enter valid number");
                }
            }

            if (!phoneRegex.test(mobile2)) {
                if (mobile2 !== "") {
                    seterrmobile2("Please enter valid number");
                }
            }

        }

        if (currUser?.userType === "service seeker") {
            if (!phoneRegex.test(mobile)) {
                if (mobile === "") {
                    seterrmobile("This field is required");
                } else {
                    seterrmobile("Please enter valid number");
                }
            }



        }


        if (currUser?.userType === "service provider") {

            // if (!policy5 || !policeDate) {
            //     seterrPolice(true);
            // }

            if (selectedCat === "") {
                seterrcategory(true);
            }

        }



        // if(password != "" && repassword != "" && !(password === repassword)){
        //   seterrRePass("Password Mismatch")
        // }
        // if(password != "" && repassword != "" && (password === repassword)){
        //   seterrRePass("")
        // }

        // if (!(currUser.avatar && currUser.cover)) {
        //     handleScroll2();
        //     return;
        // }

        let obj = {
            firstName: firstName,
            lastName: lastName,
            supplierCategory: category,
            businessName: businessName,
            businessRegistrationNumber: businessReg,
            establishedYear: moment(year).format("YYYY"),
            website: website,
            country: country,
            contactNumber: mobile,
            contactNumber2: mobile2,
            email: email,
            contactName: contactName,
            about: about,
            homeNumber: currUser.homeNumber === null ? "" : currUser.homeNumber,
            homeAddress: homeAddress,
            homeCity: homeCity,
            password: "",
            homeDistrict: homeDistrict,
            homepostCode: homepostCode,
            note: currUser.note === null ? "" : currUser.note,
            cover: !currUser.cover ? "/uploads/cover/cover.jpg" : currUser.cover,
            avatar: !currUser.avatar ? "/uploads/avatar/avatar.jpg" : currUser.avatar,
            logo: currUser.logo === null ? "" : currUser.logo,
            paymentGateway: currUser.paymentGateway === null ? "" : currUser.paymentGateway,
            userType: currUser.userType === null ? "" : currUser.userType,
            clientId: currUser.clientId === null ? "" : currUser.clientId,
            secretId: currUser.secretId === null ? "" : currUser.secretId,
            deliveryFee: currUser.deliveryFee === null ? "" : currUser.deliveryFee,
            salesCount: currUser.salesCount === null ? "" : currUser.salesCount,
            // serviceProviderImages: images,
            // serviceProvierVideos: vedioUrl,
            serviceProviderImages: !currUser.serviceProviderImages ? "" : JSON.parse(currUser.serviceProviderImages),
            serviceProvierVideos: !currUser.serviceProvierVideos ? "" : currUser.serviceProvierVideos,
            cleaningTypeId: selectedCtypes,
            shippitKey: shippitKey,
            websiteRequest: websiteRequest === true ? 1 : 0,
            refundPolicyDescription: editorData,
            refundPolicyDocument: refundDoc,
            deliveryPolicyDescription: editorData2,
            deliveryPolicyDocument: deliveryDoc,
            publicLiabilityInsurance: policy1 === true ? 1 : 0,
            IndemnityInsurance: policy2 === true ? 1 : 0,
            workCoverInsurance: policy3 === true ? 1 : 0,
            workersCompensationInsurance: policy4 === true ? 1 : 0,
            policeClearanceCertificate: policy5 === true ? 1 : 0,
            workingWithChildren: policy6 === true ? 1 : 0,
            policeClearanceCertificateDoc: policeDoc,
            workingWithChildrenDoc: childDoc,
            issuedDate: moment(policeDate).format("YYYY-MM-DD"),
            expiryDate: moment(childDate).format("YYYY-MM-DD"),
            mainId: selectedCat,
            subId: selectedSubCat,
            title: currUser?.title ? currUser?.title : "",
            accType: currUser?.accType ? currUser?.accType : "",


        };
        console.log("obj>>>>", obj);


        if (
            firstName !== "" &&
            lastName !== "" &&
            // category !== "" &&
            // businessName !== "" &&
            // businessReg !== "" &&
            // year !== "" &&
            // website !== "" &&
            country !== "" &&
            email !== "" &&
            // about !== "" &&

            mobile !== "" &&
            phoneRegex.test(mobile) &&
            // mobile2 !== "" &&
            ((currUser?.userType != "service seeker") ? homeAddress !== "" : true) &&
            ((currUser?.userType != "service seeker") ? (country === "Sri Lanka" ? homeCity : true) : true) &&
            ((currUser?.userType != "service seeker") ? (country === "Sri Lanka" ? homeDistrict : true) : true) &&
            ((currUser?.userType != "service seeker") ? (country === "Sri Lanka" ? homepostCode : true) : true) &&
            // homeAddress !== "" &&
            // homeCity !== "" &&
            // homeDistrict !== "" &&
            // homepostCode !== "" &&
            // (password !== "" ? repassword !== "" : businessName !== "") &&
            // phoneRegex.test(mobile) &&
            // (mobile2 !== "" ? phoneRegex.test(mobile2) : true) &&

            ((currUser?.userType === "service provider" || currUser?.userType === "wholesaler" || currUser?.userType === "retail supplier") ? businessName !== "" : true) &&
            // ((currUser?.userType === "service provider" || currUser?.userType === "wholesaler" || currUser?.userType === "retail supplier") ? verifyABN !== false : true) &&
            // ((currUser?.userType === "service provider" || currUser?.userType === "wholesaler" || currUser?.userType === "retail supplier") ? businessReg !== "" : true) &&
            // ((currUser?.userType === "service provider") ? phoneRegex.test(mobile) : true) &&
            // ((currUser?.userType === "service provider") ? mobile !== "" : true) &&
            // ((currUser?.userType === "service provider") ? (mobile2 !== "" ? phoneRegex.test(mobile2) : true) : true) &&

            // ((currUser?.userType === "service provider") ? cleanId !== "" : true) &&

            // ((currUser?.userType === "service seeker") ? phoneRegex.test(mobile) : true) &&
            // ((currUser?.userType === "service provider") ? (policy5 && policeDate) : true) &&
            ((currUser?.userType === "service provider" || currUser?.userType === "wholesaler" || currUser?.userType === "retail supplier") ? year !== "" : true) &&
            ((currUser?.userType === "service seeker") ? phoneRegex.test(mobile) : true) &&
            ((currUser?.userType === "service seeker") ? mobile !== "" : true) &&

            ((currUser?.userType === "service provider") ? about !== "" : true) &&
            ((currUser?.userType === "service provider") ? selectedCat !== "" : true)
            // ((currUser?.userType === "wholesaler" || currUser?.userType === "retail supplier") ? shippitKey !== "" : true)



        ) {
            setlopen(true);

            // if (email !== premail)
            if (false) {

                let obj2 = {
                    data: {
                        businessName: upperFirst(lowerCase(businessName)),
                        businessReg: upperFirst(lowerCase(businessReg)),
                        mobileNumber: mobile,
                        email: email,
                        homeNumber: currUser.homeNumber === null ? "" : currUser.homeNumber,

                        homeAddress: currUser.homeAddress === null ? "" : currUser.homeNumber,

                        homeCity: currUser.homeCity === null ? "" : currUser.homeCity,



                        password: "",
                        // oldPassword: repassword,

                        homeDistrict: currUser.homeDistrict === null ? "" : currUser.homeDistrict,

                        homepostCode: currUser.homepostCode === null ? "" : currUser.homepostCode,
                        note: currUser.note === null ? "" : currUser.note,

                        type: "profile",
                    },
                };

                signUpVerification(obj2)
                    .then((res) => {
                        setlopen(false);
                        addToast(`The email verification link has been sent to ${email}. 
              Please click on the confirmation link to confirm your email. `, "success");
                        // setuserId(res.data[0]);
                        // setOpenToast(true);
                        // setColor(COLORS.Primary);
                        // setAlertClass("success");
                        // setMsg(
                        //   `The email verification link has been sent to ${email}. 
                        //   Please click on the confirmation link to confirm your email. `
                        // );
                        // setTimeout(() => {
                        //   //  setOpen(true);
                        //   history.push("/login");
                        //   window.location.reload();
                        // }, 2000);

                        // history.push("/");
                        // window.location.reload();
                    })
                    .catch((error) => {
                        setlopen(false);
                        console.log("Error", error.response);
                        addToast(error.response.data.message, "warning");
                        // setOpenToast(true);
                        // setColor(COLORS.Orange);
                        // setAlertClass("error");
                        // setMsg(error.response.data.message);
                    });


            } else {
                setlopen(true);
                EditUserQuery(obj)
                    .then((res) => {
                        setlopen(false);

                        setbOpen(true);
                        setbMsg("Successfully updated");
                        setbStatus("success");





                        // addToast("Successfully updated", "success");
                        // setOpenToast(true);
                        // setColor(COLORS.Primary);
                        // setAlertClass("success");
                        // setMsg("Successfully updated");
                        getCurrentUser();
                        seteDisable(true);
                        getUser();

                        // if (currUser?.userType === "wholesaler" || currUser?.userType === "retail supplier") {
                        //     setselectedTab(6);
                        //     handleScroll2();
                        // } else if (currUser?.userType != "service seeker") {
                        //     setselectedTab(19);
                        //     handleScroll2();
                        // }

                        // if (currUser?.userType != "service seeker") {
                        //     setselectedTab(19);
                        //     handleScroll2();
                        // }

                        if (currUser?.userType === "service provider") {
                            setselectedTab(20);
                            handleScroll2();
                        }




                        // history.push("/");
                        // window.location.reload();
                    })
                    .catch((error) => {
                        setlopen(false);
                        console.log("Error", error.response);

                        setbOpen(true);
                        setbMsg(error.response.data.message);
                        setbStatus("warning");


                        // addToast(error.response.data.message, "warning");
                        // setOpenToast(true);
                        // setColor(COLORS.Orange);
                        // setAlertClass("error");
                        // setMsg(error.response.data.message);
                    })
            }

        } else {
            // handleScroll();
            setbOpen(true);
            setbMsg("Required fields are missing and add missing fields.");
            setbStatus("warning");
        }
    };

    useEffect(() => {
        if (bOpen === true) {
            setTimeout(() => {

                setbOpen(false)

            }, 10000);
        }
    }, [bOpen])

    React.useEffect(() => {

        getAllFaqs();
        listDistricts();

    }, [showModalQuickView, country])


    const getCurrentUser = () => {
        CurrentUser()
            .then((res) => {
                if (res.statusCode === 200) {
                    const cData = res.data[0];
                    setUser(cData);
                    const obj = {
                        id: cData.id,
                        email: cData?.email,
                        businessName: cData?.businessName,
                        contactName: cData?.contactName,
                        phone: cData?.mobileNumber,
                    };
                    //   this.setState({
                    //     businessName: cData?.businessName,
                    //     contactName: cData?.contactName,
                    //     email: cData?.email,
                    //     tele: cData?.phone,
                    //   });
                    console.log("<><><><>", cData);
                    setcurrUser(cData);

                    if (cData?.businessRegistrationNumber) {
                        setverifyABN(true);
                    }


                    // setName(cData?.businessName + " " + cData?.contactName);
                    setfirstName(cData?.firstName);
                    setlastName(cData?.lastName);
                    setcategory(cData?.supplierCategory !== null ? cData?.supplierCategory : "");
                    setbusinessName(cData?.businessName !== null ? cData?.businessName : "");
                    setbusinessReg(cData?.businessRegistrationNumber ? cData?.businessRegistrationNumber : "");
                    setyear(cData?.establishedYear ? moment(cData?.establishedYear.toString() + "-01-01").toDate() : "");
                    setwebsite(cData?.website !== null ? cData?.website : "");
                    setcountry(cData?.country ? cData?.country : "Australia");
                    setmobile(cData?.contactNumber !== null ? cData?.contactNumber : "");
                    setmobile2(cData?.contactNumber2 !== null ? cData?.contactNumber2 : "");
                    setcontactName(cData?.contactName !== null ? cData?.contactName : "");
                    setemail(cData?.email);
                    setpremail(cData?.email);
                    setabout(cData?.about !== null ? cData?.about : "");
                    sethomeDistrict(cData?.homeDistrict !== null ? cData?.homeDistrict : "");
                    sethomeAddress(cData?.homeAddress !== null ? cData?.homeAddress : "");
                    sethomeCity(cData?.homeCity !== null ? cData?.homeCity : "");
                    // sethomeNo(cData?.homeNumber !== null ? cData?.homeNumber : "");
                    sethomepostCode(cData?.homepostCode !== null ? cData?.homepostCode : "");
                    setuserId(cData?.id);
                    setImages(JSON.parse(cData?.serviceProviderImages));
                    setvedioUrl(cData?.serviceProvierVideos);
                    // setcleanId(cData?.cleaningTypeId !== null ? cData?.cleaningTypeId : "");
                    setshippitKey(cData?.shippitKey !== null ? cData?.shippitKey : "")
                    // setselectedCtypes(cData?.cleaningTypeId ? JSON.parse(cData?.cleaningTypeId) : []);
                    setwebsiteRequest(cData?.websiteRequest === 1 ? true : false)

                    setEditorData(cData?.refundPolicyDescription ? cData?.refundPolicyDescription : "<br/><br/><br/><br/><br/>")
                    setEditorData2(cData?.deliveryPolicyDescription ? cData?.deliveryPolicyDescription : "<br/><br/><br/><br/><br/>")

                    setrefundDoc(cData?.refundPolicyDocument ? JSON.parse(cData?.refundPolicyDocument) : "")

                    setdeliveryDoc(cData?.deliveryPolicyDocument ? JSON.parse(cData?.deliveryPolicyDocument) : "")

                    setpolicy1(cData?.publicLiabilityInsurance === 1 ? true : false)
                    setpolicy2(cData?.IndemnityInsurance === 1 ? true : false)
                    setpolicy3(cData?.workCoverInsurance === 1 ? true : false)
                    setpolicy4(cData?.workersCompensationInsurance === 1 ? true : false)
                    setpolicy5(cData?.policeClearanceCertificate === 1 ? true : false)
                    setpolicy6(cData?.workingWithChildren === 1 ? true : false)

                    setpoliceDoc(cData?.policeClearanceCertificateDoc ? JSON.parse(cData?.policeClearanceCertificateDoc) : "")
                    setchildDoc(cData?.workingWithChildrenDoc ? JSON.parse(cData?.workingWithChildrenDoc) : "")

                    setpoliceDate(cData?.issuedDate ? moment(cData?.issuedDate).toDate() : "")
                    setchildDate(cData?.expiryDate ? moment(cData?.expiryDate).toDate() : "")
                    setselectedCat(cData?.mainId !== 0 ? cData?.mainId : "")
                    setselectedSubCat(cData?.subId !== 0 ? cData?.subId : "")

                    getDistricts()
                        .then((res) => {

                            var mobile22 = res.data?.filter(function (el) {
                                return el.name_en === cData?.homeDistrict;
                            });

                            // sethomeDistrictId(mobile22.length > 0 ? mobile22[0]?.id : 0)

                            setnewSubs(newSubArray.filter((u) => u.state === (mobile22.length > 0 ? mobile22[0]?.id : 0)))


                            // const filtered = newSubArray.filter((u) => u.state === (mobile22.length > 0 ? mobile22[0]?.id : 0))

                            // const perChunk = 700

                            // const result = filtered.reduce((filtered, item, index) => {
                            //     const chunkIndex = Math.floor(index / perChunk)

                            //     if (!filtered[chunkIndex]) {
                            //         filtered[chunkIndex] = [] // start a new chunk
                            //     }

                            //     filtered[chunkIndex].push(item)

                            //     return filtered
                            // }, [])

                            // console.log("<<<<<<<", result)

                            // for (let i = 0; i < result.length - 1; i++) {

                            //     let item = result[i];

                            //     setTimeout(() => {
                            //         setnewSubs(existing => [...existing, ...item]);
                            //     }, i === 0 ? 0 : (2000));
                            // }

                            // setallODistricts(res.data);
                        })
                        .catch((error) => {
                            console.log("Error", error.response);
                            // setOpenToast(true);
                            // setColor(COLORS.Orange);
                            // setAlertClass("error");
                            // setMsg(error.response.data.message);
                        });



                    localStorage.setItem(
                        `${window.location.hostname}.profile`,
                        JSON.stringify(obj)
                    );
                }
            })
            .catch((error) => {
                console.log("error", error);
                console.log("current user error", error.response.data.message);
            });
    };

    React.useEffect(() => {
        getCurrentUser();

    }, [newSubArray]);

    React.useEffect(() => {
        if (homeDistrictId !== 0) {
            listHomecities();
        }
    }, [homeDistrictId, country]);

    // const listDistricts = () => {
    //     getDistricts()
    //         .then((res) => {
    //             console.log("districts>>>", res);
    //             setallHDistricts(res.data);
    //             // setallODistricts(res.data);
    //         })
    //         .catch((error) => {
    //             console.log("Error", error.response);
    //             // setOpenToast(true);
    //             // setColor(COLORS.Orange);
    //             // setAlertClass("error");
    //             // setMsg(error.response.data.message);
    //         });

    //     GetCleaningType()
    //         .then((res) => {
    //             console.log("cleanTypes>>>", res);
    //             setcleanTypes(res.data);
    //             // setallODistricts(res.data);
    //         })
    //         .catch((error) => {
    //             console.log("Error", error.response);
    //             setcleanTypes([])
    //             // setOpenToast(true);
    //             // setColor(COLORS.Orange);
    //             // setAlertClass("error");
    //             // setMsg(error.response.data.message);
    //         });
    // };

    // const listHomecities = () => {
    //     getCities(homeDistrict)
    //         .then((res) => {
    //             console.log("cities>>>", res);
    //             setallHCities(res.data.records);
    //         })
    //         .catch((error) => {
    //             console.log("Error", error.response);
    //             setallHCities([])
    //             // setOpenToast(true);
    //             // setColor(COLORS.Orange);
    //             // setAlertClass("error");
    //             // setMsg(error.response.data.message);
    //         });
    // };


    const listDistricts = () => {
        if (country === "Australia") {

            getDistricts()
                .then((res) => {
                    console.log("districts>>>", res);
                    setallHDistricts(res.data);
                    // sethomeDistrict(res.data[0]?.name_en)
                    // sethomeDistrictId(res.data[0]?.id);
                    // seterrhomeDistrict(false);
                    // setallODistricts(res.data);
                })
                .catch((error) => {
                    console.log("Error", error.response);
                    // setOpenToast(true);
                    // setColor(COLORS.Orange);
                    // setAlertClass("error");
                    // setMsg(error.response.data.message);
                });

        } else if (country === "Sri Lanka") {

            getSriDistricts({ language: t("langKey") })
                .then((res) => {
                    console.log("districts>>>", res);
                    setallHDistricts(res.data);
                    // sethomeDistrict(res.data[0]?.name_en)
                    // sethomeDistrictId(res.data[0]?.id);
                    // seterrhomeDistrict(false);
                    // setallODistricts(res.data);
                })
                .catch((error) => {
                    console.log("Error", error.response);
                    // setOpenToast(true);
                    // setColor(COLORS.Orange);
                    // setAlertClass("error");
                    // setMsg(error.response.data.message);
                });

        }

        GetCleaningType()
            .then((res) => {
                console.log("cleanTypes>>>", res);
                setcleanTypes(res.data);
                // setallODistricts(res.data);
            })
            .catch((error) => {
                console.log("Error", error.response);
                setcleanTypes([])
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });

    };

    const listHomecities = () => {
        if (country === "Australia") {
            getCities(homeDistrict)
                .then((res) => {
                    console.log("cities>>>", res.data);
                    setallHCities(res.data.records);
                    // sethomeCity(res.data.records[0].fields.scc_name)
                    // sethomepostCode(res.data.records[0].fields.scc_code)
                    // seterrhomeCity(false);
                    // seterrhomepostCode(false);
                })
                .catch((error) => {
                    console.log("Error", error.response);
                    setallHCities([])
                    // setOpenToast(true);
                    // setColor(COLORS.Orange);
                    // setAlertClass("error");
                    // setMsg(error.response.data.message);
                });
        }
        else if (country === "Sri Lanka") {

            getSriCities(homeDistrictId, t("langKey"))
                .then((res) => {
                    console.log("cities>>>", res.data);


                    const subArr = res.data?.map((d) => {
                        return {
                            ...d,
                            fields: {
                                scc_name: d.name_en,
                                scc_code: d.postcode
                            }
                        };
                    });




                    setallHCities(subArr);
                    // sethomeCity(res.data.records[0].fields.scc_name)
                    // sethomepostCode(res.data.records[0].fields.scc_code)
                    // seterrhomeCity(false);
                    // seterrhomepostCode(false);
                })
                .catch((error) => {
                    console.log("Error", error.response);
                    setallHCities([])
                    // setOpenToast(true);
                    // setColor(COLORS.Orange);
                    // setAlertClass("error");
                    // setMsg(error.response.data.message);
                });

        }

    };

    const handleChangeHdist = (e, value) => {
        if (value !== null) {
            sethomeDistrict(value.name_en);
            seterrhomeDistrict(false);
            sethomeDistrictId(value.id);
        } else {
            sethomeDistrict("");
            sethomeDistrictId(1);
        }


        setnewSubs([]);

        const filtered = newSubArray.filter((u) => u.state === value.id)

        const perChunk = 700

        const result = filtered.reduce((filtered, item, index) => {
            const chunkIndex = Math.floor(index / perChunk)

            if (!filtered[chunkIndex]) {
                filtered[chunkIndex] = [] // start a new chunk
            }

            filtered[chunkIndex].push(item)

            return filtered
        }, [])

        console.log("<<<<<<<", result)

        for (let i = 0; i < result.length - 1; i++) {

            let item = result[i];

            setTimeout(() => {
                setnewSubs(existing => [...existing, ...item]);
            }, i === 0 ? 0 : (2000));
        }



        // setnewSubs(newSubArray.filter((u) => u.state === value.id))



        console.log("state>>", value);
    };


    useEffect(() => {
        console.log("subs>>>>>", newSubs)
    }, [newSubs])




    const handleChangeHcity = (e, value) => {
        if (value !== null) {
            sethomeCity(value.fields.scc_name);
            sethomepostCode(value.fields.scc_code);
            // sethomeCity(value);

            // sethomepostCode(newSubs.find((v) => v.locality === value).postcode)

            // sethomepostCode(value.postcode);
            seterrhomeCity(false);
            seterrhomepostCode(false);
        } else {
            sethomeCity("");
            sethomepostCode("");

        }

        console.log("city>>", value);
    };

    function CustomInput({ onFocus, value, onChange, placeholder = "YYYY" }) {
        return (
            <div className="mt-1.5 flex">
                <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-xl las la-calendar"></i>
                </span>
                <Input
                    className="!rounded-l-none"
                    placeholder={placeholder}
                    onFocus={onFocus}
                    value={value}
                    onChange={onChange}

                />

            </div>
        )
    }
    return (
        <>
            <ModalQuickView
                getAllFaqs={getAllFaqs}
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
            />
            <LoadingSpinner open={lopen} />
            <div className="w-full flex justify-center px-3">
                <div className="w-full md:w-9/12 lg:w-7/12 xl:w-[35%]">
                    <div className="flex justify-center w-full mt-12 mb-6">

                        <span className="block text-base flex justify-center w-full text-center text-[#002930] font-medium">
                            Add Account Info
                        </span>
                    </div>
                    <div className="space-y-4" >
                        {/* <div>
                            <div className="flex-shrink-0 flex justify-center w-full">
                                <div className="relative w-full overflow-hidden flex">
                                    <img
                                        src={cover}
                                        alt=""
                                        className="h-36 w-full object-cover z-0"
                                    />
                                    <div className="absolute z-[1] inset-0 hover:bg-black hover:bg-opacity-30  flex flex-col items-center justify-center text-transparent hover:text-neutral-50 cursor-pointer">
                                        <svg
                                            width="30"
                                            height="30"
                                            viewBox="0 0 30 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                                                stroke="currentColor"
                                                strokeWidth={1.5}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>

                                        <span className="mt-1 text-[10.8px]">Change Image</span>
                                        <input
                                            type="file"
                                            className="absolute inset-0 opacity-0 cursor-pointer"
                                        />
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="flex-shrink-0 flex justify-center w-full -mt-10">
                                <div className="relative rounded-full overflow-hidden flex">
                                    <img
                                        src={Company1}
                                        alt=""
                                        className="w-24 h-24 rounded-full object-cover z-[2] border-2 border-slate-100"
                                    />
                                    <div className="absolute z-[3] inset-0 hover:bg-black hover:bg-opacity-30  flex flex-col items-center justify-center text-transparent hover:text-neutral-50 cursor-pointer">
                                        <svg
                                            width="30"
                                            height="30"
                                            viewBox="0 0 30 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                                                stroke="currentColor"
                                                strokeWidth={1.5}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>

                                        <span className="mt-1 text-[10.8px]">Change Image</span>
                                        <input
                                            type="file"
                                            className="absolute inset-0 opacity-0 cursor-pointer"
                                        />
                                    </div>

                                </div>
                            </div>
                        </div> */}

                        {
                            (currUser?.userType === "wholesaler" || currUser?.userType === "retail supplier" || currUser?.userType === "service provider") &&

                            <>
                                {/* <Label className="text-sm">Business Registration Number (ABN) </Label>


                                <div className="flex w-full space-x-2 mt-1.5">

                                    <div className="w-full">


                                        <Input className=""
                                            placeholder="Business Registration Number 
                                "
                                            onChange={(e) => {
                                                setbusinessReg(e.target.value);
                                                seterrbusinessReg(false);
                                                setverifyABN(false);
                                            }}
                                            value={businessReg}

                                        />
                                        <span className="text-xs text-slate-600">Don't leave spaces between the numbers</span>

                                        {errbusinessReg && (
                                            <div className="text-red-600 text-sm text-right mt-2">
                                                This field is required
                                            </div>
                                        )}
                                    </div>

                                    <div className="w-fit">
                                       

                                        <button
                                            className={
                                                `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full text-slate-50 font-medium h-11`}

                                            onClick={() => {

                                                if (!businessReg) {
                                                    seterrbusinessReg(true);
                                                    return;
                                                }

                                                setlopen(true);

                                                VerifyABN({ ID: businessReg })
                                                    .then((res) => {
                                                        setlopen(false);

                                                        console.log("abn res>>>", res);

                                                        const business = res.business;

                                                        setverifyABN(true);

                                                        setwebsite(business.overview.contact.website ? business.overview.contact.website : "")
                                                        // setemail(business.overview.contact.email ? business.overview.contact.email : "")
                                                        setmobile(business.overview.contact.phone ? business.overview.contact.phone.replace(/ /g, '') : "")
                                                        setabout(business.overview.description ? business.overview.description : "")
                                                        sethomeDistrictId(business.overview.location.state ? business.overview.location.state : "")
                                                        sethomeDistrict(allHDistricts.filter((u) => u.id === business.overview.location.state)[0].name_en)
                                                        sethomeCity(business.overview.location.suburb ? business.overview.location.suburb : "")
                                                        sethomepostCode(business.overview.location.postcode ? business.overview.location.postcode : "")
                                                        sethomeAddress(business.overview.location.street ? business.overview.location.street : "")
                                                        setcountry(business.overview.location.country ? business.overview.location.country : "")
                                                        setbusinessName(business.overview.legal_name ? business.overview.legal_name : "");
                                                        // setfirstName(business.overview.name ? business.overview.name : "")
                                                        setyear(business.overview.created ? moment(business.overview.created.toString() + "-01-01").toDate() : "");

                                                        // setallODistricts(res.data);
                                                    })
                                                    .catch((error) => {
                                                        setlopen(false);

                                                        console.log("Error", error);
                                                        addToast(error.response.data.message, "warning");
                                                        setverifyABN(false);

                                                        // setOpenToast(true);
                                                        // setColor(COLORS.Orange);
                                                        // setAlertClass("error");
                                                        // setMsg(error.response.data.message);
                                                    });
                                            }}
                                        >

                                            <span className="block  text-sm font-medium whitespace-nowrap px-4"

                                            >
                                                Verify
                                            </span>

                                        </button>
                                       
                                    </div>

                                </div> */}

                            </>


                        }



                        <div >
                            <Label className="text-sm" >First Name</Label>
                            <Input className="mt-1.5"

                                placeholder="First Name"
                                onChange={(e) => {
                                    setfirstName(e.target.value);
                                    seterrfirstName(false);
                                }}
                                value={firstName}

                            />
                            {errfirstName && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div ref={ref}>
                            <Label className="text-sm">Last Name</Label>
                            <Input className="mt-1.5"

                                placeholder="Last Name"
                                onChange={(e) => {
                                    setlastName(e.target.value);
                                    seterrlastName(false);
                                }}
                                value={lastName}

                            />
                            {errlastName && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>


                        {/* <div>
                            <Label className="text-sm">Member Number</Label>
                            <Input className="mt-1.5"
                                readOnly
                                placeholder="Member Number"
                                onChange={(e) => {
                                    setbusinessName(e.target.value);
                                    seterrbusinessName(false);
                                }}
                                value={currUser.id}

                            />
                            {errbusinessName && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}
                        {
                            currUser?.userType === "service provider"
                            // false
                            &&

                            <>

                                <div>
                                    <Label className="text-sm">Category</Label>

                                    <Autocomplete
                                        className="mt-1.5"
                                        // className="form-group"
                                        // className="border-neutral-200"
                                        disablePortal
                                        value={{ name: allProCate.find((v) => v.id === selectedCat) ? allProCate.find((v) => v.id === selectedCat).name : "" }}

                                        // id="combo-box-demo"
                                        options={allProCate}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, value) => {

                                            if (value !== null) {
                                                setselectedCat(value.id);
                                            } else {

                                                setselectedCat("")
                                            }

                                            seterrcategory(false)

                                            // handleChangeHdist(event, value);
                                            // setCountry(value);
                                        }}
                                        // sx={{
                                        //   // width: "80vw",
                                        //   background:"transparent",
                                        //   borderRadius: "20px",
                                        //   // border: "none",
                                        //   // borderColor: "transparent",

                                        //   "& .MuiInputBase-input": {
                                        //     height: 15,
                                        //     // border: "none",
                                        //     fontSize: "14px",
                                        //     fontFamily: "'Jost', sans-serif",
                                        //     // borderColor: "transparent",
                                        //   },
                                        //   //view
                                        //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                        //     // borderColor: "transparent",
                                        //   },

                                        //   "&:hover .MuiOutlinedInput-input": {
                                        //     color: "black",
                                        //   },
                                        //   "&:hover .MuiInputLabel-root": {
                                        //     color: COLORS.GrayBackground,
                                        //   },
                                        //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                        //   {
                                        //     // borderColor: "transparent",
                                        //   },
                                        //   ".MuiAutocomplete-root fieldset:hover": {
                                        //     color: "transparent",
                                        //     // borderColor: "transparent",
                                        //   },
                                        // }}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                                <Input {...params.inputProps} className="" placeholder="Select a Category" />
                                            </div>
                                        )}
                                        ListboxProps={{
                                            style: {
                                                maxHeight: "150px",
                                                fontSize: "14px",
                                                // border: '1px solid white'
                                            },
                                        }}
                                    />


                                    {/* <Select className="mt-1.5"
                                    onChange={(e) => {
                                        setcategory(e.target.value);
                                        seterrcategory(false);
                                    }}
                                    value={category}
                                >
                                    <option value={""}>Select a Supplier Category</option>
                                    <option value="Agri Technology">Agri Technology</option>
                                    <option value="Chemicals Supplies">Chemicals Supplies</option>
                                    <option value="Consultancy">Consultancy</option>
                                    <option value="Equipment Rentals">Equipment Rentals</option>
                                    <option value="Equipment Repairs">Equipment Repairs</option>
                                    <option value="Exporters">Exporters</option>
                                    <option value="Farm Supplies">Farm Supplies</option>
                                    <option value="Financial Services">Financial Services</option>
                                    <option value="Insurance">Insurance</option>
                                    <option value="Labour Supplies">Labour Supplies</option>
                                    <option value="Logistic">Logistic</option>
                                    <option value="Machinery">Machinery</option>
                                    <option value="Other">Other</option>
                                    <option value="Pest Controlling">Pest Controlling</option>
                                    <option value="Cold room Suppliers">Storage/Cold room Suppliers</option>

                                    
                                </Select> */}
                                    {errcategory && (
                                        <div className="text-red-600 text-sm text-right mt-2">
                                            This field is required
                                        </div>
                                    )}
                                </div>



                                <div>
                                    <Label className="text-sm">Sub Category</Label>

                                    <Autocomplete
                                        className="mt-1.5"
                                        // className="form-group"
                                        // className="border-neutral-200"
                                        disablePortal
                                        value={{ name: allProSubCate.find((v) => v.id === selectedSubCat) ? allProSubCate.find((v) => v.id === selectedSubCat).name : "" }}

                                        // id="combo-box-demo"
                                        options={allProSubCate}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, value) => {

                                            if (value !== null) {
                                                setselectedSubCat(value.id);
                                            } else {

                                                setselectedSubCat("")
                                            }

                                            // handleChangeHdist(event, value);
                                            // setCountry(value);
                                        }}
                                        // sx={{
                                        //   // width: "80vw",
                                        //   background:"transparent",
                                        //   borderRadius: "20px",
                                        //   // border: "none",
                                        //   // borderColor: "transparent",

                                        //   "& .MuiInputBase-input": {
                                        //     height: 15,
                                        //     // border: "none",
                                        //     fontSize: "14px",
                                        //     fontFamily: "'Jost', sans-serif",
                                        //     // borderColor: "transparent",
                                        //   },
                                        //   //view
                                        //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                        //     // borderColor: "transparent",
                                        //   },

                                        //   "&:hover .MuiOutlinedInput-input": {
                                        //     color: "black",
                                        //   },
                                        //   "&:hover .MuiInputLabel-root": {
                                        //     color: COLORS.GrayBackground,
                                        //   },
                                        //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                        //   {
                                        //     // borderColor: "transparent",
                                        //   },
                                        //   ".MuiAutocomplete-root fieldset:hover": {
                                        //     color: "transparent",
                                        //     // borderColor: "transparent",
                                        //   },
                                        // }}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                                <Input {...params.inputProps} className="" placeholder="Select a Sub Category" />
                                            </div>
                                        )}
                                        ListboxProps={{
                                            style: {
                                                maxHeight: "150px",
                                                fontSize: "14px",
                                                // border: '1px solid white'
                                            },
                                        }}
                                    />


                                    {/* <Select className="mt-1.5"
                                    onChange={(e) => {
                                        setcategory(e.target.value);
                                        seterrcategory(false);
                                    }}
                                    value={category}
                                >
                                    <option value={""}>Select a Supplier Category</option>
                                    <option value="Agri Technology">Agri Technology</option>
                                    <option value="Chemicals Supplies">Chemicals Supplies</option>
                                    <option value="Consultancy">Consultancy</option>
                                    <option value="Equipment Rentals">Equipment Rentals</option>
                                    <option value="Equipment Repairs">Equipment Repairs</option>
                                    <option value="Exporters">Exporters</option>
                                    <option value="Farm Supplies">Farm Supplies</option>
                                    <option value="Financial Services">Financial Services</option>
                                    <option value="Insurance">Insurance</option>
                                    <option value="Labour Supplies">Labour Supplies</option>
                                    <option value="Logistic">Logistic</option>
                                    <option value="Machinery">Machinery</option>
                                    <option value="Other">Other</option>
                                    <option value="Pest Controlling">Pest Controlling</option>
                                    <option value="Cold room Suppliers">Storage/Cold room Suppliers</option>

                                    
                                </Select> */}
                                    {/* {errcategory && (
                                        <div className="text-red-600 text-sm text-right mt-2">
                                            This field is required
                                        </div>
                                    )} */}
                                </div>

                            </>



                        }



                        {
                            (currUser?.userType === "wholesaler" || currUser?.userType === "retail supplier" || currUser?.userType === "service provider") &&

                            <>
                                {
                                    currUser?.userType === "service provider" &&

                                    <div>
                                        {/* <Label className="text-sm">Cleaning Type</Label>

                                        <Autocomplete
                                            className="mt-1.5"
                                            // className="form-group"
                                            // className="border-neutral-200"
                                            disablePortal
                                            // defaultValue={{ name: cleanTypes.find((v) => v.id === cleanId) ? cleanTypes.find((v) => v.id === cleanId).name : "" }}
                                            // value={{ name: cleanTypes.find((v) => v.id === cleanId) ? cleanTypes.find((v) => v.id === cleanId).name : "" }}

                                            value={{ name: "" }}
                                            // options={[{ name: "" }, ...cleanTypes]}
                                            // id="combo-box-demo"
                                            options={cleanTypes}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(e, newValue) => {
                                                // setcleanId(value.id);
                                                // seterrclean(false);

                                                if (newValue.length !== 0) {
                                                    // setselectedSizes(newValue)
                                                    if (selectedCtypes?.length > 0) {

                                                        const index = _.map(selectedCtypes, "id").indexOf(newValue?.id);

                                                        if (index < 0) {

                                                            selectedCtypes(selectedCtypes.concat(newValue));

                                                        }

                                                    } else {
                                                        setselectedCtypes(selectedCtypes.concat(newValue));
                                                    }
                                                    // seterrcat(false);
                                                } else {
                                                    // setselectedSizes([]);
                                                    // seterrcat(false);
                                                }

                                                // setCountry(value);
                                            }}
                                            // sx={{
                                            //   // width: "80vw",
                                            //   background:"transparent",
                                            //   borderRadius: "20px",
                                            //   // border: "none",
                                            //   // borderColor: "transparent",

                                            //   "& .MuiInputBase-input": {
                                            //     height: 15,
                                            //     // border: "none",
                                            //     fontSize: "14px",
                                            //     fontFamily: "'Jost', sans-serif",
                                            //     // borderColor: "transparent",
                                            //   },
                                            //   //view
                                            //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                            //     // borderColor: "transparent",
                                            //   },

                                            //   "&:hover .MuiOutlinedInput-input": {
                                            //     color: "black",
                                            //   },
                                            //   "&:hover .MuiInputLabel-root": {
                                            //     color: COLORS.GrayBackground,
                                            //   },
                                            //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                            //   {
                                            //     // borderColor: "transparent",
                                            //   },
                                            //   ".MuiAutocomplete-root fieldset:hover": {
                                            //     color: "transparent",
                                            //     // borderColor: "transparent",
                                            //   },
                                            // }}
                                            renderInput={(params) => (
                                                <div ref={params.InputProps.ref}>
                                                    <Input {...params.inputProps} className="" placeholder="Select a Cleaning Type" />
                                                </div>
                                            )}
                                            ListboxProps={{
                                                style: {
                                                    maxHeight: "150px",
                                                    fontSize: "14px",
                                                    // border: '1px solid white'
                                                },
                                            }}
                                        />

                                        <div className="flex flex-wrap mt-0 mb-6">

                                            {
                                                selectedCtypes.map((item, index) => {

                                                    let data = cleanTypes.find((v) => v.id === parseInt(item))
                                                    return (
                                                        <>

                                                            <div className="bg-[#e2e8f0] w-fit p-2 flex mr-1 mt-2">

                                                                <span className="text-sm">{data?.name}</span>

                                                                <div className="flex items-center ml-3"
                                                                    onClick={(e) => {
                                                                        var array = [...selectedCtypes]
                                                                        array.splice(index, 1);
                                                                        setselectedCtypes([...array])
                                                                    }}
                                                                >
                                                                    <i className="las la-times text-base text-center  flex items-center justify-center"></i>

                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }


                                        </div> */}

                                        {/* <Select className="mt-1.5"
                                    onChange={(e) => {
                                        setcleanId(e.target.value);
                                        seterrclean(false);
                                    }}
                                    value={cleanId}

                                >
                                    <option value={""}>Select a Cleaning Type</option>
                                    {

                                        cleanTypes.map((item, index) => {
                                            return (
                                                <option value={item.id}>{item?.name}</option>

                                            )
                                        })
                                    }
                                   
                                </Select> */}
                                        {/* {errclean && (
                                            <div className="text-red-600 text-sm text-right mt-2">
                                                This field is required
                                            </div>
                                        )} */}
                                    </div>

                                }


                                <div>
                                    <Label className="text-sm">Business Name</Label>
                                    <Input className="mt-1.5"
                                        placeholder="Business Name"
                                        onChange={(e) => {
                                            setbusinessName(e.target.value);
                                            seterrbusinessName(false);
                                        }}
                                        value={businessName}

                                    />
                                    {errbusinessName && (
                                        <div className="text-red-600 text-sm text-right mt-2">
                                            This field is required
                                        </div>
                                    )}
                                </div>


                                <div className="w-full">
                                    <Label className="text-sm">Established Year</Label>
                                    <div className="flex w-full">

                                        <DatePicker
                                            maxDate={moment().toDate()}
                                            onlyYearPicker
                                            format="YYYY"
                                            containerStyle={{
                                                width: "100%"
                                            }}
                                            render={<CustomInput />}
                                            // render={(value, onChange, onFocus) => {
                                            //     return (
                                            //         <input
                                            //             onFocus={onFocus}
                                            //             value={value}
                                            //             onChange={onChange}
                                            //         />
                                            //     )
                                            // }}
                                            // render={<div className="mt-1.5 flex">
                                            //     <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                            //         <i className="text-xl las la-calendar"></i>
                                            //     </span>
                                            //     <Input
                                            //         className="!rounded-l-none"
                                            //         placeholder="YYYY"
                                            //         onChange={(e) => {
                                            //             console.log(">>>>>>", e)
                                            //         }}

                                            //     />

                                            // </div>}
                                            onChange={(e, val) => {
                                                console.log(">>>>>>", e.toDate())
                                                setyear(e.toDate());
                                                // seterryear(false);
                                            }}
                                            value={year}
                                        // value={year}
                                        // onChange={setyear}

                                        />

                                    </div>


                                    {erryear && (
                                        <div className="text-red-600 text-sm text-right mt-2">
                                            This field is required
                                        </div>
                                    )}
                                </div>

                            </>


                        }

                        {
                            currUser?.userType != "service seeker" &&

                            <>
                                {/* <div>
                                    <Label className="text-sm">Website (Optional)</Label>
                                    <Input className="mt-1.5"
                                        placeholder="Website"
                                        onChange={(e) => {
                                            setwebsite(e.target.value);
                                            seterrwebsite(false);
                                        }}
                                        value={website}

                                    />
                                    {errwebsite && (
                                        <div className="text-red-600 text-sm text-right mt-2">
                                            This field is required
                                        </div>
                                    )}
                                </div>
                                <div className="form-check flex cursor-pointer">
                                    <input id="checkbox-switch-1" className="w-5 h-5 text-[#002930] bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox"
                                        checked={websiteRequest}

                                        onChange={(e) => {
                                            console.log("<<<<<", e.target.checked)
                                            setwebsiteRequest(e.target.checked)

                                            // onChangeUserArr(e, arr.privilege)
                                            // this.onChangeAllArr(e, arr.privilege);
                                        }}
                                    />
                                    <label
                                        className="form-check-label ml-2 text-sm flex items-center"
                                        htmlFor="checkbox-switch-1"
                                    >
                                        No website ? Don’t worry ! We can develop a modern and intuitive website for your business. Simply click here to  request a FREE quote
                                    </label>



                                </div> */}

                            </>
                        }


                        {/* <div>
                            <Label className="text-sm">Address</Label>
                            <Input className="mt-1.5"
                                placeholder="Address"
                                onChange={(e) => {
                                    setcontactName(e.target.value);
                                    seterrcontactName(false);
                                }}
                                value={contactName}

                            />
                            {errcontactName && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}

                        <div>
                            <Label className="text-sm">{currUser?.userType === "service seeker" ? "Delivery Address" : "Addess"}</Label>
                            <div className="mt-1.5 flex">
                                <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                    <i className="text-xl las la-map-signs"></i>
                                </span>
                                <Input
                                    className="!rounded-l-none"
                                    placeholder="Address"
                                    onChange={(e) => {
                                        sethomeAddress(e.target.value);
                                        seterrhomeAddress(false);
                                    }}
                                    value={homeAddress}
                                />
                            </div>
                            {errhomeAddress && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>


                        <div>
                            <Label className="text-sm">Country</Label>
                            <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                // defaultValue={country}
                                value={country}

                                // id="combo-box-demo"
                                options={COUNTArr}
                                getOptionLabel={(option) => option}
                                onChange={(e, value) => {
                                    setcountry(value);
                                    seterrcountry(false);
                                    sethomeDistrict("");
                                    sethomeCity("");
                                    sethomepostCode("");

                                    // setCountry(value);
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Select a country" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            />

                            {/* <Select className="mt-1.5"
                                onChange={(e) => {
                                    setcountry(e.target.value);
                                    seterrcountry(false);
                                }}
                                value={country}
                            >
                                <option value={""}>Select a country</option>
                                <option value="Australia">Australia</option>

                            </Select> */}
                            {errcountry && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>

                        {
                            country === "Sri Lanka" &&

                            <>
                                <div>
                                    <Label className="text-sm">District</Label>


                                    <Autocomplete
                                        className="mt-1.5"
                                        // className="form-group"
                                        // className="border-neutral-200"
                                        disablePortal
                                        defaultValue={{ name_en: homeDistrict }}
                                        value={{ name_en: homeDistrict }}

                                        // id="combo-box-demo"
                                        options={allHDistricts}
                                        getOptionLabel={(option) => option.name_en}
                                        onChange={(event, value) => {

                                            handleChangeHdist(event, value);
                                            // setCountry(value);
                                        }}
                                        // sx={{
                                        //   // width: "80vw",
                                        //   background:"transparent",
                                        //   borderRadius: "20px",
                                        //   // border: "none",
                                        //   // borderColor: "transparent",

                                        //   "& .MuiInputBase-input": {
                                        //     height: 15,
                                        //     // border: "none",
                                        //     fontSize: "14px",
                                        //     fontFamily: "'Jost', sans-serif",
                                        //     // borderColor: "transparent",
                                        //   },
                                        //   //view
                                        //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                        //     // borderColor: "transparent",
                                        //   },

                                        //   "&:hover .MuiOutlinedInput-input": {
                                        //     color: "black",
                                        //   },
                                        //   "&:hover .MuiInputLabel-root": {
                                        //     color: COLORS.GrayBackground,
                                        //   },
                                        //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                        //   {
                                        //     // borderColor: "transparent",
                                        //   },
                                        //   ".MuiAutocomplete-root fieldset:hover": {
                                        //     color: "transparent",
                                        //     // borderColor: "transparent",
                                        //   },
                                        // }}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                                <Input {...params.inputProps} className="" placeholder="Select District" />
                                            </div>
                                        )}
                                        ListboxProps={{
                                            style: {
                                                maxHeight: "150px",
                                                fontSize: "14px",
                                                // border: '1px solid white'
                                            },
                                        }}
                                    />

                                    {errhomeDistrict && (
                                        <div className="text-red-600 text-sm text-right mt-2">
                                            This field is required
                                        </div>
                                    )}

                                </div>

                                <div>
                                    <Label className="text-sm">City</Label>


                                    <Autocomplete
                                        className="mt-1.5"
                                        // className="form-group"
                                        // className="border-neutral-200"
                                        // disablePortal
                                        // defaultValue={{ fields: { scc_name: homeCity } }}
                                        value={{ fields: { scc_name: homeCity } }}
                                        // defaultValue={{ locality: homeCity }}
                                        // value={{ locality: homeCity }}
                                        // value={homeCity}

                                        // id="combo-box-demo"
                                        options={allHCities}
                                        // options={newSubs2}
                                        // options={newSubs2}

                                        getOptionLabel={(option) => option?.fields?.scc_name}
                                        // getOptionLabel={(option) => option}
                                        // onInputChange={(event, value) => {
                                        //     console.log(value)

                                        //     if (value) {

                                        //         let char = lowerCase(value)

                                        //         let arr = [...newSubs]

                                        //         // arr = arr.filter((data) => lowerCase(data.locality).includes(char));

                                        //         const det = arr.map((item, index) => {

                                        //             if (lowerCase(item.locality).startsWith(char)) {

                                        //                 return { ...item }
                                        //             }
                                        //         })


                                        //         console.log(">>>>>", _.compact(det))

                                        //         arr = _.compact(det)

                                        //         arr = _.map(arr, "locality")

                                        //         console.log("arr>>", arr)

                                        //         arr = _.uniq(arr);

                                        //         setnewSubs2(arr);

                                        //     } else {
                                        //         setnewSubs2([])
                                        //     }
                                        // }}
                                        onChange={(event, value) => {

                                            handleChangeHcity(event, value);
                                            // setCountry(value);
                                        }}
                                        // sx={{
                                        //   // width: "80vw",
                                        //   background:"transparent",
                                        //   borderRadius: "20px",
                                        //   // border: "none",
                                        //   // borderColor: "transparent",

                                        //   "& .MuiInputBase-input": {
                                        //     height: 15,
                                        //     // border: "none",
                                        //     fontSize: "14px",
                                        //     fontFamily: "'Jost', sans-serif",
                                        //     // borderColor: "transparent",
                                        //   },
                                        //   //view
                                        //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                        //     // borderColor: "transparent",
                                        //   },

                                        //   "&:hover .MuiOutlinedInput-input": {
                                        //     color: "black",
                                        //   },
                                        //   "&:hover .MuiInputLabel-root": {
                                        //     color: COLORS.GrayBackground,
                                        //   },
                                        //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                        //   {
                                        //     // borderColor: "transparent",
                                        //   },
                                        //   ".MuiAutocomplete-root fieldset:hover": {
                                        //     color: "transparent",
                                        //     // borderColor: "transparent",
                                        //   },
                                        // }}

                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                                <Input {...params.inputProps} className="" placeholder="City" />
                                            </div>
                                        )}
                                        ListboxProps={{
                                            style: {
                                                maxHeight: "150px",
                                                fontSize: "14px",
                                                // border: '1px solid white'
                                            },
                                        }}
                                    />

                                    {errhomeCity && (
                                        <div className="text-red-600 text-sm text-right mt-2">
                                            This field is required
                                        </div>
                                    )}

                                </div>


                                <div>
                                    <Label className="text-sm">Post Code</Label>
                                    <div className="mt-1.5 flex">
                                        <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                            <i className="text-xl las la-envelope"></i>
                                        </span>
                                        <Input
                                            className="!rounded-l-none"
                                            placeholder="1152"
                                            onChange={(e) => {
                                                sethomepostCode(e.target.value);
                                                seterrhomepostCode(false);
                                                // seterrfirstName(false);
                                            }}
                                            value={homepostCode}
                                        />
                                    </div>
                                    {errhomepostCode && (
                                        <div className="text-red-600 text-sm text-right mt-2">
                                            This field is required
                                        </div>
                                    )}
                                </div>

                            </>
                        }


                        {/* <div>
                            <Label className="text-sm">State</Label>
                            <Select className="mt-1.5"
                            // onChange={(e) => {
                            //     setcountry(e.target.value);
                            //     seterrcountry(false);
                            // }}
                            // value={country}
                            >
                                <option value={""}>Select a Suburb</option>
                                <option value="Australia">Australia</option>
                               
                            </Select>
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div className="flex w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="flex flex-col w-full">
                                <Label className="text-sm">Suburb</Label>
                                <Select className="mt-1.5"
                                // onChange={(e) => {
                                //     setcountry(e.target.value);
                                //     seterrcountry(false);
                                // }}
                                // value={country}
                                >
                                    <option value={""}>Select a Suburb</option>
                                    <option value="Australia">Australia</option>
                                    
                                </Select>
                                {false && (
                                    <div className="text-red-600 text-sm text-right mt-2">
                                        This field is required
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col w-full">
                                <Label className="text-sm">Post Code</Label>
                                <Input className="mt-1.5"
                                    placeholder="Post Code"
                                    onChange={(e) => {
                                        setcontactName(e.target.value);
                                        seterrcontactName(false);
                                    }}
                                    value={contactName}

                                />
                                {errcontactName && (
                                    <div className="text-red-600 text-sm text-right mt-2">
                                        This field is required
                                    </div>
                                )}
                            </div>
                        </div> */}

                        {/* ---- */}

                        {/* ---- */}
                        <div>
                            <Label className="text-sm">Email</Label>
                            <div className="mt-1.5 flex">
                                <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                    <i className="text-xl las la-envelope"></i>
                                </span>
                                <Input
                                    className="!rounded-l-none"
                                    placeholder="example@email.com"
                                    onChange={(e) => {
                                        setemail(e.target.value);
                                        seterremail(false);
                                    }}
                                    value={email}
                                />

                            </div>
                            {erremail && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>

                        {/* ---- */}
                        {/* <div className="max-w-lg">
                <Label>Date of birth</Label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-xl las la-calendar"></i>
                  </span>
                  <Input
                    className="!rounded-l-none"
                    type="date"
                    defaultValue="1990-07-22"
                  />
                </div>
              </div> */}
                        {/* ---- */}
                        {/* <div>
                <Label>Addess</Label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-xl las la-map-signs"></i>
                  </span>
                  <Input
                    className="!rounded-l-none"
                    defaultValue="New york, USA"
                  />
                </div>
              </div> */}

                        {/* ---- */}
                        {/* <div>
                <Label>Gender</Label>
                <Select className="mt-1.5">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Select>
              </div> */}

                        {/* ---- */}

                        {
                            // (currUser?.userType === "service provider" || currUser?.userType === "service seeker") &&

                            <>

                                <div>
                                    <Label className="text-sm">Contact number</Label>
                                    <div className="mt-1.5 flex">
                                        <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                            <i className="text-xl las la-phone-volume"></i>
                                        </span>
                                        <Input
                                            className="!rounded-l-none"
                                            // defaultValue="003 888 232"
                                            placeholder="Enter Mobile"
                                            onChange={(e) => {
                                                setmobile(e.target.value);
                                                seterrmobile("");
                                            }}
                                            value={mobile}
                                        />
                                    </div>
                                    {errmobile && (
                                        <div className="text-red-600 text-sm text-right mt-2">
                                            {errmobile}
                                        </div>
                                    )}
                                </div>

                                {/* {
                                    currUser?.userType === "service provider" &&

                                    <div>
                                        <Label className="text-sm">Contact number 2 (Optional)</Label>
                                        <div className="mt-1.5 flex">
                                            <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                                <i className="text-xl las la-phone-volume"></i>
                                            </span>
                                            <Input
                                                className="!rounded-l-none"
                                                // defaultValue="003 888 232"
                                                placeholder="Enter Mobile"
                                                onChange={(e) => {
                                                    setmobile2(e.target.value);
                                                    seterrmobile2("");
                                                }}
                                                value={mobile2}
                                            />
                                        </div>
                                        {errmobile2 && (
                                            <div className="text-red-600 text-sm text-right mt-2">
                                                {errmobile2}
                                            </div>
                                        )}
                                    </div>
                                } */}


                            </>
                        }

                        {/* <div>
                            <Label className="text-sm">Contact Name</Label>
                            <Input className="mt-1.5"
                                placeholder="Contact Name"
                                onChange={(e) => {
                                    setcontactName(e.target.value);
                                    seterrcontactName(false);
                                }}
                                value={contactName}

                            />
                            {errcontactName && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}
                        {
                            (currUser?.userType === "wholesaler" || currUser?.userType === "retail supplier") &&

                            <div>

                                {/* <Label className="text-sm">Shippit Key</Label>

                                <div className="flex w-full space-x-2 mt-1.5">
                                    <div className="w-full">
                                        <Input className=""
                                            placeholder="Shippit Key"
                                            onChange={(e) => {
                                                setshippitKey(e.target.value);
                                                seterrshippit(false);
                                            }}
                                            value={shippitKey}

                                        />
                                        {errshippit && (
                                            <div className="text-red-600 text-sm text-right mt-2">
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="w-fit">
                                        <a href="https://app.shippit.com/signup/" target="_blank">
                                            <Tooltip title={"Create your shippit account > account settings > Integrations > Copy your api"} placement="top" TransitionComponent={Zoom} arrow >


                                                <button
                                                    className={
                                                        `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full text-slate-50 font-medium h-11`}


                                                >
                                                   
                                                    <span className="block  text-sm font-medium whitespace-nowrap px-4"

                                                    >
                                                        Get Your Shippit
                                                    </span>

                                                </button>
                                            </Tooltip>
                                        </a>
                                    </div>

                                </div> */}

                            </div>

                        }

                        {
                            // currUser?.userType != "service seeker" &&
                            currUser?.userType === "service provider" &&


                            <>
                                <div>
                                    <Label className="text-sm">About the business</Label>
                                    <Textarea className="mt-1.5" rows={8}
                                        onChange={(e) => {
                                            setabout(e.target.value);
                                            seterrabout(false);
                                        }}
                                        value={about}
                                    />
                                    {errabout && (
                                        <div className="text-red-600 text-sm text-right mt-2">
                                            This field is required
                                        </div>
                                    )}
                                </div>
                            </>

                        }


                        {
                            currUser?.userType === "service provider" &&
                            <>
                                {/* <div>
                                    <Label className="text-sm">Video URL</Label>
                                    <Input className="mt-1.5"
                                        placeholder="Video URL"
                                        onChange={(e) => {
                                            setvedioUrl(e.target.value);
                                            // seterrcontactName(false);
                                        }}
                                        value={vedioUrl}

                                    />
                           
                                </div> */}
                                {/* <div className="w-full mt-3 xl:mt-6 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                                    <div className="grid grid-cols-10 gap-5 pl-4 pr-5">
                                        {images?.map((faker, fakerKey) => (
                                            <>
                                                <div
                                                    // key={fakerKey}
                                                    className="flex col-span-5 md:col-span-2 h-28 relative image-fit cursor-pointer zoom-in"
                                                >
                                                    <img
                                                        className="rounded-md"
                                                        alt="Midone - HTML Admin Template"
                                                        src={faker.path.includes('/uploads/providerImages/') === true ? IMAGE_SERVER_URL_DEV + faker?.path : faker?.path}
                                                    />
                                                    

                                                    <div
                                                        className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 -mr-2 -mt-2"
                                                    >
                                                        <div
                                                            onClick={(e) => {

                                                                var array = [...images]
                                                                array.splice(fakerKey, 1);
                                                                setImages(array)

                                                            }}
                                                        >
                                                            
                                                            <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                                        </div>
                                                    </div>


                                                </div>
                                               
                                            </>

                                        ))}
                                    </div>
                                    <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative text-sm mt-4">
                                       
                                        <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                                        <span className="text-primary mr-1">
                                            Upload Images
                                        </span>{" "}
                                        or drag and drop
                                        <input
                                            id="horizontal-form-1"
                                            type="file"
                                            multiple
                                            accept={"image/png, image/jpeg"}
                                            className="w-full h-full top-0 left-0 absolute opacity-0"
                                            onChange={(e) => {

                                                Array.from(e.target.files).map((item) => {

                                                    let file = item;
                                                    let reader = new FileReader();
                                                    reader.readAsDataURL(file);
                                                    let fileInp = item;
                                                    let baseURL;
                                                    // on reader load somthing...
                                                    reader.onload = () => {
                                                        // Make a fileInfo Object
                                                        baseURL = reader.result;
                                                        let obj =
                                                        {
                                                            path: baseURL,
                                                            thumbnail: false,
                                                            name: file.name,
                                                        }
                                                            ;

                                                        console.log(">>>>>", obj);

                                                        setImages(existing => [...existing, obj]);

                                                    };
                                                })



                                            }}
                                        />
                                    </div>
                                </div> */}
                            </>
                        }

                        {
                            (currUser?.userType != "service seeker" && currUser?.userType != "service provider") &&
                            <>

                                {/* <div>
                                    <Label className="text-sm">Refund Policy</Label>
                                    <CKEditor

                                        editor={ClassicEditor}
                                        data={editorData}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setEditorData(data);
                                            console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            console.log('Focus.', editor);
                                        }}
                                    />
                                    {false && (
                                        <div className="text-red-600 text-sm text-right mt-2">
                                            This field is required
                                        </div>
                                    )}
                                </div>

                                <div>
                                    <span className="flex w-full text-center justify-center align-center font-semibold text-sm">OR</span>

                                   

                                    <div className="w-full mt-3 xl:mt-3 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                                        <div className="grid grid-cols-10 gap-5 pl-4 pr-5">
                                            {refundDoc &&
                                                <>
                                                    <div
                                                        // key={fakerKey}
                                                        className="col-span-5 md:col-span-2 h-28 relative image-fit cursor-pointer zoom-in"
                                                    >
                                                       
                                                        <div className="flex flex-col">
                                                            <img className="cursor-pointer w-14 h-14" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nO3Xv0skMRQH8GnGH6yoWCiChbJgZaGNtYWNnf+CFqJYWIpiJ8gVgjaiYiX+BRaCrY0iy3EWarGIhYiLhQiLyWzmzeR7JKO76KLHqclucB6EYbr3Sd6bl/G8NNIwG8z3YWj9chfQ12cPwQwAwj+/wbJZOwgTALov2EMwQwBrCGYQQDYQpgFkGmEDQCYRtgBkCmETQCYQtgH0BlGXgP9ZXgrw0xOAcyVUGhuDzOchi0WECwvuAeTtLcoRx+C9ve4AeFdXJfejI/0sjY+7A2CZDBBFCeDiwkGA7yM+OyuXj4pgeNgtQLi4WOkBIrC2NrcAvLsbCILkEHK5TyfPajmJo4ODBHB8DNbQ4BigqQmyUChXkZiddW+Q6RBCP9RA4/397gBod1cnHq6sINrfTxBXV+A9PfUP4KqBn570LODZLHhnJ+LLS42IT0/16Yi5OdDGBmhnB7S9DTExAdbcXB+AcHlZJ6uaWL0Hg4OgrS38K2hvr/YA3tEB+fCQ7PbJCeT19essn3viJWQ+j/j8PHnhvMaATAa0tla1s6p8wtVVlEZHwVpa9JBTTf02osPDGgEaGyFmZiBvbio7Wyzq+n7vCqEufGJyErS+DtrchJieBmtvtw8IBgZ0Y77a8VxOl9Jnvl7MJiAYGYF8fKyq8WBo6NuSZ8YAatI+l4y8uyvfOsOlpW9NnhkDtLZWfVHU8PrKnYfZLiExNaV3X/0+hvPz7w6iugUwS8tLAX56AkhL6EeXUBppeB/GX+axT5lwmtMeAAAAAElFTkSuQmCC" />
                                                            <span className="text-xs flex justify-center text-center">{refundDoc?.name}</span>
                                                        </div>
                                                        <div
                                                            className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 -mr-2 -mt-2"
                                                        >
                                                            <div
                                                                onClick={(e) => {

                                                                    // var array = [...images]
                                                                    // array.splice(fakerKey, 1);
                                                                    // setImages(array)

                                                                    setrefundDoc("")

                                                                }}
                                                            >
                                                               
                                                                <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    
                                                </>

                                            }
                                        </div>
                                        <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative">
                                           
                                            <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                                            <span className="text-primary mr-1">
                                                Upload or drag and drop the refund policy document
                                            </span>{" "}

                                            <input
                                                id="horizontal-form-1"
                                                type="file"
                                                accept={"application/pdf"}
                                                className="w-full h-full top-0 left-0 absolute opacity-0"
                                                onChange={(e) => {


                                                    let file = e.target.files[0];

                                                    // seterrImages(false);

                                                    let reader = new FileReader();
                                                    reader.readAsDataURL(file);
                                                    let fileInp = e.target.files[0];
                                                    let baseURL;
                                                    // on reader load somthing...
                                                    reader.onload = () => {
                                                        // Make a fileInfo Object
                                                        baseURL = reader.result;
                                                        let obj =
                                                        {
                                                            path: baseURL,
                                                            thumbnail: false,
                                                            name: file.name
                                                        }
                                                            ;

                                                        // setImages(images.concat(obj))

                                                        setrefundDoc(obj);

                                                    };



                                                }}
                                            />
                                        </div>

                                    </div>
                                    <span className="flex justify-end text-xs">(.pdf only)</span>
                                </div>
                                <div>
                                    <Label className="text-sm">Delivery Policy</Label>
                                    <CKEditor

                                        editor={ClassicEditor}
                                        data={editorData2}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setEditorData2(data);
                                            console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            console.log('Focus.', editor);
                                        }}
                                    />
                                    {false && (
                                        <div className="text-red-600 text-sm text-right mt-2">
                                            This field is required
                                        </div>
                                    )}
                                </div>

                                <div>
                                    <span className="flex w-full text-center justify-center align-center font-semibold text-sm">OR</span>

                                

                                    <div className="w-full mt-3 xl:mt-3 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                                        <div className="grid grid-cols-10 gap-5 pl-4 pr-5">
                                            {deliveryDoc &&
                                                <>
                                                    <div
                                                        // key={fakerKey}
                                                        className="col-span-5 md:col-span-2 h-28 relative image-fit cursor-pointer zoom-in"
                                                    >
                                                      
                                                        <div className="flex flex-col">
                                                            <img className="cursor-pointer w-14 h-14" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nO3Xv0skMRQH8GnGH6yoWCiChbJgZaGNtYWNnf+CFqJYWIpiJ8gVgjaiYiX+BRaCrY0iy3EWarGIhYiLhQiLyWzmzeR7JKO76KLHqclucB6EYbr3Sd6bl/G8NNIwG8z3YWj9chfQ12cPwQwAwj+/wbJZOwgTALov2EMwQwBrCGYQQDYQpgFkGmEDQCYRtgBkCmETQCYQtgH0BlGXgP9ZXgrw0xOAcyVUGhuDzOchi0WECwvuAeTtLcoRx+C9ve4AeFdXJfejI/0sjY+7A2CZDBBFCeDiwkGA7yM+OyuXj4pgeNgtQLi4WOkBIrC2NrcAvLsbCILkEHK5TyfPajmJo4ODBHB8DNbQ4BigqQmyUChXkZiddW+Q6RBCP9RA4/397gBod1cnHq6sINrfTxBXV+A9PfUP4KqBn570LODZLHhnJ+LLS42IT0/16Yi5OdDGBmhnB7S9DTExAdbcXB+AcHlZJ6uaWL0Hg4OgrS38K2hvr/YA3tEB+fCQ7PbJCeT19essn3viJWQ+j/j8PHnhvMaATAa0tla1s6p8wtVVlEZHwVpa9JBTTf02osPDGgEaGyFmZiBvbio7Wyzq+n7vCqEufGJyErS+DtrchJieBmtvtw8IBgZ0Y77a8VxOl9Jnvl7MJiAYGYF8fKyq8WBo6NuSZ8YAatI+l4y8uyvfOsOlpW9NnhkDtLZWfVHU8PrKnYfZLiExNaV3X/0+hvPz7w6iugUwS8tLAX56AkhL6EeXUBppeB/GX+axT5lwmtMeAAAAAElFTkSuQmCC" />
                                                            <span className="text-xs flex justify-center text-center">{deliveryDoc?.name}</span>
                                                        </div>
                                                        <div
                                                            className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 -mr-2 -mt-2"
                                                        >
                                                            <div
                                                                onClick={(e) => {

                                                                    // var array = [...images]
                                                                    // array.splice(fakerKey, 1);
                                                                    // setImages(array)

                                                                    setdeliveryDoc("")

                                                                }}
                                                            >
                                                               
                                                                <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                                            </div>
                                                        </div>


                                                    </div>
                                                   
                                                </>

                                            }
                                        </div>
                                        <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative">
                                          
                                            <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                                            <span className="text-primary mr-1">
                                                Upload or drag and drop the delivery policy document
                                            </span>{" "}

                                            <input
                                                id="horizontal-form-1"
                                                type="file"
                                                accept={"application/pdf"}
                                                className="w-full h-full top-0 left-0 absolute opacity-0"
                                                onChange={(e) => {


                                                    let file = e.target.files[0];

                                                    // seterrImages(false);

                                                    let reader = new FileReader();
                                                    reader.readAsDataURL(file);
                                                    let fileInp = e.target.files[0];
                                                    let baseURL;
                                                    // on reader load somthing...
                                                    reader.onload = () => {
                                                        // Make a fileInfo Object
                                                        baseURL = reader.result;
                                                        let obj =
                                                        {
                                                            path: baseURL,
                                                            thumbnail: false,
                                                            name: file.name
                                                        }
                                                            ;

                                                        // setImages(images.concat(obj))

                                                        setdeliveryDoc(obj);

                                                    };



                                                }}
                                            />
                                        </div>

                                    </div>
                                    <span className="flex justify-end text-xs">(.pdf only)</span>
                                </div> */}



                            </>

                        }

                        {
                            currUser?.userType === "service provider" &&

                            <>
                                {/* <div className="space-y-3">
                                    <Label className="flex text-sm mt-4">Please tick the available insurance options and clearance certificates of your business</Label>

                                    <div className="form-check flex ">
                                        <input id="checkbox-switch-pol1" className="cursor-pointer w-5 h-5 text-[#002930] bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox"
                                            checked={policy1}

                                            onChange={(e) => {
                                                console.log("<<<<<", e.target.checked)
                                                setpolicy1(e.target.checked)

                                                // onChangeUserArr(e, arr.privilege)
                                                // this.onChangeAllArr(e, arr.privilege);
                                            }}
                                        />
                                        <label
                                            className="form-check-label ml-2 text-sm flex items-center cursor-pointer"
                                            htmlFor="checkbox-switch-pol1"
                                        >
                                            Public Liability Insurance
                                        </label>



                                    </div>

                                    <div className="form-check flex cursor-pointer">
                                        <input id="checkbox-switch-pol2" className="cursor-pointer w-5 h-5 text-[#002930] bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox"
                                            checked={policy2}

                                            onChange={(e) => {
                                                console.log("<<<<<", e.target.checked)
                                                setpolicy2(e.target.checked)

                                                // onChangeUserArr(e, arr.privilege)
                                                // this.onChangeAllArr(e, arr.privilege);
                                            }}
                                        />
                                        <label
                                            className="cursor-pointer form-check-label ml-2 text-sm flex items-center"
                                            htmlFor="checkbox-switch-pol2"
                                        >
                                            Indemnity Insurance
                                        </label>



                                    </div>

                                    <div className="form-check flex cursor-pointer">
                                        <input id="checkbox-switch-pol3" className="cursor-pointer w-5 h-5 text-[#002930] bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox"
                                            checked={policy3}

                                            onChange={(e) => {
                                                console.log("<<<<<", e.target.checked)
                                                setpolicy3(e.target.checked)

                                                // onChangeUserArr(e, arr.privilege)
                                                // this.onChangeAllArr(e, arr.privilege);
                                            }}
                                        />
                                        <label
                                            className="cursor-pointer form-check-label ml-2 text-sm flex items-center"
                                            htmlFor="checkbox-switch-pol3"
                                        >
                                            Work Cover
                                        </label>



                                    </div>

                                    <div className="form-check flex cursor-pointer">
                                        <input id="checkbox-switch-pol4" className="cursor-pointer w-5 h-5 text-[#002930] bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox"
                                            checked={policy4}

                                            onChange={(e) => {
                                                console.log("<<<<<", e.target.checked)
                                                setpolicy4(e.target.checked)

                                                // onChangeUserArr(e, arr.privilege)
                                                // this.onChangeAllArr(e, arr.privilege);
                                            }}
                                        />
                                        <label
                                            className="cursor-pointer form-check-label ml-2 text-sm flex items-center"
                                            htmlFor="checkbox-switch-pol4"
                                        >
                                            Workers' Compensation Insurance
                                        </label>



                                    </div>
                                </div>


                                <div className="space-y-3">
                                  

                                    <div className="form-check flex">
                                        <input id="checkbox-switch-pol5" className="cursor-pointer w-5 h-5 text-[#002930] bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox"
                                            checked={policy5}

                                            onChange={(e) => {
                                                console.log("<<<<<", e.target.checked)
                                                setpolicy5(e.target.checked)
                                                setpoliceDoc("");
                                                seterrPolice(false);

                                                // onChangeUserArr(e, arr.privilege)
                                                // this.onChangeAllArr(e, arr.privilege);
                                            }}
                                        />
                                        <label
                                            className="cursor-pointer form-check-label ml-2 text-sm flex items-center"
                                            htmlFor="checkbox-switch-pol5"
                                        >
                                            A Valid Police Clearance Certificate
                                        </label>



                                    </div>

                                    {
                                        policy5 &&

                                        <div>
                                            <Label className="text-sm">Issued date</Label>
                                           

                                            <div className="flex w-full">

                                                <DatePicker
                                                    maxDate={moment().toDate()}
                                                    format="DD/MM/YYYY"
                                                    containerStyle={{
                                                        width: "100%"
                                                    }}
                                                    render={<CustomInput placeholder="DD/MM/YYYY" />}

                                                    onChange={(e, val) => {
                                                        console.log(">>>>>>", e.toDate())
                                                        setpoliceDate(e.toDate());
                                                        // seterrexpire(false);
                                                    }}
                                                    value={policeDate}
                                                // value={year}
                                                // onChange={setyear}

                                                />

                                            </div>

                                            
                                            {false && (
                                                <div className="text-red-600 text-sm text-right mt-2">
                                                    This field is required
                                                </div>
                                            )}
                                        </div>

                                    }

                                  

                                    {errPolice && (
                                        <div className="text-red-600 text-sm text-right mt-2">
                                            This field is required
                                        </div>
                                    )}



                                    <div className="form-check flex cursor-pointer">
                                        <input id="checkbox-switch-pol6" className="cursor-pointer w-5 h-5 text-[#002930] bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox"
                                            checked={policy6}

                                            onChange={(e) => {
                                                console.log("<<<<<", e.target.checked)
                                                setpolicy6(e.target.checked)
                                                setchildDoc("");

                                                // onChangeUserArr(e, arr.privilege)
                                                // this.onChangeAllArr(e, arr.privilege);
                                            }}
                                        />
                                        <label
                                            className="cursor-pointer form-check-label ml-2 text-sm flex items-center"
                                            htmlFor="checkbox-switch-pol6"
                                        >
                                            Children Check
                                        </label>



                                    </div>

                                    {
                                        policy6 &&

                                        <div>
                                            <Label className="text-sm">Expiry date</Label>
                                          

                                            <div className="flex w-full">

                                                <DatePicker
                                                    minDate={moment().toDate()}
                                                    format="DD/MM/YYYY"
                                                    containerStyle={{
                                                        width: "100%"
                                                    }}
                                                    render={<CustomInput placeholder="DD/MM/YYYY" />}

                                                    onChange={(e, val) => {
                                                        console.log(">>>>>>", e.toDate())
                                                        setchildDate(e.toDate());
                                                        // seterrexpire(false);
                                                    }}
                                                    value={childDate}
                                                // value={year}
                                                // onChange={setyear}

                                                />

                                            </div>

                                           
                                            {false && (
                                                <div className="text-red-600 text-sm text-right mt-2">
                                                    This field is required
                                                </div>
                                            )}
                                        </div>
                                    }
                                    



                                </div> */}
                            </>

                        }




                        {/* ---- */}
                        {/* <div>
                <Label>About you</Label>
                <Textarea className="mt-1.5" defaultValue="..." />
              </div> */}
                    </div>

                    {/* <div className="pt-2 mt-4">
                        <ButtonPrimary
                        // onClick={onSubmit}
                        >Update account</ButtonPrimary>
                    </div> */}
                    <div className="w-full items-center justify-center">
                        {/* <button
                            className={
                                `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-gray-100 text-slate-900 hover:text-slate-50 hover:bg-[#002930]
        w-full h-9 font-medium mt-12 `}

                            onClick={() => { setShowModalQuickView(true) }}
                        >
                          
                            <span className="block  text-sm font-medium "
                            >
                                Create FAQ s
                            </span>

                        </button> */}
                        <BotToast open={bOpen} msg={bMsg} status={bStatus} />

                        <button
                            className={
                                `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-8 `}
                            onClick={onSubmit}

                        >
                            {/* <i className="las la-pencil-alt text-xl text-left mr-2"></i> */}
                            <span className="block  text-sm font-medium "

                            >
                                {
                                    currUser?.userType != "service seeker" ?

                                        "Save & Continue" :

                                        "Save"
                                }

                            </span>

                        </button>


                    </div>


                </div>
            </div >

        </>
    )

}

export default Account;