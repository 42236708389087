import React, { FC, useState, useEffect, useRef, useContext } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { CurrentUser } from "Services/API/Get";
import { EditUserQuery } from "Services/API/Put";
import { addToast } from "shared/Toast";
import AuthContext from "Context/AuthContext";
import BotToast from "shared/BotToast";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import LoadingSpinner from "shared/LoadingSpinner";

const ChangePassword = ({ getUser, logUser, setselectedTab }) => {

    const { user, setUser } = useContext(AuthContext);
    const [firstName, setfirstName] = useState("");
    const [lastName, setlasttName] = useState("");
    const [mobile, setmobile] = useState("");
    const [email, setemail] = useState("");
    const [premail, setpremail] = useState("");
    const [lopen, setlopen] = React.useState(false);

    const [homeNo, sethomeNo] = useState("");
    const [homeAddress, sethomeAddress] = useState("");
    const [homeDistrict, sethomeDistrict] = useState("");
    const [homeCity, sethomeCity] = useState("");
    const [homepostCode, sethomepostCode] = useState("");

    const [errhomeDistrict, seterrhomeDistrict] = useState(false);
    const [errhomeCity, seterrhomeCity] = useState(false);
    const [errhomepostCode, seterrhomepostCode] = useState(false);
    const [errhomeAddress, seterrhomeAddress] = useState(false);

    const [homeDistrictId, sethomeDistrictId] = useState(0);
    const [allHCities, setallHCities] = useState([]);
    const [allHDistricts, setallHDistricts] = useState([]);

    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [repassword, setrePassword] = useState("");
    const [idisable, setidisable] = useState(true);
    const [errfirstName, seterrfirstName] = useState(false);
    const [errlastName, seterrlasttName] = useState(false);
    const [errmobile, seterrmobile] = useState("");
    const [erremail, seterremail] = useState(false);

    const [errPass, seterrPass] = useState(false);
    const [errPass2, seterrPass2] = useState("");
    const [errRePass, seterrRePass] = useState("");

    const [userId, setuserId] = useState("");
    const [currUser, setcurrUser] = useState(logUser);
    const [eDisable, seteDisable] = useState(true);
    const [eDisable2, seteDisable2] = useState(true);

    const [bOpen, setbOpen] = useState(false);
    const [bMsg, setbMsg] = useState("");
    const [bStatus, setbStatus] = useState("");


    const [images, setImages] = useState([]);

    const [vedioUrl, setvedioUrl] = useState("");


    useEffect(() => {
        setcurrUser(logUser);
    }, [logUser])

    const changePassword = async (e) => {

        const phoneRegex =
            /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

        e.preventDefault();

        if (password === "") {
            seterrPass(true)

        }

        if (password2 === "") {
            seterrPass2("This field is required")

        }

        if (password != password2) {
            seterrPass2("Password Mismatch")

        }

        if (repassword === "") {
            seterrRePass("This field is required");

        }

        if (password !== "") {
            //   seterrPass(true)
            if (repassword === "") {
                seterrRePass("This field is required");
            } else {
                seterrRePass("");
            }
        }





        // if(password != "" && repassword != "" && !(password === repassword)){
        //   seterrRePass("Password Mismatch")
        // }
        // if(password != "" && repassword != "" && (password === repassword)){
        //   seterrRePass("")
        // }

        let obj = {
            firstName: currUser.firstName === null ? "" : currUser.firstName,
            lastName: currUser.lastName === null ? "" : currUser.lastName,
            supplierCategory: currUser.supplierCategory === null ? "" : currUser.supplierCategory,
            businessName: currUser.businessName === null ? "" : currUser.businessName,
            businessRegistrationNumber: currUser.businessRegistrationNumber === null ? "" : currUser.businessRegistrationNumber,
            establishedYear: currUser.establishedYear === null ? "" : currUser.establishedYear,
            website: currUser.website === null ? "" : currUser.website,
            country: currUser.country === null ? "" : currUser.country,
            contactNumber: currUser.contactNumber === null ? "" : currUser.contactNumber,
            contactNumber2: currUser.contactNumber2 === null ? "" : currUser.contactNumber2,
            email: currUser.email === null ? "" : currUser.email,
            contactName: currUser.contactName === null ? "" : currUser.contactName,
            about: currUser.about === null ? "" : currUser.about,
            homeNumber: currUser.homeNumber === null ? "" : currUser.homeNumber,
            homeAddress: currUser.homeAddress === null ? "" : currUser.homeAddress,
            homeCity: currUser.homeCity === null ? "" : currUser.homeCity,
            homeDistrict: currUser.homeDistrict === null ? "" : currUser.homeDistrict,
            homepostCode: currUser.homepostCode === null ? "" : currUser.homepostCode,
            note: currUser.note === null ? "" : currUser.note,
            cover: currUser.cover === null ? "" : currUser.cover,
            avatar: currUser.avatar === null ? "" : currUser.avatar,
            logo: currUser.logo === null ? "" : currUser.logo,
            paymentGateway: currUser.paymentGateway === null ? "" : currUser.paymentGateway,
            userType: currUser.userType === null ? "" : currUser.userType,
            clientId: currUser.clientId === null ? "" : currUser.clientId,
            secretId: currUser.secretId === null ? "" : currUser.secretId,
            deliveryFee: currUser.deliveryFee === null ? "" : currUser.deliveryFee,
            salesCount: currUser.salesCount === null ? "" : currUser.salesCount,
            password: "",
            // oldPassword: repassword,
            serviceProviderImages: images,
            serviceProvierVideos: vedioUrl,
            cleaningTypeId: currUser.cleaningTypeId === null ? "" : currUser.cleaningTypeId,
            shippitKey: currUser.shippitKey === null ? "" : currUser.shippitKey,
            websiteRequest: currUser.websiteRequest === null ? 0 : currUser.websiteRequest,
            refundPolicyDescription: currUser?.refundPolicyDescription ? currUser?.refundPolicyDescription : "",
            refundPolicyDocument: currUser?.refundPolicyDocument ? currUser?.refundPolicyDocument : "",
            deliveryPolicyDescription: currUser?.deliveryPolicyDescription ? currUser?.deliveryPolicyDescription : "",
            deliveryPolicyDocument: currUser?.deliveryPolicyDocument ? currUser?.deliveryPolicyDocument : "",

        };
        console.log("obj>>>>", obj);

        if (

            // (password !== "" && repassword !== "" && password === password2)
            true

        ) {
            setlopen(true);

            EditUserQuery(obj)
                .then((res) => {
                    setlopen(false);

                    setbOpen(true);
                    setbMsg("Successfully updated");
                    setbStatus("success");

                    // addToast("Successfully updated", "success");
                    setPassword("");
                    setPassword2("");
                    setrePassword("");
                    // setOpenToast(true);
                    // setColor(COLORS.Primary);
                    // setAlertClass("success");
                    // setMsg("Successfully updated");
                    getCurrentUser();
                    getUser();
                    setselectedTab(24);
                    // seteDisable(true);

                    // history.push("/");
                    // window.location.reload();
                })
                .catch((error) => {
                    setlopen(false);
                    console.log("Error", error.response);

                    setbOpen(true);
                    setbMsg(error.response.data.message);
                    setbStatus("warning");

                    // addToast(error.response.data.message, "warning");
                    // setOpenToast(true);
                    // setColor(COLORS.Orange);
                    // setAlertClass("error");
                    // setMsg(error.response.data.message);
                })


        } else {
            // scrollElement();
        }
    };

    const getCurrentUser = () => {
        CurrentUser()
            .then((res) => {
                if (res.statusCode === 200) {
                    const cData = res.data[0];
                    setUser(cData);
                    const obj = {
                        id: cData.id,
                        email: cData?.email,
                        businessName: cData?.businessName,
                        contactName: cData?.contactName,
                        phone: cData?.mobileNumber,
                    };
                    //   this.setState({
                    //     businessName: cData?.businessName,
                    //     contactName: cData?.contactName,
                    //     email: cData?.email,
                    //     tele: cData?.phone,
                    //   });
                    console.log("<><><><>", cData);
                    setcurrUser(cData);
                    setImages(JSON.parse(cData?.serviceProviderImages));
                    setvedioUrl(cData?.serviceProvierVideos);
                    // setName(cData?.businessName + " " + cData?.contactName);
                    // setfirstName(cData?.firstName);
                    // setlastName(cData?.lastName);
                    // setcategory(cData?.supplierCategory !== null ? cData?.supplierCategory : "");
                    // setbusinessName(cData?.businessName !== null ? cData?.businessName : "");
                    // setbusinessReg(cData?.businessRegistrationNumber !== null ? cData?.businessRegistrationNumber : "");
                    // setyear(cData?.establishedYear !== null ? cData?.establishedYear : "");
                    // setwebsite(cData?.website !== null ? cData?.website : "");
                    // setcountry(cData?.country !== null ? cData?.country : "");
                    // setmobile(cData?.contactNumber !== null ? cData?.contactNumber : "");
                    // setmobile2(cData?.contactNumber2 !== null ? cData?.contactNumber2 : "");
                    // setcontactName(cData?.contactName !== null ? cData?.contactName : "");
                    // setemail(cData?.email);
                    // setpremail(cData?.email);
                    // setabout(cData?.about !== null ? cData?.about : "");
                    // sethomeDistrict(cData?.homeDistrict !== null ? cData?.homeDistrict : "");
                    // sethomeAddress(cData?.homeAddress !== null ? cData?.homeAddress : "");
                    // sethomeCity(cData?.homeCity !== null ? cData?.homeCity : "");
                    // sethomeNo(cData?.homeNumber !== null ? cData?.homeNumber : "");
                    // sethomepostCode(cData?.homepostCode !== null ? cData?.homepostCode : "");
                    setuserId(cData?.id);


                    localStorage.setItem(
                        `${window.location.hostname}.profile`,
                        JSON.stringify(obj)
                    );
                }
            })
            .catch((error) => {
                console.log("error", error);
                console.log("current user error", error.response.data.message);
            });
    };

    React.useEffect(() => {
        getCurrentUser();

    }, []);

    useEffect(() => {
        if (bOpen === true) {
            setTimeout(() => {

                setbOpen(false)

            }, 10000);
        }
    }, [bOpen])

    return (
        <>
            <LoadingSpinner open={lopen} />
            <div className="w-full flex justify-center px-3">
                <div className="w-full md:w-9/12 lg:w-[45%]">
                    <div className="flex justify-center w-full mt-12 mb-6">

                        <span className="block text-xl font-bold flex justify-center w-full text-center">
                            Add Gallery
                        </span>
                    </div>
                    <div className="space-y-4">
                        <div>
                            <Label className="text-sm">Video URL</Label>
                            <Input className="mt-1.5"
                                placeholder="Video URL"
                                onChange={(e) => {
                                    setvedioUrl(e.target.value);
                                    // seterrcontactName(false);
                                }}
                                value={vedioUrl}

                            />
                            {/* {errcontactName && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )} */}
                        </div>
                        <div className="w-full mt-3 xl:mt-6 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                            <div className="grid grid-cols-10 gap-5 pl-4 pr-5">
                                {images?.map((faker, fakerKey) => (
                                    <>
                                        <div
                                            // key={fakerKey}
                                            className="flex col-span-5 md:col-span-2 h-28 relative image-fit cursor-pointer zoom-in"
                                        >
                                            <img
                                                className="rounded-md"
                                                alt="Midone - HTML Admin Template"
                                                src={faker.path.includes('/uploads/providerImages/') === true ? IMAGE_SERVER_URL_DEV + faker?.path : faker?.path}
                                            />
                                            {/* <div className="flex flex-col">
                                                <img className="cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nO3Xv0skMRQH8GnGH6yoWCiChbJgZaGNtYWNnf+CFqJYWIpiJ8gVgjaiYiX+BRaCrY0iy3EWarGIhYiLhQiLyWzmzeR7JKO76KLHqclucB6EYbr3Sd6bl/G8NNIwG8z3YWj9chfQ12cPwQwAwj+/wbJZOwgTALov2EMwQwBrCGYQQDYQpgFkGmEDQCYRtgBkCmETQCYQtgH0BlGXgP9ZXgrw0xOAcyVUGhuDzOchi0WECwvuAeTtLcoRx+C9ve4AeFdXJfejI/0sjY+7A2CZDBBFCeDiwkGA7yM+OyuXj4pgeNgtQLi4WOkBIrC2NrcAvLsbCILkEHK5TyfPajmJo4ODBHB8DNbQ4BigqQmyUChXkZiddW+Q6RBCP9RA4/397gBod1cnHq6sINrfTxBXV+A9PfUP4KqBn570LODZLHhnJ+LLS42IT0/16Yi5OdDGBmhnB7S9DTExAdbcXB+AcHlZJ6uaWL0Hg4OgrS38K2hvr/YA3tEB+fCQ7PbJCeT19essn3viJWQ+j/j8PHnhvMaATAa0tla1s6p8wtVVlEZHwVpa9JBTTf02osPDGgEaGyFmZiBvbio7Wyzq+n7vCqEufGJyErS+DtrchJieBmtvtw8IBgZ0Y77a8VxOl9Jnvl7MJiAYGYF8fKyq8WBo6NuSZ8YAatI+l4y8uyvfOsOlpW9NnhkDtLZWfVHU8PrKnYfZLiExNaV3X/0+hvPz7w6iugUwS8tLAX56AkhL6EeXUBppeB/GX+axT5lwmtMeAAAAAElFTkSuQmCC" />
                                                <span className="text-xs flex justify-center text-center">{faker?.name}</span>
                                            </div> */}

                                            <div
                                                className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 -mr-2 -mt-2"
                                            >
                                                <div
                                                    onClick={(e) => {

                                                        var array = [...images]
                                                        array.splice(fakerKey, 1);
                                                        setImages(array)

                                                    }}
                                                >
                                                    {/* <Lucide icon="X" className="w-4 h-4" /> */}
                                                    <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                                </div>
                                            </div>


                                        </div>
                                        {/* <div className="form-check">
                                            <input id="checkbox-switch-" className="form-check-input w-5 h-5 bg-gray-200 relative rounded-full text-white right-10 top-[80px]" type="checkbox"
                                                checked={faker?.thumbnail}

                                                onChange={(e) => {
                                                    var array = [...images]
                                                    const index = _.map(array, "thumbnail").indexOf(true);

                                                    // console.log("index>>>", index)

                                                    if (index < 0) {
                                                        array[fakerKey].thumbnail = e.target.checked;
                                                        setImages(array)
                                                    } else {
                                                        array[index].thumbnail = false;
                                                        array[fakerKey].thumbnail = e.target.checked;
                                                        setImages(array)
                                                    }
                                                    // array.filter(person => person.thumbnail === true)
                                                    // array[fakerKey].thumbnail = e.target.checked;
                                                    // setImages(array)
                                                    // console.log("<<<<<",e.target.checked)
                                                    // setlatest(e.target.checked)
                                                    // onChangeUserArr(e, arr.privilege)
                                                    // this.onChangeAllArr(e, arr.privilege);
                                                }}
                                            />
                                           


                                        </div> */}
                                    </>

                                ))}
                            </div>
                            <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative text-sm mt-4">
                                {/* <Lucide icon="Image" className="w-4 h-4 mr-2" /> */}
                                <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                                <span className="text-primary mr-1">
                                    Upload Images
                                </span>{" "}
                                or drag and drop
                                <input
                                    id="horizontal-form-1"
                                    type="file"
                                    multiple
                                    accept={"image/png, image/jpeg"}
                                    className="w-full h-full top-0 left-0 absolute opacity-0"
                                    onChange={(e) => {

                                        Array.from(e.target.files).map((item) => {

                                            let file = item;
                                            let reader = new FileReader();
                                            reader.readAsDataURL(file);
                                            let fileInp = item;
                                            let baseURL;
                                            // on reader load somthing...
                                            reader.onload = () => {
                                                // Make a fileInfo Object
                                                baseURL = reader.result;
                                                let obj =
                                                {
                                                    path: baseURL,
                                                    thumbnail: false,
                                                    name: file.name,
                                                }
                                                    ;

                                                console.log(">>>>>", obj);

                                                setImages(existing => [...existing, obj]);

                                            };
                                        })



                                    }}
                                />
                            </div>
                        </div>
                        {/* <div className="pt-2 mt-4">
                            <ButtonPrimary
                            // onClick={changePassword}
                            >Update password</ButtonPrimary>
                        </div> */}
                        <div className="w-full items-center justify-center">
                            <BotToast open={bOpen} msg={bMsg} status={bStatus} />

                            <button
                                className={
                                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-12 `}

                                onClick={changePassword}
                            >
                                {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                                <span className="block  text-sm font-medium "
                                >
                                    Save
                                </span>

                            </button>


                        </div>

                    </div>


                </div>
            </div>

        </>
    )

}

export default ChangePassword;