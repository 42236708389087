import React, { useState, FC } from "react";

import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
import Radio from "shared/Radio/Radio";
import MySwitch from "components/MySwitch";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import { getCities, getDistricts } from "Services/API/Get";
import { Autocomplete } from "@mui/material";


export interface CategorProps {
  mainId?: any;
  maincategory?: string;
}

export interface SubCategorProps {
  id?: any;
  name?: any;
  description?: any;
}

export interface SectionSliderProductCardProps {
  categories?: any,
  setSelectedcat?: any,
  selectedCat?: any,
  allSubs?: SubCategorProps[],
  setselectedSub?: any,
  selectedSub?: any,
  allSizes?: any,
  allColours?: any,
  selectedSize?: any,
  selectedColour?: any,
  setselectedSize?: any,
  setselectedColour?: any,
  setminPrice?: any,
  minPrice?: any,
  setmaxPrice?: any,
  maxPrice?: any,
  setFilterSortValue?: any,
  filterSortValue?: any,
  setCurrentPage?: any,
  setmainDesc?: any,
  setsearch?: any,
  search?: any,
  selectedPro?: any,
  setselectedPro?: any,
  homeDistrict?: any,
  homeCity?: any,
  sethomeDistrict?: any,
  sethomeCity?: any,
  filterType?: any,
  houseType?: any,
  sethouseType?: any,
  payType?: any,
  setpayType?: any,
  fromDate?: any,
  setfromDate?: any,
  settoDate?: any,
  toDate?: any,
  country?: any;
  setcountry?: any;
}

// DEMO DATA
const DATA_categories = [
  {
    name: "Backpacks",
  },
  {
    name: "Travel Bags",
  },
  {
    name: "Laptop Sleeves",
  },
  {
    name: "Organization",
  },
  {
    name: "Accessories",
  },
];

const DATA_colors = [
  { name: "White" },
  { name: "Beige" },
  { name: "Blue" },
  { name: "Black" },
  { name: "Brown" },
  { name: "Green" },
  { name: "Navy" },
];

const DATA_sizes = [
  { name: "XS" },
  { name: "S" },
  { name: "M" },
  { name: "L" },
  { name: "XL" },
  { name: "2XL" },
];

const DATA_sortOrderRadios = [
  { name: "Most Popular", id: "featured" },
  // { name: "Best Rating", id: "Best-Rating" },
  { name: "Newest", id: "latest" },
  { name: "Price Low - Hight", id: "LowToHigh" },
  { name: "Price Hight - Low", id: "HighToLow" },
];

const PROTYPES = [
  {
    name: "Wholesaler",
    value: "wholesaler",
    id: 1,
  },
  {
    name: "Farmer",
    value: "farmer",
    id: 2,
  },
  {
    name: "Service Provider",
    value: "service provider",
    id: 3,
  },
  {
    name: "Buyer",
    value: "buyer",
    id: 4,
  },

]

const JOBTYPES = [
  {
    name: "Seasonal",
    value: "Seasonal",
    id: 5,
  },
  {
    name: "Casual",
    value: "Casual",
    id: 1,
  },
  {
    name: "Temporary",
    value: "Temporary",
    id: 2,
  },
  {
    name: "Fixed term contract",
    value: "Fixed",
    id: 3,
  },
  {
    name: "Permanent – Part Time",
    value: "Part Time",
    id: 4,
  },
  {
    name: "Permanent – Full time",
    value: "Full Time",
    id: 4,
  },

]


const PRICE_RANGE = [1, 500];

const EVENTTYpes = [
  {
    name: "Expo",
    value: "Expo",
    id: 1,
  },
  {
    name: "Show",
    value: "Show",
    id: 1,
  },
  {
    name: "Conference",
    value: "Conference",
    id: 1,
  },
  {
    name: "Training",
    value: "Training",
    id: 1,
  },
  {
    name: "Forum",
    value: "Forum",
    id: 1,
  },
]

const HOUSETYPES = [
  {
    name: "Online",
    value: "Online",
    id: 1,
  },
  {
    name: "In-house",
    value: "In-house",
    id: 2,
  },
]

const PAYTYPES = [
  {
    name: "Free",
    value: "Free",
    id: 1,
  },
  {
    name: "Paid",
    value: "Paid",
    id: 2,
  },
]
//
const SidebarFilters: FC<SectionSliderProductCardProps> = ({
  categories = [{ mainId: "", maincategory: "" }],
  setSelectedcat,
  selectedCat,
  allSubs = [],
  setselectedSub,
  selectedSub,
  allSizes = [],
  allColours = [],
  selectedSize,
  selectedColour,
  setselectedSize,
  setselectedColour,
  setminPrice,
  minPrice,
  setmaxPrice,
  maxPrice,
  setFilterSortValue,
  filterSortValue,
  setCurrentPage,
  setmainDesc,
  setsearch,
  search,
  selectedPro,
  setselectedPro,
  homeDistrict,
  homeCity,
  sethomeDistrict,
  sethomeCity,
  filterType = "company",
  houseType,
  sethouseType,
  payType,
  setpayType,
  fromDate,
  setfromDate,
  settoDate,
  toDate,

}) => {
  //
  const [isOnSale, setIsIsOnSale] = useState(true);
  const [rangePrices, setRangePrices] = useState([minPrice, maxPrice]);
  const [categoriesState, setCategoriesState] = useState<string[]>([]);
  const [colorsState, setColorsState] = useState<string[]>([]);
  const [sizesState, setSizesState] = useState<string[]>([]);
  const [sortOrderStates, setSortOrderStates] = useState<string>("");

  const [homeDistrictId, sethomeDistrictId] = useState(0);
  const [allHCities, setallHCities] = useState([]);
  const [allHDistricts, setallHDistricts] = useState([]);

  //
  const handleChangeCategories = (checked: boolean, name: string) => {
    checked
      ? setCategoriesState([...categoriesState, name])
      : setCategoriesState(categoriesState.filter((i) => i !== name));
  };

  const handleChangeColors = (checked: boolean, name: string) => {
    checked
      ? setColorsState([...colorsState, name])
      : setColorsState(colorsState.filter((i) => i !== name));
  };

  const handleChangeSizes = (checked: boolean, name: string) => {
    checked
      ? setSizesState([...sizesState, name])
      : setSizesState(sizesState.filter((i) => i !== name));
  };

  const removeLocal = () => {
    localStorage.removeItem("sMain");
    localStorage.removeItem("sSub");
  }

  React.useEffect(() => {
    listDistricts();

  }, []);

  React.useEffect(() => {
    if (homeDistrictId !== 0) {
      listHomecities();
    }
  }, [homeDistrictId]);

  const listDistricts = () => {
    getDistricts()
      .then((res) => {
        console.log("districts>>>", res);
        setallHDistricts(res.data);
        // setallODistricts(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };
  const listHomecities = () => {
    getCities(homeDistrict)
      .then((res) => {
        console.log("cities>>>", res);
        setallHCities(res.data.records);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setallHCities([])
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const handleChangeHdist = (e?: any, value?: any) => {
    if (value !== null) {
      if (value.name_en === "All") {
        sethomeDistrict("");

      } else {
        sethomeDistrict(value.name_en);

        sethomeDistrictId(value.id);
      }


    } else {
      sethomeDistrict("");
      sethomeDistrictId(1);
    }

    sethomeCity("")

    console.log("state>>", value);
  };
  const handleChangeHcity = (e?: any, value?: any) => {
    if (value !== null) {
      if (value.fields.scc_name === "All") {
        sethomeCity("");
      } else {
        sethomeCity(value.fields.scc_name)
      }


    } else {
      sethomeCity("");


    }

    console.log("city>>", value);
  };

  //

  // OK
  const renderTabsCategories = () => {

    return (
      <div className="relative flex flex-col pb-8 space-y-4">
        {/* <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">
        
          </span>
          <Input
            type="text"
            placeholder={filterType === "company" ? "Search Company..." : filterType === "job" ? "Search Job..." : "Search Event..."}
            className="mt-1"
            onChange={(e: any) => {
              setsearch(e.target.value);
              // seterrfirstName(false);
            }}
            value={search}
          />
         
        </label> */}
        {
          filterType === "company2" ?

            <>
              <span className="font-semibold mb-2.5">Profile Type</span>
              <Checkbox
                name={""}
                label={"All"}

                checked={selectedPro?.length === 0}
                sizeClassName="w-5 h-5"
                labelClassName="text-sm font-normal"
                onChange={(checked) => {
                  setselectedPro([]);
                  setselectedColour("");
                  setselectedSize("");
                  setCurrentPage(1);
                  removeLocal();
                  setmainDesc("");
                  setsearch("")
                }}

              />
              {PROTYPES.map((item: any) => (
                <div key={item.id} className="">
                  <Checkbox
                    name={item.value}
                    label={item.name}
                    checked={selectedPro?.includes(item.value)}
                    // defaultChecked={categoriesState.includes(item.mainId)}
                    sizeClassName="w-5 h-5"
                    labelClassName="text-sm font-normal"
                    onChange={(checked) => {
                      if (selectedPro?.includes(item.value)) {
                        const index = selectedPro.indexOf(item.value);

                        console.log(">>>>>>index", index)

                        var arr = [...selectedPro]
                        if (index > -1) {
                          arr.splice(index, 1);
                          console.log("arr>>>>", arr)
                          setselectedPro(arr)
                        }

                      } else {
                        setselectedPro(((data: any) => [...data, item.value]))
                      }
                      // setSelectedcat(item.value);

                      setselectedColour("");
                      setselectedSize("");
                      setCurrentPage(1);
                      removeLocal();
                      setmainDesc(item);
                      setsearch("")
                    }}
                  // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                  />

                </div>
              ))}</>
            : filterType === "job" ?
              <>
                <span className="font-semibold mb-2.5">Job Type</span>
                <Checkbox
                  name={""}
                  label={"All"}

                  checked={selectedPro?.length === 0}
                  sizeClassName="w-5 h-5"
                  labelClassName="text-sm font-normal"
                  onChange={(checked) => {
                    setselectedPro([]);
                    setselectedColour("");
                    setselectedSize("");
                    setCurrentPage(1);
                    removeLocal();
                    setmainDesc("");
                    setsearch("")
                  }}

                />
                {JOBTYPES.map((item: any) => (
                  <div key={item.id} className="">
                    <Checkbox
                      name={item.value}
                      label={item.name}
                      checked={selectedPro?.includes(item.value)}
                      // defaultChecked={categoriesState.includes(item.mainId)}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        // if (selectedPro?.includes(item.value)) {
                        //   const index = selectedPro.indexOf(item.value);

                        //   console.log(">>>>>>index", index)

                        //   var arr = [...selectedPro]
                        //   if (index > -1) {
                        //     arr.splice(index, 1);
                        //     console.log("arr>>>>", arr)
                        //     setselectedPro(arr)
                        //   }

                        // } else {
                        //   setselectedPro(((data: any) => [...data, item.value]))
                        // }

                        setselectedPro([item.value]);
                        // setSelectedcat(item.value);

                        setselectedColour("");
                        setselectedSize("");
                        setCurrentPage(1);
                        removeLocal();
                        setmainDesc(item);
                        setsearch("")
                      }}
                    // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                    />

                  </div>
                ))}
              </>
              : filterType === "event" ?
                <>
                  <span className="font-semibold mb-2.5">Event Type</span>
                  <Checkbox
                    name={""}
                    label={"All"}

                    checked={selectedPro?.length === 0}
                    sizeClassName="w-5 h-5"
                    labelClassName="text-sm font-normal"
                    onChange={(checked) => {
                      setselectedPro([]);
                      setselectedColour("");
                      setselectedSize("");
                      setCurrentPage(1);
                      removeLocal();
                      setmainDesc("");
                      setsearch("")
                    }}

                  />
                  {EVENTTYpes.map((item: any) => (
                    <div key={item.id} className="">
                      <Checkbox
                        name={item.value}
                        label={item.name}
                        checked={selectedPro?.includes(item.value)}
                        // defaultChecked={categoriesState.includes(item.mainId)}
                        sizeClassName="w-5 h-5"
                        labelClassName="text-sm font-normal"
                        onChange={(checked) => {
                          // if (selectedPro?.includes(item.value)) {
                          //   const index = selectedPro.indexOf(item.value);

                          //   console.log(">>>>>>index", index)

                          //   var arr = [...selectedPro]
                          //   if (index > -1) {
                          //     arr.splice(index, 1);
                          //     console.log("arr>>>>", arr)
                          //     setselectedPro(arr)
                          //   }

                          // } else {
                          //   setselectedPro(((data: any) => [...data, item.value]))
                          // }

                          setselectedPro([item.value]);
                          // setSelectedcat(item.value);

                          setselectedColour("");
                          setselectedSize("");
                          setCurrentPage(1);
                          removeLocal();
                          setmainDesc(item);
                          setsearch("")
                        }}
                      // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                      />

                    </div>
                  ))}
                </>
                :
                <></>
        }


      </div>
    );
  };

  const renderHouse = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-4">
        {/* <span className="font-semibold mb-2.5">Country</span> */}

        {HOUSETYPES.map((item: any) => (
          <div key={item.id} className="">
            <Checkbox
              name={item.value}
              label={item.name}
              checked={houseType?.includes(item.value)}
              // defaultChecked={categoriesState.includes(item.mainId)}
              sizeClassName="w-5 h-5"
              labelClassName="text-sm font-normal"
              onChange={(checked) => {
                // if (selectedPro?.includes(item.value)) {
                //   const index = selectedPro.indexOf(item.value);

                //   console.log(">>>>>>index", index)

                //   var arr = [...selectedPro]
                //   if (index > -1) {
                //     arr.splice(index, 1);
                //     console.log("arr>>>>", arr)
                //     setselectedPro(arr)
                //   }

                // } else {
                //   setselectedPro(((data: any) => [...data, item.value]))
                // }
                if (houseType?.includes(item.value)) {
                  sethouseType([]);
                } else {
                  sethouseType([item.value]);
                }


                // setSelectedcat(item.value);

                setselectedColour("");
                setselectedSize("");
                setCurrentPage(1);
                removeLocal();
                setmainDesc(item);
                setsearch("")
              }}
            // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
            />

          </div>
        ))}

      </div>
    );


  }

  const renderPayType = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-4">
        {/* <span className="font-semibold mb-2.5">Country</span> */}

        {PAYTYPES.map((item: any) => (
          <div key={item.id} className="">
            <Checkbox
              name={item.value}
              label={item.name}
              checked={payType?.includes(item.value)}
              // defaultChecked={categoriesState.includes(item.mainId)}
              sizeClassName="w-5 h-5"
              labelClassName="text-sm font-normal"
              onChange={(checked) => {
                // if (selectedPro?.includes(item.value)) {
                //   const index = selectedPro.indexOf(item.value);

                //   console.log(">>>>>>index", index)

                //   var arr = [...selectedPro]
                //   if (index > -1) {
                //     arr.splice(index, 1);
                //     console.log("arr>>>>", arr)
                //     setselectedPro(arr)
                //   }

                // } else {
                //   setselectedPro(((data: any) => [...data, item.value]))
                // }

                if (payType?.includes(item.value)) {
                  setpayType([]);
                } else {
                  setpayType([item.value]);
                }
                // setSelectedcat(item.value);

                setselectedColour("");
                setselectedSize("");
                setCurrentPage(1);
                removeLocal();
                setmainDesc(item);
                setsearch("")
              }}
            // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
            />

          </div>
        ))}

      </div>
    );


  }

  // OK
  const renderTabsSubCategories = () => {

    return (
      <div className="relative flex flex-col py-8 space-y-4">
        <span className="font-semibold mb-2.5">Sub Categories</span>
        {allSubs.map((item) => (
          <div key={item.id} className="">
            <Checkbox
              name={item.id}
              label={item.name}
              checked={selectedSub === item.id}
              // defaultChecked={categoriesState.includes(item.mainId)}
              sizeClassName="w-5 h-5"
              labelClassName="text-sm font-normal"
              onChange={(checked) => { setselectedSub(item.id); removeLocal(); }}
            // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
            />

          </div>
        ))}
      </div>
    );
  };

  // OK
  const renderTabsColor = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-4">
        <span className="font-semibold mb-2.5">Colors</span>
        <Checkbox
          sizeClassName="w-5 h-5"
          labelClassName="text-sm font-normal"
          name={""}
          label={"All"}
          checked={selectedColour === ""}
          onChange={(checked) => { setselectedColour("") }}
        // defaultChecked={colorsState.includes(item.name)}
        // onChange={(checked) => handleChangeColors(checked, item.name)}
        />
        <Checkbox
          sizeClassName="w-5 h-5"
          labelClassName="text-sm font-normal"
          name={"none"}
          label={"None"}
          checked={selectedColour === "none"}
          onChange={(checked) => { setselectedColour("none") }}
        // defaultChecked={colorsState.includes(item.name)}
        // onChange={(checked) => handleChangeColors(checked, item.name)}
        />
        {allColours.map((item: any) => (
          <div key={item} className="">
            <Checkbox
              sizeClassName="w-5 h-5"
              labelClassName="text-sm font-normal capitalize"
              name={item}
              label={item}
              checked={selectedColour === item}
              onChange={(checked) => { setselectedColour(item) }}

            // defaultChecked={colorsState.includes(item.name)}
            // onChange={(checked) => handleChangeColors(checked, item.name)}
            />
          </div>
        ))}
      </div>
    );
  };

  const renderTabsCategory = () => {
    return (
      <div className="relative flex flex-col pb-8 space-y-4">
        <span className="font-semibold mb-2.5">Category</span>
        <Select className="mt-1.5"
          onChange={(e) => {
            setSelectedcat(e.target.value);

          }}
          value={selectedCat}
        >
          <option value={""}>All</option>
          <option value="Agri Technology">Agri Technology</option>
          <option value="Chemicals Supplies">Chemicals Supplies</option>
          <option value="Consultancy">Consultancy</option>
          <option value="Equipment Rentals">Equipment Rentals</option>
          <option value="Equipment Repairs">Equipment Repairs</option>
          <option value="Exporters">Exporters</option>
          <option value="Farm Supplies">Farm Supplies</option>
          <option value="Financial Services">Financial Services</option>
          <option value="Insurance">Insurance</option>
          <option value="Labour Supplies">Labour Supplies</option>
          <option value="Logistic">Logistic</option>
          <option value="Machinery">Machinery</option>
          <option value="Other">Other</option>
          <option value="Pest Controlling">Pest Controlling</option>
          <option value="Cold room Suppliers">Storage/Cold room Suppliers</option>

          {/* <option value="United States">Canada</option>
                    <option value="United States">Mexico</option>
                    <option value="United States">Israel</option>
                    <option value="United States">France</option>
                    <option value="United States">England</option>
                    <option value="United States">Laos</option>
                    <option value="United States">China</option> */}
        </Select>

        <span className="font-semibold mb-2.5">Sub Category</span>
        <Select className="mt-1.5"
        // onChange={(e) => {
        //   setSelectedcat(e.target.value);

        // }}
        // value={selectedCat}
        >
          <option value={""}>All</option>
          {/* <option value="Agri Technology">Agri Technology</option>
          <option value="Chemicals Supplies">Chemicals Supplies</option>
          <option value="Consultancy">Consultancy</option>
          <option value="Equipment Rentals">Equipment Rentals</option>
          <option value="Equipment Repairs">Equipment Repairs</option>
          <option value="Exporters">Exporters</option>
          <option value="Farm Supplies">Farm Supplies</option>
          <option value="Financial Services">Financial Services</option>
          <option value="Insurance">Insurance</option>
          <option value="Labour Supplies">Labour Supplies</option>
          <option value="Logistic">Logistic</option>
          <option value="Machinery">Machinery</option>
          <option value="Other">Other</option>
          <option value="Pest Controlling">Pest Controlling</option>
          <option value="Cold room Suppliers">Storage/Cold room Suppliers</option> */}

          {/* <option value="United States">Canada</option>
                    <option value="United States">Mexico</option>
                    <option value="United States">Israel</option>
                    <option value="United States">France</option>
                    <option value="United States">England</option>
                    <option value="United States">Laos</option>
                    <option value="United States">China</option> */}
        </Select>

      </div>
    );
  };

  const renderTabsCountry = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-4">
        <span className="font-semibold mb-2.5">Country</span>
        <Select className="mt-1.5"
        // onChange={(e) => {
        //   setSelectedcat(e.target.value);

        // }}
        // value={selectedCat}
        >
          <option value={""}>All</option>
          <option value="Australia" selected>Australia</option>
          {/* <option value="United States">Canada</option>
                    <option value="United States">Mexico</option>
                    <option value="United States">Israel</option>
                    <option value="United States">France</option>
                    <option value="United States">England</option>
                    <option value="United States">Laos</option>
                    <option value="United States">China</option> */}
        </Select>

        <span className="font-semibold mb-2.5">State</span>
        <Autocomplete
          className="mt-1.5"
          // className="form-group"
          // className="border-neutral-200"
          disablePortal
          defaultValue={{ name_en: homeDistrict }}
          value={{ name_en: homeDistrict }}

          // id="combo-box-demo"
          options={[{ name_en: "All", value: "" }, ...allHDistricts]}
          getOptionLabel={(option) => option.name_en}
          onChange={(event, value) => {

            handleChangeHdist(event, value);
            // setCountry(value);
          }}
          // sx={{
          //   // width: "80vw",
          //   background:"transparent",
          //   borderRadius: "20px",
          //   // border: "none",
          //   // borderColor: "transparent",

          //   "& .MuiInputBase-input": {
          //     height: 15,
          //     // border: "none",
          //     fontSize: "14px",
          //     fontFamily: "'Jost', sans-serif",
          //     // borderColor: "transparent",
          //   },
          //   //view
          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          //     // borderColor: "transparent",
          //   },

          //   "&:hover .MuiOutlinedInput-input": {
          //     color: "black",
          //   },
          //   "&:hover .MuiInputLabel-root": {
          //     color: COLORS.GrayBackground,
          //   },
          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
          //   {
          //     // borderColor: "transparent",
          //   },
          //   ".MuiAutocomplete-root fieldset:hover": {
          //     color: "transparent",
          //     // borderColor: "transparent",
          //   },
          // }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input {...params.inputProps} className="" placeholder="Select State" />
            </div>
          )}
          ListboxProps={{
            style: {
              maxHeight: "150px",
              fontSize: "14px",
              // border: '1px solid white'
            },
          }}
        />

        <span className="font-semibold mb-2.5">Suburb</span>
        <Autocomplete
          className="mt-1.5"
          // className="form-group"
          // className="border-neutral-200"
          disablePortal
          defaultValue={{ fields: { scc_name: homeCity } }}
          value={{ fields: { scc_name: homeCity } }}
          // id="combo-box-demo"
          options={[{ fields: { scc_name: "All", value: "" } }, ...allHCities]}
          getOptionLabel={(option) => option?.fields?.scc_name}
          onChange={(event, value) => {

            handleChangeHcity(event, value);
            // setCountry(value);
          }}
          // sx={{
          //   // width: "80vw",
          //   background:"transparent",
          //   borderRadius: "20px",
          //   // border: "none",
          //   // borderColor: "transparent",

          //   "& .MuiInputBase-input": {
          //     height: 15,
          //     // border: "none",
          //     fontSize: "14px",
          //     fontFamily: "'Jost', sans-serif",
          //     // borderColor: "transparent",
          //   },
          //   //view
          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          //     // borderColor: "transparent",
          //   },

          //   "&:hover .MuiOutlinedInput-input": {
          //     color: "black",
          //   },
          //   "&:hover .MuiInputLabel-root": {
          //     color: COLORS.GrayBackground,
          //   },
          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
          //   {
          //     // borderColor: "transparent",
          //   },
          //   ".MuiAutocomplete-root fieldset:hover": {
          //     color: "transparent",
          //     // borderColor: "transparent",
          //   },
          // }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input {...params.inputProps} className="" placeholder="Select State" />
            </div>
          )}
          ListboxProps={{
            style: {
              maxHeight: "150px",
              fontSize: "14px",
              // border: '1px solid white'
            },
          }}
        />

      </div>
    );
  };



  // OK
  const renderTabsSize = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-4">
        <span className="font-semibold mb-2.5">Sizes</span>
        <Checkbox
          sizeClassName="w-5 h-5"
          labelClassName="text-sm font-normal"
          name={""}
          label={"All"}
          checked={selectedSize === ""}
          onChange={(checked) => { setselectedSize("") }}
        // defaultChecked={colorsState.includes(item.name)}
        // onChange={(checked) => handleChangeColors(checked, item.name)}
        />
        <Checkbox
          sizeClassName="w-5 h-5"
          labelClassName="text-sm font-normal"
          name={"none"}
          label={"None"}
          checked={selectedSize === "none"}
          onChange={(checked) => { setselectedSize("none") }}
        // defaultChecked={colorsState.includes(item.name)}
        // onChange={(checked) => handleChangeColors(checked, item.name)}
        />
        {allSizes.map((item: any) => (
          <div key={item} className="">
            <Checkbox
              name={item}
              label={item}
              checked={selectedSize === item}
              // defaultChecked={sizesState.includes(item.name)}
              onChange={(checked) => { setselectedSize(item) }}
              // onChange={(checked) => handleChangeSizes(checked, item.name)}
              sizeClassName="w-5 h-5"
              labelClassName="text-sm font-normal"
            />
          </div>
        ))}
      </div>
    );
  };

  // OK
  const renderTabsPriceRage = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-5 pr-3">
        <div className="space-y-5">
          <span className="font-semibold">
            {filterType === "job" ? "Hourly Rate" : "Price Range"}
          </span>
          <Slider
            range
            min={0}
            max={999}
            step={1}
            defaultValue={[0, 999]}
            allowCross={false}
            onAfterChange={(_input: any | number | number[]) => {

              // setRangePrices(_input as number[]);
              setminPrice(_input[0]);
              setmaxPrice(_input[1]);

            }

            }
            onChange={(_input: any | number | number[]) => {
              setRangePrices(_input as number[]);
              // setminPrice(_input[0]);
              // setmaxPrice(_input[1]);
            }
            }
          // onChange={(e : any)=>{
          //   console.log(">>>>>>>",e)
          //   setminPrice(e[0]);
          //   setmaxPrice(e[1]);
          // }}
          />
        </div>

        <div className="flex justify-between space-x-5">
          <div>
            <label
              htmlFor="minPrice"
              className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
            >
              Min price
            </label>
            <div className="mt-1 relative rounded-md">
              <span className="absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-sm">
                $
              </span>
              <input
                type="text"
                name="minPrice"
                disabled
                id="minPrice"
                className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                value={rangePrices[0]}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="maxPrice"
              className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
            >
              Max price
            </label>
            <div className="mt-1 relative rounded-md">
              <span className="absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-sm">
                $
              </span>
              <input
                type="text"
                disabled
                name="maxPrice"
                id="maxPrice"
                className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                value={rangePrices[1]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DateRange = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-5 pr-3">
        <div className="space-y-4">
          <div>
            <span className="font-semibold">Date range</span>
            <label
              htmlFor="minPrice"
              className="block text-sm font-medium text-neutral-700 dark:text-neutral-300 mt-3"
            >
              From
            </label>
            <Input className="mt-1.5" type="date"
              onChange={(e) => {
                setfromDate(e.target.value);
                // seterrlasttName(false);
              }}
              value={fromDate}
            />
          </div>
          <div>
            <label
              htmlFor="minPrice"
              className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
            >
              To
            </label>
            <Input className="mt-1.5" type="date"
              onChange={(e) => {
                settoDate(e.target.value);
                // seterrlasttName(false);
              }}
              value={toDate}
            />
          </div>

        </div>


      </div>
    );
  }

  // OK
  const renderTabsSortOrder = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-4">
        <span className="font-semibold mb-2.5">Sort order</span>
        <Radio
          id={""}
          key={""}
          name="radioNameSort"
          label={"None"}
          checked={filterSortValue === ""}
          // defaultChecked={sortOrderStates === item.id}
          sizeClassName="w-5 h-5"
          onChange={setFilterSortValue}
          className="!text-sm"
        />
        {DATA_sortOrderRadios.map((item) => (
          <Radio
            id={item.id}
            key={item.id}
            name="radioNameSort"
            label={item.name}
            checked={filterSortValue === item.id}
            // defaultChecked={sortOrderStates === item.id}
            sizeClassName="w-5 h-5"
            onChange={setFilterSortValue}
            className="!text-sm"
          />
        ))}
      </div>
    );
  };

  return (
    <div className="text-sm">
      {filterType != "company" && renderTabsCategories()}
      {filterType === "event" && renderHouse()}
      {filterType === "event" && renderPayType()}

      {/* {allSubs.length > 0 && selectedCat !== "" && renderTabsSubCategories()} */}
      {filterType === "company" && renderTabsCategory()}
      {renderTabsCountry()}
      {/* {renderTabsColor()}
      {renderTabsSize()} */}
      {filterType != "event" ? renderTabsPriceRage() : filterType === "event" ? DateRange() : ""}
      {/* <div className="py-8 pr-2">
        <MySwitch
          label="On sale!"
          desc="Products currently on sale"
          enabled={isOnSale}
          onChange={setIsIsOnSale}
        />
      </div> */}
      {/* {renderTabsSortOrder()} */}
    </div>
  );
};

export default SidebarFilters;
