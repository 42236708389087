import React, { FC, useState, useEffect, useRef } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL, IMAGE_SERVER_URL_DEV, SUPPORT_URL } from "Constant/System";
import Company1 from "images/avatars/Image-1.png";
import ModalQuickView from "components/ModalJob";
import { addToast } from "shared/Toast";
import { CurrentUser, getCities, getDistricts, getJobs } from "Services/API/Get";
import { DeleteJob } from "Services/API/Delete";
import { useHistory } from "react-router-dom";

import ModalQuickView2 from "components/ModalApplicant";

import DatePicker from "react-multi-date-picker"
import { Autocomplete } from "@mui/material";
import _, { lowerCase, upperFirst } from "lodash";
import SubData from "data/australian_postcodes.json";
import { UpdateShippingCost } from "Services/API/Put";
import { uploadExcel, uploadExcelDelivery } from "Services/API/Post";
import LoadingSpinner from "shared/LoadingSpinner";
import { CSVLink } from "react-csv";


const MyJobs = (props) => {

    const router = useHistory();

    const [images, setImages] = useState([]);
    const [showModalQuickView, setShowModalQuickView] = useState(false);

    const [showModalQuickView2, setShowModalQuickView2] = useState(false);

    const [jobs, setjobs] = useState([]);

    const [search, setsearch] = useState("");
    const [category, setcategory] = useState("");

    const [selectedJob, setselectedJob] = useState("");

    const [currUser, setcurrUser] = useState("");

    const [homeDistrict, sethomeDistrict] = useState("");
    const [homeCity, sethomeCity] = useState("");
    const [homeDistrictId, sethomeDistrictId] = useState(0);
    const [allHCities, setallHCities] = useState([]);
    const [allHDistricts, setallHDistricts] = useState([]);
    const [newSubArray, setnewSubArray] = useState([]);
    const [newSubs, setnewSubs] = useState([]);

    const [errState, seterrState] = useState(false);

    const [homeDistrict2, sethomeDistrict2] = useState("");
    const [homeDistrictId2, sethomeDistrictId2] = useState(0);

    const [lOpen, setlOpen] = useState(false);

    const [excel, setExcel] = useState([]);

    const [delDetails, setdelDetails] = useState(
        //     [
        //         {
        //         "state": "VIC",
        //         "name": "Abbeyard",
        //         "postcode": "3737",
        //         "delCharge": 4,

        //     },
        //     {
        //         "state": "VIC",
        //         "name": "Abbotsford",
        //         "postcode": "3067",
        //         "delCharge": 4,

        //     }
        // ]
        []
    );

    const [subsData, setsubsData] = useState({});

    const [refundDoc, setrefundDoc] = useState("");

    const getCurrentUser = () => {
        CurrentUser()
            .then((res) => {
                if (res.statusCode === 200) {
                    const cData = res.data[0];
                    // setUser(cData);
                    const obj = {
                        id: cData.id,
                        email: cData?.email,
                        businessName: cData?.businessName,
                        contactName: cData?.contactName,
                        phone: cData?.mobileNumber,
                    };
                    //   this.setState({
                    //     businessName: cData?.businessName,
                    //     contactName: cData?.contactName,
                    //     email: cData?.email,
                    //     tele: cData?.phone,
                    //   });
                    console.log("<><><><>", cData);
                    setcurrUser(cData);
                    // setName(cData?.businessName + " " + cData?.contactName);
                    // setfirstName(cData?.firstName);
                    // setlastName(cData?.lastName);
                    // setcategory(cData?.supplierCategory !== null ? cData?.supplierCategory : "");
                    // setbusinessName(cData?.businessName !== null ? cData?.businessName : "");
                    // setbusinessReg(cData?.businessRegistrationNumber !== null ? cData?.businessRegistrationNumber : "");
                    // setyear(cData?.establishedYear !== null ? cData?.establishedYear : "");
                    // setwebsite(cData?.website !== null ? cData?.website : "");
                    // setcountry(cData?.country !== null ? cData?.country : "");
                    // setmobile(cData?.contactNumber !== null ? cData?.contactNumber : "");
                    // setmobile2(cData?.contactNumber2 !== null ? cData?.contactNumber2 : "");
                    // setcontactName(cData?.contactName !== null ? cData?.contactName : "");
                    // setemail(cData?.email);
                    // setpremail(cData?.email);
                    // setabout(cData?.about !== null ? cData?.about : "");
                    // sethomeDistrict(cData?.homeDistrict !== null ? cData?.homeDistrict : "");
                    // sethomeAddress(cData?.homeAddress !== null ? cData?.homeAddress : "");
                    // sethomeCity(cData?.homeCity !== null ? cData?.homeCity : "");
                    // sethomeNo(cData?.homeNumber !== null ? cData?.homeNumber : "");
                    // sethomepostCode(cData?.homepostCode !== null ? cData?.homepostCode : "");
                    // setuserId(cData?.id);

                    if (cData.deliveryInfo) {
                        setdelDetails(JSON.parse(cData.deliveryInfo));

                    } else {
                        setdelDetails([]);
                    }


                    localStorage.setItem(
                        `${window.location.hostname}.profile`,
                        JSON.stringify(obj)
                    );
                }
            })
            .catch((error) => {
                console.log("error", error);
                console.log("current user error", error.response.data.message);
            });
    };

    React.useEffect(() => {
        getCurrentUser();

    }, []);

    useEffect(() => {
        console.log("del change>>>>", delDetails)

        var arr = {}

        delDetails.map((item, index) => {

            let id = item.state + item.name + item.postcode

            arr[id] = item



        })

        setsubsData(arr)

    }, [delDetails])

    useEffect(() => {
        console.log("subsData>>>>", subsData)

    }, [subsData])


    React.useEffect(() => {

        let newSubArray = JSON.parse(JSON.stringify(SubData));

        newSubArray = newSubArray?.map((d) => {
            if (d.type === "Delivery Area") {

                return {
                    ...d,
                    locality: upperFirst(lowerCase(d.locality)),
                };

            }

        });

        newSubArray = _.compact(newSubArray)


        setnewSubArray(_.orderBy(newSubArray, ['locality'], ['asc']));

        console.log(">>>>>>>subs", newSubArray.filter((u) => u.state === 0))


    }, []);


    React.useEffect(() => {
        listDistricts();

    }, []);


    React.useEffect(() => {
        console.log("subs>>>>>", newSubs)

    }, [newSubs]);

    React.useEffect(() => {
        if (homeDistrictId !== 0) {
            listHomecities();
        }
    }, [homeDistrictId]);

    const listDistricts = () => {
        getDistricts()
            .then((res) => {
                console.log("districts>>>", res);
                setallHDistricts(res.data);
                // setallODistricts(res.data);
            })
            .catch((error) => {
                console.log("Error", error.response);
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });
    };
    const listHomecities = () => {
        getCities(homeDistrict)
            .then((res) => {
                console.log("cities>>>", res);
                setallHCities(res.data.records);
            })
            .catch((error) => {
                console.log("Error", error.response);
                setallHCities([])
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });
    };

    const handleChangeHdist = async (e, value) => {

        setlOpen(true);

        setTimeout(() => {
            setlOpen(false);
        }, 3000);


        if (value !== null) {
            if (value.name_en === "All") {
                sethomeDistrict("");

            } else {
                sethomeDistrict(value.name_en);

                sethomeDistrictId(value.id);
            }


        } else {
            sethomeDistrict("");
            sethomeDistrictId(1);
        }

        await setnewSubs(newSubArray.filter((u) => u.state === value.id))




        sethomeCity("")

        console.log("state>>", value);
    };
    const handleChangeHcity = (e, value) => {
        if (value !== null) {
            // if (value.fields.scc_name === "All") {
            //   sethomeCity("");
            // } else {
            //   sethomeCity(value.fields.scc_name)
            // }

            if (value.locality === "All") {
                sethomeCity("");
            } else {
                sethomeCity(value.locality)
            }


        } else {
            sethomeCity("");


        }

        console.log("city>>", value);
    };



    const CATEG = [{
        value: "Seasonal",
        name: "Seasonal"
    },
    {
        value: "Casual",
        name: "Casual"
    },
    {
        value: "Temporary",
        name: "Temporary"
    },
    {
        value: "Fixed",
        name: "Fixed"
    },
    {
        value: "Part Time",
        name: "Permanent – Part Time"
    },
    {
        value: "Full Time",
        name: "Permanent – Full time"
    },


    ]

    const getAllJobs = () => {
        console.log(">>>>>>sdsxcddf")
        getJobs({
            jobType: JSON.stringify([]),
            title: search,
            jobType: category ? JSON.stringify([category]) : JSON.stringify([])
        })
            .then((res) => {
                console.log(">>>>>>>jobs", res.data);
                setjobs(res.data.result);
            })
            .catch((error) => {
                // setlopen(false);
                setjobs([]);
                console.log("Error", error.response);
                addToast(error.response.data.message, "warning");
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            })
    }

    useEffect(() => {
        getAllJobs();
    }, [showModalQuickView, search, category])


    const RenderSuburbs = ({ item }) => {

        let id = item.state + item.locality + item.postcode;

        // let res = delDetails.length > 0 ? (delDetails.filter((u) => u.state === homeDistrict))?.find((u) => u.name === item?.locality) : ""

        let res = subsData[id];

        const [status, setstatus] = useState(res ? true : false);

        const [delivery, setdelivery] = useState(res ? res?.delCharge : "");

        const [upChanges, setupChanges] = useState([]);

        // useEffect(() => {

        //     let res = (delDetails.filter((u) => u.state === homeDistrict)[0].suburbs)?.find((u) => u.name === item?.locality)

        //     if (res) {
        //         setstatus(true);
        //     } else {
        //         setstatus(false);
        //     }

        //     console.log("res<><>", res)

        // }, [item])


        return (
            <>

                <div>
                    <div className="flex items-center justify-start">

                        <input id={`${item?.locality}`} className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600  cursor-pointer" type="checkbox"
                            checked={status}

                            onChange={(e) => {
                                console.log("<<<<<", e.target.checked)

                                if (!e.target.checked) {

                                    // var arr = [...delDetails]

                                    // var arr2 = arr.filter((u) => u.state === homeDistrict)

                                    // let index = arr2.findIndex(x => x.name === item?.locality);

                                    // console.log("<SDSD", index)

                                    // arr2.splice(index, 1);



                                    // if (arr2.length > 0) {
                                    //     arr.filter((u) => u.state === homeDistrict)[0].suburbs = arr2
                                    // } else {

                                    //     arr.filter((u) => u.state === homeDistrict)[0] = []

                                    // }



                                    // console.log("<SDSD", arr2)

                                    // var arr = arr2
                                    // setupChanges(arr);
                                    // setupChanges(arr);
                                    // setdelDetails(arr);

                                    var arr = { ...subsData }
                                    delete arr[id]
                                    console.log(arr)
                                    setsubsData(arr);

                                }
                                // setfeatured(e.target.checked)
                                // setlatest(false)
                                setstatus(e.target.checked);
                                // onChangeUserArr(e, arr.privilege)
                                // this.onChangeAllArr(e, arr.privilege);
                            }}
                        />
                        <label
                            className="form-check-label ml-2 text-base flex items-center  cursor-pointer"
                            htmlFor={`${item?.locality}`}
                        >
                            {item?.locality}
                        </label>


                    </div>

                    {
                        status &&

                        <div className="mt-1.5 flex">
                            <div className="flex">
                                <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                    $
                                </span>
                                <Input
                                    className="!rounded-l-none"
                                    placeholder="Price"
                                    onChange={(e) => {

                                        setdelivery(e.target.value);

                                        if (e.target.value) {

                                            // var arr = [...delDetails]

                                            // var arr2 = arr.filter((u) => u.state === homeDistrict)


                                            // let index = arr2.findIndex(x => x.name === item?.locality);

                                            // console.log("index>>", index)

                                            // if (index > -1) {

                                            //     arr2[index].delCharge = e.target.value;

                                            // } else {
                                            //     let obj = {
                                            //         "state": homeDistrict,
                                            //         "name": item?.locality,
                                            //         "postcode": item?.postcode,
                                            //         "delCharge": e.target.value,

                                            //     }


                                            //     arr2.push(obj);

                                            //     console.log("arr>>>", arr2)
                                            // }

                                            // arr = arr2

                                            // console.log("<SDSD", arr)
                                            // setupChanges(arr);

                                            let obj = {
                                                "state": homeDistrict,
                                                "name": item?.locality,
                                                "postcode": item?.postcode,
                                                "delCharge": e.target.value,

                                            }

                                            var arr = { ...subsData }

                                            arr[id] = obj;

                                            setsubsData(arr);

                                        } else {

                                            // var arr = [...delDetails]

                                            // var arr2 = arr.filter((u) => u.state === homeDistrict)

                                            // let index = arr2.findIndex(x => x.name === item?.locality);

                                            // console.log("<SDSD", index)

                                            // arr2.splice(index, 1);



                                            // if (arr2.length > 0) {
                                            //     arr.filter((u) => u.state === homeDistrict)[0].suburbs = arr2
                                            // } else {

                                            //     arr.filter((u) => u.state === homeDistrict)[0] = []

                                            // }



                                            // console.log("<SDSD", arr2)

                                            // var arr = arr2

                                            // console.log("<SDSD", arr);
                                            // setupChanges(arr);
                                            // setdelDetails(arr);

                                            var arr = { ...subsData }
                                            delete arr[id]
                                            console.log(arr)
                                            setsubsData(arr);

                                        }

                                    }}
                                    value={delivery}
                                />
                            </div>
                            <div className="flex items-center">
                                <i className="las la-save text-3xl text-left ml-2 text-[#2d867b] cursor-pointer"

                                    onClick={() => {
                                        setdelDetails(upChanges);
                                    }}
                                ></i>
                            </div>
                        </div>
                    }


                </div>
            </>
        );

    }


    return (
        <>
            <div className="justify-center w-full mt-12">

                <span className="block text-xl font-bold flex justify-center w-full text-center">
                    Delivery Details
                </span>

                <div className="flex justify-center">
                    <span className="text-xs font-normal justify-center w-full md:w-9/12 lg:w-[45%] text-center mt-1 mb-6 px-4">
                        In this section, you can define the delivery charges for all
                        the suburbs to which you deliver your products. Please select the
                        suburbs you deliver to and enter the delivery charge for each suburb.
                        These charges will be automatically added at the customer checkout based
                        on the suburb where the order should be delivered. If you need any
                        assistance in completing this task, please <a href={SUPPORT_URL} target="_blank" className="font-medium underline">click here</a> and submit a
                        support ticket. One of our support team members will be in touch with
                        you soon.
                    </span>
                </div>

                <div className="flex justify-center">
                    <div className="text-xs flex flex-col font-normal w-full md:w-9/12 lg:w-[45%] text-left my-2 px-4">
                        <span className="font-semibold mb-2"> Bulk Uploading </span>

                        <span>Step 1 – Select the state in which you operate your business.</span>
                        <span>Step 2 – Download the spreadsheet with the list of suburbs in the
                            selected state.</span>
                        <span>Step 3 – Complete the spreadsheet with your delivery charges.</span>
                        <span>Step 4 – Upload the spreadsheet and click "Upload."</span>


                    </div>



                </div>

                <div className="flex justify-center">
                    <div className="text-xs flex flex-col font-normal w-full md:w-9/12 lg:w-[45%] text-left my-2 px-4">
                        <span className="font-semibold">OR</span>

                    </div>



                </div>

                <div className="flex justify-center">
                    <div className="text-xs flex flex-col font-normal w-full md:w-9/12 lg:w-[45%] text-left my-2 px-4">
                        <span className="font-semibold mb-2">Individual Uploading</span>

                        <span>Step 1 – Select the state in which you operate.</span>
                        <span>Step 2 – Tick the suburbs to which you deliver products and enter the
                            delivery charge for each suburb.</span>
                        <span>Step 3 – Click "Save."</span>



                    </div>



                </div>

                {/* <div className="flex justify-center">

                    <div className="text-xs flex font-normal w-full md:w-9/12 lg:w-[45%] text-left mt-2 mb-6">
                        <a href={SUPPORT_URL} target="_blank" className="font-medium ">
                            <span className="font-semibold">Please contact our support team if you are facing any issues when
                                completing this task.
                            </span>
                        </a>

                    </div>




                </div> */}




            </div>
            <div className="w-full flex justify-center px-3">

                <div className="w-full md:w-10/12 lg:w-9/12 xl:w-[55%]">





                    <div className="w-full mt-4 space-y-4 bg-gray-50 p-7">


                        <Label className="mb-4 font-bold">Bulk Uploading</Label>

                        <div>


                            <Label className="text-sm">State</Label>
                            <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                defaultValue={{ name_en: homeDistrict2 }}
                                value={{ name_en: homeDistrict2 }}

                                // id="combo-box-demo"
                                options={allHDistricts}
                                getOptionLabel={(option) => option.name_en}
                                onChange={(event, value) => {

                                    // handleChangeHdist(event, value);
                                    if (value) {
                                        sethomeDistrict2(value.name_en)
                                        sethomeDistrictId2(value.id);

                                        const arr = newSubArray.filter((u) => u.state === value.id)

                                        let header = []

                                        for (let key in arr) {

                                            const obj = {};

                                            obj["suburb"] = arr[key]["locality"]
                                            obj["postcode"] = arr[key]["postcode"]
                                            obj["delivery charge"] = ""


                                            header.push(obj);
                                        }



                                        console.log(">>>>>", header)

                                        setExcel(header);



                                    }
                                    seterrState(false);
                                    // setCountry(value);
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="bg-gray-100" placeholder="Select State" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            />
                            {/* <Select className="mt-1.5"
                                onChange={(e) => {
                                    setcategory(e.target.value);
                                    // seterrcountry(false);
                                }}
                                value={category}
                            >
                                <option value={""}>All</option>
                                <option value="Seasonal">Seasonal</option>
                                <option value="Casual">Casual</option>
                                <option value="Temporary">Temporary</option>
                                <option value="Fixed">Fixed term contract</option>
                                <option value="Part Time">Permanent – Part Time</option>
                                <option value="Full Time">Permanent – Full time</option>
                           
                            </Select> */}
                            {errState && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>

                        <div className="mb-10">

                            {/* <a href={IMAGE_SERVER_URL_DEV + "/uploads/delivery_excel/deliverycharges.xlsx"} target="_blank" >
                                <Label className="text-sm cursor-pointer">Download excel template</Label>

                                <i className="las la-file-download cursor-pointer ml-1 text-lg"></i>
                            </a> */}
                            <CSVLink
                                filename='deliverycharges.csv'
                                data={excel}
                                onClick={() => {

                                    if (!homeDistrictId2) {
                                        seterrState(true);
                                        return false;
                                    }

                                }}
                            >
                                <div

                                >
                                    <Label className="text-sm cursor-pointer">Download excel template</Label>

                                    <i className="las la-file-download cursor-pointer ml-1 text-lg"></i>
                                </div>
                            </CSVLink>
                            {/* <Tooltip title={"Download excel template"} placement="top" TransitionComponent={Zoom} arrow >
<a href={IMAGE_SERVER_URL_DEV + "/uploads/product_excel/template.xlsx"} target="_blank" >
<i className="las la-file-download cursor-pointer ml-1 text-lg"></i>
</a>
</Tooltip> */}
                            <div className="w-full mt-3 xl:mt-3 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                                <div className="grid grid-cols-10 gap-5 pl-4 pr-5">
                                    {refundDoc &&
                                        <>
                                            <div
                                                // key={fakerKey}
                                                className="col-span-5 md:col-span-2 h-28 relative image-fit cursor-pointer zoom-in"
                                            >
                                                {/* <img
className="rounded-md"
alt="Midone - HTML Admin Template"
src={faker.path.includes('/uploads/products/') === true ? IMAGE_SERVER_URL + faker?.path : faker?.path}
/> */}
                                                <div className="flex flex-col">
                                                    <img className="cursor-pointer w-14 h-14" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB90lEQVR4nGNgGAWjYBSMgiEJxCYEZ4hNCP4oNjHkP6lY81A96fhg3Uetg3Xp1PQAWY4n2wOHwPgD9TxApuMp9MD/UQ/AwGgMHBpNQsM0CXEXWhRwF1p84Smy+E8RLrH8L9TsMSAe+EKx42G42OI/T4MrBpZck0o2ZiAEqOZ4GB71QOpoDPwXLrP9f+f1o/8gULVpEjx5aLUE/v/x+9f/b79+/NdpDR7cSShiXhnYA2++vP8vUekEFlt5didYrGHr9KGRB3ZdPw52cOO2Gf8te2L///339//tVw/BMTQkPGDSGfn/15/f/99/+/T/2L0L///9+/ffa1r20CqFphxc8R8Glp/ZPvSK0YUnNsE9sOLsDoIeEHfXwsBC04LIxgyUeMBzahY42Zx6cPn/kbvnwZ7wn5k/NDwgXGb7/8aL+2BHg9K9/YQksGcevn32X6zCcfB7oHvPArDjd1w7ChfbdOkAWKx378LB7QGzrihw6QMqNq174+Dihh1h/3///QPGVr2xg9cDPBTgUQ9MG2kxwF1k/plqHigwHwAPFJrnUcUTBeb/hKL0v4m7a75Hx6oZDmRjBmoBCQ+t/+Ri9TR7sjHDqAegYDQG0kaT0EhPQu5aH+nvATvqTXBIemilSXhofaCfB+w+qKbYp1LNA6NgFIyCUcBADQAA6UC2Ipn+q/gAAAAASUVORK5CYII=" />
                                                    <span className="text-xs flex justify-center text-center">{refundDoc?.name}</span>
                                                </div>
                                                <div
                                                    className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 -mr-2 -mt-2"
                                                >
                                                    <div
                                                        onClick={(e) => {

                                                            // var array = [...images]
                                                            // array.splice(fakerKey, 1);
                                                            // setImages(array)

                                                            setrefundDoc("")

                                                        }}
                                                    >
                                                        {/* <Lucide icon="X" className="w-4 h-4" /> */}
                                                        <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                                    </div>
                                                </div>


                                            </div>
                                            {/* <div className="form-check">
<input id="checkbox-switch-" className="form-check-input w-5 h-5 bg-gray-200 relative rounded-full text-white right-10 top-[80px]" type="checkbox"
checked={faker?.thumbnail}

onChange={(e) => {
var array = [...images]
const index = _.map(array, "thumbnail").indexOf(true);

// console.log("index>>>", index)

if (index < 0) {
array[fakerKey].thumbnail = e.target.checked;
setImages(array)
} else {
array[index].thumbnail = false;
array[fakerKey].thumbnail = e.target.checked;
setImages(array)
}
// array.filter(person => person.thumbnail === true)
// array[fakerKey].thumbnail = e.target.checked;
// setImages(array)
// console.log("<<<<<",e.target.checked)
// setlatest(e.target.checked)
// onChangeUserArr(e, arr.privilege)
// this.onChangeAllArr(e, arr.privilege);
}}
/>



</div> */}
                                        </>

                                    }
                                </div>
                                <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative">
                                    {/* <Lucide icon="Image" className="w-4 h-4 mr-2" /> */}
                                    <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                                    <span className="text-primary mr-1">
                                        Upload or drag and drop the
                                    </span>{" "}
                                    excel
                                    <input
                                        id="horizontal-form-1"
                                        type="file"
                                        accept={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
                                        className="w-full h-full top-0 left-0 absolute opacity-0"
                                        onChange={(e) => {


                                            let file = e.target.files[0];

                                            // seterrImages(false);

                                            let reader = new FileReader();
                                            reader.readAsDataURL(file);
                                            let fileInp = e.target.files[0];
                                            let baseURL;
                                            // on reader load somthing...
                                            reader.onload = () => {
                                                // Make a fileInfo Object
                                                baseURL = reader.result;
                                                let obj =
                                                {
                                                    path: baseURL,
                                                    thumbnail: false,
                                                    name: file.name
                                                }
                                                    ;

                                                // setImages(images.concat(obj))

                                                setrefundDoc(obj);

                                            };



                                        }}
                                    />
                                </div>

                            </div>
                            <span className="flex justify-end text-xs">(.excel only)</span>

                            <button
                                className={
                                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
w-full h-9 text-slate-50 font-medium mt-8`}

                                onClick={() => {

                                    if (!homeDistrictId2) {
                                        seterrState(true);
                                        return;
                                    }

                                    let obj = {
                                        document: refundDoc,
                                        state: homeDistrictId2
                                    }


                                    uploadExcelDelivery({ obj: obj, userId: currUser.id })
                                        .then((res) => {
                                            console.log("excel>>", res);
                                            addToast("Excel successfully uploaded", "success");
                                            getCurrentUser();
                                            setrefundDoc("");
                                            // setsizes(res.data);
                                            // setsubcat(res.data[0]?.id)
                                            // setLogo(res.data[res.data.length - 1].image)
                                            // this.setState({
                                            //   logo: res.data[res.data.length - 1].image,
                                            // });
                                        })
                                        .catch((error) => {
                                            console.error(error);
                                            addToast(error.response.data.message, "warning");
                                        });



                                }}
                            >
                                {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                                <span className="block  text-sm font-medium "
                                >

                                    Save

                                </span>

                            </button>

                        </div>

                        {/* <div>
                            <Label className="text-sm">Sub Category</Label>
                            <Select className="mt-1.5"
                            // onChange={(e) => {
                            //     setcountry(e.target.value);
                            //     seterrcountry(false);
                            // }}
                            // value={country}
                            >
                                <option value={""}>Select a job type</option>
                                <option value="Stripe">Stripe</option>
                          
                            </Select>
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}


                    </div>

                    <div className="flex justify-center">
                        <div className="flex justify-center font-normal w-full text-center mt-4">
                            <span className="font-semibold">OR</span>

                        </div>



                    </div>


                    <div className="mt-6 bg-gray-50 p-7">

                        <Label className="flex mb-4 font-bold">Individual Uploading</Label>

                        <div>
                            <Label className="text-sm">State</Label>
                            <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                defaultValue={{ name_en: homeDistrict }}
                                value={{ name_en: homeDistrict }}

                                // id="combo-box-demo"
                                options={allHDistricts}
                                getOptionLabel={(option) => option.name_en}
                                onChange={(event, value) => {

                                    handleChangeHdist(event, value);
                                    seterrState(false);
                                    // setCountry(value);
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="bg-gray-100" placeholder="Select State" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            />
                            {/* <Select className="mt-1.5"
                                onChange={(e) => {
                                    setcategory(e.target.value);
                                    // seterrcountry(false);
                                }}
                                value={category}
                            >
                                <option value={""}>All</option>
                                <option value="Seasonal">Seasonal</option>
                                <option value="Casual">Casual</option>
                                <option value="Temporary">Temporary</option>
                                <option value="Fixed">Fixed term contract</option>
                                <option value="Part Time">Permanent – Part Time</option>
                                <option value="Full Time">Permanent – Full time</option>
                           
                            </Select> */}
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>


                        <form className="mt-6 w-full">



                            <div class="relative">
                                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                </div>
                                <Input type="search" id="default-search" className="bg-gray-100 block w-full p-4 pl-10 text-base text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Search Suburb..."
                                    required
                                    onChange={(e) => {
                                        // setsearch(e.target.value);
                                        if (e.target.value) {

                                            let char = lowerCase(e.target.value)

                                            let arr = [...newSubArray.filter((u) => u.state === homeDistrictId)]

                                            arr = arr.filter((data) => lowerCase(data.locality).includes(char));

                                            console.log("arr>>", arr)

                                            setnewSubs(arr);

                                        } else {
                                            setnewSubs(newSubArray.filter((u) => u.state === homeDistrictId))
                                        }
                                    }}
                                />

                            </div>
                        </form>

                        <div className="flex w-full items-center justify-center">
                            <button
                                className={
                                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-12 `}

                                onClick={() => {

                                    let cItem = Object.entries(subsData).map(([k, v]) => ({ [k]: v }));
                                    // console.log("^^^^^^^^^^^", cItem);
                                    var cItem2 = cItem.sort(function (a, b) {
                                        if (Object.keys(a)[0] > Object.keys(b)[0]) return 1;
                                        return -1;
                                    });
                                    console.log("ZXZXZXZX", cItem2);

                                    var newArr = []

                                    const update2 = cItem2?.map((d) => {

                                        if (d[Object.keys(d)[0]] != "none")

                                            newArr.push(d[Object.keys(d)[0]])

                                    });

                                    console.log("newArr>>", newArr)

                                    let obj = {
                                        deliveryInfo: newArr
                                    }

                                    UpdateShippingCost(obj, currUser?.id)
                                        .then((res) => {
                                            addToast("Delivery Details successfully updated ", "success");
                                            getCurrentUser();
                                            // console.log("districts>>>", res);
                                            // setallHDistricts(res.data);
                                            // setallODistricts(res.data);
                                        })
                                        .catch((error) => {
                                            console.log("Error", error.response);
                                            addToast(error.response.data.message, "warning")
                                            // setOpenToast(true);
                                            // setColor(COLORS.Orange);
                                            // setAlertClass("error");
                                            // setMsg(error.response.data.message);
                                        });



                                }}
                            >
                                {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                                <span className="block  text-sm font-medium "
                                >
                                    Save
                                </span>

                            </button>
                        </div>

                    </div>

                    <div className="mt-10 gap-y-8 gap-x-5 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 w-full ">

                        <LoadingSpinner open={lOpen} />
                        {
                            newSubs.map((item, index) => {

                                let id = item.state + item.locality + item.postcode;

                                return (
                                    // <RenderSuburbs item={item} />

                                    <div>
                                        <div className="flex items-start justify-start">



                                            <input id={`${item?.locality}`} className="mt-0.5 w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600  cursor-pointer" type="checkbox"
                                                checked={subsData[id] ? true : false}

                                                onChange={(e) => {
                                                    console.log("<<<<<", e.target.checked)

                                                    if (!e.target.checked) {

                                                        var arr = { ...subsData }
                                                        delete arr[id]
                                                        console.log(arr)
                                                        setsubsData(arr);

                                                    } else {

                                                        var arr = { ...subsData }

                                                        arr[id] = "none";

                                                        setsubsData(arr);

                                                    }

                                                }}
                                            />
                                            <div>
                                                <label
                                                    className="form-check-label ml-2 text-base flex items-start justify-start  cursor-pointer"
                                                    htmlFor={`${item?.locality}`}
                                                >
                                                    {item?.locality}
                                                </label>
                                                <span className="text-xs ml-2">{`(${item?.postcode})`}</span>
                                            </div>

                                        </div>

                                        {
                                            // status &&

                                            subsData[id] &&

                                            <div className="mt-1.5 flex">
                                                <div className="flex">
                                                    <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                                        $
                                                    </span>
                                                    <Input
                                                        className="!rounded-l-none"
                                                        placeholder="Delivery Charge"
                                                        onChange={(e) => {

                                                            if (e.target.value) {

                                                                if (!isNaN(parseFloat(e.target.value))) {

                                                                    let obj = {
                                                                        "state": item?.state,
                                                                        "name": item?.locality,
                                                                        "postcode": item?.postcode,
                                                                        "delCharge": e.target.value,

                                                                    }

                                                                    var arr = { ...subsData }

                                                                    arr[id] = obj;

                                                                    setsubsData(arr);
                                                                }

                                                            } else {

                                                                var arr = { ...subsData }
                                                                delete arr[id]
                                                                console.log(arr)
                                                                setsubsData(arr);

                                                            }

                                                        }}
                                                        value={subsData[id] != "none" ? subsData[id].delCharge : ""}
                                                    />
                                                </div>
                                                {/* <div className="flex items-center">
                                                    <i className="las la-save text-3xl text-left ml-2 text-[#2d867b] cursor-pointer"

                                                        onClick={() => {
                                                            setdelDetails(upChanges);
                                                        }}
                                                    ></i>
                                                </div> */}
                                            </div>
                                        }


                                    </div>
                                );
                            })
                        }

                    </div>

                </div>
            </div>

            <ModalQuickView
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
                selectedJob={selectedJob}
            />

            <ModalQuickView2
                show={showModalQuickView2}
                onCloseModalQuickView={() => setShowModalQuickView2(false)}
                selectedJob={selectedJob}

            />

        </>
    )

}

export default MyJobs;