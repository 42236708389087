import React, { FC, useEffect, useId } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import CardCategory2 from "components/CardCategories/CardCategory2New";
import PopularCategory from "components/CardCategories/PopularCategory";
import CompaniesCard from "components/CardCategories/CompaniesCard";
import JobsCard from "components/CardCategories/JobsCard";
import department1Png from "images/brands/logo-1.png";
import department2Png from "images/brands/logo-2.png";
import department3Png from "images/brands/logo-3.png";
import department4Png from "images/brands/logo-4.png";
import department5Png from "images/brands/logo-5.png";
import department6Png from "images/brands/logo-6.png";
import department7Png from "images/brands/logo-7.png";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { getJobs } from "Services/API/Get";

export interface CardCategoryData {
  name: string;
  desc: string;
  img: string;
  color?: string;
}
const CATS: CardCategoryData[] = [
  {
    name: "Travel Kits",
    desc: "20+ categories",
    img: department1Png,
    color: "bg-indigo-100",
  },
  {
    name: "Beauty Products",
    desc: "10+ categories",
    img: department2Png,
    color: "bg-slate-100",
  },
  {
    name: "Sport Kits",
    desc: "34+ categories",
    img: department3Png,
    color: "bg-sky-100",
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department4Png,
    color: "bg-orange-100",
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department5Png,
    color: "bg-orange-100",
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department6Png,
    color: "bg-orange-100",
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department7Png,
    color: "bg-orange-100",
  },
];
export interface SectionSliderCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  data?: CardCategoryData[];
}

const SectionSliderCategories: FC<SectionSliderCategoriesProps> = ({
  heading = "",
  subHeading = "",
  className = "",
  itemClassName = "py-8 px-2",
  data = CATS,
}) => {
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  const [categories, setcategories] = React.useState<any>(data);
  const [images, setimages] = React.useState<any>([]);

  useEffect(() => {
    if (categories.length > 0) {



      const OPTIONS: Glide.Options = {
        perView: 5,
        autoplay: 3000,
        hoverpause: false,
        type: categories.length > 5 ? 'carousel' : 'slider',
        // gap: 32,
        bound: true,
        breakpoints: {
          1280: {
            perView: 5 - 1,
            type: categories.length > 4 ? 'carousel' : 'slider',
          },
          1024: {
            gap: 20,
            perView: 5 - 1,
            type: categories.length > 4 ? 'carousel' : 'slider',
          },
          768: {
            gap: 20,
            perView: 4 - 1,
            type: categories.length > 3 ? 'carousel' : 'slider',
          },
          640: {
            gap: 20,
            perView: 2,
            type: categories.length > 2 ? 'carousel' : 'slider',
          },
          500: {
            gap: 8,
            perView: 2,
            type: categories.length > 2 ? 'carousel' : 'slider',
          },
        },
      };

      let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
      slider.mount();
      // @ts-ignore
      return () => slider.destroy();

    }
  }, [UNIQUE_CLASS, categories]);



  React.useEffect(() => {

    getallCategory();

  }, []);

  const getallCategory = async () => {

    getJobs({
      jobType: JSON.stringify([]),
      userId: "",
      title: "",
      // categories: selectedCat === "" ? JSON.stringify([]) : JSON.stringify([selectedCat]),
      // fromHourlyRate: minPrice,
      // toHourlyRate: maxPrice,
      page: "1",
      // limit: "5",
      state: "",
      suburb: "",
      sortValue: "desc",
      sortField: "id",
    })
      .then(async (response) => {
        console.log("jobshome>>>>", response?.data.result);

        setcategories(response.data.result);


      })
      .catch(() => {

        setcategories([])

      });

  }

  return (
    <>
      {
        categories.length > 0 &&

        <div className={`nc-SectionSliderCategories ${className} relative z-[1] `}>
          <div className={`${UNIQUE_CLASS} flow-root`}>
            {/* <Heading desc={subHeading} hasNextPrev isCenter={true}>
            {heading}
          </Heading> */}
            <div className="glide__track" data-glide-el="track">
              <ul className="glide__slides">
                {categories.map((item: any, index: any) => (
                  <li key={index} className={`glide__slide ${itemClassName}`}>
                    <JobsCard
                      collection
                      data={item}
                    // featuredImage={item.img}
                    // name={item.name}
                    // desc={item.desc}
                    // bgClass={item.color}
                    />
                  </li>
                ))}
                {/* <li className={`glide__slide ${itemClassName}`}>
            <div
              className={`flex-1 relative w-full h-0 rounded-2xl overflow-hidden group aspect-w-1 aspect-h-1 bg-slate-100`}
            >
              <div>
                <div className="absolute inset-y-6 inset-x-10 flex flex-col sm:items-center justify-center">
                  <div className="flex relative text-slate-900">
                    <span className="text-base font-semibold ">
                      More collections
                    </span>
                    <svg
                      className="absolute left-full w-5 h-5 ml-2 rotate-45 group-hover:scale-110 transition-transform"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.0701 9.57L12.0001 3.5L5.93005 9.57"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M12 20.4999V3.66992"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <span className="text-sm mt-1 text-slate-800">
                    Show me more
                  </span>
                </div>
              </div>
              <Link
                to={"/"}
                className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"
              ></Link>
            </div>
          </li> */}
              </ul>
            </div>
            {/* <div className="flex w-full mt-4 items-center justify-center">
          <button
            className={
              `ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#002930]
      w-3xl px-6 h-9 text-slate-50 font-medium mt-2 cursor-pointer hover:bg-slate-800`}

            onClick={() => {
              console.log(">>>>>", "hello")
            }}

          >

            <span className="block  text-sm font-medium">
              See more
            </span>

          </button>
        </div> */}
            {/* <NavLink to={"/events"}>
            <div className="flex mt-4 justify-center items-center">
              <ButtonPrimary>See more</ButtonPrimary>
            </div>
          </NavLink> */}
          </div>
        </div>

      }

      {/* <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      /> */}
    </>
  );
};

export default SectionSliderCategories;
