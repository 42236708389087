import React, { FC, useState, useEffect, useRef } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL } from "Constant/System";
import _ from "lodash";
import { addToast } from "shared/Toast";
import { CreateMain, CreateSize, CreateSubCategory, postBlogs, postJobs } from "Services/API/Post";
import { GetAllUnits, GetUserId } from "Services/API/Get";
import { Autocomplete } from "@mui/material";
import { COLORS } from "Constant/Colors";


const Blogs = (props) => {

    const [images, setImages] = useState([]);

    const [title, settitle] = useState("");
    const [blogType, setblogType] = useState("");
    const [description, setdescription] = useState("");
    const [Keywords, setKeywords] = useState("");

    const [errimages, seterrImages] = useState([]);

    const [errtitle, seterrtitle] = useState(false);
    const [errUnit, seterrUnit] = useState(false);


    const [errblogType, seterrblogType] = useState(false);
    const [errdescription, seterrdescription] = useState(false);
    const [errKeywords, seterrKeywords] = useState(false);

    const [allUnits, setallUnits] = useState([]);

    const [selectedUnit, setselectedUnit] = useState(props?.type ? props?.type : "");


    const getAllUnits = () => {

        GetAllUnits()
            .then((res) => {
                console.log("all unit>>", res.data);
                setallUnits(res.data)

                // setsizes(res.data);
                // setsubcat(res.data[0]?.id)
                // setLogo(res.data[res.data.length - 1].image)
                // this.setState({
                //   logo: res.data[res.data.length - 1].image,
                // });
            })
            .catch((error) => {
                console.error(error);
                setallUnits([]);
            });

    }
    useEffect(() => {
        // CategoryGet();
        // SubCategoryGet();

        getAllUnits();

    }, [])


    const saveBlog = (e) => {

        e.preventDefault();

        if (!title) {
            seterrtitle(true);
        }

        if (!selectedUnit) {
            seterrUnit(true);
        }

        // if (!blogType) {
        //     seterrblogType(true);
        // }

        // if (!description) {
        //     seterrdescription(true);
        // }

        // if (!Keywords) {
        //     seterrKeywords(true);
        // }
        // if (images.length === 0) {
        //     seterrImages(true);
        // }

        // console.log(">>>>>>>>", _.filter(Keywords.split(" ")))

        // console.log(">>>>>>", props?.type)



        let obj = {
            measurement: title,
            userId: GetUserId(),
            unit: selectedUnit.id,
        }

        console.log("obj>>>>", obj)



        if (
            title &&
            selectedUnit
            // description &&
            // Keywords &&
            // blogType
            // images.length > 0
        ) {


            CreateSize({ obj })
                .then((res) => {
                    // setlopen(false);
                    addToast("Size successfully added", "success");
                    setselectedUnit("");
                    settitle("");
                    setdescription("");
                    setblogType("");
                    setImages([]);
                    setKeywords([]);
                    // props?.onCloseModalQuickView();

                    // setOpenToast(true);
                    // setColor(COLORS.Primary);
                    // setAlertClass("success");
                    // setMsg("Successfully updated");
                    // getCurrentUser();
                    // seteDisable(true);
                    // getUser();

                    // history.push("/");
                    // window.location.reload();
                })
                .catch((error) => {
                    // setlopen(false);
                    console.log("Error", error.response);
                    addToast(error.response.data.message, "warning");
                    // setOpenToast(true);
                    // setColor(COLORS.Orange);
                    // setAlertClass("error");
                    // setMsg(error.response.data.message);
                })



        }


    }




    return (
        <>
            <div className="flex justify-center w-full mt-2">

                <span className="block text-xl font-bold flex justify-center w-full text-center">
                    {
                        "Create Size"
                    }

                </span>


            </div>
            <div className="w-full flex justify-center px-3">

                <div className="w-full md:w-11/12 lg:w-8/12">

                    <div className="w-full mt-6 space-y-4">

                        {/* <div>
                            <span className="flex mt-4 text-sm">Select Unit</span>
                            <Autocomplete
                                // multiple
                                size="small"
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                // value={selectedSizes}
                                defaultValue={{ unit: selectedUnit ? selectedUnit.unit : "", id: selectedUnit ? selectedUnit.id : "" }}
                                value={{ unit: selectedUnit ? selectedUnit.unit : "", id: selectedUnit ? selectedUnit.id : "" }}
                                options={[{ unit: "None", id: 0 }, ...allUnits]}

                                getOptionLabel={(option) => option.unit}
                                onChange={(event, newValue) => {

                                    if (newValue !== null) {
                                        // setselectedUnit(newValue.id)
                                        // setselectedUnits(newValue);

                                        setselectedUnit(newValue);

                                        // setselectedSizes([]);
                                        // seterrcat(false);
                                    } else {
                                        // setselectedUnit(0);
                                        // setselectedSizes([]);
                                        // seterrcat(false);
                                    }

                                }}
                                sx={{
                                    // width: "80vw",
                                    background: "transparent",
                                    borderRadius: "20px",
                                    // border: "none",
                                    // borderColor: "transparent",

                                    "& .MuiInputBase-input": {
                                        height: 15,
                                        // border: "1px #873e23",
                                        fontSize: "14px",
                                        fontFamily: "'Avenir', sans-serif",
                                        // borderColor: "#873e23",

                                    },

                                    //view
                                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                        // borderColor: "transparent",
                                        borderColor: "#d4d4d4",
                                    },

                                    "&:hover .MuiOutlinedInput-input": {
                                        color: "black",
                                        borderColor: "#d4d4d4",
                                    },
                                    "&:hover .MuiInputLabel-root": {
                                        color: COLORS.GrayBackground,
                                        borderColor: "#d4d4d4",
                                    },
                                    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                    {
                                        borderColor: "#d4d4d4",
                                    },
                                    ".MuiAutocomplete-root fieldset:hover": {
                                        color: "transparent",
                                        borderColor: "transparent",
                                    },
                                }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Select Unit" />
                                    </div>
                                )}
                                // renderInput={(params) => (
                                //     <TextField
                                //         {...params}

                                //     />

                                // )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            />
                            {errUnit && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}

                        <div>
                            <Label className="text-sm">Size</Label>
                            <Input className="mt-1.5"
                                placeholder="Enter Size"
                                onChange={(e) => {
                                    settitle(e.target.value);
                                    seterrtitle(false);
                                }}
                                value={title}
                            />
                            {errtitle && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>


                        <div className="flex w-full items-center justify-center">
                            <button
                                className={
                                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-4 `}

                                onClick={saveBlog}
                            >
                                {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                                <span className="block  text-sm font-medium "
                                >
                                    Save & add another
                                </span>

                            </button>
                        </div>

                    </div>

                </div>
            </div>

        </>
    )

}

export default Blogs;