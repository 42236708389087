import React, { FC, useContext, useEffect, useRef, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import { MinusIcon, PlusIcon, StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { PRODUCTS } from "data/data";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import NotifyAddTocart from "./NotifyAddTocart";
import { Link, useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import Label from "./Label/Label";
import Select from "shared/Select/Select";
import { IMAGE_SERVER_URL } from "Constant/System";
import { GetUserId, getFAQs } from "Services/API/Get";
import { Disclosure } from "@headlessui/react";
import { postFaq } from "Services/API/Post";
import { addToast } from "shared/Toast";
import { DeleteFAQ } from "Services/API/Delete";
import { UpdateFAQs } from "Services/API/Put";
import BotToast from "shared/BotToast";
import AuthContext from "Context/AuthContext";


export interface ProductQuickView2Props {
  className?: string;
  getAllFaqs?: any;
  modal?: any;
  setselectedTab?: any;
}

const ProductQuickView2: FC<ProductQuickView2Props> = ({ className = "", getAllFaqs, modal = false, setselectedTab }) => {
  const { sizes, variants, status, allOfSizes } = PRODUCTS[0];
  const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];

  const [variantActive, setVariantActive] = React.useState(0);
  const [sizeSelected, setSizeSelected] = React.useState(sizes ? sizes[0] : "");
  const [qualitySelected, setQualitySelected] = React.useState(1);

  const [value, setValue] = React.useState<any>(0);
  const [firstName, setfirstName] = useState("");
  const [errfirstName, seterrfirstName] = useState(false);

  const [images, setImages] = useState([])

  const [faqs, setfaqs] = useState([])

  const [question, setquestion] = useState("");
  const [answer, setanswer] = useState("");

  const [errquestion, seterrquestion] = useState(false);
  const [erranswer, seterranswer] = useState(false);

  const [upState, setupState] = useState(false);
  const [faqId, setfaqId] = useState(0);

  const [bOpen, setbOpen] = useState(false);
  const [bMsg, setbMsg] = useState("");
  const [bStatus, setbStatus] = useState("");

  const ref: any = useRef(null);

  const handleScroll = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: "center", });
  };

  const history = useHistory();

  const { user, setUser } = useContext(AuthContext);


  const notifyAddTocart = () => {
    toast.custom(
      (t) => (
        <NotifyAddTocart
          productImage={LIST_IMAGES_DEMO[0]}
          qualitySelected={qualitySelected}
          show={t.visible}
          sizeSelected={sizeSelected}
          variantActive={variantActive}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const getFAQList = () => {
    getFAQs()
      .then((res) => {
        console.log(">>>>>faqs", res.data);
        setfaqs(res.data);

        // setTimeout(() => {
        //   history.push("/account");
        //   window.location.reload();
        // }, 2000);

      })
      .catch((error) => {
        // setlopen(false);
        console.log("Error", error.response);
        setfaqs([]);
        // setlopen(false);
        // addToast(error.response.data.message, { appearance: "warning", autoDismiss: true });
      });

  }

  useEffect(() => {
    getFAQList();
  }, [])

  const postFAQs = () => {


    let obj = {
      question: question,
      answer: answer,
      userId: GetUserId()
    }

    if (!question) {
      seterrquestion(true);
    }

    if (!answer) {
      seterranswer(true);
    }

    if (question && answer) {
      postFaq(obj)
        .then((res) => {
          console.log(">>>>>faqs", res);

          setbOpen(true);
          setbMsg("FAQ successfully added");
          setbStatus("success");

          setquestion("");
          setanswer("");

          // addToast("FAQ successfully added", "success");
          getFAQList();
          getAllFaqs();


          // setTimeout(() => {
          //   history.push("/account");
          //   window.location.reload();
          // }, 2000);

        })
        .catch((error) => {
          // setlopen(false);
          console.log("Error", error.response);

          setbOpen(true);
          setbMsg(error.response.data.message);
          setbStatus("warning");

          // setlopen(false);
          // addToast(error.response.data.message, { appearance: "warning", autoDismiss: true });
        });

    }



  }

  const editFAQs = () => {


    let obj = {
      question: question,
      answer: answer,
      userId: GetUserId()
    }

    if (!question) {
      seterrquestion(true);
    }

    if (!answer) {
      seterranswer(true);
    }

    if (question && answer) {
      UpdateFAQs(obj, faqId)
        .then((res) => {
          console.log(">>>>>faqs", res);
          // addToast("FAQ successfully added", "success");

          setbOpen(true);
          setbMsg("FAQ successfully updated ");
          setbStatus("success");

          setquestion("");
          setanswer("");

          getFAQList();
          getAllFaqs();

          setupState(false);

          // setTimeout(() => {
          //   history.push("/account");
          //   window.location.reload();
          // }, 2000);

        })
        .catch((error) => {
          // setlopen(false);
          console.log("Error", error.response);
          setupState(false);

          setbOpen(true);
          setbMsg(error.response.data.message);
          setbStatus("warning");

          // setlopen(false);
          // addToast(error.response.data.message, { appearance: "warning", autoDismiss: true });
        });

    }



  }

  useEffect(() => {
    if (bOpen === true) {
      setTimeout(() => {

        setbOpen(false)

      }, 10000);
    }
  }, [bOpen])


  return (
    <div className={`nc-ProductQuickView2 ${className} mt-16 sm:mt-0 md:px-32 py-6`}>
      {/* MAIn */}
      <div className="lg:flex">
        {/* CONTENT */}
        <div className="w-full ">
          {/* HEADING */}

          <div className="w-full flex justify-center px-3">

            <div className="w-full">
              <div className="flex justify-center w-full mt-0">

                <span className="block text-xl font-bold flex justify-center w-full text-center">
                  Create FAQ s
                </span>


              </div>

              <div className="w-full justify-center flex">
                <div className={`w-full ${modal ? "md:w-11/12 lg:w-full" : "md:w-9/12 lg:w-7/12 xl:w-[45%]"} mt-6 space-y-4 `}>

                  <div ref={ref}>
                    <Label className="text-sm">Question</Label>
                    <Input className="mt-1.5"
                      placeholder="Enter question"

                      onChange={(e) => {
                        setquestion(e.target.value);
                        seterrquestion(false);
                      }}
                      value={question}
                    />
                    {errquestion && (
                      <div className="text-red-600 text-sm text-right mt-2">
                        This field is required
                      </div>
                    )}
                  </div>

                  <div>
                    <Label className="text-sm">Answer</Label>
                    <Textarea className="mt-1.5" rows={4}
                      placeholder="Enter answer"
                      onChange={(e) => {
                        setanswer(e.target.value);
                        seterranswer(false);
                      }}
                      value={answer}
                    />
                    {erranswer && (
                      <div className="text-red-600 text-sm text-right mt-2">
                        This field is required
                      </div>
                    )}
                  </div>

                  <div className="w-full items-center justify-center ">

                    <BotToast open={bOpen} msg={bMsg} status={bStatus} />

                    <button
                      className={
                        `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 border border-[#2d867b] text-[#2d867b] hover:from-pink-500 hover:to-yellow-500 
w-full h-9 text-slate-50 font-medium mt-4 `}
                      onClick={!upState ? postFAQs : editFAQs}

                    >
                      {/* <i className="las la-pencil-alt text-xl text-left mr-2"></i> */}
                      <span className="block  text-sm font-medium "
                      >

                        {upState ? "Update" : "Save and add another"}
                      </span>

                    </button>

                    {
                      upState &&

                      <button
                        className={
                          `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 border border-[#2d867b] text-[#2d867b] hover:from-pink-500 hover:to-yellow-500 
w-full h-9 text-slate-50 font-medium mt-4 `}
                        onClick={() => {
                          setupState(false);
                          setquestion("");
                          setanswer("");
                        }}

                      >
                        {/* <i className="las la-pencil-alt text-xl text-left mr-2"></i> */}
                        <span className="block  text-sm font-medium "
                        >

                          Cancel
                        </span>

                      </button>

                    }




                    <div className="flex justify-center w-full mt-12 ">
                      <div className="w-full rounded-2xl space-y-2.5">
                        {/* ============ */}
                        {faqs.map((item: any, index: any) => {
                          return (
                            <Disclosure key={index} defaultOpen={index < 1}>
                              {({ open }) => (
                                <>
                                  <div className="flex items-center">
                                    <Disclosure.Button className="flex text-sm items-center justify-between w-full px-4 py-2 font-bold text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 "

                                    >
                                      <span>{item.question}</span>
                                      {!open ? (
                                        <PlusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                                      ) : (
                                        <MinusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                                      )}
                                    </Disclosure.Button>
                                    <i className="las la-pencil-alt text-xl text-left ml-2 cursor-pointer"
                                      onClick={() => {
                                        setquestion(item.question);
                                        setanswer(item.answer);
                                        setupState(true);
                                        setfaqId(item.id);
                                        handleScroll();
                                      }}
                                    ></i>
                                    <i className="las la-trash-alt text-xl text-left ml-2 text-red-500 cursor-pointer"
                                      onClick={() => {
                                        DeleteFAQ(item.id)
                                          .then((res) => {
                                            console.log(">>>>>faqs", res);
                                            addToast("FAQ successfully deleted", "success");
                                            getFAQList();
                                            getAllFaqs();


                                            // setTimeout(() => {
                                            //   history.push("/account");
                                            //   window.location.reload();
                                            // }, 2000);

                                          })
                                          .catch((error) => {
                                            // setlopen(false);
                                            console.log("Error", error.response);

                                            // setlopen(false);
                                            addToast(error.response.data.message, { appearance: "warning", autoDismiss: true });
                                          });
                                      }}
                                    ></i>
                                  </div>
                                  <Disclosure.Panel
                                    className={"p-4 pt-3 last:pb-0  text-sm dark:text-slate-300 leading-6"}
                                    as="div"
                                    dangerouslySetInnerHTML={{ __html: item.answer }}
                                  ></Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          );
                        })}

                        {/* ============ */}
                      </div>

                    </div>

                    <div className="w-full items-center justify-center ">


                      <button
                        className={
                          `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b] 
w-full h-9 text-slate-50 font-medium mt-4 `}
                        onClick={() => {
                          if (user?.userType === "service provider") {
                            setselectedTab(20);
                          } else {
                            setselectedTab(6);
                          }

                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });

                        }}

                      >
                        {/* <i className="las la-pencil-alt text-xl text-left mr-2"></i> */}
                        <span className="block  text-sm font-medium "
                        >

                          Save and next
                        </span>

                      </button>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>


        </div>


      </div>
    </div >
  );
};

export default ProductQuickView2;
