import React, { FC, useState, useEffect, useRef } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL, IMAGE_SERVER_URL_DEV, SERVER_URL_DEV } from "Constant/System";
import Company1 from "images/avatars/Image-1.png";
import ModalQuickView from "components/ModalProduct";
import { GetAllSubsforMain, GetMaincategory, getAllProducts } from "Services/API/Get";
import { Button, Grid, TextField, Typography, Autocomplete } from "@mui/material";
import Empty from "images/empty.png"
import { addToast } from "shared/Toast";
import { DeleteProduct } from "Services/API/Delete";
import Pagination from "shared/Pagination/PaginationNew";
import { UpdateProductSub } from "Services/API/Put";


const Stocks = () => {

    const [images, setImages] = useState([]);

    const [showModalQuickView, setShowModalQuickView] = useState(false);

    const [role, setrole] = useState({ maincategory: "All", mainId: "" });

    const [roles, setroles] = useState("");

    const [search, setsearch] = useState("");
    const [subcat, setsubcat] = useState({ name: "All", id: "" });
    const [subcategories, setsubcategories] = useState([]);
    const [users, setusers] = useState([]);
    const [SelectedProduct, setSelectedProduct] = useState("");

    const [hire, sethire] = useState({ name: "All", hire: "" });

    const [dataCount, setcount] = useState(0);
    const [pageLimit, setpageLimit] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);

    const [sortField, setsortField] = useState("productId")

    const [sort, setsort] = useState("asc")


    const RolesGet = () => {
        GetMaincategory({
            privateStore: ""
        })
            .then((res) => {
                console.log("cat>>", res.data);

                setroles(res.data);

                // setLogo(res.data[res.data.length - 1].image)
                // this.setState({
                //   logo: res.data[res.data.length - 1].image,
                // });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        RolesGet();
    }, [])

    const getSubsforMain = () => {

        GetAllSubsforMain(role?.mainId)
            .then((res) => {
                console.log("sub cats>>", res.data);

                setsubcategories(res.data);
                // setsubcat(res.data[0]?.id)

                // const newArr = res.data?.map((m, index) => {

                //   if (m.subId) {
                //     return m.subId;
                //   }

                // })

                // console.log("sub cats22222>>", _.compact(newArr));

                // setselectedSubs(_.compact(newArr));

                // setusers(res.data);

                // setLogo(res.data[res.data.length - 1].image)
                // this.setState({
                //   logo: res.data[res.data.length - 1].image,
                // });
            })
            .catch((error) => {
                console.error(error);
                setsubcategories([])
                // setselectedSubs([]);
                // setsubcat("");
                // setusers([])
            });
    }


    useEffect(() => {

        getSubsforMain()

        console.log(">>>>>", role)

    }, [role])

    const GetUsers = () => {
        getAllProducts({
            sort: {
                sortField: sortField,
                sort: sort,
            },
            categories: role?.mainId !== "" ? JSON.stringify([role?.mainId]) : JSON.stringify([]),
            // isActive: status?.value,
            subId: subcat?.id !== "" ? JSON.stringify([subcat?.id]) : JSON.stringify([]),
            search: search,
            distinct: 0,
            isActive: "",
            hire: hire.hire,
            pNo: currentPage.toString(),
            limit: pageLimit.toString(),
        })
            .then((res) => {
                console.log("products>>>", res.data);
                setusers(res.data.result)

                setcount(res.data.count[0].count);




            })
            .catch((error) => {
                console.log("Error", error.response);
                setusers([])
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });
    };

    React.useEffect(() => {
        GetUsers();

        // let obj = {
        //     role: role,
        //     status: status,
        //     subcat: subcat,
        //     search: search
        // }

        // localStorage.setItem("prodFilters", JSON.stringify(obj))
        // localStorage.setItem("prodFilterPage", 1)
        // localStorage.setItem("prodFilterSort", JSON.stringify({}))


    }, [role, search, subcat, showModalQuickView, hire, pageLimit, currentPage, sortField, sort]);

    const SubCatUp = ({ item }) => {



        const [subcategories, setsubcategories] = useState([]);

        const [subcat, setsubcat] = useState(item ? item?.subId : "");


        useEffect(() => {
            GetAllSubsforMain(item?.mainId, item?.hire === 1 ? 1 : 0)
                .then((res) => {
                    console.log("sub cats>>", res.data);

                    setsubcategories(res.data);
                    // if (!product)
                    //     setsubcat(res.data[0]?.id)

                    // const newArr = res.data?.map((m, index) => {

                    //   if (m.subId) {
                    //     return m.subId;
                    //   }

                    // })

                    // console.log("sub cats22222>>", _.compact(newArr));

                    // setselectedSubs(_.compact(newArr));

                    // setusers(res.data);

                    // setLogo(res.data[res.data.length - 1].image)
                    // this.setState({
                    //   logo: res.data[res.data.length - 1].image,
                    // });
                })
                .catch((error) => {
                    console.error(error);
                    setsubcategories([])
                    // setselectedSubs([]);
                    // setsubcat("");
                    // setusers([])
                });
        }, [])



        return (
            <>

                <Autocomplete
                    className=""
                    // className="form-group"
                    // className="border-neutral-200"
                    defaultValue={{ name: subcategories.find(({ id }) => id === subcat)?.name ? subcategories.find(({ id }) => id === subcat)?.name : "" }}
                    value={{ name: subcategories.find(({ id }) => id === subcat)?.name ? subcategories.find(({ id }) => id === subcat)?.name : "" }}
                    options={subcategories}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {

                        if (newValue !== null) {
                            // setsubcat(newValue?.id)
                            // seterrcat(false);
                            let obj = {

                                subId: newValue?.id

                            }
                            UpdateProductSub(obj, item?.productId)
                                .then((res) => {

                                    // addToast("Product successfully deleted", "success");
                                    GetUsers();
                                })
                                .catch((error) => {
                                    // setlopen(false);
                                    console.log("Error", error.response);
                                    addToast(error.response.data.message, "warning");
                                    // setOpenToast(true);
                                    // setColor(COLORS.Orange);
                                    // setAlertClass("error");
                                    // setMsg(error.response.data.message);
                                })
                        } else {
                            // setsubcat("");
                            // seterrcat(false);
                        }


                        // setsubcat2("");
                        // setsubcat3("");

                    }}
                    // sx={{
                    //   // width: "80vw",
                    //   background:"transparent",
                    //   borderRadius: "20px",
                    //   // border: "none",
                    //   // borderColor: "transparent",

                    //   "& .MuiInputBase-input": {
                    //     height: 15,
                    //     // border: "none",
                    //     fontSize: "14px",
                    //     fontFamily: "'Avenir', sans-serif",
                    //     // borderColor: "transparent",
                    //   },
                    //   //view
                    //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    //     // borderColor: "transparent",
                    //   },

                    //   "&:hover .MuiOutlinedInput-input": {
                    //     color: "black",
                    //   },
                    //   "&:hover .MuiInputLabel-root": {
                    //     color: COLORS.GrayBackground,
                    //   },
                    //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    //   {
                    //     // borderColor: "transparent",
                    //   },
                    //   ".MuiAutocomplete-root fieldset:hover": {
                    //     color: "transparent",
                    //     // borderColor: "transparent",
                    //   },
                    // }}
                    renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                            <Input {...params.inputProps} className="text-xs" placeholder="Select Sub Category" />
                        </div>
                    )}
                    ListboxProps={{
                        style: {
                            maxHeight: "150px",
                            fontSize: "14px",
                            // border: '1px solid white'
                        },
                    }}
                />
            </>
        );

    }

    return (
        <>
            <ModalQuickView
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
                SelectedProduct={SelectedProduct}
            />
            <div className="flex justify-center w-full mt-12">

                <span className="block text-3xl font-bold flex justify-center w-full text-center">
                    Current Stocks
                </span>


            </div>
            <div className="w-full flex justify-center px-3">

                <div className="w-full md:w-9/12 lg:w-11/12 xl:w-[65%]">
                    {/* <div className="flex w-full items-center justify-center">
                        <button
                            className={
                                `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-gradient-to-r from-cyan-500 from-10% to-[#3c9140] to-90% hover:from-pink-500 hover:to-yellow-500 
        w-full h-9 text-slate-50 font-medium mt-12 `}

                            onClick={() => { setShowModalQuickView(true) }}


                        >
                           
                            <span className="block  text-sm font-medium "
                            >
                                Add product
                            </span>

                        </button>
                    </div> */}
                    <form className="mt-6 w-full">

                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                            </div>

                            <Input type="search" id="default-search" className="d-block w-full p-4 pl-10 text-base text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Name..." required
                                value={search}
                                onChange={(e) => {
                                    setsearch(e.target.value)

                                }}
                            />

                        </div>
                    </form>

                    <div className="w-full mt-4 space-y-4">

                        <div>
                            <Label className="text-sm">Product Type</Label>
                            <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                defaultValue={{ name: hire?.name }}
                                value={{ name: hire?.name }}
                                options={[{ name: "All", hire: "" }, { name: "Sell Product", hire: "0" }, { name: "Hire Product", hire: "1" }]}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) => {
                                    if (newValue !== null) {
                                        sethire(newValue);

                                        setsearch("");
                                    } else {
                                        sethire({ name: "All", hire: "" })

                                        setsearch("");
                                    }
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Select Product Type" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            />
                            {/* <Select className="mt-1.5"
                            // onChange={(e) => {
                            //     setcountry(e.target.value);
                            //     seterrcountry(false);
                            // }}
                            // value={country}
                            >
                                <option value={""}>Select a job type</option>
                                <option value="Stripe">Stripe</option>
                         
                            </Select> */}
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>

                        <div>
                            <Label className="text-sm">Category</Label>
                            <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                defaultValue={{ maincategory: role?.maincategory }}
                                value={{ maincategory: role?.maincategory }}
                                options={[{ maincategory: "All", mainId: "" }, ...roles]}
                                getOptionLabel={(option) => option.maincategory}
                                onChange={(event, newValue) => {
                                    if (newValue !== null) {
                                        setrole(newValue);
                                        setsubcat({ name: "All", id: "" });
                                        setsearch("");
                                    } else {
                                        setrole({ maincategory: "All", mainId: "" })
                                        setsubcat({ name: "All", id: "" });
                                        setsearch("");
                                    }
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Select Main Category" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            />
                            {/* <Select className="mt-1.5"
                            // onChange={(e) => {
                            //     setcountry(e.target.value);
                            //     seterrcountry(false);
                            // }}
                            // value={country}
                            >
                                <option value={""}>Select a job type</option>
                                <option value="Stripe">Stripe</option>
                         
                            </Select> */}
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">Sub Category</Label>
                            <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                defaultValue={{ name: subcat?.name }}
                                value={{ name: subcat?.name }}
                                options={[{ name: "All", id: "" }, ...subcategories]}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) => {
                                    if (newValue !== null) {
                                        setsubcat(newValue);
                                    } else {
                                        setsubcat({ name: "All", id: "" })
                                    }
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Select Sub Category" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            />
                            {/* <Select className="mt-1.5"
                            // onChange={(e) => {
                            //     setcountry(e.target.value);
                            //     seterrcountry(false);
                            // }}
                            // value={country}
                            >
                                <option value={""}>Select a job type</option>
                                <option value="Stripe">Stripe</option>
                              
                            </Select> */}
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>


                    </div>
                    <div className="mt-10 space-y-2">
                        <div className="flex">
                            <div className="bg-gray-100 flex w-full px-4 py-3 rounded-lg space-x-4 h-full">
                                <div className="flex justify-start w-4/12 items-center">
                                    {/* <div className="w-12 h-12 object-fill bg-contain rounded-full border-2 border-white"
                                        style={{
                                            backgroundImage: JSON.parse(item.images).filter((item) => item?.thumbnail === true).length > 0 ? `url(${IMAGE_SERVER_URL_DEV}${JSON.parse(item.images).filter((item) => item?.thumbnail === true)[0]?.path})` : `url(${Empty})`
                                        }}
                                    >

                                    </div> */}

                                </div>
                                <div className="flex justify-start w-6/12 items-center">
                                    <div className="flex cursor-pointer"
                                        onClick={() => {


                                            if (sort === "asc" && sortField != "productName") {
                                                setsortField("productName");
                                                setsort("asc")
                                            } else if (sort === "asc" && sortField === "productName") {
                                                setsortField("productName");
                                                setsort("desc")
                                            } else if (sort === "desc" && sortField === "productName") {
                                                setsortField("productName");
                                                setsort("asc")
                                            }
                                        }}
                                    >
                                        <div className="text-sm font-semibold flex justify-start items-center">Name</div>

                                        <div className="ml-2 cursor-pointer">

                                            {
                                                sortField != "productName" ?

                                                    <i className="las la-arrows-alt-v"></i>

                                                    : (sortField === "productName" && sort === "asc") ?

                                                        <i className="las la-arrow-down" ></i>

                                                        : (sortField === "productName" && sort === "desc") ?

                                                            <i className="las la-arrow-up"></i>

                                                            : <></>

                                            }



                                        </div>
                                        {/* <div className="text-sm font-medium flex justify-start items-center">{item?.country}</div> */}
                                    </div>
                                </div>


                                <div className="w-6/12 flex justify-start items-center">
                                    <div>
                                        <div className="text-sm font-semibold flex justify-start items-center">Id</div>

                                        {/* <div className="text-sm font-medium flex justify-start items-center">{item?.country}</div> */}
                                    </div>
                                </div>
                                <div className="w-6/12 flex justify-start items-center">
                                    <div className="flex cursor-pointer"
                                        onClick={() => {


                                            if (sort === "asc" && sortField != "mainId") {
                                                setsortField("mainId");
                                                setsort("asc")
                                            } else if (sort === "asc" && sortField === "mainId") {
                                                setsortField("mainId");
                                                setsort("desc")
                                            } else if (sort === "desc" && sortField === "mainId") {
                                                setsortField("mainId");
                                                setsort("asc")
                                            }
                                        }}
                                    >
                                        <div className="text-sm font-semibold flex justify-start items-center">Main Category</div>

                                        <div className="ml-2 cursor-pointer">

                                            {
                                                sortField != "mainId" ?

                                                    <i className="las la-arrows-alt-v"></i>

                                                    : (sortField === "mainId" && sort === "asc") ?

                                                        <i className="las la-arrow-down" ></i>

                                                        : (sortField === "mainId" && sort === "desc") ?

                                                            <i className="las la-arrow-up"></i>

                                                            : <></>

                                            }



                                        </div>

                                        {/* <div className="text-sm font-medium flex justify-start items-center">{item?.country}</div> */}
                                    </div>
                                </div>

                                <div className="w-8/12 flex justify-start items-center">
                                    <div>
                                        <div className="text-sm font-semibold flex justify-start items-center">Sub Category</div>

                                        {/* <div className="text-sm font-medium flex justify-start items-center">{item?.country}</div> */}
                                    </div>
                                </div>


                                <div className="w-6/12 flex justify-start items-center">
                                    <div>
                                        <div className="text-sm font-semibold flex justify-start items-center">Available Stock</div>

                                        {/* <div className="text-sm font-medium flex justify-start items-center">{item?.availableStocks}</div> */}
                                    </div>
                                </div>


                            </div>
                            <div className="flex items-center">
                                <i className="las la-pencil-alt text-xl text-left ml-2 cursor-pointer text-transparent"

                                ></i>
                                <i className="las la-trash-alt text-xl text-left ml-2 text-transparent cursor-pointer"

                                ></i>
                            </div>

                        </div>
                        {
                            users.map((item, index) => {
                                return (
                                    <>
                                        <div className="flex">
                                            <div className="bg-gray-100 flex w-full px-4 py-3 rounded-lg space-x-4 h-full">
                                                <div className="flex justify-start w-4/12 items-center">
                                                    <div className="w-12 h-12 object-fill bg-contain rounded-full border-2 border-white"
                                                        style={{
                                                            backgroundImage:
                                                                JSON.parse(item.images).length === 1 ?

                                                                    `url(${IMAGE_SERVER_URL_DEV}${JSON.parse(item.images)[0]?.path})`
                                                                    :
                                                                    JSON.parse(item.images).filter((item) => item?.thumbnail === true).length > 0 ?
                                                                        `url(${IMAGE_SERVER_URL_DEV}${JSON.parse(item.images).filter((item) => item?.thumbnail === true)[0]?.path})`
                                                                        : `url(${Empty})`
                                                        }}
                                                    >

                                                    </div>

                                                </div>
                                                <div className="flex justify-start w-6/12 items-center">
                                                    <div>
                                                        <div className="text-sm font-medium flex justify-start items-center">{item?.productName}</div>
                                                        {/* <div className="text-sm font-medium flex justify-start items-center">{item?.country}</div> */}
                                                    </div>
                                                </div>


                                                <div className="w-6/12 flex justify-start items-center">
                                                    <div>
                                                        <div className="text-sm font-medium flex justify-start items-center">{item?.productId}</div>

                                                        {/* <div className="text-sm font-medium flex justify-start items-center">{item?.country}</div> */}
                                                    </div>
                                                </div>
                                                <div className="w-6/12 flex justify-start items-center">
                                                    <div>
                                                        <div className="text-sm font-medium flex justify-start items-center">{item?.maincategory}</div>

                                                        {/* <div className="text-sm font-medium flex justify-start items-center">{item?.country}</div> */}
                                                    </div>
                                                </div>

                                                <div className="w-8/12 flex justify-start items-center">
                                                    <div>
                                                        {/* <div className="text-sm font-medium flex justify-start items-center">{item?.subCategory}</div> */}


                                                        <SubCatUp item={item} />

                                                        {/* <div className="text-sm font-medium flex justify-start items-center">{item?.country}</div> */}
                                                    </div>
                                                </div>


                                                <div className="w-6/12 flex justify-start items-center">
                                                    <div>
                                                        {/* <div className="text-sm font-medium flex justify-start items-center">Available Stock</div> */}

                                                        <div className="text-sm font-medium flex justify-start items-center">{item?.availableStocks}</div>
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="flex items-center">
                                                <i className="las la-pencil-alt text-xl text-left ml-2 cursor-pointer"
                                                    onClick={() => {
                                                        console.log("item>>>>", item)
                                                        setSelectedProduct(item);
                                                        setShowModalQuickView(true);

                                                    }}
                                                ></i>
                                                <i className="las la-trash-alt text-xl text-left ml-2 text-red-500 cursor-pointer"
                                                    onClick={() => {
                                                        DeleteProduct(item?.productId)
                                                            .then((res) => {

                                                                addToast("Product successfully deleted", "success");
                                                                GetUsers();
                                                            })
                                                            .catch((error) => {
                                                                // setlopen(false);
                                                                console.log("Error", error.response);
                                                                addToast(error.response.data.message, "warning");
                                                                // setOpenToast(true);
                                                                // setColor(COLORS.Orange);
                                                                // setAlertClass("error");
                                                                // setMsg(error.response.data.message);
                                                            })
                                                    }}
                                                ></i>
                                            </div>

                                        </div>

                                    </>
                                );
                            })
                        }

                    </div>

                    <div className="mt-6">
                        <Pagination
                            pageLimit={pageLimit}
                            dataCount={dataCount}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>

                </div>
            </div>

        </>
    )

}

export default Stocks;