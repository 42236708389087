import React from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
import { Disclosure } from "@headlessui/react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO_2, NAVIGATION_DEMO_3, NAVIGATION_DEMO_4 } from "data/navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SocialsList from "shared/SocialsList/SocialsList";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
  data = NAVIGATION_DEMO_4,
  onClickClose,
}) => {
  const history = useHistory();

  const _renderMenuChild = (
    item: any,
    itemClass = " pl-3 text-neutral-900 dark:text-neutral-200 font-medium "
  ) => {
    console.log("BBBB", data)
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.categories?.map((i: any, index: any) => (
          <Disclosure key={i.href + index} as="li"

          >
            <div
              onClick={() => {
                localStorage.setItem("sMain", i.id);
                history.push('/page-collection-2')
                window.location.reload();
              }}
            >


              <NavLink
                // exact
                // strict
                to={{
                  pathname: i.href || undefined,
                }}
                className={`flex text-sm rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 pr-4 ${itemClass}`}
                activeClassName="text-secondary"
              >
                <span
                  className={`py-2.5 ${!i.children ? "block w-full" : ""}`}
                  onClick={onClickClose}
                >
                  {i.name}
                </span>
                {/* {i.children && (
                <span
                  className="flex items-center flex-grow"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="flex justify-end flex-grow"
                  >
                    <ChevronDownIcon
                      className="ml-2 h-4 w-4 text-slate-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )} */}
              </NavLink>
            </div>
            {/* {i.children && (
              <Disclosure.Panel>
                {_renderMenuChild(
                  i,
                  "pl-3 text-slate-600 dark:text-slate-400 "
                )}
              </Disclosure.Panel>
            )} */}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderMenuChild2 = (
    item: any,
    itemClass = " pl-3 text-neutral-900 dark:text-neutral-200 font-medium "
  ) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i: any, index: any) => (
          <Disclosure key={i.href + index} as="li">
            {
              i.target === true ?
                <>
                  <a
                    href={i.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`flex text-sm rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 pr-4 ${itemClass}`}
                  >

                    <span
                      className={`py-2.5 ${!i.children ? "block w-full" : ""}`}
                      onClick={onClickClose}
                    >
                      {i.name}
                    </span>
                    {i.children && (
                      <span
                        className="flex items-center flex-grow"
                        onClick={(e) => e.preventDefault()}
                      >
                        <Disclosure.Button
                          as="span"
                          className="flex justify-end flex-grow"
                        >
                          <ChevronDownIcon
                            className="ml-2 h-4 w-4 text-slate-500"
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                      </span>
                    )}


                  </a>

                </>

                :
                <NavLink
                  exact
                  strict
                  to={{
                    pathname: i.href || undefined,
                  }}
                  className={`flex text-sm rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 pr-4 ${itemClass}`}
                  activeClassName="text-secondary"
                >
                  <span
                    className={`py-2.5 ${!i.children ? "block w-full" : ""}`}
                    onClick={onClickClose}
                  >
                    {i.name}
                  </span>
                  {i.children && (
                    <span
                      className="flex items-center flex-grow"
                      onClick={(e) => e.preventDefault()}
                    >
                      <Disclosure.Button
                        as="span"
                        className="flex justify-end flex-grow"
                      >
                        <ChevronDownIcon
                          className="ml-2 h-4 w-4 text-slate-500"
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                    </span>
                  )}
                </NavLink>

            }

            {i.children && (
              <Disclosure.Panel>
                {_renderMenuChild(
                  i,
                  "pl-3 text-slate-600 dark:text-slate-400 "
                )}
              </Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };


  const _renderItem = (item: any, index: number) => {
    return (
      <Disclosure
        key={index}
        as="li"
        className="text-slate-900 dark:text-white"
      >

        {
          item.name !== "Hire" ?

            <>
              <NavLink
                //  href={item.href}
                exact
                strict
                className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg"
                to={{
                  pathname: item.href || undefined,
                }}
                activeClassName="text-secondary"
              >
                <span
                  className={!item.children ? "block w-full" : ""}
                  onClick={onClickClose}
                >
                  {item.name}
                </span>
                {item.categories && (
                  <span
                    className="block flex-grow"
                    onClick={(e) => e.preventDefault()}
                  >
                    <Disclosure.Button
                      as="span"
                      className="flex justify-end flex-grow"
                    >
                      <ChevronDownIcon
                        className="ml-2 h-4 w-4 text-neutral-500"
                        aria-hidden="true"
                      />
                    </Disclosure.Button>
                  </span>
                )}
                {
                  index !== 0 && item.children && (
                    <span
                      className="block flex-grow"
                      onClick={(e) => e.preventDefault()}
                    >
                      <Disclosure.Button
                        as="span"
                        className="flex justify-end flex-grow"
                      >
                        <ChevronDownIcon
                          className="ml-2 h-4 w-4 text-neutral-500"
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                    </span>
                  )
                }
              </NavLink>

            </>
            :
            <>
              <a href={item.href}
                // exact
                // strict
                className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg"
              // to={{
              //   pathname: item.href || undefined,
              // }}
              // activeClassName="text-secondary"
              >
                <span
                  className={!item.children ? "block w-full" : ""}
                  onClick={onClickClose}
                >
                  {item.name}
                </span>
                {item.categories && (
                  <span
                    className="block flex-grow"
                    onClick={(e) => e.preventDefault()}
                  >
                    <Disclosure.Button
                      as="span"
                      className="flex justify-end flex-grow"
                    >
                      <ChevronDownIcon
                        className="ml-2 h-4 w-4 text-neutral-500"
                        aria-hidden="true"
                      />
                    </Disclosure.Button>
                  </span>
                )}
                {
                  index !== 0 && item.children && (
                    <span
                      className="block flex-grow"
                      onClick={(e) => e.preventDefault()}
                    >
                      <Disclosure.Button
                        as="span"
                        className="flex justify-end flex-grow"
                      >
                        <ChevronDownIcon
                          className="ml-2 h-4 w-4 text-neutral-500"
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                    </span>
                  )
                }
              </a>
            </>

        }


        {item.categories && (
          <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
        )}
        {index !== 0 && item.children && (
          <Disclosure.Panel>{_renderMenuChild2(item)}</Disclosure.Panel>
        )}
      </Disclosure>
    );
  };

  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderSearchForm = () => {
    return (
      <form
        action=""
        method="POST"
        className="flex-1 text-slate-900 dark:text-slate-200"
      >
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1 py-2 px-4 rounded-xl h-full">
          {renderMagnifyingGlassIcon()}
          <input
            type="search"
            placeholder="Type and press enter"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-sm "
          />
        </div>
        <input type="submit" hidden value="" />
      </form>
    );
  };

  return (
    <div className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <div className="py-6 px-5">
        <Logo sideMenu />
        <div className="flex flex-col mt-5 text-slate-600 dark:text-slate-300 text-sm">
          {/* <span>
            Discover the most outstanding articles on all topics of life. Write
            your stories and share them
          </span> */}

          {/* <div className="flex justify-between items-center mt-4">
            <SocialsList itemClass="w-7 h-7 sm:w-8 sm:h-8 flex items-center justify-center rounded-full text-xl" />
            <span className="block">
              <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800" />
            </span>
          </div> */}
        </div>
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>

        {/* <div className="mt-5">{renderSearchForm()}</div> */}
      </div>
      <ul className="flex flex-col py-6 px-2 space-y-1">
        {/* {data.map(_renderItem)}

        <div className="flex w-full pt-5"></div>

        <div className="flex w-full border border-neutral-100 "></div> */}

        <div className="flex w-full pb-5"></div>

        <Link
          to={"/signup"}
          className="flex items-center p-2 m-1 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
          onClick={onClickClose}
        >
          <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2C14.4501 2 16.4401 3.99 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.15997 14.56C4.73997 16.18 4.73997 18.82 7.15997 20.43C9.90997 22.27 14.42 22.27 17.17 20.43C19.59 18.81 19.59 16.17 17.17 14.56C14.43 12.73 9.91997 12.73 7.15997 14.56Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium ">{"Sign Up"}</p>
          </div>
        </Link>
        <Link
          to={"/login"}
          className="flex items-center p-2 m-1 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
          onClick={onClickClose}
        >
          <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.90002 7.55999C9.21002 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24002 20.08 8.91002 16.54"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15 12H3.62"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.85 8.6499L2.5 11.9999L5.85 15.3499"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="ml-4"

          >
            <p className="text-sm font-medium ">{"Login"}</p>
          </div>
        </Link>
        {/* <Link
          to={"/cart"}
          className="flex items-center p-2 m-1 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
          onClick={onClickClose}
        >
          <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
            <svg
              className="w-6 h-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 8H21"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="ml-4"

          >
            <p className="text-sm font-medium ">{"Cart"}</p>
          </div>
        </Link> */}
      </ul>
      {/* <div className="flex items-center justify-between py-6 px-5 space-x-2">
        <ButtonPrimary href={"/"} className="!px-10">
          Buy this template
        </ButtonPrimary>
      </div> */}
    </div>
  );
};

export default NavMobile;
