// import rightImg from "images/hero-right1.png";
import rightImg from "images/about/Maps.png";
import React, { FC } from "react";
import SectionFounder, { People } from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";
import NcImage from "shared/NcImage/NcImage";

export interface PageAboutProps {
    className?: string;
}

const FOUNDER_DEMO: People[] = [
    {
        id: "1",
        name: `Niamh O'Shea`,
        job: "Co-founder and Chief Executive",
        avatar:
            "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "4",
        name: `Danien Jame`,
        job: "Co-founder and Chief Executive",
        avatar:
            "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "3",
        name: `Orla Dwyer`,
        job: "Co-founder, Chairman",
        avatar:
            "https://images.unsplash.com/photo-1560365163-3e8d64e762ef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "2",
        name: `Dara Frazier`,
        job: "Co-Founder, Chief Strategy Officer",
        avatar:
            "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
];


const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
    return (
        <div
            className={`nc-PageAbout overflow-hidden relative ${className}`}
            data-nc-id="PageAbout"
        >
            <Helmet>
                <title>Privacy Policy || Tidytraderr</title>
            </Helmet>

            {/* ======== BG GLASS ======== */}
            {/* <BgGlassmorphism /> */}

            <div className="container py-16 lg:pt-10 lg:pb-28 space-y-16 lg:space-y-14">
                {/* <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        /> */}

                <div className="flex items-center justify-items-center justify-center grid grid-cols-1 lg:grid-cols-1 gap-10 ">

                    <div className="w-full">

                        <div className="w-full space-y-2">

                            <h2 className="flex justify-center text-lg font-semibold text-neutral-900 md:text-2xl dark:text-neutral-100">
                                Privacy Policy
                            </h2>

                            <h2 className="flex justify-center text-2xl font-semibold text-neutral-900 md:text-4xl dark:text-neutral-100 text-center">
                                How Tidytraderr handles your personal data
                            </h2>

                            <h2 className="flex justify-center text-base font-normal text-neutral-900 md:text-lg dark:text-neutral-100">
                                Updated on 30th April 2023
                            </h2>
                        </div>
                        <div className="text-left">
                            <h2 className="text-base font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100 mt-6">
                                1.	We respect your privacy.
                            </h2>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                Tidytraderr respects your right to privacy and is committed to safeguarding the privacy of our customers and website visitors. This policy sets out how we collect and treat your personal information.
                            </span>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                "Personal information" is the information we hold which is identifiable as being about you.
                            </span>


                            <h2 className="text-base font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100 mt-8">
                                2.	Collection of personal information.
                            </h2>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                Tidytraderr will, from time to time, receive and store the personal information you enter onto our website, provided to us directly or given to us in other forms.
                            </span>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                You may provide basic information such as your name, phone number, address, and email address to enable us to send information, provide updates, and process your product or service order. We may collect additional information at other times, including but not limited to, when you provide feedback, information about your personal or business affairs, change your content or email preferences, respond to surveys and/or promotions, provide financial or credit card information, or communicate with our customer support.
                            </span>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                Additionally, we may also collect any other information you provide while interacting with us.
                            </span>

                            <h2 className="text-base font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100 mt-8">
                                3.	How we collect your personal information.
                            </h2>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                Tidytraderr collects personal information from you in a variety of ways, including when you interact with us electronically or in person, when you access our website, and when we provide our services to you. We may receive personal information from third parties. If we do, we will protect it as set out in this Privacy Policy.
                            </span>


                            <h2 className="text-base font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100 mt-8">
                                4.	Use of your personal information.
                            </h2>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                Tidytraderr may use personal information collected from you to provide you with information, updates, and our services. We may also make you aware of new and additional products, services, and opportunities available to you. We may use your personal information to improve our products and services and better understand your needs.
                            </span>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                Tidytraderr may contact you by a variety of measures including, but not limited to, telephone, email, SMS, or mail.
                            </span>


                            <h2 className="text-base font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100 mt-8">
                                5.	Disclosure of your personal information.
                            </h2>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                We may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers, or subcontractors insofar as reasonably necessary for the purposes set out in this Policy. When required, personal information is only supplied to a third party to deliver our services.
                            </span>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                We may from time to time need to disclose personal information to comply with a legal requirement, such as a law, regulation, court order, subpoena, warrant, during a legal proceeding, or in response to a law enforcement agency request.
                            </span>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                We may also use your personal information to protect the copyright, trademarks, legal rights, property, or safety of Tidytraderr, www.Tidytraderr.com , its customers, or third parties.
                            </span>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                Information that we collect may from time to time be stored, processed in, or transferred between parties located in countries outside of the operating country.
                            </span>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                If there is a change of control in our business or a sale or transfer of business assets, we reserve the right to transfer to the extent permissible by law our user databases, together with any personal information and non-personal information contained in those databases. This information may be disclosed to a potential purchaser under an agreement to maintain confidentiality. We would seek to disclose information in good faith and where required by any of the above circumstances.
                            </span>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                By providing us with personal information, you consent to the terms of this Privacy Policy and the types of disclosure covered by this Policy. When we disclose your personal information to third parties, we will request that the third party follows this Policy regarding handling your personal information.
                            </span>

                            <h2 className="text-base font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100 mt-8">
                                6.	Security of Your Personal Information.
                            </h2>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                Tidytraderr is committed to ensuring that the information you provide to us is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure information, and protect it from misuse, interference, loss, and unauthorized access, modification, and disclosure.
                            </span>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                The transmission and exchange of information are carried out at your own risk. We cannot guarantee the security of any information that you transmit to us or receive from us. Although we take measures to safeguard against unauthorized disclosures of information, we cannot assure you that the personal information that we collect will not be disclosed in a manner that is inconsistent with this Privacy Policy.
                            </span>


                            <h2 className="text-base font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100 mt-8">
                                7.	Complaints About Privacy.
                            </h2>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                If you have any complaints about our privacy practices, please feel free to send in details of your complaints to support@infinitycs.com.au. We take complaints very seriously and will respond shortly after receiving written notice of your complaint.
                            </span>


                            <h2 className="text-base font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100 mt-8">
                                8.	Changes to Privacy Policy.
                            </h2>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                Please be aware that we may change this Privacy Policy in the future. We may modify this policy at any time, in our sole discretion, and all modifications will be effective immediately upon our posting of the modifications on our website or notice board. Please check back from time to time to review our Privacy Policy.
                            </span>

                            <h2 className="text-base font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100 mt-8">
                                9.	Website.
                            </h2>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                {/* Tidytraderr will comply with Tidytraderr Privacy Statement. Tidytraderr will not reveal your personal information  to external or third party organisations except for the purposes of fulfilling your order. Your use of the website and any dispute arising out of such use of the website is subject to the laws of the Australian Government as outlined in the Privacy Act 1988. Information Privacy Principles under the Privacy Act 1988 and further legislation is available at <a href="https://www.privacy.gov.au" target="_blank" className="font-semibold prose prose-sm  sm:prose lg:prose-lg dark:prose-invert"> www.privacy.gov.au.</a> */}

                                When you visit our website at www.Tidytraderr.com, we may collect certain information such as browser type, operating system, website visited immediately before coming to our site, etc. This information is used in an aggregated manner to analyse how people use our site so that we can improve our service.
                            </span>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                Cookies: We may use cookies on our website from time to time. Cookies are very small files that a website uses to identify you when you come back to the site and to store details about your use of the site. Cookies are not malicious programs that access or damage your computer. Most web browsers automatically accept cookies, but you can choose to reject cookies by changing your browser settings. However, this may prevent you from taking full advantage of our website. Our website may from time-to-time use cookies to analyse website traffic and help us provide a better website visitor experience. In addition, cookies may be used to serve relevant ads to website visitors through third-party services such as Google AdWords. These ads may appear on this website or other websites you visit.
                            </span>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                Third-Party Sites: Our site may from time to time have links to other websites not owned or controlled by us. These links are meant for your convenience only. Links to third-party websites do not constitute sponsorship or endorsement or approval of these websites. Please be aware that Tidytraderr is not responsible for the privacy practices of other such websites. When you leave our website, we encourage our users to be aware and to read the privacy statements of each website that collects personally identifiable information.
                            </span>




                            {/* <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8 mt-6">
                {FOUNDER_DEMO.map((item) => (
                  <div key={item.id} className="max-w-sm">
                    <NcImage
                      containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
                      className="absolute inset-0 object-cover"
                      src={item.avatar}
                    />
                    <h3 className="text-base font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
                      {item.name}
                    </h3>
                    <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
                      {item.job}
                    </span>
                  </div>
                ))}
              </div> */}

                        </div>
                    </div>

                </div>





                {/* <SectionFounder /> */}
                {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>

        <SectionStatistic /> */}

                {/* <SectionPromo3 /> */}
            </div>
        </div>
    );
};

export default PageAbout;
