import React, { FC, useState, useEffect } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useHistory, useLocation } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { addToast } from "shared/Toast";
import { LoginQuery, updatePassword } from "Services/API/Post";
import jwt_decode from "jwt-decode";
import BotToast from "shared/BotToast";
import Label from "components/Label/Label";
import { t } from "i18next";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {

  const history = useHistory();
  const [lopen, setlopen] = React.useState(false);

  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");

  const [username, setusername] = useState("");
  const [lpass, setlpass] = useState("");
  const [aKey, setaKey] = useState("login");

  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);
  const [errPass, seterrPass] = useState(false);
  const [errUsername, seterrerrUsername] = useState("");
  const [errLpass, seterrerrLpass] = useState("");

  const [bOpen, setbOpen] = useState(false);
  const [bMsg, setbMsg] = useState("");
  const [bStatus, setbStatus] = useState("");


  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();


  const Login = async (e?: any) => {

    e.preventDefault();

    let password1 = username
    let password = lpass

    if (password1 === "") {
      seterrerrUsername("This Field Required");
    }

    if (password1 !== "") {
      seterrerrUsername("");
    }

    if (password === "") {
      seterrerrLpass("This Field Required");
    }

    if (password !== "") {
      seterrerrLpass("");
    }

    if (!(password1 === password)) {
      seterrerrLpass("Password Mismatch")
    }
    // eslint-disable-next-line no-console
    console.log({
      password: password1,
    });

    let obj = {
      password: password1,
      token: query.get("token"),
    };

    if (password1 !== "" && password !== "" && password1 === password) {
      console.log("<<<<", obj)
      updatePassword(obj)
        .then((res) => {
          // setOpenToast(true)
          // setColor(COLORS.Primary)
          // setAlertClass("success");
          // setMsg("Password Changed Successfully")
          // addToast("Password Changed Successfully", "success");
          setbOpen(true);
          setbMsg("Password Changed Successfully");
          setbStatus("success");
          setTimeout(() => {
            history.push("/login");
            // window.location.reload();
          }, 2000);
          // if (res.status) {
          //   localStorage.setItem(
          //     `${window.location.hostname}.login`,
          //     JSON.stringify(res)
          //   );
          // }

          // setEmail("");
          // setPassword("");

          // history.push("/");
          // window.location.reload();
        })
        .catch((error) => {
          console.log(error.response)
          // addToast(error.response.data.message, "warning");
          setbOpen(true);
          setbMsg(error.response.data.message);
          setbStatus("warning");
          // setColor("red");
          // setAlertClass("error");
          // setOpenToast(true)
          // setColor("red")
          // setMsg(error.response.data.message)

          // if (error.response.data.statusCode === 401) {
          //   setErrPassword(error.response.data.message);
          // } else {
          //   setErrEmail(error.response.data.errorMessage);
          // }
        });

    }



  };

  useEffect(() => {
    if (bOpen === true) {
      setTimeout(() => {

        setbOpen(false)

      }, 10000);
    }
  }, [bOpen])

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Forgot Password || Booking React Template</title>
      </Helmet>
      <div className="w-full flex justify-center items-center">


        <div className="w-full px-3 sm:px-0 md:w-6/12 lg:w-4/12 xl:w-[29%]">


          <div className=" mb-24 lg:mb-32 bg-[#F4F4F4] mt-14 rounded-lg">
            <div className="py-10">

              <h2 className="mb-10 flex items-center text-3xl leading-[115%] md:text-3xl md:leading-[115%] font-bold text-[#003d35] dark:text-neutral-100 justify-center">
                {t('resetPwrd')}

              </h2>
              <div className="px-10 sm:px-12 space-y-6 ">
                {/* <div className="grid gap-3">
    {loginSocials.map((item, index) => (
      <a
        key={index}
        href={item.href}
        className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
      >
        <img
          className="flex-shrink-0"
          src={item.icon}
          alt={item.name}
        />
        <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
          {item.name}
        </h3>
      </a>
    ))}
  </div> */}
                {/* OR */}
                {/* <div className="relative text-center">
    <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
      OR
    </span>
    <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
  </div> */}
                {/* FORM */}
                <form className="grid grid-cols-1 gap-6" onSubmit={Login}>
                  <label className="block">
                    <Label className="text-sm" >               {t('newPwrd')}

                    </Label>

                    {errUsername && (
                      <div className="text-red-600 text-sm text-right mt-2">
                        {errUsername}
                      </div>
                    )}
                    <Input
                      type="text"
                      placeholder={`${t('newPwrd')}`}
                      className="mt-1"
                      onChange={(e) => {
                        setusername(e.target.value);
                        seterrerrUsername("");
                      }}
                      value={username}
                    />

                  </label>
                  <label className="block">
                    <Label className="flex text-sm justify-between items-center" >{t('oldPwrd')}


                    </Label>

                    {errLpass && (
                      <div className="text-red-600 text-sm text-right mt-2">
                        {errLpass}
                      </div>
                    )}

                    <Input type="password" className="mt-1"
                      placeholder={t('oldPwrd')}
                      onChange={(e) => {
                        setlpass(e.target.value);
                        seterrerrLpass("");
                      }}
                      value={lpass}
                    />



                  </label>
                  <BotToast open={bOpen} msg={bMsg} status={bStatus} />
                  {/* <ButtonPrimary type="submit">Continue</ButtonPrimary> */}

                  <button
                    className={
                      `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b] 
w-full h-9 text-slate-50 font-medium mt-2 cursor-pointer`}


                    type="submit"
                  >
                    {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
                    <span className="block  text-sm font-medium">
                      {t('Save')}

                    </span>

                  </button>
                </form>

                {/* ==== */}

              </div>
            </div>

          </div>

        </div>

      </div>


    </div>
  );
};

export default PageLogin;
