import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link, NavLink } from "react-router-dom";
import ButtonCircle from "shared/Button/ButtonCircle";
import Company1 from "images/avatars/Image-1.png";
import { StarIcon } from "@heroicons/react/24/solid";
import Rating from '@mui/material/Rating';
import Cover from "images/videoImg.jpeg";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";

export interface CardCategory2Props {
  className?: string;
  ratioClass?: string;
  bgClass?: string;
  featuredImage?: string;
  name?: string;
  desc?: string;
  data?: any;
}

const CardCategory2: FC<CardCategory2Props> = ({
  className = "",
  ratioClass = "aspect-w-1 aspect-h-1",
  bgClass = "bg-orange-50",
  featuredImage = ".",
  name,
  desc,
  data,
}) => {
  return (
    <NavLink to={`/event-single/${data?.id}`} >
      <div

        className={`nc-CardCategory2 ${className}`}
        data-nc-id="CardCategory2"
      >
        <div
        // className={`flex-1 relative w-full h-fit rounded-2xl group`}
        >
          <div className="bg-[#2d867b] rounded-md">

            <div className="w-full h-44 object-fill bg-cover bg-center rounded-t-md"
              style={{
                // backgroundImage: `url(${Cover})`
                backgroundImage: data ? `url(${IMAGE_SERVER_URL_DEV}${data?.banner})` : `url(${Cover})`

              }}
            >
              <div className="flex justify-end relative z-[1] pr-3 pt-4">
                <div className="w-20 h-7 bg-[#002930] text-slate-100 text-xs rounded-md flex justify-center items-center"

                >

                  {
                    data ? (data.charge != "Free" ? `$ ${data.ticketPrice}` : "FREE") : "FREE"
                  }

                </div>

              </div>

            </div>



            <div className="container p-2">


              <div className="flex" >

                {/* <ButtonCircle
              // className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
              size=" w-11 h-11"
              type="submit"
            >
              <i className="las la-apple-alt text-4xl text-left"></i>


            </ButtonCircle> */}



                <div className="flex justify-between w-full cursor-pointer pl-1 pt-1">
                  <h2 className="flex text-xs text-slate-100 dark:text-neutral-100 font-medium">
                    {/* Ad Title should be here */}
                    {
                      data ? data.title : "Ad Title should be here"
                    }
                  </h2>
                  <span className="flex">
                    <i className="las la-chevron-circle-right text-base text-slate-100 dark:text-neutral-100 font-medium mt-0.5 flex justify-end"></i>
                  </span>


                  {/* <div className="flex items-center space-x-0.5 mt-1.5 text-yellow-500">
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
              </div> */}

                </div>

              </div>




              {/* <img
            className="object-cover "
            src={featuredImage}

          /> */}
              {/* <NcImage
            containerClassName="w-full h-full flex justify-center"
            src={featuredImage}
            className="object-cover rounded-2xl"
          /> */}
            </div>

          </div>
          <div className="pt-10"></div>
          {/* <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span> */}
        </div>
        {/* <div className="mt-5 flex-1 text-center">
        <h2 className="text-base sm:text-base text-neutral-900 dark:text-neutral-100 font-semibold">
          {name}
        </h2>
        <span className="block mt-0.5 sm:mt-1.5 text-sm text-neutral-500 dark:text-neutral-400">
          {desc}
        </span>
      </div> */}
      </div>
    </NavLink >
  );
};

export default CardCategory2;
