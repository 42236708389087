import React, { FC, useState } from "react";
import imageRightPng from "images/hero-right.png";
import imageRightPng2 from "images/hero-right-2.png";
import imageRightPng3 from "images/hero-right-3.png";

import backgroundLineSvg from "images/Moon.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Next from "shared/NextPrev/Next";
import Prev from "shared/NextPrev/Prev";
import useInterval from "react-use/lib/useInterval";
import useBoolean from "react-use/lib/useBoolean";
import { getCover } from "Services/API/Get";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import { Link, NavLink } from "react-router-dom";
import cover1 from "images/cover/cover-4.png"
import cover2 from "images/cover/cover-5.png"
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import cover12 from "images/cover/cover-12.png"
import { COLORS } from "Constant/Colors";
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

import ICON1 from "images/dekstop/1.png";
import ICON2 from "images/dekstop/2.png";


interface Hero2DataType {
  image: string;
  heading: string;
  subHeading: string;
  btnText: string;
  btnLink: string;
}

interface Hero2DataType2 {
  image: string;
  caption1: string;
  caption2: string;

}

export interface SectionHero2Props {
  className?: string;
  tabNo?: any;
  settabNo?: any;
}

const DATA: Hero2DataType[] = [
  {
    image: imageRightPng2,
    heading: "Exclusive collection for everyone",
    subHeading: "In this season, find the best 🔥",
    btnText: "Explore now",
    btnLink: "/",
  },
  {
    image: imageRightPng3,
    heading: "Exclusive collection for everyone",
    subHeading: "In this season, find the best 🔥",
    btnText: "Explore now",
    btnLink: "/",
  },
  {
    image: imageRightPng,
    heading: "Exclusive collection for everyone",
    subHeading: "In this season, find the best 🔥",
    btnText: "Explore now",
    btnLink: "/",
  },
];
let TIME_OUT: NodeJS.Timeout | null = null;

const MCOVERS: any = [
  {
    image: cover1,
  },
  {
    image: cover2,
  }

]

const SectionHero2: FC<SectionHero2Props> = ({ className = "", tabNo, settabNo }) => {
  // =================
  const { t } = useTranslation();
  const [indexActive, setIndexActive] = useState(0);
  const [isRunning, toggleIsRunning] = useBoolean(true);

  const [slides, setSlides] = React.useState<Hero2DataType2[]>([{
    image: "",
    caption1: "",
    caption2: "",

  }]);
  const [lopen, setlopen] = React.useState(false);

  React.useEffect(() => {
    // getSlides();
  }, []);

  const getSlides = () => {
    setlopen(true);
    getCover()
      .then((res) => {
        console.log("COver res>>>>", res.data)
        setlopen(false);


        setSlides(res.data);
      })
      .catch((error) => {
        console.log("cover error", error.response)
        setlopen(false);

      });


  };

  useInterval(
    () => {
      handleAutoNext();
    },
    isRunning ? 5500 : null
  );
  //

  const handleAutoNext = () => {
    setIndexActive((state) => {
      if (state >= slides.length - 1) {
        return 0;
      }
      return state + 1;
    });
  };

  const handleClickNext = () => {
    setIndexActive((state) => {
      if (state >= slides.length - 1) {
        return 0;
      }
      return state + 1;
    });
    handleAfterClick();
  };

  const handleClickPrev = () => {
    setIndexActive((state) => {
      if (state === 0) {
        return slides.length - 1;
      }
      return state - 1;
    });
    handleAfterClick();
  };

  const handleAfterClick = () => {
    toggleIsRunning(false);
    if (TIME_OUT) {
      clearTimeout(TIME_OUT);
    }
    TIME_OUT = setTimeout(() => {
      toggleIsRunning(true);
    }, 1000);
  };
  // =================

  const renderItem = (index: number) => {
    const isActive = indexActive === index;
    const item = slides[index];
    if (!isActive) {
      return null;
    }
    return (
      <div>


        <div
          className={`//nc-SectionHero2Item //nc-SectionHero2Item--animation flex flex-col-reverse lg:flex-col relative overflow-hidden ${className}`}
          key={index}
        >
          <div className="absolute bottom-4 left-1/2 -translate-x-1/2 z-20 flex justify-center">
            {slides.map((_, index) => {
              const isActive = indexActive === index;
              return (
                <div
                  key={index}
                  onClick={() => {
                    setIndexActive(index);
                    handleAfterClick();
                  }}
                  className={`relative px-1 py-1.5 cursor-pointer`}
                >
                  {/* <div
                  className={`relative w-20 h-1 shadow-sm rounded-md bg-white`}
                >
                  {isActive && (
                    <div
                      className={`nc-SectionHero2Item__dot absolute inset-0 bg-[#002930] rounded-md ${isActive ? " " : " "
                        }`}
                    ></div>
                  )}
                </div> */}
                </div>
              );
            })}
          </div>

          {/* <Prev
          className="absolute left-1 sm:left-5 top-3/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
          btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
          svgSize="w-6 h-6"
          onClickPrev={handleClickPrev}
        />
        <Next
          className="absolute right-1 sm:right-5 top-3/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
          btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
          svgSize="w-6 h-6"
          onClickNext={handleClickNext}
        /> */}

          {/* BG */}
          {/* <div className="absolute inset-0 bg-[#E3FFE6]">
         
          <img
            className="absolute w-full h-full object-cover"
            // src={backgroundLineSvg}
            src={`${IMAGE_SERVER_URL_DEV}${item?.image}`}
            alt=""
          />
        </div> */}

          {/* <Link to={{ pathname: "/page-collection-2" }}> */}

          <div className="invisible md:visible" >


            <div className={`absolute inset-0 bg-blueF h-full bg-cover ${index != 1 ? "bg-center" : "bg-[center_left_-17.7rem]"} lg:bg-[center_bottom_-2.8rem] `}
              style={{
                // backgroundImage: `url(${IMAGE_SERVER_URL_DEV}${item?.image})`
                // backgroundImage: `url(${cover12})`
              }}
            >

            </div>

          </div>

          <div className="visible md:invisible" >


            <div className={`absolute inset-0 bg-blueF h-full bg-cover ${index != 1 ? "bg-center" : "bg-center"} lg:bg-[center_bottom_-2.8rem] `}
              style={{
                // backgroundImage: `url(${MCOVERS[index]?.image})`
                // backgroundImage: `url(${cover12})`
              }}
            >

            </div>

          </div>

          <div className="relative container pb-0 pt-0 lg:py-44 h-[580px] xl:h-[570px] ">
            <div
              className={`relative z-[1] w-full h-full flex items-center space-y-8 sm:space-y-14 nc-SectionHero2Item__left`}
            >
              <div className="container">

                <header className="max-w-5xl mx-auto  flex flex-col">
                  <div className="mb-10" >


                    <h2 className={`text-3xl sm:text-3xl md:text-4xl  ${t("langKey") === "Si" ? "font-[Gamunu] text-4xl lg:text-6xl font-extrabold lg:px-24" : "lg:text-5xl !leading-[114%] font-normal lg:px-32"}   text-slate-50 text-center `}>
                      {/* Sri Lanka's fast growing platform for  */}

                      {"Sri Lanka's fast growing platform for job seekers"}
                      {/* <span className="text-[#b69d52]">
                       
                        {" "}{t("bSecondary")}
                      </span> */}
                    </h2>

                    <span className={`block  ${t("langKey") === "Si" ? "//text-base //md:text-lg text-sm md:text-base" : "text-sm md:text-base"} text-slate-100 font-normal flex justify-center px-12 text-center mt-6 text-[#BFBFBF]`}>
                      {t("bDesc")}

                      {/* Discover the life partner with just a few clicks. */}
                    </span>


                  </div>

                  <div className="w-full grid grid-cols-3 sm:grid-cols-5 flex justify-center items-center gap-y-6 mt-3 gap-x-6 sm:gap-x-8 lg:px-[200px]">

                    <div className="w-full flex justify-center items-center ">

                      <div className={`w-full ${tabNo === 1 ? "bg-redF text-white" : "bg-white text-slate-900"}  text-sm h-[100px] flex justify-center items-center rounded-sm cursor-pointer `}
                        onClick={() => {
                          settabNo(1);
                        }}
                      >

                        <span className="px-6 text-center text-xs">Job Seekers</span>

                      </div>

                    </div>

                    <div className="w-full flex justify-center items-center ">

                      <div className={`w-full ${tabNo === 2 ? "bg-redF text-white" : "bg-white text-slate-900"}  text-sm h-[100px] flex justify-center items-center rounded-sm cursor-pointer `}
                        onClick={() => {
                          settabNo(2);
                        }}
                      >

                        <span className="px-6 text-center text-xs">Job Vacancies</span>

                      </div>

                    </div>

                    <div className="w-full flex justify-center items-center ">

                      <div className={`w-full ${tabNo === 3 ? "bg-redF text-white" : "bg-white text-slate-900"}  text-sm h-[100px] flex justify-center items-center rounded-sm cursor-pointer `}
                        onClick={() => {
                          settabNo(3);
                        }}
                      >

                        <span className="px-6 text-center text-xs">CV Writers</span>

                      </div>

                    </div>

                    <div className="w-full flex justify-center items-center ">

                      <div className={`w-full ${tabNo === 4 ? "bg-redF text-white" : "bg-white text-slate-900"}  text-sm h-[100px] flex justify-center items-center rounded-sm cursor-pointer `}
                        onClick={() => {
                          settabNo(4);
                        }}
                      >

                        <span className="px-6 text-center text-xs">Registered Employers</span>

                      </div>

                    </div>

                    <div className="w-full flex justify-center items-center ">

                      <div className={`w-full ${tabNo === 5 ? "bg-redF text-white" : "bg-white text-slate-900"}  text-sm h-[100px] flex justify-center items-center rounded-sm cursor-pointer `}
                        onClick={() => {
                          settabNo(5);
                        }}
                      >

                        <span className="px-6 text-center text-xs">Trainers & Consultants</span>

                      </div>

                    </div>



                  </div>

                  <div className="w-full flex flex-col sm:flex-row justify-center items-center space-y-5 sm:space-y-0 sm:space-x-4 mt-10">

                    <div className="w-full flex justify-center">



                      <div className="w-full flex justify-center sm:justify-end">

                        <a href="https://wattl.sssd.store/" target="_blank" rel="noopener noreferrer" >
                          <button
                            className={
                              `ttnc-ButtonCircle rounded-3xl flex items-center justify-center !leading-none disabled:bg-opacity-70 
sm:h-9 text-slate-900 border border-white bg-white px-2  py-2  sm:py-4 w-[340px] `}

                          // onClick={() => { setShowModalQuickView(true) }}
                          >

                            <span className={`block   font-medium ${t("langKey") === "Si" ? "text-[11px]" : "text-xs"}`}
                            >
                              Login as a CV Writer


                            </span>

                          </button>
                        </a>

                      </div>



                    </div>

                    <div className="w-full flex justify-center">



                      <div className="w-full flex justify-center sm:justify-start">
                        <a href="https://wattl.sssd.store/" target="_blank" rel="noopener noreferrer" >
                          <button
                            className={
                              `ttnc-ButtonCircle rounded-3xl flex items-center justify-center !leading-none disabled:bg-opacity-70 
sm:h-9 text-slate-900 border border-white bg-white py-2 sm:px-4 sm:py-4 w-[340px]  `}

                          // onClick={() => { setShowModalQuickView(true) }}
                          >

                            <span className={`block   font-medium ${t("langKey") === "Si" ? "text-[11px]" : "text-xs"}`}
                            >
                              Login as an employee


                            </span>

                          </button>
                        </a>
                      </div>



                    </div>

                  </div>

                  {/* <form className="relative w-full " method="post">
                    <label
                      htmlFor="search-input"
                      className="text-neutral-500 dark:text-neutral-300"
                    >

                      <Input
                        className="shadow-lg border-0 dark:border"
                        id="search-input"
                        type="search"
                        placeholder="   Category           |             Location            |             Keywords"
                        sizeClass="pl-14 py-5 pr-5 md:pl-16"
                        rounded="rounded-full"
                      />
                      <ButtonCircle
                        className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                        size=" w-32 h-11"
                        type="submit"
                      >

                        <div className="flex w-full pl-5" >
                          <i className="las la-search text-xl text-left"></i>
                          <span className="nc-SectionHero2Item__subheading block text-base md:text-sm text-slate-100 font-medium pl-3">
                            Search
                          </span>

                        </div>

                      </ButtonCircle>
                     
                      <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                        <svg
                          className="h-5 w-5"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M22 22L20 20"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </label>
                  </form>
                  <div className="mb-10 mt-6 flex justify-center" >

                    <span className="nc-SectionHero2Item__subheading block text-sm md:text-sm text-slate-100 font-medium flex justify-center">
                      New to the platform ?
                    </span>
                    <span className="nc-SectionHero2Item__subheading block text-sm md:text-sm text-yellow-300 font-medium flex justify-center pl-2 cursor-pointer">
                      Open your FREE virtual shop
                    </span>

                  </div> */}
                </header>
              </div>



            </div>
            <div className="mt-10 lg:mt-0 lg:absolute right-0 bottom-0 top-0 w-full max-w-2xl xl:max-w-3xl 2xl:max-w-4xl">
              <div className="w-full h-52 object-contain object-right-bottom nc-SectionHero2Item__image"></div>
              {/* <img
              className="w-full h-full object-contain object-right-bottom nc-SectionHero2Item__image"
              src={`${IMAGE_SERVER_URL_DEV}${item?.image}`}
              alt={item.caption1}
            /> */}
            </div>
          </div>
          {/* </Link> */}
        </div>

        {/* <div className={`w-full container mt-6 sm:-mt-10 relative z-[1]`}>
          <div className="flex grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-y-0 gap-x-4 sm:gap-x-1">

           
            <div className={`w-full bg-[#2d867b]  py-3 sm:p-5 text-slate-100 flex pl-0 justify-center sm:pl-0 sm:justify-center items-center rounded-md sm:rounded-none cursor-pointer
            ${tabNo === 1 ? "border-b-4 border-[#FFB81C]" : ""}
            `}
              onClick={() => {
                settabNo(1);
              }}
            >
              <div className="flex flex-col sm:justify-center items-center text-center">

               
                <div className="flex flex justify-start text-left">

                  <div className="flex  justify-start text-left items-center space-x-4">
                    <div>
                      <img src={ICON1} className="w-[50px] lg:w-[60px]"></img>
                    </div>
                    <div className="flex flex-col">
                      <div className={` ${t("langKey") === "Si" ? "text-sm lg:text-md" : "text-xs lg:text-base"}`}>

                        <span>
                         
                          {t("bLeftCardTitle")}

                        </span>
                      </div>

                      <span className={` font-bold ${t("langKey") === "Si" ? "text-sm lg:text-xl" : "text-sm lg:text-xl"} `}>
                       
                        {t("bLeftCardDesc")}

                      </span>

                    </div>


                  </div>





                </div>

              </div>


            </div>

           

            <div className={`w-full bg-[#b69d52]  py-3 sm:p-5 text-slate-100 flex pl-0 sm:pl-0 justify-center sm:justify-center items-center rounded-md sm:rounded-none cursor-pointer
             ${tabNo === 2 ? "border-b-4 border-[#FFB81C]" : ""}
            `}
              onClick={() => {
                settabNo(2);
              }}>
              <div className="flex flex-col justify-center items-center text-center">

              

               

                <div className="flex  justify-start text-left items-center space-x-4">
                  <div>
                    <img src={ICON2} className="w-[50px] lg:w-[60px]"></img>
                  </div>
                  <div className="flex flex-col">
                    <div className={` ${t("langKey") === "Si" ? "text-sm lg:text-md" : "text-xs lg:text-base"}`}>

                      <span>
                        
                        {t("bLeftCardTitle")}

                      </span>
                    </div>

                    <span className={` font-bold ${t("langKey") === "Si" ? "text-sm lg:text-xl" : "text-sm lg:text-xl"} `}>
                  
                      {t("bRightCardDesc")}

                    </span>

                  </div>


                </div>

              </div>


            </div>

           



          </div>

        </div> */}


      </div>
    );
  };

  return <>{slides.map((_, index) => renderItem(index))}</>;
};

export default SectionHero2;
