import Label from "components/Label/Label";
import NcInputNumber from "components/NcInputNumber";
import NcInputNumberNew from "components/NcInputNumberNew";
import Prices from "components/Prices";
import { Product, PRODUCTS } from "data/data";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import ContactInfo from "./ContactInfo";
import PaymentMethod from "./PaymentMethod";
import ShippingAddress from "./ShippingAddress";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Radio from "shared/Radio/Radio";
import Select from "shared/Select/Select";
import CartContext from "Context/CartContext";
import TotalContext from "Context/TotalContext";
import { CurrentUser, getAllCities, getCartList, getDistricts, getCities, getProductById, getOrderDetails, getProductById2, OrderbyID } from "Services/API/Get";
import CartCount from "Constant/CartCount";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import { DeleteCard } from "Services/API/Delete";
import { Autocomplete } from "@mui/material";
import { OrderRequest, OrderSave, PostQuote, PostQuoteApi, RegisterUser } from "Services/API/Post";
import { EMAIL_TAMPLETE } from "./email";
import { EMAIL_TAMPLETE2 } from "./email2";
import { upperFirst, lowerCase } from 'lodash';
import { EditUserBilling, EditUserQuery } from "Services/API/Put";
import moment from "moment"
import { addToast } from "shared/Toast";
import _ from "lodash";
import LoadingSpinner from "shared/LoadingSpinner";
import SubData from "data/australian_postcodes.json";
import { EMAIL_TAMPLETE_3 } from "./emailCreateOrder";

import { EMAIL_TAMPLETE_QUOTE } from "./emailOrderQuote";
import { resolve } from "path";
import { EMAIL_TAMPLETE_RETAIL } from "./emailRetailOrder";
import EmptyIMG from "images/empty.png";


const CheckoutPage = () => {

  const useQuery: any = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const history = useHistory()

  const [tabActive, setTabActive] = useState<
    "ContactInfo" | "ShippingAddress" | "PaymentMethod"
  >("ShippingAddress");

  const handleScrollToEl = (id: string) => {
    const element = document.getElementById(id);
    setTimeout(() => {
      element?.scrollIntoView({ behavior: "smooth" });
    }, 80);
  };


  const { cart, setCart } = useContext(CartContext);
  const { cartotal, setcartotal } = useContext(TotalContext);


  const [array, setArray] = useState<any>(null);
  const [arrayNew, setarrayNew] = useState([]);

  const [Total, setTotal] = useState(0);

  const [lopen, setlopen] = useState(true);

  const [cartproducts, setcartproducts] = useState<any>([]);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [homeNo, sethomeNo] = useState("");
  const [country, setcountry] = useState("Australia");
  const [homeAddress, sethomeAddress] = useState("");
  const [homeDistrict, sethomeDistrict] = useState("");
  const [homeCity, sethomeCity] = useState("");
  const [homepostCode, sethomepostCode] = useState("");
  const [userId, setuserId] = useState("");
  const [notes, setnotes] = useState("");
  const [currUser, setcurrUser] = useState<any>("");


  const [homeDistrictId, sethomeDistrictId] = useState(0);
  const [allHCities, setallHCities] = useState([]);
  const [allHDistricts, setallHDistricts] = useState([]);

  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);
  const [errcountry, seterrcountry] = useState(false);
  const [errhomeDistrict, seterrhomeDistrict] = useState(false);
  const [errhomeCity, seterrhomeCity] = useState(false);
  const [errhomepostCode, seterrhomepostCode] = useState(false);
  const [errhomeAddress, seterrhomeAddress] = useState(false);

  const [tempUser, settempUser] = useState<any>(null);

  const [newSubArray, setnewSubArray] = useState<any>([]);
  const [newSubs, setnewSubs] = useState<any>([]);

  const [newSubs2, setnewSubs2] = useState<any>([]);

  const [delCharge, setdelCharge] = useState<any>(0);

  const [delChargeArr, setdelChargeArr] = useState<any>([]);

  const [checkOrder, setcheckOrder] = useState<any>(null);
  const [orderSummary, setordersummary] = useState([]);

  const [orderFinal, setorderFinal] = useState<any>([]);

  const [payDisable, setpayDisable] = useState(false);

  const [userType, setuserType] = useState("");

  useEffect(() => {

    let testArr: any = _.groupBy(array, (data: any) => data.supplierId);

    testArr = Object.entries(testArr).map(([k, v]) => ({ [k]: v }));

    testArr = testArr.sort(function (a: any, b: any) {
      if (Object.keys(a)[0] > Object.keys(b)[0]) return 1;
      return -1;
    });

    console.log("arrGrp>>>", testArr)

    setarrayNew(testArr);



  }, [array])


  useEffect(() => {

    let valueFromList = query.get("order");
    console.log("order ID==", valueFromList)

    let buyer = query.get("buyer");

    if (valueFromList) {
      // let decoded = jwt_decode(valueFromList);
      let decoded = atob(valueFromList)
      console.log("><><><><", decoded)
      setcheckOrder(decoded);

      // const reloadCount: any = sessionStorage.getItem('reloadCount');
      // if (reloadCount < 1) {
      //   sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      //   window.location.reload();
      // } else {
      //   sessionStorage.removeItem('reloadCount');
      // }

    } else {
      setcheckOrder(false);

    }

    if (buyer) {
      // let decoded = jwt_decode(valueFromList);
      let decoded = atob(buyer)
      console.log("><><><><", decoded)
      settempUser(decoded);

      // const reloadCount: any = sessionStorage.getItem('reloadCount');
      // if (reloadCount < 1) {
      //   sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      //   window.location.reload();
      // } else {
      //   sessionStorage.removeItem('reloadCount');
      // }

    } else {
      setcheckOrder(false);

    }

  }, [query])

  const getOrder = () => {
    // const valueFromOrderSum = JSON.parse(localStorage.getItem("orderSummary"));
    // const id = valueFromOrderSum.orderNo

    OrderbyID({ ID: checkOrder, status: "Quotation Submitted" })
      .then(async (res) => {

        setlopen(false);

        console.log("order>>>>", res.data)

        const order = res.data[0]

        setdelCharge(order?.shippingCost);


        setordersummary(res.data);


        let prodDetails = JSON.parse(order?.productDetails);

        console.log("productDetails>>>>", prodDetails);


        setTotal(order?.itemTotal);
        setcartotal(order?.itemTotal);

        const newCart = prodDetails?.map((item: any, index: any) => {

          return {
            ...item,
            // images : JSON.parse(item.images),
          };
        });

        let cartArr: any = []

        const gCart = await newCart?.map(async (item: any, index: any) => {

          let proData: any = ""
          await getProductById2(item?.id)
            .then((res) => {
              // delete res.data[0].images;
              // delete res.data[0].description;
              // delete res.data[0].options;
              // delete res.data[0].priceDetails;
              // delete res.data[0].imgArray;
              // delete res.data[0].hirePrices


              let unitPrice: any = 0;
              let discount = item.discount;
              let cartQty = item.qty >= 1 ? item.qty : 1;

              res.data[0].unitprice = item.unitPrice;
              res.data[0].selectedProductColor = item.colour;
              res.data[0].selectedProductSize = item.size;
              res.data[0].qty = item.qty;

              proData = res.data[0];

              cartArr.push(res.data[0]);

              const { images, description, options, priceDetails, imgArray, hirePrices, deliveryInfo, ...prodRest } = res.data[0];


              // const discountedPrice = cartItem.discountedprice;
              // const finalProductPrice = cartItem.unitprice?.toFixed(2);
              // const finalDiscountedPrice = cartItem.discountedprice?.toFixed(2);




              // this.setState(
              //   {
              //     product: res.data[0],
              //   },
              //   () => {
              //     console.log(">>>>>>>>>>>>>>", this.state.product);
              //   }
              // )
              newCart[index] = res.data[0]
              newCart[index].unitprice = item.unitPrice;
              newCart[index].selectedProductColor = item.colour;
              newCart[index].selectedProductSize = item.size;
              newCart[index].qty = item.qty;

              newCart[index].productDetails = prodRest;
              newCart[index].productDetails.qty = item.qty;
              newCart[index].productDetails.productPrice = item.unitPrice;
              newCart[index].productDetails.unitprice = item.unitPrice;
              // newCart[index].productDetails.id = item.productId;
              newCart[index].productDetails.design = item.design;
              newCart[index].productDetails.supplierId = item.supplierId;
              newCart[index].productDetails.supplierName = item.supplierName;


            })
            .catch((error) => {
              console.log("Error", error.response);
              // this.setState({ product: "" });
            });

          return proData;

          // newItemList[index].productDetails = this.state.product;
          // newItemList[index].qty = item.qtyOrderd;
          // newItemList[index].id = item.productId;
          // delete newItemList[index].discountedprice;
          // delete newItemList[index].qtyOrderd;
          // delete newItemList[index].images;
        });



        console.log("New Item >>", newCart);

        var arrayOfValues = await Promise.all(gCart)

        console.log("toCart >>", arrayOfValues);



        setArray(arrayOfValues);



        setcartproducts(newCart);



      }).catch((err) => {
        setlopen(false);
        console.log("######", err)


      })

  }


  React.useEffect(() => {

    let newSubArray = JSON.parse(JSON.stringify(SubData));

    newSubArray = newSubArray?.map((d: any) => {
      if (d.type === "Delivery Area") {

        return {
          ...d,
          locality: upperFirst(lowerCase(d.locality)),
        };

      }

    });

    newSubArray = _.compact(newSubArray)

    setnewSubArray(_.orderBy(newSubArray, ['locality'], ['asc']));

    console.log(">>>>>>>subs", newSubArray.filter((u: any) => u.state === 0))


  }, []);

  React.useEffect(() => {
    if (checkOrder === false) {

      if (localStorage.getItem("tempUser")) {
        settempUser(localStorage.getItem("tempUser"))
      } else {
        settempUser(null);
      }

    }

  }, [checkOrder])

  React.useEffect(() => {

    if (checkOrder !== null) {
      if (checkOrder) {
        getOrder();
      } else {
        getCart();
      }
    }


    // if (!checkOrder) {
    //   getCart();
    // }

    // if (checkOrder) {
    //   getOrder();
    // }

  }, [checkOrder, cart]);

  React.useEffect(() => {
    getCurrentUser();
    listDistricts();

  }, [newSubArray, tempUser]);

  React.useEffect(() => {
    if (homeDistrictId !== 0) {
      listHomecities();
    }
  }, [homeDistrictId]);

  const listDistricts = () => {
    getDistricts()
      .then((res) => {
        console.log("districts>>>", res);
        setallHDistricts(res.data);
        // setallODistricts(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };
  const listHomecities = () => {
    getCities(homeDistrictId)
      .then((res) => {
        console.log("cities>>>", res.data);
        setallHCities(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setallHCities([])
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const handleChangeHdist = (e: any, value: any) => {
    if (value !== null) {
      sethomeDistrict(value.name_en);
      seterrhomeDistrict(false);
      sethomeDistrictId(value.id);
    } else {
      sethomeDistrict("");
      sethomeDistrictId(1);
    }

    console.log("state>>", value);
    // setnewSubs(newSubArray.filter((u: any) => u.state === value.id))

    setnewSubs([]);

    const filtered = newSubArray.filter((u: { state: any; }) => u.state === value.id)

    const perChunk = 700

    const result = filtered.reduce((filtered: any, item: any, index: any) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!filtered[chunkIndex]) {
        filtered[chunkIndex] = [] // start a new chunk
      }

      filtered[chunkIndex].push(item)

      return filtered
    }, [])

    console.log("<<<<<<<", result)

    for (let i = 0; i < result.length - 1; i++) {

      let item = result[i];

      setTimeout(() => {
        setnewSubs((existing: any) => [...existing, ...item]);
      }, i === 0 ? 0 : (2000));
    }

  };


  // useEffect(() => {

  //   if (newSubs.length > 0) {
  //     setTimeout(() => {

  //       setnewSubs2(
  //         [...newSubs2].concat(
  //           new Array(30).fill(true)
  //             .map((item) => ({...item}))
  //         )
  //       );
  //     }, 2500);
  //   }

  // }, [newSubs])


  // useEffect(() => {

  //   console.log("newSubs2>>>>>", newSubs2)

  // }, [newSubs2])





  const handleChangeHcity = (e: any, value: any) => {
    if (value !== null) {
      sethomeCity(value);

      sethomepostCode(newSubs.find((v: any) => v.locality === value).postcode)

      // sethomepostCode(value.postcode);
      seterrhomeCity(false);
      seterrhomepostCode(false);
    } else {
      sethomeCity("");
      sethomepostCode("");

    }

    console.log("city>>", value);
  };


  const getCurrentUser = () => {
    CurrentUser(tempUser ? tempUser : null)
      .then((res) => {

        if (res.statusCode === 200) {
          const cData = res.data[0];
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber,
          };
          //   this.setState({
          //     firstName: cData?.firstName,
          //     lastName: cData?.lastName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });
          console.log("<><><><>", cData);
          setcurrUser(cData);
          // setName(cData?.firstName + " " + cData?.lastName);
          setfirstName(cData?.firstName);
          setlasttName(cData?.lastName);
          setemail(cData?.email);
          setmobile(cData?.contactNumber);
          sethomeDistrict(cData?.homeDistrict !== null ? cData?.homeDistrict : "");
          sethomeAddress(cData?.homeAddress !== null ? cData?.homeAddress : "");
          sethomeCity(cData?.homeCity !== null ? cData?.homeCity : "");
          sethomeNo(cData?.homeNumber !== null ? cData?.homeNumber : "");
          sethomepostCode(cData?.homepostCode !== null ? cData?.homepostCode : "");
          setuserId(cData?.id);

          getDistricts()
            .then((res) => {

              var mobile22 = res.data?.filter(function (el: any) {
                return el.name_en === cData?.homeDistrict;
              });

              sethomeDistrictId(mobile22.length > 0 ? mobile22[0]?.id : 0)

              // setnewSubs(newSubArray.filter((u: any) => u.state === (mobile22.length > 0 ? mobile22[0]?.id : 0)))


              const filtered = newSubArray.filter((u: any) => u.state === (mobile22.length > 0 ? mobile22[0]?.id : 0))

              const perChunk = 700

              const result = filtered.reduce((filtered: any, item: any, index: any) => {
                const chunkIndex = Math.floor(index / perChunk)

                if (!filtered[chunkIndex]) {
                  filtered[chunkIndex] = [] // start a new chunk
                }

                filtered[chunkIndex].push(item)

                return filtered
              }, [])

              console.log("<<<<<<<", result)

              for (let i = 0; i < result.length - 1; i++) {

                let item = result[i];

                setTimeout(() => {
                  setnewSubs((existing: any) => [...existing, ...item]);
                }, i === 0 ? 0 : (2000));
              }

              // setallODistricts(res.data);
            })
            .catch((error) => {
              console.log("Error", error.response);
              // setOpenToast(true);
              // setColor(COLORS.Orange);
              // setAlertClass("error");
              // setMsg(error.response.data.message);
            });



          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };


  const getCart = () => {

    if (!checkOrder) {

      setlopen(true);
      getCartList({
        isQuotation: "0"
      })
        .then((res) => {
          console.log("get Cart >>>>>", res.data)
          let cartTotalPrice = 0;
          const abc = res.data?.map((e: any) => {
            const finalProductPrice = e.unitprice?.toFixed(2);

            cartTotalPrice += finalProductPrice * e.qty
            // m = m + e.qty * e.discountedprice;
          });



          setArray(res.data);


          setTotal(cartTotalPrice);
          setcartotal(cartTotalPrice);
          setlopen(false);
          CartCount().then((res) => {
            console.log("count res-------->", res);
            setCart(res);
          });


          const newCart = res.data?.map((item: any, index: any) => {

            return {
              ...item,
              // images : JSON.parse(item.images),
            };
          });

          const gCart = newCart?.map((item: any, index: any) => {
            getProductById2(item?.id)
              .then((res) => {
                delete res.data[0].images;
                delete res.data[0].description;
                delete res.data[0].options;
                delete res.data[0].priceDetails;
                delete res.data[0].imgArray;
                delete res.data[0].hirePrices;
                delete res.data[0].deliveryInfo;


                let unitPrice: any = 0;
                let discount = item.discount;
                let cartQty = item.qty >= 1 ? item.qty : 1;




                // const discountedPrice = cartItem.discountedprice;
                // const finalProductPrice = cartItem.unitprice?.toFixed(2);
                // const finalDiscountedPrice = cartItem.discountedprice?.toFixed(2);




                // this.setState(
                //   {
                //     product: res.data[0],
                //   },
                //   () => {
                //     console.log(">>>>>>>>>>>>>>", this.state.product);
                //   }
                // )
                newCart[index].productDetails = res.data[0];
                newCart[index].productDetails.qty = item.qty;
                newCart[index].productDetails.productPrice = item.unitprice;
                // newCart[index].productDetails.id = item.productId;
                newCart[index].productDetails.design = item.design;
                newCart[index].productDetails.supplierId = item.supplierId;
                newCart[index].productDetails.supplierName = item.supplierName;
              })
              .catch((error) => {
                console.log("Error", error.response);
                // this.setState({ product: "" });
              });

            // newItemList[index].productDetails = this.state.product;
            // newItemList[index].qty = item.qtyOrderd;
            // newItemList[index].id = item.productId;
            // delete newItemList[index].discountedprice;
            // delete newItemList[index].qtyOrderd;
            // delete newItemList[index].images;
          });



          console.log("New Item >>", newCart);
          setcartproducts(newCart);

          const grouped = _.groupBy(newCart, data => data.supplierId);

          let cItem = Object.entries(grouped).map(([k, v]) => ({ [k]: v }));
          // console.log("^^^^^^^^^^^", cItem);
          var cItem2 = cItem.sort(function (a, b) {
            if (Object.keys(a)[0] > Object.keys(b)[0]) return 1;
            return -1;
          });
          console.log("ZXZXZXZX", cItem2);

          var newArr: any = []

          const update2 = cItem2?.map((d) => {

            newArr.push(d[Object.keys(d)[0]])

          });

          console.log(">>>", newArr)

          setorderFinal(newArr);



          // this.setState({
          //   array: res.data,
          //   total: m,
          // });
        })
        .catch((err) => {
          setArray([]);
          setarrayNew([]);
          setTotal(0);
          setcartotal(0);
          setlopen(false);
          setCart(0);
          // this.setState({
          //   color2: "#645bff",
          //   alertClass: "success",
          //   msg: `${this.state.productData?.displayName} product add failed`,
          //   open_toast: true,
          // });
        });

    }

  }


  const renderProduct = (item: Product, index: number) => {
    const { image, price, name } = item;

    return (
      <div key={index} className="relative flex py-7 first:pt-0 last:pb-0">
        <div className="relative h-36 w-24 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={image}
            alt={name}
            className="h-full w-full object-contain object-center"
          />
          <Link to="/product-detail" className="absolute inset-0"></Link>
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-base font-semibold">
                  <Link to="/product-detail">{name}</Link>
                </h3>
                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                  <div className="flex items-center space-x-1.5">
                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M7.01 18.0001L3 13.9901C1.66 12.6501 1.66 11.32 3 9.98004L9.68 3.30005L17.03 10.6501C17.4 11.0201 17.4 11.6201 17.03 11.9901L11.01 18.0101C9.69 19.3301 8.35 19.3301 7.01 18.0001Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.35 1.94995L9.69 3.28992"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.07 11.92L17.19 11.26"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 22H16"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.85 15C18.85 15 17 17.01 17 18.24C17 19.26 17.83 20.09 18.85 20.09C19.87 20.09 20.7 19.26 20.7 18.24C20.7 17.01 18.85 15 18.85 15Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span>{`Black`}</span>
                  </div>
                  <span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
                  <div className="flex items-center space-x-1.5">
                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M21 9V3H15"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 15V21H9"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21 3L13.5 10.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.5 13.5L3 21"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span>{`2XL`}</span>
                  </div>
                </div>

                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  <select
                    name="qty"
                    id="qty"
                    className="form-select text-sm rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800 "
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                  </select>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                    price={price}
                  />
                </div>
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                <Prices price={price} className="mt-0.5" />
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-sm">
            <div className="hidden sm:block text-center relative">
              <NcInputNumber className="relative z-10" />
            </div>

            <a
              href="##"
              className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm "
            >
              <span>Remove</span>
            </a>
          </div>
        </div>
      </div>
    );
  };

  const getQuotes = async (obj: any, data: any) => {

    let resultOut = 0;

    let postObj = {

      data: obj,
      token: "FBLvw-HxMeut4bPpBuBoFA",
      // token: "h91vT47BKFp7L0qUFB22eA",
    }


    await PostQuoteApi(postObj)
      .then((res: any) => {

        // console.log("res>>>>", res.data.response)

        const filtered = res.data.response.filter((data: any) => {
          return data.success === true;
        });

        const quotes = _.map(filtered, "quotes")

        const Arr = quotes.map((item, index) => {
          return (
            item[0]
          )

        })

        const minPrice = Math.min(...Arr.map(({ price }) => price));

        let result = Arr.filter(({ price }) => price === minPrice);



        if (result.length > 0) {



          console.log("del Cal>>>>", result)

          resultOut = result[0].price


          // setdelCharge(del)
        } else {

          resultOut = 0

        }




      })
      .catch((error) => {
        console.log("Error", error.response);

      });


    return resultOut;


  }

  useEffect(() => {

    // getCartList()
    //   .then((res) => {
    //     console.log("get Cart >>>>>", res.data)

    let DelChag = 0

    // setdelCharge(0);

    const fetchData2 = async () => {

      const abcd = await array.map((data: any, index: any) => {

        let obj = {
          quote: {
            dropoff_postcode: homepostCode,
            dropoff_state: homeDistrict,
            dropoff_suburb: homeCity,
            parcel_attributes: [
              {
                qty: data?.qty,
                weight: 1
              }
            ]
          }
        }

        console.log("obj>>>>>", obj)


        const fetchData = async () => {

          const res = await getQuotes(obj, data);

          console.log("hello>>>>", res)

          let del = delCharge

          del = del + res

          // setdelCharge(del);

          setdelCharge((existing: any) => existing + res);

          DelChag = DelChag + res

        }


        fetchData();

      })




    }


    // fetchData2();

    // console.log("shipping>>>>", DelChag)


    // })
    // .catch((err) => {
    //   // setArray([]);

    // });




  }, [array, homeDistrict, homeCity, homepostCode])


  const ShippitQuote = (props: any) => {

    const item = props?.item

    const [result, setresult] = useState<any>([])



    useEffect(() => {

      let obj = {
        quote: {
          dropoff_postcode: homepostCode,
          dropoff_state: homeDistrict,
          dropoff_suburb: homeCity,
          parcel_attributes: [
            {
              qty: item?.qty,
              weight: 1
            }
          ]
        }
      }

      console.log("obj>>>>>", obj)

      let postObj = {

        data: obj,
        token: "FBLvw-HxMeut4bPpBuBoFA",
        // token: "h91vT47BKFp7L0qUFB22eA",
      }

      PostQuoteApi(postObj)
        .then((res: any) => {

          console.log("res>>>>", res.data.response)

          const filtered = res.data.response.filter((data: any) => {
            return data.success === true;
          });

          const quotes = _.map(filtered, "quotes")

          const Arr = quotes.map((item, index) => {
            return (
              item[0]
            )

          })

          const minPrice = Math.min(...Arr.map(({ price }) => price));

          let result = Arr.filter(({ price }) => price === minPrice);

          console.log("shippit>>>>", result)

          setresult(result);


        })
        .catch((error) => {
          console.log("Error", error.response);

        });

    }, [])









    return (<>
      {
        result.length > 0 ?

          <div className="text-xs text-slate-600 space-y-2 mt-3">
            <span className="capitalize flex">Del Charge : {` $ ${result[0].price}`} </span>

            <span className="capitalize flex">Est Delivery : {` ${result[0].estimated_transit_time}`}</span>
          </div>

          :
          <div className="text-xs text-slate-600 space-y-2 mt-3">
            <span className="capitalize flex">No delivery details available.</span>


          </div>

      }

    </>);

  }

  useEffect(() => {

    let delCharge = 0
    let delArr: any = []

    if (!checkOrder) {

      arrayNew.map((item: any, index: any) => {

        let data = item[Object.keys(item)[0]]

        let subData = data[0].deliveryInfo ? JSON.parse(data[0].deliveryInfo) : []

        setuserType(data[0]?.userType);

        if (data[0]?.userType === "retail supplier") {

          let res = (subData.filter((u: any) => u.state === homeDistrictId))?.find((u: any) => u.name === homeCity)

          console.log(">>>delcal", res)

          if (res) {

            delCharge += parseFloat(res.delCharge)

            delArr.push(res.delCharge)


          } else {
            delArr.push(0)

          }



        }




      })


      console.log("delTotal<><><", delArr)
      console.log("delTotal<><><", delCharge)

      setdelCharge(delCharge);
      setdelChargeArr(delArr);
    }

  }, [arrayNew, homeDistrictId, homeCity, checkOrder])

  useEffect(() => {

    let payStatus = false;

    arrayNew.map((item: any, index: any) => {

      let data = item[Object.keys(item)[0]]

      let subData = data[0].deliveryInfo ? JSON.parse(data[0].deliveryInfo) : []

      if (data[0]?.userType === "retail supplier") {

        let res = (subData.filter((u: any) => u.state === homeDistrictId))?.find((u: any) => u.name === homeCity)

        console.log(">>>delcal", res)

        if (res) {

          payStatus = true;

        } else {

          payStatus = false;
          return;

        }

      } else {
        payStatus = true
      }

    })

    console.log("payStatus<><>", payStatus)

    setpayDisable(payStatus);



  }, [arrayNew, homeDistrictId, homeCity])

  const renderProduct2 = (item: any, index: number) => {
    const { image, price, name } = item;
    let value = item?.qty;

    const thumblineImage = JSON.parse(
      item.images
    ).filter((item: any) => item.thumbnail === true)[0];

    return (
      <div
        key={index}
        className="relative flex py-8 sm:py-10 xl:py-12 first:pt-0 last:pb-0"
      >
        <div className="relative h-36 w-24 sm:w-32 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            // src={image}
            // src={`${IMAGE_SERVER_URL_DEV}${thumblineImage?.path}`}
            src={
              item.images && JSON.parse(item.images).length === 1 ?

                `${IMAGE_SERVER_URL_DEV}${JSON.parse(item.images)[0]?.path}`

                : JSON.parse(item.images).length > 1 ?

                  `${IMAGE_SERVER_URL_DEV}${JSON.parse(item.images).filter((item: { thumbnail: boolean; }) => item?.thumbnail === true)[0]?.path}`


                  :


                  EmptyIMG
            }
            alt={item?.productName}
            className="h-full w-full object-contain object-center"
          />
          <Link to={`/product-detail/${item?.productId}`} className="absolute inset-0"></Link>
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-base font-semibold">
                  <Link to={`/product-detail/${item?.productId}`} className="text-slate-900"> {item?.productName}</Link>
                </h3>
                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                  <div className="flex items-center space-x-1.5">
                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M7.01 18.0001L3 13.9901C1.66 12.6501 1.66 11.32 3 9.98004L9.68 3.30005L17.03 10.6501C17.4 11.0201 17.4 11.6201 17.03 11.9901L11.01 18.0101C9.69 19.3301 8.35 19.3301 7.01 18.0001Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.35 1.94995L9.69 3.28992"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.07 11.92L17.19 11.26"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 22H16"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.85 15C18.85 15 17 17.01 17 18.24C17 19.26 17.83 20.09 18.85 20.09C19.87 20.09 20.7 19.26 20.7 18.24C20.7 17.01 18.85 15 18.85 15Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span className="capitalize">{item?.selectedProductColor}</span>
                  </div>
                  <span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
                  <div className="flex items-center space-x-1.5">
                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M21 9V3H15"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 15V21H9"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21 3L13.5 10.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.5 13.5L3 21"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span className="capitalize">{item?.selectedProductSize}</span>
                  </div>
                </div>



                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  {/* <select
                    name="qty"
                    id="qty"
                    className="form-select text-sm rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800 "
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                  </select> */}
                  <div className="sm:block text-center relative">
                    <NcInputNumberNew className="relative z-10" max={item?.availableStocks} defaultValue={item?.qty} qty={item} getCart={getCart} />
                  </div>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                    price={item?.unitprice}
                  />
                </div>
              </div>

              {/* <div className="hidden sm:block text-center relative">
                <NcInputNumberNew className="relative z-10" max={item?.availableStocks} defaultValue={item?.qty} qty={item} getCart={getCart} />
              </div> */}


              <div className="hidden flex-1 sm:flex justify-end">
                <Prices price={item?.unitprice} className="mt-0.5" />
              </div>
            </div>
          </div>



          {
            !checkOrder &&

            <div className="flex mt-auto pt-4 items-end justify-between text-sm">
              {/* {item?.availableStocks === 0
              ? renderStatusSoldout()
              : renderStatusInstock()} */}

              <div className="hidden sm:block text-center relative">
                <NcInputNumberNew className="relative z-10" max={item?.availableStocks} defaultValue={item?.qty} qty={item} getCart={getCart} />
              </div>

              <div
                // href="##"
                className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm cursor-pointer"
              >
                <span onClick={() => {
                  setlopen(true);
                  DeleteCard(item?.cartId)
                    .then((res) => {
                      // CartCount().then((res) => {
                      //   console.log("count res-------->", res);
                      //   setCart(res);
                      // });
                      // addToast(`${single.productName} Removed`, { appearance: "error", autoDismiss: true });
                      getCart();

                      //  this.setState(
                      //    {
                      //      open_toast: true,
                      //      alertClass: "error",
                      //      msg: `${data.displayName} Removed`,
                      //      color: "#635bff",
                      //    },
                      //    () => this.getAllCart()
                      //  );
                    })
                    .catch((error) => {
                      setlopen(false);
                      console.error("delete cart item error", error);
                    }

                    );
                }}>Remove</span>
              </div>
            </div>

          }


          {/* <div className="mt-2">
            <ShippitQuote item={item} />
          </div> */}

        </div>
      </div>
    );
  };

  const renderLeft = (isActive: any = true) => {
    return (
      <div className="space-y-8">
        {/* <div id="ContactInfo" className="scroll-mt-24">
          <ContactInfo
            isActive={tabActive === "ContactInfo"}
            onOpenActive={() => {
              setTabActive("ContactInfo");
              handleScrollToEl("ContactInfo");
            }}
            onCloseActive={() => {
              setTabActive("ShippingAddress");
              handleScrollToEl("ShippingAddress");
            }}
          />
        </div> */}

        <div id="ShippingAddress" className="scroll-mt-24">
          {/* <ShippingAddress
            isActive={tabActive === "ShippingAddress"}
            onOpenActive={() => {
              setTabActive("ShippingAddress");
              handleScrollToEl("ShippingAddress");
            }}
            onCloseActive={() => {
              setTabActive("PaymentMethod");
              handleScrollToEl("PaymentMethod");
            }}
          /> */}
          <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
            <div className="p-6 flex flex-col sm:flex-row items-start">
              <span className="hidden sm:block">
                <svg
                  className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>

              <div className="sm:ml-8">
                <h3 className=" text-slate-700 dark:text-slate-300 flex ">
                  <span className="uppercase">Billing Details</span>
                  <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2.5"
                    stroke="currentColor"
                    className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </h3>
                {/* <div className="font-semibold mt-1 text-sm">
              <span className="">
                St. Paul's Road, Norris, SD 57560, Dakota, USA
              </span>
            </div> */}
              </div>
              {/* <ButtonSecondary
            sizeClass="py-2 px-4 "
            fontSize="text-sm font-medium"
            className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
            onClick={onOpenActive}
          >
            Change
          </ButtonSecondary> */}
            </div>
            <div
              className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${isActive ? "block" : "hidden"
                }`}
            >
              {/* ============ */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                <div>
                  <Label className="text-sm">First name</Label>
                  <Input className="mt-1.5"
                    onChange={(e) => {
                      setfirstName(e.target.value);
                      seterrfirstName(false);
                    }}
                    value={firstName}
                  />
                  {errfirstName && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>
                <div>
                  <Label className="text-sm">Last name</Label>
                  <Input className="mt-1.5"
                    onChange={(e) => {
                      setlasttName(e.target.value);
                      seterrlasttName(false);
                    }}
                    value={lastName}
                  />
                  {errlastName && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                <div className="max-w-lg">
                  <Label className="text-sm">Email address</Label>
                  <Input className="mt-1.5" type={"email"}
                    onChange={(e) => {
                      setemail(e.target.value);
                      seterremail(false);
                    }}
                    value={email}
                  />
                  {erremail && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>

                <div className="max-w-lg">
                  <Label className="text-sm">Your phone number</Label>
                  <Input className="mt-1.5" placeholder={"+808 xxx"} type={"tel"}
                    onChange={(e) => {
                      setmobile(e.target.value);
                      seterrmobile("");
                    }}
                    value={mobile}
                  />
                </div>
                {errmobile && (
                  <div className="text-red-600 text-sm text-right mt-2">
                    {errmobile}
                  </div>
                )}


              </div>

              {/* ============ */}
              <div>
                <div className="w-full">
                  <Label className="text-sm">Address</Label>
                  <Input
                    className="mt-1.5"
                    placeholder="House number and street name"
                    type="text"
                    onChange={(e) => {
                      sethomeAddress(e.target.value);
                      seterrhomeAddress(false);
                    }}
                    value={homeAddress}
                  />
                  {errhomeAddress && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>
                {/* <div className="sm:w-1/3">
                  <Label className="text-sm">Apt, Suite *</Label>
                  <Input className="mt-1.5" defaultValue="55U - DD5 " />
                </div> */}
              </div>

              {/* ============ */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                <div>
                  <Label className="text-sm">Country</Label>
                  <Select className="mt-1.5"
                    onChange={(e) => {
                      setcountry(e.target.value);
                      seterrcountry(false);
                    }}
                    value={country}
                  >
                    <option value={""}>Select a country</option>
                    <option value="Australia">Australia</option>
                    {/* <option value="United States">Canada</option>
                    <option value="United States">Mexico</option>
                    <option value="United States">Israel</option>
                    <option value="United States">France</option>
                    <option value="United States">England</option>
                    <option value="United States">Laos</option>
                    <option value="United States">China</option> */}
                  </Select>
                  {errcountry && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>
                <div>
                  <Label className="text-sm">State</Label>
                  {/* <Input className="mt-1.5" defaultValue="Norris" /> */}
                  <Autocomplete
                    className="mt-1.5"
                    // className="form-group"
                    // className="border-neutral-200"
                    disablePortal
                    defaultValue={{ name_en: homeDistrict }}
                    value={{ name_en: homeDistrict }}

                    // id="combo-box-demo"
                    options={allHDistricts}
                    getOptionLabel={(option) => option.name_en}
                    onChange={(event, value) => {

                      handleChangeHdist(event, value);
                      // setCountry(value);
                    }}
                    // sx={{
                    //   // width: "80vw",
                    //   background:"transparent",
                    //   borderRadius: "20px",
                    //   // border: "none",
                    //   // borderColor: "transparent",

                    //   "& .MuiInputBase-input": {
                    //     height: 15,
                    //     // border: "none",
                    //     fontSize: "14px",
                    //     fontFamily: "'Jost', sans-serif",
                    //     // borderColor: "transparent",
                    //   },
                    //   //view
                    //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    //     // borderColor: "transparent",
                    //   },

                    //   "&:hover .MuiOutlinedInput-input": {
                    //     color: "black",
                    //   },
                    //   "&:hover .MuiInputLabel-root": {
                    //     color: COLORS.GrayBackground,
                    //   },
                    //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    //   {
                    //     // borderColor: "transparent",
                    //   },
                    //   ".MuiAutocomplete-root fieldset:hover": {
                    //     color: "transparent",
                    //     // borderColor: "transparent",
                    //   },
                    // }}
                    renderInput={(params) => (
                      <div ref={params.InputProps.ref}>
                        <Input {...params.inputProps} className="mt-1.5" placeholder="Select State" />
                      </div>
                    )}
                    ListboxProps={{
                      style: {
                        maxHeight: "150px",
                        fontSize: "14px",
                        // border: '1px solid white'
                      },
                    }}
                  />

                  {errhomeDistrict && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}

                </div>

              </div>

              {/* ============ */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                <div>
                  <Label className="text-sm">Subrub / City</Label>
                  {/* <Input className="mt-1.5" defaultValue="Texas" /> */}

                  {/* <Autocomplete
                    className="mt-1.5"
                    // className="form-group"
                    // className="border-neutral-200"
                    disablePortal
                    // defaultValue={{ name: homeCity }}
                    // value={{ name: homeCity }}
                    defaultValue={{ locality: homeCity }}
                    value={{ locality: homeCity }}
                    // id="combo-box-demo"
                    options={newSubs}
                    getOptionLabel={(option) => option.locality}
                    onChange={(event, value) => {

                      handleChangeHcity(event, value);
                      // setCountry(value);
                    }}
                    // sx={{
                    //   // width: "80vw",
                    //   background:"transparent",
                    //   borderRadius: "20px",
                    //   // border: "none",
                    //   // borderColor: "transparent",

                    //   "& .MuiInputBase-input": {
                    //     height: 15,
                    //     // border: "none",
                    //     fontSize: "14px",
                    //     fontFamily: "'Jost', sans-serif",
                    //     // borderColor: "transparent",
                    //   },
                    //   //view
                    //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    //     // borderColor: "transparent",
                    //   },

                    //   "&:hover .MuiOutlinedInput-input": {
                    //     color: "black",
                    //   },
                    //   "&:hover .MuiInputLabel-root": {
                    //     color: COLORS.GrayBackground,
                    //   },
                    //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    //   {
                    //     // borderColor: "transparent",
                    //   },
                    //   ".MuiAutocomplete-root fieldset:hover": {
                    //     color: "transparent",
                    //     // borderColor: "transparent",
                    //   },
                    // }}
                    renderInput={(params) => (
                      <div ref={params.InputProps.ref}>
                        <Input {...params.inputProps} className="mt-1.5" placeholder="Select State" />

                      </div>
                    )}
                    ListboxProps={{
                      style: {
                        maxHeight: "150px",
                        fontSize: "14px",
                        // border: '1px solid white'
                      },
                    }}
                  /> */}

                  <Autocomplete
                    className="mt-1.5"
                    // className="form-group"
                    // className="border-neutral-200"
                    // disablePortal
                    // defaultValue={{ fields: { scc_name: homeCity } }}
                    // value={{ fields: { scc_name: homeCity } }}
                    // defaultValue={{ locality: homeCity }}
                    // value={{ locality: homeCity }}
                    value={homeCity}

                    // id="combo-box-demo"
                    // options={allHCities}
                    // options={newSubs2}
                    options={newSubs2}

                    // getOptionLabel={(option) => option?.fields?.scc_name}
                    getOptionLabel={(option) => option}
                    onInputChange={(event, value) => {
                      console.log(value)

                      if (value) {

                        let char = lowerCase(value)

                        let arr = [...newSubs]

                        // arr = arr.filter((data) => lowerCase(data.locality).includes(char));

                        const det = arr.map((item, index) => {

                          if (lowerCase(item.locality).startsWith(char)) {

                            return { ...item }
                          }
                        })


                        console.log(">>>>>", _.compact(det))

                        arr = _.compact(det)

                        arr = _.map(arr, "locality")

                        console.log("arr>>", arr)

                        arr = _.uniq(arr);

                        setnewSubs2(arr);

                      } else {
                        setnewSubs2([])
                      }
                    }}
                    onChange={(event, value) => {

                      handleChangeHcity(event, value);
                      // setCountry(value);
                    }}
                    // sx={{
                    //   // width: "80vw",
                    //   background:"transparent",
                    //   borderRadius: "20px",
                    //   // border: "none",
                    //   // borderColor: "transparent",

                    //   "& .MuiInputBase-input": {
                    //     height: 15,
                    //     // border: "none",
                    //     fontSize: "14px",
                    //     fontFamily: "'Jost', sans-serif",
                    //     // borderColor: "transparent",
                    //   },
                    //   //view
                    //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    //     // borderColor: "transparent",
                    //   },

                    //   "&:hover .MuiOutlinedInput-input": {
                    //     color: "black",
                    //   },
                    //   "&:hover .MuiInputLabel-root": {
                    //     color: COLORS.GrayBackground,
                    //   },
                    //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    //   {
                    //     // borderColor: "transparent",
                    //   },
                    //   ".MuiAutocomplete-root fieldset:hover": {
                    //     color: "transparent",
                    //     // borderColor: "transparent",
                    //   },
                    // }}

                    renderInput={(params) => (
                      <div ref={params.InputProps.ref}>
                        <Input {...params.inputProps} className="" placeholder="Search Suburb" />
                      </div>
                    )}
                    ListboxProps={{
                      style: {
                        maxHeight: "150px",
                        fontSize: "14px",
                        // border: '1px solid white'
                      },
                    }}
                  />

                  {errhomeCity && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}

                </div>
                <div>
                  <Label className="text-sm">Postal code</Label>
                  <Input className="mt-1.5" value={homepostCode} placeholder="Enter Postcode"
                    onChange={(e) => {
                      sethomepostCode(e.target.value);
                      seterrhomepostCode(false);
                    }}
                  />
                  {errhomepostCode && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}

                </div>
              </div>

              {/* ============ */}
              {/* <div>
            <Label className="text-sm">Address type</Label>
            <div className="mt-1.5 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3">
              <Radio
                label={`<span class="text-sm font-medium">Home <span class="font-light">(All Day Delivery)</span></span>`}
                id="Address-type-home"
                name="Address-type"
                defaultChecked
              />
              <Radio
                label={`<span class="text-sm font-medium">Office <span class="font-light">(Delivery <span class="font-medium">9 AM - 5 PM</span>)</span> </span>`}
                id="Address-type-office"
                name="Address-type"
              />
            </div>
          </div> */}

              {/* ============ */}
              {/* <div className="flex flex-col sm:flex-row pt-6">
            <ButtonPrimary
              className="sm:!px-7 shadow-none"
              onClick={onCloseActive}
            >
              Save and next to Payment
            </ButtonPrimary>
            <ButtonSecondary
              className="mt-3 sm:mt-0 sm:ml-3"
              onClick={onCloseActive}
            >
              Cancel
            </ButtonSecondary>
          </div> */}
            </div>
          </div>
        </div>

        {/* <div id="PaymentMethod" className="scroll-mt-24">
          <PaymentMethod
            isActive={tabActive === "PaymentMethod"}
            onOpenActive={() => {
              setTabActive("PaymentMethod");
              handleScrollToEl("PaymentMethod");
            }}
            onCloseActive={() => setTabActive("PaymentMethod")}
          />
        </div> */}
      </div>
    );
  };

  const onSubmit2 = async (e?: any) => {

    const phoneRegex =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    e.preventDefault();

    if (firstName === "") {
      seterrfirstName(true);
    }
    if (lastName === "") {
      seterrlasttName(true);
    }
    if (email === "") {
      seterremail(true);
    }
    if (country === "") {
      seterrcountry(true);
    }
    if (homeAddress === "") {
      seterrhomeAddress(true);
    }
    if (homeCity === "") {
      seterrhomeCity(true);
    }
    if (homeDistrict === "") {
      seterrhomeDistrict(true);
    }
    if (homepostCode === "") {
      seterrhomepostCode(true);
    }


    if (!phoneRegex.test(mobile)) {
      if (mobile === "") {
        seterrmobile("This field is required");
      } else {
        seterrmobile("Please enter valid number");
      }
    }


    // if(password != "" && repassword != "" && !(password === repassword)){
    //   seterrRePass("Password Mismatch")
    // }
    // if(password != "" && repassword != "" && (password === repassword)){
    //   seterrRePass("")
    // }

    let obj = {
      firstName: upperFirst(lowerCase(currUser.firstName)),
      lastName: upperFirst(lowerCase(currUser.lastName)),
      contactNumber: currUser.mobileNumber,
      email: currUser.email,
      homeNumber: currUser.homeNumber === null ? "" : currUser.homeNumber,
      homeAddress: homeAddress,
      homeCity: homeCity,
      password: "",

      homeDistrict: homeDistrict,
      homepostCode: homepostCode,
      note: currUser.note === null ? "" : currUser.note,

    };
    console.log("obj>>>>", obj);

    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      mobile !== "" &&
      country !== "" &&
      homeAddress !== "" &&
      homeCity !== "" &&
      homeDistrict !== "" &&
      homepostCode !== "" &&
      // (password !== "" ? repassword !== "" : firstName !== "") &&
      phoneRegex.test(mobile)


    ) {





      if (!tempUser || (tempUser && checkOrder)) {

        // EditUserBilling(obj, tempUser)
        //   .then((res) => {
        //     // setlopen(false);
        //     // setOpenToast(true);
        //     // setColor(COLORS.Primary);
        //     // setAlertClass("success");
        //     // setMsg("Successfully updated");
        //     getCurrentUser();

        //     // seteDisable(true);

        //     // history.push("/");
        //     // window.location.reload();
        //   })
        //   .catch((error) => {
        //     // setlopen(false);
        //     console.log("Error", error.response);

        //     // setOpenToast(true);
        //     // setColor(COLORS.Orange);
        //     // setAlertClass("error");
        //     // setMsg(error.response.data.message);
        //   })

      } else {

        let obj = {
          data: {
            firstName: upperFirst(lowerCase(firstName)),
            lastName: upperFirst(lowerCase(lastName)),
            mobileNumber: mobile,
            email: email,
            password: "",
            clientType: "individual",
            registered: 0,
            userId: tempUser,
            homeAddress: homeAddress,
            homeCity: homeCity,
            homeDistrict: homeDistrict,
            homepostCode: homepostCode,
            roleId: 4,
            userType: 4,
          }

        };

        RegisterUser(obj.data)
          .then((res) => {
            // setlopen(false);
            // addToast(`Registration Successful `, "success");
            // history.push("/login");
            // setaKey("login")
            // setuserId(res.data[0]);
            // setOpenToast(true);
            // setColor(COLORS.Primary);
            // setAlertClass("success");
            // setMsg("Register Successful");
            // setTimeout(() => {
            //   setlopen(false);
            //   //  setOpen(true);
            //   history.push("/login-register");
            //   // window.location.reload();
            //   // setProgress(false);
            // }, 2000);

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            setlopen(false);
            // addToast(error.response.data.message, "warning");

            // console.log("Error", error.response);
            // setProgress(false);
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          });

      }

      let Subtotal = 0;
      let TotalItem = 0;
      let orderMyNo: any = `IN-${Math.floor(100000000 + Math.random() * 900000000)}`;
      let d = moment().format("lll");

      if (cartproducts.length !== 0) {
        console.log("--------------", cartproducts);
        await cartproducts?.map((item: any) => {

          let unitPrice = 0;
          let discount = item.discount;
          let cartQty = item.qty >= 1 ? item.qty : 1;

          // const newArr = JSON.parse(item.priceDetails)?.map(single => {

          //   if (cartQty === single.qty)
          //     unitPrice = single.unitPrice
          // })


          // const discountedPrice = cartItem.discountedprice;
          // const finalProductPrice = cartItem.unitprice?.toFixed(2);
          // const finalDiscountedPrice = cartItem.discountedprice?.toFixed(2);
          // let decPlaces = countDecimals(unitPrice)

          // const finalProductPrice = decPlaces === 2 ? unitPrice?.toFixed(2) : unitPrice?.toFixed(decPlaces)
          // const discountedPrice = parseFloat(unitPrice*discount/100).toFixed(2) 
          // const finalDiscountedPrice = decPlaces === 2 ? parseFloat(unitPrice*(100-discount)/100).toFixed(2) :parseFloat(unitPrice*(100-discount)/100).toFixed(decPlaces) 


          let amountpayable = item?.qty * item?.unitprice;
          let itemTotal = item?.qty * item?.unitprice;
          Subtotal = Subtotal + amountpayable;
          TotalItem = TotalItem + itemTotal;
        });
      } else {
        addToast("Your Cart List Empty", "warning");
        // this.setState({
        //   color: "#635bff",
        //   alertClass: "success",
        //   msg: "Your Cart List Empty",
        //   open_toast: true,
        // });
        return true;
      }

      await getOrderDetails()
        .then((res) => {
          // orderMyNo = parseInt(res.data[res.data.length - 1]?.orderNo) + 1;
        })
        .catch((error) => {
          console.error("order history error", error.response);
        });


      let emailTem = ""

      let finalOrders: any = []

      if (checkOrder) {

        emailTem = await EMAIL_TAMPLETE_3({
          date: moment().format("YYYY-MM-DD"),
          address: homeAddress,
          address2: `${homeDistrict}`,
          address3: `${homeCity}`,
          address4: `${homepostCode}`,
          customerName: `${firstName}`,
          invoiceNo: `${checkOrder ? checkOrder : orderMyNo}`.trim().toString(),
          pdfName: `${firstName}_${`${checkOrder ? checkOrder : orderMyNo}`
            .trim()
            .toString()}`,
          url: `https://spotly.infinitycs.com.au/#/user-delivery/${cartproducts[0].supplierId}`
        });

      } else {

        // emailTem = await EMAIL_TAMPLETE_3({
        //   customerName: firstName,
        //   invoiceNo: `${checkOrder ? checkOrder : orderMyNo}`.trim().toString(),
        //   pdfName: `${firstName}_${`${checkOrder ? checkOrder : orderMyNo}`
        //     .trim()
        //     .toString()}`,
        // });


        await orderFinal?.map(async (order: any, index: any) => {

          let Subtotal = 0;
          let TotalItem: any = 0;
          let delivery: any = delChargeArr[index]

          let orderMyNo: any = `IN-${Math.floor(100000000 + Math.random() * 900000000)}`;

          order?.map((item: any) => {

            let amountpayable = item?.qty * item?.unitprice;
            let itemTotal = item?.qty * item?.unitprice;
            Subtotal = Subtotal + amountpayable;
            TotalItem = TotalItem + itemTotal;


          });

          let emailTem = ""

          let emailTem2 = ""

          if (userType === "wholesaler") {

            emailTem = await EMAIL_TAMPLETE({
              customerName: `${firstName}`,
              invoiceNo: `${checkOrder ? checkOrder : orderMyNo}`.trim().toString(),
              pdfName: `${firstName}_${`${checkOrder ? checkOrder : orderMyNo}`
                .trim()
                .toString()}`,
              supplier: order[0].supplierName
            });

            emailTem2 = await EMAIL_TAMPLETE_QUOTE({
              customerName: `${firstName}`,
              address: homeAddress,
              address2: `${homeDistrict}`,
              address3: `${homeCity}`,
              address4: `${homepostCode}`,
              date: moment(new Date()).format("l"),
              invoiceNo: `${checkOrder ? checkOrder : orderMyNo}`.trim().toString(),
              tax: 0,
              amount: Subtotal,
              MESSAGE: "",
              iUsers: order,
              currency: "$",
              delivery: delCharge,
            });

          } else {
            emailTem = await EMAIL_TAMPLETE_RETAIL({
              date: moment().format("YYYY-MM-DD"),
              address: homeAddress,
              address2: `${homeDistrict}`,
              address3: `${homeCity}`,
              address4: `${homepostCode}`,
              customerName: `${firstName}`,
              invoiceNo: `${checkOrder ? checkOrder : orderMyNo}`.trim().toString(),
              pdfName: `${firstName}_${`${checkOrder ? checkOrder : orderMyNo}`
                .trim()
                .toString()}`,
              // url: `https://spotly.infinitycs.com.au/#/user-delivery/${order[0].supplierId}`
            });

            emailTem2 = await EMAIL_TAMPLETE2({
              customerName: `${firstName}`,
              address: homeAddress,
              address2: `${homeDistrict}`,
              address3: `${homeCity}`,
              address4: `${homepostCode}`,
              date: moment(new Date()).format("l"),
              invoiceNo: `${checkOrder ? checkOrder : orderMyNo}`.trim().toString(),
              tax: 0,
              amount: Subtotal,
              MESSAGE: "",
              iUsers: order,
              currency: "$",
              delivery: delivery,
            });
          }




          const obj2: any = {
            // productId: item?.productId,
            orderNo: `${orderMyNo}`.trim().toString(),
            invoiceNo: `${orderMyNo}`.trim().toString(),
            userId: tempUser ? tempUser : userId,
            userName: firstName,
            email: email,
            qty: order[0].qty,
            productName: order[0]?.productName,
            productId: order[0]?.id,
            itemTotal: parseFloat(TotalItem).toFixed(2),
            shippingAddress: homeAddress,
            billingAddress: homeAddress,
            productPrice: order[0]?.discountedprice,
            // shippingCost: shippingcost,
            amountPayable: parseFloat(TotalItem + parseFloat(delivery)).toFixed(2),
            payment: "Card",
            date: moment().format("lll"),
            productDetails: _.map(order, "productDetails"),
            image: _.map(order, "images"),
            customerDetails: {
              customer: currUser,
              billing: obj,
              shipping: obj,
            },
            firstName: firstName,
            lastName: lastName,
            phoneNumber: mobile,
            billingState: homeDistrict,
            shippingState: homeDistrict,
            billingSuburb: homeCity,
            shippingSuburb: homeCity,
            billingpostCode: homepostCode,
            shippingpostCode: homepostCode,
            emailBody: emailTem,
            pdfName: `${firstName}_${`${orderMyNo}_${userType === "wholesaler" ? "NEW_QUOTE" : "NEW_ORDER"}`
              .trim()
              .toString()}`,
            emailTemplate: emailTem2,
            subject: "",
            shippingCost: delivery,
            createdAt: moment().format("YYYY-MM-DD HH:mm:ss")
          };


          finalOrders.push(obj2);

        });

        console.log("final>>>>>", finalOrders)

      }

      const emailTem2 = await EMAIL_TAMPLETE2({
        customerName: `${firstName}`,
        address: homeAddress,
        address2: `${homeDistrict}`,
        address3: `${homeCity}`,
        address4: `${homepostCode}`,
        date: moment(new Date()).format("l"),
        invoiceNo: `${checkOrder ? checkOrder : orderMyNo}`.trim().toString(),
        tax: 0,
        amount: Subtotal,
        MESSAGE: "",
        iUsers: cartproducts,
        currency: "$",
        delivery: delCharge,
      });

      const emailRetail = await EMAIL_TAMPLETE_RETAIL({
        date: moment().format("YYYY-MM-DD"),
        address: homeAddress,
        address2: `${homeDistrict}`,
        address3: `${homeCity}`,
        address4: `${homepostCode}`,
        customerName: `${firstName}`,
        invoiceNo: `${orderMyNo}`.trim().toString(),
        pdfName: `${firstName}_${`${orderMyNo}`
          .trim()
          .toString()}`,
        // url: `https://spotly.infinitycs.com.au/#/user-delivery/${cartproducts[0]?.supplierId}`
      });

      // const emailTem = await EMAIL_TAMPLETE({
      //   date: d,
      //   currency: "$",
      //   amount: Subtotal,
      //   orderNo: orderMyNo,
      //   data: cartproducts,
      //   IMAGE_URL: IMAGE_SERVER_URL_DEV,
      //   MESSAGE: "",
      // });


      const shippingcost = 0;

      const obj2: any = {
        // productId: item?.productId,
        orderNo: `${orderMyNo}`.trim().toString(),
        invoiceNo: `${orderMyNo}`.trim().toString(),
        userId: tempUser ? tempUser : userId,
        userName: firstName,
        email: email,
        qty: cartproducts[0].qty,
        productName: cartproducts[0]?.productName,
        productId: cartproducts[0]?.id,
        itemTotal: TotalItem,
        shippingAddress: homeAddress,
        billingAddress: homeAddress,
        productPrice: cartproducts[0]?.discountedprice,
        // shippingCost: shippingcost,
        amountPayable: parseFloat(Subtotal.toFixed(2)),
        payment: "Card",
        date: moment().format("lll"),
        productDetails: _.map(cartproducts, "productDetails"),
        image: _.map(cartproducts, "images"),
        customerDetails: {
          customer: currUser,
          billing: obj,
          shipping: obj,
        },
        firstName: firstName,
        lastName: lastName,
        phoneNumber: mobile,
        billingState: homeDistrict,
        shippingState: homeDistrict,
        billingSuburb: homeCity,
        shippingSuburb: homeCity,
        billingpostCode: homepostCode,
        shippingpostCode: homepostCode,
        emailBody: emailRetail,
        pdfName: `${firstName}_${`${orderMyNo}`
          .trim()
          .toString()}`,
        emailTemplate: emailTem2,
        subject: "",
        shippingCost: delCharge,
        createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        orders: finalOrders,
        orderType: "retail supplier"
      };




      if (checkOrder) {

        let objNew = {
          orderNo: checkOrder,
          invoiceNo: checkOrder,
          emailBody: emailTem,
          pdfName: `${firstName}_${`${checkOrder}`
            .trim()
            .toString()}`,
          emailTemplate: emailTem2,
          subject: "",
          shippingCost: delCharge,
          amountPayable: ((Total) + delCharge).toFixed(2),
          email: email,
          productDetails: _.map(cartproducts, "productDetails"),
          itemTotal: TotalItem,
          image: _.map(cartproducts, "images"),
          date: moment().format("lll"),
          billingState: homeDistrict,
          shippingState: homeDistrict,
          billingSuburb: homeCity,
          shippingSuburb: homeCity,
          billingpostCode: homepostCode,
          shippingpostCode: homepostCode,
          shippingAddress: homeAddress,
          billingAddress: homeAddress,
          userId: tempUser ? tempUser : userId,
          userName: firstName,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: mobile,
          createdAt: moment().format("YYYY-MM-DD HH:mm:ss")
        }


        setlopen(true);

        OrderSave(objNew)
          .then((res) => {
            // addToast("Your Quotation Successfully Sent", "success");
            // getCart();
            if (res.data !== undefined) {
              window.location.href = res.data;
            } else {
              history.push("/cart");
            }

            // this.setState(
            //   {
            //     color: "#635bff",
            //     alertClass: "success",
            //     msg: "Your Order Successfully Sent",
            //     open_toast: true,
            //   },
            //   () => {
            //     setTimeout(() => {
            //       this.props.Step4Switch();
            //     }, 2000);
            //   }
            // );

          })
          .catch((error) => {
            setlopen(false);
            addToast(error.response.data.message, "warning");
            console.log("Error", error.response);
          });

      } else if (userType === "wholesaler") {
        setlopen(true);

        OrderRequest({ orders: finalOrders })
          .then((res) => {
            setlopen(false);

            addToast("Your quotation request Successfully Sent", "success");
            getCart();
            // if (res.data !== undefined) {
            //   window.location.href = res.data;
            // } else {
            //   history.push("/cart");
            // }

            // this.setState(
            //   {
            //     color: "#635bff",
            //     alertClass: "success",
            //     msg: "Your Order Successfully Sent",
            //     open_toast: true,
            //   },
            //   () => {
            //     setTimeout(() => {
            //       this.props.Step4Switch();
            //     }, 2000);
            //   }
            // );

          })
          .catch((error) => {
            setlopen(false);
            addToast(error.response.data.message, "warning");
            console.log("Error", error.response);
          });

      } else if (userType === "retail supplier") {


        console.log("Order>>>>", obj2)

        setlopen(true);


        OrderSave(obj2)
          .then((res) => {
            setlopen(false);

            if (res.data !== undefined) {
              window.location.href = res.data;
            } else {
              history.push("/cart");
            }
            // if (res.data !== undefined) {
            //   window.location.href = res.data;
            // } else {
            //   history.push("/cart");
            // }

            // this.setState(
            //   {
            //     color: "#635bff",
            //     alertClass: "success",
            //     msg: "Your Order Successfully Sent",
            //     open_toast: true,
            //   },
            //   () => {
            //     setTimeout(() => {
            //       this.props.Step4Switch();
            //     }, 2000);
            //   }
            // );

          })
          .catch((error) => {
            setlopen(false);
            addToast(error.response.data.message, "warning");
            console.log("Error", error.response);
          });

      }








    } else {
      // scrollElement();
    }
  };

  return (
    <div className="nc-CheckoutPage">
      <LoadingSpinner open={lopen} />
      <Helmet>
        <title>Checkout || Skillnet</title>
      </Helmet>

      <main className="container py-16 lg:pb-28 lg:pt-20 ">
        <div className="mb-16">
          <h2 className="block text-2xl sm:text-2xl lg:text-4xl font-semibold ">
            Checkout
          </h2>
          <div className="block mt-3 sm:mt-5 text-[10.8px] sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link to={"/#"} className="text-slate-900">
              Homepage
            </Link>
            <span className="text-[10.8px] mx-1 sm:mx-1.5">/</span>
            {/* <Link to={"/#"} className="">
              Clothing Categories
            </Link>
            <span className="text-[10.8px] mx-1 sm:mx-1.5">/</span> */}
            <span className="underline text-slate-900">Checkout</span>
          </div>
        </div>

        {
          (array?.length > 0) ?

            <>
              <div className="flex flex-col lg:flex-row">
                <div className="flex-1">{renderLeft()}</div>

                <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>

                <div className="w-full lg:w-[36%] ">
                  <h3 className="text-base font-semibold">Order summary</h3>
                  <div className="mt-8 space-y-4 dark:divide-slate-700 ">
                    {
                      arrayNew.map((item: any, index: any) => {

                        let data = item[Object.keys(item)[0]]

                        let subData = data[0].deliveryInfo ? JSON.parse(data[0].deliveryInfo) : []


                        let res = (subData.filter((u: any) => u.state === homeDistrictId))?.find((u: any) => u.name === homeCity)

                        return (
                          <>
                            <div>
                              <h3 className="text-base font-semibold">Seller : {data[0].supplierName} </h3>
                              {data.map(renderProduct2)}


                              {
                                data[0].userType === "retail supplier" &&

                                <div className="text-sm font-semibold space-y-2 mt-3">
                                  {
                                    res ?
                                      <span className="flex">Delivery charge : {` $ ${parseFloat(res?.delCharge).toFixed(2)}`} </span>

                                      :

                                      (homeDistrict && homeCity) ?

                                        <span className="flex text-red-500">Unfortunately this  seller does not delivery goods for the selected suburb</span>

                                        :
                                        <></>

                                  }
                                </div>

                              }


                              <hr className="mt-4 border-slate-300 dark:border-slate-700" />
                            </div>

                          </>

                        );
                      })
                    }

                  </div>



                  <div className="mt-6 text-sm text-slate-500 dark:text-slate-400 dark:border-slate-700 ">
                    {/* <div>
                <Label className="text-sm">Discount code</Label>
                <div className="flex mt-1.5">
                  <Input sizeClass="h-10 px-4 py-3" className="flex-1" />
                  <button className="text-neutral-700 dark:text-neutral-200 border border-neutral-200 dark:border-neutral-700 hover:bg-neutral-100 rounded-2xl px-4 ml-3 font-medium text-sm bg-neutral-200/70 dark:bg-neutral-700 dark:hover:bg-neutral-800 w-24 flex justify-center items-center transition-colors">
                    Apply
                  </button>
                </div>
              </div> */}

                    <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">

                      {array.map(
                        (item: any, index: any) =>
                          <div className="flex justify-between pb-4 pt-4">
                            <span className="w-7/12">{`${item?.productName} ( $${item.unitprice.toFixed(2)} ) x ${item?.qty}`}</span>
                            <span className="font-semibold text-slate-900 dark:text-slate-200 whitespace-nowrap">
                              {`$ ${(item.qty * item.unitprice).toFixed(2)}`}
                            </span>

                          </div>
                      )}




                    </div>

                    <div className="mt-4 flex justify-between py-2.5">
                      <span>Subtotal</span>
                      <span className="font-semibold text-slate-900 dark:text-slate-200">
                        {`$ ${Total.toFixed(2)}`}
                      </span>
                    </div>

                    {
                      delCharge > 0 &&

                      <div className="mt-4 flex justify-between pb-2.5">
                        <span>Delivery</span>
                        <span className="font-semibold text-slate-900 dark:text-slate-200">
                          {`$ ${delCharge.toFixed(2)}`}
                        </span>
                      </div>
                    }
                    {/* <div className="flex justify-between py-2.5">
                <span>Shipping estimate</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  $5.00
                </span>
              </div>
              <div className="flex justify-between py-2.5">
                <span>Tax estimate</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  $24.90
                </span>
              </div> */}
                    <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                      <span>Order total</span>
                      <span> {`$ ${((Total) + delCharge).toFixed(2)}`}</span>
                    </div>
                  </div>
                  {
                    payDisable &&
                    <>



                      <ButtonPrimary className="mt-8 w-full" onClick={onSubmit2} >
                        {userType === "wholesaler" ? "Request Quotation" : "Confirm order"}

                      </ButtonPrimary>

                      {/* <div className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center">
                        <p className="block relative pl-5">
                          <svg
                            className="w-4 h-4 absolute -left-1 top-0.5"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12 8V13"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.9945 16H12.0035"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          Learn more{` `}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="##"
                            className="text-slate-900 dark:text-slate-200 underline font-medium"
                          >
                            Taxes
                          </a>
                          <span>
                            {` `}and{` `}
                          </span>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="##"
                            className="text-slate-900 dark:text-slate-200 underline font-medium"
                          >
                            Shipping
                          </a>
                          {` `} infomation
                        </p>
                      </div> */}

                    </>
                  }


                </div>
              </div>

            </>
            : array != null ?
              <>
                <div className="flex !mt-20 justify-center items-center">
                  <p className="text-xl font-semibold">
                    {/* #WU3746HGG12 */}
                    No items added to cart
                  </p>

                </div>
                <div className="flex mt-6 justify-center items-center">

                  <NavLink to={"/sellers"}>
                    <ButtonPrimary>Shop Now</ButtonPrimary>
                  </NavLink>
                </div>

              </>
              :
              <></>
        }


      </main>
    </div>
  );
};

export default CheckoutPage;
