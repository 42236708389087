import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1img.png";
import HIW2img from "images/HIW2img.png";
import HIW3img from "images/HIW3img.png";
import HIW4img from "images/HIW4img.png";
import VectorImg from "images/VectorHIW.svg";
import Badge from "shared/Badge/Badge";
import { getIcon } from "Services/API/Get";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import Heading from "components/Heading/Heading";

export interface SectionHowItWorkProps {
  className?: string;
  data?: typeof DEMO_DATA[0][];
  heading?: string;
  subHeading?: string;
}

const DEMO_DATA = [
  {
    id: 1,
    img: HIW1img,
    imgDark: HIW1img,
    title: "Sign up",
    desc: "Smart filtering and suggestions make it easy to find",
  },
  {
    id: 2,
    img: HIW2img,
    imgDark: HIW2img,
    title: "Build Your Store and Add Products or Services",
    desc: "Easily select the correct items and add them to the cart",
  },
  {
    id: 3,
    img: HIW3img,
    imgDark: HIW3img,
    title: "Connect the Payment Gateway (Optional)",
    desc: "The carrier will confirm and ship quickly to you",
  },
  {
    id: 4,
    img: HIW4img,
    imgDark: HIW4img,
    title: "Start Selling or Get Business Inquiries",
    desc: "Have fun and enjoy your 5-star quality products",
  },
];

const DEMO_DATA2 = [
  {
    id: "",
    caption1: "",
    caption2: "",
    image: ""

  },

];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DEMO_DATA,
  heading,
  subHeading
}) => {

  const [slides, setSlides] = React.useState([]);
  const [lopen, setlopen] = React.useState(false);

  React.useEffect(() => {
    getSlides();
  }, []);

  const getSlides = () => {
    setlopen(true);
    getIcon()
      .then((res) => {
        console.log("icon res>>>>", res.data)
        setlopen(false);


        setSlides(res.data);
      })
      .catch((error) => {
        console.log("cover error", error.response)
        setlopen(false);

      });


  };

  return (
    <div
      className={`nc-SectionHowItWork ${className}`}
      data-nc-id="SectionHowItWork"
    >
      <Heading desc={subHeading} isCenter={true}
      // hasNextPrev
      >
        {heading}
      </Heading>
      <div className="relative grid sm:grid-cols-2 lg:grid-cols-4 gap-10 sm:gap-16 xl:gap-20">
        <img
          className="hidden md:block absolute inset-x-0 top-5"
          src={VectorImg}
          alt="vector"
        />
        {DEMO_DATA.map((item: any, index: number) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            <NcImage
              containerClassName="mb-4 sm:mb-10 max-w-[140px] mx-auto"
              className="rounded-3xl"
              // src={`${IMAGE_SERVER_URL_DEV}${item?.image}`}
              src={item?.img}
            />
            <div className="text-center mt-auto space-y-5">
              <Badge
                name={`Step ${index + 1}`}
                color={
                  !index
                    ? "red"
                    : index === 1
                      ? "indigo"
                      : index === 2
                        ? "yellow"
                        : "purple"
                }
              />
              <h3 className="text-base font-semibold">{item.title}

                {
                  index === 0 &&
                  <>
                    <br />
                    <span className="text-transparent">{"Smart filtering"}</span>

                  </>


                }

              </h3>
              {/* <span className="block text-slate-600 dark:text-slate-400 text-sm leading-6">
                {item.desc}
              </span> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;
