import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link, NavLink } from "react-router-dom";
import ButtonCircle from "shared/Button/ButtonCircle";
import Company1 from "images/avatars/Image-3.png";
import Company2 from "images/avatars/Image-4.png";
import { StarIcon } from "@heroicons/react/24/solid";
import Rating from '@mui/material/Rating';


export interface CardCategory2Props {
  className?: string;
  ratioClass?: string;
  bgClass?: string;
  featuredImage?: string;
  name?: string;
  desc?: string;
}

const CardCategory2: FC<CardCategory2Props> = ({
  className = "",
  ratioClass = "aspect-w-1 aspect-h-1",
  bgClass = "bg-orange-50",
  featuredImage = ".",
  name,
  desc,
}) => {
  return (
    <NavLink to={"/single-blog"} >
      <div

        className={`nc-CardCategory2 ${className}`}
        data-nc-id="CardCategory2"
      >
        <div
        // className={`flex-1 relative w-full h-fit rounded-2xl group`}
        >

          <div className="border border-slate-200 dark:border-slate-700 rounded-xs">

            <div className="flex w-full p-5 items-center">

              <div className="w-fit pr-8">
                <img className="w-full aspect-square object-fill bg-cover rounded-2xl" src={Company1} />
              </div>

              <div className="w-full">



                <h2 className="text-base sm:text-base text-slate-900 dark:text-neutral-100 font-semibold mb-3">
                  Blog title should be here
                </h2>


                <div className="flex items-center" >
                  <div className="w-10 h-10 object-fill bg-contain rounded-full"
                    style={{
                      backgroundImage: `url(${Company2})`
                    }}
                  >

                  </div>
                  {/* <ButtonCircle
              // className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
              size=" w-11 h-11"
              type="submit"
            >
              <i className="las la-apple-alt text-4xl text-left"></i>


            </ButtonCircle> */}



                  <div className="ml-4">
                    <h2 className="text-base sm:text-base text-neutral-900 dark:text-neutral-100 font-semibold">
                      Publisher Name
                    </h2>
                    <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                      {/* <i className="las la-map-marker text-sm text-left mr-2"></i> */}

                      May 20, 2021
                    </span>
                    {/* <Rating name="size-small" defaultValue={4} size="small" className="mt-0.5" readOnly /> */}
                    {/* <div className="flex items-center space-x-0.5 mt-1.5 text-yellow-500">
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
              </div> */}


                  </div>




                </div>
                <div className="mt-4">
                  {/* <h2 className="text-base sm:text-base text-slate-900 dark:text-neutral-100 font-semibold">
                Job title
              </h2> */}


                  <div>
                    <span className="block mt-6 text-sm font-medium">
                      See completed
                      projects and flexible talent strategies
                      from companies like yours using Fiverr
                      Business.
                    </span>
                  </div>


                </div>

              </div>

              {/* <img
            className="object-cover "
            src={featuredImage}

          /> */}
              {/* <NcImage
            containerClassName="w-full h-full flex justify-center"
            src={featuredImage}
            className="object-cover rounded-2xl"
          /> */}
            </div>
          </div>
          <div className="pt-10"></div>
          {/* <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span> */}
        </div>
        {/* <div className="mt-5 flex-1 text-center">
        <h2 className="text-base sm:text-base text-neutral-900 dark:text-neutral-100 font-semibold">
          {name}
        </h2>
        <span className="block mt-0.5 sm:mt-1.5 text-sm text-neutral-500 dark:text-neutral-400">
          {desc}
        </span>
      </div> */}
      </div>
    </NavLink>
  );
};

export default CardCategory2;
