import React, { FC, useState, useEffect, useRef } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL_DEV, IMAGE_SERVER_URL_DEV_DEV, SUPPORT_URL } from "Constant/System";
import _ from "lodash";
import { makeStyles } from "@mui/styles";
import { Button, Grid, TextField, Typography, Autocomplete, Tooltip, Zoom } from "@mui/material";
import { FONTS_INTER } from "Constant";
import { COLORS } from "Constant/Colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CurrentUser, GetAllSubs2forMain, GetAllSubs3forMain, GetAllSubsforMain, GetAllUnits, GetBrands, GetColours, GetMaincategory, GetMaterials, GetSizebyUnit, GetSizes } from "Services/API/Get";
import { CreateProduct, CreateSize, uploadExcel } from "Services/API/Post";
import { addToast } from "shared/Toast";
import { UpdateProduct } from "Services/API/Put";
import ModalQuickView from "components/ModalSub1";
import ModalQuickView2 from "components/ModalEditCat";
import LoadingSpinner from "shared/LoadingSpinner";
import BotToast from "shared/BotToast";
import FileResizer from "react-image-file-resizer";

import ModalSize from "components/ModalSize";


const useStyles = makeStyles((theme) => ({
    "@global": {
        '.MuiAutocomplete-option': {
            width: "100%"
        },
    },
    root: {
        "& .MuiOutlinedInput-root": {
            padding: 7,
        },
    },
    inputRoot: {
        '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            padding: "0px",
            fontFamily: FONTS_INTER,
            fontSize: "10.5pt",

            color: COLORS.TextColor2,
            fontWeight: 400,
            outline: "none",
            border: "none",

            height: "1.6rem",
        },
        '&[class*="MuiOutlinedInput-root"] .Mui-disabled': {
            opacity: 1,
            WebkitTextFillColor: "rgba(0, 0, 0, 0.68)",
            fontWeight: "400",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",

            background: "none",
        },
    },
}));

let searchSize = ""


const CreateStore = (props) => {

    const theme = createTheme({
        palette: {
            primary: {
                main: COLORS.PrimaryBackground,
            },
            secondary: {
                main: COLORS.PrimaryBackground,
            },
        },
    });

    const modal = props?.modal
    const SelectedProduct = props?.SelectedProduct
    const product = SelectedProduct
    let options = SelectedProduct ? JSON.parse(SelectedProduct?.options) : ""

    const classes = useStyles();

    const [subcategory, setSubcategory] = useState([]);
    const [editorData, setEditorData] = useState(product ? product.description : "");

    const [status, setstatus] = useState(product ? (product.hire === 1 ? true : false) : false);
    const [images, setImages] = useState(product ? JSON.parse(product?.images) : []);
    const [categories, setcategories] = useState([]);
    const [colours, setcolours] = useState([]);
    const [sizes, setsizes] = useState([]);
    const [prices, setprices] = useState(options ? options.prices : {});
    const [stocks, setstocks] = useState(options ? options.stocks : {});
    const [subcategories, setsubcategories] = useState([]);
    const [stocksdata, setstocksdata] = useState(options ? options.stocksdata : {});
    const [productName, setproductName] = useState(product ? product?.productName : "");
    const [minOrders, setminOrders] = useState(product ? product?.minimumOrder : "");
    const [features, setfeatures] = useState(product ? product?.productFeatures : "");
    const [barCode, setbarCode] = useState("");
    const [productId, setproductId] = useState(product ? product?.productId : `P${Math.floor(100000000 + Math.random() * 900000000).toString()}`);
    const [category, setcategory] = useState(product ? product?.mainId : "");
    const [subcat, setsubcat] = useState(product ? product?.subId : "");
    const [colour, setcolour] = useState("");
    const [size, setsize] = useState("");


    const [errsize, seterrsize] = useState(false);
    const [errcolour, seterrcolour] = useState(false);
    const [errid, seterrid] = useState(false);
    const [errprodname, seterrprodname] = useState(false);
    const [errbarCode, seterrbarCode] = useState(false);
    const [errcat, seterrcat] = useState(false);
    const [errsubcat, seterrsubcat] = useState(false);
    const [errdetails, seterrdetails] = useState(false);
    const [errminOrders, seterrminOrders] = useState("");

    const [errUnit, seterrUnit] = useState(false);

    const [errSize, seterrSize] = useState(false);

    const [dispSize, setdispSize] = useState(false);

    const [modal1, setmodal1] = useState(false);
    const [modal2, setmodal2] = useState(false);

    const [nCategory, setnCategory] = useState("");
    const [nCategory2, setnCategory2] = useState("");

    const [latest, setlatest] = useState(product ? (product.latest === 1 ? true : false) : false);
    const [featured, setfeatured] = useState(product ? (product.featured === 1 ? true : false) : false);
    const [freeSamples, setfreeSamples] = useState(product ? (product.freeSamples === 1 ? true : false) : false);

    const [desc, setdesc] = useState("");
    const [errname, seterrname] = useState(false);
    const [errdesc, seterrdesc] = useState(false);

    const [selectedSize, setselectedSize] = useState({ measurement: "None", unit: "" });


    const [selectedSubs, setselectedSubs] = useState([]);

    const [allUnits, setallUnits] = useState([]);
    const [selectedUnit, setselectedUnit] = useState(0);
    const [selectedColors, setselectedColors] = useState(options.selectedColors ? options.selectedColors : []);

    const [selectedSizes, setselectedSizes] = useState(options.selectedSizes ? options.selectedSizes : []);

    const [applyAll, setapplyAll] = useState(false);

    const [currUser, setcurrUser] = useState("");

    const [imagesNew, setimagesNew] = useState(product?.imgArray ? JSON.parse(product?.imgArray) : []);

    const [subcategories2, setsubcategories2] = useState([]);
    const [subcat2, setsubcat2] = useState(product ? product?.sub2Id : "");

    const [subcategories3, setsubcategories3] = useState([]);
    const [subcat3, setsubcat3] = useState(product ? product?.sub3Id : "");

    const [showModalQuickView, setShowModalQuickView] = useState(false)

    const [showModalQuickViewSize, setShowModalQuickViewSize] = useState(false)

    const [showModalQuickView2, setShowModalQuickView2] = useState(false)

    const [showModalQuickView3, setShowModalQuickView3] = useState(false)

    const [mType, setmType] = useState("");

    const [sType, setsType] = useState("");

    const [sItem, setsItem] = useState("");

    const [discount, setdiscount] = useState(product ? product?.discount : 0);

    const [hDay, sethDay] = useState("Per Day");
    const [hOperator, sethOperator] = useState("Without Operator");
    const [hPrice, sethPrice] = useState(0);

    const [hDetails, sethDetails] = useState(product?.hirePrices ? JSON.parse(product?.hirePrices) : []);

    const [brands, setBrands] = useState([]);
    const [materials, setMaterials] = useState([]);

    const [brand, setBrand] = useState(product ? product?.brand : "");
    const [material, setMaterial] = useState(product ? product?.meterial : "");


    const [lOpen, setlOpen] = useState(false);

    const [bOpen, setbOpen] = useState(false);
    const [bMsg, setbMsg] = useState("");
    const [bStatus, setbStatus] = useState("");

    const [compSeller, setcompSeller] = useState(false);

    const [errcompSeller, seterrcompSeller] = useState(false);

    const id = "nonenone";

    const [selectedUnits, setselectedUnits] = useState(options.selectedUnits ? options.selectedUnits : []);

    const [refundDoc, setrefundDoc] = useState("");

    // const [searchSize, setsearchSize] = useState("");


    const getSubsforMain = () => {

        if (category) {
            GetAllSubsforMain(category, status === true ? 1 : 0)
                .then((res) => {
                    console.log("sub cats>>", res.data);

                    setsubcategories(res.data);
                    // if (!product)
                    //     setsubcat(res.data[0]?.id)

                    // const newArr = res.data?.map((m, index) => {

                    //   if (m.subId) {
                    //     return m.subId;
                    //   }

                    // })

                    // console.log("sub cats22222>>", _.compact(newArr));

                    // setselectedSubs(_.compact(newArr));

                    // setusers(res.data);

                    // setLogo(res.data[res.data.length - 1].image)
                    // this.setState({
                    //   logo: res.data[res.data.length - 1].image,
                    // });
                })
                .catch((error) => {
                    console.error(error);
                    setsubcategories([])
                    // setselectedSubs([]);
                    // setsubcat("");
                    // setusers([])
                });

        }

        if (category && subcat) {

            GetAllSubs2forMain({ id: category, subId: subcat, hire: status === true ? 1 : 0 })
                .then((res) => {
                    console.log("sub cats222>>", res.data);

                    setsubcategories2(res.data);
                    // if (!product)
                    //     setsubcat2(res.data[0]?.id)

                    // const newArr = res.data?.map((m, index) => {

                    //   if (m.subId) {
                    //     return m.subId;
                    //   }

                    // })

                    // console.log("sub cats22222>>", _.compact(newArr));

                    // setselectedSubs(_.compact(newArr));

                    // setusers(res.data);

                    // setLogo(res.data[res.data.length - 1].image)
                    // this.setState({
                    //   logo: res.data[res.data.length - 1].image,
                    // });
                })
                .catch((error) => {
                    console.error(error);
                    setsubcategories2([])
                    // setselectedSubs([]);
                    // setsubcat("");
                    // setusers([])
                });

        }


        if (category && subcat && subcat2) {


            GetAllSubs3forMain({ id: category, subId: subcat, sub2Id: subcat2, hire: status === true ? 1 : 0 })
                .then((res) => {
                    console.log("sub cats333>>", res.data);

                    setsubcategories3(res.data);
                    // if (!product)
                    //     setsubcat3(res.data[0]?.id)

                    // const newArr = res.data?.map((m, index) => {

                    //   if (m.subId) {
                    //     return m.subId;
                    //   }

                    // })

                    // console.log("sub cats22222>>", _.compact(newArr));

                    // setselectedSubs(_.compact(newArr));

                    // setusers(res.data);

                    // setLogo(res.data[res.data.length - 1].image)
                    // this.setState({
                    //   logo: res.data[res.data.length - 1].image,
                    // });
                })
                .catch((error) => {
                    console.error(error);
                    setsubcategories3([])
                    // setselectedSubs([]);
                    // setsubcat("");
                    // setusers([])
                });

        }
    }


    useEffect(() => {

        getSubsforMain()

    }, [category, subcat, subcat2, showModalQuickView2, status])


    const resizeFile = (file) =>
        new Promise((resolve) => {
            FileResizer.imageFileResizer(
                file,
                320,
                320,
                "PNG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });

    const CategoryGet = () => {
        GetMaincategory({
            privateStore: "",
            hire: status === true ? 1 : 0,
        })
            .then((res) => {
                console.log("cat>>", res.data);

                setcategories(res.data);
                // if (!product)
                //     setcategory(res.data[0]?.mainId)
                // setLogo(res.data[res.data.length - 1].image)
                // this.setState({
                //   logo: res.data[res.data.length - 1].image,
                // });
            })
            .catch((error) => {
                console.error(error);
            });


        GetBrands()
            .then((res) => {
                console.log("brand>>", res.data);

                setBrands(res.data);
                // if (!product)
                //     setcategory(res.data[0]?.mainId)
                // setLogo(res.data[res.data.length - 1].image)
                // this.setState({
                //   logo: res.data[res.data.length - 1].image,
                // });
            })
            .catch((error) => {
                console.error(error);
                setBrands([]);
            });

        GetMaterials()
            .then((res) => {
                console.log("mat>>", res.data);

                setMaterials(res.data);
                // if (!product)
                //     setcategory(res.data[0]?.mainId)
                // setLogo(res.data[res.data.length - 1].image)
                // this.setState({
                //   logo: res.data[res.data.length - 1].image,
                // });
            })
            .catch((error) => {
                console.error(error);
                setMaterials([]);
            });
    };

    // const SubCategoryGet = () => {
    //     GetSubcategory1()
    //         .then((res) => {
    //             console.log("sub>>", res.data);

    //             setsubcategories(res.data);
    //             setsubcat(res.data[0]?.id)
    //             // setLogo(res.data[res.data.length - 1].image)
    //             // this.setState({
    //             //   logo: res.data[res.data.length - 1].image,
    //             // });
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // };

    const ColoursGet = () => {
        GetColours()
            .then((res) => {
                console.log("colo>>", res.data);
                setcolours(res.data);
                // setsubcategories(res.data);
                // setsubcat(res.data[0]?.id)
                // setLogo(res.data[res.data.length - 1].image)
                // this.setState({
                //   logo: res.data[res.data.length - 1].image,
                // });
            })
            .catch((error) => {
                console.error(error);
            });

        // GetUnit()
        //   .then((res) => {
        //     console.log("unit>>", res.data);

        //     setsizes(res.data);
        //     // setsubcat(res.data[0]?.id)
        //     // setLogo(res.data[res.data.length - 1].image)
        //     // this.setState({
        //     //   logo: res.data[res.data.length - 1].image,
        //     // });
        //   })
        //   .catch((error) => {
        //     console.error(error);
        //   });
    };

    const getAllUnits = () => {

        GetAllUnits()
            .then((res) => {
                console.log("all unit>>", res.data);
                setallUnits(res.data)

                // setsizes(res.data);
                // setsubcat(res.data[0]?.id)
                // setLogo(res.data[res.data.length - 1].image)
                // this.setState({
                //   logo: res.data[res.data.length - 1].image,
                // });
            })
            .catch((error) => {
                console.error(error);
                setallUnits([]);
            });

    }

    const getSizeByUnit = () => {

        // GetSizes()
        //     .then((res) => {
        //         console.log("units>>", res.data);
        //         setsizes(res.data);
        //         // setsizes(res.data);
        //         // setsubcat(res.data[0]?.id)
        //         // setLogo(res.data[res.data.length - 1].image)
        //         // this.setState({
        //         //   logo: res.data[res.data.length - 1].image,
        //         // });
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //         setsizes([]);
        //     });


        GetSizebyUnit(JSON.stringify(selectedUnits.length > 0 ? _.map(selectedUnits, "id") : []))
            .then((res) => {
                console.log("units>>", res.data);
                setsizes(res.data);
                // setsizes(res.data);
                // setsubcat(res.data[0]?.id)
                // setLogo(res.data[res.data.length - 1].image)
                // this.setState({
                //   logo: res.data[res.data.length - 1].image,
                // });
            })
            .catch((error) => {
                console.error(error);
                setsizes([]);
            });

    }

    useEffect(() => {

        getSizeByUnit();

    }, [selectedUnit, selectedUnits, showModalQuickViewSize])

    useEffect(() => {

        CategoryGet();

    }, [showModalQuickView2, showModalQuickView, status])

    useEffect(() => {
        // CategoryGet();
        // SubCategoryGet();
        ColoursGet();
        getAllUnits();
        getCurrentUser();
    }, [])

    useEffect(() => {
        console.log("prices>>>", prices)
        console.log("stock>>>", stocksdata)
    }, [prices])


    useEffect(() => {
        console.log("stocks>>>", stocks)
        console.log("stock>>>", stocksdata)
    }, [stocks])

    const getCurrentUser = () => {
        CurrentUser()
            .then((res) => {
                if (res.statusCode === 200) {
                    const cData = res.data[0];
                    // setUser(cData);
                    const obj = {
                        id: cData.id,
                        email: cData?.email,
                        firstName: cData?.firstName,
                        lastName: cData?.lastName,
                        phone: cData?.mobileNumber,
                    };
                    //   this.setState({
                    //     firstName: cData?.firstName,
                    //     lastName: cData?.lastName,
                    //     email: cData?.email,
                    //     tele: cData?.phone,
                    //   });
                    console.log("<><><><>", cData);
                    setcurrUser(cData);

                    // if (!cData?.StripeKey) {

                    //     setcompSeller(true)
                    //     // setstatus(true);

                    // } else {

                    //     setcompSeller(false)
                    //     // setstatus(false);
                    // }

                    localStorage.setItem(
                        `${window.location.hostname}.profile`,
                        JSON.stringify(obj)
                    );
                }
            })
            .catch((error) => {
                console.log("error", error);
                console.log("current user error", error.response.data.message);
            });
    };

    const saveProduct2 = () => {

        let cItem = Object.entries(stocksdata).map(([k, v]) => ({ [k]: v }));
        // console.log("^^^^^^^^^^^", cItem);
        var cItem2 = cItem.sort(function (a, b) {
            if (Object.keys(a)[0] > Object.keys(b)[0]) return 1;
            return -1;
        });
        console.log("ZXZXZXZX", cItem2);

        var newArr = []

        const update2 = cItem2?.map((d) => {

            newArr.push(d[Object.keys(d)[0]])

        });

        console.log("newArr>>", newArr)


        let obj = {
            userId: currUser?.id,
            productData: {
                name: productName,
                productId: productId,
                description: editorData,
                images: images,
                mainId: category,
                subId: subcat,
                sub2Id: subcat2,
                sub3Id: subcat3,
                // isActive: status === true ? 1 : 0,
                top: 0,
                latest: latest === true ? 1 : 0,
                featured: featured === true ? 1 : 0,
                barCode: barCode,
                privateStore: 1,
                userId: currUser?.id,
                country: currUser?.country,
                state: currUser?.homeDistrict,
                suburb: currUser?.homeCity,
                postcode: currUser?.homepostCode,
                productFeatures: features,
                minimumOrder: minOrders,
                freeSamples: freeSamples === true ? 1 : 0,
                imagesNew: imagesNew,
                hire: status === true ? 1 : 0,
                discount: discount,
                hirePrices: status === true ? hDetails : [],
                brand: brand,
                meterial: material,
            },
            stocksData: !status ? newArr : [1],
            options: {
                prices: prices,
                stocks: stocks,
                stocksdata: stocksdata,
                selectedSizes: selectedSizes,
                selectedColors: selectedColors,
                selectedUnit: selectedUnit,
                selectedUnits: selectedUnits
            }
        }

        console.log("obj>>>>", obj)

        // if (productId === "") {
        //     seterrid(true)
        // }

        if (!productName) {
            seterrprodname(true)
        }

        if (!category) {
            seterrcat(true)
        }

        // if (subcat === "") {
        //     seterrsubcat(true)
        // }

        if (!status && newArr.length === 0) {
            seterrdetails(true)
        }

        if (currUser.userType === "wholesaler") {

            if (!minOrders) {
                seterrminOrders(true);
            }

        }

        // if (!status) {
        // if (compSeller) {
        //     seterrcompSeller(true)
        // }
        // }

        if (
            // productId !== "" &&
            productName !== "" &&
            category !== "" &&
            // subcat !== "" &&
            (!status ? newArr.length !== 0 : true) &&
            // newArr.length !== 0 &&
            ((currUser?.userType === "wholesaler") ? minOrders : true)
            // (compSeller !== false)
        ) {

            if (SelectedProduct) {

                setlOpen(true);
                UpdateProduct({ obj: obj, productId: "1000" })
                    .then((res) => {
                        setlOpen(false);
                        console.log("create>>", res);
                        // setproductId("");
                        // setproductName("");
                        // setImages([]);
                        // setprices([]);
                        // setstocks([]);
                        // setstocksdata([]);
                        // setselectedColors([]);
                        // setselectedSizes([]);
                        // setfeatures("");
                        // setminOrders("");
                        // setEditorData("");
                        // CategoryGet();
                        // setfreeSamples(false);


                        // addToast("Product successfully updated ", "success");
                        props?.onCloseModalQuickView();
                        // SubCategoryGet();
                        // Toastify({

                        //     duration: 3000,
                        //     text: "Product successfully added",
                        //     newWindow: true,
                        //     gravity: "bottom",
                        //     position: "center",

                        //     stopOnFocus: true,
                        //     style: {
                        //         background: "linear-gradient(to right, #00b09b, #96c93d)",
                        //     },


                        // }).showToast();

                    })
                    .catch((error) => {
                        setlOpen(false);
                        console.error(error);

                        setbOpen(true);
                        setbMsg(error.response.data.message);
                        setbStatus("warning");


                        // addToast(error.response.data.message, "warning");
                        // Toastify({

                        //     duration: 3000,
                        //     text: error.response.data.message,
                        //     newWindow: true,
                        //     gravity: "bottom",
                        //     position: "center",

                        //     stopOnFocus: true,
                        //     style: {
                        //         background: "linear-gradient(to right, #ffcc00, #96c93d)",
                        //     },


                        // }).showToast();
                    });

            } else {

                setlOpen(true);
                CreateProduct({ obj: obj })
                    .then((res) => {
                        setlOpen(false);
                        console.log("create>>", res);
                        setproductId("");
                        setproductName("");
                        setImages([]);
                        setprices([]);
                        setstocks([]);
                        setstocksdata([]);
                        setselectedColors([]);
                        setselectedSizes([]);
                        setfeatures("");
                        setminOrders("");
                        setEditorData("");
                        CategoryGet();
                        setfreeSamples(false);

                        setbOpen(true);
                        setbMsg("Product successfully added");
                        setbStatus("success");

                        // addToast("Product successfully added", "success");
                        // SubCategoryGet();
                        // Toastify({

                        //     duration: 3000,
                        //     text: "Product successfully added",
                        //     newWindow: true,
                        //     gravity: "bottom",
                        //     position: "center",

                        //     stopOnFocus: true,
                        //     style: {
                        //         background: "linear-gradient(to right, #00b09b, #96c93d)",
                        //     },


                        // }).showToast();

                    })
                    .catch((error) => {
                        setlOpen(false);
                        console.error(error);

                        setbOpen(true);
                        setbMsg(error.response.data.message);
                        setbStatus("warning");

                        // addToast(error.response.data.message, "warning");
                        // Toastify({

                        //     duration: 3000,
                        //     text: error.response.data.message,
                        //     newWindow: true,
                        //     gravity: "bottom",
                        //     position: "center",

                        //     stopOnFocus: true,
                        //     style: {
                        //         background: "linear-gradient(to right, #ffcc00, #96c93d)",
                        //     },


                        // }).showToast();
                    });

            }







        } else {
            setbOpen(true);
            setbMsg("Required fields are missing and add missing fields.");
            setbStatus("warning");
        }




    };

    useEffect(() => {
        if (bOpen === true) {
            setTimeout(() => {

                setbOpen(false)

            }, 10000);
        }
    }, [bOpen])



    return (
        <>
            <ModalQuickView
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
                type={mType}
            />

            <ModalSize
                show={showModalQuickViewSize}
                onCloseModalQuickView={() => setShowModalQuickViewSize(false)}
                type={selectedUnits[0]}
            />

            <LoadingSpinner open={lOpen} />

            <ModalQuickView2
                show={showModalQuickView2}
                onCloseModalQuickView={() => setShowModalQuickView2(false)}
                type={sType}
                mainId={category}
                subId={subcat}
                sub2Id={subcat2}
                sub3Id={subcat3}

            />

            <div className={`justify-center w-full ${modal ? "mt-2" : "mt-12"}`}>

                <span className="block text-3xl font-bold flex justify-center w-full text-center">
                    {product ? "Update Product" : "Add product"}

                </span>

                {!product &&

                    <>

                        <div className="flex justify-center">
                            <span className="text-xs font-normal flex justify-center w-full md:w-9/12 lg:w-[45%] text-center mt-1 mb-6 px-4">
                                Through this page, you can start adding products to your
                                Tidytrader online store. You can complete this task by adding products
                                one by one or by performing a bulk upload to the site. Please note that
                                you may not be able to find exact product categories or subcategories in
                                the provided dropdown. We recommend you select the most appropriate
                                category when uploading the product.
                            </span>

                        </div>


                        <div className="flex justify-center">
                            <div className="text-xs flex flex-col font-normal w-full md:w-9/12 lg:w-[45%] text-left my-2 px-4">
                                <span className="font-semibold mb-2"> Bulk Uploading </span>

                                <span>Step 1 – Download the provided email template.</span>
                                <span>Step 2 – Complete the spreadsheet with product details. (Please do not
                                    add additional columns to the given template)</span>
                                <span>Step 3 – Upload the spreadsheet.</span>
                                <span>Step 4 – Click "Save."</span>


                            </div>



                        </div>

                        <div className="flex justify-center">
                            <div className="text-xs flex flex-col font-normal w-full md:w-9/12 lg:w-[45%] text-left my-2 px-4">
                                <span className="font-semibold">OR</span>

                            </div>



                        </div>

                        <div className="flex justify-center">
                            <div className="text-xs flex flex-col font-normal w-full md:w-9/12 lg:w-[45%] text-left my-2 px-4">
                                <span className="font-semibold mb-2">Individual Uploading</span>

                                <span>Step 1 – Select either "Selling Product" or "Hire Product."</span>
                                <span>Step 2 – Add product details and product images.</span>
                                <span>Step 3 – Click "Save and Add Another."</span>



                            </div>



                        </div>

                        <div className="flex justify-center">
                            <div className="text-xs flex flex-col font-normal w-full md:w-9/12 lg:w-[45%] text-left mt-2 mb-6 px-4">
                                <a href={SUPPORT_URL} target="_blank" className="font-medium ">
                                    <span className="font-semibold">Please contact our support team if you are facing any issues when
                                        completing this task.
                                    </span>
                                </a>

                            </div>



                        </div>

                    </>


                }




            </div>
            <div className="w-full flex justify-center px-3">

                <div className={`w-full ${modal ? "md:w-11/12 lg:w-full" : "md:w-9/12 lg:w-8/12 xl:w-[50%]"} `}>

                    <div className="w-full mt-6 space-y-4">
                        {
                            !product &&

                            <>
                                <div className="mb-10 bg-gray-50 p-7">
                                    <Label className="flex mb-4 font-bold">Bulk Uploading</Label>
                                    {/* <Tooltip title={"Download excel template"} placement="top" TransitionComponent={Zoom} arrow > */}
                                    <a href={IMAGE_SERVER_URL_DEV + "/uploads/product_excel/template.xlsx"} target="_blank" >
                                        <Label className="text-sm cursor-pointer">Download excel template</Label>

                                        <i className="las la-file-download cursor-pointer ml-1 text-lg"></i>
                                    </a>
                                    {/* </Tooltip> */}
                                    <div className="w-full mt-3 xl:mt-3 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                                        <div className="grid grid-cols-10 gap-5 pl-4 pr-5">
                                            {refundDoc &&
                                                <>
                                                    <div
                                                        // key={fakerKey}
                                                        className="col-span-5 md:col-span-2 h-28 relative image-fit cursor-pointer zoom-in"
                                                    >
                                                        {/* <img
                            className="rounded-md"
                            alt="Midone - HTML Admin Template"
                            src={faker.path.includes('/uploads/products/') === true ? IMAGE_SERVER_URL + faker?.path : faker?.path}
                          /> */}
                                                        <div className="flex flex-col">
                                                            <img className="cursor-pointer w-14 h-14" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB90lEQVR4nGNgGAWjYBSMgiEJxCYEZ4hNCP4oNjHkP6lY81A96fhg3Uetg3Xp1PQAWY4n2wOHwPgD9TxApuMp9MD/UQ/AwGgMHBpNQsM0CXEXWhRwF1p84Smy+E8RLrH8L9TsMSAe+EKx42G42OI/T4MrBpZck0o2ZiAEqOZ4GB71QOpoDPwXLrP9f+f1o/8gULVpEjx5aLUE/v/x+9f/b79+/NdpDR7cSShiXhnYA2++vP8vUekEFlt5didYrGHr9KGRB3ZdPw52cOO2Gf8te2L///339//tVw/BMTQkPGDSGfn/15/f/99/+/T/2L0L///9+/ffa1r20CqFphxc8R8Glp/ZPvSK0YUnNsE9sOLsDoIeEHfXwsBC04LIxgyUeMBzahY42Zx6cPn/kbvnwZ7wn5k/NDwgXGb7/8aL+2BHg9K9/YQksGcevn32X6zCcfB7oHvPArDjd1w7ChfbdOkAWKx378LB7QGzrihw6QMqNq174+Dihh1h/3///QPGVr2xg9cDPBTgUQ9MG2kxwF1k/plqHigwHwAPFJrnUcUTBeb/hKL0v4m7a75Hx6oZDmRjBmoBCQ+t/+Ri9TR7sjHDqAegYDQG0kaT0EhPQu5aH+nvATvqTXBIemilSXhofaCfB+w+qKbYp1LNA6NgFIyCUcBADQAA6UC2Ipn+q/gAAAAASUVORK5CYII=" />
                                                            <span className="text-xs flex justify-center text-center">{refundDoc?.name}</span>
                                                        </div>
                                                        <div
                                                            className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 -mr-2 -mt-2"
                                                        >
                                                            <div
                                                                onClick={(e) => {

                                                                    // var array = [...images]
                                                                    // array.splice(fakerKey, 1);
                                                                    // setImages(array)

                                                                    setrefundDoc("")

                                                                }}
                                                            >
                                                                {/* <Lucide icon="X" className="w-4 h-4" /> */}
                                                                <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    {/* <div className="form-check">
                                            <input id="checkbox-switch-" className="form-check-input w-5 h-5 bg-gray-200 relative rounded-full text-white right-10 top-[80px]" type="checkbox"
                                                checked={faker?.thumbnail}

                                                onChange={(e) => {
                                                    var array = [...images]
                                                    const index = _.map(array, "thumbnail").indexOf(true);

                                                    // console.log("index>>>", index)

                                                    if (index < 0) {
                                                        array[fakerKey].thumbnail = e.target.checked;
                                                        setImages(array)
                                                    } else {
                                                        array[index].thumbnail = false;
                                                        array[fakerKey].thumbnail = e.target.checked;
                                                        setImages(array)
                                                    }
                                                    // array.filter(person => person.thumbnail === true)
                                                    // array[fakerKey].thumbnail = e.target.checked;
                                                    // setImages(array)
                                                    // console.log("<<<<<",e.target.checked)
                                                    // setlatest(e.target.checked)
                                                    // onChangeUserArr(e, arr.privilege)
                                                    // this.onChangeAllArr(e, arr.privilege);
                                                }}
                                            />
                                           


                                        </div> */}
                                                </>

                                            }
                                        </div>
                                        <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative">
                                            {/* <Lucide icon="Image" className="w-4 h-4 mr-2" /> */}
                                            <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                                            <span className="text-primary mr-1">
                                                Upload or drag and drop the
                                            </span>{" "}
                                            excel
                                            <input
                                                id="horizontal-form-1"
                                                type="file"
                                                accept={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
                                                className="w-full h-full top-0 left-0 absolute opacity-0"
                                                onChange={(e) => {


                                                    let file = e.target.files[0];

                                                    // seterrImages(false);

                                                    let reader = new FileReader();
                                                    reader.readAsDataURL(file);
                                                    let fileInp = e.target.files[0];
                                                    let baseURL;
                                                    // on reader load somthing...
                                                    reader.onload = () => {
                                                        // Make a fileInfo Object
                                                        baseURL = reader.result;
                                                        let obj =
                                                        {
                                                            path: baseURL,
                                                            thumbnail: false,
                                                            name: file.name
                                                        }
                                                            ;

                                                        // setImages(images.concat(obj))

                                                        setrefundDoc(obj);

                                                    };



                                                }}
                                            />
                                        </div>

                                    </div>
                                    <span className="flex justify-end text-xs">(.excel only)</span>

                                    <button
                                        className={
                                            `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-8`}

                                        onClick={() => {

                                            let obj = {
                                                refundDoc
                                            }

                                            setlOpen(true);

                                            uploadExcel({ obj: refundDoc, userId: currUser.id })
                                                .then((res) => {
                                                    setlOpen(false);

                                                    console.log("excel>>", res);
                                                    addToast("Excel successfully uploaded", "success");
                                                    setrefundDoc("");
                                                    // setsizes(res.data);
                                                    // setsubcat(res.data[0]?.id)
                                                    // setLogo(res.data[res.data.length - 1].image)
                                                    // this.setState({
                                                    //   logo: res.data[res.data.length - 1].image,
                                                    // });
                                                })
                                                .catch((error) => {
                                                    setlOpen(false);

                                                    console.error(error);
                                                    addToast(error.response.data.message, "warning");
                                                });



                                        }}
                                    >
                                        {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                                        <span className="block  text-sm font-medium "
                                        >

                                            Save

                                        </span>

                                    </button>

                                </div>

                            </>
                        }


                        {/* <div className="flex flex-col">


                            <Label className="text-sm">Product Hire</Label>

                            <label class="relative inline-flex items-center cursor-pointer mt-3">
                                <input type="checkbox" class="sr-only peer"
                                    onChange={(e) => {
                                        console.log(e.target.checked)
                                        setstatus(e.target.checked)

                                        if (e.target.checked) {

                                            setselectedColors([{ name: "None" }]);
                                        } else {
                                            setselectedColors([]);
                                            setstocks({});
                                            setprices({});
                                            setstocksdata({});
                                            sethDetails([]);
                                        }
                                    }}
                                    checked={status}
                                    id="product-status-active"

                                />
                                <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#2d867b]"></div>
                                <label class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer" htmlFor="product-status-active">Hire</label>
                            </label>
                        </div> */}


                        {
                            !product &&

                            <>
                                <div className="flex justify-center">
                                    <div className="flex justify-center font-normal w-full text-center mb-2">
                                        <span className="font-semibold">OR</span>

                                    </div>



                                </div>

                                <Label className="flex mb-4 font-bold">Individual Uploading</Label>

                                <div className="form-check flex">


                                    <input id="checkbox-switch-1" className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox"

                                        disabled={compSeller}
                                        // checked={!status && !compSeller}
                                        checked={!status}


                                        onChange={(e) => {
                                            if (!compSeller) {
                                                console.log("<<<<<", e.target.checked)
                                                // setlatest(e.target.checked)
                                                // setfeatured(false)
                                                setstatus(false);
                                                setselectedColors([])
                                            }

                                            // onChangeUserArr(e, arr.privilege)
                                            // this.onChangeAllArr(e, arr.privilege);
                                        }}
                                    />
                                    <label
                                        className={`form-check-label ml-2 text-base flex items-center ${compSeller ? "text-gray-400" : ""}`}
                                        htmlFor="checkbox-switch-1"
                                    >
                                        Selling Product

                                        {
                                            compSeller &&

                                            <Tooltip title={"Complete your pofile and connect to the stripe account first"} placement="top" TransitionComponent={Zoom} arrow >
                                                <i className="las la-question-circle cursor-pointer ml-1 text-lg"></i>

                                            </Tooltip>
                                        }
                                    </label>





                                    {
                                        currUser?.userType === "retail supplier" &&

                                        <>

                                            {/* <input id="checkbox-switch-2" className="ml-6 w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox"
                                                disabled={compSeller}
                                                checked={status}

                                                onChange={(e) => {
                                                    console.log("<<<<<", e.target.checked)
                                                    // setfeatured(e.target.checked)
                                                    // setlatest(false)
                                                    setstatus(true);
                                                    setselectedColors([{ name: "None" }])
                                                    // onChangeUserArr(e, arr.privilege)
                                                    // this.onChangeAllArr(e, arr.privilege);
                                                }}
                                            />
                                            <label
                                                className={`form-check-label ml-2 text-base flex items-center ${compSeller ? "text-gray-400" : ""}`}
                                                htmlFor="checkbox-switch-2"
                                            >
                                                Hire Product

                                                {
                                                    compSeller &&

                                                    <Tooltip title={"Complete your pofile and connect to the stripe account first"} placement="top" TransitionComponent={Zoom} arrow >
                                                        <i className="las la-question-circle cursor-pointer ml-1 text-lg"></i>

                                                    </Tooltip>
                                                }
                                            </label> */}

                                        </>
                                    }



                                </div>
                                {errcompSeller && (
                                    <div className="text-red-600 text-sm text-left mt-2">
                                        This field is required
                                    </div>
                                )}

                            </>
                        }



                        {/* <div className="form-check flex">
                            <input id="checkbox-switch-1" className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox"
                                checked={latest}

                                onChange={(e) => {
                                    console.log("<<<<<", e.target.checked)
                                    setlatest(e.target.checked)
                                    setfeatured(false)
                                    // onChangeUserArr(e, arr.privilege)
                                    // this.onChangeAllArr(e, arr.privilege);
                                }}
                            />
                            <label
                                className="form-check-label ml-2 text-base flex items-center"
                                htmlFor="checkbox-switch-1"
                            >
                                Hot Product
                            </label>

                            <input id="checkbox-switch-2" className="ml-6 w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox"
                                checked={featured}

                                onChange={(e) => {
                                    console.log("<<<<<", e.target.checked)
                                    setfeatured(e.target.checked)
                                    setlatest(false)
                                    // onChangeUserArr(e, arr.privilege)
                                    // this.onChangeAllArr(e, arr.privilege);
                                }}
                            />
                            <label
                                className="form-check-label ml-2 text-base flex items-center"
                                htmlFor="checkbox-switch-2"
                            >
                                Most Popular
                            </label>

                        </div> */}
                        {/* <div>
                            <Label className="text-sm">Product Id</Label>
                            <Input className="mt-1.5"
                                placeholder="Product Id"
                                disabled={product ? true : false}
                                onChange={(e) => {
                                    setproductId(e.target.value)
                                    seterrid(false)
                                }}
                                value={productId}
                            />
                            {errid && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}
                        <div>
                            <Label className="text-sm">Product Name</Label>
                            <Input className="mt-1.5"
                                placeholder="Product name"
                                onChange={(e) => {
                                    setproductName(e.target.value)
                                    seterrprodname(false);
                                }}
                                value={productName}
                            />
                            {errprodname && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        {
                            currUser.userType === "wholesaler" &&

                            <div>
                                <Label className="text-sm">Minimum Orders</Label>
                                <Input className="mt-1.5"
                                    type="number"
                                    placeholder="Minimum orders"
                                    min={0}
                                    onChange={(e) => {
                                        setminOrders(e.target.value)
                                        seterrminOrders(false);
                                    }}
                                    value={minOrders}
                                />
                                {errminOrders && (
                                    <div className="text-red-600 text-sm text-right mt-2">
                                        This field is required
                                    </div>
                                )}
                            </div>


                        }

                        {
                            !status &&

                            <div>
                                <Label className="text-sm">Discount Percentage (%)</Label>
                                <Input className="mt-1.5"
                                    type="number"
                                    placeholder="Discount"
                                    min={0}
                                    onChange={(e) => {
                                        setdiscount(e.target.value)
                                        // seterrminOrders(false);
                                    }}
                                    value={discount}
                                />
                                {/* {errminOrders && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )} */}
                            </div>
                        }


                        {/* <div>
                            <Label className="text-sm">Product Photos</Label>
                            <div className="w-full mt-3 xl:mt-6 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                                <div className="grid grid-cols-10 gap-5 pl-4 pr-5">
                                    {images?.map((faker, fakerKey) => (
                                        <>
                                            <div
                                                // key={fakerKey}
                                                className="col-span-5 md:col-span-2 h-28 relative image-fit cursor-pointer zoom-in"
                                            >
                                                <img
                                                    className="rounded-md"
                                                    alt="Midone - HTML Admin Template"
                                                    src={faker.path.includes('/uploads/products/') === true ? IMAGE_SERVER_URL_DEV_DEV + faker?.path : faker?.path}
                                                />
                                                <div
                                                    className="w-5 h-5 flex items-center justify-center position-absolute rounded-full text-white bg-danger right-0 top-0 -mt-2 -mr-2"
                                                >
                                                    <div
                                                        onClick={(e) => {

                                                            var array = [...images]
                                                            array.splice(fakerKey, 1);
                                                            setImages(array)

                                                        }}
                                                    >
                                                      
                                                        <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="form-check">
                                                <input id="checkbox-switch-" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mt-[80px]" type="checkbox"
                                                    checked={faker?.thumbnail}

                                                    onChange={(e) => {
                                                        var array = [...images]
                                                        const index = _.map(array, "thumbnail").indexOf(true);

                                                        // console.log("index>>>", index)

                                                        if (index < 0) {
                                                            array[fakerKey].thumbnail = e.target.checked;
                                                            setImages(array)
                                                        } else {
                                                            array[index].thumbnail = false;
                                                            array[fakerKey].thumbnail = e.target.checked;
                                                            setImages(array)
                                                        }
                                                        // array.filter(person => person.thumbnail === true)
                                                        // array[fakerKey].thumbnail = e.target.checked;
                                                        // setImages(array)
                                                        // console.log("<<<<<",e.target.checked)
                                                        // setlatest(e.target.checked)
                                                        // onChangeUserArr(e, arr.privilege)
                                                        // this.onChangeAllArr(e, arr.privilege);
                                                    }}
                                                />



                                            </div>
                                        </>

                                    ))}
                                </div>
                                <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative">
                                   
                                    <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                                    <span className="mr-1">
                                        Upload photos
                                    </span>{" "}
                                    or drag and drop
                                    <Input
                                        id="horizontal-form-1"
                                        type="file"
                                        className="w-full h-full top-0 left-0 absolute opacity-0"
                                        onChange={(e) => {

                                            let file = e.target.files[0];

                                            let reader = new FileReader();
                                            reader.readAsDataURL(file);
                                            let fileInp = e.target.files[0];
                                            let baseURL;
                                            // on reader load somthing...
                                            reader.onload = () => {
                                                // Make a fileInfo Object
                                                baseURL = reader.result;
                                                let obj =
                                                {
                                                    path: baseURL,
                                                    thumbnail: false,
                                                }
                                                    ;

                                                setImages(images.concat(obj))


                                            };
                                        }}
                                    />
                                </div>
                            </div>
                        </div> */}

                        {
                            !status &&

                            <>
                                <div>
                                    <Label className="text-sm">Brand</Label>
                                    <div className="flex w-full items-center mt-1.5">
                                        <div className="w-full">
                                            <Autocomplete
                                                className=""
                                                // className="form-group"
                                                // className="border-neutral-200"
                                                defaultValue={{ name: brands.find(({ id }) => id === brand)?.name ? brands.find(({ id }) => id === brand)?.name : "" }}
                                                value={{ name: brands.find(({ id }) => id === brand)?.name ? brands.find(({ id }) => id === brand)?.name : "" }}
                                                options={brands}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(event, newValue) => {

                                                    if (newValue !== null) {
                                                        setBrand(newValue?.id)
                                                        // seterrcat(false);
                                                        // setsubcat("")
                                                    } else {
                                                        setBrand("")
                                                        // setcategory("");
                                                        // seterrcat(false);
                                                    }

                                                    // setsubcat("");
                                                    // setsubcat2("");
                                                    // setsubcat3("");

                                                }}
                                                // sx={{
                                                //   // width: "80vw",
                                                //   background:"transparent",
                                                //   borderRadius: "20px",
                                                //   // border: "none",
                                                //   // borderColor: "transparent",

                                                //   "& .MuiInputBase-input": {
                                                //     height: 15,
                                                //     // border: "none",
                                                //     fontSize: "14px",
                                                //     fontFamily: "'Avenir', sans-serif",
                                                //     // borderColor: "transparent",
                                                //   },
                                                //   //view
                                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                                //     // borderColor: "transparent",
                                                //   },

                                                //   "&:hover .MuiOutlinedInput-input": {
                                                //     color: "black",
                                                //   },
                                                //   "&:hover .MuiInputLabel-root": {
                                                //     color: COLORS.GrayBackground,
                                                //   },
                                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                                //   {
                                                //     // borderColor: "transparent",
                                                //   },
                                                //   ".MuiAutocomplete-root fieldset:hover": {
                                                //     color: "transparent",
                                                //     // borderColor: "transparent",
                                                //   },
                                                // }}
                                                renderInput={(params) => (
                                                    <div ref={params.InputProps.ref}>
                                                        <Input {...params.inputProps} className="" placeholder="Select Brand" />
                                                    </div>
                                                )}
                                                ListboxProps={{
                                                    style: {
                                                        maxHeight: "150px",
                                                        fontSize: "14px",
                                                        // border: '1px solid white'
                                                    },
                                                }}
                                            />
                                        </div>

                                        {/* <div className="flex w-fit ml-3 ">
                                    <i className="las la-plus-circle text-3xl cursor-pointer text-[#2d867b]"
                                        onClick={() => {
                                            setmType("main");
                                            setShowModalQuickView(true);
                                        }}
                                    ></i>
                                    <i className="las la-edit text-3xl cursor-pointer text-[#2d867b] ml-2"
                                        onClick={() => {
                                            setsType("main");
                                            setShowModalQuickView2(true);

                                        }}
                                    ></i>
                                </div> */}
                                    </div>

                                    {false && (
                                        <div className="text-red-600 text-sm text-right mt-2">
                                            This field is required
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <Label className="text-sm">Material</Label>
                                    <div className="flex w-full items-center mt-1.5">
                                        <div className="w-full">
                                            <Autocomplete
                                                className=""
                                                // className="form-group"
                                                // className="border-neutral-200"
                                                defaultValue={{ name: materials.find(({ id }) => id === material)?.name ? materials.find(({ id }) => id === material)?.name : "" }}
                                                value={{ name: materials.find(({ id }) => id === material)?.name ? materials.find(({ id }) => id === material)?.name : "" }}
                                                options={materials}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(event, newValue) => {

                                                    if (newValue !== null) {
                                                        setMaterial(newValue?.id)
                                                        // seterrcat(false);
                                                        // setsubcat("")
                                                    } else {
                                                        setMaterial("")
                                                        // setcategory("");
                                                        // seterrcat(false);
                                                    }

                                                    // setsubcat("");
                                                    // setsubcat2("");
                                                    // setsubcat3("");

                                                }}
                                                // sx={{
                                                //   // width: "80vw",
                                                //   background:"transparent",
                                                //   borderRadius: "20px",
                                                //   // border: "none",
                                                //   // borderColor: "transparent",

                                                //   "& .MuiInputBase-input": {
                                                //     height: 15,
                                                //     // border: "none",
                                                //     fontSize: "14px",
                                                //     fontFamily: "'Avenir', sans-serif",
                                                //     // borderColor: "transparent",
                                                //   },
                                                //   //view
                                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                                //     // borderColor: "transparent",
                                                //   },

                                                //   "&:hover .MuiOutlinedInput-input": {
                                                //     color: "black",
                                                //   },
                                                //   "&:hover .MuiInputLabel-root": {
                                                //     color: COLORS.GrayBackground,
                                                //   },
                                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                                //   {
                                                //     // borderColor: "transparent",
                                                //   },
                                                //   ".MuiAutocomplete-root fieldset:hover": {
                                                //     color: "transparent",
                                                //     // borderColor: "transparent",
                                                //   },
                                                // }}
                                                renderInput={(params) => (
                                                    <div ref={params.InputProps.ref}>
                                                        <Input {...params.inputProps} className="" placeholder="Select Material" />
                                                    </div>
                                                )}
                                                ListboxProps={{
                                                    style: {
                                                        maxHeight: "150px",
                                                        fontSize: "14px",
                                                        // border: '1px solid white'
                                                    },
                                                }}
                                            />
                                        </div>

                                        {/* <div className="flex w-fit ml-3 ">
                                    <i className="las la-plus-circle text-3xl cursor-pointer text-[#2d867b]"
                                        onClick={() => {
                                            setmType("main");
                                            setShowModalQuickView(true);
                                        }}
                                    ></i>
                                    <i className="las la-edit text-3xl cursor-pointer text-[#2d867b] ml-2"
                                        onClick={() => {
                                            setsType("main");
                                            setShowModalQuickView2(true);

                                        }}
                                    ></i>
                                </div> */}
                                    </div>

                                    {false && (
                                        <div className="text-red-600 text-sm text-right mt-2">
                                            This field is required
                                        </div>
                                    )}
                                </div>
                            </>
                        }

                        <div>
                            <Label className="text-sm">Category</Label>
                            <div className="flex w-full items-center mt-1.5">
                                <div className="w-full">
                                    <Autocomplete
                                        className=""
                                        // className="form-group"
                                        // className="border-neutral-200"
                                        defaultValue={{ maincategory: categories.find(({ mainId }) => mainId === category)?.maincategory ? categories.find(({ mainId }) => mainId === category)?.maincategory : "" }}
                                        value={{ maincategory: categories.find(({ mainId }) => mainId === category)?.maincategory ? categories.find(({ mainId }) => mainId === category)?.maincategory : "" }}
                                        options={categories}
                                        getOptionLabel={(option) => option.maincategory}
                                        onChange={(event, newValue) => {

                                            if (newValue !== null) {
                                                setcategory(newValue?.mainId)
                                                seterrcat(false);
                                                setsubcat("")
                                            } else {
                                                setcategory("");
                                                seterrcat(false);
                                            }

                                            setsubcat("");
                                            setsubcat2("");
                                            setsubcat3("");

                                        }}
                                        // sx={{
                                        //   // width: "80vw",
                                        //   background:"transparent",
                                        //   borderRadius: "20px",
                                        //   // border: "none",
                                        //   // borderColor: "transparent",

                                        //   "& .MuiInputBase-input": {
                                        //     height: 15,
                                        //     // border: "none",
                                        //     fontSize: "14px",
                                        //     fontFamily: "'Avenir', sans-serif",
                                        //     // borderColor: "transparent",
                                        //   },
                                        //   //view
                                        //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                        //     // borderColor: "transparent",
                                        //   },

                                        //   "&:hover .MuiOutlinedInput-input": {
                                        //     color: "black",
                                        //   },
                                        //   "&:hover .MuiInputLabel-root": {
                                        //     color: COLORS.GrayBackground,
                                        //   },
                                        //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                        //   {
                                        //     // borderColor: "transparent",
                                        //   },
                                        //   ".MuiAutocomplete-root fieldset:hover": {
                                        //     color: "transparent",
                                        //     // borderColor: "transparent",
                                        //   },
                                        // }}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                                <Input {...params.inputProps} className="" placeholder="Select Category" />
                                            </div>
                                        )}
                                        ListboxProps={{
                                            style: {
                                                maxHeight: "150px",
                                                fontSize: "14px",
                                                // border: '1px solid white'
                                            },
                                        }}
                                    />
                                </div>

                                {/* <div className="flex w-fit ml-3 ">
                                    <i className="las la-plus-circle text-3xl cursor-pointer text-[#2d867b]"
                                        onClick={() => {
                                            setmType("main");
                                            setShowModalQuickView(true);
                                        }}
                                    ></i>
                                    <i className="las la-edit text-3xl cursor-pointer text-[#2d867b] ml-2"
                                        onClick={() => {
                                            setsType("main");
                                            setShowModalQuickView2(true);

                                        }}
                                    ></i>
                                </div> */}
                            </div>

                            {errcat && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">Sub Category</Label>
                            <div className="flex w-full items-center mt-1.5">
                                <div className="w-full">
                                    <Autocomplete
                                        className=""
                                        // className="form-group"
                                        // className="border-neutral-200"
                                        defaultValue={{ name: subcategories.find(({ id }) => id === subcat)?.name ? subcategories.find(({ id }) => id === subcat)?.name : "" }}
                                        value={{ name: subcategories.find(({ id }) => id === subcat)?.name ? subcategories.find(({ id }) => id === subcat)?.name : "" }}
                                        options={subcategories}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, newValue) => {

                                            if (newValue !== null) {
                                                setsubcat(newValue?.id)
                                                seterrcat(false);
                                            } else {
                                                setsubcat("");
                                                seterrcat(false);
                                            }


                                            setsubcat2("");
                                            setsubcat3("");

                                        }}
                                        // sx={{
                                        //   // width: "80vw",
                                        //   background:"transparent",
                                        //   borderRadius: "20px",
                                        //   // border: "none",
                                        //   // borderColor: "transparent",

                                        //   "& .MuiInputBase-input": {
                                        //     height: 15,
                                        //     // border: "none",
                                        //     fontSize: "14px",
                                        //     fontFamily: "'Avenir', sans-serif",
                                        //     // borderColor: "transparent",
                                        //   },
                                        //   //view
                                        //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                        //     // borderColor: "transparent",
                                        //   },

                                        //   "&:hover .MuiOutlinedInput-input": {
                                        //     color: "black",
                                        //   },
                                        //   "&:hover .MuiInputLabel-root": {
                                        //     color: COLORS.GrayBackground,
                                        //   },
                                        //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                        //   {
                                        //     // borderColor: "transparent",
                                        //   },
                                        //   ".MuiAutocomplete-root fieldset:hover": {
                                        //     color: "transparent",
                                        //     // borderColor: "transparent",
                                        //   },
                                        // }}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                                <Input {...params.inputProps} className="" placeholder="Select Sub Category" />
                                            </div>
                                        )}
                                        ListboxProps={{
                                            style: {
                                                maxHeight: "150px",
                                                fontSize: "14px",
                                                // border: '1px solid white'
                                            },
                                        }}
                                    />
                                </div>

                                {/* <div className="flex w-fit ml-3 ">
                                    <i className="las la-plus-circle text-3xl cursor-pointer text-[#2d867b]"
                                        onClick={() => {
                                            setmType("1");
                                            setShowModalQuickView(true);
                                        }}
                                    ></i>
                                    <i className="las la-edit text-3xl cursor-pointer text-[#2d867b] ml-2"
                                        onClick={() => {
                                            setsType("sub1");
                                            setShowModalQuickView2(true);

                                        }}
                                    ></i>
                                </div> */}
                            </div>
                            {errsubcat && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        {/* <div>
                            <Label className="text-sm">Sub Category2</Label>
                            <div className="flex w-full items-center mt-1.5">
                                <div className="w-full">
                                    <Autocomplete
                                        className=""
                                        // className="form-group"
                                        // className="border-neutral-200"
                                        defaultValue={{ name: subcategories2.find(({ id }) => id === subcat2)?.name ? subcategories2.find(({ id }) => id === subcat2)?.name : "" }}
                                        value={{ name: subcategories2.find(({ id }) => id === subcat2)?.name ? subcategories2.find(({ id }) => id === subcat2)?.name : "" }}
                                        options={subcategories2}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, newValue) => {

                                            if (newValue !== null) {
                                                setsubcat2(newValue?.id)
                                                // seterrcat(false);
                                            } else {
                                                setsubcat2("");
                                                // seterrcat(false);
                                            }


                                            setsubcat3("");

                                        }}
                                        // sx={{
                                        //   // width: "80vw",
                                        //   background:"transparent",
                                        //   borderRadius: "20px",
                                        //   // border: "none",
                                        //   // borderColor: "transparent",

                                        //   "& .MuiInputBase-input": {
                                        //     height: 15,
                                        //     // border: "none",
                                        //     fontSize: "14px",
                                        //     fontFamily: "'Avenir', sans-serif",
                                        //     // borderColor: "transparent",
                                        //   },
                                        //   //view
                                        //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                        //     // borderColor: "transparent",
                                        //   },

                                        //   "&:hover .MuiOutlinedInput-input": {
                                        //     color: "black",
                                        //   },
                                        //   "&:hover .MuiInputLabel-root": {
                                        //     color: COLORS.GrayBackground,
                                        //   },
                                        //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                        //   {
                                        //     // borderColor: "transparent",
                                        //   },
                                        //   ".MuiAutocomplete-root fieldset:hover": {
                                        //     color: "transparent",
                                        //     // borderColor: "transparent",
                                        //   },
                                        // }}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                                <Input {...params.inputProps} className="" placeholder="Select Sub Category2" />
                                            </div>
                                        )}
                                        ListboxProps={{
                                            style: {
                                                maxHeight: "150px",
                                                fontSize: "14px",
                                                // border: '1px solid white'
                                            },
                                        }}
                                    />
                                </div>

                                <div className="flex w-fit ml-3 ">
                                    <i className="las la-plus-circle text-3xl cursor-pointer text-[#2d867b]"
                                        onClick={() => {
                                            setmType("2");
                                            setShowModalQuickView(true);
                                        }}
                                    ></i>
                                    <i className="las la-edit text-3xl cursor-pointer text-[#2d867b] ml-2"
                                        onClick={() => {
                                            setsType("sub2");
                                            setShowModalQuickView2(true);

                                        }}
                                    ></i>
                                </div>
                            </div>

                           
                        </div> */}
                        {/* <div>
                            <Label className="text-sm">Sub Category3</Label>
                            <div className="flex w-full items-center mt-1.5">
                                <div className="w-full">
                                    <Autocomplete
                                        className=""
                                        // className="form-group"
                                        // className="border-neutral-200"
                                        defaultValue={{ name: subcategories3.find(({ id }) => id === subcat3)?.name ? subcategories3.find(({ id }) => id === subcat3)?.name : "" }}
                                        value={{ name: subcategories3.find(({ id }) => id === subcat3)?.name ? subcategories3.find(({ id }) => id === subcat3)?.name : "" }}
                                        options={subcategories3}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, newValue) => {

                                            if (newValue !== null) {
                                                setsubcat3(newValue?.id)
                                                // seterrcat(false);
                                            } else {
                                                setsubcat3("");
                                                // seterrcat(false);
                                            }

                                        }}
                                        // sx={{
                                        //   // width: "80vw",
                                        //   background:"transparent",
                                        //   borderRadius: "20px",
                                        //   // border: "none",
                                        //   // borderColor: "transparent",

                                        //   "& .MuiInputBase-input": {
                                        //     height: 15,
                                        //     // border: "none",
                                        //     fontSize: "14px",
                                        //     fontFamily: "'Avenir', sans-serif",
                                        //     // borderColor: "transparent",
                                        //   },
                                        //   //view
                                        //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                        //     // borderColor: "transparent",
                                        //   },

                                        //   "&:hover .MuiOutlinedInput-input": {
                                        //     color: "black",
                                        //   },
                                        //   "&:hover .MuiInputLabel-root": {
                                        //     color: COLORS.GrayBackground,
                                        //   },
                                        //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                        //   {
                                        //     // borderColor: "transparent",
                                        //   },
                                        //   ".MuiAutocomplete-root fieldset:hover": {
                                        //     color: "transparent",
                                        //     // borderColor: "transparent",
                                        //   },
                                        // }}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                                <Input {...params.inputProps} className="" placeholder="Select Sub Category3" />
                                            </div>
                                        )}
                                        ListboxProps={{
                                            style: {
                                                maxHeight: "150px",
                                                fontSize: "14px",
                                                // border: '1px solid white'
                                            },
                                        }}
                                    />
                                </div>

                                <div className="flex w-fit ml-3 ">
                                    <i className="las la-plus-circle text-3xl cursor-pointer text-[#2d867b]"
                                        onClick={() => {
                                            setmType("3");
                                            setShowModalQuickView(true);
                                        }}
                                    ></i>
                                    <i className="las la-edit text-3xl cursor-pointer text-[#2d867b] ml-2"
                                        onClick={() => {
                                            setsType("sub3");
                                            setShowModalQuickView2(true);

                                        }}
                                    ></i>
                                </div>
                            </div>

                         
                        </div> */}
                        {/* <div>
                            <Label className="text-sm">Product Features</Label>
                            <Textarea className="mt-1.5" rows={8}
                                onChange={(e) => {
                                    setfeatures(e.target.value);
                                    // seterrabout(false);
                                }}
                                value={features}
                            />
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}

                        <div className="prose prose-sm  w-full sm:prose lg:prose dark:prose-invert">
                            <Label className="text-sm">Product Description</Label>
                            <CKEditor

                                editor={ClassicEditor}
                                data={editorData}
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setEditorData(data);
                                    console.log({ event, editor, data });
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                            />
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        {/* {
                            !status &&

                            <div className="form-check flex">
                                <input id="checkbox-switch-1" className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox"
                                    checked={latest}

                                    onChange={(e) => {
                                        console.log("<<<<<", e.target.checked)
                                        setlatest(e.target.checked)
                                        setfeatured(false)
                                        // onChangeUserArr(e, arr.privilege)
                                        // this.onChangeAllArr(e, arr.privilege);
                                    }}
                                />
                                <label
                                    className="form-check-label ml-2 text-sm flex items-center"
                                    htmlFor="checkbox-switch-1"
                                >
                                    Offer FREE Samples to the buyers
                                </label>



                            </div>
                        } */}

                        {
                            status &&

                            <>

                                <div>

                                    <Label className="text-base font-bold">Product Hire Details</Label>


                                    <div className="w-full flex mt-4 space-x-4">

                                        <div className="w-full">
                                            <Label className="text-sm">Day</Label>
                                            <Autocomplete
                                                className="mt-1.5"
                                                // className="form-group"
                                                // className="border-neutral-200"
                                                disablePortal
                                                defaultValue={hDay}
                                                value={hDay}

                                                // id="combo-box-demo"
                                                options={["Per Day", "Per Week (Monday to Friday)", "Per Weekend"]}
                                                getOptionLabel={(option) => option}
                                                onChange={(e, value) => {
                                                    sethDay(value);


                                                    // setCountry(value);
                                                }}
                                                // sx={{
                                                //   // width: "80vw",
                                                //   background:"transparent",
                                                //   borderRadius: "20px",
                                                //   // border: "none",
                                                //   // borderColor: "transparent",

                                                //   "& .MuiInputBase-input": {
                                                //     height: 15,
                                                //     // border: "none",
                                                //     fontSize: "14px",
                                                //     fontFamily: "'Jost', sans-serif",
                                                //     // borderColor: "transparent",
                                                //   },
                                                //   //view
                                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                                //     // borderColor: "transparent",
                                                //   },

                                                //   "&:hover .MuiOutlinedInput-input": {
                                                //     color: "black",
                                                //   },
                                                //   "&:hover .MuiInputLabel-root": {
                                                //     color: COLORS.GrayBackground,
                                                //   },
                                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                                //   {
                                                //     // borderColor: "transparent",
                                                //   },
                                                //   ".MuiAutocomplete-root fieldset:hover": {
                                                //     color: "transparent",
                                                //     // borderColor: "transparent",
                                                //   },
                                                // }}
                                                renderInput={(params) => (
                                                    <div ref={params.InputProps.ref}>
                                                        <Input {...params.inputProps} className="" placeholder="Select day" />
                                                    </div>
                                                )}
                                                ListboxProps={{
                                                    style: {
                                                        maxHeight: "150px",
                                                        fontSize: "14px",
                                                        // border: '1px solid white'
                                                    },
                                                }}
                                            />


                                            {/* {errcountry && (
                                                <div className="text-red-600 text-sm text-right mt-2">
                                                    This field is required
                                                </div>
                                            )} */}
                                        </div>

                                        <div className="w-full">
                                            <Label className="text-sm">Operator</Label>
                                            <Autocomplete
                                                className="mt-1.5"
                                                // className="form-group"
                                                // className="border-neutral-200"
                                                disablePortal
                                                defaultValue={hOperator}
                                                value={hOperator}

                                                // id="combo-box-demo"
                                                options={["Without Operator", "With Operator"]}
                                                getOptionLabel={(option) => option}
                                                onChange={(e, value) => {
                                                    sethOperator(value);


                                                    // setCountry(value);
                                                }}
                                                // sx={{
                                                //   // width: "80vw",
                                                //   background:"transparent",
                                                //   borderRadius: "20px",
                                                //   // border: "none",
                                                //   // borderColor: "transparent",

                                                //   "& .MuiInputBase-input": {
                                                //     height: 15,
                                                //     // border: "none",
                                                //     fontSize: "14px",
                                                //     fontFamily: "'Jost', sans-serif",
                                                //     // borderColor: "transparent",
                                                //   },
                                                //   //view
                                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                                //     // borderColor: "transparent",
                                                //   },

                                                //   "&:hover .MuiOutlinedInput-input": {
                                                //     color: "black",
                                                //   },
                                                //   "&:hover .MuiInputLabel-root": {
                                                //     color: COLORS.GrayBackground,
                                                //   },
                                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                                //   {
                                                //     // borderColor: "transparent",
                                                //   },
                                                //   ".MuiAutocomplete-root fieldset:hover": {
                                                //     color: "transparent",
                                                //     // borderColor: "transparent",
                                                //   },
                                                // }}
                                                renderInput={(params) => (
                                                    <div ref={params.InputProps.ref}>
                                                        <Input {...params.inputProps} className="" placeholder="Select Operator" />
                                                    </div>
                                                )}
                                                ListboxProps={{
                                                    style: {
                                                        maxHeight: "150px",
                                                        fontSize: "14px",
                                                        // border: '1px solid white'
                                                    },
                                                }}
                                            />


                                            {/* {errcountry && (
                                                <div className="text-red-600 text-sm text-right mt-2">
                                                    This field is required
                                                </div>
                                            )} */}
                                        </div>

                                        <div className="w-full">
                                            <Label className="text-sm">Price</Label>

                                            <div className="flex items-center">
                                                <Input className="mt-1.5"
                                                    type="number"
                                                    placeholder="Price"
                                                    min={0}
                                                    onChange={(e) => {
                                                        sethPrice(e.target.value)
                                                        // seterrminOrders(false);
                                                    }}
                                                    value={hPrice}
                                                />

                                                <i className="las la-plus-circle text-3xl ml-3 mt-1.5 cursor-pointer text-[#2d867b]"
                                                    onClick={() => {

                                                        let obj = {
                                                            qty: hDay,
                                                            operator: hOperator === "With Operator" ? true : false,
                                                            unitPrice: hPrice !== "" ? hPrice : 0,
                                                        }
                                                        console.log("add tax====", obj)

                                                        sethDetails(hDetails.concat(obj))


                                                    }}
                                                ></i>

                                            </div>


                                            {/* {errcountry && (
                                                <div className="text-red-600 text-sm text-right mt-2">
                                                    This field is required
                                                </div>
                                            )} */}
                                        </div>




                                    </div>

                                    <div className="w-full mt-4 space-y-3">
                                        {
                                            hDetails?.map((item, index) => {

                                                let operator = item.operator === true ? "With Operator" : "Without Operator"

                                                return (
                                                    <div className="flex flex-col">
                                                        <div className="flex items-center">

                                                            <i className="las la-trash-alt text-xl text-left ml-2 text-red-500 cursor-pointer"
                                                                onClick={() => {

                                                                    var Arr = [...hDetails]

                                                                    Arr.splice(index, 1)

                                                                    sethDetails(Arr);

                                                                    // sethDetails(Arr.splice(index, 1));


                                                                }}
                                                            ></i>

                                                            <span className="ml-4">{operator + " - " + item.qty + (item.unitPrice ? " - " + item.unitPrice : "")}</span>


                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }




                                    </div>

                                </div>



                            </>
                        }

                        {
                            status &&


                            <div className="w-full mt-3 xl:mt-0 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                                <div className="grid grid-cols-3 gap-0 gap-y-4 gapx-1">
                                    {_.groupBy(imagesNew, 'id')[id]?.map((faker, fakerKey) => (
                                        <>
                                            <div className="flex flex-col">
                                                <div
                                                    // key={fakerKey}
                                                    className="col-span-5 md:col-span-1 h-28 relative image-fit cursor-pointer zoom-in"
                                                >
                                                    <img
                                                        className="w-24 h-24 rounded-md object-fill bg-cover ml-4"
                                                        alt="Midone - HTML Admin Template"
                                                        src={faker.path.includes('/uploads/products/') === true ? IMAGE_SERVER_URL_DEV + faker?.path : faker?.path}
                                                    />
                                                    {/* <div
                                                        className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 -mt-2"
                                                    >
                                                        <div
                                                            onClick={(e) => {


                                                                var array2 = [...imagesNew]?.filter(function (el) {
                                                                    return el.id !== id;
                                                                });


                                                                console.log(">>>>>>", array2)

                                                                var array = [..._.groupBy(imagesNew, 'id')[id]]
                                                                array.splice(fakerKey, 1);
                                                                setimagesNew([...array2, ...array])


                                                            }}
                                                        >
                                                          
                                                            <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                                        </div>
                                                    </div> */}


                                                </div>
                                                {/* <div className="form-check">
                                                    <input id="checkbox-switch-" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mt-[0px] ml-2" type="checkbox"
                                                        checked={faker?.thumbnail}

                                                        onChange={(e) => {
                                                            var array = [..._.groupBy(imagesNew, 'id')[id]]
                                                            const index = _.map(array, "thumbnail").indexOf(true);

                                                            var array2 = [...imagesNew]?.filter(function (el) {
                                                                return el.id !== id;
                                                            });


                                                            console.log(">>>>>>", array2)

                                                            // console.log("index>>>", index)


                                                            if (index < 0) {
                                                                array[fakerKey].thumbnail = e.target.checked;
                                                                setimagesNew([...array2, ...array])
                                                            } else {
                                                                array[index].thumbnail = false;
                                                                array[fakerKey].thumbnail = e.target.checked;
                                                                setimagesNew([...array2, ...array])
                                                            }
                                                            // array.filter(person => person.thumbnail === true)
                                                            // array[fakerKey].thumbnail = e.target.checked;
                                                            // setImages(array)
                                                            // console.log("<<<<<",e.target.checked)
                                                            // setlatest(e.target.checked)
                                                            // onChangeUserArr(e, arr.privilege)
                                                            // this.onChangeAllArr(e, arr.privilege);
                                                        }}
                                                    />



                                                </div> */}
                                            </div>
                                        </>

                                    ))}
                                </div>

                                {
                                    (_.groupBy(imagesNew, 'id')[id]?.length < 1 || !(_.groupBy(imagesNew, 'id')[id])) &&

                                    <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative">
                                        {/* <Lucide icon="Image" className="w-4 h-4 mr-2" /> */}
                                        <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                                        <span className="mr-1">
                                            Upload photo or drag and drop
                                        </span>
                                        <input
                                            id="horizontal-form-1"
                                            type="file"
                                            // multiple
                                            accept={"image/png, image/jpeg"}
                                            className="w-full h-full top-0 left-0 absolute opacity-0"
                                            onChange={(e) => {

                                                Array.from(e.target.files).map(async (item) => {

                                                    let file = item;
                                                    let reader = new FileReader();
                                                    reader.readAsDataURL(file);
                                                    let fileInp = item;
                                                    let baseURL;
                                                    // on reader load somthing...

                                                    const image = await resizeFile(file);

                                                    let obj =
                                                    {
                                                        path: image,
                                                        thumbnail: true,
                                                        id: id,
                                                    }
                                                        ;

                                                    console.log(">>>>>", obj);

                                                    setimagesNew(existing => [...existing, obj]);

                                                    // reader.onload = () => {
                                                    //     // Make a fileInfo Object
                                                    //     baseURL = reader.result;
                                                    //     let obj =
                                                    //     {
                                                    //         path: image,
                                                    //         thumbnail: false,
                                                    //         id: id,
                                                    //     }
                                                    //         ;

                                                    //     console.log(">>>>>", obj);

                                                    //     setimagesNew(existing => [...existing, obj]);

                                                    // };
                                                })



                                            }}
                                        />
                                        {/* <Input
                                id="horizontal-form-1"
                                type="file"
                                className="w-full h-full top-0 left-0 absolute opacity-0"
                                onChange={async (e) => {

                                    let file = e.target.files[0];

                                    let reader = new FileReader();
                                    reader.readAsDataURL(file);
                                    let fileInp = e.target.files[0];
                                    let baseURL;
                                    // on reader load somthing...

                                    const image = await resizeFile(file);
                                    // console.log(image);

                                    let obj =
                                    {
                                        path: image,
                                        thumbnail: false,
                                        id: id,
                                    };


                                    setimagesNew(imagesNew.concat(obj))


                                    // reader.onload = () => {
                                    //     // Make a fileInfo Object
                                    //     baseURL = reader.result;
                                    //     // baseURL = image
                                    //     let obj =
                                    //     {
                                    //         path: baseURL,
                                    //         thumbnail: false,
                                    //         id: id,
                                    //     }
                                    //         ;

                                    //     setimagesNew(imagesNew.concat(obj))


                                    // };


                                }}
                            /> */}
                                    </div>
                                }


                            </div>
                        }

                        {
                            !status &&

                            <>
                                <div>
                                    <Label className="text-base font-bold">Product Variant Details</Label>

                                    <div>
                                        <span className="flex mt-4 text-sm">Select Unit</span>
                                        <Autocomplete
                                            // multiple
                                            size="small"
                                            className="mt-1.5"
                                            // className="form-group"
                                            // className="border-neutral-200"
                                            // value={selectedSizes}
                                            defaultValue={{ unit: "" }}
                                            value={{ unit: "" }}
                                            options={[{ unit: "None", id: 0 }, ...allUnits]}

                                            getOptionLabel={(option) => option.unit}
                                            onChange={(event, newValue) => {

                                                seterrUnit(false);

                                                if (newValue !== null) {
                                                    // setselectedUnit(newValue.id)
                                                    // setselectedUnits(newValue);

                                                    if (selectedUnits.length > 0) {

                                                        const index = _.map(selectedUnits, "id").indexOf(newValue?.id);

                                                        if (index < 0) {

                                                            setselectedUnits(selectedUnits.concat(newValue));

                                                        }

                                                    } else {
                                                        setselectedUnits(selectedUnits.concat(newValue));
                                                    }

                                                    // setselectedUnits([newValue]);


                                                    setselectedSize({ measurement: "None", unit: "" });
                                                    // setselectedSizes([]);
                                                    // seterrcat(false);
                                                } else {
                                                    // setselectedUnit(0);
                                                    // setselectedSizes([]);
                                                    // seterrcat(false);
                                                }

                                            }}
                                            sx={{
                                                // width: "80vw",
                                                background: "transparent",
                                                borderRadius: "20px",
                                                // border: "none",
                                                // borderColor: "transparent",

                                                "& .MuiInputBase-input": {
                                                    height: 15,
                                                    // border: "1px #873e23",
                                                    fontSize: "14px",
                                                    fontFamily: "'Avenir', sans-serif",
                                                    // borderColor: "#873e23",

                                                },

                                                //view
                                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                                    // borderColor: "transparent",
                                                    borderColor: "#d4d4d4",
                                                },

                                                "&:hover .MuiOutlinedInput-input": {
                                                    color: "black",
                                                    borderColor: "#d4d4d4",
                                                },
                                                "&:hover .MuiInputLabel-root": {
                                                    color: COLORS.GrayBackground,
                                                    borderColor: "#d4d4d4",
                                                },
                                                "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor: "#d4d4d4",
                                                },
                                                ".MuiAutocomplete-root fieldset:hover": {
                                                    color: "transparent",
                                                    borderColor: "transparent",
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <div ref={params.InputProps.ref}>
                                                    <Input {...params.inputProps} className="" placeholder="Select Unit" />
                                                </div>
                                            )}
                                            // renderInput={(params) => (
                                            //     <TextField
                                            //         {...params}

                                            //     />

                                            // )}
                                            ListboxProps={{
                                                style: {
                                                    maxHeight: "150px",
                                                    fontSize: "14px",
                                                    // border: '1px solid white'
                                                },
                                            }}
                                        />
                                        {errUnit && (
                                            <div className="text-red-600 text-sm text-right mt-2">
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex flex-wrap mt-0 mb-6">

                                        {
                                            selectedUnits.map((item, index) => {
                                                return (
                                                    <>

                                                        <div className="bg-[#e2e8f0] w-fit p-2 flex mr-1 mt-2">

                                                            <span className="text-sm">{item.unit}</span>

                                                            <div className="flex items-center ml-3"
                                                                onClick={(e) => {
                                                                    var array = [...selectedUnits]
                                                                    array.splice(index, 1);
                                                                    setselectedUnits([...array])
                                                                }}
                                                            >
                                                                <i className="las la-times text-base text-center  flex items-center justify-center"></i>

                                                            </div>

                                                        </div>
                                                    </>
                                                )
                                            })
                                        }


                                    </div>

                                    <div>
                                        <span className="flex mt-4 text-sm">Select Size</span>
                                        <div className="flex w-full items-center mt-1.5">

                                            <div className="w-full">
                                                <Autocomplete
                                                    // multiple
                                                    size="small"
                                                    className="mt-1.5"
                                                    // className="form-group"
                                                    // className="border-neutral-200"
                                                    // value={selectedSizes}
                                                    value={{ measurement: "" }}
                                                    options={[{ measurement: "None", unit: "" }, ...sizes]}
                                                    onInputCapture={(event, newValue) => {
                                                        console.log("<<<<<", event.target.value)

                                                        searchSize = event.target.value

                                                        seterrSize(false);

                                                        seterrUnit(false);

                                                        // setdispSize(true);
                                                    }}

                                                    getOptionLabel={(option) => option.measurement + (option.unit ? option.unit : "")}
                                                    onChange={(event, newValue) => {

                                                        console.log("select>>>>", newValue)

                                                        if (newValue) {

                                                            if (newValue.measurement != "Add new size") {



                                                                // setselectedSizes(newValue)

                                                                // if (newValue !== null) {
                                                                //   setselectedSize(newValue)
                                                                //   // seterrcat(false);
                                                                // } else {
                                                                //   setselectedSize({ measurement: "None", unit: "" });
                                                                //   // seterrcat(false);
                                                                // }

                                                                if (newValue) {
                                                                    // setselectedSizes(newValue)
                                                                    if (selectedSizes?.length > 0) {

                                                                        const index = _.map(selectedSizes, "id").indexOf(newValue?.id);

                                                                        if (index < 0) {

                                                                            setselectedSizes(selectedSizes.concat(newValue));

                                                                        }

                                                                    } else {
                                                                        setselectedSizes(selectedSizes.concat(newValue));
                                                                    }
                                                                    // seterrcat(false);
                                                                } else {
                                                                    // setselectedSizes([]);
                                                                    // seterrcat(false);

                                                                }

                                                            } else {

                                                                if (selectedUnits.length === 0) {
                                                                    seterrUnit(true);
                                                                    return;
                                                                }

                                                                setmType("main");
                                                                setShowModalQuickViewSize(true);
                                                            }

                                                        } else {
                                                            setdispSize(false)
                                                        }



                                                    }}
                                                    sx={{
                                                        // width: "80vw",
                                                        background: "transparent",
                                                        borderRadius: "20px",
                                                        // border: "none",
                                                        // borderColor: "transparent",

                                                        "& .MuiInputBase-input": {
                                                            height: 15,
                                                            // border: "1px #873e23",
                                                            fontSize: "14px",
                                                            fontFamily: "'Avenir', sans-serif",
                                                            // borderColor: "#873e23",

                                                        },

                                                        //view
                                                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                                            // borderColor: "transparent",
                                                            borderColor: "#d4d4d4",
                                                        },

                                                        "&:hover .MuiOutlinedInput-input": {
                                                            color: "black",
                                                            borderColor: "#d4d4d4",
                                                        },
                                                        "&:hover .MuiInputLabel-root": {
                                                            color: COLORS.GrayBackground,
                                                            borderColor: "#d4d4d4",
                                                        },
                                                        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                                        {
                                                            borderColor: "#d4d4d4",
                                                        },
                                                        ".MuiAutocomplete-root fieldset:hover": {
                                                            color: "transparent",
                                                            borderColor: "transparent",
                                                        },
                                                    }}
                                                    renderInput={(params) => (
                                                        <div ref={params.InputProps.ref}>
                                                            <Input {...params.inputProps} className="" placeholder="Input the size you want to add or search" />
                                                        </div>
                                                    )}
                                                    // renderInput={(params) => (
                                                    //     <TextField
                                                    //         {...params}

                                                    //     />

                                                    // )}
                                                    ListboxProps={{
                                                        style: {
                                                            maxHeight: "150px",
                                                            fontSize: "14px",
                                                            // border: '1px solid white'
                                                        },
                                                    }}
                                                />
                                            </div>

                                            {
                                                // dispSize &&

                                                <>
                                                    <div className="flex w-fit ml-3 ">
                                                        <Tooltip title={"Add new size"} placement="top" TransitionComponent={Zoom} arrow >
                                                            <i className="las la-plus-circle text-3xl cursor-pointer text-[#2d867b]"
                                                                onClick={() => {
                                                                    // setmType("main");
                                                                    // setShowModalQuickViewSize(true);
                                                                    console.log("<<<<<", searchSize)

                                                                    if (selectedUnits.length === 0) {
                                                                        seterrUnit(true);
                                                                        // return;
                                                                    }

                                                                    if (!searchSize) {
                                                                        seterrSize(true);
                                                                    }

                                                                    if (searchSize && selectedUnits.length > 0) {

                                                                        let obj = {
                                                                            measurement: searchSize,
                                                                            userId: currUser.id,
                                                                            unit: selectedUnits[0].id,
                                                                        }

                                                                        console.log("obj>>>>", obj)

                                                                        CreateSize({ obj })
                                                                            .then((res) => {
                                                                                // setlopen(false);
                                                                                addToast("Size successfully added", "success");
                                                                                getSizeByUnit();
                                                                                setdispSize(false)

                                                                            })
                                                                            .catch((error) => {
                                                                                // setlopen(false);
                                                                                console.log("Error", error.response);
                                                                                addToast(error.response.data.message, "warning");

                                                                            })

                                                                    }
                                                                }}
                                                            ></i>

                                                        </Tooltip>

                                                    </div>
                                                </>
                                            }


                                        </div>
                                        {errSize && (
                                            <div className="text-red-600 text-sm text-right mt-2">
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex flex-wrap mt-0 mb-6">

                                        {
                                            selectedSizes.map((item, index) => {
                                                return (
                                                    <>

                                                        <div className="bg-[#e2e8f0] w-fit p-2 flex mr-1 mt-2">

                                                            <span className="text-sm">{item.measurement + "" + (item.unit ? item.unit : "")}</span>

                                                            <div className="flex items-center ml-3"
                                                                onClick={(e) => {
                                                                    var array = [...selectedSizes]
                                                                    array.splice(index, 1);
                                                                    setselectedSizes([...array])
                                                                }}
                                                            >
                                                                <i className="las la-times text-base text-center  flex items-center justify-center"></i>

                                                            </div>

                                                        </div>
                                                    </>
                                                )
                                            })
                                        }


                                    </div>

                                    {
                                        !status &&

                                        <>
                                            <div>
                                                <span className="flex mt-4 text-sm">Select Color</span>
                                                <Autocomplete
                                                    // multiple
                                                    size="small"
                                                    className="mt-1.5"
                                                    // className="form-group"
                                                    // className="border-neutral-200"
                                                    // value={selectedColors}
                                                    value={{ name: "" }}
                                                    options={[{ name: "None" }, ...colours]}
                                                    getOptionLabel={(option) => option.name}
                                                    onChange={(event, newValue) => {

                                                        // if (newValue.length !== 0) {
                                                        //     setselectedColors(_.uniqBy(newValue, 'name'))
                                                        //     // seterrcat(false);
                                                        // } else {
                                                        //     setselectedColors([]);
                                                        //     // seterrcat(false);
                                                        // }

                                                        if (newValue) {
                                                            // setselectedColors(newValue)
                                                            if (selectedColors?.length > 0) {

                                                                const index = _.map(selectedColors, "name").indexOf(newValue?.name);

                                                                if (index < 0) {

                                                                    setselectedColors(selectedColors.concat(newValue));

                                                                }

                                                            } else {
                                                                setselectedColors(selectedColors.concat(newValue));
                                                            }
                                                            // seterrcat(false);
                                                        } else {
                                                            setselectedColors([]);

                                                            // seterrcat(false);
                                                        }

                                                    }}
                                                    sx={{
                                                        // width: "80vw",
                                                        background: "transparent",
                                                        borderRadius: "20px",
                                                        // border: "none",
                                                        // borderColor: "transparent",


                                                        "& .MuiInputBase-input": {
                                                            height: 15,
                                                            // border: "1px #873e23",
                                                            fontSize: "14px",
                                                            fontFamily: "'Avenir', sans-serif",
                                                            // borderColor: "#873e23",
                                                            borderColor: "#d4d4d4",


                                                        },



                                                        //view
                                                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                                            // borderColor: "transparent",
                                                            borderColor: "#d4d4d4",
                                                        },

                                                        "&:hover .MuiOutlinedInput-input": {
                                                            color: "black",
                                                            borderColor: "#d4d4d4",
                                                        },
                                                        "&:hover .MuiInputLabel-root": {
                                                            color: COLORS.GrayBackground,
                                                            borderColor: "#d4d4d4",
                                                        },
                                                        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                                        {
                                                            borderColor: "#d4d4d4",
                                                        },
                                                        ".MuiAutocomplete-root fieldset:hover": {
                                                            color: "transparent",
                                                            borderColor: "transparent",
                                                        },
                                                    }}
                                                    renderInput={(params) => (
                                                        <div ref={params.InputProps.ref}>
                                                            <Input {...params.inputProps} className="" placeholder="Select Color" />
                                                        </div>
                                                    )}
                                                    // renderInput={(params) => (
                                                    //     <TextField
                                                    //         {...params}

                                                    //     />

                                                    // )}
                                                    ListboxProps={{
                                                        style: {
                                                            maxHeight: "150px",
                                                            fontSize: "14px",
                                                            // border: '1px solid white'
                                                        },
                                                    }}
                                                />
                                                {false && (
                                                    <div className="text-red-600 text-sm text-right mt-2">
                                                        This field is required
                                                    </div>
                                                )}
                                            </div>

                                            <div className="flex flex-wrap mt-0 mb-12">

                                                {
                                                    selectedColors.map((item, index) => {
                                                        return (
                                                            <>

                                                                <div className="bg-[#e2e8f0] w-fit p-2 flex mr-1 mt-2">

                                                                    <span className="text-sm">{item.name}</span>

                                                                    <div className="flex items-center ml-3"
                                                                        onClick={(e) => {
                                                                            var array = [...selectedColors]
                                                                            array.splice(index, 1);
                                                                            setselectedColors([...array])
                                                                        }}
                                                                    >
                                                                        <i className="las la-times text-base text-center  flex items-center justify-center"></i>

                                                                    </div>

                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }


                                            </div>
                                        </>

                                    }



                                    {
                                        selectedSizes.length > 0 && (!status ? selectedColors.length > 0 : true) &&

                                        <div className="w-full overflow-x-auto">
                                            <div className="d-flex w-full mb-10 mt-4">
                                                <button
                                                    type="button"
                                                    className="btn py-2 bg-[#2d867b] rounded-md px-6 text-slate-100 text-sm mt-4"

                                                    onClick={() => {

                                                        if (Object.keys(stocksdata).length !== 0) {

                                                            console.log("data>>>>>>", stocks, prices, stocksdata)

                                                            var stockObj = {}
                                                            var priceObj = {}
                                                            var stocksDataObj = {}

                                                            var stockVal = Object.values(stocks)[0]
                                                            var pricesVal = Object.values(prices)[0]
                                                            // var stocksDataVal = Object.values(stocksdata)[0]

                                                            selectedSizes?.map((dataSize) => {


                                                                // console.log(">>>>>><SD", dataSize)

                                                                selectedColors?.map((dataClr) => {

                                                                    var id = (dataSize.measurement === "None" ? "none" : dataSize.measurement) + (dataSize.unit ? dataSize.unit : "") + (dataClr.name === "None" ? "none" : dataClr.name)

                                                                    // console.log("id>>>>>", id)

                                                                    priceObj = { ...priceObj, [id]: pricesVal }

                                                                    stockObj = { ...stockObj, [id]: stockVal }



                                                                    let obj = {

                                                                        unitPrice: pricesVal,
                                                                        discount: 0,
                                                                        stock: 1,
                                                                        size: (dataSize.measurement === "None" ? "" : dataSize.measurement) + (dataSize.unit ? dataSize.unit : ""),
                                                                        colour: (dataClr.name === "None" ? "" : dataClr.name),
                                                                        availability: 1,
                                                                        availablestocks: stockVal,
                                                                        discountedPrice: pricesVal,
                                                                        arrId: id,
                                                                        isActive: 1

                                                                    }

                                                                    stocksDataObj = { ...stocksDataObj, [id]: obj }

                                                                    // console.log(">>>>>><SDsdsd", dataClr)

                                                                });
                                                            });


                                                            console.log("final objs>>>>", priceObj, stockObj, stocksDataObj)

                                                            setprices(priceObj);
                                                            setstocks(stockObj);
                                                            setstocksdata(stocksDataObj);

                                                        }



                                                    }}
                                                >
                                                    Apply to all
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn py-2 border border-slate-300 rounded-md dark:border-darkmode-400 text-slate-500 px-6 ml-4 text-sm"

                                                    onClick={() => {
                                                        setstocks({});
                                                        setprices({});
                                                        setstocksdata({});
                                                        // console.log("data>>>>>>", stocks, prices, stocksdata)

                                                    }}
                                                >
                                                    Clear
                                                </button>

                                                <button
                                                    type="button"
                                                    className="btn py-2 ml-7 bg-[#2d867b] rounded-md px-4 text-slate-100 text-sm mt-4"

                                                    onClick={() => {

                                                        if (imagesNew.length > 0) {



                                                            var imagesVal = [...imagesNew]
                                                            var imagesObj = []

                                                            selectedSizes?.map((dataSize) => {


                                                                // console.log(">>>>>><SD", dataSize)

                                                                selectedColors?.map((dataClr) => {

                                                                    var id = (dataSize.measurement === "None" ? "none" : dataSize.measurement) + (dataSize.unit ? dataSize.unit : "") + (dataClr.name === "None" ? "none" : dataClr.name)


                                                                    imagesVal.map((data) => {

                                                                        let imgObj = {
                                                                            path: data.path,
                                                                            thumbnail: data.thumbnail,
                                                                            id: id
                                                                        }

                                                                        imagesObj.push(imgObj);

                                                                    })






                                                                    // console.log(">>>>>><SDsdsd", dataClr)

                                                                });
                                                            });


                                                            console.log("final objs>>>>", imagesObj)


                                                            setimagesNew(imagesObj);

                                                        }



                                                    }}
                                                >
                                                    Apply Images to all
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn py-2 border border-slate-300 rounded-md dark:border-darkmode-400 text-slate-500 px-6 ml-4 text-sm"

                                                    onClick={() => {

                                                        setimagesNew([]);
                                                        // console.log("data>>>>>>", stocks, prices, stocksdata)

                                                    }}
                                                >
                                                    Clear
                                                </button>
                                                {/* <Input id="checkbox-switch-app" className="form-check-input w-5 h-5 bg-gray-200" type="checkbox"
                            checked={applyAll}

                            onChange={(e) => {
                              console.log("<<<<<", e.target.checked)
                              setapplyAll(e.target.checked)


                              console.log("data>>>>>>", stocks, prices, stocksdata)
                              // setfeatured(false)
                              // onChangeUserArr(e, arr.privilege)
                              // this.onChangeAllArr(e, arr.privilege);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkbox-switch-app"
                          >
                            Apply to all
                          </label> */}



                                            </div>
                                            <table className="table border w-full">
                                                <thead>
                                                    <tr>
                                                        <th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap px-6">
                                                            Size
                                                        </th>
                                                        <th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap px-6">
                                                            <div className="d-flex align-items-center">
                                                                Color{" "}
                                                                {/* <Lucide
                                                            icon="HelpCircle"
                                                            className="w-4 h-4 ml-2"
                                                        /> */}
                                                            </div>
                                                        </th>
                                                        <th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap !px-2 py-2">
                                                            Price (Including GST)
                                                        </th>
                                                        <th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap !px-2 py-2">
                                                            <div className="flex items-center">
                                                                <div className="relative w-4 h-4 mr-2 -mt-0.5 before:content-[''] before:absolute before:w-4 before:h-4 before:bg-primary/20 before:rounded-full lg:before:animate-ping after:content-[''] after:absolute after:w-4 after:h-4 after:bg-primary after:rounded-full after:border-4 after:border-white/60 after:dark:border-darkmode-300"></div>
                                                                Stock{" "}
                                                                {/* <Lucide
                                                            icon="HelpCircle"
                                                            className="w-4 h-4 ml-2"
                                                        /> */}
                                                            </div>


                                                        </th>
                                                        <th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap !px-2 py-2">
                                                            <div className="flex items-center">
                                                                <div className="relative w-4 h-4 mr-2 -mt-0.5 before:content-[''] before:absolute before:w-4 before:h-4 before:bg-primary/20 before:rounded-full lg:before:animate-ping after:content-[''] after:absolute after:w-4 after:h-4 after:bg-primary after:rounded-full after:border-4 after:border-white/60 after:dark:border-darkmode-300"></div>
                                                                Images{" "}
                                                                {/* <Lucide
                                                            icon="HelpCircle"
                                                            className="w-4 h-4 ml-2"
                                                        /> */}
                                                            </div>


                                                        </th>


                                                        {/* <th className="bg-slate-50 dark:bg-darkmode-800 text-slate-500 whitespace-nowrap !pl-2">
                              Variant Code
                            </th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    {[1].map((single2, key) => {

                                                        const id = "nonenone";

                                                        // if (selectedSize.measurement + (selectedSize.unit ? selectedSize.unit : "") === "None") {

                                                        if (_.map(selectedSizes, "measurement").includes("None")) {

                                                            return (
                                                                <>
                                                                    <tr className="border-b">
                                                                        <td rowSpan={(colours.length + 1).toString()} className="border-r py-2 pl-4">
                                                                            {"None"}
                                                                        </td>
                                                                        {
                                                                            _.map(selectedColors, "name").includes("None") &&
                                                                            <>

                                                                                <td className="py-2 pl-4">{"None"}</td>
                                                                                <td className="!px-2 py-2">
                                                                                    <div className="flex input-group">
                                                                                        <div className="input-group-text  flex justify-center items-center bg-gray-100 px-4 rounded-l-md">$</div>
                                                                                        <Input
                                                                                            type="text"
                                                                                            rounded="rounded-r-md"
                                                                                            size={"h-6 px-4 py-3"}
                                                                                            className="form-control min-w-[6rem]"
                                                                                            placeholder="Price"
                                                                                            value={prices[id] ? prices[id] : ""}
                                                                                            onChange={(e) => {
                                                                                                seterrdetails(false);


                                                                                                if (e.target.value === "" || parseInt(e.target.value) === 0) {
                                                                                                    var arr = { ...stocks }
                                                                                                    delete arr[id]
                                                                                                    setstocks(arr)

                                                                                                    var arr2 = { ...prices }
                                                                                                    delete arr2[id]
                                                                                                    setprices(arr2)
                                                                                                    // setstocks({ ...stocks, [id]: "" })
                                                                                                } else {
                                                                                                    setprices({ ...prices, [id]: e.target.value })
                                                                                                }

                                                                                                let obj = {
                                                                                                    discount: 0,
                                                                                                    unitPrice: e.target.value,
                                                                                                    stock: 1,
                                                                                                    size: "",
                                                                                                    colour: "",
                                                                                                    availablestocks: 0,
                                                                                                    availability: 1,
                                                                                                    discountedPrice: e.target.value,
                                                                                                    arrId: id,
                                                                                                }

                                                                                                // setstocksdata({ ...stocksdata, [id]: obj })

                                                                                                if (e.target.value === "" || parseInt(e.target.value) === 0) {
                                                                                                    var arr = { ...stocksdata }
                                                                                                    delete arr[id]
                                                                                                    console.log(arr)
                                                                                                    setstocksdata(arr)

                                                                                                } else {
                                                                                                    var arr = { ...stocksdata }

                                                                                                    if (arr[id] === undefined) {
                                                                                                        arr[id] = {}
                                                                                                        arr[id].availablestocks = "9999999999"
                                                                                                        setstocks({ ...stocks, [id]: "9999999999" })
                                                                                                    }

                                                                                                    arr[id].unitPrice = e.target.value
                                                                                                    arr[id].discount = 0
                                                                                                    arr[id].stock = 0
                                                                                                    arr[id].size = ""
                                                                                                    arr[id].colour = ""
                                                                                                    arr[id].availability = 1
                                                                                                    arr[id].isActive = 1

                                                                                                    arr[id].discountedPrice = e.target.value
                                                                                                    arr[id].arrId = id
                                                                                                    setstocksdata(arr)
                                                                                                }

                                                                                                // if (index >= 0 && parseFloat(e.target.value) > 0 && e.target.value != "") {
                                                                                                //   setstocksdata(newArr.splice(index, 1));

                                                                                                // }

                                                                                                // if (
                                                                                                //   index >= 0 &&
                                                                                                //   (parseFloat(e.target.value) <= 0 || e.target.value == "")
                                                                                                // ) {
                                                                                                //   setstocksdata(newArr.splice(index, 1));
                                                                                                // }

                                                                                                // if (index < 0 && parseFloat(e.target.value) > 0 && e.target.value != "") {
                                                                                                //   setstocksdata(newArr.splice(index, 1));
                                                                                                // }

                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                                <td className="!px-2 py-2 space-x-2">

                                                                                    <div className="flex items-center">
                                                                                        <div className="flex items-center">
                                                                                            <label class="relative flex flex-col items-center cursor-pointer mt-3">
                                                                                                <input type="checkbox" class="sr-only peer"
                                                                                                    onChange={(e) => {
                                                                                                        console.log(e.target.checked)
                                                                                                        // setstatus(e.target.checked)


                                                                                                        var arr = { ...stocksdata }
                                                                                                        if (arr[id] === undefined) {
                                                                                                            arr[id] = {}
                                                                                                        }

                                                                                                        arr[id].isActive = e.target.checked === true ? 1 : 0

                                                                                                        setstocksdata(arr)

                                                                                                    }}
                                                                                                    checked={stocksdata[id]?.isActive === 1 ? true : false}


                                                                                                />
                                                                                                <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#2d867b]"></div>
                                                                                                <label class=" text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer" htmlFor="product-status-active">Active</label>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="flex items-center">
                                                                                            <Input
                                                                                                type="text"
                                                                                                className="form-control min-w-[6rem] ml-1"
                                                                                                placeholder="Stock"
                                                                                                value={stocks[id] ? stocks[id] : ""}
                                                                                                onChange={(e) => {
                                                                                                    seterrdetails(false);
                                                                                                    setstocks({ ...stocks, [id]: e.target.value })

                                                                                                    // if (e.target.value === "") {
                                                                                                    //   setprices({ ...prices, [id]: e.target.value })
                                                                                                    // }

                                                                                                    if (e.target.value === "") {
                                                                                                        var arr = { ...stocksdata }
                                                                                                        // delete arr[id]
                                                                                                        // console.log(arr)
                                                                                                        // setstocksdata(arr)
                                                                                                        if (arr[id] === undefined) {
                                                                                                            arr[id] = {}
                                                                                                        }
                                                                                                        arr[id].stock = 1
                                                                                                        arr[id].availablestocks = 0
                                                                                                        setstocksdata(arr)

                                                                                                    } else {
                                                                                                        var arr = { ...stocksdata }
                                                                                                        if (arr[id] === undefined) {
                                                                                                            arr[id] = {}
                                                                                                        }
                                                                                                        arr[id].stock = 1
                                                                                                        arr[id].availablestocks = e.target.value
                                                                                                        setstocksdata(arr)
                                                                                                    }

                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="!pl-2 w-[150px]">
                                                                                    <div className="w-full mt-3 xl:mt-0 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                                                                                        <div className="grid grid-cols-3 gap-0 gap-y-4 gapx-1">
                                                                                            {_.groupBy(imagesNew, 'id')[id]?.map((faker, fakerKey) => (
                                                                                                <>
                                                                                                    <div className="flex flex-col">
                                                                                                        <div
                                                                                                            // key={fakerKey}
                                                                                                            className="col-span-5 md:col-span-2 h-14 relative  cursor-pointer zoom-in flex justify-center"
                                                                                                        >
                                                                                                            <img
                                                                                                                className="w-10 h-10 rounded-md object-fill bg-cover"
                                                                                                                alt="Midone - HTML Admin Template"
                                                                                                                src={faker.path.includes('/uploads/products/') === true ? IMAGE_SERVER_URL_DEV + faker?.path : faker?.path}
                                                                                                            />
                                                                                                            <div
                                                                                                                className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 ml-2 -mt-2"
                                                                                                            >
                                                                                                                <div
                                                                                                                    onClick={(e) => {


                                                                                                                        var array2 = [...imagesNew]?.filter(function (el) {
                                                                                                                            return el.id !== id;
                                                                                                                        });


                                                                                                                        console.log(">>>>>>", array2)

                                                                                                                        var array = [..._.groupBy(imagesNew, 'id')[id]]
                                                                                                                        array.splice(fakerKey, 1);
                                                                                                                        setimagesNew([...array2, ...array])


                                                                                                                    }}
                                                                                                                >
                                                                                                                    {/* <Lucide icon="X" className="w-4 h-4" /> */}
                                                                                                                    <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                                                                                                </div>
                                                                                                            </div>


                                                                                                        </div>
                                                                                                        <div className="form-check">
                                                                                                            <input id="checkbox-switch-" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mt-[0px] ml-2" type="checkbox"
                                                                                                                checked={faker?.thumbnail}

                                                                                                                onChange={(e) => {
                                                                                                                    var array = [..._.groupBy(imagesNew, 'id')[id]]
                                                                                                                    const index = _.map(array, "thumbnail").indexOf(true);

                                                                                                                    var array2 = [...imagesNew]?.filter(function (el) {
                                                                                                                        return el.id !== id;
                                                                                                                    });


                                                                                                                    console.log(">>>>>>", array2)

                                                                                                                    // console.log("index>>>", index)


                                                                                                                    if (index < 0) {
                                                                                                                        array[fakerKey].thumbnail = e.target.checked;
                                                                                                                        setimagesNew([...array2, ...array])
                                                                                                                    } else {
                                                                                                                        array[index].thumbnail = false;
                                                                                                                        array[fakerKey].thumbnail = e.target.checked;
                                                                                                                        setimagesNew([...array2, ...array])
                                                                                                                    }
                                                                                                                    // array.filter(person => person.thumbnail === true)
                                                                                                                    // array[fakerKey].thumbnail = e.target.checked;
                                                                                                                    // setImages(array)
                                                                                                                    // console.log("<<<<<",e.target.checked)
                                                                                                                    // setlatest(e.target.checked)
                                                                                                                    // onChangeUserArr(e, arr.privilege)
                                                                                                                    // this.onChangeAllArr(e, arr.privilege);
                                                                                                                }}
                                                                                                            />



                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>

                                                                                            ))}
                                                                                        </div>

                                                                                        {
                                                                                            (_.groupBy(imagesNew, 'id')[id]?.length < 3 || !(_.groupBy(imagesNew, 'id')[id])) &&

                                                                                            <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative">
                                                                                                {/* <Lucide icon="Image" className="w-4 h-4 mr-2" /> */}
                                                                                                <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                                                                                                <span className="mr-1">
                                                                                                    Upload photos
                                                                                                </span>
                                                                                                <input
                                                                                                    id="horizontal-form-1"
                                                                                                    type="file"
                                                                                                    multiple
                                                                                                    accept={"image/png, image/jpeg"}
                                                                                                    className="w-full h-full top-0 left-0 absolute opacity-0"
                                                                                                    onChange={(e) => {

                                                                                                        Array.from(e.target.files).map(async (item) => {

                                                                                                            let file = item;
                                                                                                            let reader = new FileReader();
                                                                                                            reader.readAsDataURL(file);
                                                                                                            let fileInp = item;
                                                                                                            let baseURL;
                                                                                                            // on reader load somthing...

                                                                                                            const image = await resizeFile(file);

                                                                                                            let obj =
                                                                                                            {
                                                                                                                path: image,
                                                                                                                thumbnail: false,
                                                                                                                id: id,
                                                                                                            }
                                                                                                                ;

                                                                                                            console.log(">>>>>", obj);

                                                                                                            setimagesNew(existing => [...existing, obj]);

                                                                                                            // reader.onload = () => {
                                                                                                            //     // Make a fileInfo Object
                                                                                                            //     baseURL = reader.result;
                                                                                                            //     let obj =
                                                                                                            //     {
                                                                                                            //         path: image,
                                                                                                            //         thumbnail: false,
                                                                                                            //         id: id,
                                                                                                            //     }
                                                                                                            //         ;

                                                                                                            //     console.log(">>>>>", obj);

                                                                                                            //     setimagesNew(existing => [...existing, obj]);

                                                                                                            // };
                                                                                                        })



                                                                                                    }}
                                                                                                />
                                                                                                {/* <Input
                                                                                            id="horizontal-form-1"
                                                                                            type="file"
                                                                                            className="w-full h-full top-0 left-0 absolute opacity-0"
                                                                                            onChange={async (e) => {

                                                                                                let file = e.target.files[0];

                                                                                                let reader = new FileReader();
                                                                                                reader.readAsDataURL(file);
                                                                                                let fileInp = e.target.files[0];
                                                                                                let baseURL;
                                                                                                // on reader load somthing...

                                                                                                const image = await resizeFile(file);
                                                                                                // console.log(image);

                                                                                                let obj =
                                                                                                {
                                                                                                    path: image,
                                                                                                    thumbnail: false,
                                                                                                    id: id,
                                                                                                };


                                                                                                setimagesNew(imagesNew.concat(obj))


                                                                                                // reader.onload = () => {
                                                                                                //     // Make a fileInfo Object
                                                                                                //     baseURL = reader.result;
                                                                                                //     // baseURL = image
                                                                                                //     let obj =
                                                                                                //     {
                                                                                                //         path: baseURL,
                                                                                                //         thumbnail: false,
                                                                                                //         id: id,
                                                                                                //     }
                                                                                                //         ;

                                                                                                //     setimagesNew(imagesNew.concat(obj))


                                                                                                // };


                                                                                            }}
                                                                                        /> */}
                                                                                            </div>
                                                                                        }


                                                                                    </div>
                                                                                </td>

                                                                            </>

                                                                        }


                                                                        {/* <td className="!pl-2">
                                                <Input
                                                  type="text"
                                                  className="form-control min-w-[6rem]"
                                                  placeholder="Variant Code"
                                                />
                                              </td> */}
                                                                    </tr>

                                                                </>

                                                            );

                                                        }




                                                    })}




                                                    {colours.map((single2, key) => {
                                                        let id = "none" + single2.name

                                                        // if (selectedSize.measurement + (selectedSize.unit ? selectedSize.unit : "") === "None") {

                                                        if (_.map(selectedSizes, "measurement").includes("None")) {


                                                            return (
                                                                <>
                                                                    {

                                                                        <>
                                                                            <tr className="border-b">
                                                                                {
                                                                                    _.map(selectedColors, "name").includes(single2.name) &&

                                                                                    <>

                                                                                        <td className="py-2 pl-4">{single2.name}</td>
                                                                                        <td className="!px-2 py-2">
                                                                                            <div className="flex input-group">
                                                                                                <div className="input-group-text  flex justify-center items-center bg-gray-100 px-4 rounded-l-md">$</div>
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    rounded="rounded-r-md"
                                                                                                    size={"h-6 px-4 py-3"}
                                                                                                    className="form-control min-w-[6rem]"
                                                                                                    placeholder="Price"
                                                                                                    value={prices[id] ? prices[id] : ""}
                                                                                                    onChange={(e) => {
                                                                                                        seterrdetails(false);


                                                                                                        if (e.target.value === "" || parseInt(e.target.value) === 0) {
                                                                                                            var arr = { ...stocks }
                                                                                                            delete arr[id]
                                                                                                            setstocks(arr)

                                                                                                            var arr2 = { ...prices }
                                                                                                            delete arr2[id]
                                                                                                            setprices(arr2)
                                                                                                            // setstocks({ ...stocks, [id]: "" })
                                                                                                        } else {
                                                                                                            setprices({ ...prices, [id]: e.target.value })
                                                                                                        }

                                                                                                        let obj = {
                                                                                                            discount: 0,
                                                                                                            unitPrice: e.target.value,
                                                                                                            stock: 1,
                                                                                                            size: "",
                                                                                                            colour: single2.name,
                                                                                                            availablestocks: 0,
                                                                                                            availability: 1,
                                                                                                            discountedPrice: e.target.value,
                                                                                                            arrId: id,
                                                                                                        }

                                                                                                        // setstocksdata({ ...stocksdata, [id]: obj })

                                                                                                        if (e.target.value === "" || parseInt(e.target.value) === 0) {
                                                                                                            var arr = { ...stocksdata }
                                                                                                            delete arr[id]
                                                                                                            console.log(arr)
                                                                                                            setstocksdata(arr)

                                                                                                        } else {
                                                                                                            var arr = { ...stocksdata }

                                                                                                            if (arr[id] === undefined) {
                                                                                                                arr[id] = {}
                                                                                                                arr[id].availablestocks = "9999999999"
                                                                                                                setstocks({ ...stocks, [id]: "9999999999" })
                                                                                                            }

                                                                                                            arr[id].unitPrice = e.target.value
                                                                                                            arr[id].discount = 0
                                                                                                            arr[id].stock = 0
                                                                                                            arr[id].size = ""
                                                                                                            arr[id].colour = single2.name
                                                                                                            arr[id].availability = 1
                                                                                                            arr[id].isActive = 1

                                                                                                            arr[id].discountedPrice = e.target.value
                                                                                                            arr[id].arrId = id
                                                                                                            setstocksdata(arr)
                                                                                                        }

                                                                                                        // if (index >= 0 && parseFloat(e.target.value) > 0 && e.target.value != "") {
                                                                                                        //   setstocksdata(newArr.splice(index, 1));

                                                                                                        // }

                                                                                                        // if (
                                                                                                        //   index >= 0 &&
                                                                                                        //   (parseFloat(e.target.value) <= 0 || e.target.value == "")
                                                                                                        // ) {
                                                                                                        //   setstocksdata(newArr.splice(index, 1));
                                                                                                        // }

                                                                                                        // if (index < 0 && parseFloat(e.target.value) > 0 && e.target.value != "") {
                                                                                                        //   setstocksdata(newArr.splice(index, 1));
                                                                                                        // }

                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="!px-2 py-2 space-x-2">

                                                                                            <div className="flex items-center">
                                                                                                <div className="flex items-center">
                                                                                                    <label class="relative flex flex-col items-center cursor-pointer mt-3">
                                                                                                        <input type="checkbox" class="sr-only peer"
                                                                                                            onChange={(e) => {
                                                                                                                console.log(e.target.checked)
                                                                                                                // setstatus(e.target.checked)


                                                                                                                var arr = { ...stocksdata }
                                                                                                                if (arr[id] === undefined) {
                                                                                                                    arr[id] = {}
                                                                                                                }

                                                                                                                arr[id].isActive = e.target.checked === true ? 1 : 0

                                                                                                                setstocksdata(arr)

                                                                                                            }}
                                                                                                            checked={stocksdata[id]?.isActive === 1 ? true : false}


                                                                                                        />
                                                                                                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#2d867b]"></div>
                                                                                                        <label class=" text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer" htmlFor="product-status-active">Active</label>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="flex items-center">
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        className="form-control min-w-[6rem] ml-1"
                                                                                                        placeholder="Stock"
                                                                                                        value={stocks[id] ? stocks[id] : ""}
                                                                                                        onChange={(e) => {
                                                                                                            seterrdetails(false);
                                                                                                            setstocks({ ...stocks, [id]: e.target.value })

                                                                                                            // if (e.target.value === "") {
                                                                                                            //   setprices({ ...prices, [id]: e.target.value })
                                                                                                            // }

                                                                                                            if (e.target.value === "") {
                                                                                                                var arr = { ...stocksdata }
                                                                                                                // delete arr[id]
                                                                                                                // console.log(arr)
                                                                                                                // setstocksdata(arr)
                                                                                                                if (arr[id] === undefined) {
                                                                                                                    arr[id] = {}
                                                                                                                }
                                                                                                                arr[id].stock = 1
                                                                                                                arr[id].availablestocks = 0
                                                                                                                setstocksdata(arr)

                                                                                                            } else {
                                                                                                                var arr = { ...stocksdata }
                                                                                                                if (arr[id] === undefined) {
                                                                                                                    arr[id] = {}
                                                                                                                }
                                                                                                                arr[id].stock = 1
                                                                                                                arr[id].availablestocks = e.target.value
                                                                                                                setstocksdata(arr)
                                                                                                            }

                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="!pl-2 w-[150px]">
                                                                                            <div className="w-full mt-3 xl:mt-0 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                                                                                                <div className="grid grid-cols-3 gap-0 gap-y-4 gapx-1">
                                                                                                    {_.groupBy(imagesNew, 'id')[id]?.map((faker, fakerKey) => (
                                                                                                        <>
                                                                                                            <div className="flex flex-col">
                                                                                                                <div
                                                                                                                    // key={fakerKey}
                                                                                                                    className="col-span-5 md:col-span-2 h-14 relative  cursor-pointer zoom-in flex justify-center"
                                                                                                                >
                                                                                                                    <img
                                                                                                                        className="w-10 h-10 rounded-md object-fill bg-cover"
                                                                                                                        alt="Midone - HTML Admin Template"
                                                                                                                        src={faker.path.includes('/uploads/products/') === true ? IMAGE_SERVER_URL_DEV + faker?.path : faker?.path}
                                                                                                                    />
                                                                                                                    <div
                                                                                                                        className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 ml-2 -mt-2"
                                                                                                                    >
                                                                                                                        <div
                                                                                                                            onClick={(e) => {


                                                                                                                                var array2 = [...imagesNew]?.filter(function (el) {
                                                                                                                                    return el.id !== id;
                                                                                                                                });


                                                                                                                                console.log(">>>>>>", array2)

                                                                                                                                var array = [..._.groupBy(imagesNew, 'id')[id]]
                                                                                                                                array.splice(fakerKey, 1);
                                                                                                                                setimagesNew([...array2, ...array])


                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {/* <Lucide icon="X" className="w-4 h-4" /> */}
                                                                                                                            <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                                                                                                        </div>
                                                                                                                    </div>


                                                                                                                </div>
                                                                                                                <div className="form-check">
                                                                                                                    <input id="checkbox-switch-" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mt-[0px] ml-2" type="checkbox"
                                                                                                                        checked={faker?.thumbnail}

                                                                                                                        onChange={(e) => {
                                                                                                                            var array = [..._.groupBy(imagesNew, 'id')[id]]
                                                                                                                            const index = _.map(array, "thumbnail").indexOf(true);

                                                                                                                            var array2 = [...imagesNew]?.filter(function (el) {
                                                                                                                                return el.id !== id;
                                                                                                                            });


                                                                                                                            console.log(">>>>>>", array2)

                                                                                                                            // console.log("index>>>", index)


                                                                                                                            if (index < 0) {
                                                                                                                                array[fakerKey].thumbnail = e.target.checked;
                                                                                                                                setimagesNew([...array2, ...array])
                                                                                                                            } else {
                                                                                                                                array[index].thumbnail = false;
                                                                                                                                array[fakerKey].thumbnail = e.target.checked;
                                                                                                                                setimagesNew([...array2, ...array])
                                                                                                                            }
                                                                                                                            // array.filter(person => person.thumbnail === true)
                                                                                                                            // array[fakerKey].thumbnail = e.target.checked;
                                                                                                                            // setImages(array)
                                                                                                                            // console.log("<<<<<",e.target.checked)
                                                                                                                            // setlatest(e.target.checked)
                                                                                                                            // onChangeUserArr(e, arr.privilege)
                                                                                                                            // this.onChangeAllArr(e, arr.privilege);
                                                                                                                        }}
                                                                                                                    />



                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>

                                                                                                    ))}
                                                                                                </div>

                                                                                                {
                                                                                                    (_.groupBy(imagesNew, 'id')[id]?.length < 3 || !(_.groupBy(imagesNew, 'id')[id])) &&

                                                                                                    <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative">
                                                                                                        {/* <Lucide icon="Image" className="w-4 h-4 mr-2" /> */}
                                                                                                        <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                                                                                                        <span className="mr-1">
                                                                                                            Upload photos
                                                                                                        </span>
                                                                                                        <input
                                                                                                            id="horizontal-form-1"
                                                                                                            type="file"
                                                                                                            multiple
                                                                                                            accept={"image/png, image/jpeg"}
                                                                                                            className="w-full h-full top-0 left-0 absolute opacity-0"
                                                                                                            onChange={(e) => {

                                                                                                                Array.from(e.target.files).map(async (item) => {

                                                                                                                    let file = item;
                                                                                                                    let reader = new FileReader();
                                                                                                                    reader.readAsDataURL(file);
                                                                                                                    let fileInp = item;
                                                                                                                    let baseURL;
                                                                                                                    // on reader load somthing...

                                                                                                                    const image = await resizeFile(file);

                                                                                                                    let obj =
                                                                                                                    {
                                                                                                                        path: image,
                                                                                                                        thumbnail: false,
                                                                                                                        id: id,
                                                                                                                    }
                                                                                                                        ;

                                                                                                                    console.log(">>>>>", obj);

                                                                                                                    setimagesNew(existing => [...existing, obj]);

                                                                                                                    // reader.onload = () => {
                                                                                                                    //     // Make a fileInfo Object
                                                                                                                    //     baseURL = reader.result;
                                                                                                                    //     let obj =
                                                                                                                    //     {
                                                                                                                    //         path: image,
                                                                                                                    //         thumbnail: false,
                                                                                                                    //         id: id,
                                                                                                                    //     }
                                                                                                                    //         ;

                                                                                                                    //     console.log(">>>>>", obj);

                                                                                                                    //     setimagesNew(existing => [...existing, obj]);

                                                                                                                    // };
                                                                                                                })



                                                                                                            }}
                                                                                                        />
                                                                                                        {/* <Input
                                                                                                    id="horizontal-form-1"
                                                                                                    type="file"
                                                                                                    className="w-full h-full top-0 left-0 absolute opacity-0"
                                                                                                    onChange={async (e) => {

                                                                                                        let file = e.target.files[0];

                                                                                                        let reader = new FileReader();
                                                                                                        reader.readAsDataURL(file);
                                                                                                        let fileInp = e.target.files[0];
                                                                                                        let baseURL;
                                                                                                        // on reader load somthing...

                                                                                                        const image = await resizeFile(file);
                                                                                                        // console.log(image);

                                                                                                        let obj =
                                                                                                        {
                                                                                                            path: image,
                                                                                                            thumbnail: false,
                                                                                                            id: id,
                                                                                                        };


                                                                                                        setimagesNew(imagesNew.concat(obj))


                                                                                                        // reader.onload = () => {
                                                                                                        //     // Make a fileInfo Object
                                                                                                        //     baseURL = reader.result;
                                                                                                        //     // baseURL = image
                                                                                                        //     let obj =
                                                                                                        //     {
                                                                                                        //         path: baseURL,
                                                                                                        //         thumbnail: false,
                                                                                                        //         id: id,
                                                                                                        //     }
                                                                                                        //         ;

                                                                                                        //     setimagesNew(imagesNew.concat(obj))


                                                                                                        // };


                                                                                                    }}
                                                                                                /> */}
                                                                                                    </div>
                                                                                                }


                                                                                            </div>
                                                                                        </td>

                                                                                    </>
                                                                                }


                                                                                {/* <td className="!pl-2">
                                            <Input
                                              type="text"
                                              className="form-control min-w-[6rem]"
                                              placeholder="Variant Code"
                                            />
                                          </td> */}
                                                                            </tr>
                                                                        </>
                                                                    }


                                                                </>
                                                            );

                                                        }

                                                    })}

                                                    {[{ unit: "Small", measurement: "none" }, { unit: "Medium", measurement: "none" }, { unit: "Large", measurement: "none" }, { unit: "Extra Large", measurement: "none" }, ...sizes]?.map(single => {

                                                        const id = single.measurement + (single.unit ? single.unit : "") + "none"


                                                        // if (single.measurement + (single.unit ? single.unit : "") === selectedSize.measurement + (selectedSize.unit ? selectedSize.unit : "")) {

                                                        if (_.map(selectedSizes, "measurement").includes(single.measurement) || (_.map(selectedUnits, "unit").includes(single.unit) && (single.unit === "Small" || single.unit === "Medium" || single.unit === "Large" || single.unit === "Extra Large") )) {

                                                            return (
                                                                <>
                                                                    <>
                                                                        <tr className="border-b">
                                                                            <td rowSpan={(colours.length + 1).toString()} className="border-r py-2 pl-4">
                                                                                {

                                                                                    (single.unit === "Small" || single.unit === "Medium" || single.unit === "Large" || single.unit === "Extra Large") ?


                                                                                        single.unit

                                                                                        :

                                                                                        (single.measurement + (single.unit ? single.unit : ""))

                                                                                }
                                                                            </td>
                                                                            {
                                                                                _.map(selectedColors, "name").includes("None") &&

                                                                                <>

                                                                                    <td className="py-2 pl-4">{"None"}</td>
                                                                                    <td className="!px-2 py-2">
                                                                                        <div className="flex input-group">
                                                                                            <div className="input-group-text  flex justify-center items-center bg-gray-100 px-4 rounded-l-md">$</div>
                                                                                            <Input
                                                                                                type="text"
                                                                                                rounded="rounded-r-md"
                                                                                                size={"h-6 px-4 py-3"}
                                                                                                className="form-control min-w-[6rem]"
                                                                                                placeholder="Price"
                                                                                                value={prices[id] ? prices[id] : ""}
                                                                                                onChange={(e) => {
                                                                                                    seterrdetails(false);


                                                                                                    if (e.target.value === "" || parseInt(e.target.value) === 0) {
                                                                                                        var arr = { ...stocks }
                                                                                                        delete arr[id]
                                                                                                        setstocks(arr)

                                                                                                        var arr2 = { ...prices }
                                                                                                        delete arr2[id]
                                                                                                        setprices(arr2)
                                                                                                        // setstocks({ ...stocks, [id]: "" })
                                                                                                    } else {
                                                                                                        setprices({ ...prices, [id]: e.target.value })
                                                                                                    }

                                                                                                    let obj = {
                                                                                                        discount: 0,
                                                                                                        unitPrice: e.target.value,
                                                                                                        stock: 1,
                                                                                                        size: "",
                                                                                                        colour: "",
                                                                                                        availablestocks: 0,
                                                                                                        availability: 1,
                                                                                                        discountedPrice: e.target.value,
                                                                                                        arrId: id,
                                                                                                    }

                                                                                                    // setstocksdata({ ...stocksdata, [id]: obj })

                                                                                                    if (e.target.value === "" || parseInt(e.target.value) === 0) {
                                                                                                        var arr = { ...stocksdata }
                                                                                                        delete arr[id]
                                                                                                        console.log(arr)
                                                                                                        setstocksdata(arr)

                                                                                                    } else {
                                                                                                        var arr = { ...stocksdata }

                                                                                                        if (arr[id] === undefined) {
                                                                                                            arr[id] = {}
                                                                                                            arr[id].availablestocks = "9999999999"
                                                                                                            setstocks({ ...stocks, [id]: "9999999999" })
                                                                                                        }

                                                                                                        arr[id].unitPrice = e.target.value
                                                                                                        arr[id].discount = 0
                                                                                                        arr[id].stock = 0
                                                                                                        arr[id].size = single.measurement + (single.unit ? single.unit : "")
                                                                                                        arr[id].colour = ""
                                                                                                        arr[id].availability = 1
                                                                                                        arr[id].isActive = 1

                                                                                                        arr[id].discountedPrice = e.target.value
                                                                                                        arr[id].arrId = id
                                                                                                        setstocksdata(arr)
                                                                                                    }

                                                                                                    // if (index >= 0 && parseFloat(e.target.value) > 0 && e.target.value != "") {
                                                                                                    //   setstocksdata(newArr.splice(index, 1));

                                                                                                    // }

                                                                                                    // if (
                                                                                                    //   index >= 0 &&
                                                                                                    //   (parseFloat(e.target.value) <= 0 || e.target.value == "")
                                                                                                    // ) {
                                                                                                    //   setstocksdata(newArr.splice(index, 1));
                                                                                                    // }

                                                                                                    // if (index < 0 && parseFloat(e.target.value) > 0 && e.target.value != "") {
                                                                                                    //   setstocksdata(newArr.splice(index, 1));
                                                                                                    // }

                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="!px-2 py-2 space-x-2">

                                                                                        <div className="flex items-center">
                                                                                            <div className="flex items-center">
                                                                                                <label class="relative flex flex-col items-center cursor-pointer mt-3">
                                                                                                    <input type="checkbox" class="sr-only peer"
                                                                                                        onChange={(e) => {
                                                                                                            console.log(e.target.checked)
                                                                                                            // setstatus(e.target.checked)


                                                                                                            var arr = { ...stocksdata }
                                                                                                            if (arr[id] === undefined) {
                                                                                                                arr[id] = {}
                                                                                                            }

                                                                                                            arr[id].isActive = e.target.checked === true ? 1 : 0

                                                                                                            setstocksdata(arr)

                                                                                                        }}
                                                                                                        checked={stocksdata[id]?.isActive === 1 ? true : false}


                                                                                                    />
                                                                                                    <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#2d867b]"></div>
                                                                                                    <label class=" text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer" htmlFor="product-status-active">Active</label>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="flex items-center">
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    className="form-control min-w-[6rem] ml-1"
                                                                                                    placeholder="Stock"
                                                                                                    value={stocks[id] ? stocks[id] : ""}
                                                                                                    onChange={(e) => {
                                                                                                        seterrdetails(false);
                                                                                                        setstocks({ ...stocks, [id]: e.target.value })

                                                                                                        // if (e.target.value === "") {
                                                                                                        //   setprices({ ...prices, [id]: e.target.value })
                                                                                                        // }

                                                                                                        if (e.target.value === "") {
                                                                                                            var arr = { ...stocksdata }
                                                                                                            // delete arr[id]
                                                                                                            // console.log(arr)
                                                                                                            // setstocksdata(arr)
                                                                                                            if (arr[id] === undefined) {
                                                                                                                arr[id] = {}
                                                                                                            }
                                                                                                            arr[id].stock = 1
                                                                                                            arr[id].availablestocks = 0
                                                                                                            setstocksdata(arr)

                                                                                                        } else {
                                                                                                            var arr = { ...stocksdata }
                                                                                                            if (arr[id] === undefined) {
                                                                                                                arr[id] = {}
                                                                                                            }
                                                                                                            arr[id].stock = 1
                                                                                                            arr[id].availablestocks = e.target.value
                                                                                                            setstocksdata(arr)
                                                                                                        }

                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="!pl-2 w-[150px]">
                                                                                        <div className="w-full mt-3 xl:mt-0 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                                                                                            <div className="grid grid-cols-3 gap-0 gap-y-4 gapx-1">
                                                                                                {_.groupBy(imagesNew, 'id')[id]?.map((faker, fakerKey) => (
                                                                                                    <>
                                                                                                        <div className="flex flex-col">
                                                                                                            <div
                                                                                                                // key={fakerKey}
                                                                                                                className="col-span-5 md:col-span-2 h-14 relative  cursor-pointer zoom-in flex justify-center"
                                                                                                            >
                                                                                                                <img
                                                                                                                    className="w-10 h-10 rounded-md object-fill bg-cover"
                                                                                                                    alt="Midone - HTML Admin Template"
                                                                                                                    src={faker.path.includes('/uploads/products/') === true ? IMAGE_SERVER_URL_DEV + faker?.path : faker?.path}
                                                                                                                />
                                                                                                                <div
                                                                                                                    className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 ml-2 -mt-2"
                                                                                                                >
                                                                                                                    <div
                                                                                                                        onClick={(e) => {


                                                                                                                            var array2 = [...imagesNew]?.filter(function (el) {
                                                                                                                                return el.id !== id;
                                                                                                                            });


                                                                                                                            console.log(">>>>>>", array2)

                                                                                                                            var array = [..._.groupBy(imagesNew, 'id')[id]]
                                                                                                                            array.splice(fakerKey, 1);
                                                                                                                            setimagesNew([...array2, ...array])


                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {/* <Lucide icon="X" className="w-4 h-4" /> */}
                                                                                                                        <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                                                                                                    </div>
                                                                                                                </div>


                                                                                                            </div>
                                                                                                            <div className="form-check">
                                                                                                                <input id="checkbox-switch-" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mt-[0px] ml-2" type="checkbox"
                                                                                                                    checked={faker?.thumbnail}

                                                                                                                    onChange={(e) => {
                                                                                                                        var array = [..._.groupBy(imagesNew, 'id')[id]]
                                                                                                                        const index = _.map(array, "thumbnail").indexOf(true);

                                                                                                                        var array2 = [...imagesNew]?.filter(function (el) {
                                                                                                                            return el.id !== id;
                                                                                                                        });


                                                                                                                        console.log(">>>>>>", array2)

                                                                                                                        // console.log("index>>>", index)


                                                                                                                        if (index < 0) {
                                                                                                                            array[fakerKey].thumbnail = e.target.checked;
                                                                                                                            setimagesNew([...array2, ...array])
                                                                                                                        } else {
                                                                                                                            array[index].thumbnail = false;
                                                                                                                            array[fakerKey].thumbnail = e.target.checked;
                                                                                                                            setimagesNew([...array2, ...array])
                                                                                                                        }
                                                                                                                        // array.filter(person => person.thumbnail === true)
                                                                                                                        // array[fakerKey].thumbnail = e.target.checked;
                                                                                                                        // setImages(array)
                                                                                                                        // console.log("<<<<<",e.target.checked)
                                                                                                                        // setlatest(e.target.checked)
                                                                                                                        // onChangeUserArr(e, arr.privilege)
                                                                                                                        // this.onChangeAllArr(e, arr.privilege);
                                                                                                                    }}
                                                                                                                />



                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>

                                                                                                ))}
                                                                                            </div>

                                                                                            {
                                                                                                (_.groupBy(imagesNew, 'id')[id]?.length < 3 || !(_.groupBy(imagesNew, 'id')[id])) &&

                                                                                                <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative">
                                                                                                    {/* <Lucide icon="Image" className="w-4 h-4 mr-2" /> */}
                                                                                                    <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                                                                                                    <span className="mr-1">
                                                                                                        Upload photos
                                                                                                    </span>
                                                                                                    <input
                                                                                                        id="horizontal-form-1"
                                                                                                        type="file"
                                                                                                        multiple
                                                                                                        accept={"image/png, image/jpeg"}
                                                                                                        className="w-full h-full top-0 left-0 absolute opacity-0"
                                                                                                        onChange={(e) => {

                                                                                                            Array.from(e.target.files).map(async (item) => {

                                                                                                                let file = item;
                                                                                                                let reader = new FileReader();
                                                                                                                reader.readAsDataURL(file);
                                                                                                                let fileInp = item;
                                                                                                                let baseURL;
                                                                                                                // on reader load somthing...

                                                                                                                const image = await resizeFile(file);

                                                                                                                let obj =
                                                                                                                {
                                                                                                                    path: image,
                                                                                                                    thumbnail: false,
                                                                                                                    id: id,
                                                                                                                }
                                                                                                                    ;

                                                                                                                console.log(">>>>>", obj);

                                                                                                                setimagesNew(existing => [...existing, obj]);

                                                                                                                // reader.onload = () => {
                                                                                                                //     // Make a fileInfo Object
                                                                                                                //     baseURL = reader.result;
                                                                                                                //     let obj =
                                                                                                                //     {
                                                                                                                //         path: image,
                                                                                                                //         thumbnail: false,
                                                                                                                //         id: id,
                                                                                                                //     }
                                                                                                                //         ;

                                                                                                                //     console.log(">>>>>", obj);

                                                                                                                //     setimagesNew(existing => [...existing, obj]);

                                                                                                                // };
                                                                                                            })



                                                                                                        }}
                                                                                                    />
                                                                                                    {/* <Input
                                                                                                id="horizontal-form-1"
                                                                                                type="file"
                                                                                                className="w-full h-full top-0 left-0 absolute opacity-0"
                                                                                                onChange={async (e) => {

                                                                                                    let file = e.target.files[0];

                                                                                                    let reader = new FileReader();
                                                                                                    reader.readAsDataURL(file);
                                                                                                    let fileInp = e.target.files[0];
                                                                                                    let baseURL;
                                                                                                    // on reader load somthing...

                                                                                                    const image = await resizeFile(file);
                                                                                                    // console.log(image);

                                                                                                    let obj =
                                                                                                    {
                                                                                                        path: image,
                                                                                                        thumbnail: false,
                                                                                                        id: id,
                                                                                                    };


                                                                                                    setimagesNew(imagesNew.concat(obj))


                                                                                                    // reader.onload = () => {
                                                                                                    //     // Make a fileInfo Object
                                                                                                    //     baseURL = reader.result;
                                                                                                    //     // baseURL = image
                                                                                                    //     let obj =
                                                                                                    //     {
                                                                                                    //         path: baseURL,
                                                                                                    //         thumbnail: false,
                                                                                                    //         id: id,
                                                                                                    //     }
                                                                                                    //         ;

                                                                                                    //     setimagesNew(imagesNew.concat(obj))


                                                                                                    // };


                                                                                                }}
                                                                                            /> */}
                                                                                                </div>
                                                                                            }


                                                                                        </div>
                                                                                    </td>


                                                                                </>
                                                                            }

                                                                            {/* <td className="!pl-2">
  <Input
  type="text"
  className="form-control min-w-[6rem]"
  placeholder="Variant Code"
  />
  </td> */}
                                                                        </tr>

                                                                    </>
                                                                    {colours.map((single2, key) => {
                                                                        let id = single.measurement + (single.unit ? single.unit : "") + single2.name
                                                                        return (
                                                                            <>
                                                                                {

                                                                                    <>
                                                                                        <tr className="border-b">
                                                                                            {
                                                                                                _.map(selectedColors, "name").includes(single2.name) &&

                                                                                                <>
                                                                                                    <td className="py-2 pl-4">{single2.name}</td>
                                                                                                    <td className="!px-2 py-2">
                                                                                                        <div className="flex input-group">
                                                                                                            <div className="input-group-text  flex justify-center items-center bg-gray-100 px-4 rounded-l-md">$</div>
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                rounded="rounded-r-md"
                                                                                                                size={"h-6 px-4 py-3"}
                                                                                                                className="form-control min-w-[6rem]"
                                                                                                                placeholder="Price"
                                                                                                                value={prices[id] ? prices[id] : ""}
                                                                                                                onChange={(e) => {
                                                                                                                    seterrdetails(false);


                                                                                                                    if (e.target.value === "" || parseInt(e.target.value) === 0) {
                                                                                                                        var arr = { ...stocks }
                                                                                                                        delete arr[id]
                                                                                                                        setstocks(arr)

                                                                                                                        var arr2 = { ...prices }
                                                                                                                        delete arr2[id]
                                                                                                                        setprices(arr2)
                                                                                                                        // setstocks({ ...stocks, [id]: "" })
                                                                                                                    } else {
                                                                                                                        setprices({ ...prices, [id]: e.target.value })
                                                                                                                    }

                                                                                                                    let obj = {
                                                                                                                        discount: 0,
                                                                                                                        unitPrice: e.target.value,
                                                                                                                        stock: 1,
                                                                                                                        size: "",
                                                                                                                        colour: "",
                                                                                                                        availablestocks: 0,
                                                                                                                        availability: 1,
                                                                                                                        discountedPrice: e.target.value,
                                                                                                                        arrId: id,
                                                                                                                    }

                                                                                                                    // setstocksdata({ ...stocksdata, [id]: obj })

                                                                                                                    if (e.target.value === "" || parseInt(e.target.value) === 0) {
                                                                                                                        var arr = { ...stocksdata }
                                                                                                                        delete arr[id]
                                                                                                                        console.log(arr)
                                                                                                                        setstocksdata(arr)

                                                                                                                    } else {
                                                                                                                        var arr = { ...stocksdata }

                                                                                                                        if (arr[id] === undefined) {
                                                                                                                            arr[id] = {}
                                                                                                                            arr[id].availablestocks = "9999999999"
                                                                                                                            setstocks({ ...stocks, [id]: "9999999999" })
                                                                                                                        }

                                                                                                                        arr[id].unitPrice = e.target.value
                                                                                                                        arr[id].discount = 0
                                                                                                                        arr[id].stock = 0
                                                                                                                        arr[id].size = single.measurement + (single.unit ? single.unit : "")
                                                                                                                        arr[id].colour = single2.name
                                                                                                                        arr[id].availability = 1
                                                                                                                        arr[id].isActive = 1

                                                                                                                        arr[id].discountedPrice = e.target.value
                                                                                                                        arr[id].arrId = id
                                                                                                                        setstocksdata(arr)
                                                                                                                    }

                                                                                                                    // if (index >= 0 && parseFloat(e.target.value) > 0 && e.target.value != "") {
                                                                                                                    //   setstocksdata(newArr.splice(index, 1));

                                                                                                                    // }

                                                                                                                    // if (
                                                                                                                    //   index >= 0 &&
                                                                                                                    //   (parseFloat(e.target.value) <= 0 || e.target.value == "")
                                                                                                                    // ) {
                                                                                                                    //   setstocksdata(newArr.splice(index, 1));
                                                                                                                    // }

                                                                                                                    // if (index < 0 && parseFloat(e.target.value) > 0 && e.target.value != "") {
                                                                                                                    //   setstocksdata(newArr.splice(index, 1));
                                                                                                                    // }

                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </td>

                                                                                                    <td className="!px-2 py-2 space-x-2">

                                                                                                        <div className="flex items-center">
                                                                                                            <div className="flex items-center">
                                                                                                                <label class="relative flex flex-col items-center cursor-pointer mt-3">
                                                                                                                    <input type="checkbox" class="sr-only peer"
                                                                                                                        onChange={(e) => {
                                                                                                                            console.log(e.target.checked)
                                                                                                                            // setstatus(e.target.checked)


                                                                                                                            var arr = { ...stocksdata }
                                                                                                                            if (arr[id] === undefined) {
                                                                                                                                arr[id] = {}
                                                                                                                            }

                                                                                                                            arr[id].isActive = e.target.checked === true ? 1 : 0

                                                                                                                            setstocksdata(arr)

                                                                                                                        }}
                                                                                                                        checked={stocksdata[id]?.isActive === 1 ? true : false}


                                                                                                                    />
                                                                                                                    <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#2d867b]"></div>
                                                                                                                    <label class=" text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer" htmlFor="product-status-active">Active</label>
                                                                                                                </label>
                                                                                                            </div>
                                                                                                            <div className="flex items-center">
                                                                                                                <Input
                                                                                                                    type="text"
                                                                                                                    className="form-control min-w-[6rem] ml-1"
                                                                                                                    placeholder="Stock"
                                                                                                                    value={stocks[id] ? stocks[id] : ""}
                                                                                                                    onChange={(e) => {
                                                                                                                        seterrdetails(false);
                                                                                                                        setstocks({ ...stocks, [id]: e.target.value })

                                                                                                                        // if (e.target.value === "") {
                                                                                                                        //   setprices({ ...prices, [id]: e.target.value })
                                                                                                                        // }

                                                                                                                        if (e.target.value === "") {
                                                                                                                            var arr = { ...stocksdata }
                                                                                                                            // delete arr[id]
                                                                                                                            // console.log(arr)
                                                                                                                            // setstocksdata(arr)
                                                                                                                            if (arr[id] === undefined) {
                                                                                                                                arr[id] = {}
                                                                                                                            }
                                                                                                                            arr[id].stock = 1
                                                                                                                            arr[id].availablestocks = 0
                                                                                                                            setstocksdata(arr)

                                                                                                                        } else {
                                                                                                                            var arr = { ...stocksdata }
                                                                                                                            if (arr[id] === undefined) {
                                                                                                                                arr[id] = {}
                                                                                                                            }
                                                                                                                            arr[id].stock = 1
                                                                                                                            arr[id].availablestocks = e.target.value
                                                                                                                            setstocksdata(arr)
                                                                                                                        }

                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td className="!pl-2 w-[150px]">
                                                                                                        <div className="w-full mt-3 xl:mt-0 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                                                                                                            <div className="grid grid-cols-3 gap-0 gap-y-4 gapx-1">
                                                                                                                {_.groupBy(imagesNew, 'id')[id]?.map((faker, fakerKey) => (
                                                                                                                    <>
                                                                                                                        <div className="flex flex-col">
                                                                                                                            <div
                                                                                                                                // key={fakerKey}
                                                                                                                                className="col-span-5 md:col-span-2 h-14 relative  cursor-pointer zoom-in flex justify-center"
                                                                                                                            >
                                                                                                                                <img
                                                                                                                                    className="w-10 h-10 rounded-md object-fill bg-cover"
                                                                                                                                    alt="Midone - HTML Admin Template"
                                                                                                                                    src={faker.path.includes('/uploads/products/') === true ? IMAGE_SERVER_URL_DEV + faker?.path : faker?.path}
                                                                                                                                />
                                                                                                                                <div
                                                                                                                                    className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 ml-2 -mt-2"
                                                                                                                                >
                                                                                                                                    <div
                                                                                                                                        onClick={(e) => {


                                                                                                                                            var array2 = [...imagesNew]?.filter(function (el) {
                                                                                                                                                return el.id !== id;
                                                                                                                                            });


                                                                                                                                            console.log(">>>>>>", array2)

                                                                                                                                            var array = [..._.groupBy(imagesNew, 'id')[id]]
                                                                                                                                            array.splice(fakerKey, 1);
                                                                                                                                            setimagesNew([...array2, ...array])


                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        {/* <Lucide icon="X" className="w-4 h-4" /> */}
                                                                                                                                        <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                                                                                                                    </div>
                                                                                                                                </div>


                                                                                                                            </div>
                                                                                                                            <div className="form-check">
                                                                                                                                <input id="checkbox-switch-" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mt-[0px] ml-2" type="checkbox"
                                                                                                                                    checked={faker?.thumbnail}

                                                                                                                                    onChange={(e) => {
                                                                                                                                        var array = [..._.groupBy(imagesNew, 'id')[id]]
                                                                                                                                        const index = _.map(array, "thumbnail").indexOf(true);

                                                                                                                                        var array2 = [...imagesNew]?.filter(function (el) {
                                                                                                                                            return el.id !== id;
                                                                                                                                        });


                                                                                                                                        console.log(">>>>>>", array2)

                                                                                                                                        // console.log("index>>>", index)


                                                                                                                                        if (index < 0) {
                                                                                                                                            array[fakerKey].thumbnail = e.target.checked;
                                                                                                                                            setimagesNew([...array2, ...array])
                                                                                                                                        } else {
                                                                                                                                            array[index].thumbnail = false;
                                                                                                                                            array[fakerKey].thumbnail = e.target.checked;
                                                                                                                                            setimagesNew([...array2, ...array])
                                                                                                                                        }
                                                                                                                                        // array.filter(person => person.thumbnail === true)
                                                                                                                                        // array[fakerKey].thumbnail = e.target.checked;
                                                                                                                                        // setImages(array)
                                                                                                                                        // console.log("<<<<<",e.target.checked)
                                                                                                                                        // setlatest(e.target.checked)
                                                                                                                                        // onChangeUserArr(e, arr.privilege)
                                                                                                                                        // this.onChangeAllArr(e, arr.privilege);
                                                                                                                                    }}
                                                                                                                                />



                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </>

                                                                                                                ))}
                                                                                                            </div>

                                                                                                            {
                                                                                                                (_.groupBy(imagesNew, 'id')[id]?.length < 3 || !(_.groupBy(imagesNew, 'id')[id])) &&

                                                                                                                <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative">
                                                                                                                    {/* <Lucide icon="Image" className="w-4 h-4 mr-2" /> */}
                                                                                                                    <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                                                                                                                    <span className="mr-1">
                                                                                                                        Upload photos
                                                                                                                    </span>
                                                                                                                    <input
                                                                                                                        id="horizontal-form-1"
                                                                                                                        type="file"
                                                                                                                        multiple
                                                                                                                        accept={"image/png, image/jpeg"}
                                                                                                                        className="w-full h-full top-0 left-0 absolute opacity-0"
                                                                                                                        onChange={(e) => {

                                                                                                                            Array.from(e.target.files).map(async (item) => {

                                                                                                                                let file = item;
                                                                                                                                let reader = new FileReader();
                                                                                                                                reader.readAsDataURL(file);
                                                                                                                                let fileInp = item;
                                                                                                                                let baseURL;
                                                                                                                                // on reader load somthing...

                                                                                                                                const image = await resizeFile(file);

                                                                                                                                let obj =
                                                                                                                                {
                                                                                                                                    path: image,
                                                                                                                                    thumbnail: false,
                                                                                                                                    id: id,
                                                                                                                                }
                                                                                                                                    ;

                                                                                                                                console.log(">>>>>", obj);

                                                                                                                                setimagesNew(existing => [...existing, obj]);

                                                                                                                                // reader.onload = () => {
                                                                                                                                //     // Make a fileInfo Object
                                                                                                                                //     baseURL = reader.result;
                                                                                                                                //     let obj =
                                                                                                                                //     {
                                                                                                                                //         path: image,
                                                                                                                                //         thumbnail: false,
                                                                                                                                //         id: id,
                                                                                                                                //     }
                                                                                                                                //         ;

                                                                                                                                //     console.log(">>>>>", obj);

                                                                                                                                //     setimagesNew(existing => [...existing, obj]);

                                                                                                                                // };
                                                                                                                            })



                                                                                                                        }}
                                                                                                                    />
                                                                                                                    {/* <Input
                                                                                                                id="horizontal-form-1"
                                                                                                                type="file"
                                                                                                                className="w-full h-full top-0 left-0 absolute opacity-0"
                                                                                                                onChange={async (e) => {

                                                                                                                    let file = e.target.files[0];

                                                                                                                    let reader = new FileReader();
                                                                                                                    reader.readAsDataURL(file);
                                                                                                                    let fileInp = e.target.files[0];
                                                                                                                    let baseURL;
                                                                                                                    // on reader load somthing...

                                                                                                                    const image = await resizeFile(file);
                                                                                                                    // console.log(image);

                                                                                                                    let obj =
                                                                                                                    {
                                                                                                                        path: image,
                                                                                                                        thumbnail: false,
                                                                                                                        id: id,
                                                                                                                    };


                                                                                                                    setimagesNew(imagesNew.concat(obj))


                                                                                                                    // reader.onload = () => {
                                                                                                                    //     // Make a fileInfo Object
                                                                                                                    //     baseURL = reader.result;
                                                                                                                    //     // baseURL = image
                                                                                                                    //     let obj =
                                                                                                                    //     {
                                                                                                                    //         path: baseURL,
                                                                                                                    //         thumbnail: false,
                                                                                                                    //         id: id,
                                                                                                                    //     }
                                                                                                                    //         ;

                                                                                                                    //     setimagesNew(imagesNew.concat(obj))


                                                                                                                    // };


                                                                                                                }}
                                                                                                            /> */}
                                                                                                                </div>
                                                                                                            }


                                                                                                        </div>
                                                                                                    </td>
                                                                                                </>
                                                                                            }


                                                                                            {/* <td className="!pl-2">
                                            <Input
                                              type="text"
                                              className="form-control min-w-[6rem]"
                                              placeholder="Variant Code"
                                            />
                                          </td> */}
                                                                                        </tr>
                                                                                    </>
                                                                                }


                                                                            </>
                                                                        );
                                                                    })}


                                                                </>

                                                            );

                                                        }




                                                    })}


                                                </tbody>
                                            </table>
                                        </div>

                                    }
                                    {errdetails && (
                                        <div className="text-red-600 text-sm text-right mt-2">
                                            This field is required
                                        </div>
                                    )}


                                </div>
                            </>
                        }
                        <div className="w-full items-center justify-center">

                            <BotToast open={bOpen} msg={bMsg} status={bStatus} />

                            <button
                                className={
                                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-8 `}

                                onClick={saveProduct2}
                            >
                                {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                                <span className="block  text-sm font-medium "
                                >

                                    {product ? "Save" : "Save & add another"}

                                </span>

                            </button>


                        </div>

                    </div>

                </div>
            </div>

        </>
    )

}

export default CreateStore;