import React, { FC, useContext, useEffect } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link, NavLink, useHistory } from "react-router-dom";
import ButtonCircle from "shared/Button/ButtonCircle";
import Company1 from "images/sLogo.png";
import { StarIcon } from "@heroicons/react/24/solid";
import Rating from "@mui/material/Rating";
import ModalQuickView from "components/ModalQuote";
import AuthContext from "Context/AuthContext";
import { IMAGE_SERVER_URL_DEV, IMAGE_SERVER_URL_DEV2 } from "Constant/System";
import LikeButton from "components/LikeButton";
import ModalQuickView2 from "components/ModalAd";
import WishContext from "Context/WishContext";
import _ from "lodash";
import { AppliedJobs } from "Services/API/Get";
import { t } from "i18next";
import demAvatar from "images/avatars/Image-2.png";
import ModalQuickViewCard from "components/ModalJobDetails";
import parse from "html-react-parser";

export const getTime = (value: any) => {
  let text: any = "";

  if (value > 525600) {
    value = Math.floor(value / 525600);

    return `${value} years ago`;
  } else if (value > 43800) {
    value = Math.floor(value / 43800);

    return `${value} months ago`;
  } else if (value > 10080) {
    value = Math.floor(value / 10080);

    return `${value} weeks ago`;
  } else if (value > 1440) {
    value = Math.floor(value / 1440);

    return `${value} days ago`;
  } else if (value > 60) {
    value = Math.floor(value / 60);

    return `${value} hours ago`;
  } else if (value > 0) {
    return `${value} minutes ago`;
  } else if (value) {
    value = Math.floor(value * 60);

    return `${value} secs ago`;
  } else {
    return "secs ago";
  }
};

export interface CardCategory2Props {
  className?: string;
  ratioClass?: string;
  bgClass?: string;
  featuredImage?: string;
  name?: string;
  desc?: string;
  collection?: any;
  data?: any;
  selectedAdds?: any;
  setselectedAdds?: any;
  type?: any;
  form?: any;
}

const CardCategory2: FC<CardCategory2Props> = ({
  className = "",
  ratioClass = "aspect-w-1 aspect-h-1",
  bgClass = "bg-orange-50",
  featuredImage = ".",
  name,
  desc,
  collection,
  data,
  selectedAdds,
  setselectedAdds,
  type,
  form = false
}) => {
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);

  const router = useHistory();
  const { user, setUser } = useContext(AuthContext);

  const { applied, setapplied } = useContext(WishContext);

  useEffect(() => {
    console.log(">>>>applied", applied);
  }, [applied]);

  useEffect(() => {
    AppliedJobs({
      jobType: JSON.stringify([])
    })
      .then((res) => {
        console.log(">>>>>>>jobs", res.data);
        setapplied(res.data.result);
      })
      .catch((error) => {
        // setlopen(false);
        setapplied([]);
        console.log("Error", error.response);

        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  }, [showModalQuickView]);

  const getState = (item?: any) => {
    if (item === "Victoria") {
      return "VIC";
    } else if (item === "New South Wales") {
      return "NSW";
    } else if (item === "Western Australia") {
      return "WA";
    } else if (item === "Queensland") {
      return "QLD";
    } else if (item === "Tasmania") {
      return "TAS";
    } else if (item === "South Australia") {
      return "SA";
    } else if (item === "Northern Territory") {
      return "NT";
    } else if (item === "Australian Capital Territory") {
      return "ACT";
    }
  };

  return (
    <>
      {/* <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
        jobId={data}
        type={"job"}
        setselectedAdds={setselectedAdds}
      /> */}

      {/* <ModalQuickView2
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
        jobId={data}
        type={"job"}
        setselectedAdds={setselectedAdds}
        form={form}
      /> */}

      <ModalQuickViewCard
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
        selectedJob={data}
      />

      <div
        className={`nc-CardCategory2 ${className} h-full`}
        data-nc-id="CardCategory2"
      >
        {/* {
          type === "shop" &&

          <div className="">
            <input id="checkbox-switch-2" className="ml-0 mb-3 w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer" type="checkbox"
              checked={selectedAdds?.includes(data?.id)}
              onChange={(checked) => {

                console.log("<<<<<", data.id)

                if (selectedAdds?.includes(data?.id)) {
                  const index = selectedAdds.indexOf(data?.id);

                  console.log(">>>>>>index", index)

                  var arr = [...selectedAdds]
                  if (index > -1) {
                    arr.splice(index, 1);
                    console.log("arr>>>>", arr)
                    setselectedAdds(arr)
                  }

                } else {
                  setselectedAdds(((item: any) => [...item, data?.id]))
                }
                // setSelectedcat(data?.id);


              }}
            />
          </div>
        } */}

        <div
        // className={`flex-1 relative w-full h-fit rounded-2xl group`}
        >
          <div
            className="
          //shadow-[0px_5px_10px_5px_rgba(0,0,0,0.3)] 
          //shadow-slate-200
          bg-white
          rounded-2xl relative min-h-[300px] h-full"
          >
            {/* <LikeButton
              // liked={_.map(wish, "id").includes(id)} 
              className="absolute top-4 right-4 z-10"
            //  addWish={notifyAddToWish} 
            /> */}

            <div className="flex justify-end w-full h-full">
              {/* <i className="las la-bookmark text-4xl text-left mr-1 mt-2 "></i> */}
              {/* <img className="text-4xl text-left mr-1 mt-2 cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA00lEQVR4nO2VMU4CQRiFNzQmlhQewIYj2HgBQmPnMSw5Btew4w5WHoFslnnfm2ULCkoTG1lDgYUJgR0WtJgv+bv38iWTmX+KIvMfACZIK0PbZZBqSeN0sVR3lf7IISaLnSjdTxafTD5q58tFfk7kBZKG867mj38npK3tqe0XpK9riT8dwvM+E6UnpI+LipE2th9/5+oQHgzry4ilZQhhdDBr3xsWvYqB96qq7o7lY4xDpLdexMC8aZrbUztlWd4Ar2eJgVnbtoOuvV1n100WZ4oe+QZXC8i3JwqA3AAAAABJRU5ErkJggg==" /> */}
              {/* <div className="px-4 bg-[#FF4848] rounded-xl mr-2 mt-4 flex items-center py-1">
                <span className="text-xs text-slate-100">Featured</span>
              </div> */}
            </div>
            <div
              className={`${
                collection ? "px-3 sm:px-5" : "px-7"
              } pb-6 pt-5 h-full`}
            >
              {/* <NavLink to={`/job-single/${data?.id}`} > */}

              <div className="flex">
                <img
                  className="w-14 aspect-square object-fill bg-cover rounded-md"
                  src={
                    data?.avatar && JSON.parse(data?.avatar).length > 0
                      ? `${IMAGE_SERVER_URL_DEV2}${
                          JSON.parse(data?.avatar)[0].path
                        }`
                      : Company1
                  }
                />
              </div>

              <div className="mt-0">
                <div className="flex justify-between w-full">
                  <div className="flex flex-col px-1">
                    <h2 className="text-xs sm:text-xs font-medium text-blueF mt-5">
                      {/* Senior Business Development Manager */}
                      {data?.title}

                   </h2>

                    <span className="text-redF text-xs mt-1">
                      {data?.negotiable == 1 ? (
                       "Nagociatable"
                      ) : (
                        <>
                          LKR {data?.MinSalary} - LKR {data?.MaxSalary}{" "}
                        </>
                      )}
                    </span>

                    <span
                      className={`text-slate-900 flex items-center justify-start ${
                        t("langKey") === "Si" ? "text-[8pt]" : "text-[8pt]"
                      }  -ml-1`}
                    >
                      {/* <i className="las la-eye text-lg text-left mr-1"></i>
                     
                      {data?.viewsCount} */}
                      {/* <i className={`las la-map-marker-alt text-lg text-left mr-1`}></i>
                      {
                        data?.currentResidesCountry === "Sri Lanka" ?
                          <>
                            {data?.city} {getState(data?.district)}

                          </>



                          :
                          <>
                            {data?.currentResidesCountry}

                          </>

                      } */}
                    </span>
                  </div>

                  {/* <div className="flex">



                    {
                      _.map(applied, "adId").includes(data?.id) === true ?
                        <button
                          className={
                            `ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70 
         font-medium cursor-pointer  bg-[#d63a3a] px-2.5 lg:px-4 h-5`}



                        >

                          <span className="block  text-[7px] lg:text-[9px] font-medium whitespace-nowrap text-white">
                            Applied
                          </span>

                        </button>

                        :

                        // <LikeButton
                        //   // liked={_.map(wish, "id").includes(id)} 
                        //   className=""
                        // //  addWish={notifyAddToWish} 
                        // />

                        <></>


                    }


                  </div> */}
                </div>

                <span
                  className={`flex justify-start ${
                    collection ? "text-[10px]" : "text-xs"
                  } text-[#737373] dark:text-neutral-400 mt-4 `}
                >
                  <span className="flex items-center justify-start">
                    <i className="las la-map-marker-alt text-lg text-left mr-1"></i>
                    {data?.city} {data?.district}
                  </span>

                  {/* <span className="text-slate-900 flex items-center">


                    <i className="las la-clock text-lg text-left mr-1"></i>

                    {getTime(data?.dif)}
                  </span> */}

                  <span className="flex items-center ml-6">
                    <i className="las la-clock text-lg text-left mr-1"></i>
                    {/* {data?.dif}  */}
                    {/* minutes ago */}
                    {getTime(data?.dif)}
                  </span>
                </span>

                <span className="text-xs pt-5 w-full">
                  {data?.description?.length > 50
                    ? parse(data?.description?.substring(0, 120))
                    : parse(data?.description)}
                </span>
              </div>

              {/* </NavLink> */}

              <div className="mt-4 mb-2 w-full flex justify-center">
                {/* <button
                  className={
                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-gray-100 
        w-full h-9 font-medium`}

                >
                  <span className="block  text-xs font-bold">
                    {
                      data ? `${data?.currency} ${data?.hourlyRate}/Hourly` : "$ 250/Hourly"
                    }

                  </span>

                </button> */}

                <button
                  className={`ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-white border border-redF text-[#737373] hover:bg-redF hover:text-white
                     w-full lg:w-fit lg:px-10 h-8 font-normal mt-2 cursor-pointer`}
                  onClick={() => {
                    // if (!user) {
                    //   router.push("/login");
                    //   return;
                    // }

                    setShowModalQuickView(true);
                  }}
                >
                  {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
                  <span className="block  text-xs font-medium">
                    {t("hView")}
                  </span>
                </button>
              </div>

              {/* <img
            className="object-cover "
            src={featuredImage}

          /> */}
              {/* <NcImage
            containerClassName="w-full h-full flex justify-center"
            src={featuredImage}
            className="object-cover rounded-2xl"
          /> */}
            </div>
          </div>
          <div className="pt-5"></div>
          {/* <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span> */}
        </div>
        {/* <div className="mt-5 flex-1 text-center">
        <h2 className="text-base sm:text-base text-neutral-900 dark:text-neutral-100 font-semibold">
          {name}
        </h2>
        <span className="block mt-0.5 sm:mt-1.5 text-sm text-neutral-500 dark:text-neutral-400">
          {desc}
        </span>
      </div> */}
      </div>
    </>
  );
};

export default CardCategory2;
