import React, { FC, useState, useEffect, useRef, useContext } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import AuthContext from "Context/AuthContext";
import { CurrentUser } from "Services/API/Get";
import { addToast } from "shared/Toast";
import { EditUserQuery } from "Services/API/Put";
import { StripeConnect } from "Services/API/Post";
import LoadingSpinner from "shared/LoadingSpinner";
import BotToast from "shared/BotToast";

const Gateway = (props) => {

    const cUser = props?.currUser

    const { user, setUser } = useContext(AuthContext);
    const [firstName, setfirstName] = useState("");
    const [lastName, setlasttName] = useState("");
    const [mobile, setmobile] = useState("");
    const [email, setemail] = useState("");
    const [premail, setpremail] = useState("");
    const [lopen, setlopen] = React.useState(false);

    const [homeNo, sethomeNo] = useState("");
    const [homeAddress, sethomeAddress] = useState("");
    const [homeDistrict, sethomeDistrict] = useState("");
    const [homeCity, sethomeCity] = useState("");
    const [homepostCode, sethomepostCode] = useState("");

    const [errhomeDistrict, seterrhomeDistrict] = useState(false);
    const [errhomeCity, seterrhomeCity] = useState(false);
    const [errhomepostCode, seterrhomepostCode] = useState(false);
    const [errhomeAddress, seterrhomeAddress] = useState(false);

    const [homeDistrictId, sethomeDistrictId] = useState(0);
    const [allHCities, setallHCities] = useState([]);
    const [allHDistricts, setallHDistricts] = useState([]);

    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [repassword, setrePassword] = useState("");
    const [idisable, setidisable] = useState(true);
    const [errfirstName, seterrfirstName] = useState(false);
    const [errlastName, seterrlasttName] = useState(false);
    const [errmobile, seterrmobile] = useState("");
    const [erremail, seterremail] = useState(false);

    const [errPass, seterrPass] = useState(false);
    const [errPass2, seterrPass2] = useState("");
    const [errRePass, seterrRePass] = useState("");

    const [userId, setuserId] = useState("");
    const [currUser, setcurrUser] = useState("");
    const [eDisable, seteDisable] = useState(true);
    const [eDisable2, seteDisable2] = useState(true);

    const [method, setmethod] = useState("Stripe");
    const [clientId, setclientId] = useState("");
    const [apiKey, setapiKey] = useState("");

    const [errmethod, seterrmethod] = useState(false);
    const [errclientId, seterrclientId] = useState("");
    const [errapiKey, seterrapiKey] = useState("");

    const [bOpen, setbOpen] = useState(false);
    const [bMsg, setbMsg] = useState("");
    const [bStatus, setbStatus] = useState("");

    const updateAccount = async (e) => {

        const phoneRegex =
            /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

        e.preventDefault();

        // if (password === "") {
        //     seterrPass(true)

        // }

        // if (password2 === "") {
        //     seterrPass2("This field is required")

        // }

        // if (password != password2) {
        //     seterrPass2("Password Mismatch")

        // }

        // if (repassword === "") {
        //     seterrRePass("This field is required");

        // }

        // if (password !== "") {
        //     //   seterrPass(true)
        //     if (repassword === "") {
        //         seterrRePass("This field is required");
        //     } else {
        //         seterrRePass("");
        //     }
        // }





        // if(password != "" && repassword != "" && !(password === repassword)){
        //   seterrRePass("Password Mismatch")
        // }
        // if(password != "" && repassword != "" && (password === repassword)){
        //   seterrRePass("")
        // }

        let obj = {
            email: currUser?.email,
            user: currUser
        }
        console.log("obj>>>>", obj);

        if (

            // (clientId && apiKey && method)

            true
        ) {
            setlopen(true);

            StripeConnect(obj)
                .then((res) => {
                    setlopen(false);
                    // addToast("Successfully updated", "success");
                    setbOpen(true);
                    setbMsg(`The stripe connect link has been sent to the email. Please click on the confirmation link to activate your account. `);
                    setbStatus("success");
                    setPassword("");
                    setPassword2("");
                    setrePassword("");
                    // setOpenToast(true);
                    // setColor(COLORS.Primary);
                    // setAlertClass("success");
                    // setMsg("Successfully updated");
                    getCurrentUser();
                    // getUser();
                    // seteDisable(true);

                    // history.push("/");
                    // window.location.reload();
                })
                .catch((error) => {
                    setlopen(false);
                    console.log("Error", error.response);
                    // addToast(error.response.data.message, "warning");
                    setbOpen(true);
                    setbMsg(error.response.data.message);
                    setbStatus("warning");
                    // setOpenToast(true);
                    // setColor(COLORS.Orange);
                    // setAlertClass("error");
                    // setMsg(error.response.data.message);
                })


        } else {
            // scrollElement();
        }
    };

    const getCurrentUser = () => {
        CurrentUser()
            .then((res) => {
                if (res.statusCode === 200) {
                    const cData = res.data[0];
                    setUser(cData);
                    const obj = {
                        id: cData.id,
                        email: cData?.email,
                        businessName: cData?.businessName,
                        contactName: cData?.contactName,
                        phone: cData?.mobileNumber,
                    };
                    //   this.setState({
                    //     businessName: cData?.businessName,
                    //     contactName: cData?.contactName,
                    //     email: cData?.email,
                    //     tele: cData?.phone,
                    //   });
                    console.log("<><><><>", cData);
                    setcurrUser(cData);
                    setmethod(cData?.paymentGateway !== null ? cData?.paymentGateway : "");
                    setapiKey(cData?.secretId !== null ? cData?.secretId : "");
                    setclientId(cData?.clientId !== null ? cData?.clientId : "");
                    // setName(cData?.businessName + " " + cData?.contactName);
                    // setfirstName(cData?.firstName);
                    // setlastName(cData?.lastName);
                    // setcategory(cData?.supplierCategory !== null ? cData?.supplierCategory : "");
                    // setbusinessName(cData?.businessName !== null ? cData?.businessName : "");
                    // setbusinessReg(cData?.businessRegistrationNumber !== null ? cData?.businessRegistrationNumber : "");
                    // setyear(cData?.establishedYear !== null ? cData?.establishedYear : "");
                    // setwebsite(cData?.website !== null ? cData?.website : "");
                    // setcountry(cData?.country !== null ? cData?.country : "");
                    // setmobile(cData?.contactNumber !== null ? cData?.contactNumber : "");
                    // setmobile2(cData?.contactNumber2 !== null ? cData?.contactNumber2 : "");
                    // setcontactName(cData?.contactName !== null ? cData?.contactName : "");
                    // setemail(cData?.email);
                    // setpremail(cData?.email);
                    // setabout(cData?.about !== null ? cData?.about : "");
                    // sethomeDistrict(cData?.homeDistrict !== null ? cData?.homeDistrict : "");
                    // sethomeAddress(cData?.homeAddress !== null ? cData?.homeAddress : "");
                    // sethomeCity(cData?.homeCity !== null ? cData?.homeCity : "");
                    // sethomeNo(cData?.homeNumber !== null ? cData?.homeNumber : "");
                    // sethomepostCode(cData?.homepostCode !== null ? cData?.homepostCode : "");
                    setuserId(cData?.id);


                    localStorage.setItem(
                        `${window.location.hostname}.profile`,
                        JSON.stringify(obj)
                    );
                }
            })
            .catch((error) => {
                console.log("error", error);
                console.log("current user error", error.response.data.message);
            });
    };

    React.useEffect(() => {
        getCurrentUser();

    }, []);

    useEffect(() => {
        if (bOpen === true) {
            setTimeout(() => {

                setbOpen(false)

            }, 10000);
        }
    }, [bOpen])

    return (
        <>
            <LoadingSpinner open={lopen} />
            <div className="w-full flex justify-center px-3">
                <div className="w-full md:w-9/12 lg:w-[45%]">
                    <div className="justify-center w-full mt-12">

                        <span className="block text-xl font-bold flex justify-center w-full text-center">
                            Connect Payment Gateway
                        </span>

                        <div className="flex justify-center">
                            <span className="text-xs font-normal flex justify-center w-full text-center mt-1 mb-6">
                                Through this section, you can create your Stripe
                                payment gateway with your Tidytraderr account. You will receive an email
                                containing further instructions once you click the "Connect Account"
                                button to initiate the process.
                            </span>
                        </div>


                    </div>
                    {/* <div className="flex w-full mt-4">
                        <span className="block text-base font-normal flex justify-start w-full text-center">
                            Recommended payment gateways
                        </span>

                    </div>
                    <div className="flex-grow mt-4">
                        <img className="flex justify-center" src={Stripe} alt="" />
                    </div> */}
                    {/* <span className="block text-base font-bold flex justify-start w-full text-center mt-4">
                        Set up guide
                    </span>
                    <div
                        id="single-entry-content"
                        className="prose prose-sm  w-full sm:prose lg:prose-lg dark:prose-invert mt-4"
                    >
                        <span className="text-sm font-medium">You will receive an email containing instructions to link your Stripe account with Spotly. Please click the "Connect Account" button to initiate the process.</span>
                        <ol className="text-sm font-medium">
                            <li>If you already have an account with one of these providers, please
                                select your preferred payment gateway from the below dropdown.</li>
                            <li>Please enter your Customer ID and Pay ID and click "Save".</li>
                            <li>If you do not have a payment gateway account yet, please visit one
                                of the below sites and create an account. Once you are created
                                your account, you will get your customer ID and API.</li>
                            <li>If you already have another payment gateway please contact our
                                support. Click here to create your support ticket.</li>

                        </ol>
                    </div> */}
                    <div className="w-full mt-6 space-y-4">
                        {/* <div>
                            <Label className="text-sm">Select the service provider</Label>
                            <Select className="mt-1.5"
                                onChange={(e) => {
                                    setmethod(e.target.value);
                                    seterrmethod(false);
                                }}
                                value={method}
                            >
                                <option value={""}>Select a service</option>
                                <option value="Stripe">Stripe</option>
                                
                            </Select>
                            {errmethod && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">Customer ID</Label>
                            <Input className="mt-1.5"
                                onChange={(e) => {
                                    setclientId(e.target.value);
                                    seterrclientId(false);
                                }}
                                value={clientId}
                            />
                            {errclientId && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">API Number</Label>
                            <Input className="mt-1.5"
                                onChange={(e) => {
                                    setapiKey(e.target.value);
                                    seterrapiKey(false);
                                }}
                                value={apiKey}
                            />
                            {errapiKey && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}
                        <div className="w-full items-center justify-center">
                            <BotToast open={bOpen} msg={bMsg} status={bStatus} />
                            <button
                                className={
                                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-4 `}

                                onClick={updateAccount}
                            >
                                {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                                <span className="block  text-sm font-medium "
                                >
                                    Connect Account
                                </span>

                            </button>


                        </div>

                    </div>

                </div>
            </div>

        </>
    )

}

export default Gateway;