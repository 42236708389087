import React, { Fragment, useState, FC } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
import Radio from "shared/Radio/Radio";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import MySwitch from "components/MySwitch";
import { SectionSliderProductCardProps } from "./SidebarFilters";
import { getCities, getDistricts } from "Services/API/Get";
import { Autocomplete } from "@mui/material";
import Input from "shared/Input/Input";
import SubData from "data/australian_postcodes.json";
import _, { lowerCase, upperFirst } from "lodash";

// DEMO DATA
const DATA_categories = [
  {
    name: "New Arrivals",
  },
  {
    name: "Sale",
  },
  {
    name: "Backpacks",
  },
  {
    name: "Travel Bags",
  },
  {
    name: "Laptop Sleeves",
  },
  {
    name: "Organization",
  },
  {
    name: "Accessories",
  },
];

const DATA_colors = [
  { name: "White" },
  { name: "Beige" },
  { name: "Blue" },
  { name: "Black" },
  { name: "Brown" },
  { name: "Green" },
  { name: "Navy" },
];

const JOBTYPES = [
  {
    name: "Seasonal",
    value: "Seasonal",
    id: 5,
  },
  {
    name: "Casual",
    value: "Casual",
    id: 1,
  },
  {
    name: "Temporary",
    value: "Temporary",
    id: 2,
  },
  {
    name: "Fixed term contract",
    value: "Fixed",
    id: 3,
  },
  {
    name: "Permanent – Part Time",
    value: "Part Time",
    id: 4,
  },
  {
    name: "Permanent – Full time",
    value: "Full Time",
    id: 4,
  },

]

const SELLER_TYPES = [
  {
    name: "Wholesaler",
    value: "wholesaler",
    id: 2,
  },
  {
    name: "Retailer",
    value: "retail supplier",
    id: 1,
  },
  // {
  //   name: "Service Provider",
  //   value: "service provider",
  //   id: 3,
  // },
  // {
  //   name: "Service Seeker/Buyer",
  //   value: "service seeker",
  //   id: 4,
  // },

]

const DATA_sizes = [
  { name: "XXS" },
  { name: "XS" },
  { name: "S" },
  { name: "M" },
  { name: "L" },
  { name: "XL" },
  { name: "2XL" },
];

const DATA_sortOrderRadios = [
  // { name: "Most Popular", id: "featured" },
  // { name: "Best Rating", id: "Best-Rating" },
  // { name: "Newest", id: "latest" },
  { name: "Name A - Z", id: "LowToHigh" },
  { name: "Name Z - A", id: "HighToLow" },
];

const DATA_sortOrderRadios2 = [
  // { name: "Most Popular", id: "featured" },
  // { name: "Best Rating", id: "Best-Rating" },
  // { name: "Newest", id: "latest" },
  { name: "Hourly Rate Low - High", id: "LowToHigh" },
  { name: "Hourly Rate High - Low", id: "HighToLow" },
  { name: "Newest - Older", id: "latest" },
  { name: "Older - Newest", id: "older" },
];

const DATA_sortOrderRadios3 = [
  // { name: "Most Popular", id: "featured" },
  // { name: "Best Rating", id: "Best-Rating" },
  // { name: "Newest", id: "latest" },
  { name: "Name A - Z", id: "LowToHigh" },
  { name: "Name Z - A", id: "HighToLow" },
  { name: "Newest - Older", id: "latest" },
  { name: "Older - Newest", id: "older" },
];

const AGEarr = [
  "18+",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "69",
  "70",
  "71",
  "72",
  "73",
  "74",
  "75",
  "75+"
]

const CASTarr = [
  "Any",
  "Bathagama",
  "Choliya",
  "Dewa",
  "Colombo Chetti",
  "Durawa",
  "Govigama",
  "Hunu",
  "Karawa",
  "Kumbal",
  "Nekath Oli",
  "Panna",
  "Radala",
  "Rajaka",
  "Salagama",
  "Vishwa",
  "Other"
]

const RACEarr = [
  "Sinhalese",
  "Tamil",
  "Muslim",
  "Burgher"
]

const RELIGIONarr = [
  "Buddhism",
  "Christian",
  "Islam",
  "Hindu",
  "Other"
]

const EDUarr = [
  "Up to O/L",
  "Up to A/L",
  "Diploma",
  "Higher Diploma",
  "Bachelor Degree",
  "Master Degree",
  "PhD"
]


const OCUarr = [
  "Bank Manager",
  "Doctor",
  "English Teacher",
  "Nurse",
  "Teacher",
  "Bank Officer ",
  "Assistant Bank Manager ",
]

const MARarr = [
  "Never Married",
  "Divorced",
  "Single Parent"
]


const PRICE_RANGE = [0, 999];
//
const TabFilters: FC<SectionSliderProductCardProps> = ({
  categories = [{ mainId: "", maincategory: "" }],
  setSelectedcat,
  selectedCat,
  allSubs = [],
  setselectedSub,
  selectedSub,
  allSizes = [],
  allColours = [],
  selectedSize,
  selectedColour,
  setselectedSize,
  setselectedColour,
  setminPrice,
  minPrice,
  setmaxPrice,
  maxPrice,
  setFilterSortValue,
  filterSortValue,
  setCurrentPage,
  setmainDesc,
  setsearch,
  search,
  selectedPro,
  setselectedPro,
  homeDistrict,
  homeCity,
  sethomeDistrict,
  sethomeCity,
  filterType = "company",
  houseType,
  sethouseType,
  payType,
  setpayType,
  fromDate,
  setfromDate,
  settoDate,
  toDate,

}) => {
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  //
  const [isOnSale, setIsIsOnSale] = useState(true);
  const [rangePrices, setRangePrices] = useState([minPrice, maxPrice]);
  const [categoriesState, setCategoriesState] = useState<string[]>([]);
  const [colorsState, setColorsState] = useState<string[]>([]);
  const [sizesState, setSizesState] = useState<string[]>([]);
  const [sortOrderStates, setSortOrderStates] = useState<string>("");

  const [homeDistrictId, sethomeDistrictId] = useState(0);
  const [allHCities, setallHCities] = useState([]);
  const [allHDistricts, setallHDistricts] = useState([]);

  //
  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);

  //
  const handleChangeCategories = (checked: boolean, name: string) => {
    checked
      ? setCategoriesState([...categoriesState, name])
      : setCategoriesState(categoriesState.filter((i) => i !== name));
  };

  const handleChangeColors = (checked: boolean, name: string) => {
    checked
      ? setColorsState([...colorsState, name])
      : setColorsState(colorsState.filter((i) => i !== name));
  };

  const handleChangeSizes = (checked: boolean, name: string) => {
    checked
      ? setSizesState([...sizesState, name])
      : setSizesState(sizesState.filter((i) => i !== name));
  };

  const [newSubArray, setnewSubArray] = useState<any>([]);
  const [newSubs, setnewSubs] = useState<any>([]);

  const [newSubs2, setnewSubs2] = useState<any>([]);

  React.useEffect(() => {

    let newSubArray = JSON.parse(JSON.stringify(SubData));

    newSubArray = newSubArray?.map((d: any) => {
      if (d.type === "Delivery Area") {

        return {
          ...d,
          locality: upperFirst(lowerCase(d.locality)),
        };

      }

    });

    newSubArray = _.compact(newSubArray)

    setnewSubArray(_.orderBy(newSubArray, ['locality'], ['asc']));

    let arr: any = _.orderBy(newSubArray, ['locality'], ['asc'])

    console.log("arr>>", arr)

    arr = _.uniq(arr);

    setnewSubs(arr);

    console.log(">>>>>>>subs", newSubArray.filter((u: any) => u.state === 0))


  }, []);

  React.useEffect(() => {
    listDistricts();

  }, []);

  React.useEffect(() => {
    if (homeDistrictId !== 0) {
      listHomecities();
    }
  }, [homeDistrictId]);

  const listDistricts = () => {
    getDistricts()
      .then((res) => {
        console.log("districts>>>", res);
        setallHDistricts(res.data);
        // setallODistricts(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };
  const listHomecities = () => {
    getCities(homeDistrict)
      .then((res) => {
        console.log("cities>>>", res);
        setallHCities(res.data.records);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setallHCities([])
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const handleChangeHdist = (e?: any, value?: any) => {
    if (value !== null) {
      if (value.name_en === "All") {
        sethomeDistrict("");

      } else {
        sethomeDistrict(value.name_en);

        sethomeDistrictId(value.id);
      }


    } else {
      sethomeDistrict("");
      sethomeDistrictId(1);
    }

    setnewSubs(newSubArray.filter((u: any) => u.state === value.id))

    // setnewSubs([]);

    // const filtered = newSubArray.filter((u: { state: any; }) => u.state === value.id)

    // const perChunk = 700

    // const result = filtered.reduce((filtered: any, item: any, index: any) => {
    //   const chunkIndex = Math.floor(index / perChunk)

    //   if (!filtered[chunkIndex]) {
    //     filtered[chunkIndex] = [] // start a new chunk
    //   }

    //   filtered[chunkIndex].push(item)

    //   return filtered
    // }, [])

    // console.log("<<<<<<<", result)

    // for (let i = 0; i < result.length - 1; i++) {

    //   let item = result[i];

    //   setTimeout(() => {
    //     setnewSubs((existing: any) => [...existing, ...item]);
    //   }, i === 0 ? 0 : (2000));
    // }

    sethomeCity("")

    console.log("state>>", value);
  };
  const handleChangeHcity = (e?: any, value?: any) => {
    if (value !== null) {
      // if (value.fields.scc_name === "All") {
      //   sethomeCity("");
      // } else {
      //   sethomeCity(value.fields.scc_name)
      // }

      if (value.locality === "All") {
        sethomeCity("");
      } else {
        sethomeCity(value)
      }


    } else {
      sethomeCity("");


    }

    console.log("city>>", value);
  };


  //

  // OK
  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  // OK
  const renderTabsCategories = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none select-none
               ${open
                  ? "!border-primary-500 "
                  : "border-neutral-300 dark:border-neutral-700"
                }
                ${!!categoriesState.length
                  ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 2V5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16 2V5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 13H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 17H12"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16 3.5C19.33 3.68 21 4.95 21 9.65V15.83C21 19.95 20 22.01 15 22.01H9C4 22.01 3 19.95 3 15.83V9.65C3 4.95 4.67 3.69 8 3.5H16Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2">Categories</span>
              {!categoriesState.length ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() => setCategoriesState([])}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <Checkbox
                      name="All Categories"
                      label="All Categories"
                      defaultChecked={categoriesState.includes(
                        "All Categories"
                      )}
                      onChange={(checked) =>
                        handleChangeCategories(checked, "All Categories")
                      }
                    />
                    <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
                    {DATA_categories.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={categoriesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeCategories(checked, item.name)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setCategoriesState([]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  // OK
  const renderTabsSortOrder = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center w-full w-[150px] h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${!!filterSortValue
                  ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "bg-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg className="w-4 h-4" viewBox="0 0 20 20" fill="none">
                <path
                  d="M11.5166 5.70834L14.0499 8.24168"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.5166 14.2917V5.70834"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.48327 14.2917L5.94995 11.7583"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.48315 5.70834V14.2917"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 10C18.3334 5.39763 14.6025 1.66667 10.0001 1.66667C5.39771 1.66667 1.66675 5.39763 1.66675 10C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2">
                {filterSortValue
                  ? DATA_sortOrderRadios.filter(
                    (i) => i.id === filterSortValue
                  )[0].name
                  : "Sort order"}
              </span>
              {!filterSortValue ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() => setFilterSortValue("")}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 right-0 sm:px-0 lg:max-w-sm">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-3">
                    <Radio
                      id={""}
                      key={""}
                      name="radioNameSort"
                      label={"None"}
                      className="!text-xs"
                      sizeClassName="w-5 h-5"
                      // defaultChecked={sortOrderStates === item.id}
                      checked={filterSortValue === ""}
                      // defaultChecked={sortOrderStates === item.id}

                      onChange={setFilterSortValue}
                    // onChange={setSortOrderStates}
                    />
                    {DATA_sortOrderRadios.map((item) => (
                      <Radio
                        id={item.id}
                        key={item.id}
                        name="radioNameSort"
                        className="!text-xs"
                        sizeClassName="w-5 h-5"
                        label={item.name}
                        // defaultChecked={sortOrderStates === item.id}
                        checked={filterSortValue === item.id}
                        // defaultChecked={sortOrderStates === item.id}

                        onChange={setFilterSortValue}
                      // onChange={setSortOrderStates}
                      />
                    ))}
                  </div>
                  {/* <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setSortOrderStates("");
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsSortOrderContract = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center  w-full w-[150px] h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${!!filterSortValue
                  ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "bg-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg className="w-4 h-4" viewBox="0 0 20 20" fill="none">
                <path
                  d="M11.5166 5.70834L14.0499 8.24168"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.5166 14.2917V5.70834"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.48327 14.2917L5.94995 11.7583"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.48315 5.70834V14.2917"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 10C18.3334 5.39763 14.6025 1.66667 10.0001 1.66667C5.39771 1.66667 1.66675 5.39763 1.66675 10C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2">
                {filterSortValue
                  ? DATA_sortOrderRadios3.filter(
                    (i) => i.id === filterSortValue
                  )[0].name
                  : "Sort order"}
              </span>
              {!filterSortValue ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() => setFilterSortValue("")}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 right-0 sm:px-0 lg:max-w-sm">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-3">
                    <Radio
                      id={""}
                      key={""}
                      name="radioNameSort"
                      label={"None"}
                      className="!text-xs"
                      sizeClassName="w-5 h-5"
                      // defaultChecked={sortOrderStates === item.id}
                      checked={filterSortValue === ""}
                      // defaultChecked={sortOrderStates === item.id}

                      onChange={setFilterSortValue}
                    // onChange={setSortOrderStates}
                    />
                    {DATA_sortOrderRadios3.map((item) => (
                      <Radio
                        id={item.id}
                        key={item.id}
                        name="radioNameSort"
                        className="!text-xs"
                        sizeClassName="w-5 h-5"
                        label={item.name}
                        // defaultChecked={sortOrderStates === item.id}
                        checked={filterSortValue === item.id}
                        // defaultChecked={sortOrderStates === item.id}

                        onChange={setFilterSortValue}
                      // onChange={setSortOrderStates}
                      />
                    ))}
                  </div>
                  {/* <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setSortOrderStates("");
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };


  const renderTabsSortOrderPrice = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center  w-full w-[150px] h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${!!filterSortValue
                  ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "bg-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg className="w-4 h-4" viewBox="0 0 20 20" fill="none">
                <path
                  d="M11.5166 5.70834L14.0499 8.24168"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.5166 14.2917V5.70834"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.48327 14.2917L5.94995 11.7583"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.48315 5.70834V14.2917"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 10C18.3334 5.39763 14.6025 1.66667 10.0001 1.66667C5.39771 1.66667 1.66675 5.39763 1.66675 10C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2">
                {filterSortValue
                  ? DATA_sortOrderRadios2.filter(
                    (i) => i.id === filterSortValue
                  )[0].name
                  : "Sort order"}
              </span>
              {!filterSortValue ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() => setFilterSortValue("")}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 right-0 sm:px-0 lg:max-w-sm">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-3">
                    <Radio
                      id={""}
                      key={""}
                      name="radioNameSort"
                      label={"None"}
                      className="!text-xs"
                      sizeClassName="w-5 h-5"
                      // defaultChecked={sortOrderStates === item.id}
                      checked={filterSortValue === ""}
                      // defaultChecked={sortOrderStates === item.id}

                      onChange={setFilterSortValue}
                    // onChange={setSortOrderStates}
                    />
                    {DATA_sortOrderRadios2.map((item) => (
                      <Radio
                        id={item.id}
                        key={item.id}
                        name="radioNameSort"
                        className="!text-xs"
                        sizeClassName="w-5 h-5"
                        label={item.name}
                        // defaultChecked={sortOrderStates === item.id}
                        checked={filterSortValue === item.id}
                        // defaultChecked={sortOrderStates === item.id}

                        onChange={setFilterSortValue}
                      // onChange={setSortOrderStates}
                      />
                    ))}
                  </div>
                  {/* <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setSortOrderStates("");
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  // OK
  const renderTabsState = () => {
    return (

      <>
        <Autocomplete
          className=""
          // className="form-group"
          // className="border-neutral-200"
          disablePortal
          defaultValue={{ name_en: homeDistrict }}
          value={{ name_en: homeDistrict }}

          // id="combo-box-demo"
          // options={[{ name_en: "All", value: "" }, ...allHDistricts]}
          options={allHDistricts}
          getOptionLabel={(option) => option.name_en}
          onChange={(event, value) => {

            handleChangeHdist(event, value);
            // setCountry(value);
          }}
          // sx={{
          //   // width: "80vw",
          //   background:"transparent",
          //   borderRadius: "20px",
          //   // border: "none",
          //   // borderColor: "transparent",

          //   "& .MuiInputBase-input": {
          //     height: 15,
          //     // border: "none",
          //     fontSize: "14px",
          //     fontFamily: "'Jost', sans-serif",
          //     // borderColor: "transparent",
          //   },
          //   //view
          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          //     // borderColor: "transparent",
          //   },

          //   "&:hover .MuiOutlinedInput-input": {
          //     color: "black",
          //   },
          //   "&:hover .MuiInputLabel-root": {
          //     color: COLORS.GrayBackground,
          //   },
          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
          //   {
          //     // borderColor: "transparent",
          //   },
          //   ".MuiAutocomplete-root fieldset:hover": {
          //     color: "transparent",
          //     // borderColor: "transparent",
          //   },
          // }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input {...params.inputProps} widthClass="w-full" className="bg-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500" placeholder="Select State" />
            </div>
          )}
          ListboxProps={{
            style: {
              maxHeight: "150px",
              fontSize: "14px",
              // border: '1px solid white'
            },
          }}
        />
      </>

    );
  };

  const renderTabsSuburb = () => {
    return (

      <>
        {/* <Autocomplete
          className=""
          // className="form-group"
          // className="border-neutral-200"
          disablePortal
          // defaultValue={{ fields: { scc_name: homeCity } }}
          defaultValue={{ locality: homeCity }}
          // value={{ fields: { scc_name: homeCity } }}
          value={{ locality: homeCity }}
          // id="combo-box-demo"
          // options={[{ fields: { scc_name: "All", value: "" } }, ...allHCities]}
          options={[{ locality: "All" }, ...newSubs]}
          // getOptionLabel={(option) => option?.fields?.scc_name}
          getOptionLabel={(option) => option?.locality}
          onChange={(event, value) => {

            handleChangeHcity(event, value);
            // setCountry(value);
          }}
          // sx={{
          //   // width: "80vw",
          //   background:"transparent",
          //   borderRadius: "20px",
          //   // border: "none",
          //   // borderColor: "transparent",

          //   "& .MuiInputBase-input": {
          //     height: 15,
          //     // border: "none",
          //     fontSize: "14px",
          //     fontFamily: "'Jost', sans-serif",
          //     // borderColor: "transparent",
          //   },
          //   //view
          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          //     // borderColor: "transparent",
          //   },

          //   "&:hover .MuiOutlinedInput-input": {
          //     color: "black",
          //   },
          //   "&:hover .MuiInputLabel-root": {
          //     color: COLORS.GrayBackground,
          //   },
          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
          //   {
          //     // borderColor: "transparent",
          //   },
          //   ".MuiAutocomplete-root fieldset:hover": {
          //     color: "transparent",
          //     // borderColor: "transparent",
          //   },
          // }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input {...params.inputProps} widthClass="w-full" className="bg-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500" placeholder="Select Suburb" />
            </div>
          )}
          ListboxProps={{
            style: {
              maxHeight: "150px",
              fontSize: "14px",
              // border: '1px solid white'
            },
          }}
        /> */}

        <Autocomplete
          className=""
          // className="form-group"
          // className="border-neutral-200"
          // disablePortal
          // defaultValue={{ fields: { scc_name: homeCity } }}
          // value={{ fields: { scc_name: homeCity } }}
          // defaultValue={{ locality: homeCity }}
          // value={{ locality: homeCity }}
          value={homeCity}

          // id="combo-box-demo"
          // options={allHCities}
          options={newSubs2}
          // options={["All", ...newSubs2]}

          // getOptionLabel={(option) => option?.fields?.scc_name}
          getOptionLabel={(option) => option}
          onInputChange={(event, value) => {
            console.log(value)

            if (value) {

              let char = lowerCase(value)

              let arr = [...newSubs]

              console.log("<<<<", newSubs)

              // arr = arr.filter((data) => lowerCase(data.locality).includes(char));

              const det = arr.map((item, index) => {

                if (lowerCase(item.locality).startsWith(char)) {

                  return { ...item }
                }
              })


              console.log(">>>>>", _.compact(det))

              arr = _.compact(det)

              arr = _.map(arr, "locality")

              console.log("arr>>", arr)

              arr = _.uniq(arr);

              setnewSubs2(arr);

            } else {
              setnewSubs2([])
            }
          }}
          onChange={(event, value) => {

            handleChangeHcity(event, value);
            // setCountry(value);
          }}
          // sx={{
          //   // width: "80vw",
          //   background:"transparent",
          //   borderRadius: "20px",
          //   // border: "none",
          //   // borderColor: "transparent",

          //   "& .MuiInputBase-input": {
          //     height: 15,
          //     // border: "none",
          //     fontSize: "14px",
          //     fontFamily: "'Jost', sans-serif",
          //     // borderColor: "transparent",
          //   },
          //   //view
          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          //     // borderColor: "transparent",
          //   },

          //   "&:hover .MuiOutlinedInput-input": {
          //     color: "black",
          //   },
          //   "&:hover .MuiInputLabel-root": {
          //     color: COLORS.GrayBackground,
          //   },
          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
          //   {
          //     // borderColor: "transparent",
          //   },
          //   ".MuiAutocomplete-root fieldset:hover": {
          //     color: "transparent",
          //     // borderColor: "transparent",
          //   },
          // }}

          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input {...params.inputProps} widthClass="w-full" className="bg-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500" placeholder="Search Suburb" />
            </div>
          )}
          ListboxProps={{
            style: {
              maxHeight: "150px",
              fontSize: "14px",
              // border: '1px solid white'
            },
          }}
        />
      </>

    );
  };

  // OK
  const renderTabsCleanTypes = () => {
    return (
      // <Popover className="relative">
      //   {({ open, close }) => (
      //     <>
      //       <Popover.Button
      //         className={`flex items-center justify-center px-4 py-3 h-11 text-sm rounded-lg border focus:outline-none select-none
      //         ${open ? "!border-primary-500 " : ""}
      //           ${!!selectedCat
      //             ? "!border-primary-500 bg-primary-50 text-primary-900"
      //             : "bg-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
      //           }
      //           `}
      //       >
      //         <svg
      //           className="w-4 h-4"
      //           viewBox="0 0 24 24"
      //           fill="none"
      //           xmlns="http://www.w3.org/2000/svg"
      //         >
      //           <path
      //             d="M21 9V3H15"
      //             stroke="currentColor"
      //             strokeWidth="1.5"
      //             strokeLinecap="round"
      //             strokeLinejoin="round"
      //           />
      //           <path
      //             d="M3 15V21H9"
      //             stroke="currentColor"
      //             strokeWidth="1.5"
      //             strokeLinecap="round"
      //             strokeLinejoin="round"
      //           />
      //           <path
      //             d="M21 3L13.5 10.5"
      //             stroke="currentColor"
      //             strokeWidth="1.5"
      //             strokeLinecap="round"
      //             strokeLinejoin="round"
      //           />
      //           <path
      //             d="M10.5 13.5L3 21"
      //             stroke="currentColor"
      //             strokeWidth="1.5"
      //             strokeLinecap="round"
      //             strokeLinejoin="round"
      //           />
      //         </svg>

      //         <span className="ml-2 whitespace-nowrap">Cleaning Type</span>
      //         {!selectedCat ? (
      //           <ChevronDownIcon className="w-4 h-4 ml-3" />
      //         ) : (
      //           <span onClick={() =>
      //             // setSizesState([])
      //             setSelectedcat("")
      //           }>{renderXClear()}</span>
      //         )}
      //       </Popover.Button>
      //       <Transition
      //         as={Fragment}
      //         enter="transition ease-out duration-200"
      //         enterFrom="opacity-0 translate-y-1"
      //         enterTo="opacity-100 translate-y-0"
      //         leave="transition ease-in duration-150"
      //         leaveFrom="opacity-100 translate-y-0"
      //         leaveTo="opacity-0 translate-y-1"
      //       >
      //         <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
      //           <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
      //             <div className="relative flex flex-col px-5 py-6 space-y-5">

      //               <Checkbox
      //                 sizeClassName="w-5 h-5"
      //                 labelClassName="text-xs font-normal"
      //                 name={""}
      //                 label={"All"}
      //                 checked={selectedCat === ""}
      //                 onChange={(checked) => {
      //                   setSelectedcat("");

      //                   // window.scrollTo({
      //                   //   top: 0,
      //                   //   behavior: "smooth"
      //                   // });
      //                 }}
      //               // defaultChecked={colorsState.includes(item.name)}
      //               // onChange={(checked) => handleChangeColors(checked, item.name)}
      //               />

      //               {categories.map((item: any) => (
      //                 <div key={item} className="">
      //                   <Checkbox
      //                     name={item.name}
      //                     label={item.name}
      //                     checked={selectedCat === item.id}
      //                     // defaultChecked={sizesState.includes(item.name)}
      //                     onChange={(checked) => {
      //                       setSelectedcat(item.id);

      //                       // window.scrollTo({
      //                       //   top: 0,
      //                       //   behavior: "smooth"
      //                       // });
      //                     }}
      //                     // onChange={(checked) => handleChangeSizes(checked, item.name)}
      //                     sizeClassName="w-5 h-5"
      //                     labelClassName="text-xs font-normal"
      //                   />
      //                 </div>
      //               ))}
      //               {/* {DATA_sizes.map((item) => (
      //                 <div key={item.name} className="">
      //                   <Checkbox
      //                     name={item.name}
      //                     label={item.name}
      //                     defaultChecked={sizesState.includes(item.name)}
      //                     onChange={(checked) =>
      //                       handleChangeSizes(checked, item.name)
      //                     }
      //                   />
      //                 </div>
      //               ))} */}
      //             </div>
      //             {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
      //           <ButtonThird
      //             onClick={() => {
      //               close();
      //               setSizesState([]);
      //             }}
      //             sizeClass="px-4 py-2 sm:px-5"
      //           >
      //             Clear
      //           </ButtonThird>
      //           <ButtonPrimary
      //             onClick={close}
      //             sizeClass="px-4 py-2 sm:px-5"
      //           >
      //             Apply
      //           </ButtonPrimary>
      //         </div> */}
      //           </div>
      //         </Popover.Panel>
      //       </Transition>
      //     </>
      //   )
      //   }
      // </Popover >
      <Autocomplete
        className=""
        // className="form-group"
        // className="border-neutral-200"
        disablePortal
        // defaultValue={{ name: homeDistrict }}
        value={{ name: categories.find((v: any) => v.id === selectedCat) ? categories.find((v: any) => v.id === selectedCat).name : "" }}

        // id="combo-box-demo"
        options={[{ name: "All", id: "" }, ...categories]}
        getOptionLabel={(option) => option.name}
        onChange={(event, value) => {

          if (value !== null) {
            setSelectedcat(value.id);
          } else {

            setSelectedcat("")
          }

          // handleChangeHdist(event, value);
          // setCountry(value);
        }}
        // sx={{
        //   // width: "80vw",
        //   background:"transparent",
        //   borderRadius: "20px",
        //   // border: "none",
        //   // borderColor: "transparent",

        //   "& .MuiInputBase-input": {
        //     height: 15,
        //     // border: "none",
        //     fontSize: "14px",
        //     fontFamily: "'Jost', sans-serif",
        //     // borderColor: "transparent",
        //   },
        //   //view
        //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        //     // borderColor: "transparent",
        //   },

        //   "&:hover .MuiOutlinedInput-input": {
        //     color: "black",
        //   },
        //   "&:hover .MuiInputLabel-root": {
        //     color: COLORS.GrayBackground,
        //   },
        //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
        //   {
        //     // borderColor: "transparent",
        //   },
        //   ".MuiAutocomplete-root fieldset:hover": {
        //     color: "transparent",
        //     // borderColor: "transparent",
        //   },
        // }}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <Input {...params.inputProps} widthClass="w-full" className="bg-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500" placeholder="Provider Type" />
          </div>
        )}
        ListboxProps={{
          style: {
            maxHeight: "150px",
            fontSize: "14px",
            // border: '1px solid white'
          },
        }}
      />
    );
  };

  const renderTabsJobTypes = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center  w-full w-[150px] h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${selectedPro?.length != 0 ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "bg-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2 whitespace-nowrap">Job Type</span>
              {selectedPro?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() =>
                  // setSizesState([])
                  setselectedPro([])
                }>{renderXClear()}</span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">

                    <Checkbox
                      name={""}
                      label={"All"}

                      checked={selectedPro?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedPro([]);
                        setselectedColour("");
                        setselectedSize("");
                        setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("")
                      }}

                    />
                    {JOBTYPES.map((item: any) => (
                      <div key={item.id} className="">
                        <Checkbox
                          name={item.value}
                          label={item.name}
                          checked={selectedPro?.includes(item.value)}
                          // defaultChecked={categoriesState.includes(item.mainId)}
                          sizeClassName="w-5 h-5"
                          labelClassName="text-sm font-normal"
                          onChange={(checked) => {
                            // if (selectedPro?.includes(item.value)) {
                            //   const index = selectedPro.indexOf(item.value);

                            //   console.log(">>>>>>index", index)

                            //   var arr = [...selectedPro]
                            //   if (index > -1) {
                            //     arr.splice(index, 1);
                            //     console.log("arr>>>>", arr)
                            //     setselectedPro(arr)
                            //   }

                            // } else {
                            //   setselectedPro(((data: any) => [...data, item.value]))
                            // }

                            setselectedPro([item.value]);
                            // setSelectedcat(item.value);

                            setselectedColour("");
                            setselectedSize("");
                            setCurrentPage(1);
                            // removeLocal();
                            setmainDesc(item);
                            setsearch("")
                          }}
                        // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                        />

                      </div>
                    ))}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )
        }
      </Popover >
    );
  };

  const renderTabsGender = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center  w-full w-[150px] h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${selectedPro?.length != 0 ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "bg-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2 whitespace-nowrap">Gender</span>
              {selectedPro?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() =>
                  // setSizesState([])
                  setselectedPro([])
                }>{renderXClear()}</span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">

                    <Checkbox
                      name={""}
                      label={"All"}

                      checked={selectedPro?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedPro([]);
                        setselectedColour("");
                        setselectedSize("");
                        setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("")
                      }}

                    />
                    {["Male", "Female"].map((item: any) => (
                      <div key={item} className="">
                        <Checkbox
                          name={item}
                          label={item}
                          checked={selectedPro?.includes(item)}
                          // defaultChecked={categoriesState.includes(item.mainId)}
                          sizeClassName="w-5 h-5"
                          labelClassName="text-sm font-normal"
                          onChange={(checked) => {
                            // if (selectedPro?.includes(item.value)) {
                            //   const index = selectedPro.indexOf(item.value);

                            //   console.log(">>>>>>index", index)

                            //   var arr = [...selectedPro]
                            //   if (index > -1) {
                            //     arr.splice(index, 1);
                            //     console.log("arr>>>>", arr)
                            //     setselectedPro(arr)
                            //   }

                            // } else {
                            //   setselectedPro(((data: any) => [...data, item.value]))
                            // }

                            setselectedPro([item]);
                            // setSelectedcat(item.value);

                            setselectedColour("");
                            setselectedSize("");
                            setCurrentPage(1);
                            // removeLocal();
                            setmainDesc(item);
                            setsearch("")
                          }}
                        // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                        />

                      </div>
                    ))}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )
        }
      </Popover >
    );
  };

  const renderTabsAge = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center  w-full w-[150px] h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${selectedPro?.length != 0 ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "bg-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2 whitespace-nowrap">Age</span>
              {selectedPro?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() =>
                  // setSizesState([])
                  setselectedPro([])
                }>{renderXClear()}</span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm ">
                <div className=" max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">

                    <Checkbox
                      name={""}
                      label={"All"}

                      checked={selectedPro?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedPro([]);
                        setselectedColour("");
                        setselectedSize("");
                        setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("")
                      }}

                    />
                    {AGEarr.map((item: any) => (
                      <div key={item} className="">
                        <Checkbox
                          name={item}
                          label={item}
                          checked={selectedPro?.includes(item)}
                          // defaultChecked={categoriesState.includes(item.mainId)}
                          sizeClassName="w-5 h-5"
                          labelClassName="text-sm font-normal"
                          onChange={(checked) => {
                            // if (selectedPro?.includes(item.value)) {
                            //   const index = selectedPro.indexOf(item.value);

                            //   console.log(">>>>>>index", index)

                            //   var arr = [...selectedPro]
                            //   if (index > -1) {
                            //     arr.splice(index, 1);
                            //     console.log("arr>>>>", arr)
                            //     setselectedPro(arr)
                            //   }

                            // } else {
                            //   setselectedPro(((data: any) => [...data, item.value]))
                            // }

                            setselectedPro([item]);
                            // setSelectedcat(item.value);

                            setselectedColour("");
                            setselectedSize("");
                            setCurrentPage(1);
                            // removeLocal();
                            setmainDesc(item);
                            setsearch("")
                          }}
                        // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                        />

                      </div>
                    ))}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )
        }
      </Popover >
    );
  };

  const renderTabsCast = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center  w-full w-[150px] h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${selectedPro?.length != 0 ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "bg-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2 whitespace-nowrap">Cast</span>
              {selectedPro?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() =>
                  // setSizesState([])
                  setselectedPro([])
                }>{renderXClear()}</span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">

                    <Checkbox
                      name={""}
                      label={"All"}

                      checked={selectedPro?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedPro([]);
                        setselectedColour("");
                        setselectedSize("");
                        setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("")
                      }}

                    />
                    {CASTarr.map((item: any) => (
                      <div key={item} className="">
                        <Checkbox
                          name={item}
                          label={item}
                          checked={selectedPro?.includes(item)}
                          // defaultChecked={categoriesState.includes(item.mainId)}
                          sizeClassName="w-5 h-5"
                          labelClassName="text-sm font-normal"
                          onChange={(checked) => {
                            // if (selectedPro?.includes(item.value)) {
                            //   const index = selectedPro.indexOf(item.value);

                            //   console.log(">>>>>>index", index)

                            //   var arr = [...selectedPro]
                            //   if (index > -1) {
                            //     arr.splice(index, 1);
                            //     console.log("arr>>>>", arr)
                            //     setselectedPro(arr)
                            //   }

                            // } else {
                            //   setselectedPro(((data: any) => [...data, item.value]))
                            // }

                            setselectedPro([item]);
                            // setSelectedcat(item.value);

                            setselectedColour("");
                            setselectedSize("");
                            setCurrentPage(1);
                            // removeLocal();
                            setmainDesc(item);
                            setsearch("")
                          }}
                        // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                        />

                      </div>
                    ))}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )
        }
      </Popover >
    );
  };

  const renderTabsRace = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center  w-full w-[150px] h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${selectedPro?.length != 0 ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "bg-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2 whitespace-nowrap">Race</span>
              {selectedPro?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() =>
                  // setSizesState([])
                  setselectedPro([])
                }>{renderXClear()}</span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">

                    <Checkbox
                      name={""}
                      label={"All"}

                      checked={selectedPro?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedPro([]);
                        setselectedColour("");
                        setselectedSize("");
                        setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("")
                      }}

                    />
                    {RACEarr.map((item: any) => (
                      <div key={item} className="">
                        <Checkbox
                          name={item}
                          label={item}
                          checked={selectedPro?.includes(item)}
                          // defaultChecked={categoriesState.includes(item.mainId)}
                          sizeClassName="w-5 h-5"
                          labelClassName="text-sm font-normal"
                          onChange={(checked) => {
                            // if (selectedPro?.includes(item.value)) {
                            //   const index = selectedPro.indexOf(item.value);

                            //   console.log(">>>>>>index", index)

                            //   var arr = [...selectedPro]
                            //   if (index > -1) {
                            //     arr.splice(index, 1);
                            //     console.log("arr>>>>", arr)
                            //     setselectedPro(arr)
                            //   }

                            // } else {
                            //   setselectedPro(((data: any) => [...data, item.value]))
                            // }

                            setselectedPro([item]);
                            // setSelectedcat(item.value);

                            setselectedColour("");
                            setselectedSize("");
                            setCurrentPage(1);
                            // removeLocal();
                            setmainDesc(item);
                            setsearch("")
                          }}
                        // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                        />

                      </div>
                    ))}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )
        }
      </Popover >
    );
  };


  const renderTabsReligion = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center  w-full w-[150px] h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${selectedPro?.length != 0 ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "bg-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2 whitespace-nowrap">Religion</span>
              {selectedPro?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() =>
                  // setSizesState([])
                  setselectedPro([])
                }>{renderXClear()}</span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">

                    <Checkbox
                      name={""}
                      label={"All"}

                      checked={selectedPro?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedPro([]);
                        setselectedColour("");
                        setselectedSize("");
                        setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("")
                      }}

                    />
                    {RELIGIONarr.map((item: any) => (
                      <div key={item} className="">
                        <Checkbox
                          name={item}
                          label={item}
                          checked={selectedPro?.includes(item)}
                          // defaultChecked={categoriesState.includes(item.mainId)}
                          sizeClassName="w-5 h-5"
                          labelClassName="text-sm font-normal"
                          onChange={(checked) => {
                            // if (selectedPro?.includes(item.value)) {
                            //   const index = selectedPro.indexOf(item.value);

                            //   console.log(">>>>>>index", index)

                            //   var arr = [...selectedPro]
                            //   if (index > -1) {
                            //     arr.splice(index, 1);
                            //     console.log("arr>>>>", arr)
                            //     setselectedPro(arr)
                            //   }

                            // } else {
                            //   setselectedPro(((data: any) => [...data, item.value]))
                            // }

                            setselectedPro([item]);
                            // setSelectedcat(item.value);

                            setselectedColour("");
                            setselectedSize("");
                            setCurrentPage(1);
                            // removeLocal();
                            setmainDesc(item);
                            setsearch("")
                          }}
                        // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                        />

                      </div>
                    ))}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )
        }
      </Popover >
    );
  };

  const renderTabsHorroscope = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center  w-full w-[150px] h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${selectedPro?.length != 0 ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "bg-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2 whitespace-nowrap">Horroscope</span>
              {selectedPro?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() =>
                  // setSizesState([])
                  setselectedPro([])
                }>{renderXClear()}</span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">

                    <Checkbox
                      name={""}
                      label={"All"}

                      checked={selectedPro?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedPro([]);
                        setselectedColour("");
                        setselectedSize("");
                        setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("")
                      }}

                    />
                    {["Kuja 7", "Shani 7", "Other"].map((item: any) => (
                      <div key={item} className="">
                        <Checkbox
                          name={item}
                          label={item}
                          checked={selectedPro?.includes(item)}
                          // defaultChecked={categoriesState.includes(item.mainId)}
                          sizeClassName="w-5 h-5"
                          labelClassName="text-sm font-normal"
                          onChange={(checked) => {
                            // if (selectedPro?.includes(item.value)) {
                            //   const index = selectedPro.indexOf(item.value);

                            //   console.log(">>>>>>index", index)

                            //   var arr = [...selectedPro]
                            //   if (index > -1) {
                            //     arr.splice(index, 1);
                            //     console.log("arr>>>>", arr)
                            //     setselectedPro(arr)
                            //   }

                            // } else {
                            //   setselectedPro(((data: any) => [...data, item.value]))
                            // }

                            setselectedPro([item]);
                            // setSelectedcat(item.value);

                            setselectedColour("");
                            setselectedSize("");
                            setCurrentPage(1);
                            // removeLocal();
                            setmainDesc(item);
                            setsearch("")
                          }}
                        // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                        />

                      </div>
                    ))}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )
        }
      </Popover >
    );
  };

  const renderTabsMarriage = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center  w-full w-[150px] h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${selectedPro?.length != 0 ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "bg-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2 whitespace-nowrap">Marriage</span>
              {selectedPro?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() =>
                  // setSizesState([])
                  setselectedPro([])
                }>{renderXClear()}</span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">

                    <Checkbox
                      name={""}
                      label={"All"}

                      checked={selectedPro?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedPro([]);
                        setselectedColour("");
                        setselectedSize("");
                        setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("")
                      }}

                    />
                    {MARarr.map((item: any) => (
                      <div key={item} className="">
                        <Checkbox
                          name={item}
                          label={item}
                          checked={selectedPro?.includes(item)}
                          // defaultChecked={categoriesState.includes(item.mainId)}
                          sizeClassName="w-5 h-5"
                          labelClassName="text-sm font-normal"
                          onChange={(checked) => {
                            // if (selectedPro?.includes(item.value)) {
                            //   const index = selectedPro.indexOf(item.value);

                            //   console.log(">>>>>>index", index)

                            //   var arr = [...selectedPro]
                            //   if (index > -1) {
                            //     arr.splice(index, 1);
                            //     console.log("arr>>>>", arr)
                            //     setselectedPro(arr)
                            //   }

                            // } else {
                            //   setselectedPro(((data: any) => [...data, item.value]))
                            // }

                            setselectedPro([item]);
                            // setSelectedcat(item.value);

                            setselectedColour("");
                            setselectedSize("");
                            setCurrentPage(1);
                            // removeLocal();
                            setmainDesc(item);
                            setsearch("")
                          }}
                        // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                        />

                      </div>
                    ))}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )
        }
      </Popover >
    );
  };

  const renderTabsOccupation = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center  w-full w-[150px] h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${selectedPro?.length != 0 ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "bg-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2 whitespace-nowrap">Occupation</span>
              {selectedPro?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() =>
                  // setSizesState([])
                  setselectedPro([])
                }>{renderXClear()}</span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">

                    <Checkbox
                      name={""}
                      label={"All"}

                      checked={selectedPro?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedPro([]);
                        setselectedColour("");
                        setselectedSize("");
                        setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("")
                      }}

                    />
                    {OCUarr.map((item: any) => (
                      <div key={item} className="">
                        <Checkbox
                          name={item}
                          label={item}
                          checked={selectedPro?.includes(item)}
                          // defaultChecked={categoriesState.includes(item.mainId)}
                          sizeClassName="w-5 h-5"
                          labelClassName="text-sm font-normal"
                          onChange={(checked) => {
                            // if (selectedPro?.includes(item.value)) {
                            //   const index = selectedPro.indexOf(item.value);

                            //   console.log(">>>>>>index", index)

                            //   var arr = [...selectedPro]
                            //   if (index > -1) {
                            //     arr.splice(index, 1);
                            //     console.log("arr>>>>", arr)
                            //     setselectedPro(arr)
                            //   }

                            // } else {
                            //   setselectedPro(((data: any) => [...data, item.value]))
                            // }

                            setselectedPro([item]);
                            // setSelectedcat(item.value);

                            setselectedColour("");
                            setselectedSize("");
                            setCurrentPage(1);
                            // removeLocal();
                            setmainDesc(item);
                            setsearch("")
                          }}
                        // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                        />

                      </div>
                    ))}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )
        }
      </Popover >
    );
  };

  const renderTabsSellerTypes = () => {
    return (
      <Popover className="relative mt-4">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center  w-full w-[150px] h-11 text-sm rounded-lg border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${selectedPro?.length != 0 ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "bg-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2 whitespace-nowrap">Seller Type</span>
              {selectedPro?.length === 0 ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() =>
                  // setSizesState([])
                  setselectedPro([1, 2])
                }>{renderXClear()}</span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">

                    <Checkbox
                      name={""}
                      label={"All"}

                      checked={selectedPro?.includes(1) && selectedPro?.includes(2)}
                      // checked={selectedPro?.length === 0}
                      sizeClassName="w-5 h-5"
                      labelClassName="text-sm font-normal"
                      onChange={(checked) => {
                        setselectedPro([1, 2]);
                        setselectedColour("");
                        setselectedSize("");
                        setCurrentPage(1);
                        // removeLocal();
                        setmainDesc("");
                        setsearch("")
                      }}

                    />
                    {SELLER_TYPES.map((item: any) => (
                      <div key={item.id} className="">
                        <Checkbox
                          name={item.value}
                          label={item.name}
                          checked={selectedPro?.includes(item.id) && selectedPro.length === 1}
                          // defaultChecked={categoriesState.includes(item.mainId)}
                          sizeClassName="w-5 h-5"
                          labelClassName="text-sm font-normal"
                          onChange={(checked) => {
                            // if (selectedPro?.includes(item.value)) {
                            //   const index = selectedPro.indexOf(item.value);

                            //   console.log(">>>>>>index", index)

                            //   var arr = [...selectedPro]
                            //   if (index > -1) {
                            //     arr.splice(index, 1);
                            //     console.log("arr>>>>", arr)
                            //     setselectedPro(arr)
                            //   }

                            // } else {
                            //   setselectedPro(((data: any) => [...data, item.value]))
                            // }

                            setselectedPro([item.id]);
                            // setSelectedcat(item.value);

                            setselectedColour("");
                            setselectedSize("");
                            setCurrentPage(1);
                            // removeLocal();
                            setmainDesc(item);
                            setsearch("")
                          }}
                        // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                        />

                      </div>
                    ))}
                    {/* {DATA_sizes.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={sizesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeSizes(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* <div className="p-5 bg-slate-50 dark:bg-[#002930] dark:border-t dark:border-slate-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    close();
                    setSizesState([]);
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Clear
                </ButtonThird>
                <ButtonPrimary
                  onClick={close}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Apply
                </ButtonPrimary>
              </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )
        }
      </Popover >
    );
  };

  // OK
  const renderTabsPriceRage = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center  w-full w-[150px] h-11 text-sm rounded-lg border bg-[#F4F4F4] text-primary-900 focus:outline-none `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.67188 14.3298C8.67188 15.6198 9.66188 16.6598 10.8919 16.6598H13.4019C14.4719 16.6598 15.3419 15.7498 15.3419 14.6298C15.3419 13.4098 14.8119 12.9798 14.0219 12.6998L9.99187 11.2998C9.20187 11.0198 8.67188 10.5898 8.67188 9.36984C8.67188 8.24984 9.54187 7.33984 10.6119 7.33984H13.1219C14.3519 7.33984 15.3419 8.37984 15.3419 9.66984"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 6V18"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2 min-w-[90px]">{`${rangePrices[0]}$ - ${rangePrices[1]}$`}</span>
              {rangePrices[0] === PRICE_RANGE[0] &&
                rangePrices[1] === PRICE_RANGE[1] ? null : (
                <span onClick={() => setRangePrices(PRICE_RANGE)}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">Hourly Rate</span>
                      <Slider
                        range
                        // min={PRICE_RANGE[0]}
                        // max={PRICE_RANGE[1]}
                        min={0}
                        max={999}
                        step={1}
                        defaultValue={[0, 999]}
                        // defaultValue={[rangePrices[0], rangePrices[1]]}
                        allowCross={false}
                        onAfterChange={(_input: any | number | number[]) => {

                          // setRangePrices(_input as number[]);
                          setminPrice(_input[0]);
                          setmaxPrice(_input[1]);

                        }

                        }
                        onChange={(_input: any | number | number[]) => {
                          setRangePrices(_input as number[]);
                          // setminPrice(_input[0]);
                          // setmaxPrice(_input[1]);
                        }
                        }
                      // onChange={(_input: number | number[]) =>
                      //   setRangePrices(_input as number[])
                      // }
                      />
                    </div>

                    <div className="flex justify-between space-x-5">
                      <div>
                        <label
                          htmlFor="minPrice"
                          className="block text-xs font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Min price
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <span className="absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-sm">
                            $
                          </span>
                          <input
                            type="text"
                            name="minPrice"
                            disabled
                            id="minPrice"
                            className="block w-32 pr-10 pl-4 sm:text-xs border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                            value={rangePrices[0]}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="maxPrice"
                          className="block text-xs font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Max price
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <span className="absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-sm">
                            $
                          </span>
                          <input
                            type="text"
                            disabled
                            name="maxPrice"
                            id="maxPrice"
                            className="block w-32 pr-10 pl-4 sm:text-xs border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                            value={rangePrices[1]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        setRangePrices(PRICE_RANGE);
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  // OK
  const renderTabIsOnsale = () => {
    return (
      <div
        className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer select-none ${isOnSale
          ? "border-primary-500 bg-primary-50 text-primary-900"
          : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
          }`}
        onClick={() => setIsIsOnSale(!isOnSale)}
      >
        <svg
          className="w-4 h-4"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.9889 14.6604L2.46891 13.1404C1.84891 12.5204 1.84891 11.5004 2.46891 10.8804L3.9889 9.36039C4.2489 9.10039 4.4589 8.59038 4.4589 8.23038V6.08036C4.4589 5.20036 5.1789 4.48038 6.0589 4.48038H8.2089C8.5689 4.48038 9.0789 4.27041 9.3389 4.01041L10.8589 2.49039C11.4789 1.87039 12.4989 1.87039 13.1189 2.49039L14.6389 4.01041C14.8989 4.27041 15.4089 4.48038 15.7689 4.48038H17.9189C18.7989 4.48038 19.5189 5.20036 19.5189 6.08036V8.23038C19.5189 8.59038 19.7289 9.10039 19.9889 9.36039L21.5089 10.8804C22.1289 11.5004 22.1289 12.5204 21.5089 13.1404L19.9889 14.6604C19.7289 14.9204 19.5189 15.4304 19.5189 15.7904V17.9403C19.5189 18.8203 18.7989 19.5404 17.9189 19.5404H15.7689C15.4089 19.5404 14.8989 19.7504 14.6389 20.0104L13.1189 21.5304C12.4989 22.1504 11.4789 22.1504 10.8589 21.5304L9.3389 20.0104C9.0789 19.7504 8.5689 19.5404 8.2089 19.5404H6.0589C5.1789 19.5404 4.4589 18.8203 4.4589 17.9403V15.7904C4.4589 15.4204 4.2489 14.9104 3.9889 14.6604Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 15L15 9"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.4945 14.5H14.5035"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.49451 9.5H9.50349"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <span className="line-clamp-1 ml-2">On sale</span>
        {isOnSale && renderXClear()}
      </div>
    );
  };

  // OK
  const renderMoreFilterItem = (
    data: {
      name: string;
      description?: string;
      defaultChecked?: boolean;
    }[]
  ) => {
    const list1 = data.filter((_, i) => i < data.length / 2);
    const list2 = data.filter((_, i) => i >= data.length / 2);
    return (
      <div className="grid grid-cols-2 gap-x-4 sm:gap-x-8 gap-8">
        <div className="flex flex-col space-y-5">
          {list1.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.description}
              label={item.name}
              defaultChecked={!!item.defaultChecked}
              sizeClassName="w-5 h-5 sm:w-6 sm:h-6"
            />
          ))}
        </div>
        <div className="flex flex-col space-y-5">
          {list2.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.description}
              label={item.name}
              defaultChecked={!!item.defaultChecked}
              sizeClassName="w-5 h-5 sm:w-6 sm:h-6"
            />
          ))}
        </div>
      </div>
    );
  };

  // FOR RESPONSIVE MOBILE
  const renderTabMobileFilter = () => {
    return (
      <div className="flex-shrink-0">
        <div
          className={`flex flex-shrink-0 items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-900 focus:outline-none cursor-pointer select-none`}
          onClick={openModalMoreFilter}
        >
          <svg
            className="w-4 h-4"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22 6.5H16"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 6.5H2"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 10C11.933 10 13.5 8.433 13.5 6.5C13.5 4.567 11.933 3 10 3C8.067 3 6.5 4.567 6.5 6.5C6.5 8.433 8.067 10 10 10Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22 17.5H18"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 17.5H2"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 21C15.933 21 17.5 19.433 17.5 17.5C17.5 15.567 15.933 14 14 14C12.067 14 10.5 15.567 10.5 17.5C10.5 19.433 12.067 21 14 21Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span className="ml-2">Products filters (3)</span>
          {renderXClear()}
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full text-left align-middle transition-all transform bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-medium leading-6 text-gray-900"
                    >
                      Products filters
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-6 sm:px-8 md:px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Categories</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(DATA_categories)}
                        </div>
                      </div>
                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Colors</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(DATA_colors)}
                        </div>
                      </div>
                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Size</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(DATA_sizes)}
                        </div>
                      </div>

                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Range Prices</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-8">
                            <div className="space-y-5">
                              <Slider
                                range
                                className="text-red-400"
                                min={PRICE_RANGE[0]}
                                max={PRICE_RANGE[1]}
                                defaultValue={rangePrices}
                                allowCross={false}
                                onChange={(_input: number | number[]) =>
                                  setRangePrices(_input as number[])
                                }
                              />
                            </div>

                            <div className="flex justify-between space-x-5">
                              <div>
                                <label
                                  htmlFor="minPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Min price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name="minPrice"
                                    disabled
                                    id="minPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={rangePrices[0]}
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="maxPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Max price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    disabled
                                    name="maxPrice"
                                    id="maxPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={rangePrices[1]}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Sort Order</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-5">
                            {DATA_sortOrderRadios.map((item) => (
                              <Radio
                                id={item.id}
                                key={item.id}
                                name="radioNameSort"
                                label={item.name}
                                defaultChecked={sortOrderStates === item.id}
                                onChange={setSortOrderStates}
                              />
                            ))}
                          </div>
                        </div>
                      </div>

                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">On sale!</h3>
                        <div className="mt-6 relative ">
                          <MySwitch
                            label="On sale!"
                            desc="Products currently on sale"
                            enabled={isOnSale}
                            onChange={setIsIsOnSale}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        setRangePrices(PRICE_RANGE);
                        setCategoriesState([]);
                        setColorsState([]);
                        setSortOrderStates("");
                        closeModalMoreFilter();
                      }}
                      sizeClass="py-2.5 px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={closeModalMoreFilter}
                      sizeClass="py-2.5 px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div className="flex flex-col space-y-3 mb-6 w-full">
      {/* FOR DESKTOP */}
      <div className="flex flex-col xl:flex-row xl:space-x-4 space-y-4 xl:space-y-0 xl:justify-between w-full">
        <div className="grid grid-cols-2 gap-x-2 xl:flex xl:flex-row xl:space-x-4 space-y-4 xl:space-y-0">
          {/* {renderTabsPriceRage()} */}
          {/* {renderTabsCategories()} */}
          <Input
            type="text"
            bgClass="bg-[#F4F4F4]"
            placeholder="Search Name"
            className="flex justify-center items-center xl:max-w-xs mt-4 lg:mt-0 col-span-2"
            onChange={(e: any) => {
              setsearch(e.target.value);

              // seterrfirstName(false);
            }}
            value={search}
          />
          {/* {filterType === "job" && renderTabsPriceRage()} */}

          {filterType === "seller" && renderTabsSellerTypes()}

          {renderTabsState()}

          {filterType != "seller" &&

            renderTabsSuburb()

          }


          {filterType === "seller" &&

            <div className="hidden lg:flex">
              {renderTabsSuburb()}
            </div>
          }

          {
            // (filterType === "wholesaler" || filterType === "contract") &&
            (filterType === "provider") &&

            <div className="hidden lg:flex">
              {renderTabsCleanTypes()}
            </div>
          }


          {filterType === "job" &&

            renderTabsGender()

          }

          {filterType === "job" &&

            renderTabsAge()

          }



          {/* {filterType === "job" &&
            <div className="hidden lg:flex">
              {renderTabsJobTypes()}
            </div>
          } */}

        </div>
        {/* {renderTabIsOnsale()} */}
        <div className="grid grid-cols-2 gap-x-2 xl:flex">

          {/* {filterType === "job" &&
            <div className="lg:hidden">
              {renderTabsJobTypes()}
            </div>
          } */}

          {/* {
          // (filterType === "wholesaler" || filterType === "contract") &&
          (filterType === "provider") &&

            <div className="lg:hidden">
              {renderTabsCleanTypes()}
            </div>
          } */}

          {filterType === "seller" &&

            <div className=" lg:hidden">
              {renderTabsSuburb()}
            </div>
          }

          {filterType != "job" && filterType != "contract" && renderTabsSortOrder()}
          {filterType === "contract" && renderTabsSortOrderContract()}
          {filterType === "job" && renderTabsSortOrderPrice()}
        </div>
      </div>

      <div className="flex flex-col xl:flex-row xl:space-x-4 space-y-4 xl:space-y-0 xl:justify-between w-full">
        <div className="grid grid-cols-2 gap-x-2 lg:grid-cols-4 xl:flex xl:flex-row xl:space-x-4 space-y-4 xl:space-y-0">
          {/* {renderTabsPriceRage()} */}
          {/* {renderTabsCategories()} */}
         
          {/* {filterType === "job" && renderTabsPriceRage()} */}



          {filterType === "job" &&

            renderTabsCast()

          }

          {filterType === "job" &&

            renderTabsRace()

          }

          {filterType === "job" &&

            renderTabsReligion()

          }

          {filterType === "job" &&

            renderTabsHorroscope()

          }

          {filterType === "job" &&

            renderTabsMarriage()

          }

          {filterType === "job" &&

            renderTabsOccupation()

          }











          {/* {filterType === "job" &&
            <div className="hidden lg:flex">
              {renderTabsJobTypes()}
            </div>
          } */}

        </div>
        {/* {renderTabIsOnsale()} */}
       
      </div>

      {/* FOR RESPONSIVE MOBILE */}
      {/* <div className="flex overflow-x-auto lg:hidden space-x-4">
        {renderTabMobileFilter()}
      </div> */}
    </div >
  );
};

export default TabFilters;
