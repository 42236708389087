import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useHistory, useLocation } from "react-router-dom";
import { RegisterSeeker, RegisterUser, signUpVerification } from "Services/API/Post";
import { upperFirst, lowerCase } from "lodash";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import {
  GetRole,
  getProviderCategory,
  getProviderSubCategoryById,
  getSignUpVerification
} from "Services/API/Get";
import { addToast } from "shared/Toast";
import LoadingSpinner from "shared/LoadingSpinner";
import Select from "shared/Select/Select";
import { Autocomplete } from "@mui/material";
import BotToast from "shared/BotToast";
import Label from "components/Label/Label";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg
  }
];

const COUNTArr = [
  "Afghanistan",
  "Åland (Finland)",
  "Albania",
  "Algeria",
  "American Samoa (US)",
  "Andorra",
  "Angola",
  "Anguilla (BOT)",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Artsakh",
  "Aruba (Netherlands)",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda (BOT)",
  "Bhutan",
  "Bolivia",
  "Bonaire (Netherlands)",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands (BOT)",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands (BOT)",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island (Australia)",
  "Cocos (Keeling) Islands (Australia)",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao (Netherlands)",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "DR Congo",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (BOT)",
  "Faroe Islands (Denmark)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana (France)",
  "French Polynesia (France)",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar (BOT)",
  "Greece",
  "Greenland (Denmark)",
  "Grenada",
  "Guadeloupe (France)",
  "Guam (US)",
  "Guatemala",
  "Guernsey (Crown Dependency)",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man (Crown Dependency)",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jersey (Crown Dependency)",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique (France)",
  "Mauritania",
  "Mauritius",
  "Mayotte (France)",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat (BOT)",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia (France)",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island (Australia)",
  "North Korea",
  "North Macedonia",
  "Northern Cyprus",
  "Northern Mariana Islands (US)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn Islands (BOT)",
  "Poland",
  "Portugal",
  "Puerto Rico (US)",
  "Qatar",
  "Réunion (France)",
  "Romania",
  "Russia",
  "Rwanda",
  "Saba (Netherlands)",
  "Saint Barthélemy (France)",
  "Saint Helena, Ascension and Tristan da Cunha (BOT)",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (France)",
  "Saint Pierre and Miquelon (France)",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "São Tomé and Príncipe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Eustatius (Netherlands)",
  "Sint Maarten (Netherlands)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen (Norway)",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tokelau (NZ)",
  "Tonga",
  "Transnistria",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (BOT)",
  "Tuvalu",
  "U.S. Virgin Islands (US)",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Wallis and Futuna (France)",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [lopen, setlopen] = React.useState(false);

  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [country, setcountry] = useState("Sri Lanka");

  const [username, setusername] = useState("");
  const [lpass, setlpass] = useState("");
  const [aKey, setaKey] = useState("login");

  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);
  const [errPass, seterrPass] = useState(false);
  const [errUsername, seterrerrUsername] = useState("");
  const [errLpass, seterrerrLpass] = useState("");

  const [userType, setuserType] = useState<any>("");
  const [erruserType, seterruserType] = useState(false);

  const [roleId, setroleId] = useState<any>("");
  const [roles, setroles] = useState<any>([]);

  const [bOpen, setbOpen] = useState(false);
  const [bMsg, setbMsg] = useState("");
  const [bStatus, setbStatus] = useState("");

  const [category, setcategory] = useState("");
  const [errcategory, seterrcategory] = useState(false);

  const [allProCate, setallProCate] = useState<any>([]);
  const [allProSubCate, setallProSubCate] = useState<any>([]);

  const [selectedCat, setselectedCat] = useState<any | null>("");
  const [selectedSubCat, setselectedSubCat] = useState<any | null>("");

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  React.useEffect(() => {
    if (query.get("userType")) {
      if (query.get("userType") === "seeker") {
        setuserType(1);
      } else if (query.get("userType") === "provider") {
        setuserType(3);
      }
    }
  }, [query]);

  React.useEffect(() => {
    getVerification();
    AllRoles();
  }, []);

  useEffect(() => {
    if (bOpen === true) {
      setTimeout(() => {
        setbOpen(false);
      }, 10000);
    }
  }, [bOpen]);

  const listCategories = () => {
    getProviderCategory()
      .then((res) => {
        console.log("cities>>>", res);
        setallProCate(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setallProCate([]);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const listSubCategories = () => {
    getProviderSubCategoryById(selectedCat)
      .then((res) => {
        console.log("cities>>>", res);
        setallProSubCate(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setallProSubCate([]);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  React.useEffect(() => {
    listCategories();
  }, []);

  React.useEffect(() => {
    if (selectedCat) {
      listSubCategories();
    }
  }, [selectedCat]);

  const AllRoles = async (e?: any) => {
    GetRole()
      .then((res) => {
        setlopen(false);
        setroles(res.data);
      })
      .catch((error) => {
        setlopen(false);
        console.log("Error", error.response);
        setroles([]);

        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const getVerification = async (e?: any) => {
    let token = query.get("token");
    if (token !== null) {
      setaKey("register");
      setlopen(true);
      // setProgress(true);
      getSignUpVerification(token)
        .then((res) => {
          RegisterUser(JSON.parse(res.data[0].data))
            .then((res) => {
              setlopen(false);
              // addToast(`Registration Successful `, "success");

              setbOpen(true);
              setbMsg("Registration Successful");
              setbStatus("success");

              history.push("/login");
              setaKey("login");
              // setuserId(res.data[0]);
              // setOpenToast(true);
              // setColor(COLORS.Primary);
              // setAlertClass("success");
              // setMsg("Register Successful");
              // setTimeout(() => {
              //   setlopen(false);
              //   //  setOpen(true);
              //   history.push("/login-register");
              //   // window.location.reload();
              //   // setProgress(false);
              // }, 2000);

              // history.push("/");
              // window.location.reload();
            })
            .catch((error) => {
              setlopen(false);

              setbOpen(true);
              setbMsg(error.response.data.message);
              setbStatus("warning");
              // addToast(error.response.data.message, "warning");

              // console.log("Error", error.response);
              // setProgress(false);
              // setOpenToast(true);
              // setColor(COLORS.Orange);
              // setAlertClass("error");
              // setMsg(error.response.data.message);
            });
        })
        .catch((error) => {
          setlopen(false);
          console.log("Error", error.response);
          // addToast(error.response.data.message, "warning");
          setbOpen(true);
          setbMsg(error.response.data.message);
          setbStatus("warning");
          // setProgress(false);
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    }
  };

  const sendVerification = async (e: any) => {
    e.preventDefault();
    const phoneRegex =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    if (firstName === "") {
      seterrfirstName(true);
    }
    if (lastName === "") {
      seterrlasttName(true);
    }

    if (country === "Sri Lanka") {
      if (email === "") {
        seterremail(true);
      }
    }

    if (password === "") {
      seterrPass(true);
    }
    if (userType === "") {
      seterruserType(true);
    }
    // if (repassword === "") {
    //   seterrRePass("This field is required");
    // }
    // if (password != "" && repassword != "" && !(password === repassword)) {
    //   seterrRePass("Password Mismatch");
    // }
    // if (password != "" && repassword != "" && password === repassword) {
    //   seterrRePass("");
    // }

    let obj = {
      data: {
        firstName: upperFirst(lowerCase(firstName)),
        lastName: upperFirst(lowerCase(lastName)),
        mobileNumber: mobile,
        email: email,
        password: password,
        clientType: "individual",
        userType: userType,
        roleId: userType,
        registered: 1,
        category: selectedCat,
        language: t("langKey"),
        type: country === "Sri Lanka" ? "mobile" : "email"
      }
    };

    console.log("obj>>>", obj);

    if (country === "Sri Lanka") {
      if (!phoneRegex.test(mobile)) {
        if (mobile === "") {
          seterrmobile("This field is required");
        } else {
          seterrmobile("Please enter valid number");
        }
      }
    }

    if (
      // firstName !== "" &&
      // lastName !== "" &&
      email !== "" &&
      // mobile !== "" &&
      // (country != "Sri Lanka" ? email : true) &&
      // (country === "Sri Lanka" ? mobile : true) &&
      // (country === "Sri Lanka" ? phoneRegex.test(mobile) : true) &&
      password !== "" &&
      userType !== ""
      // repassword !== "" &&
      // password === repassword &&
      // phoneRegex.test(mobile)

      // phoneRegex.test(officeNo)
    ) {
      setlopen(true);
      // signUpVerification(obj)
      //   .then((res) => {
      //     setlopen(false);
      //     // addToast(`The account activation link has been sent to ${email}. Please click on the confirmation link to activate your account. `, "success");

      //     setbOpen(true);
      //     setbMsg(`The account activation link has been sent to the email. Please click on the confirmation link to activate your account.`);
      //     setbStatus("success");
      //     // setlopen(false);
      //     // setuserId(res.data[0]);
      //     // setOpenToast(true);
      //     // setColor(COLORS.Primary);
      //     // setAlertClass("success");
      //     // setMsg(
      //     //   `The account activation link has been sent to ${email}. Please click on the confirmation link to activate your account. `
      //     // );
      //     // setTimeout(() => {
      //     //   //  setOpen(true);
      //     //   history.push("/login");
      //     //   window.location.reload();
      //     // }, 2000);

      //     // history.push("/");
      //     // window.location.reload();
      //   })
      //   .catch((error) => {
      //     setlopen(false);
      //     console.log("Error", error.response);
      //     // addToast(error.response.data.message, "warning");

      //     setbOpen(true);
      //     setbMsg(error.response.data.message);
      //     setbStatus("warning");
      //     // setOpenToast(true);
      //     // setColor(COLORS.Orange);
      //     // setAlertClass("error");
      //     // setMsg(error.response.data.message);
      //   });
if(userType === 1)
{

  RegisterSeeker(obj.data)
  .then((res) => {
    setlopen(true);
       addToast(`Registration Successful Check your Emails for verification `, "success");

  })
  .catch((error) => {
    setlopen(false);

    setbOpen(true);
    setbMsg(error.response.data.message);
    setbStatus("warning");
    // addToast(error.response.data.message, "warning");

    // console.log("Error", error.response);
    // setProgress(false);
    // setOpenToast(true);
    // setColor(COLORS.Orange);
    // setAlertClass("error");
    // setMsg(error.response.data.message);
  });
} else {

  RegisterUser(obj.data)
  .then((res) => {
    setlopen(false);
    // addToast(`Registration Successful `, "success");

    // setbOpen(true);
    // setbMsg(`An email has been sent to the email`);
    // setbStatus("success");

    if (userType === 2 || userType === 3 || userType === 4) {
      // window.location.href = "https://wattl.sssd.store/";
      window.open(
        "https://wattl.sssd.store/",
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      history.push("/login");
      setaKey("login");
    }

    // setuserId(res.data[0]);
    // setOpenToast(true);
    // setColor(COLORS.Primary);
    // setAlertClass("success");
    // setMsg("Register Successful");
    // setTimeout(() => {
    //   setlopen(false);
    //   //  setOpen(true);
    //   history.push("/login-register");
    //   // window.location.reload();
    //   // setProgress(false);
    // }, 2000);

    // history.push("/");
    // window.location.reload();
  })
  .catch((error) => {
    setlopen(false);

    setbOpen(true);
    setbMsg(error.response.data.message);
    setbStatus("warning");
    // addToast(error.response.data.message, "warning");

    // console.log("Error", error.response);
    // setProgress(false);
    // setOpenToast(true);
    // setColor(COLORS.Orange);
    // setAlertClass("error");
    // setMsg(error.response.data.message);
  });
}

    } else {
      // scrollElement();
    }
  };

  const signUp = async (e?: any) => {
    e.preventDefault();
    const phoneRegex =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    if (firstName === "") {
      seterrfirstName(true);
    }
    if (lastName === "") {
      seterrlasttName(true);
    }
    if (email === "") {
      seterremail(true);
    }

    if (mobile === "") {
      seterrmobile("This field is required");
    }
    if (password === "") {
      seterrPass(true);
    }
    if (userType === "") {
      seterruserType(true);
    }
    // if (repassword === "") {
    //   seterrRePass("This field is required");
    // }
    // if (password != "" && repassword != "" && !(password === repassword)) {
    //   seterrRePass("Password Mismatch");
    // }
    // if (password != "" && repassword != "" && password === repassword) {
    //   seterrRePass("");
    // }

    let obj = {
      data: {
        firstName: upperFirst(lowerCase(firstName)),
        lastName: upperFirst(lowerCase(lastName)),
        mobileNumber: mobile,
        email: email,
        password: password,
        clientType: "individual",
        userType: userType,
        category: selectedCat
      }
    };

    console.log("obj>>>", obj);
    if (!phoneRegex.test(mobile)) {
      if (mobile === "") {
        seterrmobile("This field is required");
      } else {
        seterrmobile("Please enter valid number");
      }
    }

    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      mobile !== "" &&
      password !== "" &&
      userType !== "" &&
      // repassword !== "" &&
      // password === repassword &&
      phoneRegex.test(mobile)

      // phoneRegex.test(officeNo)
    ) {
      RegisterUser(obj.data)
        .then((res) => {
          setlopen(false);
          addToast(`Registration Successful `, "success");
          history.push("/login");
          setaKey("login");
          // setuserId(res.data[0]);
          // setOpenToast(true);
          // setColor(COLORS.Primary);
          // setAlertClass("success");
          // setMsg("Register Successful");
          // setTimeout(() => {
          //   setlopen(false);
          //   //  setOpen(true);
          //   history.push("/login-register");
          //   // window.location.reload();
          //   // setProgress(false);
          // }, 2000);

          // history.push("/");
          // window.location.reload();
        })
        .catch((error) => {
          setlopen(false);
          addToast(error.response.data.message, "warning");

          // console.log("Error", error.response);
          // setProgress(false);
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    }
  };

  return (
    <div
      className={`nc-PageSignUp  ${className} flex w-full h-full`}
      data-nc-id="PageSignUp"
    >
      <Helmet>
        <title>Sign up || Skillnet</title>
      </Helmet>
      <LoadingSpinner open={lopen} />
      <div className="w-full flex justify-center items-center">
        <div className="w-full px-3 sm:px-0 md:w-6/12 lg:w-4/12 xl:w-[29%]">
          <div className=" mb-24 lg:mb-32 bg-[#F4F4F4] mt-14 rounded-lg">
            <div className="py-10">
              <h2 className="mb-10 flex items-center text-3xl leading-[115%] md:text-3xl md:leading-[115%] font-bold text-[#003d35] dark:text-neutral-100 justify-center">
                {/* Signup */}
                {t("signUp")}
              </h2>
              <div className="px-10 sm:px-12 space-y-6 ">
                {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className=" flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
                {/* OR */}
                {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
                {/* FORM */}
                <form
                  className="grid grid-cols-1 gap-6"
                  onSubmit={sendVerification}
                  autoComplete="off"
                >
                  <label className="block">
                    <Label className="text-sm">{t("signUpAcc")}</Label>

                    <Autocomplete
                      className="mt-1.5"
                      // className="form-group"
                      // className="border-neutral-200"
                      disablePortal
                      // defaultValue={{ name: roles.find((v: any) => v.id === userType) ? roles.find((v: any) => v.id === userType).name : "" }}
                      value={{
                        name: roles.find((v: any) => v.id === userType)
                          ? userType === 1 && t("langKey") === "Si"
                            ? "යෝජනා අපේක්ෂක/පළකරන්නා"
                            : userType === 3 && t("langKey") === "Si"
                            ? "මංගල සේවා සපයන්නා"
                            : roles.find((v: any) => v.id === userType).name
                          : ""
                      }}
                      // id="combo-box-demo"
                      options={roles}
                      getOptionLabel={(option: any) =>
                        option.id === 1 && t("langKey") === "Si"
                          ? "යෝජනා අපේක්ෂක/පළකරන්නා"
                          : option.id === 3 && t("langKey") === "Si"
                          ? "මංගල සේවා සපයන්නා"
                          : option.name
                      }
                      // getOptionDisabled={(option) => option === roles[1]}
                      onChange={(e, value: any) => {
                        if (value.type !== "wholesaler") {
                          setuserType(value.id);
                          seterruserType(false);
                        }
                        // setCountry(value);
                      }}
                      // sx={{
                      //   // width: "80vw",
                      //   background:"transparent",
                      //   borderRadius: "20px",
                      //   // border: "none",
                      //   // borderColor: "transparent",

                      //   "& .MuiInputBase-input": {
                      //     height: 15,
                      //     // border: "none",
                      //     fontSize: "14px",
                      //     fontFamily: "'Jost', sans-serif",
                      //     // borderColor: "transparent",
                      //   },
                      //   //view
                      //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                      //     // borderColor: "transparent",
                      //   },

                      //   "&:hover .MuiOutlinedInput-input": {
                      //     color: "black",
                      //   },
                      //   "&:hover .MuiInputLabel-root": {
                      //     color: COLORS.GrayBackground,
                      //   },
                      //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      //   {
                      //     // borderColor: "transparent",
                      //   },
                      //   ".MuiAutocomplete-root fieldset:hover": {
                      //     color: "transparent",
                      //     // borderColor: "transparent",
                      //   },
                      // }}
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          <Input
                            {...params.inputProps}
                            className=""
                            placeholder={t("signUpAcc")}
                          />
                        </div>
                      )}
                      ListboxProps={{
                        style: {
                          maxHeight: "150px",
                          fontSize: "14px",
                          fontFamily:
                            t("langKey") === "Si" ? "NotoSans" : "Inter"
                          // border: '1px solid white'
                        }
                      }}
                    />

                    {erruserType && (
                      <div className="text-red-600 text-sm text-right mt-2">
                        This field is required
                      </div>
                    )}
                  </label>

                  {/* <label className="block">
              <Label className="text-sm" >First Name
              </Label>

              <Input
                type="text"
                placeholder="First Name"
                className="mt-1"
                onChange={(e) => {
                  setfirstName(e.target.value);
                  seterrfirstName(false);
                }}
                value={firstName}
              />
              {errfirstName && (
                <div className="text-red-600 text-sm text-right mt-2">
                  This field is required
                </div>
              )}
            </label>
            <label className="block">
              <Label className="text-sm" >Last Name
              </Label>

              <Input
                type="text"
                className="mt-1"
                placeholder="Last Name"
                onChange={(e) => {
                  setlasttName(e.target.value);
                  seterrlasttName(false);
                }}
                value={lastName}
              />
              {errlastName && (
                <div className="text-red-600 text-sm text-right mt-2">
                  This field is required
                </div>
              )}
            </label> */}

                  {/* {
                    userType === 3 &&


                    <div>
                      <Label className="text-sm">Category</Label>

                      <Autocomplete
                        className="mt-1.5"
                        // className="form-group"
                        // className="border-neutral-200"
                        disablePortal
                        value={{ name: allProCate.find((v: any) => v.id === selectedCat) ? allProCate.find((v: any) => v.id === selectedCat).name : "" }}

                        // id="combo-box-demo"
                        options={allProCate}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value: any) => {

                          if (value !== null) {
                            setselectedCat(value.id);
                          } else {

                            setselectedCat("")
                          }

                          // handleChangeHdist(event, value);
                          // setCountry(value);
                        }}
                        // sx={{
                        //   // width: "80vw",
                        //   background:"transparent",
                        //   borderRadius: "20px",
                        //   // border: "none",
                        //   // borderColor: "transparent",

                        //   "& .MuiInputBase-input": {
                        //     height: 15,
                        //     // border: "none",
                        //     fontSize: "14px",
                        //     fontFamily: "'Jost', sans-serif",
                        //     // borderColor: "transparent",
                        //   },
                        //   //view
                        //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        //     // borderColor: "transparent",
                        //   },

                        //   "&:hover .MuiOutlinedInput-input": {
                        //     color: "black",
                        //   },
                        //   "&:hover .MuiInputLabel-root": {
                        //     color: COLORS.GrayBackground,
                        //   },
                        //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        //   {
                        //     // borderColor: "transparent",
                        //   },
                        //   ".MuiAutocomplete-root fieldset:hover": {
                        //     color: "transparent",
                        //     // borderColor: "transparent",
                        //   },
                        // }}
                        renderInput={(params) => (
                          <div ref={params.InputProps.ref}>
                            <Input {...params.inputProps} className="" placeholder="Select a Category" />
                          </div>
                        )}
                        ListboxProps={{
                          style: {
                            maxHeight: "150px",
                            fontSize: "14px",
                            // border: '1px solid white'
                          },
                        }}
                      />


                      
                      {errcategory && (
                        <div className="text-red-600 text-sm text-right mt-2">
                          This field is required
                        </div>
                      )}
                    </div>

                  } */}
                  {/* <label className="block">
                    <Label className="text-sm" >{t('fCountry')}
                    </Label>

                    <Autocomplete
                      className="mt-1.5"
                      // className="form-group"
                      // className="border-neutral-200"
                      disablePortal
                      // defaultValue={{ value: category }}
                      value={country ? country : ""}

                      // id="combo-box-demo"
                      options={t('langKey') === "En" ? COUNTArr : COUNTArr}
                      getOptionLabel={(option) => option}
                      onChange={(e, value: any) => {
                        setcountry(value);
                        setemail("");
                        setmobile("");

                        // seterrtitle(false);

                        // setCountry(value);
                      }}
                      // sx={{
                      //   // width: "80vw",
                      //   background:"transparent",
                      //   borderRadius: "20px",
                      //   // border: "none",
                      //   // borderColor: "transparent",

                      //   "& .MuiInputBase-input": {
                      //     height: 15,
                      //     // border: "none",
                      //     fontSize: "14px",
                      //     fontFamily: "'Jost', sans-serif",
                      //     // borderColor: "transparent",
                      //   },
                      //   //view
                      //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                      //     // borderColor: "transparent",
                      //   },

                      //   "&:hover .MuiOutlinedInput-input": {
                      //     color: "black",
                      //   },
                      //   "&:hover .MuiInputLabel-root": {
                      //     color: COLORS.GrayBackground,
                      //   },
                      //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      //   {
                      //     // borderColor: "transparent",
                      //   },
                      //   ".MuiAutocomplete-root fieldset:hover": {
                      //     color: "transparent",
                      //     // borderColor: "transparent",
                      //   },
                      // }}
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          <Input {...params.inputProps} className="" placeholder={t('fCountry')} />
                        </div>
                      )}
                      ListboxProps={{
                        style: {
                          maxHeight: "150px",
                          fontSize: "14px",
                          fontFamily: t("langKey") === "Si" ? "NotoSans" : "Inter"
                          // border: '1px solid white'
                        },
                      }}
                    />

                  </label> */}

                  {
                    // country === "Sri Lanka" ?

                    // <label className="block">
                    //   <Label className="text-sm" >{t('fiContact')}
                    //   </Label>

                    //   <div className="w-full flex">

                    //     <Input className="mt-1.5"
                    //       // rounded="rounded-r-lg"
                    //       placeholder={"07xxxxxxxx"}
                    //       // onChange={(e) => {
                    //       //   setemail(e.target.value);
                    //       //   seterremail(false);
                    //       // }}
                    //       // value={email}
                    //       onChange={(e) => {
                    //         setmobile(e.target.value);
                    //         seterrmobile("");
                    //       }}
                    //       value={mobile}
                    //     />

                    //   </div>
                    //   {errmobile && (
                    //     <div className="text-red-600 text-sm text-right mt-2">
                    //       {errmobile}
                    //     </div>
                    //   )}

                    // </label>

                    // :

                    <label className="block">
                      <Label className="text-sm">{t("signUpEmail")}</Label>

                      <Input
                        type="email"
                        className="mt-1"
                        placeholder={t("signUpEmail")}
                        onChange={(e) => {
                          setemail(e.target.value);
                          seterremail(false);
                        }}
                        value={email}
                      />
                      {erremail && (
                        <div className="text-red-600 text-sm text-right mt-2">
                          This field is required
                        </div>
                      )}
                    </label>
                  }

                  {/* <label className="block">
              <Label className="text-sm" >Phone Number
              </Label>

              <Input
                type="text"
                className="mt-1"
                placeholder="Phone Number"
                onChange={(e) => {
                  setmobile(e.target.value);
                  seterrmobile("");
                }}
                value={mobile}
              />
              {errmobile && (
                <div className="text-red-600 text-sm text-right mt-2">
                  {errmobile}
                </div>
              )}
            </label> */}
                  <label className="block">
                    <Label className="text-sm">{t("signUpPwrd")}</Label>

                    <Input
                      type="password"
                      className="mt-1"
                      placeholder={t("signUpPwrd")}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        seterrPass(false);
                      }}
                      value={password}
                    />
                    {errPass && (
                      <div className="text-red-600 text-sm text-right mt-2">
                        This field is required
                      </div>
                    )}
                  </label>

                  <BotToast open={bOpen} msg={bMsg} status={bStatus} />

                  {/* <ButtonPrimary type="submit">Continue</ButtonPrimary> */}

                  <button
                    className={`ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-redF
        w-full h-9 text-slate-50 font-medium mt-2 cursor-pointer`}
                    type="submit"
                  >
                    {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
                    <span className="block  text-sm font-medium">
                      {t("signUp")}
                    </span>
                  </button>
                </form>

                {/* ==== */}
                <span className="block text-center text-gray-500 dark:text-neutral-300">
                  {t("signUpAlready")} {` `}
                  <Link className="text-redF" to="/login">
                    {t("signUpAlreadyText")}
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
