import React, { FC, useState, useEffect, useRef } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL, IMAGE_SERVER_URL_DEV } from "Constant/System";
import Company1 from "images/avatars/Image-1.png";
import ModalQuickView from "components/ModalPackage";
import { getBlogs, getCleanT, getJobs, getServicePackage } from "Services/API/Get";
import { addToast } from "shared/Toast";
import moment from "moment";
import { DeleteClean, DeleteJob, DeleteServicePackage } from "Services/API/Delete";

import ClImg from "images/cleaning/1.png"

import img1 from "images/cleaning/After Party.png"
import img2 from "images/cleaning/Blinds.png"
import img3 from "images/cleaning/Building.png"
import img4 from "images/cleaning/Car Park.png"
import img5 from "images/cleaning/Carpet.png"
import img6 from "images/cleaning/Child Care Cleaning.png"
import img7 from "images/cleaning/Emegency Cleaning.png"
import img8 from "images/cleaning/End of lease.png"
import img9 from "images/cleaning/Factory.png"
import img10 from "images/cleaning/Healthcare.png"
import img11 from "images/cleaning/Hospital.png"
import img12 from "images/cleaning/House.png"
import img13 from "images/cleaning/office.png"
import img14 from "images/cleaning/Other.png"
import img15 from "images/cleaning/Oven.png"
import img16 from "images/cleaning/Periodic House Cleaning.png"
import img17 from "images/cleaning/Pressure Washing.png"
import img18 from "images/cleaning/Sanitiznf.png"
import img19 from "images/cleaning/School.png"
import img20 from "images/cleaning/Strip and seal.png"
import img21 from "images/cleaning/Supermarket.png"
import img22 from "images/cleaning/Tile.png"
import img23 from "images/cleaning/Window.png"
import img24 from "images/cleaning/Commercial Cleaning.png"
import img25 from "images/cleaning/Industrial.png"
import img26 from "images/cleaning/Residential.png"


const BlogList = () => {

    const [images, setImages] = useState([]);
    const [showModalQuickView, setShowModalQuickView] = useState(false)

    const [blogs, setblogs] = useState([]);

    const [selectedJob, setselectedJob] = useState("");

    const getAllBlogs = () => {
        console.log(">>>>>>sdsxcddf")
        getServicePackage({ category: JSON.stringify([]) })
            .then((res) => {
                console.log(">>>>>>>blogs", res.data);

                const dat = res.data.map((item, index) => {

                    let img = ""

                    switch (item.name) {
                        case 'After Party Cleaning':
                            item.image = img1;
                            console.log("helloo")
                            break;
                        case 'Blind Cleaning':
                            item.image = img2;
                            break;
                        case 'Builders Cleaning':
                            item.image = img3;
                            break;
                        case 'Car Park Cleaning':
                            item.image = img4;
                            break;
                        case 'Carpet Cleaning':
                            item.image = img5;
                            break;
                        case 'Emergency Cleaning':
                            item.image = img7;
                            break;
                        case 'End of Lease Cleaning':
                            item.image = img8;
                            break;
                        case 'Healthcare Cleaning':
                            item.image = img10;
                            break;
                        case 'Home Cleaning':
                            item.image = img12;
                            break;
                        case 'Office Cleaning':
                            item.image = img13;
                            break;
                        case 'Pressure Washing':
                            item.image = img17;
                            break;
                        case 'Sanitization and Deep Cleaning':
                            item.image = img18;
                            break;
                        case 'School Cleaning':
                            item.image = img19;
                            break;
                        case 'Strip and Seal Cleaning':
                            item.image = img20;
                            break;
                        case 'Supermarket Cleaning':
                            item.image = img21;
                            break;
                        case 'Tile Cleaning':
                            item.image = img22;
                            break;
                        case 'Window Cleaning':
                            item.image = img23;
                            break;
                        case 'Other':
                            item.image = img14;
                            break;
                        case 'Oven Cleaning':
                            item.image = img15;
                            break;
                        case 'Child Care Cleaning':
                            item.image = img6;
                            break;
                        case 'Commercial Cleaning':
                            item.image = img24;
                            break;
                        case 'Industrial Cleaning':
                            item.image = img25;
                            break;
                        case 'Residential Cleaning':
                            item.image = img26;
                            break;


                    }


                    return item;
                })
                setblogs(dat);
            })
            .catch((error) => {
                // setlopen(false);
                setblogs([]);
                console.log("Error", error.response);
                // addToast(error.response.data.message, "warning");
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            })
    }

    useEffect(() => {
        getAllBlogs();
    }, [showModalQuickView])


    return (
        <>
            <ModalQuickView
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
                selectedItem={selectedJob}
            />
            <div className="flex justify-center w-full mt-1">

                {/* <span className="block text-3xl font-bold flex justify-center w-full text-center">
                    Packages (Optional)
                </span> */}


            </div>
            <div className="w-full flex justify-center px-3">

                <div className="w-full md:w-8/12 lg:w-7/12 xl:w-[35%]">
                    <div className="flex w-full items-center justify-center mt-12"

                        onClick={() => {
                            setShowModalQuickView(true)
                            setselectedJob("")
                        }}>

                        <div className="ml-2 bg-[#00B88B] p-1 rounded-full flex items-center justify-center text-white">
                            <i className={`las la-plus text-xl text-left  cursor-pointer`}


                            ></i>

                        </div>
                        <span className="text-base text-[#002930] ml-4 cursor-pointer font-medium">Add Packages (Optional)</span>

                    </div>
                    {/* <div className="flex w-full items-center justify-center">
                        {
                            // blogs.length === 0 &&

                            <button
                                className={
                                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-12 `}

                                onClick={() => {
                                    setShowModalQuickView(true)
                                    setselectedJob("")
                                }}
                            >
                               
                                <span className="block  text-sm font-medium "
                                >
                                    Add Package
                                </span>

                            </button>
                        }

                    </div> */}
                    {/* <form className="mt-6 w-full">

                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                            </div>
                            <Input type="search" id="default-search" className="block w-full p-4 pl-10 text-base text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search ..." required />

                        </div>
                    </form> */}

                    <div className="w-full mt-4 space-y-4">

                        {/* <div>
                            <Label className="text-sm">From</Label>
                            <Input className="mt-1.5" type="date"
                            // onChange={(e) => {
                            //     setlasttName(e.target.value);
                            //     seterrlasttName(false);
                            // }}
                            // value={lastName}
                            />
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}
                        {/* <div>
                            <Label className="text-sm">To</Label>
                            <Input className="mt-1.5" type="date"
                            // onChange={(e) => {
                            //     setlasttName(e.target.value);
                            //     seterrlasttName(false);
                            // }}
                            // value={lastName}
                            />
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}


                    </div>
                    <div className="mt-5 space-y-2">
                        {
                            blogs.map((item, index) => {
                                return (
                                    <div className="flex">
                                        <div className="bg-gray-100 flex w-full pl-8 pr-4 py-4 rounded-lg space-x-4 cursor-pointer"

                                        >
                                            {/* <div className="flex justify-start w-4/12 items-center"
                                                onClick={() => {
                                                    console.log("item>>>>", item)


                                                }}
                                            >
                                                <div className="w-12 h-12 object-fill bg-contain "
                                                    style={{
                                                        backgroundImage: `url(${item.image})`
                                                        // backgroundImage: item ? `url(${IMAGE_SERVER_URL_DEV}${item?.image})` : `url(${Company1})`
                                                    }}
                                                >

                                                </div>

                                            </div> */}
                                            <div className="flex justify-start w-full items-center"
                                                onClick={() => {
                                                    console.log("item>>>>", item)


                                                }}
                                            >
                                                <div>
                                                    <div className="text-base font-normal text-[#003D35] flex justify-start items-center">{item.packageName}</div>

                                                    {/* <div className="text-sm font-medium flex justify-start items-center">{item.country}</div> */}

                                                </div>
                                            </div>

                                            <div className="w-6/12 flex justify-start items-center"
                                                onClick={() => {
                                                    console.log("item>>>>", item)

                                                }}
                                            >
                                                {/* <div>
                                                    <div className="text-base font-medium flex justify-start items-center">Status</div>

                                                    <div className="text-sm font-medium flex justify-start items-center">Active</div>
                                                </div> */}
                                            </div>

                                            <div className="w-6/12 flex justify-start items-center"
                                                onClick={() => {
                                                    console.log("item>>>>", item)


                                                }}
                                            >
                                                {/* <div>
                                                    <div className="text-base font-medium flex justify-start items-center">Applicants</div>

                                                    <div className="text-sm font-medium flex justify-start items-center">{item?.noOfApplicants}</div>
                                                </div> */}
                                            </div>

                                            <div className="flex items-center">

                                                <div className="ml-2 bg-[#BFBFBF] p-1 rounded-full flex items-center justify-center text-white">
                                                    <i className="las la-pencil-alt text-xl text-left cursor-pointer"
                                                        onClick={() => {
                                                            console.log("item>>>>", item)
                                                            setselectedJob(item);
                                                            setShowModalQuickView(true);

                                                        }}
                                                    ></i>

                                                </div>

                                                <div className="ml-2 bg-[#e12e2f] p-1 rounded-full flex items-center justify-center text-white">

                                                    <i className="las la-trash-alt text-xl text-left cursor-pointer"
                                                        onClick={() => {
                                                            DeleteServicePackage(item?.id)
                                                                .then((res) => {

                                                                    addToast("Package successfully deleted", "success");
                                                                    getAllBlogs();
                                                                })
                                                                .catch((error) => {
                                                                    // setlopen(false);
                                                                    console.log("Error", error.response);
                                                                    addToast(error.response.data.message, "warning");
                                                                    // setOpenToast(true);
                                                                    // setColor(COLORS.Orange);
                                                                    // setAlertClass("error");
                                                                    // setMsg(error.response.data.message);
                                                                })
                                                        }}
                                                    ></i>

                                                </div>


                                            </div>


                                        </div>


                                    </div>
                                );
                            })
                        }

                    </div>

                </div>
            </div>

        </>
    )

}

export default BlogList;