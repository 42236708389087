import { IMAGE_SERVER_URL_DEV, SITE_URL } from "Constant/System";


export const EMAIL_TAMPLETE_RETAIL = ({ customerName, invoiceNo, pdfName, date, address, address2, address3, address4 }) => {
    return `
    <html lang='en'>
  
    <head>
        <meta charset='utf-8' />
        <title>${pdfName}</title>
        <meta name='viewport' content='initial-scale=1.0; maximum-scale=1.0; width=device-width;'>
   
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link
            href="https://fonts.googleapis.com/css2?family=Asap+Condensed:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
            rel="stylesheet">
        <style>
            @import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700,300,100);
    
            .txt-primary {
                color: #21949F
            }
    
            .background-primary {
                background-color: #21949F
            }
    
            .background-secondary {
                background-color: #E5DDD8
            }
    
            .background-tertiary {
                background-color: #2d867b
            }
    
            .txt-secondary {
                color: #2d867b;
                text-align:left;
            }
    
            .invoice-container {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
    
            .invoice-details-container {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                flex-direction: column;
            }
    
            .txt-invoice {
                font-family: 'Inter', sans-serif;
                text-transform: uppercase;
                text-align: right;
                font-weight: bold;
                letter-spacing: 2;
            }
    
            .txt-1 {
                font-size: 10pt;
                font-weight: 400;
                letter-spacing: 0.8px;
                font-family: 'Inter', sans-serif;
            }
    
            .txt-2 {
                font-size: 18pt;
                font-weight: 600;
                font-family: 'Inter', sans-serif;
            }
    
            .txt-3 {
                font-size: 14pt;
                font-weight: 400;
                font-family: 'Inter', sans-serif;
            }
    
            .tb-row-1 {
    
                padding: 6px 0px;
                font-size: 11pt;
                font-weight: 400;
                display: flex;
                align-items: center;
                font-family: 'Inter', sans-serif;
                letter-spacing: 1;
    
            }
    
            .tb-row-2 {
    
                padding: 8px 0px;
                font-size: 10pt;
                font-weight: 400;
                display: flex;
                align-items: center;
                font-family: 'Inter', sans-serif;
                letter-spacing: 1;
    
            }
    
            .txt-4 {
                padding: 6px 5px;
                font-size: 14pt;
                font-weight: 400;
                display: flex;
                align-items: center;
                font-family: 'Inter', sans-serif;
                letter-spacing: 1;
            }
    
            .contact-container {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
               
            }
    
            .txt-5 {
                font-size: 17pt;
                font-weight: 600;
                display: flex;
                align-items: center;
                font-family: 'Inter', sans-serif;
                letter-spacing: 3px;
            }
    
            .txt-6 {
                align-items: center;
                font-family: 'Inter', sans-serif;
                letter-spacing: 1px;
                font-size: 15pt;
                margin-bottom: 3px;
            }
    
            .txt-7 {
                align-items: center;
                font-family: 'Inter', sans-serif;
                font-weight: 400;
                letter-spacing: 1px;
                font-size: 13pt;
            }
    
            .custom-hr {
                width: 45%;
                height: 2px;
                margin: 8px 0px 20px;
                opacity: 1;
            }
    
            body {
                background-color: #e5e5e5;
            }
        </style>
    </head>
    
    <body>
        <div class="container" style="background:#fff;max-width: 800px;text-align:left;">
        <div style="padding:80px 10%">
        <div style="display: flex;justify-content: space-between;align-items: center;">
        <div class="col"  style="width:580px;text-align: left;">
            <img src="${IMAGE_SERVER_URL_DEV}/uploads/logo/tidytraderr.png" style="width:140px;min-width:140px" alt="logo" />
       <br/>
       <br/>

       <br/>

            </div>
       
       
    </div>
        <div style="margin-bottom:5px;" class="txt-secondary txt-1">
            Dear ${customerName},
        </div>
        <br />
        <div style="margin-bottom:5px;" class="txt-secondary txt-1">
        We're thrilled to confirm your recent purchase through the Tidytraderr
Cleaning Industry Marketplace! Thank you for selecting our platform for
your cleaning needs. Here are your order details:
           
        </div>
        <br />

     

        <div style="display: flex;" class="txt-secondary txt-1">

        <div style="display: block; text-align:left ;margin-top:20px" class="txt-secondary txt-1">
                                            <span style="display: block;">Order Number</span>
                                            <span style="display: block;">Purchase Date</span>
                                            <span style="display: block;">Shipping Address</span>
                                           
                                        </div>
                                        <div style="display: block; text-align:left ;margin-top:20px;margin-left:10px" class="txt-secondary txt-1">
                                            <span style="display: block;">: ${invoiceNo}</span>
                                            <span style="display: block;">: ${date}</span>
                                            <span style="display: block;">: ${`${address}, ${address2}, ${address3}, ${address4}`}</span>
                                           
                                        </div>
                                </div>

        <!--

        <div style="margin-bottom:5px; text-align: left;" class="txt-secondary txt-1">
        Order Number  : ${invoiceNo} <br/>
        Purchase Date  : ${date} <br/>
        Shipping Address:  : ${`${address}, ${address2}, ${address3}, ${address4}`} <br/>

        </div>

        -->

        <br/>

        <div style="margin-bottom:5px;" class="txt-secondary txt-1">
        Please review the attached invoice. If you
        notice any discrepancies or have questions about your order, feel free
        to contact us through this <a href="https://businessdirectory.infinitycs.com.au/" target="_blank">link.<a/> 
        
           
        </div>
        <br />

       

        <div style="margin-bottom:5px;" class="txt-secondary txt-1">
        
        Please be aware that each seller on the Tidytraderr Cleaning Industry
Marketplace has distinct policies for refunds, returns, and exchanges.
Since sellers operate independently, we kindly ask you to directly
contact the seller of the purchased item(s) for any matters related to
refunds, returns, or exchanges. You can find their contact information
on the product listing or the Seller page.
        
           
        </div>
        <br />

        <!--

        <div style="margin-bottom:5px;" class="txt-secondary txt-1">
        
        Your satisfaction is our priority, and we're dedicated to providing a
seamless experience for you. If you encounter any issues or have further
questions, please don't hesitate to fill out this suggestion form to
help us improve.
        
           
        </div>
        <br />

        -->

        <div style="margin-bottom:5px;" class="txt-secondary txt-1">
        
        Thank you for being a valued customer of the Tidytraderr Cleaning Industry
Marketplace. We appreciate your purchase and look forward your
continued transactions through Tidytraderr.
        
           
        </div>
        <br />


        <div style="margin-bottom:5px;" class="txt-secondary txt-1">
        Best regards,<br/>
        Tidytraderr Team


        </div>
        <br /><br/>

        <a href="${SITE_URL + "signup"}" traget="_blank" >
        <div style="display: flex;justify-content: space-between;align-items: center;">
        <div class="col"  style="width:580px;text-align: left;">
            <img src="https://backend.tidytraderr.com/public/uploads/promo/banner.png"style="" alt="logo" />
       <br/>
       <br/>

       <br/>

            </div>
       
       
    </div>

    </a>
     
       
    </div>
         
    
            </div>
        </div>
    </body>
    
    </html>`;
};
{
    // <br />
    // <br />
    // <br />
    // <a href="${IMAGE_SERVER_URL}/upload/${pdfName}.pdf" target="_blank" style="display:flex">
    //     <img src="https://icones.pro/wp-content/uploads/2021/03/icone-pdf-symbole-png-rouge.png"
    //         style="width:60px;object-fit: contain;" />
    //     <p style="color:#A10000;font-family: 'Roboto Slab', serif;font-weight: 500;">${pdfName}.pdf</p>
    // </a>
}
