import React, { FC, useState, useEffect, useRef } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL } from "Constant/System";
import _ from "lodash";
import { addToast } from "shared/Toast";
import { postBlogs, postJobs } from "Services/API/Post";
import { GetUserId } from "Services/API/Get";
import Cropper from "react-easy-crop";


const Blogs = (props) => {

    const imgSrc = props?.imgSrc;
    const ImgSrcavatar = props?.ImgSrcavatar;

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState(null);
    const [aspectRatio, setAspectRatio] = useState(4 / 1);

    const [images, setImages] = useState([]);

    const [title, settitle] = useState("");
    const [blogType, setblogType] = useState("");
    const [description, setdescription] = useState("");
    const [Keywords, setKeywords] = useState("");

    const [errimages, seterrImages] = useState([]);

    const [errtitle, seterrtitle] = useState(false);
    const [errblogType, seterrblogType] = useState(false);
    const [errdescription, seterrdescription] = useState(false);
    const [errKeywords, seterrKeywords] = useState(false);


    const saveBlog = (e) => {

        e.preventDefault();

        if (!title) {
            seterrtitle(true);
        }

        if (!blogType) {
            seterrblogType(true);
        }

        if (!description) {
            seterrdescription(true);
        }

        // if (!Keywords) {
        //     seterrKeywords(true);
        // }
        // if (images.length === 0) {
        //     seterrImages(true);
        // }

        console.log(">>>>>>>>", _.filter(Keywords.split(" ")))

        console.log(">>>>>>", images)

        let obj = {
            title: title,
            description: description,
            keywords: _.filter(Keywords.split(" ")),
            userId: GetUserId(),
            images: images,
            category: blogType


        }

        console.log("obj>>>>", obj)

        if (
            title &&
            description &&
            // Keywords &&
            blogType
            // images.length > 0
        ) {
            postBlogs(obj)
                .then((res) => {
                    // setlopen(false);
                    addToast("Job successfully added", "success");
                    settitle("");
                    setdescription("");
                    setblogType("");
                    setImages([]);
                    setKeywords([]);

                    // setOpenToast(true);
                    // setColor(COLORS.Primary);
                    // setAlertClass("success");
                    // setMsg("Successfully updated");
                    // getCurrentUser();
                    // seteDisable(true);
                    // getUser();

                    // history.push("/");
                    // window.location.reload();
                })
                .catch((error) => {
                    // setlopen(false);
                    console.log("Error", error.response);
                    addToast(error.response.data.message, "warning");
                    // setOpenToast(true);
                    // setColor(COLORS.Orange);
                    // setAlertClass("error");
                    // setMsg(error.response.data.message);
                })
        }


    }

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    };

    const onCropDone = (imgCroppedArea, type) => {
        const canvasEle = document.createElement("canvas");
        canvasEle.width = imgCroppedArea.width;
        canvasEle.height = imgCroppedArea.height;

        const context = canvasEle.getContext("2d");

        let imageObj1 = new Image();

        if (type === "cover") {
            imageObj1.src = imgSrc;
        } else if (type === "avatar") {
            imageObj1.src = ImgSrcavatar;
        }

        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                imgCroppedArea.x,
                imgCroppedArea.y,
                imgCroppedArea.width,
                imgCroppedArea.height,
                0,
                0,
                imgCroppedArea.width,
                imgCroppedArea.height
            );

            const dataURL = canvasEle.toDataURL("image/png");

            console.log(">>>>>", dataURL)

            if (type === "cover") {
                props?.setimgcover(dataURL);

            } else if (type === "avatar") {
                props?.setavatar(dataURL);
            }

            // setImgAfterCrop(dataURL);
            // setCurrentPage("img-cropped");
        };
    };



    return (
        <>
            <div className="flex justify-center w-full mt-2">

                <span className="block text-xl font-bold flex justify-center w-full text-center">

                    Select area of the image
                </span>

            </div>

            {
                imgSrc &&

                <>

                    <div className="flex-shrink-0 flex justify-center w-full mt-4">

                        <div className="relative w-full overflow-hidden flex">
                            <div
                                className="h-[220px] md:h-[280px] xl:h-[290px] 2xl:h-[300px] w-full bg-center"
                            >
                                <Cropper
                                    image={imgSrc}
                                    aspect={aspectRatio}
                                    crop={crop}
                                    zoom={zoom}
                                    onCropChange={setCrop}
                                    onZoomChange={setZoom}
                                    onCropComplete={onCropComplete}
                                    style={{
                                        // containerStyle: {
                                        //     width: "100%",
                                        //     height: "100%",
                                        //     backgroundColor: "#fff",
                                        // },
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                    <span className="block  text-xs font-normal text-right mt-2 mb-6"

                    >
                        (1920 x 500 px) .jpg or .png
                    </span>

                </>
            }

            {
                ImgSrcavatar &&

                <>

                    <div className="flex-shrink-0 flex justify-center w-full mt-4">
                        <div className="relative overflow-hidden flex">
                            <div
                                className="w-80 h-80 object-cover z-[2] border-2 border-white"
                            >
                                <Cropper
                                    image={ImgSrcavatar}
                                    aspect={1 / 1}
                                    crop={crop}
                                    zoom={zoom}
                                    onCropChange={setCrop}
                                    onZoomChange={setZoom}
                                    onCropComplete={onCropComplete}
                                    style={{
                                        // containerStyle: {
                                        //     width: "100%",
                                        //     height: "100%",
                                        //     backgroundColor: "#fff",
                                        // },
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <span className="block  text-xs font-normal text-right mt-2 mb-6"

                    >
                        (500 x 500 px) .jpg or .png
                    </span>
                </>


            }


            {
                (imgSrc || ImgSrcavatar) &&

                <div className="flex w-full mt-2">
                    <div className="w-full flex justify-end space-x-2">
                        <button
                            className={
                                `rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b] text-slate-50 font-medium px-2 py-1`}
                            onClick={() => {
                                if (imgSrc) {
                                    onCropDone(croppedArea, "cover");
                                } else if (!imgSrc && ImgSrcavatar) {
                                    console.log(">>>hello")
                                    onCropDone(croppedArea, "avatar");
                                }

                            }}

                        >

                            <span className="block  text-sm font-medium "

                            >
                                Save
                            </span>

                        </button>
                        <button
                            className={
                                `rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-slate-100 text-slate-900 font-medium px-2 py-1`}
                            onClick={() => {
                                props?.setImgSrc("");
                                props?.setImgSrcavatar("");
                                setCrop({ x: 0, y: 0 });
                                setZoom(1);
                                setCroppedArea(null);
                                props?.onCloseModalQuickView();
                            }}

                        >

                            <span className="block  text-sm font-medium "

                            >
                                Cancel
                            </span>

                        </button>
                    </div>

                </div>
            }

        </>
    )

}

export default Blogs;