import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import { StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { PRODUCTS } from "data/data";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import NotifyAddTocart from "./NotifyAddTocart";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import Label from "./Label/Label";
import Select from "shared/Select/Select";
import { IMAGE_SERVER_URL } from "Constant/System";


export interface ProductQuickView2Props {
  className?: string;
}

const ProductQuickView2: FC<ProductQuickView2Props> = ({ className = "" }) => {
  const { sizes, variants, status, allOfSizes } = PRODUCTS[0];
  const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];

  const [variantActive, setVariantActive] = React.useState(0);
  const [sizeSelected, setSizeSelected] = React.useState(sizes ? sizes[0] : "");
  const [qualitySelected, setQualitySelected] = React.useState(1);

  const [value, setValue] = React.useState<any>(0);
  const [firstName, setfirstName] = useState("");
  const [errfirstName, seterrfirstName] = useState(false);

  const [images, setImages] = useState([])

  const notifyAddTocart = () => {
    toast.custom(
      (t) => (
        <NotifyAddTocart
          productImage={LIST_IMAGES_DEMO[0]}
          qualitySelected={qualitySelected}
          show={t.visible}
          sizeSelected={sizeSelected}
          variantActive={variantActive}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };



  return (
    <div className={`nc-ProductQuickView2 ${className} mt-16 sm:mt-0 md:px-32 py-6`}>
      {/* MAIn */}
      <div className="lg:flex">
        {/* CONTENT */}
        <div className="w-full ">
          {/* HEADING */}

          <div className="w-full flex justify-center px-3">

            <div className="w-full">
              <div className="flex justify-center w-full mt-0">

                <span className="block text-xl font-bold flex justify-center w-full text-center">
                  Post Question
                </span>


              </div>

              <div className="w-full mt-6 space-y-4">
                <div>
                  <Label className="text-sm">Select Category</Label>
                  <Select className="mt-1.5"
                  // onChange={(e) => {
                  //     setcountry(e.target.value);
                  //     seterrcountry(false);
                  // }}
                  // value={country}
                  >
                    <option value={""}>Select category</option>
                    <option value="Stripe">Stripe</option>
                    {/* <option value="United States">Canada</option>
    <option value="United States">Mexico</option>
    <option value="United States">Israel</option>
    <option value="United States">France</option>
    <option value="United States">England</option>
    <option value="United States">Laos</option>
    <option value="United States">China</option> */}
                  </Select>
                  {false && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>

                <div>
                  <Label className="text-sm">Question</Label>
                  <Textarea className="mt-1.5" rows={8}
                  // onChange={(e) => {
                  //     setlasttName(e.target.value);
                  //     seterrlasttName(false);
                  // }}
                  // value={lastName}
                  />
                  {false && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </div>        
                <div className="w-full mt-3 xl:mt-6 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                  <div className="grid grid-cols-10 gap-5 pl-4 pr-5">
                    {images?.map((faker: any, fakerKey: any) => (
                      <>
                        <div
                          // key={fakerKey}
                          className="col-span-5 md:col-span-2 h-28 relative image-fit cursor-pointer zoom-in"
                        >
                          <img
                            className="rounded-md"
                            alt="Midone - HTML Admin Template"
                            src={faker.path.includes('/uploads/products/') === true ? IMAGE_SERVER_URL + faker?.path : faker?.path}
                          />
                          <div
                            className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 -mr-2 -mt-2"
                          >
                            <div
                              onClick={(e) => {

                                var array = [...images]
                                array.splice(fakerKey, 1);
                                setImages(array)

                              }}
                            >
                              {/* <Lucide icon="X" className="w-4 h-4" /> */}
                              <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                            </div>
                          </div>


                        </div>
                        {/* <div className="form-check">
                            <input id="checkbox-switch-" className="form-check-input w-5 h-5 bg-gray-200 relative rounded-full text-white right-10 top-[80px]" type="checkbox"
                                checked={faker?.thumbnail}

                                onChange={(e) => {
                                    var array = [...images]
                                    const index = _.map(array, "thumbnail").indexOf(true);

                                    // console.log("index>>>", index)

                                    if (index < 0) {
                                        array[fakerKey].thumbnail = e.target.checked;
                                        setImages(array)
                                    } else {
                                        array[index].thumbnail = false;
                                        array[fakerKey].thumbnail = e.target.checked;
                                        setImages(array)
                                    }
                                    // array.filter(person => person.thumbnail === true)
                                    // array[fakerKey].thumbnail = e.target.checked;
                                    // setImages(array)
                                    // console.log("<<<<<",e.target.checked)
                                    // setlatest(e.target.checked)
                                    // onChangeUserArr(e, arr.privilege)
                                    // this.onChangeAllArr(e, arr.privilege);
                                }}
                            />
                           


                        </div> */}
                      </>

                    ))}
                  </div>
                  <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative text-sm">
                    {/* <Lucide icon="Image" className="w-4 h-4 mr-2" /> */}
                    <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                    <span className="text-primary mr-1 ">
                      Upload Photos
                    </span>{" "}
                    or drag and drop
                    <input
                      id="horizontal-form-1"
                      type="file"
                      className="w-full h-full top-0 left-0 absolute opacity-0"
                      onChange={(e: any) => {

                        let file = e.target.files[0];

                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        let fileInp = e.target.files[0];
                        let baseURL;
                        // on reader load somthing...
                        reader.onload = () => {
                          // Make a fileInfo Object
                          baseURL = reader.result;
                          let obj: any =
                          {
                            path: baseURL,
                            thumbnail: false,
                          }
                            ;

                          setImages(images.concat(obj))


                        };
                      }}
                    />
                  </div>
                </div>
                <div className="flex w-full items-center justify-center">
                  <button
                    className={
                      `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-gradient-to-r from-cyan-500 from-10% to-[#3c9140] to-90% hover:from-pink-500 hover:to-yellow-500 
w-full h-9 text-slate-50 font-medium mt-4 `}


                  >
                    {/* <i className="las la-pencil-alt text-xl text-left mr-2"></i> */}
                    <span className="block  text-sm font-medium "
                    >
                      Submit your post
                    </span>

                  </button>
                </div>

              </div>

            </div>
          </div>


        </div>


      </div>
    </div>
  );
};

export default ProductQuickView2;
