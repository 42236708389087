import rightImg from "images/about/1.jpg";
import React, { FC } from "react";
import SectionFounder, { People } from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";
import NcImage from "shared/NcImage/NcImage";
import SectionPromo1 from "components/SectionPromoHeader";
import cover from "images/cover/cover-8.png"
import Map from "images/about/map.png"
import Pic1 from "images/about/pic1.png"
import Pic2 from "images/about/pic2.png"
import Pic3 from "images/about/pic3.png"
import Pic4 from "images/about/pic4.png"
import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import Company1 from "images/avatars/Image-1.png";
import SectionSliderBlogs from "components/SectionSliderCategories/SectionSliderBlogs";

const DEMO_DATA = [
    {
        name: "What is Tidytraderr?",
        content:
            "Tidytraderr is an online networking and marketplace platform for farmers, wholesalers, buyers, agricultural professionals, and other stakeholders to connect, exchange information, and trade agricultural products & services.",
    },
    {
        name: "Who can join Tidytraderr?",
        content:
            "Anyone involved in the agricultural and farming industry can join Tidytraderr. This includes farmers, wholesalers, buyers, agricultural professionals, and other stakeholders.",
    },
    {
        name: "How do I create an account on Tidytraderr?",
        content:
            `To create an account on Tidytraderr, go to the homepage and click on the "Sign Up" button. Follow the prompts to provide your name, email address, and other required information.`,
    },
    {
        name: "Is it free to join Tidytraderr?",
        content:
            "Yes, it is free to join Tidytraderr.",
    },
    {
        name: "How do I search for other members on Tidytraderr?",
        content:
            "To search for other members on Tidytraderr, use the search bar on the homepage. You can search for members by name, location, category, or keywords related to their business.",
    },
    {
        name: "How do I contact other members on Tidytraderr?",
        content:
            "To contact other members on Tidytraderr, use the messaging feature on the platform. You can send direct messages to other members to ask questions, share information, or negotiate deals or directly contact them if they have mentioned their contact details.",
    },
    {
        name: "Can I buy and sell products on Tidytraderr?",
        content:
            "Yes, you can buy and sell agricultural products on Tidytraderr. The platform allows you to list your products for sale and search for products to buy from other members.",
    },
    {
        name: "Is it safe to trade on Tidytraderr?",
        content:
            "Tidytraderr takes security and safety seriously. We have implemented measures to protect your personal information and facilitate secure transactions. However, as with any online platform, there is always a risk of fraud or other malicious activities. Please exercise caution and follow general safety guidelines when conducting transactions on Tidytraderr.",
    },
    {
        name: "Can I cancel my account on Tidytraderr?",
        content:
            "Yes, you can cancel your account on Tidytraderr at any time. Simply go to your account settings and follow the prompts to deactivate or delete your account. Note that if you are in the middle of a paid subscription, you will not receive a refund.",
    },
    {
        name: "How do I get help with using Tidytraderr?",
        content:
            "If you need help with using Tidytraderr, you can visit our Support Center or contact our customer support team. We are available to answer your questions and assist you with any issues you may encounter.",
    },
    // {
    //     name: "Fabric + Care",
    //     content: `<ul class="list-disc list-inside leading-7">
    //   <li>Made from a sheer Belgian power micromesh.</li>
    //   <li>
    //   74% Polyamide (Nylon) 26% Elastane (Spandex)
    //   </li>
    //   <li>
    //   Adjustable hook & eye closure and straps
    //   </li>
    //   <li>
    //   Hand wash in cold water, dry flat
    //   </li>
    // </ul>`,
    // },

    // {
    //     name: "How it Fits",
    //     content:
    //         "Use this as a guide. Preference is a huge factor — if you're near the top of a size range and/or prefer more coverage, you may want to size up.",
    // },
    // {
    //     name: "FAQ",
    //     content: `
    //   <ul class="list-disc list-inside leading-7">
    //   <li>All full-priced, unworn items, with tags attached and in their original packaging are eligible for return or exchange within 30 days of placing your order.</li>
    //   <li>
    //   Please note, packs must be returned in full. We do not accept partial returns of packs.
    //   </li>
    //   <li>
    //   Want to know our full returns policies? Here you go.
    //   </li>
    //   <li>
    //   Want more info about shipping, materials or care instructions? Here!
    //   </li>
    // </ul>
    //   `,
    // },
];

const FAQs = (props) => {

    return (
        <>
            <div className={`flex justify-center w-full ${props?.home ? "" : "mt-12"}`}>
                <div className="md:w-9/12 xl:w-5/12 rounded-2xl space-y-2.5 px-4">
                    {/* ============ */}
                    {DEMO_DATA.map((item, index) => {
                        return (
                            <Disclosure key={index} defaultOpen={index < 1}>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex text-sm items-center justify-between w-full px-4 py-2 font-bold text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 "

                                        >
                                            <span>{item.name}</span>
                                            {!open ? (
                                                <PlusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                                            ) : (
                                                <MinusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                                            )}
                                        </Disclosure.Button>
                                        <Disclosure.Panel
                                            className={"p-4 pt-3 last:pb-0  text-sm dark:text-slate-300 leading-6 font-['Avenir, sans-serif']"}
                                            as="div"
                                            dangerouslySetInnerHTML={{ __html: item.content }}
                                        ></Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        );
                    })}

                    {/* ============ */}
                </div>

            </div>
        </>
    );

}

export default FAQs