import React, { FC, useContext } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link, NavLink, useHistory } from "react-router-dom";
import ButtonCircle from "shared/Button/ButtonCircle";
import Company1 from "images/avatars/3.png";
import { StarIcon } from "@heroicons/react/24/solid";
import Rating from '@mui/material/Rating';
import LikeButton from "components/LikeButton";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import EmptyIMG from "images/empty.png";
import { addToWishlist } from "Services/API/Post";
import jwtDecode from "jwt-decode";
import WishContext from "Context/WishContext";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import Prices from "components/Prices";
import UserWishList from "Constant/WishList";
import { addToast } from "shared/Toast";
import { DeleteWishlist } from "Services/API/Delete";
import _ from "lodash";

export interface CardCategory2Props {
  className?: string;
  ratioClass?: string;
  bgClass?: string;
  featuredImage?: string;
  name?: string;
  desc?: string;
  data?: any;
}

const CardCategory2: FC<CardCategory2Props> = ({
  className = "",
  ratioClass = "aspect-w-1 aspect-h-1",
  bgClass = "bg-orange-50",
  featuredImage = ".",
  name,
  desc,
  data,
}) => {

  const history = useHistory();

  const { wish, setWish } = useContext(WishContext);

  const renderProductCartOnNotify = ({ size }: { size?: string }) => {


    return (
      <div className="flex ">
        <div className="h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            // src={
            //   data.images && JSON.parse(data.images).length > 0 ?

            //     `${IMAGE_SERVER_URL_DEV}${JSON.parse(data.images).filter((item: { thumbnail: boolean; }) => item?.thumbnail === true)[0]?.path}` :

            //     ""
            // }
            src={
              data.images && JSON.parse(data.images).length === 1 ?

                `${IMAGE_SERVER_URL_DEV}${JSON.parse(data.images)[0]?.path}`

                : JSON.parse(data.images).length > 1 ?

                  `${IMAGE_SERVER_URL_DEV}${JSON.parse(data.images).filter((item: { thumbnail: boolean; }) => item?.thumbnail === true)[0]?.path}`


                  :


                  EmptyIMG
            }
            alt={data.productName}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-base font-medium ">{data.productName}</h3>
                {/* <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                  <span>
                    {variants ? variants[variantActive].name : `Natural`}
                  </span>
                  <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                  <span>{size || "XL"}</span>
                </p> */}
              </div>
              <Prices price={data.unitprice} className="mt-0.5" />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            {/* <p className="text-gray-500 dark:text-slate-400">Qty 1</p> */}

            {/* <div className="flex">
              <a href="/account-savelists">
                <button
                  type="button"
                  className="font-medium text-[#2d867b] dark:text-primary-500 "
                >
                  View Wishlist
                </button>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    );
  };


  const notifyAddToWish = () => {


    const getToken = localStorage.getItem(`${window.location.hostname}.login`);

    // if (!getToken) {
    //   history.push("/login");
    //   window.scroll(0, 0);
    //   return true;
    // }

    // const tken = JSON.parse(getToken);
    // const userData: any = jwtDecode(tken.data);

    let tempId: any = ""

    let userId: any = ""

    if (localStorage.getItem("tempUser")) {
      tempId = localStorage.getItem("tempUser")
    }

    console.log("temp>>>>", tempId)

    if (getToken) {

      const { data } = JSON.parse(getToken);
      const { id }: any = jwtDecode(data);

      userId = id

    }

    let error = false;
    let cartDetails = {
      productId: data.id.toString(),
      userId: userId ? userId : tempId,
      qty: 1,
      // color: selectedProductColor,
      // size: selectedProductPrice,
      fileType: "",
      image: "",
    };

    console.log("data>>>>", cartDetails)


    if (!(_.map(wish, "id").includes(data.id))) {
      addToWishlist(cartDetails)
        .then((data) => {
          toast.custom(
            (t) => (
              <Transition
                appear
                show={t.visible}
                className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
                enter="transition-all duration-150"
                enterFrom="opacity-0 translate-x-20"
                enterTo="opacity-100 translate-x-0"
                leave="transition-all duration-150"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-20"
              >
                <p className="block text-base font-semibold leading-none">
                  Added to Wishlist !
                </p>
                <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
                {renderProductCartOnNotify({ size: "" })}
              </Transition>
            ),
            { position: "top-right", id: "nc-product-notify", duration: 3000 }
          );
          // setlopen(false);
          // addToast(`${product?.productName} Added to Cart`, { appearance: "success", autoDismiss: true });
          // toast.custom(
          //   (t) => (
          //     <NotifyAddTocart
          //       productImage={`${IMAGE_SERVER_URL_DEV}${JSON.parse(images).filter((item: { thumbnail: boolean; }) => item?.thumbnail === true)[0]?.path}`}
          //       qualitySelected={1}
          //       show={t.visible}
          //       sizeSelected={""}
          //       variantActive={variantActive}
          //       selectedProductColor={""}
          //       selectedProduct={{productName:productName,unitPrice:unitprice}}
          //     />
          //   ),
          //   { position: "top-right", id: "nc-product-notify", duration: 3000 }
          // );

          UserWishList().then((res) => {
            console.log("count res-------->", res);
            setWish(res);




          });
          // this.setState({
          //   color2: "#645bff",
          //   alertClass: "success",
          //   msg: `${this.state.productData?.displayName} Added to Cart`,
          //   open_toast: true,
          // });
        })
        .catch((err) => {
          // setlopen(false);
          addToast(`${err.response.data.message}`, "warning");
          // this.setState({
          //   color2: "#645bff",
          //   alertClass: "success",
          //   msg: `${this.state.productData?.displayName} product add failed`,
          //   open_toast: true,
          // });
        });

    } else {
      DeleteWishlist(wish.filter((item: any) => item?.id === data.id)[0]?.wishlistId)
        .then((res) => {
          toast.custom(
            (t) => (
              <Transition
                appear
                show={t.visible}
                className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
                enter="transition-all duration-150"
                enterFrom="opacity-0 translate-x-20"
                enterTo="opacity-100 translate-x-0"
                leave="transition-all duration-150"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-20"
              >
                <p className="block text-base font-semibold leading-none">
                  Removed from Wishlist !
                </p>
                <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
                {renderProductCartOnNotify({ size: "" })}
              </Transition>
            ),
            { position: "top-right", id: "nc-product-notify", duration: 3000 }
          );
          UserWishList().then((res) => {
            console.log("count res-------->", res);
            setWish(res);




          });
          //  this.setState(
          //    {
          //      open_toast: true,
          //      alertClass: "error",
          //      msg: `${data.displayName} Removed`,
          //      color: "#635bff",
          //    },
          //    () => this.getAllCart()
          //  );
        })
        .catch((error) =>
          console.error("delete cart item error", error)
        );

    }






  };


  return (
    // <NavLink to={"/product-detail/16"}>
    <div

      className={`nc-CardCategory2 ${className}`}
      data-nc-id="CardCategory2"
    >
      <div
      // className={`flex-1 relative w-full h-fit rounded-2xl group`}
      >

        <div className=" bg-[#F4F4F4] rounded-xl">
          <div className="p-2">
            <div className="relative">
              <NavLink to={`/product-detail/${data.productId}`} target="_blank">
                <img className="w-full aspect-square object-fill bg-cover h-[170px] sm:h-[180px]"
                  // src={Company1} 
                  src={
                    data.images && JSON.parse(data.images).length === 1 ?

                      `${IMAGE_SERVER_URL_DEV}${JSON.parse(data.images)[0]?.path}`

                      : JSON.parse(data.images).length > 1 ?

                        `${IMAGE_SERVER_URL_DEV}${JSON.parse(data.images).filter((item: { thumbnail: boolean; }) => item?.thumbnail === true)[0]?.path}`


                        :


                        EmptyIMG
                  }
                  alt={data.productName}
                />
              </NavLink>
              {

                data.hire === 0 &&

                // <LikeButton
                //   liked={_.map(wish, "id").includes(data.id)}
                //   className="absolute bottom-2 right-2 z-10"
                //   addWish={notifyAddToWish}
                // />

                <></>


              }


            </div>
            {/* <div className="w-full object-fill bg-cover"
              style={{
                backgroundImage: `url(${Company1})`
              }}
            >

            </div> */}
          </div>

          <div className="flex justify-end w-full">
            {/* <div className="flex">
                <span className="block text-[8.2px] text-neutral-500 dark:text-neutral-400 ml-3 mt-4">


                  Category &gt; Sub Category &gt; Sub Category 2
                </span>

              </div> */}
            <div className="flex">
              {/* <i className="las la-bookmark text-4xl text-left mr-1 mt-2 "></i> */}
              {/* <img className="text-4xl text-left mr-1 mt-2 ml-2" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA00lEQVR4nO2VMU4CQRiFNzQmlhQewIYj2HgBQmPnMSw5Btew4w5WHoFslnnfm2ULCkoTG1lDgYUJgR0WtJgv+bv38iWTmX+KIvMfACZIK0PbZZBqSeN0sVR3lf7IISaLnSjdTxafTD5q58tFfk7kBZKG867mj38npK3tqe0XpK9riT8dwvM+E6UnpI+LipE2th9/5+oQHgzry4ilZQhhdDBr3xsWvYqB96qq7o7lY4xDpLdexMC8aZrbUztlWd4Ar2eJgVnbtoOuvV1n100WZ4oe+QZXC8i3JwqA3AAAAABJRU5ErkJggg==" /> */}
            </div>

          </div>
          <div className="px-3 pb-2 pt-0 -mt-4">

            <div className="mt-5">
              <NavLink to={`/product-detail/${data.productId}`} target="_blank">
                <h2 className="text-xs text-slate-900 dark:text-neutral-100 font-semibold flex justify-start text-left">
                  {/* 3M Stainless Steel Cleaner And Polish */}
                  {
                    data ? data?.productName?.slice(0, 18) : "Company description"
                  }

                  {/* {
                    data?.productName?.length >= 18 ? "...." : ""
                  } */}

                </h2>

                {/* <span className="flex items-center text-sm text-neutral-500 dark:text-neutral-400 mt-2">
                <Rating name="size-small" defaultValue={4} size="small" className="" readOnly />
                <div className="flex items-center space-x-0.5  text-yellow-500 mr-2">
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
              </div>

              </span> */}
                <div className="w-full flex justify-between items-center mt-2">

                  {
                    data.hire === 0 &&

                    <>
                      <span className="text-sm font-bold">AUD{" "}


                        {
                          data?.discount > 0 ?

                            data.discountedprice

                            :
                            data?.unitprice

                        }


                      </span>

                      <span className="text-[8pt] text-[#737373]">+ Delivery</span>
                    </>


                  }



                </div>

                <div className="w-full flex justify-between items-start mt-2 h-5">
                  {
                    data?.discount > 0 &&

                    <span className="text-xs text-red-600 line-through">AUD {data?.unitprice}</span>
                  }





                </div>

                <div className="mt-1 flex flex-col">

                  <span className="text-[8pt] text-[#5271FF]">{data?.soldcount} Sold</span>

                  {/* <span className="text-xs font-bold">Minimum Order Qty : {data?.minimumOrder}</span> */}

                  <span className="text-xs mt-0">
                    {/* Seller Company Name */}
                    {
                      data ? data?.supplierName?.slice(0, 23) : "Company description"
                    }

                    {/* {
                      data?.supplierName?.length >= 19 ? "...." : ""
                    } */}

                  </span>

                </div>

                {/* <div>
                  <span className="block mt-3 text-xs font-medium text-left">
                    Company description - See completed
                    projects and flexible talent strategies
                    from companies like yours using Fiverr
                    Business.
                  </span>
                </div> */}

              </NavLink>
            </div>



            {/* <img
            className="object-cover "
            src={featuredImage}

          /> */}
            {/* <NcImage
            containerClassName="w-full h-full flex justify-center"
            src={featuredImage}
            className="object-cover rounded-2xl"
          /> */}
          </div>
        </div>
        <div className="pt-10"></div>
        {/* <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span> */}
      </div>
      {/* <div className="mt-5 flex-1 text-center">
        <h2 className="text-base sm:text-base text-neutral-900 dark:text-neutral-100 font-semibold">
          {name}
        </h2>
        <span className="block mt-0.5 sm:mt-1.5 text-sm text-neutral-500 dark:text-neutral-400">
          {desc}
        </span>
      </div> */}
    </div>
    // </NavLink>
  );
};

export default CardCategory2;
