import React, { FC, useState, useEffect, useRef } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL } from "Constant/System";


const ShippingFormular = () => {

    const [images, setImages] = useState([]);

    return (
        <>
            {/* <div className="flex justify-center w-full mt-2">

                <span className="block text-xl font-bold flex justify-center w-full text-center">
                    Add Shipping Formular
                </span>


            </div> */}
            <div className="w-full flex justify-center px-3">

                <div className="w-full md:w-11/12 lg:w-8/12">

                    <div className="w-full mt-6 space-y-4">
                        <div>
                            <Label className="text-sm">Formular Type</Label>
                            <Select className="mt-1.5"
                            // onChange={(e) => {
                            //     setcountry(e.target.value);
                            //     seterrcountry(false);
                            // }}
                            // value={country}
                            >
                                <option value={""}>Select a Formular type</option>
                                <option value="Stripe">Stripe</option>
                                {/* <option value="United States">Canada</option>
                    <option value="United States">Mexico</option>
                    <option value="United States">Israel</option>
                    <option value="United States">France</option>
                    <option value="United States">England</option>
                    <option value="United States">Laos</option>
                    <option value="United States">China</option> */}
                            </Select>
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">Value</Label>
                            <Input className="mt-1.5"
                            // onChange={(e) => {
                            //     setlasttName(e.target.value);
                            //     seterrlasttName(false);
                            // }}
                            // value={lastName}
                            />
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>


                        <div>
                            <Label className="text-sm">Amount</Label>
                            <div className="w-full flex">
                                <Select className="mt-1.5 w-4/12" rounded="rounded-l-lg"
                                // onChange={(e) => {
                                //     setcountry(e.target.value);
                                //     seterrcountry(false);
                                // }}
                                // value={country}
                                >
                                    <option value={""}>Currency</option>
                                    <option value="AUD">AUD</option>

                                </Select>
                                <Input className="mt-1.5" rounded="rounded-r-lg"
                                // onChange={(e) => {
                                //     setlasttName(e.target.value);
                                //     seterrlasttName(false);
                                // }}
                                // value={lastName}
                                />

                            </div>

                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>



                        <div className="flex w-full items-center justify-center">
                            <button
                                className={
                                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-4 `}


                            >
                                {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                                <span className="block  text-sm font-medium "
                                >
                                    Add
                                </span>

                            </button>
                        </div>



                    </div>
                    <div className="mt-10 space-y-4">

                        <div className="flex items-center">

                            <span className="text-sm mr-11">Between</span>
                            <div className="w-8/12 flex items-center space-x-4">
                                <div className="w-full flex space-x-4">

                                    <input className="text-sm flex justify-center bg-gray-100 w-full py-2 px-2 text-center border border-transparent focus:border-transparent focus:ring-0"
                                        type="number"
                                        value={0}
                                    />

                                    <input className="text-sm flex justify-center bg-gray-100 w-full py-2 px-2 text-center border border-transparent focus:border-transparent focus:ring-0"
                                        type="number"
                                        value={50}
                                    />

                                    <input className="text-sm flex justify-center bg-gray-100 w-full py-2 px-2 text-center border border-transparent focus:border-transparent focus:ring-0"
                                        type="text"
                                        value={"$ 15"}
                                    />

                                    <i className="las la-pencil-alt text-2xl text-left mr-2 cusrsor-pointer mt-1"></i>
                                </div>





                            </div>

                        </div>


                        <div className="flex items-center">

                            <span className="text-sm mr-11">Between</span>
                            <div className="w-8/12 flex items-center space-x-4">
                                <div className="w-full flex space-x-4">

                                    <input className="text-sm flex justify-center bg-gray-100 w-full py-2 px-2 text-center border border-transparent focus:border-transparent focus:ring-0"
                                        type="number"
                                        value={0}
                                    />

                                    <input className="text-sm flex justify-center bg-gray-100 w-full py-2 px-2 text-center border border-transparent focus:border-transparent focus:ring-0"
                                        type="number"
                                        value={50}
                                    />

                                    <input className="text-sm flex justify-center bg-gray-100 w-full py-2 px-2 text-center border border-transparent focus:border-transparent focus:ring-0"
                                        type="text"
                                        value={"$ 15"}
                                    />

                                    <i className="las la-pencil-alt text-2xl text-left mr-2 cusrsor-pointer mt-1"></i>
                                </div>





                            </div>

                        </div>

                        <div className="flex items-center">

                            <span className="text-sm mr-6">Greater than</span>
                            <div className="w-8/12 flex items-center space-x-4">

                                <div className="w-full flex space-x-4">

                                    <input className="text-sm flex justify-center bg-gray-100 w-full py-2 px-2 text-center border border-transparent focus:border-transparent focus:ring-0"
                                        type="number"
                                        value={0}
                                    />

                                    <input className="text-sm flex justify-center bg-gray-100 w-[45%] py-2 px-2 text-center border border-transparent focus:border-transparent focus:ring-0"
                                        type="text"
                                        value={"$ 15"}
                                    />



                                    <i className="las la-pencil-alt text-2xl text-left mr-2 cusrsor-pointer mt-1"></i>
                                </div>



                                {/* <div className="bg-gray-100 py-2 px-6 w-[38%]">
                                    <span className="text-sm flex justify-center">$ 15</span>

                                </div>

                                <i className="las la-pencil-alt text-2xl text-left mr-2 cusrsor-pointer"></i> */}

                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </>
    )

}

export default ShippingFormular;