import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link, NavLink } from "react-router-dom";
import ButtonCircle from "shared/Button/ButtonCircle";
import Company1 from "images/avatars/Image-1.png";
import { StarIcon } from "@heroicons/react/24/solid";
import Rating from '@mui/material/Rating';
import { IMAGE_SERVER_URL_DEV } from "Constant/System";

import demCover from "images/cover/demo-1.jpg";
import demAvatar from "images/cover/demo-2.jpg";

export interface CardCategory2Props {
  className?: string;
  ratioClass?: string;
  bgClass?: string;
  featuredImage?: string;
  name?: string;
  desc?: string;
  collection?: any;
  data?: any;
}

const CardCategory2: FC<CardCategory2Props> = ({
  className = "",
  ratioClass = "aspect-w-1 aspect-h-1",
  bgClass = "bg-orange-50",
  featuredImage = ".",
  name,
  desc,
  collection,
  data,
}) => {
  return (
    <NavLink to={data ? `/profile-view/${data?.id}` : "/profile"}>
      <div

        className={`nc-CardCategory2 ${className} `}
        data-nc-id="CardCategory2"
      >
        <div
        // className={`flex-1 relative w-full h-fit rounded-2xl group`}
        >
          <div className={`${collection ? "p-6" : "p-2 lg:p-5"} shadow-[0px_5px_10px_5px_rgba(0,0,0,0.3)] shadow-slate-200 rounded-2xl`}>
            <div className="w-full" >
              <img className="w-14 h-14 aspect-square object-fill bg-cover rounded-md"
                // src={Company1}
                src={data?.avatar ? `${IMAGE_SERVER_URL_DEV}${data?.avatar}` : demAvatar}
              />
              {/* <div className="w-14 h-14 object-fill bg-contain rounded-md"
                style={{
                  backgroundImage: `url(${Company1})`
                }}
              >

              </div> */}
              {/* <ButtonCircle
              // className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
              size=" w-11 h-11"
              type="submit"
            >
              <i className="las la-apple-alt text-4xl text-left"></i>


            </ButtonCircle> */}



              {/* <div className={`${collection ? "ml-3" : "ml-6"} -mt-1`}> */}
              <div className={`mt-2 lg:mt-10 w-full`}>
                <NavLink to={data ? `/profile-view/${data?.id}` : "/profile"}>
                  <h2 className="text-sm text-neutral-900 dark:text-neutral-100 font-semibold sm:whitespace-nowrap h-10 lg:h-6">

                    {
                      data ? data?.businessName?.slice(0, 12) : "Company description"
                    }

                    {/* {
                      data?.businessName?.length >= 17 ? "...." : ""
                    } */}

                    {/* {
                      data ? data?.businessName : "Company Name"
                    } */}


                  </h2>
                </NavLink>
                <div className="flex justify-between w-full">
                  {/* <span className="text-xs text-neutral-500 dark:text-neutral-400 mt-1">
                    <i className="las la-map-marker text-sm text-left mr-2"></i>
                    {
                      data ? data?.country : "Country"
                    }
                  </span> */}

                  <Rating name="size-small" defaultValue={data ? data?.rank : 1} size="small" className="mt-0.5" readOnly />

                  {/* <div className="flex items-center space-x-0.5 mt-1.5 text-yellow-500">
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
              </div> */}

                </div>

              </div>

            </div>
            <div className={`flex mt-2 lg:mt-6  ${data?.about === "" ? "h-12" : "h-6 lg:h-12"}`}>
              <div>
                <span className="block  text-[11.5px] font-medium text-slate-900 leading-normal font-normal">
                  {
                    data ? data?.about?.slice(0, 40) : "Company description - See completed projects and flexible talent strategies from ......."
                  }
                  {/* Company description - See completed projects and flexible talent strategies from companies like yours using Fiverr Business. */}
                  {
                    data?.about?.length >= 40 ? "..." : ""
                  }
                </span>
              </div>


            </div>

            <div className="mt-6">



              <button
                className={
                  `ttnc-ButtonCircle rounded-md text-slate-900 flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-gray-100 
        w-full h-9 font-medium`}

              >
                <span className="block  text-xs font-medium">
                  Total Sales &ensp;&ensp;{data?.sellerSoldCount ? data?.sellerSoldCount : 0}
                </span>

              </button>



              <button
                className={
                  `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-2 cursor-pointer`}

                onClick={() => {
                  console.log(">>>>>", "hello")
                }}

              >
                <i className="lab la-telegram-plane text-xl text-left mr-2"></i>
                <span className="block  text-xs font-medium">
                  Shop Now
                </span>

              </button>



            </div>

            {/* <img
            className="object-cover "
            src={featuredImage}

          /> */}
            {/* <NcImage
            containerClassName="w-full h-full flex justify-center"
            src={featuredImage}
            className="object-cover rounded-2xl"
          /> */}
          </div>
          <div className="pt-5"></div>
          {/* <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span> */}
        </div>
        {/* <div className="mt-5 flex-1 text-center">
        <h2 className="text-base sm:text-base text-neutral-900 dark:text-neutral-100 font-semibold">
          {name}
        </h2>
        <span className="block mt-0.5 sm:mt-1.5 text-sm text-neutral-500 dark:text-neutral-400">
          {desc}
        </span>
      </div> */}
      </div>
    </NavLink>
  );
};

export default CardCategory2;
