import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link, NavLink } from "react-router-dom";
import ButtonCircle from "shared/Button/ButtonCircle";
import Company1 from "images/avatars/Image-1.png";
import { StarIcon } from "@heroicons/react/24/solid";
import Rating from '@mui/material/Rating';

export interface CardCategory2Props {
  className?: string;
  ratioClass?: string;
  bgClass?: string;
  featuredImage?: string;
  name?: string;
  desc?: string;
}

const CardCategory2: FC<CardCategory2Props> = ({
  className = "",
  ratioClass = "aspect-w-1 aspect-h-1",
  bgClass = "bg-orange-50",
  featuredImage = ".",
  name,
  desc,
}) => {
  return (
    <NavLink to={"/product-detail/16"}>
      <div

        className={`nc-CardCategory2 ${className}`}
        data-nc-id="CardCategory2"
      >
        <div
        // className={`flex-1 relative w-full h-fit rounded-2xl group`}
        >

          <div className="shadow-[0px_10px_20px_10px_rgba(0,0,0,0.3)] shadow-slate-200 rounded-md">
            <div className="p-2">
              <img className="w-full aspect-square object-fill bg-cover" src={Company1} />
              {/* <div className="w-full object-fill bg-cover"
              style={{
                backgroundImage: `url(${Company1})`
              }}
            >

            </div> */}
            </div>

            <div className="flex justify-end w-full">
              {/* <div className="flex">
                <span className="block text-[8.2px] text-neutral-500 dark:text-neutral-400 ml-3 mt-4">


                  Category &gt; Sub Category &gt; Sub Category 2
                </span>

              </div> */}
              <div className="flex">
                {/* <i className="las la-bookmark text-4xl text-left mr-1 mt-2 "></i> */}
                <img className="text-4xl text-left mr-1 mt-2 ml-2" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA00lEQVR4nO2VMU4CQRiFNzQmlhQewIYj2HgBQmPnMSw5Btew4w5WHoFslnnfm2ULCkoTG1lDgYUJgR0WtJgv+bv38iWTmX+KIvMfACZIK0PbZZBqSeN0sVR3lf7IISaLnSjdTxafTD5q58tFfk7kBZKG867mj38npK3tqe0XpK9riT8dwvM+E6UnpI+LipE2th9/5+oQHgzry4ilZQhhdDBr3xsWvYqB96qq7o7lY4xDpLdexMC8aZrbUztlWd4Ar2eJgVnbtoOuvV1n100WZ4oe+QZXC8i3JwqA3AAAAABJRU5ErkJggg==" />
              </div>

            </div>
            <div className="px-7 pb-2 pt-0 -mt-4">

              <div className="mt-4">
                <NavLink to={"/product-detail/16"}>
                  <h2 className="text-sm sm:text-sm text-slate-900 dark:text-neutral-100 font-semibold flex justify-cente text-center">
                    Ad Title should be here
                  </h2>
                </NavLink>
                {/* <span className="flex items-center text-sm text-neutral-500 dark:text-neutral-400 mt-2">
                <Rating name="size-small" defaultValue={4} size="small" className="" readOnly />
                <div className="flex items-center space-x-0.5  text-yellow-500 mr-2">
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
              </div>

              </span> */}

                <div>
                  <span className="block mt-3 text-xs font-medium text-center">
                    Company description - See completed
                    projects and flexible talent strategies
                    from companies like yours using Fiverr
                    Business.
                  </span>
                </div>


              </div>

              <div className="mt-4">



                <button
                  className={
                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-gray-200 
        w-full h-9 font-medium`}

                >
                  <span className="block  text-xs font-bold">
                    $ 250/Hourly
                  </span>

                </button>



                <button
                  className={
                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-gradient-to-r from-cyan-500 from-10% to-[#3c9140] to-90% hover:from-pink-500 hover:to-yellow-500 
        w-full h-9 text-slate-50 font-medium mt-2 cursor-pointer`}

                  onClick={() => {
                    console.log(">>>>>", "hello")
                  }}

                >
                  {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
                  <span className="block  text-xs font-medium">
                    View details
                  </span>

                </button>

                <div className="flex justify-between text-[10px] mt-4 w-full">
                  <span className="text-slate-900 flex items-center">
                    <i className="las la-eye text-lg text-left mr-1"></i>
                    3,456
                  </span>

                  <span className="ml-2 text-slate-900 flex items-center">
                    <i className="las la-map-marker-alt text-lg text-left mr-1"></i>
                    Country, State
                  </span>
                </div>


              </div>

              {/* <img
            className="object-cover "
            src={featuredImage}

          /> */}
              {/* <NcImage
            containerClassName="w-full h-full flex justify-center"
            src={featuredImage}
            className="object-cover rounded-2xl"
          /> */}
            </div>
          </div>
          <div className="pt-10"></div>
          {/* <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span> */}
        </div>
        {/* <div className="mt-5 flex-1 text-center">
        <h2 className="text-base sm:text-base text-neutral-900 dark:text-neutral-100 font-semibold">
          {name}
        </h2>
        <span className="block mt-0.5 sm:mt-1.5 text-sm text-neutral-500 dark:text-neutral-400">
          {desc}
        </span>
      </div> */}
      </div>
    </NavLink>
  );
};

export default CardCategory2;
