import React, {
  FC,
  useState,
  useEffect,
  useRef,
  Fragment,
  useContext
} from "react";
import Stripe from "images/sLogo.png";
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import {
  IMAGE_SERVER_URL,
  IMAGE_SERVER_URL_DEV,
  IMAGE_SERVER_URL_DEV2
} from "Constant/System";
import _, { lowerCase, map, upperFirst } from "lodash";
import { postAds, postJobs } from "Services/API/Post";
import { addToast } from "shared/Toast";
import {
  CurrentUser,
  GetUserId,
  getCities,
  getDistricts,
  getJobDetails,
  getOrderDetails,
  getProfiles,
  getTrainerReviewsById,
  getUserIp,
  getUserLocation,
  getWriterReviewsById
} from "Services/API/Get";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Box,
  Rating
} from "@mui/material";
import { UpdateAds, UpdateJobs, UpdatePackage } from "Services/API/Put";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import moment from "moment";
import SubData from "data/australian_postcodes.json";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoadingSpinner from "shared/LoadingSpinner";
import DatePicker from "react-multi-date-picker";
import BotToast from "shared/BotToast";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import JobsCard from "components/CardCategories/JobsCard";
import { t } from "i18next";

import { Dialog, Popover, Transition } from "@headlessui/react";
import Checkbox from "shared/Checkbox/Checkbox";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import WishContext from "Context/WishContext";

import Avatar from "images/avatars/Image-2.png";
import ModalQuickView from "components/ModalReview";
import ModalQuote from "components/ModalQuote";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d867b"
    },
    secondary: {
      main: "#2d867b"
    }
  }
});

const CATEG = [
  {
    value: "Seasonal",
    name: "Seasonal"
  },
  {
    value: "Casual",
    name: "Casual"
  },
  {
    value: "Temporary",
    name: "Temporary"
  },
  // {
  //     value: "Fixed",
  //     name: "Fixed"
  // },
  {
    value: "Part Time",
    name: "Permanent – Part Time"
  },
  {
    value: "Full Time",
    name: "Permanent – Full time"
  }
];

const Jobs = ({ selectedJob = null, onCloseModalQuickView }) => {
  const { lan, setLan } = useContext(WishContext);

  const [images, setImages] = useState([]);

  const [title, settitle] = useState(
    selectedJob
      ? selectedJob?.language === "En"
        ? "English"
        : selectedJob?.language === "Si"
        ? "Sinhala"
        : ""
      : ""
  );
  const [jobType, setjobType] = useState(
    selectedJob ? selectedJob?.jobType : ""
  );
  const [country, setcountry] = useState(
    selectedJob ? selectedJob?.country : "Australia"
  );
  const [homeDistrict, sethomeDistrict] = useState(
    selectedJob ? selectedJob?.state : ""
  );
  const [homeCity, sethomeCity] = useState(
    selectedJob ? selectedJob?.suburb : ""
  );
  const [duration, setduration] = useState(
    selectedJob ? selectedJob?.duration : ""
  );
  const [description, setdescription] = useState(
    selectedJob ? selectedJob?.description : ""
  );
  const [currency, setcurrency] = useState(
    selectedJob ? selectedJob?.currency : "AUD"
  );
  const [rate, setrate] = useState(selectedJob ? selectedJob?.hourlyRate : "");

  let day = moment().add(365, "days");
  day = moment(day).format("YYYY-MM-DD");

  console.log(">>>>>>>day", moment(day).toDate());
  // setexpire(moment(day).toDate());

  const [expire, setexpire] = useState(
    selectedJob
      ? moment(selectedJob?.expiryDate).toDate()
      : moment(day).toDate()
  );

  const [expireVisibility, setexpireVisibility] = useState(
    selectedJob?.expireVisibility
      ? moment(selectedJob?.expireVisibility).toDate()
      : ""
  );

  const [Keywords, setKeywords] = useState("");

  const [oldPkg, setoldPkg] = useState(
    selectedJob ? selectedJob?.packageId : ""
  );

  const [errimages, seterrImages] = useState([]);

  const [errtitle, seterrtitle] = useState(false);
  const [errjobType, seterrjobType] = useState(false);
  const [errcountry, seterrcountry] = useState(false);
  const [errsuburb, seterrsuburb] = useState(false);
  const [errduration, seterrduration] = useState(false);
  const [errdescription, seterrdescription] = useState(false);
  const [errcurrency, seterrcurrency] = useState(false);
  const [errrate, seterrrate] = useState(false);
  const [errexpire, seterrexpire] = useState(false);
  const [errKeywords, seterrKeywords] = useState(false);
  const [errhomeDistrict, seterrhomeDistrict] = useState(false);
  const [errhomeCity, seterrhomeCity] = useState(false);

  const [homeDistrictId, sethomeDistrictId] = useState(0);
  const [allHCities, setallHCities] = useState([]);
  const [allHDistricts, setallHDistricts] = useState([]);

  const [currUser, setcurrUser] = useState("");

  const [packageId, setpackageId] = useState(
    selectedJob ? selectedJob?.packageId : "1"
  );

  const [lOpen, setlOpen] = useState(false);

  const [errpackages, seterrpackages] = useState(false);

  const history = useHistory();

  const [newSubArray, setnewSubArray] = useState([]);
  const [newSubs, setnewSubs] = useState([]);

  const [bOpen, setbOpen] = useState(false);
  const [bMsg, setbMsg] = useState("");
  const [bStatus, setbStatus] = useState("");

  const ref = useRef(null);

  const [editorData, setEditorData] = useState(
    selectedJob?.duties ? selectedJob?.duties : "<br/><br/><br/><br/><br/>"
  );

  const [newSubs2, setnewSubs2] = useState([]);

  const [profiles, setprofiles] = useState([]);
  const [selectedProfile, setselectedProfile] = useState(
    selectedJob ? { id: selectedJob?.memberId, name: selectedJob?.title } : ""
  );

  const handleScroll = () => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const [adDetails, setadDetails] = useState("");

  const [ipCountry, setipCountry] = useState("Other");

  const [selectedTab, setselectedTab] = useState(1);

  const [modal1, setmodal1] = useState(false);

  const [avatar, setavatar] = useState("");

  const [proTab, setproTab] = useState(1);

  const [showModalQuickView, setShowModalQuickView] = useState(false);
  const [showModalQuickView2, setShowModalQuickView2] = useState(false);

  const [reviews, setreviews] = useState([]);

  // const [images, setImages] = useState([]);

  const [qType, setqType] = useState("job");

  useEffect(() => {
    if (!selectedJob) settitle(lan);
  }, [lan, selectedJob]);

  React.useEffect(() => {
    let newSubArray = JSON.parse(JSON.stringify(SubData));

    newSubArray = newSubArray?.map((d) => {
      if (d.type === "Delivery Area") {
        return {
          ...d,
          locality: upperFirst(lowerCase(d.locality))
        };
      }
    });

    newSubArray = _.compact(newSubArray);

    setnewSubArray(_.orderBy(newSubArray, ["locality"], ["asc"]));

    console.log(
      ">>>>>>>subs",
      newSubArray.filter((u) => u.state === 0)
    );
  }, []);

  const packageSelect = (item) => {
    console.log("pkg>>>>", item);

    let obj = {};

    if (item === "1") {
      obj = {
        email: currUser?.email,
        amountPayable: "FREE",
        packageDetails: [
          {
            packagePrice: "FREE",
            qty: 1,
            packageName: "FREE" + " " + "Subscription",
            days: 7
          }
        ],
        packageId: 1,
        userId: currUser?.id
      };
    } else if (item === "2") {
      obj = {
        email: currUser?.email,
        amountPayable: "15",
        packageDetails: [
          {
            packagePrice: "15",
            qty: 1,
            packageName: "Basic" + " " + "Subscription",
            days: 28
          }
        ],
        packageId: 2,
        userId: currUser?.id
      };
    } else if (item === "3") {
      obj = {
        email: currUser?.email,
        amountPayable: "30",
        packageDetails: [
          {
            packagePrice: "30",
            qty: 1,
            packageName: "Plus" + " " + "Subscription",
            days: 84
          }
        ],
        packageId: 2,
        userId: currUser?.id
      };
    }

    // let obj = {
    //     email: currUser?.email,
    //     amountPayable: item.pricing.split("$")[1],
    //     packageDetails: [
    //         {
    //             packagePrice: item.pricing.split("$")[1],
    //             qty: 1,
    //             packageName: item.name + " " + "Subscription",
    //         }
    //     ],
    //     packageId: item.id,
    //     userId: currUser?.id,
    // }

    console.log("obj>>>", obj);

    setlOpen(true);

    UpdatePackage(obj, currUser?.id)
      .then((res) => {
        console.log("res>>>>", res.data);

        if (res.data !== undefined) {
          if (res.data === "success") {
            history.push("/profile");
          } else {
            window.location.href = res.data;
          }
        }
      })
      .catch((error) => {
        setlOpen(false);
        console.log("error", error);
        console.log("current user error", error.response.data.message);
        if (error.response.data.statusCode === 401) {
          history.push("/login");
        }
      });
  };

  // React.useEffect(() => {

  //     if (packageId) {
  //         packageSelect(packageId);
  //     }

  // }, [packageId]);

  React.useEffect(() => {
    listDistricts();
    getCurrentUser();
    // listProfiles();
  }, []);

  React.useEffect(() => {
    listProfiles();
  }, [selectedJob]);

  React.useEffect(() => {
    if (homeDistrictId !== 0) {
      listHomecities();
    }
  }, [homeDistrictId]);

  useEffect(() => {
    getUserIp()
      .then((res) => {
        console.log(">>>>>>ip", res);

        getUserLocation(res?.ip)
          .then((res) => {
            console.log(">>>>>>ip location", res);

            if (res?.data.country_name === "Sri Lanka") {
              setipCountry("Sri Lanka");
            } else {
              setipCountry(res?.data.country_name);
            }
          })
          .catch((error) => {
            // setprofiles([]);
            console.log("Error", error.response);
          });
      })
      .catch((error) => {
        // setprofiles([]);
        console.log("Error", error.response);
      });
  }, []);

  const listProfiles = () => {
    getProfiles({
      jobType: JSON.stringify([])
      // title: search,
      // jobType:JSON.stringify([])
    })
      .then((res) => {
        console.log(">>>>>>>jobs", res.data);

        const subArr = res.data.result?.map((d, key) => {
          return {
            ...d,
            key: key
            // id: d.state_code
          };
        });

        setprofiles(subArr);

        if (selectedJob) {
          setselectedProfile(
            res.data.result.find((v) => v.id === selectedProfile?.id)
          );
        }
      })
      .catch((error) => {
        // setlopen(false);
        setprofiles([]);
        console.log("Error", error.response);
        // addToast(error.response.data.message, "warning");
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const listDistricts = () => {
    getDistricts()
      .then((res) => {
        console.log("districts>>>", res);
        setallHDistricts(res.data);
        // setallODistricts(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };
  const listHomecities = () => {
    getCities(homeDistrict)
      .then((res) => {
        console.log("cities>>>", res.data.records);
        setallHCities(res.data.records);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setallHCities([]);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const handleChangeHdist = (e, value) => {
    if (value !== null) {
      sethomeDistrict(value.name_en);
      seterrhomeDistrict(false);
      sethomeDistrictId(value.id);
    } else {
      sethomeDistrict("");
      sethomeDistrictId(1);
    }

    console.log("state>>", value);
    // setnewSubs(newSubArray.filter((u) => u.state === value.id))

    setnewSubs([]);

    const filtered = newSubArray.filter((u) => u.state === value.id);

    const perChunk = 700;

    const result = filtered.reduce((filtered, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!filtered[chunkIndex]) {
        filtered[chunkIndex] = []; // start a new chunk
      }

      filtered[chunkIndex].push(item);

      return filtered;
    }, []);

    console.log("<<<<<<<", result);

    for (let i = 0; i < result.length - 1; i++) {
      let item = result[i];

      setTimeout(
        () => {
          setnewSubs((existing) => [...existing, ...item]);
        },
        i === 0 ? 0 : 2000
      );
    }
  };
  const handleChangeHcity = (e, value) => {
    if (value !== null) {
      // console.log(">><<", value.fields.scc_name)
      sethomeCity(value);
      // sethomepostCode(value.postcode);
      // sethomeCity(value.fields.scc_name);
      // sethomepostCode(value.postcode);
      seterrhomeCity(false);
      // seterrhomepostCode(false);
    } else {
      sethomeCity("");
      // sethomepostCode("");
    }

    console.log("city>>", value);
  };

  const saveJob = async (e) => {
    e.preventDefault();

    // if (!description) {
    //     seterrdescription(true);
    // }

    if (!selectedProfile) {
      seterrjobType(true);
    }
    if (!packageId) {
      seterrpackages(true);
    }

    let obj2 = {};

    let item = packageId;

    if (item === "1") {
      obj2 = {
        email: currUser?.email,
        amountPayable: "FREE",
        packageDetails: [
          {
            packagePrice: "FREE",
            qty: 1,
            packageName: "FREE" + " " + "Subscription",
            days: 365
          }
        ],
        packageId: 1,
        userId: currUser?.id
      };
    } else if (item === "2") {
      obj2 = {
        email: currUser?.email,
        amountPayable: "50",
        packageDetails: [
          {
            packagePrice: ipCountry === "Sri Lanka" ? "1.63" : "50",
            qty: 1,
            packageName: "Basic" + " " + "Subscription",
            days: 90
          }
        ],
        packageId: 2,
        userId: currUser?.id
      };
    } else if (item === "3") {
      obj2 = {
        email: currUser?.email,
        amountPayable: "85",
        packageDetails: [
          {
            packagePrice: ipCountry === "Sri Lanka" ? "2.93" : "85",
            qty: 1,
            packageName: "Plus" + " " + "Subscription",
            days: 180
          }
        ],
        packageId: 3,
        userId: currUser?.id
      };
    }

    let orderMyNo = 100000;

    await getJobDetails()
      .then((res) => {
        orderMyNo = parseInt(res.data[res.data.length - 1]?.adId) + 1;
      })
      .catch((error) => {
        console.error("order history error", error.response);
      });

    let obj = {
      adId: !selectedJob ? orderMyNo : selectedJob?.id,
      title: selectedProfile?.name,
      userId: GetUserId(),
      memberId: selectedProfile?.id,
      description: description,
      duties: editorData,
      createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
      email: currUser?.email,
      packageUpdatedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      packageDetails: obj2,
      oldPkg: oldPkg,
      expiryDate: moment(expire).format("YYYY-MM-DD"),
      language: title === "English" ? "En" : title === "Sinhala" ? "Si" : "",
      expireVisibility: expireVisibility
        ? moment(expireVisibility).format("YYYY-MM-DD")
        : ""
    };

    obj = { ...selectedProfile, ...obj };

    console.log("obj>>>>", obj);

    if (packageId && selectedProfile) {
      if (!selectedJob) {
        setlOpen(true);
        postAds(obj)
          .then((res) => {
            setlOpen(false);
            if (res.data.includes("stripe")) {
              window.location.href = res.data;
              return;
            }
            // setlOpen(false);

            setbOpen(true);
            setbMsg(t("adSucc"));
            setbStatus("success");

            // addToast("Job successfully added", "success");
            settitle("");
            setjobType("");
            setcountry("");
            setdescription("");
            sethomeDistrict("");
            sethomeCity("");
            setduration("");
            setrate("");
            setImages([]);
            setKeywords("");
            setexpire("");
            setcurrency("");
            setEditorData("<br/><br/><br/><br/><br/>");
            setselectedProfile("");
            // setOpenToast(true);
            // setColor(COLORS.Primary);
            // setAlertClass("success");
            // setMsg("Successfully updated");
            // getCurrentUser();
            // seteDisable(true);
            // getUser();

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            setlOpen(false);
            console.log("Error", error.response);
            setbOpen(true);
            setbMsg(error.response.data.message);
            setbStatus("warning");

            // addToast(error.response.data.message, "warning");
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          });
      } else {
        setlOpen(true);
        UpdateAds(obj, selectedJob?.id)
          .then((res) => {
            setlOpen(false);
            if (res.data.includes("stripe")) {
              window.location.href = res.data;
              return;
            } else {
              setbOpen(true);
              setbMsg("Ad successfully updated ");
              setbStatus("success");
            }

            // addToast("Job successfully updated ", "success");
            settitle("");
            setjobType("");
            setcountry("");
            setdescription("");
            sethomeDistrict("");
            sethomeCity("");
            setduration("");
            setrate("");
            setImages([]);
            setKeywords("");
            setexpire("");
            setcurrency("");
            onCloseModalQuickView();
            // setOpenToast(true);
            // setColor(COLORS.Primary);
            // setAlertClass("success");
            // setMsg("Successfully updated");
            // getCurrentUser();
            // seteDisable(true);
            // getUser();

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            setlOpen(false);
            console.log("Error", error.response);
            setbOpen(true);
            setbMsg(error.response.data.message);
            setbStatus("warning");

            // addToast(error.response.data.message, "warning");
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          });
      }
    } else {
      // handleScroll();
      setbOpen(true);
      setbMsg("Required fields are missing and add missing fields.");
      setbStatus("warning");
    }
  };

  const getCurrentUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          // setUser(cData);
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber
          };
          //   this.setState({
          //     firstName: cData?.firstName,
          //     lastName: cData?.lastName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });
          console.log("<><><><>", cData);
          setcurrUser(cData);

          // setpackageId(cData.packageId ? cData.packageId : "");

          // if (!selectedJob) {

          //     if (cData.packageId === "1") {

          //         let day = moment().add(7, "days")
          //         day = moment(day).format("YYYY-MM-DD")

          //         console.log(">>>>>>>", moment(day).toDate())
          //         setexpire(moment(day).toDate());

          //     } if (cData.packageId === "2") {

          //         let day = moment().add(28, "days")
          //         day = moment(day).format("YYYY-MM-DD")

          //         console.log(">>>>>>>", moment(day).toDate())
          //         setexpire(moment(day).toDate());

          //     } else if (cData.packageId === "3") {

          //         let day = moment().add(84, "days")
          //         day = moment(day).format("YYYY-MM-DD")

          //         console.log(">>>>>>>", moment(day).toDate())
          //         setexpire(moment(day).toDate());

          //     }
          // }

          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };

  function CustomInput({ onFocus, value, onChange }) {
    return (
      <div className="mt-1.5 flex">
        <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
          <i className="text-xl las la-calendar"></i>
        </span>
        <Input
          readOnly
          className="!rounded-l-none"
          placeholder="DD-MM-YYYY"
          onFocus={onFocus}
          value={value}
          onChange={onChange}
        />
      </div>
    );
  }

  useEffect(() => {
    if (bOpen === true) {
      setTimeout(() => {
        setbOpen(false);
      }, 10000);
    }
  }, [bOpen]);

  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const listReviews = () => {
    getTrainerReviewsById(selectedJob?.id)
      .then((res) => {
        console.log(">>>>>>>reviews", res.data);
        setreviews(res.data);
      })
      .catch((error) => {
        // setlopen(false);
        setreviews([]);
        console.log("reviews Error", error.response);
        // addToast(error.response.data.message, "warning");
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  useEffect(() => {
    listReviews();
  }, [selectedJob, showModalQuickView]);

  const [expandedQualificationIndex, setExpandedQualificationIndex] = useState(null);

const handleExpandQualification = (index) => {
  setExpandedQualificationIndex(index === expandedQualificationIndex ? null : index);
};

const [expandedProgramIndex, setExpandedProgramIndex] = useState(null);

const handleExpandProgram = (index) => {
  setExpandedProgramIndex(index === expandedProgramIndex ? null : index);
};

  return (
    <>
      <ModalQuote
        show={showModalQuickView2}
        onCloseModalQuickView={() => setShowModalQuickView2(false)}
        jobId={selectedJob}
        type={qType}
        // setselectedAdds={setselectedAdds}
      />

      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
        type={"trainer"}
        proId={selectedJob?.id}
      />

      <LoadingSpinner open={lOpen} />
      <div className="justify-center w-full mt-2">
        {!selectedJob && (
          <div className="flex justify-center">
            {/* <span className="text-xs font-normal flex justify-center w-full md:w-9/12 lg:w-[45%] text-center mt-1 mb-6">

                            Are you looking to hire talent for your company? You can post your
                            job vacancies through this page.

                        </span> */}
          </div>
        )}
      </div>
      <div className="w-full flex justify-center mb-0">
        <div className="w-full md:w-11/12 lg:w-12/12">
          {/* <span className="block text-xl font-bold flex justify-start w-full text-left text-[#003D35]">

                        {selectedJob ? "Edit Ad" : t("adButton")}
                    </span> */}

          <div className="col-span-12">
            {/* BEGIN: Display Information */}
            <div className="intro-y box lg:mt-5 py-8 w-full">
              <div className="flex border-b border-slate-200/60 dark:border-darkmode-400 w-full pb-10">
                {/* <h2 className="font-medium text-sm mr-auto">
                Personal Information
              </h2> */}
                <div className="items-center ">
                  <div className="w-20 h-20 sm:w-32 sm:h-32 image-fit">
                    <img
                      alt="Avatar"
                      className="rounded-full"
                      // src={Avatar}
                      src={
                        JSON.parse(selectedJob?.avatar).length > 0
                          ? `${IMAGE_SERVER_URL_DEV2}${
                              JSON.parse(selectedJob?.avatar)[0].path
                            }`
                          : Avatar
                      }
                    />
                  </div>
                </div>
                <div className="ml-6 items-center">
                  <h2 className="font-bold text-base mr-auto text-primaryF">
                  {selectedJob?.firstMiddleName} {selectedJob?.surName}
                
                  </h2>
                  <h3 className="font-normal text-sm mr-auto text-secondaryF">
                    {/* Senior Software Engineer */}
                    {selectedJob?.type}
                  </h3>
                  <h4 className="hidden sm:flex font-medium text-sm mr-auto text-redF mt-2 whitespace-nowrap">
                    <span> {selectedJob?.experience}+ Years Experience</span>{" "}
                    <span className="mx-2">|</span>{" "}
                    <span className="">{selectedJob?.district}</span>
                  </h4>

                  <div className="sm:hidden">
                    <h4 className="font-medium text-sm mr-auto text-redF mt-2 whitespace-nowrap">
                      <span> 10+ Years Experience</span>
                    </h4>

                    <h4 className="font-medium text-sm mr-auto text-redF mt-2 whitespace-nowrap">
                      <span className="">{selectedJob?.district}</span>
                    </h4>
                  </div>

                  <div className="text-primaryF font-medium border border-primaryF w-fit px-4 py-2 rounded-full mt-3 text-sm">
                    10000 Views
                  </div>
                </div>
                {false && (
                  <div className="flex justify-end w-full items-start">
                    <div
                      className="flex items-center text-white font-medium bg-blueF w-fit pl-3 pr-7 py-2 rounded-full text-sm space-x-4 cursor-pointer"
                      onClick={() => {
                        setmodal1(true);
                      }}
                    >
                      <div className="flex bg-white p-1 justify-center items-center rounded-full">
                        <i className="w-4 h-4 text-slate-900 las la-pencil-alt"></i>
                      </div>

                      <span>Edit Personal Details</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="">
                <div className="grid grid-cols-12">
                  <div className="col-span-4 sm:col-span-2 border-r border-slate-200/60 pt-5 cursor-pointer">
                    <div className="space-y-5 pl-0">
                      {selectedTab === 1 ? (
                        <h6 className="text-xs sm:text-sm text-redF font-medium">
                          I About Me
                        </h6>
                      ) : (
                        <h6
                          className="text-xs sm:text-sm text-primaryF font-medium"
                          onClick={() => {
                            setselectedTab(1);
                          }}
                        >
                          About Me
                        </h6>
                      )}

                      {selectedTab === 2 ? (
                        <h6 className="text-xs sm:text-sm text-redF font-medium">
                          I Qualifications
                        </h6>
                      ) : (
                        <h6
                          className="text-xs sm:text-sm text-primaryF font-medium"
                          onClick={() => {
                            setselectedTab(2);
                          }}
                        >
                          Qualifications
                        </h6>
                      )}

                      {selectedTab === 4 ? (
                        <h6 className="text-xs sm:text-sm text-redF font-medium">
                          I My Programs
                        </h6>
                      ) : (
                        <h6
                          className="text-xs sm:text-sm text-primaryF font-medium"
                          onClick={() => {
                            setselectedTab(4);
                            setmodal1(true);
                          }}
                        >
                          My Programs
                        </h6>
                      )}

                      {selectedTab === 3 ? (
                        <h6 className="text-xs sm:text-sm text-redF font-medium">
                          I Testimonials
                        </h6>
                      ) : (
                        <h6
                          className="text-xs sm:text-sm text-primaryF font-medium"
                          onClick={() => {
                            setselectedTab(3);
                          }}
                        >
                          Testimonials
                        </h6>
                      )}
                    </div>

                    {/* <div className="bg-blueF text-white flex justify-center text-xs w-[110px] rounded-full py-1.5 cursor-pointer mt-10">
                                            Shortlist
                                        </div> */}

                    <div className="border-t border-slate-200/60 mt-16">
                      <div className="pl-0">
                        <h6 className="text-base text-primaryF font-bold pt-5">
                          Top Fields
                        </h6>

                        <div className="space-y-2 mt-3 text-xs text-primaryF font-normal">
                          {/* <h6 className="">Information Technology

                                                    </h6> */}
                          {/* <h6 className="">Business</h6>
                                                    <h6 className="">Communication
                                                    </h6>
                                                    <h6 className="">Health Sector
                                                    </h6>
                                                    <h6 className="">Immigration
                                                    </h6> */}
                          {/* {JSON.parse(selectedJob?.fields).map(
                            (item, index) => {
                              return <h6 className="">{item}</h6>;
                            }
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-8 sm:col-span-10">
                    <div className="py-5 pl-4 sm:pl-10 sm:pr-5">
                      {selectedTab === 1 && (
                        <>
                          <div className="w-full text-[#45535E] mt-0 leading-7 text-sm">
                            {/* As a seasoned and results-driven Professional Resume Writer, I bring a wealth of experience in crafting compelling and targeted resumes that elevate my clients' professional profiles. With a passion for helping individuals achieve their career goals, I specialize in creating customized and impactful resumes that resonate with hiring managers and recruiters across diverse industries. */}

                            {selectedJob?.responsibilites}
                          </div>
                        
                        </>
                      )}

                      {selectedTab === 2 && (
                        <>
                          {selectedJob?.qualifications && selectedJob.qualifications.length > 0 ? (
  selectedJob.qualifications.map((qualification, index) => (
    <QualificationCard
      key={index}
      qualification={qualification}
      isExpanded={expandedQualificationIndex === index} // Pass the expanded state
      onClick={() => handleExpandQualification(index)} // Pass the click handler
    />
  ))
) : (
  <p>No qualifications available</p>
)}
                      
                        </>
                      )}

                      {selectedTab === 3 && (
                        <>
                          <div className="flex justify-end w-full">
                            <div
                              className="bg-white border border-primaryF text-xs text-primaryF rounded-full w-[150px] text-center py-1.5 cursor-pointer"
                              onClick={() => {
                                setShowModalQuickView(true);
                              }}
                            >
                              Write a Review
                            </div>
                          </div>
                          <div className="space-y-8 mt-5">
                            {reviews.map((item, index) => {
                              return (
                                <>
                                  <div className="bg-[#F4F4F4] px-4 sm:px-9 py-5">
                                    <div className="flex justify-between items-start">
                                      <div className="flex items-center">
                                        <div className="items-center ">
                                          <div className="w-12 h-12 image-fit">
                                            <img
                                              alt="Avatar"
                                              className="rounded-full"
                                              // src={Avatar}
                                              src={
                                                JSON.parse(item?.avatar)
                                                  .length > 0
                                                  ? `${IMAGE_SERVER_URL_DEV}${
                                                      JSON.parse(
                                                        item?.avatar
                                                      )[0].path
                                                    }`
                                                  : Avatar
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="ml-3 items-center">
                                          <div className="flex items-center">
                                            <h2 className="font-bold text-xs sm:text-sm mr-3 text-primaryF">
                                              {/* Kanchana Sirimanna */}
                                              {item?.firstMiddleName +
                                                " " +
                                                item?.surName}
                                            </h2>
                                          </div>

                                          <h4 className="font-medium text-xs mr-auto text-redF mt-2 whitespace-nowrap">
                                            <span className="text-primaryF">
                                              {item?.designation}
                                            </span>{" "}
                                            <span className="mx-2 text-primaryF">
                                              |
                                            </span>{" "}
                                            <span className="">
                                              {item?.companyName}
                                            </span>
                                          </h4>

                                          <div className="mt-2 sm:hidden">
                                            <Rating
                                              name="size-small"
                                              value={item?.rating}
                                              size="small"
                                              className=""
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="hidden sm:flex">
                                        <Rating
                                          name="size-small"
                                          value={item?.rating}
                                          size="small"
                                          className=""
                                          readOnly
                                        />
                                      </div>
                                    </div>

                                    <div className="w-full rounded-xl text-[#45535E] mt-4 leading-6 text-xs">
                                      {/* I had the pleasure of working with [CV Writer's Full Name] on revamping my resume, and the experience surpassed all my expectations. [He/She] is a true professional with an exceptional talent for crafting resumes that stand out. From our initial consultation to the final delivery, [CV Writer's Full Name] demonstrated a deep understanding of my industry and career goals. [He/She] asked insightful questions that helped uncover my unique strengths and accomplishments, which were then artfully highlighted in my new resume. */}

                                      {item?.feedback}
                                    </div>

                                    <div className="flex justify-end text-right text-xs text-[#45535E]">
                                      {/* 3 hours ago */}
                                      {moment(item?.createdAt).fromNow()}
                                    </div>
                                  </div>
                                </>
                              );
                            })}

                       
                          </div>
                        </>
                      )}

                      {selectedTab === 4 && (
                        <>
                        {selectedJob?.programs && selectedJob.programs.length > 0 ? (
  selectedJob.programs.map((program, index) => (
    <ProgramCard
      key={index}
      setShowModalQuickView2={setShowModalQuickView2}
      setqType={setqType}
      program={program}
      isExpanded={expandedProgramIndex === index} // Pass the expanded state
      onClick={() => handleExpandProgram(index)} // Pass the click handler
    />
  ))
) : (
  <p>No programs available</p>
)}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* END: Display Information */}

            {/* 
                        <div className="w-full flex justify-end">

                            <button className="btn btn-primary w-20 mt-3"
                            // onClick={onSubmit}
                            >
                                Save
                            </button>

                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
};


const QualificationCard = ({ qualification, isExpanded, onClick }) => {
    // Conditionally set the color for qualificationStatus
    const qualificationStatusColor = qualification?.qualificationStatus === "In progress" ? "text-redF" : "text-[#00BF63]";
  
    return (
      <div className="w-full flex flex-col mb-7" onClick={onClick}>
        <div className="flex flex-col sm:flex-row sm:justify-between items-start cursor-pointer">
          <div>
            <h5 className="text-primaryF font-bold text-base">
              {qualification?.qualificationName}
            </h5>
            <h6 className="font-normal text-xs sm:text-sm mr-auto text-secondaryF mt-1 whitespace-nowrap">
              <span>{qualification?.university} </span>{" "}
              <span className="mx-2">|</span>{" "}
              <span className="">{qualification?.fieldOfStudy}</span>
            </h6>
            {/* Apply the conditional color class for qualificationStatus */}
            <h6 className={`font-normal text-xs mr-auto mt-1 whitespace-nowrap ${qualificationStatusColor}`}>
              <span>{qualification?.qualificationStatus}</span>
            </h6>
          </div>
  
          <div className="flex items-center mt-4 sm:mt-0">
            <h6 className="font-medium text-xs sm:text-sm mr-auto text-blueF whitespace-nowrap">
              <span>
                {moment(qualification?.startDate).format("MMM YYYY")} -{" "}
                {moment(qualification?.endDate).format("MMM YYYY")}
              </span>
            </h6>
  
            {/* Custom CSS arrow */}
            <div
              className={`ml-3 transform transition-transform duration-300 ease-in-out ${
                isExpanded ? "rotate-180" : ""
              }`}
              style={{
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "0",
                  height: "0",
                  borderLeft: "5px solid transparent",
                  borderRight: "5px solid transparent",
                  borderTop: "5px solid #333", // Arrow color
                }}
              ></div>
            </div>
          </div>
        </div>
  
        {/* Conditionally show the additional qualification details */}
        <div
          className={`overflow-hidden transition-all duration-500 ease-in-out ${
            isExpanded ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          <div
            className={`mt-3 text-secondaryF transform transition-transform duration-500 ${
              isExpanded ? "translate-y-0" : "-translate-y-4"
            }`}
          >
            <div className="w-full bg-[#F4F4F4] py-4 px-7 rounded-xl text-[#45535E] mt-4 leading-8 text-xs sm:text-sm">
              {/* Display any additional information if needed */}
              Qualification Type: {qualification?.qualificationType}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const ProgramCard = ({ program, isExpanded, onClick, setqType, setShowModalQuickView2 }) => {
    return (
      <div className="bg-[#F4F4F4] px-9 py-5 mb-4" onClick={onClick}>
        <div className="flex flex-col sm:flex-row justify-between items-start">
          <div className="flex items-center">
            <div className="ml-0 items-center">
              <div className="flex items-center">
                <h2 className="font-bold text-sm mr-3 text-primaryF">
                  {program?.programName}
                </h2>
              </div>
  
              <h4 className="hidden sm:flex font-medium text-xs mr-auto text-redF mt-1 whitespace-nowrap">
                <span className="">
                  {program?.duration} Program
                </span>{" "}
                <span className="mx-2">|</span>{" "}
                <span className="">
                  LKR {program?.costPerPerson} (Per person)
                </span>
              </h4>
  
              <h4 className="sm:hidden font-medium text-xs mr-auto text-redF mt-1 whitespace-nowrap">
                <span className="">
                  {program?.duration} Program
                </span>
                <br />
                <span className="">
                  LKR {program?.costPerPerson} (Per person)
                </span>
              </h4>
  
              <div className="flex mt-2">
                <span className="text-xs text-primaryF">
                  {program?.programType}
                </span>
                {/* Example rating component */}
                <Rating
                  name="size-small"
                  value={5}
                  size="small"
                  className="ml-6"
                  readOnly
                />
              </div>
            </div>
          </div>
  
          <div>
            <div
              className="bg-redF text-xs text-white rounded-full w-[180px] text-center py-1.5 mb-3 mt-4 sm:mt-0 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setShowModalQuickView2(true);
                setqType("reg");
              }}
            >
              Register (No payment)
            </div>
  
            <div
              className="bg-white border border-primaryF text-xs text-primaryF rounded-full w-[180px] text-center py-1.5 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setShowModalQuickView2(true);
                setqType("job");
              }}
            >
              Inquire for more details
            </div>
          </div>
        </div>
  
        {/* Conditionally show the additional program details */}
        {isExpanded && (
          <div className="bg-[#F4F4F4] px-9 py-5 mt-0.5">
            <div className="flex justify-between items-start">
              <div className="flex items-center">
                <div className="ml-0 items-center">
                  <div className="flex items-center">
                    <h2 className="font-medium text-sm mr-3 text-primaryF">
                      For Whom
                    </h2>
                  </div>
  
                  <ul className="list-disc ml-5 space-y-1 font-normal text-xs text-[#737373] mt-2">
                    <li>{program?.forWhom || "Not specified"}</li>
                  </ul>
  
                  <div className="mt-4">
                    <h2 className="flex font-medium text-sm mr-3 text-primaryF">
                      Program Content in Brief
                    </h2>
  
                    <span className="flex font-normal text-xs text-[#737373] mt-3 leading-normal">
                      {program?.programContent || "No content available."}
                    </span>
                  </div>
  
                  <div className="flex flex-col space-y-2 sm:flex-row sm:justify-between sm:items-center mt-4 w-full sm:space-y-0 sm:space-x-4">
                    <span className="text-xs text-redF">
                      ***Special discounts for corporate/group bookings.
                    </span>
  
                    <span className="text-xs">
                      Program ID : {program?.programId}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

export default Jobs;
