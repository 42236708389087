import Button, { ButtonProps } from "shared/Button/Button";
import React from "react";

export interface ButtonSecondaryProps extends ButtonProps {
  type?: any,
}

const ButtonSecondary: React.FC<ButtonSecondaryProps> = ({
  className = " border border-slate-300 dark:border-slate-700 ",
  type = "",
  ...args
}) => {
  return (
    <Button
      className={`ttnc-ButtonSecondary bg-white text-slate-700 dark:bg-[#002930] dark:text-slate-300 hover:bg-gray-100 dark:hover:bg-slate-800 ${type !== "store" ? "min-w-[175px]" : ""} ${className}`}
      {...args}
    />
  );
};

export default ButtonSecondary;
