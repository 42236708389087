import React, { FC, useState, useEffect, useRef } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL, IMAGE_SERVER_URL_DEV } from "Constant/System";
import Company1 from "images/avatars/Image-1.png";
import ModalQuickView from "components/ModalJob";
import { addToast } from "shared/Toast";
import { AppliedContracts, AppliedJobs, getCleanQuote, getJobs } from "Services/API/Get";
import { DeleteJob } from "Services/API/Delete";
import { useHistory } from "react-router-dom";

import ModalQuickView2 from "components/ModalApplicant";

import DatePicker from "react-multi-date-picker"
import parse from 'html-react-parser';
import LoadingSpinner from "shared/LoadingSpinner";


const MyJobs = (props) => {

    const router = useHistory();

    const [images, setImages] = useState([]);
    const [showModalQuickView, setShowModalQuickView] = useState(false);

    const [showModalQuickView2, setShowModalQuickView2] = useState(false);

    const [jobs, setjobs] = useState([]);

    const [search, setsearch] = useState("");
    const [category, setcategory] = useState("");

    const [selectedJob, setselectedJob] = useState("");

    const [currUser, setcurrUser] = useState(props.currUser);

    const [lopen, setlopen] = React.useState(false);

    const getAllJobs = () => {
        console.log(">>>>>>sdsxcddf")
        setlopen(true);
        getCleanQuote({
            jobType: JSON.stringify([]),
            title: search,
            jobType: category ? JSON.stringify([category]) : JSON.stringify([]),
            sellerId: ""
        })
            .then((res) => {

                console.log(">>>>>>>jobs", res.data);
                setjobs(res.data.result);
                setlopen(false);

            })
            .catch((error) => {
                // setlopen(false);
                setjobs([]);
                console.log("Error", error.response);
                addToast(error.response.data.message, "warning");
                setlopen(false);

                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            })
    }

    useEffect(() => {
        getAllJobs();
    }, [showModalQuickView, search, category])




    return (
        <>
            <LoadingSpinner open={lopen} />
            <div className="justify-center w-full mt-12">

                <span className="block text-xl font-bold flex justify-center w-full text-center">
                    My Quotations
                </span>

                <div className="flex justify-center">
                    <span className="text-xs font-normal flex justify-center w-full md:w-9/12 lg:w-[45%] text-center mt-1 mb-6">
                        On this page, you can see the quotations you have
                        submitted to different service providers.

                    </span>
                </div>


            </div>
            <div className="w-full flex justify-center px-3">

                <div className="w-full md:w-8/12 lg:w-8/12 xl:w-[55%]">

                    <div className="flex w-full items-center justify-center">

                    </div>
                    <form className="mt-6 w-full">

                        {/* <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                            </div>
                            <Input type="search" id="default-search" className="block w-full p-4 pl-10 text-base text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Title..." required
                                onChange={(e) => {
                                    setsearch(e.target.value);
                                }}
                            />

                        </div> */}
                    </form>


                    {/* <div className="mt-10 space-y-2">
                        {

                            jobs.length > 0 ?

                                jobs.map((item, index) => {
                                    const doc = JSON.parse(item?.document)[0]
                                    return (
                                        <>
                                            <div className="">
                                                <div className="bg-gray-100 flex w-full px-4 py-3 rounded-lg space-x-4">
                                                   
                                                    <div className="flex justify-start w-full items-center">
                                                        <div>
                                                            <div className="text-base font-medium flex justify-start items-center">{item.title}</div>

                                                            <div className="text-sm font-medium flex justify-start items-center">{item.email}</div>


                                                        </div>
                                                    </div>

                                                    <div className="w-6/12 flex justify-start items-center">
                                                        <div>

                                                        </div>
                                                    </div>

                                                    <div className="w-6/12 flex justify-end items-center">
                                                        <div>

                                                            <a className="flex flex-col cursor-pointer" href={`${IMAGE_SERVER_URL_DEV}${doc?.path}`} target="_blank" >

                                                                <div className="flex w-full">
                                                                    <img className="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nO3Xv0skMRQH8GnGH6yoWCiChbJgZaGNtYWNnf+CFqJYWIpiJ8gVgjaiYiX+BRaCrY0iy3EWarGIhYiLhQiLyWzmzeR7JKO76KLHqclucB6EYbr3Sd6bl/G8NNIwG8z3YWj9chfQ12cPwQwAwj+/wbJZOwgTALov2EMwQwBrCGYQQDYQpgFkGmEDQCYRtgBkCmETQCYQtgH0BlGXgP9ZXgrw0xOAcyVUGhuDzOchi0WECwvuAeTtLcoRx+C9ve4AeFdXJfejI/0sjY+7A2CZDBBFCeDiwkGA7yM+OyuXj4pgeNgtQLi4WOkBIrC2NrcAvLsbCILkEHK5TyfPajmJo4ODBHB8DNbQ4BigqQmyUChXkZiddW+Q6RBCP9RA4/397gBod1cnHq6sINrfTxBXV+A9PfUP4KqBn570LODZLHhnJ+LLS42IT0/16Yi5OdDGBmhnB7S9DTExAdbcXB+AcHlZJ6uaWL0Hg4OgrS38K2hvr/YA3tEB+fCQ7PbJCeT19essn3viJWQ+j/j8PHnhvMaATAa0tla1s6p8wtVVlEZHwVpa9JBTTf02osPDGgEaGyFmZiBvbio7Wyzq+n7vCqEufGJyErS+DtrchJieBmtvtw8IBgZ0Y77a8VxOl9Jnvl7MJiAYGYF8fKyq8WBo6NuSZ8YAatI+l4y8uyvfOsOlpW9NnhkDtLZWfVHU8PrKnYfZLiExNaV3X/0+hvPz7w6iugUwS8tLAX56AkhL6EeXUBppeB/GX+axT5lwmtMeAAAAAElFTkSuQmCC" />
                                                                </div>


                                                            </a>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className="bg-gray-100 w-full px-4 py-3 rounded-b-lg space-y-0">
                                                    {parse(item?.description)}
                                                </div>
                                         

                                            </div>

                                        </>
                                    );
                                })

                                :
                                <div className="flex !mt-20 justify-center items-center">
                                    <p className="text-xl font-semibold">
                                       
                                        No quotations found
                                    </p>

                                </div>
                        }

                    </div> */}

                    <div className="mt-10 space-y-2 overflow-x-auto">

                        {


                            <>
                                <div className="bg-gray-100 flex w-full px-4 py-3 rounded-lg space-x-4">
                                    {/* <div className="flex justify-start w-3/12 items-center">

                {
                    [1,].map((data, index) => {
                        return (
                            <>
                                <div className="w-12 h-12 object-fill bg-contain rounded-full border-2 border-white"
            style={{
                // backgroundImage: `url(${IMAGE_SERVER_URL_DEV})`
            }}
        >

        </div>
                            </>)
                    })
                }


            </div> */}
                                    <div className="w-5/12 flex justify-start items-center">
                                        <div>
                                            <div className="text-base font-bold flex justify-start items-center">Service Provider</div>


                                            {/* <div className="text-sm font-medium flex justify-start items-center">{moment(item?.createdAt).format("YYYY-MM-DD HH:MM:SS")}</div> */}
                                        </div>
                                    </div>
                                    <div className="w-5/12 flex justify-start items-center">
                                        <div>
                                            <div className="text-base font-bold flex justify-start items-center">Email</div>


                                            {/* <div className="text-sm font-medium flex justify-start items-center">{moment(item?.createdAt).format("YYYY-MM-DD HH:MM:SS")}</div> */}
                                        </div>
                                    </div>
                                    <div className="w-5/12 flex justify-start items-center">
                                        <div>
                                            <div className="text-base font-bold flex justify-start items-center">Phone</div>


                                            {/* <div className="text-sm font-medium flex justify-start items-center">{moment(item?.createdAt).format("YYYY-MM-DD HH:MM:SS")}</div> */}
                                        </div>
                                    </div>

                                    <div className="w-6/12 flex justify-start items-center">
                                        <div>
                                            <div className="text-base font-bold flex justify-start items-center">Address</div>

                                            {/* <div className="text-sm font-medium flex justify-start items-center">{item?.orderNo}</div> */}
                                        </div>
                                    </div>
                                    <div className="w-2/12 flex justify-start items-center">

                                    </div>

                                    {/* <div className="w-full flex justify-start items-center">
                    <div>
                        <div className="text-base font-bold flex justify-start items-center">Customer</div>


                    </div>
                </div> */}

                                    {/* <div className="w-4/12 flex justify-start items-center">
                    <div>
                        <div className="text-base font-bold flex justify-start items-center">Amount</div>

                    </div>
                </div>
                <div className="w-11/12 flex justify-start items-center">
                    <div>
                        <div className="text-base font-bold flex justify-start items-center">Status</div>

                    </div>


                </div> */}









                                </div>
                                {
                                    jobs.map((item, index) => {
                                        // const doc = JSON.parse(item?.document)[0]
                                        const doc = ""
                                        return (
                                            <>
                                                <div className="bg-gray-100 flex w-full px-4 py-3 rounded-lg space-x-4 ">
                                                    {/* <div className="flex justify-start w-3/12 items-center">

                                {
                                    [1,].map((data, index) => {
                                        return (
                                            <>
                                                <div className="w-12 h-12 object-fill bg-contain rounded-full border-2 border-white"
                                                        style={{
                                                            backgroundImage: `url(${IMAGE_SERVER_URL_DEV}${item?.avatar})`
                                                        }}
                                                    >

                                                    </div>
                                            </>)
                                    })
                                }


                            </div> */}
                                                    <div className="w-5/12 flex justify-start items-center">
                                                        <div>


                                                            <div className="text-sm font-medium flex justify-start items-center">{item.firstName + " " + item.lastName}</div>


                                                        </div>
                                                    </div>
                                                    <div className="w-5/12 flex justify-start items-center">
                                                        <div>



                                                            <div className="text-sm font-medium flex justify-start items-center">{item?.email}</div>


                                                        </div>
                                                    </div>
                                                    <div className="w-5/12 flex justify-start items-center">
                                                        <div>



                                                            <div className="text-sm font-medium flex justify-start items-center">{item?.contactNumber}</div>

                                                        </div>
                                                    </div>

                                                    <div className="w-6/12 flex justify-start items-center">
                                                        <div>

                                                            <div className="text-sm font-medium flex justify-start items-center">{item?.homeAddress}</div>
                                                            <div className="text-sm font-normal flex justify-start items-center">{item?.homeCity}</div>
                                                            <div className="text-sm font-normal flex justify-start items-center">{item?.homeDistrict}</div>
                                                            <div className="text-sm font-normal flex justify-start items-center">{item?.homepostCode}</div>

                                                        </div>


                                                    </div>
                                                    <div className="w-2/12 flex justify-start items-center">


                                                        {/* <a className="flex flex-col cursor-pointer" href={`${IMAGE_SERVER_URL_DEV}${doc?.path}`} target="_blank" >

                                                            <div className="flex w-full">
                                                                <img className="w-10 h-10" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nO3Xv0skMRQH8GnGH6yoWCiChbJgZaGNtYWNnf+CFqJYWIpiJ8gVgjaiYiX+BRaCrY0iy3EWarGIhYiLhQiLyWzmzeR7JKO76KLHqclucB6EYbr3Sd6bl/G8NNIwG8z3YWj9chfQ12cPwQwAwj+/wbJZOwgTALov2EMwQwBrCGYQQDYQpgFkGmEDQCYRtgBkCmETQCYQtgH0BlGXgP9ZXgrw0xOAcyVUGhuDzOchi0WECwvuAeTtLcoRx+C9ve4AeFdXJfejI/0sjY+7A2CZDBBFCeDiwkGA7yM+OyuXj4pgeNgtQLi4WOkBIrC2NrcAvLsbCILkEHK5TyfPajmJo4ODBHB8DNbQ4BigqQmyUChXkZiddW+Q6RBCP9RA4/397gBod1cnHq6sINrfTxBXV+A9PfUP4KqBn570LODZLHhnJ+LLS42IT0/16Yi5OdDGBmhnB7S9DTExAdbcXB+AcHlZJ6uaWL0Hg4OgrS38K2hvr/YA3tEB+fCQ7PbJCeT19essn3viJWQ+j/j8PHnhvMaATAa0tla1s6p8wtVVlEZHwVpa9JBTTf02osPDGgEaGyFmZiBvbio7Wyzq+n7vCqEufGJyErS+DtrchJieBmtvtw8IBgZ0Y77a8VxOl9Jnvl7MJiAYGYF8fKyq8WBo6NuSZ8YAatI+l4y8uyvfOsOlpW9NnhkDtLZWfVHU8PrKnYfZLiExNaV3X/0+hvPz7w6iugUwS8tLAX56AkhL6EeXUBppeB/GX+axT5lwmtMeAAAAAElFTkSuQmCC" />
                                                            </div>


                                                        </a> */}

                                                    </div>



                                                </div>

                                            </>
                                        );
                                    })
                                }
                            </>


                        }


                        {jobs.length === 0 &&


                            <>
                                <div className="flex !mt-20 justify-center items-center">
                                    <p className="text-xl font-semibold">
                                        {/* #WU3746HGG12 */}
                                        No quotations Found
                                    </p>

                                </div>


                            </>

                        }

                    </div>


                </div>
            </div>

            <ModalQuickView
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
                selectedJob={selectedJob}
            />

            <ModalQuickView2
                show={showModalQuickView2}
                onCloseModalQuickView={() => setShowModalQuickView2(false)}
                selectedJob={selectedJob}

            />

        </>
    )

}

export default MyJobs;