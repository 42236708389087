import React, { FC, useState, useEffect, useRef, useContext } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { CurrentUser } from "Services/API/Get";
import { EditUserQuery } from "Services/API/Put";
import { addToast } from "shared/Toast";
import AuthContext from "Context/AuthContext";
import BotToast from "shared/BotToast";
import { t } from "i18next";

const ChangePassword = ({ getUser, logUser }) => {

    const { user, setUser } = useContext(AuthContext);
    const [firstName, setfirstName] = useState("");
    const [lastName, setlasttName] = useState("");
    const [mobile, setmobile] = useState("");
    const [email, setemail] = useState("");
    const [premail, setpremail] = useState("");
    const [lopen, setlopen] = React.useState(false);

    const [homeNo, sethomeNo] = useState("");
    const [homeAddress, sethomeAddress] = useState("");
    const [homeDistrict, sethomeDistrict] = useState("");
    const [homeCity, sethomeCity] = useState("");
    const [homepostCode, sethomepostCode] = useState("");

    const [errhomeDistrict, seterrhomeDistrict] = useState(false);
    const [errhomeCity, seterrhomeCity] = useState(false);
    const [errhomepostCode, seterrhomepostCode] = useState(false);
    const [errhomeAddress, seterrhomeAddress] = useState(false);

    const [homeDistrictId, sethomeDistrictId] = useState(0);
    const [allHCities, setallHCities] = useState([]);
    const [allHDistricts, setallHDistricts] = useState([]);

    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [repassword, setrePassword] = useState("");
    const [idisable, setidisable] = useState(true);
    const [errfirstName, seterrfirstName] = useState(false);
    const [errlastName, seterrlasttName] = useState(false);
    const [errmobile, seterrmobile] = useState("");
    const [erremail, seterremail] = useState(false);

    const [errPass, seterrPass] = useState(false);
    const [errPass2, seterrPass2] = useState("");
    const [errRePass, seterrRePass] = useState("");

    const [userId, setuserId] = useState("");
    const [currUser, setcurrUser] = useState(logUser);
    const [eDisable, seteDisable] = useState(true);
    const [eDisable2, seteDisable2] = useState(true);

    const [bOpen, setbOpen] = useState(false);
    const [bMsg, setbMsg] = useState("");
    const [bStatus, setbStatus] = useState("");

    useEffect(() => {
        setcurrUser(logUser);
    }, [logUser])

    const changePassword = async (e) => {

        const phoneRegex =
            /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

        e.preventDefault();

        if (password === "") {
            seterrPass(true)

        }

        if (password2 === "") {
            seterrPass2("This field is required")

        }

        if (password != password2) {
            seterrPass2("Password Mismatch")

        }

        if (repassword === "") {
            seterrRePass("This field is required");

        }

        if (password !== "") {
            //   seterrPass(true)
            if (repassword === "") {
                seterrRePass("This field is required");
            } else {
                seterrRePass("");
            }
        }





        // if(password != "" && repassword != "" && !(password === repassword)){
        //   seterrRePass("Password Mismatch")
        // }
        // if(password != "" && repassword != "" && (password === repassword)){
        //   seterrRePass("")
        // }

        let obj = {
            firstName: currUser.firstName === null ? "" : currUser.firstName,
            lastName: currUser.lastName === null ? "" : currUser.lastName,
            supplierCategory: currUser.supplierCategory === null ? "" : currUser.supplierCategory,
            businessName: currUser.businessName === null ? "" : currUser.businessName,
            businessRegistrationNumber: currUser.businessRegistrationNumber === null ? "" : currUser.businessRegistrationNumber,
            establishedYear: currUser.establishedYear === null ? "" : currUser.establishedYear,
            website: currUser.website === null ? "" : currUser.website,
            country: currUser.country === null ? "" : currUser.country,
            contactNumber: currUser.contactNumber === null ? "" : currUser.contactNumber,
            contactNumber2: currUser.contactNumber2 === null ? "" : currUser.contactNumber2,
            email: currUser.email === null ? "" : currUser.email,
            contactName: currUser.contactName === null ? "" : currUser.contactName,
            about: currUser.about === null ? "" : currUser.about,
            homeNumber: currUser.homeNumber === null ? "" : currUser.homeNumber,
            homeAddress: currUser.homeAddress === null ? "" : currUser.homeAddress,
            homeCity: currUser.homeCity === null ? "" : currUser.homeCity,
            homeDistrict: currUser.homeDistrict === null ? "" : currUser.homeDistrict,
            homepostCode: currUser.homepostCode === null ? "" : currUser.homepostCode,
            note: currUser.note === null ? "" : currUser.note,
            cover: currUser.cover === null ? "" : currUser.cover,
            avatar: currUser.avatar === null ? "" : currUser.avatar,
            logo: currUser.logo === null ? "" : currUser.logo,
            paymentGateway: currUser.paymentGateway === null ? "" : currUser.paymentGateway,
            userType: currUser.userType === null ? "" : currUser.userType,
            clientId: currUser.clientId === null ? "" : currUser.clientId,
            secretId: currUser.secretId === null ? "" : currUser.secretId,
            deliveryFee: currUser.deliveryFee === null ? "" : currUser.deliveryFee,
            salesCount: currUser.salesCount === null ? "" : currUser.salesCount,
            password: password,
            oldPassword: repassword,
            serviceProviderImages: !currUser.serviceProviderImages ? "" : JSON.parse(currUser.serviceProviderImages),
            serviceProvierVideos: !currUser.serviceProvierVideos ? "" : currUser.serviceProvierVideos,
            cleaningTypeId: currUser.cleaningTypeId === null ? "" : currUser.cleaningTypeId,
            shippitKey: currUser.shippitKey === null ? "" : currUser.shippitKey,
            websiteRequest: currUser.websiteRequest === null ? 0 : currUser.websiteRequest,
            refundPolicyDescription: currUser?.refundPolicyDescription ? currUser?.refundPolicyDescription : "",
            refundPolicyDocument: currUser?.refundPolicyDocument ? currUser?.refundPolicyDocument : "",
            deliveryPolicyDescription: currUser?.deliveryPolicyDescription ? currUser?.deliveryPolicyDescription : "",
            deliveryPolicyDocument: currUser?.deliveryPolicyDocument ? currUser?.deliveryPolicyDocument : "",

            publicLiabilityInsurance: currUser.publicLiabilityInsurance === null ? 0 : currUser.publicLiabilityInsurance,
            IndemnityInsurance: currUser.IndemnityInsurance === null ? 0 : currUser.IndemnityInsurance,
            workCoverInsurance: currUser.workCoverInsurance === null ? 0 : currUser.workCoverInsurance,
            workersCompensationInsurance: currUser.workersCompensationInsurance === null ? 0 : currUser.workersCompensationInsurance,
            policeClearanceCertificate: currUser.policeClearanceCertificate === null ? 0 : currUser.policeClearanceCertificate,
            workingWithChildren: currUser.workingWithChildren === null ? 0 : currUser.workingWithChildren,

            policeClearanceCertificateDoc: currUser?.policeClearanceCertificateDoc ? currUser?.policeClearanceCertificateDoc : "",
            workingWithChildrenDoc: currUser?.workingWithChildrenDoc ? currUser?.workingWithChildrenDoc : "",
            issuedDate: currUser?.issuedDate ? currUser?.issuedDate : "",
            expiryDate: currUser?.expiryDate ? currUser?.expiryDate : "",
            mainId: currUser?.mainId ? currUser?.mainId : "",
            subId: currUser?.subId ? currUser?.subId : "",
            title: currUser?.title ? currUser?.title : "",
            accType: currUser?.accType ? currUser?.accType : "",
            profUpdate: false

        };
        console.log("obj>>>>", obj);

        if (

            (password !== "" && repassword !== "" && password === password2)


        ) {
            setlopen(true);

            EditUserQuery(obj)
                .then((res) => {
                    setlopen(false);

                    setbOpen(true);
                    setbMsg("Successfully updated");
                    setbStatus("warning");

                    // addToast("Successfully updated", "success");
                    setPassword("");
                    setPassword2("");
                    setrePassword("");
                    // setOpenToast(true);
                    // setColor(COLORS.Primary);
                    // setAlertClass("success");
                    // setMsg("Successfully updated");
                    getCurrentUser();
                    getUser();
                    // seteDisable(true);

                    // history.push("/");
                    // window.location.reload();
                })
                .catch((error) => {
                    setlopen(false);
                    console.log("Error", error.response);

                    setbOpen(true);
                    setbMsg(error.response.data.message);
                    setbStatus("warning");

                    // addToast(error.response.data.message, "warning");
                    // setOpenToast(true);
                    // setColor(COLORS.Orange);
                    // setAlertClass("error");
                    // setMsg(error.response.data.message);
                })


        } else {
            // scrollElement();
        }
    };

    const getCurrentUser = () => {
        CurrentUser()
            .then((res) => {
                if (res.statusCode === 200) {
                    const cData = res.data[0];
                    setUser(cData);
                    const obj = {
                        id: cData.id,
                        email: cData?.email,
                        businessName: cData?.businessName,
                        contactName: cData?.contactName,
                        phone: cData?.mobileNumber,
                    };
                    //   this.setState({
                    //     businessName: cData?.businessName,
                    //     contactName: cData?.contactName,
                    //     email: cData?.email,
                    //     tele: cData?.phone,
                    //   });
                    console.log("<><><><>", cData);
                    setcurrUser(cData);
                    // setName(cData?.businessName + " " + cData?.contactName);
                    // setfirstName(cData?.firstName);
                    // setlastName(cData?.lastName);
                    // setcategory(cData?.supplierCategory !== null ? cData?.supplierCategory : "");
                    // setbusinessName(cData?.businessName !== null ? cData?.businessName : "");
                    // setbusinessReg(cData?.businessRegistrationNumber !== null ? cData?.businessRegistrationNumber : "");
                    // setyear(cData?.establishedYear !== null ? cData?.establishedYear : "");
                    // setwebsite(cData?.website !== null ? cData?.website : "");
                    // setcountry(cData?.country !== null ? cData?.country : "");
                    // setmobile(cData?.contactNumber !== null ? cData?.contactNumber : "");
                    // setmobile2(cData?.contactNumber2 !== null ? cData?.contactNumber2 : "");
                    // setcontactName(cData?.contactName !== null ? cData?.contactName : "");
                    // setemail(cData?.email);
                    // setpremail(cData?.email);
                    // setabout(cData?.about !== null ? cData?.about : "");
                    // sethomeDistrict(cData?.homeDistrict !== null ? cData?.homeDistrict : "");
                    // sethomeAddress(cData?.homeAddress !== null ? cData?.homeAddress : "");
                    // sethomeCity(cData?.homeCity !== null ? cData?.homeCity : "");
                    // sethomeNo(cData?.homeNumber !== null ? cData?.homeNumber : "");
                    // sethomepostCode(cData?.homepostCode !== null ? cData?.homepostCode : "");
                    setuserId(cData?.id);


                    localStorage.setItem(
                        `${window.location.hostname}.profile`,
                        JSON.stringify(obj)
                    );
                }
            })
            .catch((error) => {
                console.log("error", error);
                console.log("current user error", error.response.data.message);
            });
    };

    React.useEffect(() => {
        getCurrentUser();

    }, []);

    useEffect(() => {
        if (bOpen === true) {
            setTimeout(() => {

                setbOpen(false)

            }, 10000);
        }
    }, [bOpen])

    return (
        <>
            <div className="w-full flex justify-center px-3">
                <div className="w-full md:w-9/12 lg:w-[45%]">
                    <div className="flex justify-center w-full mt-12 mb-6">

                        <span className="block text-3xl font-bold flex justify-center w-full text-center">
                            {/* Update Password */}
                            {t("hChgPwrd")}
                        </span>
                    </div>
                    <div className="space-y-4">
                        <div>
                            <Label className="text-sm">{t("pwOld")}</Label>
                            <Input type="password" className="mt-1.5"
                                placeholder={t("pwOld")}
                                onChange={(e) => {
                                    setrePassword(e.target.value);
                                    seterrRePass("");
                                }}
                                value={repassword}
                            />
                            {errRePass && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    {errRePass}
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">{t("pwNew")}</Label>
                            <Input type="password" className="mt-1.5"
                                placeholder={t("pwNew")}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    seterrPass(false);
                                }}
                                value={password}
                            />
                            {errPass && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">{t("pwConf")}</Label>
                            <Input type="password" className="mt-1.5"
                                onChange={(e) => {
                                    setPassword2(e.target.value);
                                    seterrPass2("");
                                }}
                                placeholder={t("pwConf")}
                                value={password2}
                            />
                            {errPass2 && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    {errPass2}
                                </div>
                            )}
                        </div>
                        {/* <div className="pt-2 mt-4">
                            <ButtonPrimary
                            // onClick={changePassword}
                            >Update password</ButtonPrimary>
                        </div> */}
                        <div className="w-full items-center justify-center">
                            <BotToast open={bOpen} msg={bMsg} status={bStatus} />

                            <button
                                className={
                                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-12 `}

                                onClick={changePassword}
                            >
                                {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                                <span className="block  text-sm font-medium "
                                >
                                    {t("hChgPwrd")}
                                </span>

                            </button>


                        </div>

                    </div>


                </div>
            </div>

        </>
    )

}

export default ChangePassword;