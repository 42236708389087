import React, { FC, useContext } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import LikeButton from "./LikeButton";
import Prices from "./Prices";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import { Product, PRODUCTS } from "data/data";
import { StarIcon } from "@heroicons/react/24/solid";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondaryNew";
import BagIcon from "./BagIcon";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import ModalQuickView from "./ModalQuickView";
import ProductStatus from "./ProductStatus";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import jwtDecode from "jwt-decode";
import { addToCard, addToWishlist } from "Services/API/Post";
import { addToast } from "shared/Toast";
import NotifyAddTocart from "./NotifyAddTocart";
import UserWishList from "Constant/WishList";
import WishContext from "Context/WishContext";
import _ from "lodash"
import { DeleteWishlist } from "Services/API/Delete";

export interface Product2 {
  id: number;
  productId: string;
  name: string;
  unitprice: number;
  productName: any;
  images: string;
  latest: number;
  featured?: number;

}

export interface Pimages {
  path: string
}

export interface ProductCardProps {
  className?: string;
  data?: Product;
  data2?: Product2;
  isLiked?: boolean;
}



const ProductCard: FC<ProductCardProps> = ({
  className = "",
  data = PRODUCTS[0],
  data2 = {
    id: 0,
    productId: "",
    unitprice: 0,
    productName: "",
    images: "",
    latest: 0,
    featured: 0,
  },
  isLiked,
}) => {
  const {
    // name,
    // price,
    // description,
    // sizes,
    // variants,
    // variantType,
    // status,
    // image,
    id,
    productId,
    unitprice,
    productName,
    images,
    latest,
    featured,
  } = data2;
  const {
    name,
    price,
    description,
    sizes,
    variants,
    variantType,
    status,
    image,

  } = data;
  const history = useHistory();
  const [variantActive, setVariantActive] = React.useState(0);
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);

  const { wish, setWish } = useContext(WishContext);

  console.log("AQQQQQ", images && JSON.parse(images)[0]?.path)

  const notifyAddToWish = () => {


    const getToken = localStorage.getItem(`${window.location.hostname}.login`);

    if (!getToken) {
      history.push("/login");
      window.scroll(0, 0);
      return true;
    }

    const { data } = JSON.parse(getToken);
    const userData: any = jwtDecode(data);

    let error = false;
    let cartDetails = {
      productId: id.toString(),
      userId: userData.id,
      qty: 1,
      // color: selectedProductColor,
      // size: selectedProductPrice,
      fileType: "",
      image: "",
    };

    console.log("data>>>>", cartDetails)


    if (!(_.map(wish, "id").includes(id))) {
      addToWishlist(cartDetails)
        .then((data) => {
          toast.custom(
            (t) => (
              <Transition
                appear
                show={t.visible}
                className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
                enter="transition-all duration-150"
                enterFrom="opacity-0 translate-x-20"
                enterTo="opacity-100 translate-x-0"
                leave="transition-all duration-150"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-20"
              >
                <p className="block text-base font-semibold leading-none">
                  Added to Wishlist !
                </p>
                <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
                {renderProductCartOnNotify({ size: "" })}
              </Transition>
            ),
            { position: "top-right", id: "nc-product-notify", duration: 3000 }
          );
          // setlopen(false);
          // addToast(`${product?.productName} Added to Cart`, { appearance: "success", autoDismiss: true });
          // toast.custom(
          //   (t) => (
          //     <NotifyAddTocart
          //       productImage={`${IMAGE_SERVER_URL_DEV}${JSON.parse(images).filter((item: { thumbnail: boolean; }) => item?.thumbnail === true)[0]?.path}`}
          //       qualitySelected={1}
          //       show={t.visible}
          //       sizeSelected={""}
          //       variantActive={variantActive}
          //       selectedProductColor={""}
          //       selectedProduct={{productName:productName,unitPrice:unitprice}}
          //     />
          //   ),
          //   { position: "top-right", id: "nc-product-notify", duration: 3000 }
          // );

          UserWishList().then((res) => {
            console.log("count res-------->", res);
            setWish(res);




          });
          // this.setState({
          //   color2: "#645bff",
          //   alertClass: "success",
          //   msg: `${this.state.productData?.displayName} Added to Cart`,
          //   open_toast: true,
          // });
        })
        .catch((err) => {
          // setlopen(false);
          addToast(`${err.response.data.message}`, "warning");
          // this.setState({
          //   color2: "#645bff",
          //   alertClass: "success",
          //   msg: `${this.state.productData?.displayName} product add failed`,
          //   open_toast: true,
          // });
        });

    } else {
      DeleteWishlist(wish.filter((item: any) => item?.id === id)[0]?.wishlistId)
        .then((res) => {
          toast.custom(
            (t) => (
              <Transition
                appear
                show={t.visible}
                className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
                enter="transition-all duration-150"
                enterFrom="opacity-0 translate-x-20"
                enterTo="opacity-100 translate-x-0"
                leave="transition-all duration-150"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-20"
              >
                <p className="block text-base font-semibold leading-none">
                  Removed from Wishlist !
                </p>
                <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
                {renderProductCartOnNotify({ size: "" })}
              </Transition>
            ),
            { position: "top-right", id: "nc-product-notify", duration: 3000 }
          );
          UserWishList().then((res) => {
            console.log("count res-------->", res);
            setWish(res);




          });
          //  this.setState(
          //    {
          //      open_toast: true,
          //      alertClass: "error",
          //      msg: `${data.displayName} Removed`,
          //      color: "#635bff",
          //    },
          //    () => this.getAllCart()
          //  );
        })
        .catch((error) =>
          console.error("delete cart item error", error)
        );

    }






  };

  const notifyAddTocart = ({ size }: { size?: string }) => {
    toast.custom(
      (t) => (
        <Transition
          appear
          show={t.visible}
          className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
          enter="transition-all duration-150"
          enterFrom="opacity-0 translate-x-20"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-20"
        >
          <p className="block text-base font-semibold leading-none">
            Added to cart!
          </p>
          <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
          {renderProductCartOnNotify({ size })}
        </Transition>
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const renderProductCartOnNotify = ({ size }: { size?: string }) => {


    return (
      <div className="flex ">
        <div className="h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={
              images && JSON.parse(images).length > 0 ?

                `${IMAGE_SERVER_URL_DEV}${JSON.parse(images).filter((item: { thumbnail: boolean; }) => item?.thumbnail === true)[0]?.path}` :

                ""
            }
            alt={productName}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-base font-medium ">{productName}</h3>
                {/* <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                  <span>
                    {variants ? variants[variantActive].name : `Natural`}
                  </span>
                  <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                  <span>{size || "XL"}</span>
                </p> */}
              </div>
              <Prices price={unitprice} className="mt-0.5" />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            {/* <p className="text-gray-500 dark:text-slate-400">Qty 1</p> */}

            <div className="flex">
              <a href="/account-savelists">
                <button
                  type="button"
                  className="font-medium text-primary-6000 dark:text-primary-500 "
                >
                  View Wishlist
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getBorderClass = (Bgclass = "") => {
    if (Bgclass.includes("red")) {
      return "border-red-500";
    }
    if (Bgclass.includes("violet")) {
      return "border-violet-500";
    }
    if (Bgclass.includes("orange")) {
      return "border-orange-500";
    }
    if (Bgclass.includes("green")) {
      return "border-green-500";
    }
    if (Bgclass.includes("blue")) {
      return "border-blue-500";
    }
    if (Bgclass.includes("sky")) {
      return "border-sky-500";
    }
    if (Bgclass.includes("yellow")) {
      return "border-yellow-500";
    }
    return "border-transparent";
  };

  const renderVariants = () => {
    if (!variants || !variants.length || !variantType) {
      return null;
    }

    if (variantType === "color") {
      return (
        <div className="flex space-x-1">
          {variants.map((variant, index) => (
            <div
              key={index}
              onClick={() => setVariantActive(index)}
              className={`relative w-6 h-6 rounded-full overflow-hidden z-10 border cursor-pointer ${variantActive === index
                ? getBorderClass(variant.color)
                : "border-transparent"
                }`}
              title={variant.name}
            >
              <div
                className={`absolute inset-0.5 rounded-full z-0 ${variant.color}`}
              ></div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className="flex ">
        {variants.map((variant, index) => (
          <div
            key={index}
            onClick={() => setVariantActive(index)}
            className={`relative w-11 h-6 rounded-full overflow-hidden z-10 border cursor-pointer ${variantActive === index
              ? "border-black dark:border-slate-300"
              : "border-transparent"
              }`}
            title={variant.name}
          >
            <div className="absolute inset-0.5 rounded-full overflow-hidden z-0">
              <img
                src={variant.thumbnail}
                alt="variant"
                className="absolute w-full h-full object-cover"
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderGroupButtons = () => {
    return (
      <div className="absolute bottom-0 group-hover:bottom-4 inset-x-1 flex justify-center opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all">
        {/* <ButtonPrimary
          className="shadow-lg"
          fontSize="text-[10.8px]"
          sizeClass="py-2 px-4"
          onClick={() => notifyAddTocart({ size: "XL" })}
        >
          <BagIcon className="w-3.5 h-3.5 mb-0.5" />
          <span className="ml-1">Add to bag</span>
        </ButtonPrimary> */}
        <NavLink to={{ pathname: `/product-detail/${productId}` }}>
          <ButtonSecondary
         
            className="ml-1.5 bg-white hover:!bg-gray-100 hover:text-slate-900 transition-colors shadow-lg"
            fontSize="text-[10.8px]"
            sizeClass="py-2 px-4"
          // onClick={() => setShowModalQuickView(true)}
          >
            <ArrowsPointingOutIcon className="w-3.5 h-3.5" />
            {/* <a href={`/product-detail/${productId}`}> */}
            <span className="ml-1">View</span>
            {/* </a> */}
          </ButtonSecondary>
        </NavLink>

      </div>
    );
  };

  const renderSizeList = () => {
    if (!sizes || !sizes.length) {
      return null;
    }

    return (
      <div className="absolute bottom-0 inset-x-1 space-x-1.5 flex justify-center opacity-0 invisible group-hover:bottom-4 group-hover:opacity-100 group-hover:visible transition-all">
        {sizes.map((size, index) => {
          return (
            <div
              key={index}
              className="nc-shadow-lg w-10 h-10 rounded-xl bg-white hover:bg-[#002930] hover:text-white transition-colors cursor-pointer flex items-center justify-center uppercase font-semibold tracking-tight text-sm text-slate-900"
              onClick={() => notifyAddTocart({ size })}
            >
              {size}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div
        className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`}
        data-nc-id="ProductCard"
      >
        <Link to={`/product-detail/${productId}`} className="absolute inset-0"></Link>

        <div className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
          <Link to={`/product-detail/${productId}`} className="block">
            <NcImage
              containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
              // src={image}


              src={
                images && JSON.parse(images).length > 0 ?

                  `${IMAGE_SERVER_URL_DEV}${JSON.parse(images).filter((item: { thumbnail: boolean; }) => item?.thumbnail === true)[0]?.path}` :

                  ""

              }
              className="object-cover w-full h-full drop-shadow-xl"
            />
          </Link>

          <ProductStatus status={latest === 1 ? "latest" : featured === 1 ? "featured" : ""} />

          <LikeButton liked={_.map(wish, "id").includes(id)} className="absolute top-3 right-3 z-10" addWish={notifyAddToWish} />

          {/* {sizes ? renderSizeList() : renderGroupButtons()} */}
          {renderGroupButtons()}
        </div>

        <div className="space-y-4 px-2.5 pt-5 pb-2.5">
          {/* {renderVariants()} */}

          <div>
            <h2
              className={`nc-ProductCard__title text-base font-semibold transition-colors`}
            >
              {productName}
            </h2>
            {/* <p className={`text-sm text-slate-500 dark:text-slate-400 mt-1 `}>
              {description}
            </p> */}
          </div>

          <div className="flex justify-between items-end ">
            <Prices price={unitprice} />
            <div className="flex items-center mb-0.5">
              {/* <StarIcon className="w-5 h-5 pb-[1px] text-amber-400" />
              <span className="text-sm ml-1 text-slate-500 dark:text-slate-400">
                {(Math.random() * 1 + 4).toFixed(1)} (
                {Math.floor(Math.random() * 70 + 20)} reviews)
              </span> */}
            </div>
          </div>
        </div>
      </div>

      {/* QUICKVIEW */}
      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />
    </>
  );
};

export default ProductCard;
