import React, { FC, useState, useEffect, useContext } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useHistory, useLocation } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { addToast } from "shared/Toast";
import { LoginQuery } from "Services/API/Post";
import jwt_decode from "jwt-decode";
import { checkToken, CurrentUser, getSignUpVerification } from "Services/API/Get";
import AuthContext from "Context/AuthContext";
import BotToast from "shared/BotToast";
import Label from "components/Label/Label";
import { useTranslation } from "react-i18next";
import { UpdateUserwithemail } from "Services/API/Put";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg
  }
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const [lopen, setlopen] = React.useState(false);

  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");

  const [username, setusername] = useState("");
  const [lpass, setlpass] = useState("");
  const [aKey, setaKey] = useState("login");

  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);
  const [errPass, seterrPass] = useState(false);
  const [errUsername, seterrerrUsername] = useState("");
  const [errLpass, seterrerrLpass] = useState("");

  const { user, setUser } = useContext(AuthContext);

  const [bOpen, setbOpen] = useState(false);
  const [bMsg, setbMsg] = useState("");
  const [bStatus, setbStatus] = useState("");

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const Login = async (e?: any) => {
    e.preventDefault();

    if (username === "") {
      seterrerrUsername("This field is required");
    }

    if (username !== "") {
      seterrerrUsername("");
    }

    if (lpass === "") {
      seterrerrLpass("This field is required");
    }

    if (lpass !== "") {
      seterrerrLpass("");
    }
    // eslint-disable-next-line no-console

    let obj = {
      email: username,
      password: lpass,
      type: username?.includes("@") === true ? "email" : "mobile"
    };

    console.log(">>>>>>>", obj);

    if (username !== "" && lpass !== "") {
      // setlOpen(true);
      LoginQuery(obj)
        .then((res) => {
          // setUser("logged");
          // setOpenToast(true);
          // setColor(COLORS.Primary);
          // setAlertClass("success");
          // setMsg("Login Successful");

          // localStorage.clear();

          let decoded: any = null;

          decoded = jwt_decode(res?.data);

          if (res?.status) {
            localStorage.setItem(
              `${window.location.hostname}.login`,
              JSON.stringify(res)
            );

            localStorage.setItem("exprTime", decoded?.exp);
            // localStorage.setItem("remember", rememberMe);
          }

          CurrentUser(decoded?.id)
            .then((res) => {
              if (res.statusCode === 200) {
                const cData = res.data[0];
                console.log("log to>>>>>", cData);

                const response = window.localStorage.setItem(
                  `${window.location.hostname}.userDetails`,
                  JSON.stringify(cData)
                );
                setUser(cData);
                // setTimeout(() => {
                //   // setlopen(false);
                // }, 3000);

                if (
                  query.get("selectPage") &&
                  query.get("selectPage") === "replies"
                ) {
                  history.push("/profile?selectPage=replies");
                  window.location.reload();
                } else {
                  if (cData?.userType != "service seeker") {
                    history.push("/profile");
                    window.location.reload();
                  } else {
                    history.push("/");
                    window.location.reload();
                  }
                }

                // history.push("/");
                // window.location.reload();
              } else {
                // setlopen(false);
              }
            })
            .catch((err) => {
              // setlopen(false);
            });

          // history.push("/");
          // window.location.reload();

          // setTimeout(() => {
          //   history.push("/");
          //   window.location.reload();
          // }, 0);
        })
        .catch((error) => {
          // setlOpen(false);
          console.log(error);

          if (error.response.data.statusCode === 401) {
            setbOpen(true);
            setbMsg(error.response.data.message);
            setbStatus("warning");

            seterrerrLpass(error.response.data.message);

            // addToast(error.response.data.message, "warning");
            // seterrerrLpass(error.response.data.message);
          } else if (error.response.data.statusCode === 500) {
            setbOpen(true);
            setbMsg(error.response.data.errorMessage);
            setbStatus("warning");

            seterrerrUsername(error.response.data.errorMessage);

            // addToast(error.response.data.errorMessage, "warning");
            // seterrerrUsername(error.response.data.errorMessage);
          } else {
            setbOpen(true);
            setbMsg(error.response.data.message);
            setbStatus("warning");

            seterrerrLpass(error.response.data.message);

            // addToast(error.response.data.message, "warning");
            // setOpenToast(true);
            // setColor("red");
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          }
        });
    }
  };


  const getVerification = () => {

    let token = query.get("token");
    if (token !== null) {
      // setlopen(true);
      setlopen(true);
      checkToken(token)
        .then((res) => {
         console.log("respond",res)
              setlopen(false);
              addToast("Successfully Verified Please Login", "success");
           
        })
        .catch((error) => {
          // setlopen(false);
          console.log("Error", error.response);
          setlopen(false);
          addToast(error.response.data.message, { appearance: "warning", autoDismiss: true });
        });
    }
  };
  React.useEffect(() => {
  
    getVerification();

  }, []);
  return (
    <div
      className={`nc-PageLogin ${className} flex w-full h-full `}
      data-nc-id="PageLogin"
    >
      <Helmet>
        <title>Login || Skillnet</title>
      </Helmet>

      <div className="w-full flex justify-center items-center">
        <div className="w-full px-3 sm:px-0 md:w-6/12 lg:w-4/12 xl:w-[29%]">
          <div className=" mb-24 lg:mb-32 bg-[#F4F4F4] mt-14 rounded-lg">
            <div className="py-10">
              <h2 className="mb-10 flex items-center text-3xl leading-[115%] md:text-3xl md:leading-[115%] font-bold text-[#003d35] dark:text-neutral-100 justify-center">
                {t("hLogin")}
              </h2>
              <div className="px-10 sm:px-12 space-y-6 ">
                {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
                {/* OR */}
                {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
                {/* FORM */}
                <form className="grid grid-cols-1 gap-6" onSubmit={Login}>
                  <label className="block">
                    <Label className="text-sm"> {t("signUpEmail")}</Label>

                    {errUsername && (
                      <div className="text-red-600 text-sm text-right mt-2">
                        {errUsername}
                      </div>
                    )}
                    <Input
                      type="text"
                      placeholder={`${t("signUpEmail")}`}
                      className="mt-1"
                      onChange={(e) => {
                        setusername(e.target.value);
                        seterrerrUsername("");
                      }}
                      value={username}
                    />
                  </label>
                  <label className="block">
                    <Label className="flex text-sm justify-between items-center">
                      {t("signUpPwrd")}
                    </Label>

                    {errLpass && (
                      <div className="text-red-600 text-sm text-right mt-2">
                        {errLpass}
                      </div>
                    )}

                    <Input
                      type="password"
                      className="mt-1"
                      placeholder={t("signUpPwrd")}
                      onChange={(e) => {
                        setlpass(e.target.value);
                        seterrerrLpass("");
                      }}
                      value={lpass}
                    />

                    <Link
                      to="/reset-password"
                      className="mt-2 text-sm text-gray-500 font-normal flex justify-end"
                    >
                      {t("signInForgot")}
                    </Link>
                  </label>
                  {/* <BotToast open={bOpen} msg={bMsg} status={bStatus} /> */}
                  {/* <ButtonPrimary type="submit">Continue</ButtonPrimary> */}

                  <button
                    className={`ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-redF
        w-full h-9 text-slate-50 font-medium mt-2 cursor-pointer`}
                    type="submit"
                  >
                    {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
                    <span className="block  text-sm font-medium">
                      {t("hLogin")}
                    </span>
                  </button>
                </form>

                {/* ==== */}
                <span className="block text-center text-gray-500 dark:text-neutral-300">
                  {t("logCap1")} {` `}
                  <Link className="text-redF" to="/signup">
                    {t("logCap2")}
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
